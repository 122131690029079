import { FBUIConstants as FB_UI_CONSTANTS } from '../components/UI/FB';
import { presentTimeZone } from './Presenters/TimePresenters';

export const {
  STATUS_CHIP_TYPES,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
  NOTIFICATION_MESSAGE_TYPES,
  NOTIFICATION_MESSAGE_SIZES,
  TYPOGRAPHY_TYPES,
  ALERT_TYPES,
  EDITABLE_TABLE_CELL_TYPES,
} = FB_UI_CONSTANTS;

export const SORT_BY_METHODS = {
  ASC: 'asc',
  DESC: 'desc',
};

// Order statuses from api
export const PENDING_ORDER = 'PENDING';
export const COMPLETED_ORDER = 'COMPLETED';
export const CANCELLED_ORDER = 'CANCELLED';
export const PROCESSING_ORDER = 'PROCESSING';
export const ALL = 'ALL';

export const ORDER_STATUSES = {
  ALL_STATUSES: 'ALL STATUSES',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  PROCESSING: 'PROCESSING',
};

export const ORDER_TYPES = {
  REGULAR: 'REGULAR',
  TOPUP: 'TOPUP',
  STANDING: 'STANDING',
};

export const MAX_NUMBER_OF_LOSS_LEADER_PRODUCTS = 10;

export const DELIVERY_DOCKET_VALID_STATUSES = [ORDER_STATUSES.PENDING, ORDER_STATUSES.COMPLETED];

export const NOTIFICATION_TYPES = {
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
};

export const SPECIAL_STATUSES = {
  ACTIVE: 'active',
  SCHEDULED: 'scheduled',
  ARCHIVED: 'archived',
};

export const NETWORK_STATUS_NOTIFICATION_TARGET_GROUP = 'NETWORK_STATUS';
export const EXPIRED_SESSION_NOTIFICATION_TARGET_GROUP = 'EXPIRED_SESSION';
export const BULK_DOWNLOAD_COUNT_NOTIFICATION_TARGET_GROUP = 'BULK_COUNT';
export const DOWNLOAD_IN_PROGRESS_TARGET_GROUP = 'DOWNLOAD_IN_PROGRESS';
export const CART_NOTIFICATION_TARGET_GROUP = 'CART_NOTIFICATION';
export const UNAUTHORIZED_NOTIFICATION_TARGET_GROUP = 'UNAUTHORIZED_NOTIFICATION';
export const ZIP_NOTIFICATION_TARGET_GROUP = 'ZIP_NOTIFICATION';
export const PRODUCT_UNAVAILABLE_TARGET_GROUP = 'PRODUCT_UNAVAILABLE_TARGET_GROUP';
export const CRITICAL_DATA_ERROR_TARGET_GROUP = 'CRITICAL_DATA_ERROR_TARGET_GROUP';
export const MAINTENANCE_MODE_ERROR_TARGET_GROUP = 'MAINTENANCE_MODE_ERROR_TARGET_GROUP';
export const API_RETRY_STATUS_NOTIFICATION_TARGET_GROUP = 'API_RETRY_STATUS_NOTIFICATION_TARGET_GROUP';

export const API_METHODS = {
  PATCH: 'patch',
  POST: 'post',
  DELETE: 'delete',
};

export const ENABLED_CUSTOMER_STATUS = 1;
export const ACTIVE_VENUE_STATUS = 'active';
export const PERSONAL_VENUE_TYPE = 'Personal use';

export const SUPPLIER_CONTACT_TYPE_IDS = {
  SUPPORT: 1,
  REMITTANCE: 2,
  ADDITIONAL_ORDER_RECIPIENT: 3,
  REVIEWS: 4,
  AFTER_HOURS: 5,
  OWNER: 6,
  NEW_CUSTOMER_SETUP: 7,
  UPDATED_DETAILS_RECIPIENT: 8,
};

export const DOWNLOAD_PENDING_CSV_ENABLED_SUPPLIERS = {
  FRUITIQUE: 206,
  SCICLUNAS: 426,
  SIMPLY_FRESH_VIC: 448,
  TASSIE_FINE_FOODS: 557,
  HANSA: 489,
  AQUANAS_FOODS: 596,
  SEA_KINGS: 449,
};

export const DOWNLOAD_ORDER_PRODUCTS_FOR_DELIVERY_DAY_CSV = {
  LUXE: 240,
};

export const FB_API_VERSION = 'fb-api-version';

export const PDF_DOCUMENT_TYPES = {
  PURCHASE_ORDER: 'purchase-order',
  DELIVERY_SLIP: 'delivery-slip',
  INVOICE: 'invoice',
};

export const MOMENT_FORMATS = {
  SIMPLE_DATE: 'D MMM YYYY',
  DATE_TIME_SECONDS: 'DD MMM YYYY HH:mm:ss',
};

export const DATE_FNS_FORMATS = {
  DB_DATE_FORMAT: "yyyy-MM-dd'T'HH:mm:ssxxx",
  DATE_TIME_SECONDS: 'd MMM yyyy H:mm:ss',
  DATE: 'd MMM yyyy',
};

export const ENVIRONMENT = {
  PRODUCTION: 'production',
};

export const VIEW_BY_SUBURB = 'suburb';

export const STATE_TO_TIMEZONE = {
  'NSW / ACT': 'Australia/Sydney',
  VIC: 'Australia/Melbourne',
  QLD: 'Australia/Brisbane',
  SA: 'Australia/Adelaide',
  NT: 'Australia/Darwin',
  WA: 'Australia/Perth',
  TAS: 'Australia/Hobart',
};

export const TIMEZONE_OPTIONS = Object.values(STATE_TO_TIMEZONE).map((value) => ({
  id: value,
  label: presentTimeZone(value),
  value,
}));

export const CUSTOMER_ALERT_TYPES = {
  NEW_CUSTOMER: 'New Customer',
  UPDATED_DETAILS: 'Updated Details',
};

export const MAINTENANCE_FLAG = 'MaintenanceModeEnabled';
export const MAINTENANCE_HEADER = 'fb-maintenance-mode-enabled';
