import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { SwipeableDrawer } from '@material-ui/core';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

import { BUTTON_VARIANTS, TYPOGRAPHY_TYPES, VIEW_BY_SUBURB } from '../../utils/Constants';
import { Button, Emoji, ErrorNotification, Typography, Paper } from '../../components/UI/FB/index';
import DeliveryZoneIndexCard from './DeliveryZoneIndexCard/DeliveryZoneIndexCard';
import { DeliveryPreferencesAPI } from '../../utils/AxiosInstances';
import CreateZoneModal from './CreateZoneModal/CreateZoneModal';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import { isOnDesktop, isOniOS } from '../../utils/ScreenUtils/ScreenUtils';
import DeliveryZoneDetailView from './DeliveryZoneDetailView/DeliveryZoneDetailView';
import DeliveryZonesBySuburbView from './DeliveryZonesBySuburbView/DeliveryZonesBySuburbView';
import DeliveryZoneDetailLoadingAnimation from './DeliveryZoneDetailComponents/DeliveryZoneDetailLoadingAnimation/DeliveryZoneDetailLoadingAnimation';
import withQueryParams from '../../hoc/withQueryParams/withQueryParams';
import styles from './DeliveryZones.module.scss';

const DeliveryZones = ({
  sendDatadogError,
  getViewFromQueryString,
  updateViewQueryString,
  getZoneIdFromQueryString,
  updateZoneIdQueryParamAndClearViewBy,
}) => {
  const [selectedDeliveryZone, setSelectedDeliveryZone] = useState(undefined);
  const [createZoneModalOpen, setCreateZoneModalOpen] = useState(false);
  const [loadingDeliveryZones, setLoadingDeliveryZones] = useState(false);
  const [deliveryZones, setDeliveryZones] = useState([]);
  const [apiError, setApiError] = useState(undefined);
  const [duplicateCurrentZone, setDuplicateCurrentZone] = useState(false);

  const sortZonesByName = (zones) => zones.sort((a, b) => a.name.localeCompare(b.name));

  const iOS = isOniOS();
  const [onDesktop, setOnDesktop] = useState(isOnDesktop());
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const handleBrowserResize = () => {
    setOnDesktop(isOnDesktop());
  };

  useEffect(() => {
    window.addEventListener('resize', handleBrowserResize, false);
    return () => {
      window.removeEventListener('resize', handleBrowserResize, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setApiError(undefined);
    setLoadingDeliveryZones(true);
    DeliveryPreferencesAPI.get('/zones')
      .then((response) => {
        const zones = sortZonesByName(response.data);
        setDeliveryZones(
          zones.map((zone) => ({ ...zone, deliveryDays: zone.deliveryDays.map((day) => ({ ...day, enabled: true })) })),
        );
        const initialZone = zones.find((zone) => zone.id === getZoneIdFromQueryString());
        setSelectedDeliveryZone(initialZone || zones?.[0]);
      })
      .catch((error) => {
        sendDatadogError('Unable to load delivery zones', {
          error,
          location: 'Delivery Zones Page',
        });
        setApiError(
          <ErrorNotification
            className={styles.APIError}
            body={
              <span>
                We&apos;re unable to load your delivery zones. Don&apos;t worry, our engineers have been notified!
              </span>
            }
          />,
        );
      })
      .finally(() => {
        setLoadingDeliveryZones(false);
      });
    // eslint-disable-next-line
  }, [sendDatadogError]);

  const openCreateZoneModal = () => {
    setMobileDrawerOpen(false);
    setCreateZoneModalOpen(true);
  };

  const openDuplicateZoneModal = () => {
    setMobileDrawerOpen(false);
    setDuplicateCurrentZone(true);
    openCreateZoneModal();
  };

  const closeCreateZoneModal = () => {
    setDuplicateCurrentZone(false);
    setCreateZoneModalOpen(false);
  };

  const handleSelectDeliveryZone = (zone) => {
    setMobileDrawerOpen(false);
    setSelectedDeliveryZone(zone);
    updateZoneIdQueryParamAndClearViewBy(zone?.id);
  };

  const isViewingBySuburb = () => getViewFromQueryString() === VIEW_BY_SUBURB;

  const toggleViewBySuburb = () => {
    setMobileDrawerOpen(false);
    updateViewQueryString(isViewingBySuburb() ? undefined : VIEW_BY_SUBURB);
  };

  const handleZoneCreated = (newDeliveryZone) => {
    const updatedDeliveryZones = sortZonesByName(deliveryZones.concat([newDeliveryZone]));
    setDeliveryZones(updatedDeliveryZones);
    handleSelectDeliveryZone(newDeliveryZone);
    closeCreateZoneModal();
  };

  const handleZoneUpdated = (updatedDeliveryZone) => {
    const deliveryZonesCopy = deliveryZones;
    const indexOfZoneToUpdated = deliveryZonesCopy.findIndex(
      (deliveryZone) => deliveryZone.id === updatedDeliveryZone.id,
    );
    deliveryZonesCopy[indexOfZoneToUpdated] = updatedDeliveryZone;
    setDeliveryZones(deliveryZonesCopy);
    setSelectedDeliveryZone(updatedDeliveryZone);
  };

  const handleZoneDeleted = (deletedDeliveryZoneId) => {
    const updatedDeliveryZones = deliveryZones.filter((deliveryZone) => deliveryZone.id !== deletedDeliveryZoneId);
    setDeliveryZones(updatedDeliveryZones);
    handleSelectDeliveryZone(updatedDeliveryZones?.[0]);
  };

  const drawerContent = (
    <div className={styles.DeliveryZones__NavigationPanel}>
      <div className={styles.NavigationPanel__heading}>
        <Emoji content="🚚" label="truck" className={styles.HeadingEmoji} />
        &nbsp;
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XXL} className={styles.HeadingText}>
          Delivery Zones
          {deliveryZones?.length ? <React.Fragment>&nbsp;({deliveryZones.length})</React.Fragment> : null}
        </Typography>
      </div>
      <div className={styles.ActionBar}>
        <Button variant={BUTTON_VARIANTS.SECONDARY} className={styles.CreateZoneBtn} onClick={openCreateZoneModal}>
          <AddIcon />
          Create Zone
        </Button>
        <Button
          variant={isViewingBySuburb() ? BUTTON_VARIANTS.SUCCESS : BUTTON_VARIANTS.SECONDARY}
          onClick={toggleViewBySuburb}
          className={styles.BySuburbBtn}
        >
          <SearchIcon />
          By Suburb
        </Button>
      </div>
      <div className={styles.NavigationPanel__ZoneIndex}>
        {loadingDeliveryZones ? (
          <React.Fragment>
            {[...Array(4)].map((e, i) => (
              <div className={styles.ContentLoaderContainer} key={`loading-drawer-${i}`}>
                <ContentLoader
                  speed={3}
                  width={360}
                  height={122}
                  viewBox="0 0 360 122"
                  backgroundColor="#f7f7f7"
                  foregroundColor="#fdfdfd"
                >
                  <rect x="0" y="0" rx="3" ry="3" width="360" height="10" />
                  <rect x="0" y="0" rx="3" ry="3" width="10" height="122" />
                  <rect x="16" y="30" rx="3" ry="3" width="180" height="32" />
                  <rect x="16" y="82" rx="3" ry="3" width="140" height="20" />
                  <rect x="166" y="82" rx="3" ry="3" width="40" height="20" />
                  <rect x="216" y="82" rx="3" ry="3" width="40" height="20" />
                  <rect x="266" y="82" rx="3" ry="3" width="70" height="20" />
                  <rect x="350" y="0" rx="3" ry="3" width="10" height="122" />
                  <rect x="0" y="112" rx="3" ry="3" width="360" height="10" />
                </ContentLoader>
              </div>
            ))}
          </React.Fragment>
        ) : (
          <div className={styles.DeliveryZoneIndexCardsContainer}>
            {deliveryZones?.map((deliveryZone) => (
              <DeliveryZoneIndexCard
                key={`${deliveryZone.id}_indexCard`}
                deliveryZone={deliveryZone}
                selected={!isViewingBySuburb() && selectedDeliveryZone && selectedDeliveryZone?.id === deliveryZone.id}
                handleSelectZone={() => handleSelectDeliveryZone(deliveryZone)}
              />
            ))}
            {apiError}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className={styles.DeliveryZones}>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={onDesktop || mobileDrawerOpen}
        anchor="left"
        onOpen={() => setMobileDrawerOpen(true)}
        onClose={() => setMobileDrawerOpen(false)}
        variant={onDesktop ? 'permanent' : 'temporary'}
        classes={{
          root: styles.MobileMenuDrawerWrapper,
          paper: styles.MobileMenuDrawerPaper,
          modal: styles.MobileMenuDrawerBackdrop,
        }}
      >
        {drawerContent}
      </SwipeableDrawer>
      {isViewingBySuburb() ? (
        <DeliveryZonesBySuburbView
          onOpenMobileMenu={() => setMobileDrawerOpen(true)}
          displayShowMobileMenuBtn={!onDesktop}
          handleShowDeliveryZone={handleSelectDeliveryZone}
        />
      ) : (
        <React.Fragment>
          {loadingDeliveryZones ? (
            <DeliveryZoneDetailLoadingAnimation />
          ) : (
            <React.Fragment>
              {selectedDeliveryZone?.id ? (
                <DeliveryZoneDetailView
                  initialDeliveryZoneData={selectedDeliveryZone}
                  handleOpenDuplicateZoneDialog={openDuplicateZoneModal}
                  handleZoneDeleted={handleZoneDeleted}
                  handleZoneUpdated={handleZoneUpdated}
                  onOpenMobileMenu={() => setMobileDrawerOpen(true)}
                  displayShowMobileMenuBtn={!onDesktop}
                />
              ) : (
                <div className={styles.DeliveryZonesInfoPaper__Container}>
                  <Paper className={styles.DeliveryZonesInfoPaper}>
                    <div className={styles.DeliveryZonesInfoPaper__Logo}></div>
                    <Typography className={styles.DeliveryZonesInfoPaper__Title} type={TYPOGRAPHY_TYPES.HEADING_L}>
                      Welcome to your delivery zones
                    </Typography>
                    <Typography type={TYPOGRAPHY_TYPES.BODY}>
                      This is where you can set up your delivery zones.
                    </Typography>
                    <Typography type={TYPOGRAPHY_TYPES.BODY}>
                      Navigate through your existing zones in the panel on the left or create new ones.
                    </Typography>
                    <div className={styles.DeliveryZonesInfoPaper__Actions}>
                      <Button
                        variant={BUTTON_VARIANTS.SECONDARY}
                        className={styles.CreateZoneBtn}
                        onClick={openCreateZoneModal}
                      >
                        <AddIcon />
                        Create New Zone
                      </Button>
                    </div>
                  </Paper>
                </div>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {createZoneModalOpen && (
        <CreateZoneModal
          isOpen={createZoneModalOpen}
          handleClose={closeCreateZoneModal}
          handleZoneCreated={handleZoneCreated}
          existingDeliveryZone={duplicateCurrentZone ? selectedDeliveryZone : undefined}
          currentZoneNames={deliveryZones.map((zone) => zone.name)}
        />
      )}
    </div>
  );
};

DeliveryZones.propTypes = {
  sendDatadogError: PropTypes.func.isRequired,
  getViewFromQueryString: PropTypes.func.isRequired,
  updateViewQueryString: PropTypes.func.isRequired,
  getZoneIdFromQueryString: PropTypes.func.isRequired,
  updateZoneIdQueryParamAndClearViewBy: PropTypes.func.isRequired,
};

export default withQueryParams(withErrorReports(DeliveryZones));
