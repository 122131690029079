import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';

import Products from './Products';
import Product from './Product';
import AddOrEditMultipleProducts from './AddOrEditMultipleProducts';
import { API_METHODS } from '../../utils/Constants';
import styles from './ProductRoutes.module.scss';

class ProductRoutes extends Component {
  render() {
    const { match } = this.props;

    return (
      <div className={styles.ProductRoutesContainer}>
        <Switch>
          <Route
            exact
            path={`${match.path}/add-multiple-products`}
            render={() => <AddOrEditMultipleProducts addProductsMode={true} />}
          />
          <Route
            exact
            path={`${match.path}/edit-multiple-products`}
            render={() => <AddOrEditMultipleProducts addProductsMode={false} />}
          />
          <Route exact path={`${match.path}/new`} render={() => <Product method={API_METHODS.POST} />} />
          <Route exact path={`${match.path}/:id`} render={() => <Product method={API_METHODS.PATCH} />} />
          <Route exact path={`${match.path}`} component={Products} />
        </Switch>
      </div>
    );
  }
}

ProductRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(ProductRoutes);
