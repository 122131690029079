import * as actionTypes from './ActionTypes';
import { fetchImageGallery } from '../ReduxUtilities';

export const receiveImages = (images) => ({
  type: actionTypes.RECEIVE_IMAGES,
  payload: {
    images,
  },
});

// thunk
export const handleReceiveImages = () => (dispatch) => {
  fetchImageGallery().then((images) => dispatch(receiveImages(images)));
};

export default handleReceiveImages;
