import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import StarRatings from 'react-star-ratings';

import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import ReviewDialog from './ReviewDialog/ReviewDialog';
import { ErrorNotification, SearchBar, Emoji, Typography, Paper, Table, Button } from '../../components/UI/FB';
import { SORT_BY_METHODS, TYPOGRAPHY_TYPES, BUTTON_SIZES, BUTTON_VARIANTS } from '../../utils/Constants';
import withQueryParams from '../../hoc/withQueryParams/withQueryParams';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import styles from './Reviews.module.scss';

import { FoodbombAPI } from '../../utils/AxiosInstances';

const REVIEW_API_FIELDS = {
  id: {
    endpointKey: 'id',
    searchable: true,
  },
  average: {
    endpointKey: 'average',
  },
  venueName: {
    endpointKey: 'venueName',
    searchable: true,
  },
  isArchived: {
    endpointKey: 'isArchived',
  },
  createdAt: {
    endpointKey: 'createdAt',
  },
  content: {
    endpointKey: 'content',
  },
  supplierReply: {
    endpointKey: 'supplierReply',
  },
};

const Reviews = ({
  getQueryFromQueryString,
  updateSearchQueryParam,
  sendDatadogError,
  getPageNumberQueryFromQueryString,
  updatePageNumberQueryParam,
}) => {
  const mapValueToSortByToAPIField = (valueToSortBy) => REVIEW_API_FIELDS[valueToSortBy].endpointKey;

  const [reviews, setReviews] = useState([]);
  const [searchQuery, setSearchQuery] = useState(getQueryFromQueryString() || '');
  const [loadingReviews, setLoadingReviews] = useState(true);
  const [shouldShowSearchBar, setShouldShowSearchBar] = useState(false);
  const [APIError, setAPIError] = useState(undefined);

  // Server Side Filtering of table
  const [pageSize, setPageSize] = useState(100);
  const [reviewsResultsLength, setReviewsResultsLength] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(getPageNumberQueryFromQueryString() || 1);
  const [orderBy, setOrderBy] = useState(mapValueToSortByToAPIField('createdAt'));
  const [sortBy, setSortBy] = useState(SORT_BY_METHODS.DESC);
  const [apiSearchQuery, setApiSearchQuery] = useState(getQueryFromQueryString() || '');

  // review dialog
  const [selectedReviewIdForDialog, setSelectedReviewIdForDialog] = useState(undefined);

  const handleCloseReviewDialog = () => setSelectedReviewIdForDialog(undefined);

  const clearSearch = () => {
    setSearchQuery('');
    setApiSearchQuery('');
    updateSearchQueryParam('');
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    updateSearchQueryParam(searchQuery);
    setApiSearchQuery(searchQuery);
    setCurrentPageNumber(1);
  };

  const handleColumnHeaderClick = (valueToSortBy, shouldSortByMin) => {
    setOrderBy(mapValueToSortByToAPIField(valueToSortBy));
    setSortBy(shouldSortByMin ? SORT_BY_METHODS.ASC : SORT_BY_METHODS.DESC);
  };

  const onNextPageClick = () => {
    setCurrentPageNumber((prev) => {
      const newPageNumber = prev + 1;
      updatePageNumberQueryParam(newPageNumber);
      return newPageNumber;
    });
  };

  const onPreviousPageClick = () => {
    setCurrentPageNumber((prev) => {
      const newPageNumber = prev - 1;
      updatePageNumberQueryParam(newPageNumber);
      return newPageNumber;
    });
  };

  const reviewsColumnSpecs = [
    {
      index: 0,
      title: 'ID',
      mobileText: 'ID',
      sortMethod: 'id',
      className: styles.ID,
      columnDataFunction: (review) => review.id,
      searchable: true,
    },
    {
      index: 1,
      title: 'Rating',
      mobileText: 'Rating',
      sortMethod: 'average',
      className: styles.Rating,
      columnDataFunction: (review) => review.average,
      // eslint-disable-next-line react/display-name
      getChild: (review) => (
        <StarRatings
          rating={parseFloat(review?.average || 0)}
          starDimension="15px"
          starSpacing="0px"
          starRatedColor="#ffcc00"
          starEmptyColor="#C5C5C5"
        />
      ),
      searchable: false,
    },
    {
      index: 2,
      title: 'Review',
      mobileText: 'Review',
      sortMethod: 'content',
      className: [styles.Review, styles.TruncatedText].join(' '),
      columnDataFunction: (review) => review.content || '-',
      searchable: false,
    },
    {
      index: 3,
      title: 'Customer',
      mobileText: 'Customer',
      sortMethod: 'venueName',
      className: styles.Customer,
      columnDataFunction: (review) => review.venueName,
      searchable: true,
    },
    {
      index: 4,
      title: 'Date Created',
      mobileText: 'Date',
      sortMethod: 'createdAt',
      className: styles.CreatedAt,
      columnDataFunction: (review) => Moment(review.createdAt).format('D MMM YYYY | LT'),
      searchable: false,
    },
    {
      index: 5,
      title: 'Your Reply',
      mobileText: 'Reply',
      sortMethod: 'supplierReply',
      className: [styles.Reply, styles.TruncatedText].join(' '),
      columnDataFunction: (review) => review.supplierReply || '-',
      searchable: false,
    },
  ];

  const fetchReviews = useCallback(() => {
    setLoadingReviews(true);
    setAPIError(undefined);

    const fields = Object.values(REVIEW_API_FIELDS)
      .map((field) => field.endpointKey)
      .join(';');

    const fuzzyFields = Object.values(REVIEW_API_FIELDS)
      .filter((field) => field.searchable)
      .map((field) => `${field.endpointKey};`)
      .join('');

    const fuzzySearch = apiSearchQuery;

    const endpointURL = `/suppliers/reviews?pageSize=${pageSize}&page=${currentPageNumber}&filter=${fields}&orderBy=${orderBy}&sortedBy=${sortBy}&fuzzyFields=${fuzzyFields}&fuzzy=${fuzzySearch}`;

    FoodbombAPI.get(endpointURL)
      .then((response) => {
        setReviews(response.data.data);

        setPageSize(response.data.perPage);
        setReviewsResultsLength(response.data.total);
        setCurrentPageNumber(response.data.currentPage);
        updatePageNumberQueryParam(response.data.currentPage);
        setShouldShowSearchBar(true);
      })
      .catch((error) => {
        sendDatadogError('Unable to load reviews', {
          error,
          location: 'Reviews index page',
        });
        setAPIError(
          <ErrorNotification
            actions={
              <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={() => window.location.reload(true)}>
                Reload this page
              </Button>
            }
          />,
        );
      })
      .finally(() => {
        setLoadingReviews(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, orderBy, sortBy, pageSize, sendDatadogError, apiSearchQuery]);

  // When first loading this page, update the search query
  useEffect(() => {
    fetchReviews();
  }, [fetchReviews]);

  const handleRowClick = (review) => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 768) {
      setSelectedReviewIdForDialog(review.id);
    }
  };

  const createRowActions = (review) => (
    <React.Fragment>
      <Button
        onClick={() => setSelectedReviewIdForDialog(review.id)}
        className={styles.TableBtn}
        variant={BUTTON_VARIANTS.PRIMARY}
        size={BUTTON_SIZES.EXTRA_SMALL}
      >
        View
      </Button>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div>
        <PageHeader>
          <Breadcrumbs
            currentPageTitle={
              <React.Fragment>
                <Emoji content="⭐️" label="star" />
                &nbsp;Reviews
              </React.Fragment>
            }
          />
          <Typography type={TYPOGRAPHY_TYPES.HEADING_M}>Here are all your reviews</Typography>
          <div className={styles.ActionBar}>
            <div className={styles.SearchBarContainer}>
              {shouldShowSearchBar ? (
                <SearchBar
                  placeholder="Search reviews"
                  handleSearch={handleSearch}
                  onChange={handleSearchChange}
                  handleClear={clearSearch}
                  value={searchQuery}
                />
              ) : null}
            </div>
            <div className={styles.ActionBar__buttonsContainer}></div>
          </div>
        </PageHeader>
        {APIError ? (
          <div className={styles.ErrorWrapper}>{APIError}</div>
        ) : (
          <React.Fragment>
            {!loadingReviews && reviewsResultsLength === 0 ? (
              <div>
                <Paper className={styles.ReviewsWelcomePaper}>
                  <div className={styles.ReviewsWelcomePaper__heading}>
                    <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
                      <Emoji content="✨" label="star" />
                    </Typography>
                    <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Welcome to your reviews</Typography>
                  </div>
                  <div className={styles.ReviewsWelcomePaper__body}>
                    <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ReviewsWelcomePaperBlock}>
                      Here&apos;s where you can see all your ratings and reviews.
                    </Typography>
                    <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ReviewsWelcomePaperBlock}>
                      Please don&apos;t use this as a messaging tool.
                    </Typography>
                    <Typography
                      type={TYPOGRAPHY_TYPES.BODY}
                      className={[styles.ReviewsWelcomePaperBlock, styles.Padded].join(' ')}
                    >
                      If you want to dispute a review, please contact us at&nbsp;
                      <a className={styles.GreenLink} href={`mailto:support@foodbomb.com.au`}>
                        support@foodbomb.com.au
                      </a>
                    </Typography>
                  </div>
                </Paper>
              </div>
            ) : (
              <div className={styles.TableContainer}>
                <Table
                  className={styles.ReviewsTable}
                  columnSpecs={reviewsColumnSpecs}
                  rowData={reviews}
                  loadingRowData={loadingReviews}
                  handleRowClick={handleRowClick}
                  rowOverlayColSpan={1}
                  rowActions={createRowActions}
                  noResultsComponent={
                    <Paper className={styles.NoResultsContainer}>
                      <div className={styles.TypographySection}>
                        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
                          <span role="img" aria-label="wave">
                            👋
                          </span>
                        </Typography>
                        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Welcome to the reviews page</Typography>
                        <div className={styles.TypographySection}>
                          <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                            Here is where we&apos;ll display all of your reviews
                          </Typography>
                        </div>
                      </div>
                      <div className={styles.NoResultsActions}></div>
                    </Paper>
                  }
                  searchQuery={apiSearchQuery}
                  grow
                  paginationOverrideData={{
                    currentPageNumber,
                    onNextPageClick,
                    onPreviousPageClick,
                    pageSize,
                    numberOfResults: reviewsResultsLength,
                  }}
                  sortOverrideMethod={handleColumnHeaderClick}
                  defaultSortColumn={5}
                  sortAscending={false}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      <ReviewDialog
        isOpen={Boolean(selectedReviewIdForDialog)}
        handleClose={handleCloseReviewDialog}
        reviewId={selectedReviewIdForDialog}
        reloadAllReviews={fetchReviews}
      />
    </React.Fragment>
  );
};

Reviews.propTypes = {
  getQueryFromQueryString: PropTypes.func.isRequired,
  updateSearchQueryParam: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  getPageNumberQueryFromQueryString: PropTypes.func.isRequired,
  updatePageNumberQueryParam: PropTypes.func.isRequired,
};

export default withErrorReports(withQueryParams(Reviews));
