import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { Typography } from '../UI/FB';
import { TYPOGRAPHY_TYPES } from '../../utils/Constants';
import styles from './MultipleProductsStepper.module.scss';
import CSVProductsTable from '../CSVProductsTable/CSVProductsTable.tsx';

const StepOne = ({
  isProductIdInvalid,
  validatedProducts,
  numberOfErroredProducts,
  areProductsReadyForUpload,
  numberOfSuccessfulProducts,
  updateAndRevalidateAProduct,
  deleteProductsWithErrors,
  deleteProduct,
  categories,
  createCategories,
  loadingCreateCategories,
  categoryCreateError,
  categoriesToBeCreated,
  isStaff,
  downloadProductsWithErrors,
  unitsOfMeasure,
}) => {
  const confirm = useConfirm();

  const handleCreateCategories = () => {
    confirm({
      title: `Are you sure you wish to create ${categoriesToBeCreated.length} categories`,
      description: `The following categories will be created: ${categoriesToBeCreated.join(', ')}`,
      confirmationText: 'Create categories',
      cancellationText: 'Cancel',
      dialogProps: {
        classes: { root: styles.MultipleProductsContainer__confirmationContainer_largeText },
      },
    }).then(() => {
      createCategories();
    });
  };

  return (
    <div>
      <div className={styles.MultipleProductsContainer__confirmationContainer}>
        <div className={styles.MultipleProductsContainer__CSVFormatErrorMessageTitle}>
          {isProductIdInvalid ? (
            <p
              className={[
                styles.MultipleProductsContainer__CSVFormatErrorPosition_animation,
                styles.MultipleProductsContainer__CSVFormatErrorPosition,
              ].join(' ')}
            >
              Please ensure you do not edit your Product Id
            </p>
          ) : null}
          {categoryCreateError ? (
            <p
              className={[
                styles.MultipleProductsContainer__CSVFormatErrorPosition_animation,
                styles.MultipleProductsContainer__CSVFormatErrorPosition,
              ].join(' ')}
            >
              {categoryCreateError}
            </p>
          ) : null}
          {numberOfErroredProducts ? (
            <React.Fragment>
              <p
                className={[
                  styles.MultipleProductsContainer__CSVFormatErrorPosition_animation,
                  styles.MultipleProductsContainer__CSVFormatErrorPosition,
                ].join(' ')}
              >
                {numberOfErroredProducts} of your {validatedProducts.length} product
                {validatedProducts.length > 1 ? 's' : ''} have errors
              </p>
              <div className={styles.MultipleProductsContainer_ErrorBtns}>
                <Button
                  variant="outlined"
                  className={styles.MultipleProductsContainer__confirmationContainer__btn}
                  onClick={deleteProductsWithErrors}
                >
                  Ignore products with errors
                </Button>

                <Button
                  variant="outlined"
                  aria-label="download products with errors"
                  className={styles.MultipleProductsContainer__confirmationContainer__btn}
                  onClick={downloadProductsWithErrors}
                >
                  Download products with errors
                </Button>

                {isStaff ? (
                  <div className={styles.MultipleProductsContainer__confirmationContainer_CreateCategoriesWrapper}>
                    <Button
                      variant="outlined"
                      aria-label="create categories"
                      className={styles.MultipleProductsContainer__confirmationContainer__btn}
                      onClick={handleCreateCategories}
                    >
                      {loadingCreateCategories ? (
                        <CircularProgress
                          classess={{ root: styles.MultipleProductsContainer__Spinner_white }}
                          size={25}
                          thickness={3}
                        />
                      ) : (
                        <React.Fragment>Create Categories</React.Fragment>
                      )}
                    </Button>
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          ) : null}
        </div>

        {areProductsReadyForUpload ? (
          <div className={styles.MultipleProductsContainer__text_foodBombGreen_bold}>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_M}>
              You have {numberOfSuccessfulProducts} product{numberOfSuccessfulProducts > 1 ? 's' : ''} ready to be
              saved!
            </Typography>
          </div>
        ) : null}
      </div>

      <div className={styles.MultipleProductsContainer__productsTableContainer}>
        <CSVProductsTable
          validatedProducts={validatedProducts}
          isLoading={true}
          updateAndRevalidateAProduct={updateAndRevalidateAProduct}
          deleteProduct={deleteProduct}
          categories={categories}
          unitsOfMeasure={unitsOfMeasure}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isStaff: state.auth.supplierDetails ? state.auth.supplierDetails.isStaff : false,
});

StepOne.propTypes = {
  isProductIdInvalid: PropTypes.bool,
  validatedProducts: PropTypes.array,
  numberOfErroredProducts: PropTypes.number,
  areProductsReadyForUpload: PropTypes.bool,
  numberOfSuccessfulProducts: PropTypes.number,
  updateAndRevalidateAProduct: PropTypes.func.isRequired,
  deleteProductsWithErrors: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  createCategories: PropTypes.func.isRequired,
  loadingCreateCategories: PropTypes.bool.isRequired,
  categoryCreateError: PropTypes.string,
  categoriesToBeCreated: PropTypes.array.isRequired,
  isStaff: PropTypes.bool.isRequired,
  downloadProductsWithErrors: PropTypes.func.isRequired,
  unitsOfMeasure: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, null)(StepOne);
