import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import { debounce } from 'lodash';
import { CircularProgress, Dialog, IconButton } from '@material-ui/core';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import { Button, Typography, FormikSingleSelectField } from '../../UI/FB';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import { CreateNotification, SendErrorReportType } from '../../../utils/Presenters/ReduxType';
import { LossLeaderVenuesForSelect } from '../../../containers/LossLeaders/LossLeaderTypes';
import styles from './LossLeadersVenues.module.scss';

type FormValueType = {
  venueId: string;
};

type LossLeaderVeneusModalTypes = {
  isOpen: boolean;
  onClose: () => void;
  sendDatadogError: SendErrorReportType;
  createNotification: CreateNotification;
  onSuccessfulLossVenuesAPICall: () => void;
  setLoadingLossLeaderVenues: (val: boolean) => void;
  loadingLossLeaderVenues: boolean;
  venues: LossLeaderVenuesForSelect[];
};

const LossLeaderVenuesModal: FC<LossLeaderVeneusModalTypes> = ({
  isOpen,
  onClose,
  sendDatadogError,
  onSuccessfulLossVenuesAPICall,
  createNotification,
  setLoadingLossLeaderVenues,
  loadingLossLeaderVenues,
  venues,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const searchArrayByLabel = (query: string, data: LossLeaderVenuesForSelect[]) => {
    const numberOfResults = 15;

    if (query) {
      return data
        ?.filter((c: LossLeaderVenuesForSelect | any) => c?.label.toLowerCase().includes(query.toLowerCase()))
        ?.slice(0, numberOfResults);
    }
    return data?.slice(0, numberOfResults);
  };

  const filterVenuesBySearch = (query: string) => searchArrayByLabel(query, venues);

  const lossLeaderVenueValidationSchema = Yup.object().shape({
    venueId: Yup.number().required('Venue is required'),
  });

  const handleClose = () => {
    setErrorMessage(undefined);
    onClose();
  };
  const handleSubmit = (values: FormValueType, actions: FormikHelpers<FormValueType>) => {
    setErrorMessage(undefined);
    setLoadingLossLeaderVenues(true);
    FoodbombAPI.post(`suppliers/loss-venues/${values.venueId}`)
      .then(() => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully created loss leader venues',
          timeout: 4000,
          closable: true,
        });
        onSuccessfulLossVenuesAPICall();
        onClose();
      })
      .catch((error: any) => {
        if (error && error.response?.data && error.response?.data?.errors) {
          setErrorMessage(error.response?.data.errors[0]?.message);
          setLoadingLossLeaderVenues(false);
        }
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to create loss leader venues',
          timeout: 4000,
          closable: true,
        });
        sendDatadogError('Unable to create loss leader venues', {
          error,
          location: 'Loss leader venues modal',
        });
      })
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ root: styles.LossLeaderVenuesDialogRoot, paper: styles.LossLeaderVenuesDialogPaper }}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ venueId: '' }}
        validationSchema={lossLeaderVenueValidationSchema}
      >
        {({ errors, touched, isSubmitting, values, setTouched, setFieldValue }) => (
          <Form>
            <div className={styles.LossLeaderVenuesDialogHeader}>
              <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Add venue</Typography>
              <IconButton
                type="button"
                className={styles.LossLeaderVenuesDialog__closeBtn}
                aria-label="close"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className={styles.LossLeaderVenuesDialogContent}>
              <Typography className={styles.LossLeaderVenuesDialogContentText} type={TYPOGRAPHY_TYPES.BODY}>
                Select a venue you wish to apply the order threshold to.
              </Typography>
              <Typography type={TYPOGRAPHY_TYPES.BODY}>
                Once added, this venue will only be able to purchase your loss leader products once they have met your
                threshold.
              </Typography>
            </div>
            <div className={styles.LossLeaderVenuesDialogSelectedField}>
              <FormikSingleSelectField
                isMulti={false}
                value={venues?.find((venue: LossLeaderVenuesForSelect) => venue.id === parseInt(values.venueId, 10))}
                errors={errors}
                touched={touched}
                setTouched={setTouched}
                label="Venue"
                setFieldValue={setFieldValue}
                fieldName="venueId"
                cacheOptions
                defaultOptions
                loadOptions={debounce(filterVenuesBySearch, 250)}
                placeholder={venues ? 'Search by venue name or ID' : 'Loading Venues...'}
                loadingMessage="Loading Venues..."
                maxMenuHeight={220}
                options={venues}
                hideErrors
                isDisabled={!venues}
              />
              <div className={styles.ErrorMessageContainer}>
                {errors.venueId && !loadingLossLeaderVenues ? <ErrorMessage name="venueId" /> : null}
                {errorMessage}
              </div>
            </div>
            <div className={styles.LossLeaderVenuesDialogAction}>
              <Button
                type="submit"
                className={styles.LossLeaderVenuesModalButton}
                disabled={Boolean(errors && Object.keys(errors).length) || isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                  </>
                ) : (
                  <>Save</>
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

LossLeaderVenuesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  onSuccessfulLossVenuesAPICall: PropTypes.func.isRequired,
  setLoadingLossLeaderVenues: PropTypes.func.isRequired,
  loadingLossLeaderVenues: PropTypes.bool.isRequired,
  venues: PropTypes.array.isRequired,
};

export default LossLeaderVenuesModal;
