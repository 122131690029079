import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import { Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import SwitchField from '../../../../components/UI/SwitchField/SwitchField';
import { Typography, Emoji, FormikSingleSelectField, Button, ErrorNotification } from '../../../../components/UI/FB';
import withNotifications from '../../../../hoc/withNotifications/withNotifications';
import withErrorReports from '../../../../hoc/withErrorReports/withErrorReports';
import { DeliveryPreferencesAPI } from '../../../../utils/AxiosInstances';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES, BUTTON_VARIANTS, BUTTON_SIZES } from '../../../../utils/Constants';
import { getDayBeforeDay, DAYS, DAYS_OPTIONS } from '../../../../utils/TimeConstants';

import styles from './EditDeliveryDaysModal.module.scss';

const EditDeliveryDaysModal = ({
  deliveryZone,
  createNotification,
  sendDatadogError,
  refetchZoneData,
  handleClose,
  isOpen,
}) => {
  const formatErrorsFromApiResponse = (requestData, errorData) => {
    const formErrors = {};
    requestData.forEach((deliveryDayObj, idx) => {
      const currentWeekDay = [deliveryDayObj.deliveryDay];

      formErrors[currentWeekDay] = errorData[idx];

      if (formErrors[currentWeekDay]?.[currentWeekDay]) {
        formErrors[currentWeekDay].enabled = errorData[idx][currentWeekDay];
        delete formErrors[currentWeekDay][currentWeekDay];
      }
    });

    return formErrors;
  };

  const handleUpdateDeliveryDays = (values, actions) => {
    const deliveryDayValues = Object.values(values).filter((days) => days.enabled);
    DeliveryPreferencesAPI.put(`zones/${deliveryZone.id}/days`, deliveryDayValues)
      .then(() => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully updated delivery days',
          timeout: 4000,
          closable: true,
        });
        refetchZoneData();
        handleClose();
      })
      .catch((error) => {
        actions.setSubmitting(false);
        if (error?.response?.status === 400 && error?.response?.data) {
          if (Object.keys(error?.response?.data).includes('invalidActivation')) {
            createNotification({
              type: NOTIFICATION_TYPES.ERROR,
              content: error.response.data.invalidActivation,
              timeout: 4000,
              closable: true,
            });
          } else {
            const formErrors = formatErrorsFromApiResponse(error.response.data);
            actions.setErrors(formErrors);
          }
        } else {
          sendDatadogError('Unable to update delivery zone days', {
            error,
            location: 'Edit Delivery Zone Days Modal',
          });
          actions.setStatus({
            apiError: (
              <ErrorNotification
                body={'We were unable to update the delivery days for this zone. Our engineers have been notified!'}
              />
            ),
          });
        }
      });
  };

  const buildInitialFormValues = () => {
    const values = {};
    deliveryZone.deliveryDays.forEach((day) => {
      values[day.deliveryDay] = {
        enabled: Boolean(day.newCustomerOrderBy && day.existingCustomerOrderBy),
        blocked: day.blocked,
        deliveryDay: day.deliveryDay,
        newCustomerOrderBy: day.newCustomerOrderBy || getDayBeforeDay(day.deliveryDay),
        existingCustomerOrderBy: day.existingCustomerOrderBy || getDayBeforeDay(day.deliveryDay),
      };
    });
    return values;
  };

  const buildDayValidations = () => {
    const validations = {};
    deliveryZone.deliveryDays.forEach((day) => {
      validations[day.deliveryDay] = Yup.object({
        newCustomerOrderBy: Yup.mixed().test('day-validator', 'Please select a valid day', function validateDay(value) {
          if (!this.parent.enabled) {
            return true;
          }
          return value && DAYS.includes(value);
        }),
        existingCustomerOrderBy: Yup.mixed().test(
          'day-validator',
          'Please select a valid day',
          function validateDay(value) {
            if (!this.parent.enabled) {
              return true;
            }
            return value && DAYS.includes(value);
          },
        ),
      });
    });
    return Yup.object(validations);
  };

  return (
    <Dialog
      disableBackdropClick
      open={isOpen}
      keepMounted={false}
      onClose={handleClose}
      classes={{ root: styles.EditDeliveryDaysModal__root, paper: styles.EditDeliveryDaysModal__paper }}
    >
      <Formik
        initialValues={buildInitialFormValues()}
        validationSchema={buildDayValidations()}
        onSubmit={handleUpdateDeliveryDays}
        initialStatus={{
          apiError: undefined,
        }}
      >
        {({ errors, touched, values, status, setFieldValue, setTouched, isSubmitting, setFieldTouched }) => (
          <Form>
            <div className={styles.EditDeliveryDaysModal__header}>
              <Typography type={TYPOGRAPHY_TYPES.HEADING_XL} styles={styles.EditDeliveryDaysModal__title}>
                <Emoji content="✏️" label="pencil" />
                &nbsp;Edit Delivery Days
              </Typography>
              <div className={styles.EditDeliveryDaysModalHeader__actions}>
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTON_SIZES.SMALL}
                  onClick={handleClose}
                  type="button"
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTON_SIZES.SMALL}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress thickness={3} size={24} className={styles.ButtonLoadingSpinner} />
                  ) : (
                    <React.Fragment>Save</React.Fragment>
                  )}
                </Button>
              </div>
            </div>
            <DialogContent className={styles.EditDeliveryDaysModal__content}>
              <table className={styles.DeliveryDaysTable}>
                <thead>
                  <tr>
                    <th className={styles.PrimaryHeading}>Delivery Days</th>
                    <th className={styles.PrimaryHeading}>New Customers</th>
                    <th className={styles.PrimaryHeading}>Existing Customers</th>
                  </tr>
                  <tr>
                    <th className={styles.SecondaryHeading}>Deliveries for</th>
                    <th className={styles.SecondaryHeading}>Need to be placed by</th>
                    <th className={styles.SecondaryHeading}>Need to be placed by</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(values).map((dayObj) => (
                    <tr
                      key={dayObj.deliveryDay}
                      className={
                        dayObj.enabled
                          ? [styles.ContentRow, styles.active].join(' ')
                          : [styles.ContentRow, styles.inactive].join(' ')
                      }
                    >
                      <td>
                        <SwitchField
                          disabled={dayObj.blocked}
                          checked={dayObj.enabled}
                          onChange={(e) => {
                            if (!dayObj.blocked) {
                              const dayName = dayObj.deliveryDay;
                              setFieldValue(`${dayName}.enabled`, e.target.checked);
                              setFieldTouched(`${dayName}.enabled`, true);
                            }
                          }}
                          label={
                            <span
                              className={dayObj.enabled ? [styles.DayLabel, styles.active].join(' ') : styles.DayLabel}
                            >
                              {dayObj.deliveryDay}
                            </span>
                          }
                        />
                        {touched[dayObj.deliveryDay]?.enabled && errors[dayObj.deliveryDay]?.enabled ? (
                          <div className={styles.FormFieldErrorContainer}>
                            <ErrorMessage name={`${dayObj.deliveryDay}.enabled`} />
                          </div>
                        ) : null}
                      </td>
                      <td>
                        {dayObj.enabled ? (
                          <React.Fragment>
                            <FormikSingleSelectField
                              isMulti={false}
                              value={DAYS_OPTIONS.find((d) => d.id === dayObj.newCustomerOrderBy)}
                              errors={errors}
                              touched={touched}
                              setTouched={setTouched}
                              setFieldValue={setFieldValue}
                              fieldName={`${dayObj.deliveryDay}.newCustomerOrderBy`}
                              placeholder={'-'}
                              maxMenuHeight={180}
                              options={DAYS_OPTIONS}
                              hideErrors={
                                !(
                                  touched?.[dayObj.deliveryDay]?.newCustomerOrderBy &&
                                  errors?.[dayObj.deliveryDay]?.newCustomerOrderBy
                                )
                              }
                            />
                            {dayObj.newCustomerOrderBy === dayObj.deliveryDay ? (
                              <p className={styles.SameDayWarning}>
                                <strong>Warning:</strong>&nbsp;Same day delivery
                              </p>
                            ) : null}
                          </React.Fragment>
                        ) : (
                          <span className={styles.DisabledDayDash}></span>
                        )}
                      </td>
                      <td>
                        {dayObj.enabled ? (
                          <React.Fragment>
                            <FormikSingleSelectField
                              isMulti={false}
                              value={DAYS_OPTIONS.find((d) => d.id === dayObj.existingCustomerOrderBy)}
                              errors={errors}
                              touched={touched}
                              setTouched={setTouched}
                              setFieldValue={setFieldValue}
                              fieldName={`${dayObj.deliveryDay}.existingCustomerOrderBy`}
                              placeholder={'-'}
                              maxMenuHeight={180}
                              options={DAYS_OPTIONS}
                              hideErrors={
                                !(
                                  touched?.[dayObj.deliveryDay]?.existingCustomerOrderBy &&
                                  errors?.[dayObj.deliveryDay]?.existingCustomerOrderBy
                                )
                              }
                            />
                            {dayObj.existingCustomerOrderBy === dayObj.deliveryDay ? (
                              <p className={styles.SameDayWarning}>
                                <strong>Warning:</strong>&nbsp;Same day delivery
                              </p>
                            ) : null}
                          </React.Fragment>
                        ) : (
                          <span className={styles.DisabledDayDash}></span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={styles.ErrorContainer}>{status?.apiError}</div>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

EditDeliveryDaysModal.propTypes = {
  deliveryZone: PropTypes.shape({
    id: PropTypes.number.isRequired,
    deliveryDays: PropTypes.array.isRequired,
  }).isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refetchZoneData: PropTypes.func.isRequired,
};

export default withErrorReports(withNotifications(EditDeliveryDaysModal));
