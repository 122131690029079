import { FC, ElementType, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Switch } from '@material-ui/core';
import { Button, Paper, Typography } from '../../../components/UI/FB';
import { BUTTON_VARIANTS, NOTIFICATION_TYPES, TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import useDDErrorReporting from '../../../hooks/useDDErrorReporting/useDDErrorReporting';
import useNotifications from '../../../hooks/useNotifications/useNotifications';
import { ContactTypes } from '../../../utils/Presenters/Common';
import styles from '../Settings.module.scss';

type NewCustomerPreferencesCardTypes = {
  innerRef: () => void | ElementType;
  updateSupplierDetails: (data: ContactTypes) => void;
  thirdPartyDeliveryEnabled: boolean;
};
const AdminCard: FC<NewCustomerPreferencesCardTypes> = ({
  innerRef,
  updateSupplierDetails,
  thirdPartyDeliveryEnabled,
}) => {
  const { createNotification } = useNotifications();
  const { sendDatadogError } = useDDErrorReporting();
  const [buttonToggled, setButtonToggled] = useState<Boolean>(thirdPartyDeliveryEnabled);
  const [editMode, setEditMode] = useState<Boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<Boolean>(false);
  const handleUpdate3PLPreference = () => {
    setIsSubmitting(true);
    const payload = {
      third_party_delivery_enabled: buttonToggled,
    };
    FoodbombAPI.patch('suppliers/auth/me', payload)
      .then((response) => {
        updateSupplierDetails(response.data);
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Successfully ${buttonToggled ? 'enabled' : 'disabled'} 3rd party delivery information`,
          timeout: 4000,
          closable: true,
        });
        setEditMode(false);
      })
      .catch((error) => {
        if (error?.response?.status === 400 && error?.response?.data?.errors) {
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to update admin settings',
            timeout: 4000,
            closable: true,
          });
        } else {
          sendDatadogError('Unable to update admin settings', {
            error,
            location: 'Settings Page - Admin Section',
          });
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to update admin settings',
            timeout: 4000,
            closable: true,
          });
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div ref={innerRef}>
      <Paper className={styles.SettingsCard}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.SettingsCard__sectionHeading}>
          3PL Preferences
        </Typography>
        <div className={styles.NewCustomers__buttonContainer}>
          <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.buttonExplanationText}>
            Enable 3PL (3rd Party Logistics) message on Checkout&nbsp;
          </Typography>
          {editMode ? (
            <div className={styles.ToggleButton}>
              <Switch
                checked={Boolean(buttonToggled)}
                onChange={() => setButtonToggled((prev) => !prev)}
                disabled={!editMode}
                classes={{
                  root: styles.SwitchRoot,
                  colorPrimary: styles.ColorPrimary,
                  thumb: styles.ColorPrimary,
                  track: buttonToggled ? styles.ActiveColor : styles.PausedColor,
                }}
              />
            </div>
          ) : null}
        </div>
        {editMode ? (
          <>
            <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__description}>
              If you enable this setting, customers will be shown a message on checkout, informing them that the
              delivery is not handled by the supplier.
            </Typography>
            <div className={styles.SettingsCard__editBtnContainer}>
              <Button
                type="button"
                disabled={isSubmitting}
                variant={BUTTON_VARIANTS.SECONDARY}
                onClick={() => setEditMode(false)}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting} onClick={() => handleUpdate3PLPreference()}>
                {isSubmitting ? (
                  <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                ) : (
                  <>Update Details</>
                )}
              </Button>
            </div>
          </>
        ) : (
          <div className={styles.SettingsCard__editBtnContainer}>
            <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={() => setEditMode(true)} className={styles.EditBtn}>
              Edit Preferences
            </Button>
          </div>
        )}
      </Paper>
    </div>
  );
};
AdminCard.propTypes = {
  updateSupplierDetails: PropTypes.func.isRequired,
  innerRef: PropTypes.func.isRequired,
  thirdPartyDeliveryEnabled: PropTypes.bool.isRequired,
};
export default AdminCard;
