import { FoodbombAPI } from '../utils/AxiosInstances';
import * as ErrorReportHelpers from '../utils/ErrorReportHelpers/ErrorReportHelpers';

// We're going to have a lot more of these helpers
// eslint-disable-next-line import/prefer-default-export
export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export const fetchImageGallery = () =>
  FoodbombAPI.get(`suppliers/product-images`, { credentials: 'include' })
    .then((response) => response.data.data)
    .catch((error) => {
      const tags = [];
      if (error && error.response && error.response.status) {
        tags.push(error.response.status);
      }
      ErrorReportHelpers.sendError(
        error,
        'Unable to load image gallery',
        {
          error: {
            status: error?.response?.status,
            data: error?.reasponse?.data,
          },
        },
        tags,
      );
    });
