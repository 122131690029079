import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { CircularProgress, Step, StepLabel, Stepper } from '@material-ui/core';
import styles from './AddOrEditMultipleProducts.module.scss';
import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import { Button, Typography } from '../../components/UI/FB';
import { NOTIFICATION_TYPES, BUTTON_VARIANTS, TYPOGRAPHY_TYPES } from '../../utils/Constants';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import withEventTracking from '../../hoc/withEventTracking/withEventTracking';
import { validateCSVData, validateCSVFormat } from '../../utils/CSVUploadValidators/CSVUploadValidators';
import { round2DP } from '../../utils/PortionSizePricingHelper/PortionSizePricingHelper';
import { AddProductsCSVHeaders, ProductSKUCSVHeader } from '../../utils/CSVHeaders';
import StepZero from '../../components/MultipleProductsStepper/StepZero';
import StepOne from '../../components/MultipleProductsStepper/StepOne';
import StepTwo from '../../components/MultipleProductsStepper/StepTwo';
import BulkUploadProgressDialog from './BulkUploadProgressDialog/BulkUploadProgressDialog';
import {
  categoryIdToName,
  mapCategoryIdsToNames,
  mapCategoryNamesToIds,
  mapPortionUnitIdsToTitles,
  mapWeightClassDescriptionsToIds,
  sortProductsByAnyErrorFirst,
  sortProductsByCategoryOneErrorFirst,
} from '../../utils/ProductDataHelpers/ProductDataHelpers';
import { isCategoryEntered } from '../../utils/CSVUploadValidators/CSVUploadValidatorsHelpers';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';

import buildGenericErrorMessage from '../../utils/GenericErrorMessage';
import { extractProductsToUpdate } from '../../utils/AddOrEditMultipleProductsHelper.ts';

class AddOrEditMultipleProducts extends React.Component {
  state = {
    hideInstructions: true,
    isCSVStructureValid: false,
    validatedProducts: [],
    errorMessage: '',
    numberOfErroredProducts: 0,
    areProductsReadyForUpload: false,
    numberOfSuccessfulProducts: 0,
    portionUnits: [],
    activeStep: 0,
    loadingSaveProducts: false,
    displayUploadProgressDialog: false,
    incorrectHeaders: [],
    categories: [],
    loadingCSV: false,
    loadingProducts: false,
    isProductIdInvalid: false,
    loadingCreateCategories: false,
    categoryCreateError: '',
    currentProducts: [],
    loadingCategories: true,
    productsWithCustomPricing: [],
    productsOnSpecial: [],
  };

  componentDidMount() {
    this.fetchAllPortionSizes();
    this.fetchAllCategories();
    this.fetchAndStoreCurrentProducts();
  }

  fetchAllCategories = async () => {
    this.setState({ loadingCategories: true });
    FoodbombAPI.get('/categories')
      .then((response) => {
        this.setState({ categories: response.data.data, loadingCategories: false });
      })
      .catch((error) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = buildGenericErrorMessage(JSON.stringify(error.response.data));
        } else {
          errorMessage = buildGenericErrorMessage('Could not fetch categories');
        }
        this.setState({ errorMessage });
      });
  };

  fetchAllPortionSizes = () => {
    FoodbombAPI.get('portions')
      .then((response) => {
        this.setState({ portionUnits: response.data.data });
      })
      .catch((error) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = buildGenericErrorMessage(JSON.stringify(error.response.data));
        } else {
          errorMessage = buildGenericErrorMessage('Could not fetch portions');
        }
        this.setState({ errorMessage });
      });
  };

  fetchCurrentProducts = async () => {
    const { sendDatadogError } = this.props;
    try {
      const res = await FoodbombAPI.get(`suppliers/products`, { credentials: 'include' });
      return res.data.data;
    } catch (error) {
      sendDatadogError('Unable to load current products', {
        error,
        location: 'Bulk add or Edit products via CSV Page',
      });
      throw error;
    }
  };

  fetchAndStoreCurrentProducts = async () => {
    const { createNotification } = this.props;
    try {
      const products = await this.fetchCurrentProducts();
      this.setState({ currentProducts: products });
    } catch (error) {
      // Already logged to raygun in parent
      createNotification({
        type: NOTIFICATION_TYPES.ERROR,
        content: 'Failed to download current product list. Please try again.',
        timeout: 8000,
        closable: true,
      });
    }
  };

  toggleInstructions = () => {
    this.setState((state) => ({
      hideInstructions: !state.hideInstructions,
    }));
  };

  parseProductsFromCSVToUITable = (products) =>
    products.map((product) => ({
      productId: product['Product Id'],
      sku: product[ProductSKUCSVHeader],
      name: product['Product Name'],
      categoryOne: product['Category One'],
      categoryTwo: product['Category Two'],
      categoryThree: product['Category Three'],
      price: product['Price (Ex.GST)'],
      unitOfPrice: product['Price Unit'],
      portions: product.Portions,
      unitOfPortion: product['Portion Unit'],
      taxable: product['GST (Y/N)'],
      enabled: product['Enabled (Y/N)'] ? product['Enabled (Y/N)'] : null,
      outOfStock: product['Out of Stock (Y/N)'] ? product['Out of Stock (Y/N)'] : false,
    }));

  onCSVFormatError = (CSVFormatErr) => {
    this.setState({
      errorMessage: CSVFormatErr.message,
      isCSVStructureValid: false,
      validatedProducts: [],
      loadingCSV: false,
      incorrectHeaders: CSVFormatErr.path === 'headers' ? CSVFormatErr.incorrectHeaders : [],
    });

    this.props.createNotification({
      type: NOTIFICATION_TYPES.ERROR,
      content: CSVFormatErr.message,
      timeout: 3000,
      closable: true,
    });
  };

  setValidatedProductsHandler = (
    validatedProducts,
    sortMethod = sortProductsByAnyErrorFirst,
    multiProductsEval = true,
  ) => {
    validatedProducts.sort(sortMethod);
    const isProductIdInvalid = validatedProducts.some((validatedProduct) =>
      validatedProduct.productErrors.some((err) => err.path === 'productId'),
    );

    const numberOfErroredProducts = validatedProducts.filter(
      (validatedProduct) => validatedProduct.productErrors.length,
    ).length;
    const numberOfSuccessfulProducts = validatedProducts.length - numberOfErroredProducts;
    const areProductsReadyForUpload = !numberOfErroredProducts;

    let activeStep = 1;
    let notificationContent;
    let notificationType;
    if (!numberOfErroredProducts && !validatedProducts.length) {
      activeStep = 0;
      notificationContent = 'Zero products uploaded';
      notificationType = NOTIFICATION_TYPES.ERROR;
    } else if (!numberOfErroredProducts) {
      notificationContent = 'Products valid';
      notificationType = NOTIFICATION_TYPES.SUCCESS;
    } else {
      notificationContent = 'Products invalid';
      notificationType = NOTIFICATION_TYPES.ERROR;
    }
    this.setState({
      activeStep,
      errorMessage: '',
      isCSVStructureValid: true,
      validatedProducts,
      numberOfErroredProducts,
      areProductsReadyForUpload,
      numberOfSuccessfulProducts,
      loadingCSV: false,
      isProductIdInvalid,
    });

    if (multiProductsEval) {
      this.props.createNotification({
        type: notificationType,
        content: notificationContent,
        timeout: 3000,
        closable: true,
      });
    }
  };

  CSVHandlerForOnChange = (addProductsMode) => (e) => {
    this.setState({ loadingCSV: true, hideInstructions: true });
    const PapaConfig = {
      header: true,
      delimiter: ',',
      newline: '\r\n',
      dynamicTyping: (header) => header !== ProductSKUCSVHeader,
      quoteChar: '"',
      skipEmptyLines: 'greedy', // lines that don't have any content (those which have only whitespace after parsing) will be skipped
      encoding: 'utf-8',
      complete: async (results) => {
        const CSVFormatError = validateCSVFormat(results.meta.fields, results.errors, addProductsMode);
        if (CSVFormatError.path) {
          this.onCSVFormatError(CSVFormatError);
        } else {
          const parsedProducts = this.parseProductsFromCSVToUITable(results.data);
          const validatedProducts = await validateCSVData(
            parsedProducts,
            this.state.portionUnits,
            this.state.categories,
            addProductsMode,
          );
          // hack to allow to hit api with dodgey products: // validatedProducts.map(validatedProduct => (validatedProduct.productErrors = []));
          this.setValidatedProductsHandler(validatedProducts);
        }
      },
    };
    Papa.parse(e.target.files[0], PapaConfig);
    e.target.value = null;
  };

  CSVHandlerForDelete = () => {
    this.setState({
      isCSVStructureValid: false,
      validatedProducts: [],
      incorrectHeaders: [],
      errorMessage: '',
      hideInstructions: true,
    });
  };

  downloadCSVTemplate = () => {
    const headersAsKeysInObjInArray = [
      AddProductsCSVHeaders.reduce((acc, header) => ({ ...acc, ...{ [header]: '' } }), {}),
    ];
    const csv = Papa.unparse(headersAsKeysInObjInArray);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'foodbombs_add_products_template.csv');
  };

  sanitizeProductsForAPI = (products, weightClassDescriptions, categories) =>
    products.map((product) => ({
      productId: Number(product.productId),
      name: product.name,
      sku: product.sku || null,
      categories: mapCategoryNamesToIds(
        [product.categoryOne, product.categoryTwo, product.categoryThree].filter((catId) => isCategoryEntered(catId)),
        categories,
      ),
      priceInCents: parseInt((product.price * 100).toFixed(0), 10),
      unitOfPriceId: mapWeightClassDescriptionsToIds(product.unitOfPrice, weightClassDescriptions), // product.unitOfPrice,
      gstExempt: product.taxable === 'N',
      unitOfPortionId: mapWeightClassDescriptionsToIds(product.unitOfPortion, weightClassDescriptions), // product.unitOfPortion,
      portions: product.portions.split(',').map((portion) => parseFloat(portion, 10)),
      enabled: product.enabled === 'Y',
      outOfStock: product.outOfStock === 'Y',
    }));

  mapAPIParamToUIField = (param) => {
    switch (param) {
      case 'gstExempt':
        return 'taxable';
      case 'priceInCents':
        return 'price';
      case 'unitOfPriceId':
        return 'unitOfPrice';
      case 'unitOfPortionId':
        return 'unitOfPortion';
      case 'categories':
        return 'categoryOne';
      default:
        return param;
    }
  };

  parseValidatedProductsFromAPIToUITable = (productsFromAPI, portionUnits, categories) =>
    productsFromAPI.map((validatedProduct) => {
      const [categoryOne, categoryTwo, categoryThree] = validatedProduct.productSanitized.categories;
      return {
        productSanitized: {
          productId: validatedProduct.productSanitized.productId,
          sku: validatedProduct.productSanitized.sku,
          name: validatedProduct.productSanitized.name,
          categoryOne: categoryOne ? mapCategoryIdsToNames(categoryOne, categories) : '',
          categoryTwo: categoryTwo ? mapCategoryIdsToNames(categoryTwo, categories) : '',
          categoryThree: categoryThree ? mapCategoryIdsToNames(categoryThree, categories) : '',
          price: round2DP(validatedProduct.productSanitized.priceInCents / 100),
          unitOfPrice: mapPortionUnitIdsToTitles(validatedProduct.productSanitized.unitOfPriceId, portionUnits),
          portions: validatedProduct.productSanitized.portions.toString(),
          unitOfPortion: mapPortionUnitIdsToTitles(validatedProduct.productSanitized.unitOfPortionId, portionUnits),
          taxable: validatedProduct.productSanitized.gstExempt ? 'N' : 'Y',
          enabled: validatedProduct.productSanitized.enabled ? 'Y' : 'N',
          outOfStock: validatedProduct.productSanitized.outOfStock ? 'Y' : 'N',
        },
        productErrors:
          validatedProduct.productErrors?.map((error) => ({
            path: this.mapAPIParamToUIField(error.param),
            message: error.message,
          })) || [],
      };
    });

  joinArrayWithCommas = (arrayOfStrings) =>
    [arrayOfStrings.slice(0, -1).join(', '), arrayOfStrings.slice(-1)[0]].join(
      arrayOfStrings.length < 2 ? '' : ' and ',
    );

  potentiallyUpdateCustomPriceAndSpecialCount = (sanitizedProducts) => {
    const { currentProducts } = this.state;

    const updatedProducstWithCustomPricing = [];
    const updatedProductsOnSpecial = [];

    if (currentProducts) {
      sanitizedProducts.forEach((product) => {
        const idxInQuestion = currentProducts.findIndex((currentProduct) => currentProduct.id === product.productId);
        if (idxInQuestion !== -1) {
          if (currentProducts[idxInQuestion].customPricing) {
            const updatedProduct = { ...currentProducts[idxInQuestion], ...product };
            updatedProducstWithCustomPricing.push(updatedProduct);
          }
          if (currentProducts[idxInQuestion].specials) {
            const updatedProduct = { ...currentProducts[idxInQuestion], ...product };
            updatedProductsOnSpecial.push(updatedProduct);
          }
        }
      });
      this.setState({
        productsWithCustomPricing: updatedProducstWithCustomPricing,
        productsOnSpecial: updatedProductsOnSpecial,
      });
    }
  };

  /*
   * We load the current list of products so that we only have to upload the deltas
   */
  extractProductDeltas = async (sanitizedProducts) => {
    const { addProductsMode } = this.props;

    if (addProductsMode) {
      return sanitizedProducts;
    }

    const currentProductData = await this.fetchCurrentProducts();
    const formattedCurrentProductData = currentProductData.map((product) => ({
      categories: product.categories,
      enabled: product.enabled,
      gstExempt: product.gstExempt,
      name: product.name,
      portions: product.portions,
      priceInCents: product.priceInCents,
      productId: product.id,
      sku: product.sku,
      unitOfPortionId: product.unitOfPortion.id,
      unitOfPriceId: product.unitOfPrice.id,
      outOfStock: product.outOfStock,
    }));

    const productMap = (product) => [product.productId, product];
    const currentProductsMap = new Map(formattedCurrentProductData.map(productMap));
    const productsToUploadMap = new Map(sanitizedProducts.map(productMap));

    return extractProductsToUpdate(currentProductsMap, productsToUploadMap);
  };

  handleSimulateProductsSavingLoadingComplete = () => {
    this.setState({ loadingSaveProducts: false });
    setTimeout(() => {
      this.setState({ displayUploadProgressDialog: false });
    }, 1000);
  };

  handleProductSave = async () => {
    this.setState({ loadingSaveProducts: true, displayUploadProgressDialog: true });
    const { validatedProducts, portionUnits, categories } = this.state;
    const { addProductsMode, createNotification, sendDatadogError, trackBulkProductUpload } = this.props;
    let sanitizedDeltas = [];
    const sanitizedProducts = this.sanitizeProductsForAPI(
      validatedProducts.map((prod) => prod.productSanitized),
      portionUnits,
      categories,
    );

    try {
      sanitizedDeltas = await this.extractProductDeltas(sanitizedProducts);
    } catch (error) {
      // Already logged to raygun in parent
      createNotification({
        type: NOTIFICATION_TYPES.ERROR,
        content: 'Failed to save products. Please try again.',
        timeout: 8000,
        closable: true,
      });
      this.setState({ loadingSaveProducts: false, displayUploadProgressDialog: false });
      return;
    }

    if (sanitizedDeltas.length === 0) {
      createNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        content: 'Your products are already up to date',
        timeout: 10000,
        closable: true,
      });
      this.handleSimulateProductsSavingLoadingComplete();
      this.handleNext();
      return;
    }

    const endpointURI = 'suppliers/products/bulk-upload';
    const httpMethod = addProductsMode ? 'post' : 'patch';
    FoodbombAPI[httpMethod](endpointURI, { products: sanitizedDeltas }, { timeout: 600000 })
      .then((response) => {
        const updatedProductIds = response?.data?.productIds || [];
        this.handleSimulateProductsSavingLoadingComplete();

        trackBulkProductUpload(sanitizedDeltas, endpointURI);
        this.handleNext();

        this.props.createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Successfully ${addProductsMode ? 'added' : 'updated'} ${updatedProductIds.length ?? 0} products`,
          timeout: 10000,
          closable: true,
        });
        if (!addProductsMode) {
          const updatedProducts = sanitizedProducts.filter((p) => updatedProductIds.includes(p.productId));
          this.potentiallyUpdateCustomPriceAndSpecialCount(updatedProducts);
        }
      })
      .catch((error) => {
        let errorMessage;
        if (error?.response?.status === 400) {
          // If we're adding products, everything we added is Echoed and we can use that as our source of data
          // Sometimes PHP is doing weird things and returning an object instead of an array.
          // These objects have numerical keys, yet javascript is interpreting them as objects.
          // This enforces that we use the array.
          let productData = Object.values(error.response.data);

          // Alternatively, if we're editing products, we stripped the data to only send the deltas to the API.
          // Hence, we need to attach the relevant errors to the correct products to complete a full data set.
          if (!addProductsMode) {
            const echoedErrorsMap = new Map(
              productData.map((data) => [data.productSanitized.productId, data.productErrors]),
            );
            productData = sanitizedProducts.map((product) => ({
              productSanitized: { ...product },
              productErrors: echoedErrorsMap.get(product.productId),
            }));
          }

          const parsedProducts = this.parseValidatedProductsFromAPIToUITable(productData, portionUnits, categories);
          this.setValidatedProductsHandler(parsedProducts);
        } else if (error.response && error.response.data) {
          errorMessage = buildGenericErrorMessage(JSON.stringify(error.response.data));
        } else {
          errorMessage = buildGenericErrorMessage('Could not save edited products');
        }
        if (errorMessage) {
          this.setState({ errorMessage, activeStep: 0 });
        }
        this.setState({ loadingSaveProducts: false, displayUploadProgressDialog: false });
        if (error?.response?.status !== 400) {
          sendDatadogError(`Unable to bulk ${addProductsMode ? 'add' : 'edit'} products`, {
            error,
            location: 'Bulk add or Edit products via CSV Page',
          });
        }
      });
  };

  updateAndRevalidateAProduct = (addProductsMode) => async (productToUpdateIdx, updatedProductData) => {
    const validatedEditedProduct = await validateCSVData(
      [updatedProductData],
      this.state.portionUnits,
      this.state.categories,
      addProductsMode,
    );
    const validatedProducts = [...this.state.validatedProducts];
    validatedProducts[productToUpdateIdx] = validatedEditedProduct[0];
    this.setValidatedProductsHandler(validatedProducts, undefined, false);
    if (!Object.entries(validatedEditedProduct[0].productErrors).length) {
      this.props.createNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        content: 'Updated product successfully',
        timeout: 3000,
        closable: true,
      });
    } else {
      this.props.createNotification({
        type: NOTIFICATION_TYPES.ERROR,
        content: 'Updated product incorrectly',
        timeout: 3000,
        closable: true,
      });
    }
  };

  deleteProduct = (productToDeleteIdx) => {
    const validatedProducts = [...this.state.validatedProducts];
    validatedProducts.splice(productToDeleteIdx, 1);
    this.setValidatedProductsHandler(validatedProducts, undefined, false);
    this.props.createNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      content: 'Product deleted',
      timeout: 3000,
      closable: true,
    });
  };

  deleteProductsWithErrors = () => {
    this.setValidatedProductsHandler(
      this.state.validatedProducts.filter((product) => product.productErrors.length === 0),
    );
  };

  parseProductsFromAPIToCSV = (products, categories) =>
    products.map((product) => ({
      'Product Id': product.id,
      [ProductSKUCSVHeader]: product.sku,
      'Product Name': product.name,
      'Category One': categoryIdToName(product.categories[0], categories),
      'Category Two': categoryIdToName(product.categories[1], categories),
      'Category Three': categoryIdToName(product.categories[2], categories),
      'Price (Ex.GST)': round2DP(product.priceInCents / 100),
      'Price Unit': product.unitOfPrice.title,
      Portions: product.portions.toString(),
      'Portion Unit': product.unitOfPortion.title,
      'GST (Y/N)': product.gstExempt ? 'N' : 'Y',
      'Enabled (Y/N)': product.enabled ? 'Y' : 'N',
      'Out of Stock (Y/N)': product.outOfStock ? 'Y' : 'N',
    }));

  downloadExistingProducts = () => {
    const { supplierId } = this.props;
    const { categories } = this.state;
    this.setState({ loadingProducts: true });
    FoodbombAPI.get(`suppliers/products`, { credentials: 'include' })
      .then((response) => {
        this.setState({ loadingProducts: false });
        const parsedProductsForCSV = this.parseProductsFromAPIToCSV(response.data.data, categories);
        const csv = Papa.unparse(parsedProductsForCSV);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, `supplier_${supplierId}_products_on_foodbomb.csv`);
      })
      .catch((error) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = buildGenericErrorMessage(JSON.stringify(error.response.data));
        } else {
          errorMessage = buildGenericErrorMessage('Could not download products');
        }
        this.setState({ loadingProducts: false, errorMessage });
      });
  };

  findCategory = (name, parentId, categories) =>
    categories.find((cat) => cat.name === name && cat.parentCategoryId === parentId);

  createCategoriesHandler = async () => {
    this.setState({ loadingCreateCategories: true, categoryCreateError: '' });
    let categoryCreateError;
    try {
      await this.createCategories();
    } catch (e) {
      categoryCreateError = e.message;
    } finally {
      this.setState({ loadingCreateCategories: false, categoryCreateError });
      await this.fetchAllCategories();
      const validatedProductsAfterCategoryCreation = await validateCSVData(
        this.state.validatedProducts.map((validatedProduct) => validatedProduct.productSanitized),
        this.state.portionUnits,
        this.state.categories,
        this.props.addProductsMode,
      );
      this.setValidatedProductsHandler(validatedProductsAfterCategoryCreation, sortProductsByCategoryOneErrorFirst);
    }
  };

  createCategories = async () => {
    const { validatedProducts, categories } = this.state;

    // fail hard if there is an incorrect categoryOne
    const productsWithErrors = validatedProducts.filter((validatedProduct) => validatedProduct.productErrors.length);
    const someProductHasAnInvalidCategoryOne = productsWithErrors.some((prod) =>
      prod.productErrors.some((err) => err.path === 'categoryOne'),
    );
    if (someProductHasAnInvalidCategoryOne) {
      throw new Error('Please correct all Category One errors before creating new ones');
    }

    const productsWithCategoryErrors = productsWithErrors.map((validatedProduct) => ({
      categoryOne: validatedProduct.productSanitized.categoryOne,
      categoryTwo: validatedProduct.productSanitized.categoryTwo,
      categoryThree: validatedProduct.productSanitized.categoryThree,
    }));

    // https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404 ?
    for (let productIdx = 0; productIdx < productsWithCategoryErrors.length; productIdx += 1) {
      const { categoryOne, categoryTwo, categoryThree } = productsWithCategoryErrors[productIdx];

      if (isCategoryEntered(categoryTwo)) {
        const foundCat1 = this.findCategory(categoryOne, null, categories);
        let newCategoryTwoId;
        const potentiallyFoundCat2 = this.findCategory(categoryTwo, foundCat1.id, categories);
        if (!potentiallyFoundCat2) {
          // TODO: this requires rebuilding, but not touching it now as dont want to break the universe, ignoring error
          // eslint-disable-next-line no-await-in-loop
          await FoodbombAPI.post('/categories', {
            parentId: foundCat1.id,
            name: categoryTwo,
          })
            .then((response) => {
              newCategoryTwoId = response.data.id;
            })
            .catch((error) => {
              let errorMessage;
              if (error?.response?.status === 409) {
                newCategoryTwoId = error.response.data.id;
              } else if (error?.response?.data) {
                errorMessage = JSON.stringify(error.response.data);
              } else {
                errorMessage = buildGenericErrorMessage(`Could not create category ${categoryTwo}`);
              }
              if (errorMessage) throw new Error(errorMessage);
            });
        }
        const categoryThreeParentId = newCategoryTwoId || potentiallyFoundCat2.id;
        const potentiallyFoundCat3 = this.findCategory(categoryThree, categoryThreeParentId, categories);
        if (isCategoryEntered(categoryThree) && !potentiallyFoundCat3) {
          // TODO: this requires rebuilding, but not touching it now as dont want to break the universe, ignoring error
          // eslint-disable-next-line no-await-in-loop
          await FoodbombAPI.post('/categories', {
            parentId: categoryThreeParentId,
            name: categoryThree,
          }).catch((error) => {
            let errorMessage;
            if (error.response.status === 409) {
              // pass
            } else if (error?.response?.data) {
              errorMessage = JSON.stringify(error.response.data);
            } else {
              errorMessage = buildGenericErrorMessage(`Could not create category ${categoryThree}`);
            }
            if (errorMessage) throw new Error(errorMessage);
          });
        }
      }
    }
  };

  gatherCategoriesToBeCreated = () => {
    const { validatedProducts } = this.state;
    const newCategoryTwos = validatedProducts
      .filter((validatedProduct) => {
        const isCat1Correct = !validatedProduct.productErrors.some((e) => e.path === 'categoryOne');
        const isCat2Correct = !validatedProduct.productErrors.some((e) => e.path === 'categoryTwo');
        return isCat1Correct && !isCat2Correct;
      })
      .map(
        (validatedProduct) =>
          `${validatedProduct.productSanitized.categoryOne} > ${validatedProduct.productSanitized.categoryTwo}`,
      );

    const newCategoryThrees = validatedProducts
      .filter((validatedProduct) => {
        const isCat1Correct = !validatedProduct.productErrors.some((e) => e.path === 'categoryOne');
        const isCat3Correct = !validatedProduct.productErrors.some((e) => e.path === 'categoryThree');
        return isCat1Correct && isCategoryEntered(validatedProduct.productSanitized.categoryTwo) && !isCat3Correct;
      })
      .map(
        (validatedProduct) =>
          `${validatedProduct.productSanitized.categoryOne} > ${validatedProduct.productSanitized.categoryTwo} > ${validatedProduct.productSanitized.categoryThree}`,
      );
    return [...new Set(newCategoryTwos.concat(newCategoryThrees))];
  };

  parseProductsForCSVErrorProductsInEditMode = (parseProducts) =>
    parseProducts.map((product) => ({
      'Product Id': product.productId,
      [ProductSKUCSVHeader]: product.sku,
      'Product Name': product.name,
      'Category One': product.categoryOne,
      'Category Two': product.categoryTwo,
      'Category Three': product.categoryThree,
      'Price (Ex.GST)': product.price,
      'Price Unit': product.unitOfPrice,
      Portions: product.portions ? product.portions.toString() : '',
      'Portion Unit': product.unitOfPortion,
      'GST (Y/N)': product.gstExempt ? 'N' : 'Y',
      'Out of Stock (Y/N)': product.outOfStock ? 'Y' : 'N',
    }));

  downloadProductsWithErrors = () => {
    const { validatedProducts } = this.state;
    const { addProductsMode, supplierId } = this.props;

    const productsWithErrors = validatedProducts
      .filter((validatedProduct) => validatedProduct.productErrors.length)
      .map((validatedProduct) => validatedProduct.productSanitized);

    let parseProducts = this.parseProductsForCSVErrorProductsInEditMode(productsWithErrors);
    if (addProductsMode) {
      parseProducts = parseProducts.filter((parseProduct) =>
        Object.entries(parseProduct).filter((keyValPair) => keyValPair[0] !== 'Product Id'),
      );
    }

    const csv = Papa.unparse(parseProducts);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `supplier_${supplierId}_products_with_errors.csv`);
  };

  getSteps = () => ['Upload', 'Confirm and Save'];

  getStepContext = (stepIndex) => {
    const {
      hideInstructions,
      validatedProducts,
      errorMessage,
      numberOfErroredProducts,
      areProductsReadyForUpload,
      numberOfSuccessfulProducts,
      incorrectHeaders,
      loadingCSV,
      loadingProducts,
      isProductIdInvalid,
      categories,
      loadingCreateCategories,
      categoryCreateError,
      portionUnits,
      loadingCategories,
    } = this.state;

    const { addProductsMode } = this.props;

    switch (stepIndex) {
      case 0:
        return (
          <StepZero
            isProductIdInvalid={isProductIdInvalid}
            hideInstructions={hideInstructions}
            incorrectHeaders={incorrectHeaders}
            errorMessage={errorMessage}
            loadingCSV={loadingCSV}
            loadingData={loadingProducts || loadingCategories}
            toggleInstructions={this.toggleInstructions}
            downloadCSVTemplate={this.downloadCSVTemplate}
            CSVHandlerForOnChange={this.CSVHandlerForOnChange(addProductsMode)}
            CSVHandlerForDelete={this.CSVHandlerForDelete}
            downloadExistingProducts={this.downloadExistingProducts}
            addProductsMode={addProductsMode}
          />
        );

      case 1:
        return (
          <StepOne
            isProductIdInvalid={isProductIdInvalid}
            categories={categories}
            validatedProducts={validatedProducts}
            numberOfErroredProducts={numberOfErroredProducts}
            areProductsReadyForUpload={areProductsReadyForUpload}
            numberOfSuccessfulProducts={numberOfSuccessfulProducts}
            updateAndRevalidateAProduct={this.updateAndRevalidateAProduct(addProductsMode)}
            deleteProduct={this.deleteProduct}
            deleteProductsWithErrors={this.deleteProductsWithErrors}
            createCategories={this.createCategoriesHandler}
            loadingCreateCategories={loadingCreateCategories}
            categoryCreateError={categoryCreateError}
            categoriesToBeCreated={this.gatherCategoriesToBeCreated()}
            downloadProductsWithErrors={this.downloadProductsWithErrors}
            unitsOfMeasure={portionUnits}
          />
        );
      case 2:
        return (
          <StepTwo
            numberOfSuccessfulProducts={numberOfSuccessfulProducts}
            addProductsMode={addProductsMode}
            productsWithCustomPricing={this.state.productsWithCustomPricing}
            productsOnSpecial={this.state.productsOnSpecial}
          />
        );
      default:
        return <div>Unknown step</div>;
    }
  };

  handleNext = () => {
    this.setState((prevState) => ({ activeStep: prevState.activeStep + 1 }));
  };

  handleBack = () => {
    this.setState((prevState) => ({ activeStep: prevState.activeStep - 1 }));
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  render() {
    const { activeStep, numberOfErroredProducts, loadingSaveProducts, displayUploadProgressDialog } = this.state;
    const { addProductsMode } = this.props;

    const steps = this.getSteps();
    const stepContext = this.getStepContext(activeStep);
    const isSaveBtnAvailable = activeStep === 1;
    const hasCSVBeenUploaded = activeStep > 0;
    const cannotStepBack = activeStep === 0 || activeStep === 2;
    const cannotStepForward = activeStep === 2;

    return (
      <>
        <div className={styles.AddOrEditMultipleProductsContainer}>
          <PageHeader>
            <Breadcrumbs
              currentPageTitle={`${addProductsMode ? 'Add' : 'Edit'} Multiple Products via CSV`}
              previousLinks={[
                {
                  previousPageTitle: 'All Products',
                  previousPageLink: '/products',
                },
              ]}
            />
          </PageHeader>

          <div className={styles.AddOrEditMultipleProductsContainer__stepper}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              classes={{ root: styles.AddOrEditMultipleProductsContainer__stepper_paper }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        active: styles.StepIcon__active,
                        completed: styles.StepIcon__completed,
                      },
                    }}
                  >
                    <Typography type={TYPOGRAPHY_TYPES.BODY}>{label}</Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>

          {stepContext}

          {!cannotStepBack || !cannotStepForward ? (
            <div className={styles.AddOrEditMultipleProductsContainer__stepperBtnsContainer}>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                className={styles.AddOrEditMultipleProductsContainer__stepperBtn}
                onClick={this.handleBack}
                disabled={cannotStepBack || loadingSaveProducts}
              >
                Back
              </Button>
              {cannotStepForward ? (
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  className={styles.AddOrEditMultipleProductsContainer__stepperBtn}
                  onClick={this.handleReset}
                >
                  Reset
                </Button>
              ) : null}

              {isSaveBtnAvailable ? (
                <Button
                  variant={BUTTON_VARIANTS.PRIMARY}
                  className={styles.AddOrEditMultipleProductsContainer__stepperBtn}
                  onClick={this.handleProductSave}
                  disabled={numberOfErroredProducts !== 0}
                >
                  {loadingSaveProducts ? (
                    <CircularProgress className={styles.AddOrEditMultipleProductsContainer__Spinner} size={24} />
                  ) : (
                    <React.Fragment>Save</React.Fragment>
                  )}
                </Button>
              ) : (
                <Button
                  className={styles.AddOrEditMultipleProductsContainer__stepperBtn}
                  onClick={this.handleNext}
                  disabled={!hasCSVBeenUploaded || cannotStepForward}
                >
                  Next
                </Button>
              )}
            </div>
          ) : null}
        </div>
        {displayUploadProgressDialog ? (
          <BulkUploadProgressDialog isOpen={true} numberOfUploads={1500} isComplete={!loadingSaveProducts} />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  supplierId: state.auth.supplierDetails ? state.auth.supplierDetails.id : undefined,
});

AddOrEditMultipleProducts.propTypes = {
  supplierId: PropTypes.number.isRequired,
  createNotification: PropTypes.func.isRequired,
  addProductsMode: PropTypes.bool.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  trackBulkProductUpload: PropTypes.func.isRequired,
};

export default withErrorReports(
  withNotifications(withEventTracking(withRouter(connect(mapStateToProps, null)(AddOrEditMultipleProducts)))),
);
