import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import { BUTTON_VARIANTS, NOTIFICATION_TYPES, TYPOGRAPHY_TYPES } from '../../utils/Constants';
import { LossLeaderVenuesForSelect } from '../../containers/LossLeaders/LossLeaderTypes';
import { CreateNotification, SendErrorReportType } from '../../utils/Presenters/ReduxType';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog/DeleteConfirmationDialog';
import { Button, ErrorNotification, Paper, Tooltip, Typography } from '../UI/FB';
import LargeLoadingSpinner from '../UI/LargeLoadingSpinner/LargeLoadingSpinner';
import LossLeadersVenuesModal from './LossLeadersVenuesModal/LossLeadersVenuesModal';
import styles from './LossLeaderVenues.module.scss';

type LossLeaderVenuesTypes = {
  addVenuesDisabled: boolean;
  onSuccessfulLossVenuesAPICall: () => void;
  lossLeaderVenues: LossLeaderVenuesForSelect[] | null;
  sendDatadogError: SendErrorReportType;
  createNotification: CreateNotification;
  loadingLossLeaderVenues: boolean;
  setLoadingLossLeaderVenues: (val: boolean) => void;
  venues: LossLeaderVenuesForSelect[];
  APIError: boolean;
};

const LossLeaderVenues: FC<LossLeaderVenuesTypes> = ({
  addVenuesDisabled,
  onSuccessfulLossVenuesAPICall,
  lossLeaderVenues,
  sendDatadogError,
  createNotification,
  loadingLossLeaderVenues,
  setLoadingLossLeaderVenues,
  venues,
  APIError,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [venueToDeleteId, setVenueToDeleteId] = useState<number | undefined>(undefined);
  const [lossLeaderTooltip, setLossLeaderTooltip] = useState<boolean>(false);

  const triggerModal = (): void => setOpenModal(true);
  const closeModal = (): void => setOpenModal(false);

  const handleDeleteLossVenue = (id: number): void => {
    setVenueToDeleteId(id);
  };

  const handleDelete = () => {
    setLoadingLossLeaderVenues(true);
    FoodbombAPI.delete(`/suppliers/loss-venues/${venueToDeleteId}`)
      .then(() => {
        onSuccessfulLossVenuesAPICall();
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully removed loss leader venue.',
          timeout: 4000,
          closable: true,
        });
      })
      .catch((error: any) => {
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to remove loss leader venue.',
          timeout: 4000,
          closable: true,
        });
        sendDatadogError('Unable to remove leader venue.', {
          error,
          location: 'Loss leader venues page',
        });
      })
      .finally(() => setLoadingLossLeaderVenues(false));
  };

  return (
    <Paper
      className={
        lossLeaderVenues
          ? [styles.LossLeaderVenuesContainer, styles.LossVenues].join(' ')
          : styles.LossLeaderVenuesContainer
      }
    >
      <div className={styles.LossLeaderVenuesHeader}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL} className={styles.LossLeaderVenuesTitle}>
          Apply to below venues
        </Typography>
      </div>
      {loadingLossLeaderVenues || (!lossLeaderVenues?.length && !venues.length) ? (
        <LargeLoadingSpinner small />
      ) : (
        <>
          {APIError ? (
            <ErrorNotification
              className={styles.ErrorWrapper}
              body="We were unable to load your venues, our engineers have been notified."
            />
          ) : (
            <>
              {lossLeaderVenues?.length ? (
                <>
                  {lossLeaderVenues?.map((lossVenue: LossLeaderVenuesForSelect) => (
                    <div key={lossVenue.value} className={styles.LossVenuesBodyInfo}>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.LossVenuesBodyText}>
                        {lossVenue?.label?.split(',')[1]}
                      </Typography>
                      <button
                        type="button"
                        onClick={() => handleDeleteLossVenue(lossVenue.value)}
                        className={styles.LossVenuesDeleteButton}
                      >
                        <DeleteOutlineIcon className={styles.LossVenuesIcon} />
                      </button>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className={styles.LossLeaderVenuesBodyInfo}>
                    <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.LossLeaderVenuesBodyText}>
                      Any venues added here will not be able to purchase your loss leader products until they meet the
                      threshold.
                    </Typography>
                  </div>
                </>
              )}
              <div className={styles.LossLeaderVenuesAction}>
                {addVenuesDisabled ? (
                  <div>
                    <button
                      className={styles.FakeBtn}
                      onClick={() => setLossLeaderTooltip(true)}
                      onMouseLeave={() => setLossLeaderTooltip(false)}
                      type="button"
                    >
                      <ClickAwayListener onClickAway={() => setLossLeaderTooltip(false)}>
                        <Tooltip
                          title="Please set an order threshold first"
                          onClose={() => setLossLeaderTooltip(false)}
                          onOpen={() => setLossLeaderTooltip(true)}
                          open={lossLeaderTooltip}
                        >
                          <Button
                            onClick={triggerModal}
                            variant={BUTTON_VARIANTS.SECONDARY}
                            disabled={addVenuesDisabled}
                            className={styles.LossLeaderVenuesAddButton}
                          >
                            <AddIcon className={styles.ActionIcon} />
                            Add Venue
                          </Button>
                        </Tooltip>
                      </ClickAwayListener>
                    </button>
                  </div>
                ) : (
                  <Button onClick={triggerModal} variant={BUTTON_VARIANTS.SECONDARY} disabled={addVenuesDisabled}>
                    <AddIcon className={styles.ActionIcon} />
                    Add Venue
                  </Button>
                )}
              </div>
            </>
          )}
        </>
      )}
      <LossLeadersVenuesModal
        isOpen={openModal}
        onClose={closeModal}
        onSuccessfulLossVenuesAPICall={onSuccessfulLossVenuesAPICall}
        sendDatadogError={sendDatadogError}
        createNotification={createNotification}
        setLoadingLossLeaderVenues={setLoadingLossLeaderVenues}
        loadingLossLeaderVenues={loadingLossLeaderVenues}
        venues={venues}
      />
      <DeleteConfirmationDialog
        isOpen={Boolean(venueToDeleteId)}
        handleClose={() => setVenueToDeleteId(undefined)}
        handleDelete={handleDelete}
        confirmationMessage="Are you sure you want to remove this venue?"
      />
    </Paper>
  );
};

LossLeaderVenues.propTypes = {
  addVenuesDisabled: PropTypes.bool.isRequired,
  lossLeaderVenues: PropTypes.array.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  onSuccessfulLossVenuesAPICall: PropTypes.func.isRequired,
  loadingLossLeaderVenues: PropTypes.bool.isRequired,
  setLoadingLossLeaderVenues: PropTypes.func.isRequired,
  venues: PropTypes.array.isRequired,
  APIError: PropTypes.bool.isRequired,
};

export default LossLeaderVenues;
