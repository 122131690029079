import axios from 'axios';
import { DELIVERY_PREFERENCES_API_BASE_URL } from './BaseURLs';

const axiosInstance = axios.create({
  baseURL: DELIVERY_PREFERENCES_API_BASE_URL,
  timeout: 30000,
  withCredentials: true,
});

axiosInstance.CancelToken = axios.CancelToken;
axiosInstance.isCancel = axios.isCancel;

axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

export default axiosInstance;
