import { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Orders from './Orders';
import Order from './Order';
import styles from './OrdersRoutes.module.scss';

const OrdersRoutes: FC = () => {
  const match = useRouteMatch();

  return (
    <div className={styles.OrderRoutesContainer}>
      <Switch>
        <Route exact path={`${match.path}/`} component={Orders} />
        <Route exact path={`${match.path}/:id`} component={Order} />
      </Switch>
    </div>
  );
};

export default OrdersRoutes;
