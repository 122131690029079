// Polyfil everything
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'array-flat-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// End polyfil

import React from 'react';
import { render } from 'react-dom';
import { StylesProvider } from '@material-ui/styles';
import { BrowserRouter } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Moment from 'moment';

import App from './containers/App/App';
import NavigationProvider from './context/Navigation/NavigationProvider';
import rootReducer from './store/reducers/RootReducer';
import FBStyleSheetRelocator from './components/FBStyleSheetRelocator/FBStyleSheetRelocator';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

// Make weeks start on a Monday when we use Moment
Moment.updateLocale('en', {
  week: {
    dow: 1, // First day of week is Monday
  },
});

const Root = () => (
  <Provider store={store}>
    <StylesProvider injectFirst>
      <FBStyleSheetRelocator>
        <BrowserRouter>
          <NavigationProvider>
            <ConfirmProvider>
              <App />
            </ConfirmProvider>
          </NavigationProvider>
        </BrowserRouter>
      </FBStyleSheetRelocator>
    </StylesProvider>
  </Provider>
);

render(<Root />, document.querySelector('#root'));
