import 'axios';

// TODO: consider moving this file or creating multiple

export enum ApiMethodTypes {
  get = 'get',
  post = 'post',
  patch = 'patch',
  delete = 'delete',
}

export interface ContactTypes {
  contactDays?: string;
  contactHours?: string;
  contactTypeId: number;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  phone: string;
  sellerId: number;
}

export interface ValuesType {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  contactTypeId: number;
  contactHours?: string;
  contactDays?: string;
}

export type VT = keyof ValuesType;

type Address = {
  postcode: number;
  street: string;
  suburb: string;
};

export interface Supplier {
  accountName: string;
  accountNumber: string;
  additionalOrderRecipientContact: ContactTypes[];
  address: Address;
  afterHoursContact: ContactTypes[];
  backgroundImageUrl: string;
  bio: string;
  bsb: string;
  company: string;
  email: string;
  firstName: string;
  id: number;
  image: string;
  lastName: string;
  newCustomerSetUpContact: ContactTypes[];
  ownerContact: ContactTypes[];
  phone: string;
  remittanceContact: ContactTypes[];
  reviewsContact: ContactTypes[];
  substitutionPreferenceEnabled: boolean;
  supportContact: ContactTypes[];
  updatedDetailsRecipientContact: ContactTypes[];
  isEarlyDeliveryEnabled: boolean;
  earlyDeliveryStartTime?: string;
  earlyDeliveryEndTime?: string;
}
