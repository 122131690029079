import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import styles from './SwitchField.module.scss';

const SwitchField = ({ disabled, checked, onChange, label, containerStyles, ...props }) => {
  const rootStyles = [styles.Switch__root];
  const switchBaseStyles = [styles.Switch__switchBase];
  const thumbStyles = [styles.Switch__thumb];
  const trackStyles = [styles.Switch__track];

  if (checked) {
    rootStyles.push(styles.checked);
    switchBaseStyles.push(styles.checked);
    thumbStyles.push(styles.checked);
    trackStyles.push(styles.checked);
  }

  if (disabled) {
    rootStyles.push(styles.disabled);
    switchBaseStyles.push(styles.disabled);
    thumbStyles.push(styles.disabled);
    trackStyles.push(styles.disabled);
  }

  return (
    <div className={containerStyles}>
      <FormControlLabel
        control={
          <Switch
            disabled={disabled}
            checked={checked}
            classes={{
              root: rootStyles.join(' '),
              switchBase: switchBaseStyles.join(' '),
              thumb: thumbStyles.join(' '),
              track: trackStyles.join(' '),
            }}
            onChange={onChange}
          />
        }
        label={label}
        {...props}
      />
    </div>
  );
};

SwitchField.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.any,
  containerStyles: PropTypes.string,
};

export default SwitchField;
