import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router-dom';
import { CircularProgress, MenuItem } from '@material-ui/core';
import { saveAs } from 'file-saver';
import Moment from 'moment';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterList from '@material-ui/icons/FilterList';
import {
  MultiSelectTable,
  Typography,
  SearchBar,
  Button,
  Emoji,
  Menu,
  Paper,
  Tooltip,
  ErrorNotification,
  Badge,
} from '../../components/UI/FB';
import OrderStatusChip from '../../components/OrderStatusChip/OrderStatusChip.tsx';
import CustomerAlertChip from '../../components/CustomerAlertChip/CustomerAlertChip';
import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import styles from './Orders.module.scss';
import { FoodbombPDFAPI, FoodbombAPI } from '../../utils/AxiosInstances';
import DownloadOrdersDialog from '../../components/DownloadOrdersDialog/DownloadOrdersDialog';
import DownloadProductsForDeliveryDayDialog from '../../components/DownloadProductsForDeliveryDayDialog/DownloadProductsForDeliveryDayDialog';
import withRedirectHelper from '../../hoc/withRedirectHelper/withRedirectHelper';
import withQueryParams from '../../hoc/withQueryParams/withQueryParams';
import {
  DELIVERY_DOCKET_VALID_STATUSES,
  NOTIFICATION_TYPES,
  TYPOGRAPHY_TYPES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  ORDER_STATUSES,
  SORT_BY_METHODS,
  PDF_DOCUMENT_TYPES,
  BULK_DOWNLOAD_COUNT_NOTIFICATION_TARGET_GROUP,
  DOWNLOAD_ORDER_PRODUCTS_FOR_DELIVERY_DAY_CSV,
  CUSTOMER_ALERT_TYPES,
  ORDER_TYPES,
} from '../../utils/Constants';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import withEventTracking from '../../hoc/withEventTracking/withEventTracking';
import OrderTypeChip from '../../components/OrderTypeChip/OrderTypeChip.tsx';
import PDFFileNameFromResponse from '../../utils/PDFFileNameFromResponse';
import { presentCurrency } from '../../utils/Presenters/PresentCurrency/PresentCurrency';
import { getDateText, getDateLabel } from '../../utils/DateHelper/DateHelper';
import { OBJECT_TYPES, PAGE_POSITION } from '../../hoc/withEventTracking/Properties';
import EmailDownloadPDFDialog from './EmailDownloadPDFDialog/EmailDownloadPDFDialog';
import DownloadingPDFsDialog from './DownloadingPDFsDialog/DownloadingPDFsDialog';

const STATUS_FILTERS = [
  {
    id: 0,
    title: 'All Statuses',
  },
  {
    id: 1,
    title: 'Completed Orders',
    filterField: 'orderStatus',
    filterValue: ORDER_STATUSES.COMPLETED,
  },
  {
    id: 2,
    title: 'Cancelled Orders',
    filterField: 'orderStatus',
    filterValue: ORDER_STATUSES.CANCELLED,
  },
  {
    id: 3,
    title: 'Pending Orders',
    filterField: 'orderStatus',
    filterValue: ORDER_STATUSES.PENDING,
  },
];

const ORDER_TYPE_FILTER = [
  {
    id: 0,
    title: 'All Order types',
  },
  {
    id: 1,
    title: 'Regular Orders',
    // Here is the regular way of doing things,
    // However, we can use filterKeyValues instead for multiple joins
    // filterField: 'regular',
    // filterValue: 0,
    filterKeyValues: [
      {
        key: 'isStandingOrder',
        value: '0',
        comparison: '=',
      },
      {
        key: 'isTopUpOrder',
        value: '0',
        comparison: '=',
      },
    ],
  },
  {
    id: 2,
    title: 'Top-Up Orders',
    filterKeyValues: [
      {
        key: 'isTopUpOrder',
        value: '1',
        comparison: '=',
      },
    ],
  },
  {
    id: 3,
    title: 'Standing Orders',
    filterKeyValues: [
      {
        key: 'isStandingOrder',
        value: '1',
        comparison: '=',
      },
    ],
  },
];

const ORDER_API_FIELDS = {
  id: {
    endpointKey: 'orderId',
    searchable: true,
  },
  firstName: {
    endpointKey: 'shippingFirstname',
  },
  lastName: {
    endpointKey: 'shippingLastname',
  },
  fullName: {
    endpointKey: 'fullName',
    searchable: true,
  },
  venue: {
    endpointKey: 'shippingCompany',
    searchable: true,
  },
  dateOrdered: {
    endpointKey: 'dateAdded',
  },
  deliveryDate: {
    endpointKey: 'deliveryDate',
  },
  status: {
    endpointKey: 'orderStatus',
  },
  total: {
    endpointKey: 'total',
  },
  venueId: {
    endpointKey: 'venueId',
  },
  orderedBefore: {
    endpointKey: 'orderedBefore',
  },
  customerAlert: {
    endpointKey: 'customerAlert',
  },
  substitutionAllowed: {
    endpointKey: 'substitutionAllowed',
  },
  standingOrderId: {
    endpointKey: 'standingOrderId',
  },
  parentOrderId: {
    endpointKey: 'parentOrderId',
  },
  supplierReference: {
    endpointKey: 'supplierReference',
    searchable: true,
  },
  venueName: {
    endpointKey: 'veunueName',
    searchable: true,
  },
};

const Orders = ({
  redirectToOrderWithQueryParams,
  redirectToOrder,
  createNotification,
  sendDatadogError,
  getQueryFromQueryString,
  updateSearchQueryParam,
  updateOrderFilterIdQueryParam,
  updateOrderTypeIdQueryParam,
  updatePageNumberQueryParam,
  getOrderFilterIdQueryFromQueryString,
  getPageNumberQueryFromQueryString,
  trackDownloadPDF,
  createDownloadInProgressNotification,
  removeDownloadInProgressNotification,
  removeNotificationOfTargetGroup,
  supplierId,
}) => {
  const mapValueToSortByToAPIField = (valueToSortBy) => ORDER_API_FIELDS[valueToSortBy].endpointKey;

  const [orders, setOrders] = useState([]);

  // Dropdown Anchor Elements
  const [orderStatusFilterAnchorElem, setOrderStatusFilterAnchorElem] = useState(undefined);
  const [orderTypeFilterAnchorElem, setOrderTypeFilterAnchorElem] = useState(undefined);
  const [bulkActionsDropDownAnchorElem, setBulkActionsDropDownAnchorElem] = useState(undefined);
  const [downloadDropDownAnchorElem, setDownloadDropDownAnchorElem] = useState(undefined);

  // Loading States
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [currentlyDownloadingPurchaseOrderIds, setCurrentlyDownloadingPurchaseOrderIds] = useState([]);
  const [currentlyDownloadingInvoiceIds, setCurrentlyDownloadingInvoiceIds] = useState([]);
  const [currentlyDownloadingDocketIds, setCurrentlyDownloadingDocketIds] = useState([]);
  const [currentlyDownloadingDeliveryDetailIds, setCurrentlyDownloadingDeliveryDetailIds] = useState([]);
  const [downloadingOrdersPDFCancelSource, setDownloadingOrdersPDFCancelSource] = useState(undefined);

  // Dialog States
  const [downloadingPDFsDialogOpen, setDownloadingPDFsDialogOpen] = useState(false);
  const [downloadOrdersCSVDialogOpen, setDownloadOrdersCSVDialogOpen] = useState(false);
  const [emailDownloadPDFDialogOpen, setEmailDownloadPDFDialogOpen] = useState(false);
  const [downloadProductsForDeliveryDayDialogOpen, setDownloadProductsForDeliveryDayDialogOpen] = useState(false);

  const [numberOfSelectedOrders, setNumberOfSelectedOrders] = useState(0);
  const [selectedOrderIdsForBulkDownload, setSelectedOrderIdsForBulkDownload] = useState([]);
  const [documentTypeForBulkDownload, setDocumentTypeForBulkDownload] = useState('');

  // Search and Filter States
  const [selectedOrderStatusFilterId, setSelectedOrderStatusFilterId] = useState(
    getOrderFilterIdQueryFromQueryString() || 0,
  );

  const [selectedOrderTypeFilterId, setSelectedOrderTypeFilterId] = useState(0);

  const [searchQuery, setSearchQuery] = useState(getQueryFromQueryString() || '');
  const [shouldShowSearchBar, setShouldShowSearchBar] = useState(false);
  const [APIError, setAPIError] = useState(undefined);
  const [supplierEmail, setSupplierEmail] = useState('');

  // Server Side Filtering of table
  const [pageSize, setPageSize] = useState(100);
  const [ordersResultsLength, setOrdersResultsLength] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(getPageNumberQueryFromQueryString() || 1);
  const [orderBy, setOrderBy] = useState(mapValueToSortByToAPIField('id'));
  const [sortBy, setSortBy] = useState(SORT_BY_METHODS.DESC);
  const [apiSearchQuery, setApiSearchQuery] = useState(getQueryFromQueryString() || '');

  const IS_SELECTED_KEY = 'isSelected';
  const MAX_NUMBER_OF_BROWSER_PDF_DOWNLOADS = 20;

  //  Dropdown handlers
  const handleOrderStatusFilterClicked = (e) => setOrderStatusFilterAnchorElem(e.currentTarget);
  const handleCloseOrderStatusFilterMenu = () => setOrderStatusFilterAnchorElem(undefined);
  const handleOrderTypeFilterClick = (e) => setOrderTypeFilterAnchorElem(e.currentTarget);
  const handleCloseOrderTypeFilterMenu = () => setOrderTypeFilterAnchorElem(undefined);
  const handleOpenBulkActionsDropDown = (e) => setBulkActionsDropDownAnchorElem(e.currentTarget);
  const handleCloseBulkActionsDropDown = () => setBulkActionsDropDownAnchorElem(undefined);
  const handleDownloadsDropdownClicked = (e) => setDownloadDropDownAnchorElem(e.currentTarget);
  const handleCloseDownloadDropdownMenu = () => setDownloadDropDownAnchorElem(undefined);

  // Dialog handlers
  const handleOpenDownloadingPDFsDialog = () => setDownloadingPDFsDialogOpen(true);
  const handleCloseDownloadingPDFsDialog = () => setDownloadingPDFsDialogOpen(false);
  const openDownloadProductsForDeliveryDayDialog = () => {
    setDownloadProductsForDeliveryDayDialogOpen(true);
  };
  const closeDownloadProductsForDeliveryDayDialog = () => {
    setDownloadProductsForDeliveryDayDialogOpen(false);
  };
  const openDownloadOrdersCSVDialog = () => {
    setDownloadOrdersCSVDialogOpen(true);
  };
  const closeDownloadOrdersCSVDialog = () => {
    setDownloadOrdersCSVDialogOpen(false);
  };

  const handleOpenEmailDownloadPDFDialog = () => {
    setEmailDownloadPDFDialogOpen(true);
  };

  const handleCloseEmailDownloadPDFDialog = () => {
    setSelectedOrderIdsForBulkDownload([]);
    setDocumentTypeForBulkDownload('');
    setEmailDownloadPDFDialogOpen(false);
  };

  const cancelBrowserDownload = () => {
    downloadingOrdersPDFCancelSource?.cancel('Download Cancelled by the user');
    setDownloadingOrdersPDFCancelSource(undefined);
    createNotification({
      type: NOTIFICATION_TYPES.ERROR,
      content: 'Download Cancelled',
      timeout: 2000,
      closable: true,
    });

    if (documentTypeForBulkDownload === PDF_DOCUMENT_TYPES.INVOICE) {
      const updatedListOfDownloadingInvoices = currentlyDownloadingInvoiceIds.filter(
        (orderId) => !selectedOrderIdsForBulkDownload.includes(orderId),
      );
      setCurrentlyDownloadingInvoiceIds(updatedListOfDownloadingInvoices);
    } else if (documentTypeForBulkDownload === PDF_DOCUMENT_TYPES.PURCHASE_ORDER) {
      const updatedListOfDownloadingPurchaseOrderIds = currentlyDownloadingPurchaseOrderIds.filter(
        (orderId) => !selectedOrderIdsForBulkDownload.includes(orderId),
      );
      setCurrentlyDownloadingPurchaseOrderIds(updatedListOfDownloadingPurchaseOrderIds);
    } else if (documentTypeForBulkDownload === PDF_DOCUMENT_TYPES.DELIVERY_SLIP) {
      const updatedListOfDownloadingDeliverySlipIds = currentlyDownloadingDocketIds.filter(
        (orderId) => !selectedOrderIdsForBulkDownload.includes(orderId),
      );
      setCurrentlyDownloadingDocketIds(updatedListOfDownloadingDeliverySlipIds);
    }
    setSelectedOrderIdsForBulkDownload([]);
    handleCloseDownloadingPDFsDialog();
  };

  const clearSelectedOrders = () => {
    const updatedOrders = [...orders];
    const deselectedOrders = updatedOrders.map((order) => ({
      ...order,
      isSelected: false,
    }));
    setOrders(deselectedOrders);
    setNumberOfSelectedOrders(0);
  };

  const handleSelectOrderStatusFilter = (filterId) => {
    setCurrentPageNumber(1);
    setSelectedOrderStatusFilterId(filterId);
    updateOrderFilterIdQueryParam(filterId);
    handleCloseOrderStatusFilterMenu();
  };

  const handleSelectedOrderTypeFilter = (filterId) => {
    setCurrentPageNumber(1);
    setSelectedOrderTypeFilterId(filterId);
    updateOrderTypeIdQueryParam(filterId);
    handleCloseOrderTypeFilterMenu();
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchSupplierDetails = useCallback(() => {
    FoodbombAPI.get(`suppliers/auth/me`)
      .then((response) => {
        setSupplierEmail(response.data.email);
      })
      .catch((error) => {
        sendDatadogError(`Unable to load supplier details`, {
          error,
          location: 'Orders page',
        });
      });
  }, [sendDatadogError]);
  const clearSearch = () => {
    setSearchQuery('');
    setApiSearchQuery('');
    updateSearchQueryParam('');
  };

  const handleSearch = () => {
    updateSearchQueryParam(searchQuery);
    setApiSearchQuery(searchQuery);
    setCurrentPageNumber(1);
  };

  const buildTableSearchQuery = () => {
    let tableSearchQuery = '';
    const currentStatusFilter = STATUS_FILTERS.find((filter) => filter.id === selectedOrderStatusFilterId);
    const currentOrderTypeFilter = ORDER_TYPE_FILTER.find((filter) => filter.id === selectedOrderTypeFilterId);

    if (apiSearchQuery) {
      tableSearchQuery += `${apiSearchQuery} `;
    }
    if (currentStatusFilter?.filterValue !== undefined) {
      tableSearchQuery += `in ${currentStatusFilter.title.toLowerCase()}`;
    }
    if (currentOrderTypeFilter?.filterValue !== undefined) {
      tableSearchQuery += `in ${currentOrderTypeFilter.title.toLowerCase()}`;
    }
    return tableSearchQuery || undefined;
  };

  const handleColumnHeaderClick = (valueToSortBy, shouldSortByMin) => {
    setOrderBy(mapValueToSortByToAPIField(valueToSortBy));
    setSortBy(shouldSortByMin ? SORT_BY_METHODS.ASC : SORT_BY_METHODS.DESC);
  };

  const onNextPageClick = () => {
    setCurrentPageNumber((prev) => {
      const newPageNumber = prev + 1;
      updatePageNumberQueryParam(newPageNumber);
      return newPageNumber;
    });
  };

  const onPreviousPageClick = () => {
    setCurrentPageNumber((prev) => {
      const newPageNumber = prev - 1;
      updatePageNumberQueryParam(newPageNumber);
      return newPageNumber;
    });
  };

  const fetchAllSupplierOrders = useCallback(() => {
    setLoadingOrders(true);
    setAPIError(undefined);
    clearSelectedOrders();

    const fields = Object.values(ORDER_API_FIELDS)
      .map((field) => field.endpointKey)
      .join(';');

    const fuzzyFields = Object.values(ORDER_API_FIELDS)
      .filter((field) => field.searchable)
      .map((field) => `${field.endpointKey};`)
      .join('');

    const fuzzySearch = apiSearchQuery;

    let filterSearches = '';
    let filterFields = '';

    const currentStatusFilter = STATUS_FILTERS.find((filter) => filter.id === selectedOrderStatusFilterId);
    if (currentStatusFilter?.filterValue !== undefined) {
      filterFields += `${currentStatusFilter.filterField}:=;`;
      filterSearches += `${currentStatusFilter.filterField}:${currentStatusFilter.filterValue};`;
    }

    const currentOrdersTypeFilter = ORDER_TYPE_FILTER.find((filter) => filter.id === selectedOrderTypeFilterId);
    if (currentOrdersTypeFilter?.filterValue !== undefined || currentOrdersTypeFilter?.filterKeyValues?.length) {
      if (currentOrdersTypeFilter?.filterKeyValues?.length) {
        const orderTypeFilterFieldsString = currentOrdersTypeFilter.filterKeyValues
          .map((fkv) => `${fkv.key}:${fkv.comparison}`)
          .join(';');
        const orderTypeFilterSearchesString = currentOrdersTypeFilter.filterKeyValues
          .map((fkv) => `${fkv.key}:${fkv.value}`)
          .join(';');
        filterFields += orderTypeFilterFieldsString;
        filterSearches += orderTypeFilterSearchesString;
      } else {
        filterFields += `${currentOrdersTypeFilter.filterField}:=;`;
        filterSearches += `${currentOrdersTypeFilter.filterField}:${currentOrdersTypeFilter.filterValue};`;
      }
    }

    FoodbombAPI.get(
      `/suppliers/orders?pageSize=${pageSize}&page=${currentPageNumber}&filter=${fields}&orderBy=${orderBy}&sortedBy=${sortBy}&fuzzyFields=${fuzzyFields}&fuzzy=${fuzzySearch}&searchFields=${filterFields}&search=${filterSearches}`,
    )
      .then((response) => {
        const orderData = response.data.data.map((order) => ({
          id: order.orderId,
          status: order.orderStatus,
          name: order.fullName,
          fullName: order.fullName,
          shippingCompany: order.shippingCompany,
          dateOrdered: order.dateAdded,
          deliveryDate: order.deliveryDate,
          total: order.total,
          isSelected: false,
          orderedBefore: order.orderedBefore,
          customerAlert: order.customerAlert,
          substitutionAllowed: order.substitutionAllowed,
          standingOrderId: order.standingOrderId,
          parentOrderId: order.parentOrderId,
          supplierReference: order.supplierReference,
        }));
        setOrders(orderData);
        setPageSize(response.data.perPage);
        setOrdersResultsLength(response.data.total);
        setCurrentPageNumber(response.data.currentPage);
        updatePageNumberQueryParam(response.data.currentPage);
        setShouldShowSearchBar(true);
      })
      .catch((error) => {
        sendDatadogError('Unable to load orders', {
          error,
          location: 'Orders Page',
        });
        setAPIError(
          <ErrorNotification
            actions={
              <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={() => window.location.reload(true)}>
                Reload this page
              </Button>
            }
          />,
        );
      })
      .finally(() => {
        setLoadingOrders(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPageNumber,
    orderBy,
    sortBy,
    pageSize,
    sendDatadogError,
    apiSearchQuery,
    selectedOrderStatusFilterId,
    selectedOrderTypeFilterId,
  ]);

  const createStatusChip = (order) => <OrderStatusChip apiOrderStatus={order.status} />;
  const createOrderTypeChip = (orderType) => <OrderTypeChip apiOrderType={orderType} />;

  const createCustomerAlertChip = (order) => (
    <>{order.customerAlert ? <CustomerAlertChip customerAlert={order.customerAlert} /> : null}</>
  );

  const handleRowClick = (order) => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 768) {
      redirectToOrder(order.id);
    }
  };

  const ordersColumnSpecs = [
    {
      index: 0,
      title: 'Order ID',
      sortMethod: 'id',
      className: styles.OrderID,
      columnDataFunction: (order) => order.id,
      searchable: true,
    },
    {
      index: 1,
      title: 'Supplier Order ID',
      className: styles.SupplierOrderId,
      columnDataFunction: (order) => order?.supplierReference,
      searchable: true,
    },
    {
      index: 2,
      title: 'Order Type',
      className: styles.OrderType,
      columnDataFunction: (order) => order?.standingOrderId,
      getChild: (order) => {
        let orderType = ORDER_TYPES.REGULAR;
        if (order?.parentOrderId) {
          orderType = ORDER_TYPES.TOPUP;
        } else if (order?.standingOrderId) {
          orderType = ORDER_TYPES.STANDING;
        }
        return createOrderTypeChip(orderType);
      },
    },
    {
      index: 3,
      title: '🗓 Placed',
      className: styles.Placed,
      searchable: true,
      sortMethod: 'dateOrdered',
      columnDataFunction: (order) => Moment(order.dateOrdered).format('D MMM YYYY hh:mm a'),
    },
    {
      index: 4,
      title: '🗓 Delivery',
      className: styles.Delivery,
      searchable: true,
      sortMethod: 'deliveryDate',
      columnDataFunction: (order) => getDateText(order.deliveryDate),
      // eslint-disable-next-line react/display-name
      getChild: (order) => getDateLabel(order.deliveryDate),
    },
    {
      index: 5,
      className: styles.CompanyName,
      title: 'Venue Name',
      sortMethod: 'venue',
      columnDataFunction: (order) => order.shippingCompany,
      // eslint-disable-next-line react/display-name
      getChild: (order) => <div className={styles.VenueName}>&nbsp;{order.shippingCompany}</div>,
      searchable: true,
    },
    {
      index: 6,
      className: styles.CustomerName,
      title: 'Customer Name',
      sortMethod: 'fullName',
      columnDataFunction: (order) => order.fullName,
      // eslint-disable-next-line react/display-name
      getChild: (order) => (
        <div className={styles.VenueColumnChild}>
          <div className={styles.VenueName}>{order.fullName}</div>
        </div>
      ),
      searchable: true,
    },
    {
      index: 7,
      title: 'Alert',
      className: styles.CustomerAlert,
      columnDataFunction: (order) => order.customerAlert,
      getChild: createCustomerAlertChip,
    },
    {
      index: 8,
      title: 'Status',
      className: styles.Status,
      sortMethod: 'status',
      columnDataFunction: (order) => order.status,
      getChild: createStatusChip,
      searchable: true,
    },
    {
      index: 9,
      title: 'Value',
      sortMethod: 'total',
      className: styles.OrderValue,
      columnDataFunction: (order) => presentCurrency(order.total / 100),
      searchable: true,
    },
  ];

  const getOrderIdsToDownloadFromSelected = (downloadType) => {
    switch (downloadType) {
      case PDF_DOCUMENT_TYPES.INVOICE:
        return orders
          .filter((order) => order.status === ORDER_STATUSES.COMPLETED && order[IS_SELECTED_KEY] === true)
          .map((order) => order.id);
      case PDF_DOCUMENT_TYPES.DELIVERY_SLIP:
        return orders
          .filter((order) => order.status !== ORDER_STATUSES.CANCELLED && order[IS_SELECTED_KEY] === true)
          .map((order) => order.id);
      case PDF_DOCUMENT_TYPES.PURCHASE_ORDER:
        return orders.filter((order) => order[IS_SELECTED_KEY] === true).map((order) => order.id);
      default:
        return orders.filter((order) => order[IS_SELECTED_KEY] === true).map((order) => order.id);
    }
  };

  const isDownloadingPDFsToBrowser = () =>
    Boolean(
      currentlyDownloadingDocketIds.length ||
        currentlyDownloadingInvoiceIds.length ||
        currentlyDownloadingPurchaseOrderIds.length ||
        currentlyDownloadingDeliveryDetailIds.length,
    );

  const onSelectedItemsChanged = (itemsToUpdate, shouldBeSelected) => {
    const updatedOrders = [...orders];

    itemsToUpdate.forEach((itemToUpdate) => {
      const idxToUpdate = orders.findIndex((item) => item.id === itemToUpdate.id);
      updatedOrders[idxToUpdate][IS_SELECTED_KEY] = shouldBeSelected;
    });

    const selectedCount = updatedOrders
      .filter((order) => order[IS_SELECTED_KEY] === true)
      .map((order) => order.id).length;
    setNumberOfSelectedOrders(selectedCount);

    setOrders(updatedOrders);
  };

  const buildNewCancelSource = () => FoodbombPDFAPI.CancelToken.source();

  const generateAndStoreCancelTokenForPDFDownload = () => {
    const cancelSource = buildNewCancelSource();
    setDownloadingOrdersPDFCancelSource(cancelSource);
    return cancelSource.token;
  };

  const downloadOrderDeliveryDetails = (orderId) => {
    setCurrentlyDownloadingDeliveryDetailIds(currentlyDownloadingDeliveryDetailIds.concat(orderId));
    const apiUrl = `orders/${orderId}/delivery-instructions`;
    FoodbombPDFAPI.get(apiUrl, { responseType: 'blob' })
      .then((response) => {
        if (!response.data) {
          throw new TypeError(
            `Expected data of type Object for get delivery instructions, got ${JSON.stringify(response)}`,
          );
        }
        saveAs(response.data, PDFFileNameFromResponse(response));
      })
      .catch((error) => {
        sendDatadogError('Failed to download delivery instructions', {
          error,
          location: 'Supplier Portal Order Page',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to download the delivery instructions. Please try again later',
          timeout: 3000,
          closable: true,
        });
      })
      .finally(() => setCurrentlyDownloadingDeliveryDetailIds((prev) => prev.filter((v) => v !== orderId)));
  };

  const downloadPDFsToBrowser = (downloadAction, singleOrderIdToDownload) => {
    let orderIdsToDownloadInBrowser = [];
    let cancelToken;
    if (singleOrderIdToDownload) {
      orderIdsToDownloadInBrowser = [singleOrderIdToDownload];
    } else {
      orderIdsToDownloadInBrowser = getOrderIdsToDownloadFromSelected(downloadAction.type);
      setSelectedOrderIdsForBulkDownload(orderIdsToDownloadInBrowser);
      setDocumentTypeForBulkDownload(downloadAction.type);
      cancelToken = generateAndStoreCancelTokenForPDFDownload();
      handleOpenDownloadingPDFsDialog();
    }

    downloadAction.setDownloadState((prevDownloadingOrderIds) => [
      ...prevDownloadingOrderIds,
      ...orderIdsToDownloadInBrowser,
    ]);

    FoodbombPDFAPI.get(downloadAction.apiUrl, {
      responseType: 'blob',
      params: {
        orderIds: orderIdsToDownloadInBrowser,
      },
      cancelToken,
    })
      .then((response) => {
        saveAs(response.data, PDFFileNameFromResponse(response));
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Your ${downloadAction.title} PDF download has been completed.`,
          timeout: 4000,
          closable: true,
        });
        trackDownloadPDF(downloadAction.objectType, orderIdsToDownloadInBrowser, downloadAction.apiUrl, {
          uiPosition: PAGE_POSITION.TABLE,
        });
        clearSelectedOrders();
      })
      .catch((error) => {
        sendDatadogError(
          `Failed to download ${downloadAction.title} for orders: ${orderIdsToDownloadInBrowser.join(', ')}`,
          {
            error,
            location: 'Supplier Portal Orders Page',
          },
          3000,
        );
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: `Unable to download ${downloadAction.title} for orders ${orderIdsToDownloadInBrowser.join(
            ', ',
          )}. Please try again later`,
          timeout: 3000,
          closable: true,
        });
      })
      .finally(() => {
        downloadAction.setDownloadState((prevDownloadingOrderIds) =>
          prevDownloadingOrderIds?.filter((orderId) => !orderIdsToDownloadInBrowser.includes(orderId)),
        );
        setSelectedOrderIdsForBulkDownload([]);
        handleCloseDownloadingPDFsDialog();
      });
  };

  const handleBulkDownloadAction = (action) => {
    const numberOfOrderIdsToDownload = getOrderIdsToDownloadFromSelected(action.type).length;

    if (numberOfOrderIdsToDownload > 0 && numberOfOrderIdsToDownload <= MAX_NUMBER_OF_BROWSER_PDF_DOWNLOADS) {
      downloadPDFsToBrowser(action);
    } else if (numberOfOrderIdsToDownload > MAX_NUMBER_OF_BROWSER_PDF_DOWNLOADS) {
      setSelectedOrderIdsForBulkDownload(getOrderIdsToDownloadFromSelected(action.type));
      setDocumentTypeForBulkDownload(action.type);
      handleOpenEmailDownloadPDFDialog();
    } else {
      createNotification({
        type: NOTIFICATION_TYPES.ERROR,
        content: `Unable to download ${action.title} for the selected orders.  ${action.errorMessage}`,
        timeout: 6000,
        closable: true,
      });
    }
    handleCloseBulkActionsDropDown();
  };

  const DOWNLOAD_ACTIONS = [
    {
      type: PDF_DOCUMENT_TYPES.DELIVERY_SLIP,
      title: 'Delivery Slip',
      setDownloadState: setCurrentlyDownloadingDocketIds,
      downloadState: currentlyDownloadingDocketIds,
      apiUrl: 'orders/delivery-dockets',
      validStatuses: DELIVERY_DOCKET_VALID_STATUSES,
      objectType: OBJECT_TYPES.DELIVERY_SLIP,
      errorMessage: 'Please note you cannot download Delivery slips for cancelled orders. ',
    },
    {
      type: PDF_DOCUMENT_TYPES.INVOICE,
      title: 'Invoice',
      setDownloadState: setCurrentlyDownloadingInvoiceIds,
      downloadState: currentlyDownloadingInvoiceIds,
      apiUrl: 'orders/invoices',
      validStatuses: [ORDER_STATUSES.COMPLETED],
      objectType: OBJECT_TYPES.INVOICE,
      errorMessage: 'Please note you can only download Invoices for completed Orders. ',
    },
    {
      type: PDF_DOCUMENT_TYPES.PURCHASE_ORDER,
      title: 'Purchase Order',
      setDownloadState: setCurrentlyDownloadingPurchaseOrderIds,
      downloadState: currentlyDownloadingPurchaseOrderIds,
      apiUrl: 'orders/purchase-orders',
      validStatuses: [
        ORDER_STATUSES.COMPLETED,
        ORDER_STATUSES.PENDING,
        ORDER_STATUSES.PROCESSING,
        ORDER_STATUSES.CANCELLED,
      ],
      objectType: OBJECT_TYPES.PURCHASE_ORDER,
      errorMessage: '',
    },
  ];

  const createRowActions = (order) => (
    <React.Fragment>
      <Button
        onClick={() => redirectToOrderWithQueryParams(order.id, window.location?.search)}
        className={styles.RowAction}
        variant={BUTTON_VARIANTS.SECONDARY}
        size={BUTTON_SIZES.EXTRA_SMALL}
      >
        View Order
      </Button>
      {DOWNLOAD_ACTIONS.filter((action) => action.validStatuses.includes(order.status)).map((action) => {
        const orderIsCurrentlyDownloading = action.downloadState.includes(order.id);
        return (
          <Button
            key={`${action.apiUrl}_for_${order.id}`}
            onClick={() => downloadPDFsToBrowser(action, order.id)}
            className={styles.RowAction}
            variant={BUTTON_VARIANTS.SECONDARY}
            size={BUTTON_SIZES.EXTRA_SMALL}
            disabled={orderIsCurrentlyDownloading}
          >
            {orderIsCurrentlyDownloading ? (
              <React.Fragment>
                <CircularProgress size={18} className={styles.SubmitBtn__loading} />
                &nbsp;Downloading...
              </React.Fragment>
            ) : (
              <React.Fragment>
                <GetAppOutlinedIcon className={styles.ActionButton__icon} />
                {action.title}
              </React.Fragment>
            )}
          </Button>
        );
      })}

      <Button
        onClick={() => downloadOrderDeliveryDetails(order.id)}
        className={
          order.customerAlert === CUSTOMER_ALERT_TYPES.UPDATED_DETAILS
            ? [styles.RowAction, styles.StandOutBtn].join(' ')
            : styles.RowAction
        }
        variant={BUTTON_VARIANTS.SECONDARY}
        size={BUTTON_SIZES.EXTRA_SMALL}
      >
        {currentlyDownloadingDeliveryDetailIds.includes(order.id) ? (
          <React.Fragment>
            <CircularProgress size={18} className={styles.SubmitBtn__loading} />
            &nbsp;Downloading...
          </React.Fragment>
        ) : (
          <React.Fragment>
            <GetAppOutlinedIcon className={styles.ActionButton__icon} />
            Delivery Details
          </React.Fragment>
        )}
      </Button>
    </React.Fragment>
  );

  useEffect(() => {
    fetchSupplierDetails();
  }, [fetchSupplierDetails]);

  useEffect(() => {
    const fileDownloadLength =
      currentlyDownloadingDocketIds.length +
      currentlyDownloadingInvoiceIds.length +
      currentlyDownloadingPurchaseOrderIds.length +
      currentlyDownloadingDeliveryDetailIds.length;
    if (fileDownloadLength) {
      createDownloadInProgressNotification(fileDownloadLength);
    } else {
      removeDownloadInProgressNotification();
    }
    return () => removeDownloadInProgressNotification();
  }, [
    currentlyDownloadingPurchaseOrderIds,
    currentlyDownloadingInvoiceIds,
    currentlyDownloadingDocketIds,
    currentlyDownloadingDeliveryDetailIds,
    createDownloadInProgressNotification,
    removeDownloadInProgressNotification,
  ]);

  useEffect(() => {
    if (numberOfSelectedOrders > MAX_NUMBER_OF_BROWSER_PDF_DOWNLOADS) {
      createNotification({
        type: NOTIFICATION_TYPES.INFO,
        content: `Download will be emailed to you. Direct download maximum is ${MAX_NUMBER_OF_BROWSER_PDF_DOWNLOADS} items`,
        uniqueTargetGroup: BULK_DOWNLOAD_COUNT_NOTIFICATION_TARGET_GROUP,
        closable: true,
      });
    } else {
      removeNotificationOfTargetGroup(BULK_DOWNLOAD_COUNT_NOTIFICATION_TARGET_GROUP);
    }
    return () => removeNotificationOfTargetGroup(BULK_DOWNLOAD_COUNT_NOTIFICATION_TARGET_GROUP);
  }, [numberOfSelectedOrders, createNotification, removeNotificationOfTargetGroup]);

  useEffect(() => {
    fetchAllSupplierOrders();
  }, [fetchAllSupplierOrders]);

  useEffect(
    () => () => {
      downloadingOrdersPDFCancelSource?.cancel('Operation Cancelled as the user navigated away from the page.');
    },
    [downloadingOrdersPDFCancelSource],
  );

  return (
    <React.Fragment>
      <EmailDownloadPDFDialog
        handleClose={handleCloseEmailDownloadPDFDialog}
        isOpen={emailDownloadPDFDialogOpen}
        orderIds={selectedOrderIdsForBulkDownload}
        documentType={documentTypeForBulkDownload}
        defaultEmail={supplierEmail}
        handleClearSelectedOrders={clearSelectedOrders}
      />

      <div className={styles.PageContainer}>
        <PageHeader>
          <div className={styles.SubheadingContainer}>
            <Breadcrumbs
              currentPageTitle={
                <>
                  <Emoji content="📋" label="clipboard" />
                  &nbsp; Orders
                </>
              }
            />
          </div>
          <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.Font__standardGrey}>
            Here are all of your orders
          </Typography>
          <div className={styles.ActionBar}>
            <div className={styles.SearchBarContainer}>
              {shouldShowSearchBar ? (
                <SearchBar
                  placeholder="Search orders"
                  handleSearch={handleSearch}
                  onChange={handleSearchChange}
                  handleClear={clearSearch}
                  value={searchQuery}
                />
              ) : null}
            </div>
            <div className={styles.ActionBar__buttonsContainer}>
              <Button
                variant={selectedOrderStatusFilterId ? BUTTON_VARIANTS.SUCCESS : BUTTON_VARIANTS.SECONDARY}
                className={styles.ActionBar__button}
                onClick={handleOrderStatusFilterClicked}
              >
                <FilterList className={styles.ActionButton__icon} />
                {`${STATUS_FILTERS.find((filter) => filter.id === selectedOrderStatusFilterId).title}`}
                <ArrowDropDownIcon className={styles.DropdownIcon} />
              </Button>

              <Button
                className={styles.ActionBar__button}
                variant={BUTTON_VARIANTS.SECONDARY}
                onClick={handleDownloadsDropdownClicked}
              >
                <GetAppOutlinedIcon className={styles.ActionButton__icon} />
                Downloads
                <ArrowDropDownIcon className={styles.DropdownIcon} />
              </Button>

              <Tooltip
                title={
                  selectedOrderIdsForBulkDownload.length
                    ? 'Please wait, You can only perform one bulk direct download at a time.'
                    : 'Select orders (checkboxes) before to apply bulk actions'
                }
                placement="bottom"
              >
                <div>
                  <Button
                    variant={BUTTON_VARIANTS.SECONDARY}
                    className={styles.ActionBar__button}
                    onClick={handleOpenBulkActionsDropDown}
                    disabled={!numberOfSelectedOrders || Boolean(selectedOrderIdsForBulkDownload.length)}
                  >
                    Bulk Actions&nbsp;
                    <React.Fragment>
                      {selectedOrderIdsForBulkDownload.length ? (
                        <CircularProgress size={18} className={styles.SubmitBtn__loading} />
                      ) : (
                        <ArrowDropDownIcon className={styles.DropdownIcon} />
                      )}
                    </React.Fragment>
                  </Button>
                </div>
              </Tooltip>
              <Button
                variant={selectedOrderTypeFilterId ? BUTTON_VARIANTS.SUCCESS : BUTTON_VARIANTS.SECONDARY}
                className={styles.ActionBar__button}
                onClick={handleOrderTypeFilterClick}
              >
                <FilterList className={styles.ActionButton__icon} />
                {`${ORDER_TYPE_FILTER.find((filter) => filter.id === selectedOrderTypeFilterId).title}`}
                <ArrowDropDownIcon className={styles.DropdownIcon} />
              </Button>
            </div>
            <Menu
              open={Boolean(orderStatusFilterAnchorElem)}
              anchorEl={orderStatusFilterAnchorElem}
              onClose={handleCloseOrderStatusFilterMenu}
            >
              {STATUS_FILTERS.map((filter) => (
                <MenuItem
                  key={filter.title}
                  onClick={() => handleSelectOrderStatusFilter(filter.id)}
                  classes={{ root: styles.MenuLink }}
                >
                  {filter.title}
                </MenuItem>
              ))}
            </Menu>
            <Menu
              open={Boolean(orderTypeFilterAnchorElem)}
              anchorEl={orderTypeFilterAnchorElem}
              onClose={handleCloseOrderTypeFilterMenu}
            >
              {ORDER_TYPE_FILTER.map((filter) => (
                <MenuItem
                  key={filter.title}
                  onClick={() => handleSelectedOrderTypeFilter(filter.id)}
                  classes={{ root: styles.MenuLink }}
                >
                  {filter.title}
                </MenuItem>
              ))}
            </Menu>
            <Menu
              open={Boolean(downloadDropDownAnchorElem)}
              anchorEl={downloadDropDownAnchorElem}
              onClose={handleCloseDownloadDropdownMenu}
            >
              <MenuItem
                classes={{ root: styles.MenuLink }}
                onClick={() => {
                  handleCloseDownloadDropdownMenu();
                  openDownloadOrdersCSVDialog();
                }}
              >
                Orders CSV
              </MenuItem>
              {Object.values(DOWNLOAD_ORDER_PRODUCTS_FOR_DELIVERY_DAY_CSV).includes(supplierId) ? (
                <MenuItem
                  classes={{ root: styles.MenuLink }}
                  onClick={() => {
                    handleCloseDownloadDropdownMenu();
                    openDownloadProductsForDeliveryDayDialog();
                  }}
                >
                  Products for delivery day
                </MenuItem>
              ) : null}
            </Menu>
            <Menu
              open={Boolean(bulkActionsDropDownAnchorElem)}
              anchorEl={bulkActionsDropDownAnchorElem}
              onClose={handleCloseBulkActionsDropDown}
            >
              {DOWNLOAD_ACTIONS.map((downloadAction) => (
                <MenuItem
                  key={downloadAction.type}
                  onClick={() => handleBulkDownloadAction(downloadAction)}
                  classes={{ root: styles.MenuLink }}
                  disabled={!getOrderIdsToDownloadFromSelected(downloadAction.type).length}
                >
                  <GetAppOutlinedIcon className={styles.BulkActionButton__icon} />
                  {downloadAction.title} ({getOrderIdsToDownloadFromSelected(downloadAction.type).length})
                </MenuItem>
              ))}
            </Menu>
          </div>
        </PageHeader>
        {APIError ? (
          <div className={styles.ErrorWrapper}>{APIError}</div>
        ) : (
          <div className={styles.TableContainer}>
            {numberOfSelectedOrders > 0 ? (
              <div className={styles.NumberSelectedOrdersBar}>
                <Badge
                  standalone
                  badgeContent={numberOfSelectedOrders}
                  className={styles.NumberSelectedOrdersBar__badge}
                  max={100}
                >
                  <span></span>
                </Badge>
                Orders Selected
              </div>
            ) : (
              <div className={styles.NumberSelectedOrdersBar__space}></div>
            )}
            <MultiSelectTable
              className={styles.OrdersTable}
              columnSpecs={ordersColumnSpecs}
              rowData={orders}
              searchQuery={buildTableSearchQuery()}
              loadingRowData={loadingOrders}
              onSelectedItemsChanged={onSelectedItemsChanged}
              isSelectedKey={IS_SELECTED_KEY}
              handleRowClick={handleRowClick}
              grow
              rowOverlayColSpan={6}
              sortAscending={false}
              rowActions={createRowActions}
              isFiltering={Boolean(selectedOrderStatusFilterId) || Boolean(selectedOrderTypeFilterId)}
              paginationOverrideData={{
                currentPageNumber,
                onNextPageClick,
                onPreviousPageClick,
                pageSize,
                numberOfResults: ordersResultsLength,
              }}
              useServerSidePagination
              sortOverrideMethod={handleColumnHeaderClick}
              noResultsComponent={
                <Paper className={styles.NoResultsContainer}>
                  <div className={styles.TypographySection}>
                    <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
                      <span role="img" aria-label="heartbreak">
                        👋
                      </span>
                    </Typography>
                    <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Welcome to the orders page</Typography>
                    <div className={styles.TypographySection}>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                        Here is where we&apos;ll display all of your orders.
                      </Typography>
                    </div>
                  </div>
                </Paper>
              }
              displaySelectAllCheckbox
            />
          </div>
        )}
        <DownloadProductsForDeliveryDayDialog
          isOpen={downloadProductsForDeliveryDayDialogOpen}
          handleClose={closeDownloadProductsForDeliveryDayDialog}
        />
        <DownloadOrdersDialog isOpen={downloadOrdersCSVDialogOpen} handleClose={closeDownloadOrdersCSVDialog} />
        <DownloadingPDFsDialog
          isOpen={downloadingPDFsDialogOpen}
          handleClose={handleCloseDownloadingPDFsDialog}
          handleCancel={cancelBrowserDownload}
        />
        <Prompt
          when={isDownloadingPDFsToBrowser()}
          message={'You have Order PDFs downloading, these will be cancelled if you leave the page. Are you sure?'}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isStaff: state.auth.supplierDetails ? state.auth.supplierDetails.isStaff : false,
  supplierId: state.auth.supplierDetails ? state.auth.supplierDetails.id : undefined,
});

Orders.propTypes = {
  supplierId: PropTypes.number.isRequired,
  isStaff: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  createNotification: PropTypes.func.isRequired,
  redirectToOrderWithQueryParams: PropTypes.func.isRequired,
  redirectToOrder: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  getQueryFromQueryString: PropTypes.func.isRequired,
  updateSearchQueryParam: PropTypes.func.isRequired,
  updateOrderFilterIdQueryParam: PropTypes.func.isRequired,
  updateOrderTypeIdQueryParam: PropTypes.func.isRequired,
  getOrderFilterIdQueryFromQueryString: PropTypes.func.isRequired,
  updatePageNumberQueryParam: PropTypes.func.isRequired,
  getPageNumberQueryFromQueryString: PropTypes.func.isRequired,
  trackDownloadPDF: PropTypes.func.isRequired,
  createDownloadInProgressNotification: PropTypes.func.isRequired,
  removeDownloadInProgressNotification: PropTypes.func.isRequired,
  removeNotificationOfTargetGroup: PropTypes.func.isRequired,
};

export default withEventTracking(
  withErrorReports(
    withRedirectHelper(withNotifications(withRouter(withQueryParams(connect(mapStateToProps, null)(Orders))))),
  ),
);
