import * as actionTypes from './ActionTypes';

export const setFoodbombAPIVersion = (foodbombAPIVersion) => ({
  type: actionTypes.SET_FOODBOMB_API_VERSION,
  payload: {
    foodbombAPIVersion,
  },
});

export const resetFoodbombAPIVersion = () => ({
  type: actionTypes.RESET_FOODBOMB_API_VERSION,
});

export const setFoodbombPDFAPIVersion = (foodbombPDFAPIVersion) => ({
  type: actionTypes.SET_FOODBOMB_PDF_API_VERSION,
  payload: {
    foodbombPDFAPIVersion,
  },
});

export const resetFoodbombPDFAPIVersion = () => ({
  type: actionTypes.RESET_FOODBOMB_PDF_API_VERSION,
});

export const setDeliveryPreferencesAPIVersion = (deliveryPreferencesAPIVersion) => ({
  type: actionTypes.SET_DELIVERY_PREFERENCES_API_VERSION,
  payload: {
    deliveryPreferencesAPIVersion,
  },
});

export const resetDeliveryPreferencesAPIVersion = () => ({
  type: actionTypes.RESET_DELIVERY_PREFERENCES_API_VERSION,
});
