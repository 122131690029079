import React from 'react';
import PropTypes from 'prop-types';

import { DAYS } from '../../../../utils/TimeConstants';
import { Typography } from '../../../../components/UI/FB';
import { TYPOGRAPHY_TYPES } from '../../../../utils/Constants';
import styles from './DeliveryDaysStatus.module.scss';

const DeliveryDaysStatus = ({ activeDays, greyscale, classes }) => {
  const containerStyles = [styles.DeliveryDaysContainer];
  const dayStyles = [styles.DeliveryDays__day];
  const activeDayStyles = [styles.Day__active];
  const inactiveDayStyles = [styles.Day__inactive];

  if (classes?.container) containerStyles.push(classes.container);
  if (classes?.day) dayStyles.push(classes.day);
  if (classes?.activeDay) activeDayStyles.push(classes.activeDay);
  if (classes?.inactiveDay) inactiveDayStyles.push(classes.inactiveDay);

  if (greyscale) dayStyles.push(styles.Greyscale);

  const getDayClasses = (day) => {
    if (activeDays.includes(day)) {
      return dayStyles.concat(activeDayStyles).join(' ');
    }
    return dayStyles.concat(inactiveDayStyles).join(' ');
  };

  return (
    <div className={containerStyles.join(' ')}>
      <span>
        {DAYS.map((day) => (
          <Typography key={`${day}`} type={TYPOGRAPHY_TYPES.BODY_BOLD} className={getDayClasses(day)}>
            {day[0].toUpperCase()}
          </Typography>
        ))}
      </span>
    </div>
  );
};

DeliveryDaysStatus.propTypes = {
  activeDays: PropTypes.array.isRequired,
  greyscale: PropTypes.bool,
  classes: PropTypes.shape({
    container: PropTypes.string,
    day: PropTypes.string,
    inactiveDay: PropTypes.string,
    activeDay: PropTypes.string,
  }),
};

export default DeliveryDaysStatus;
