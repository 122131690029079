import React from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';
import styles from './Login.module.scss';

const Login = () => (
  <div className={styles.LoginPageContainer}>
    <LoginForm />
  </div>
);

export default Login;
