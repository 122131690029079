export const localStorageGetItem = (key) => {
  try {
    return window.localStorage.getItem(key);
  } catch (exception) {
    return null;
  }
};

export const sessionStorageGetItem = (key) => {
  try {
    return window.sessionStorage.getItem(key);
  } catch (exception) {
    return null;
  }
};

export const localStorageSetItem = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (exception) {
    // nothing to do
  }
};

export const sessionStorageSetItem = (key, value) => {
  try {
    window.sessionStorage.setItem(key, value);
  } catch (exception) {
    // nothing to do
  }
};

export const localStorageRemoveItem = (key) => {
  try {
    window.localStorage.removeItem(key);
  } catch (exception) {
    // nothing to do
  }
};
