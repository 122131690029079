import React from 'react';
import PropTypes from 'prop-types';

import styles from './FloatingBanner.module.scss';

const FloatingBanner = ({ rainbow, percentComplete, children, hide, onClick }) => (
  <button
    className={hide ? [styles.FloatingBannerContainer, styles.hide].join(' ') : styles.FloatingBannerContainer}
    onClick={onClick}
  >
    <div className={hide ? [styles.FloatingBanner, styles.hide].join(' ') : styles.FloatingBanner}>
      {rainbow ? <div style={{ width: `${percentComplete}%` }} className={styles.RainbowBackground}></div> : null}
      <div className={styles.FloatingBannerContent}>{hide ? '🎭' : children}</div>
    </div>
  </button>
);

FloatingBanner.propTypes = {
  children: PropTypes.node,
  rainbow: PropTypes.bool,
  percentComplete: PropTypes.number,
  hide: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FloatingBanner;
