import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import LargeLoadingSpinner from '../../components/UI/LargeLoadingSpinner/LargeLoadingSpinner';
import { Typography } from '../../components/UI/FB';
import {
  ACTIVE_VENUE_STATUS,
  MAX_NUMBER_OF_LOSS_LEADER_PRODUCTS,
  NOTIFICATION_TYPES,
  PERSONAL_VENUE_TYPE,
  TYPOGRAPHY_TYPES,
} from '../../utils/Constants';
import OrderThreshold from '../../components/OrderThreshold/OrderThreshold';
import LossLeaderProducts from '../../components/LossLeaderProducts/LossLeaderProducts';
import LossLeaderVenues from '../../components/LossLeaderVenues/LossLeaderVenues';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import { CreateNotification, SendErrorReportType } from '../../utils/Presenters/ReduxType';
import { buildPriceLabelForProduct } from '../../utils/PortionSizePricingHelper/PortionSizePricingHelper';
import {
  LossLeaderVenues as Venues,
  LossLeaderVenuesForSelect,
  PresentedLossLeaderVenue,
  LossLeaderProducts as Products,
  PresentedLossLeaderProducts,
  LossLeaderProductsForSelect,
} from './LossLeaderTypes';
import styles from './LossLeaders.module.scss';

type LossLeadersTypes = {
  sendDatadogError: SendErrorReportType;
  createNotification: CreateNotification;
};

const LossLeaders: FC<LossLeadersTypes> = ({ sendDatadogError, createNotification }) => {
  const [lossProducts, setLossProducts] = useState<PresentedLossLeaderProducts[] | null>(null);
  const [lossVenues, setLossVenues] = useState<PresentedLossLeaderVenue[] | null>(null);
  const [products, setProducts] = useState<LossLeaderProductsForSelect[]>([]);
  const [venues, setVenues] = useState<LossLeaderVenuesForSelect[]>([]);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const [loadingLossLeaderProducts, setLoadingLossLeaderProducts] = useState<boolean>(false);
  const [loadingLossLeaderVenues, setLoadingLossLeaderVenues] = useState<boolean>(false);
  const [minimumAmount, setMinimumAmount] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [APIError, setAPIError] = useState<boolean>(false);

  // fetch loss leaders minimum amount
  const fetchLossLeaderMinimumAmount = useCallback(() => {
    setIsLoading(true);
    setAPIError(false);
    FoodbombAPI.get('/suppliers/loss-products-minimum-spend')
      .then((response: any) => {
        setMinimumAmount(response?.data?.lossProductsMinimumSpend);
      })
      .catch((error: AxiosError) => {
        sendDatadogError('Unable to update threshold minimum amount.', {
          error,
          location: 'Loss leader page',
        });
        setAPIError(true);
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to load the loss product minimum amount',
          timeout: 4000,
          closable: true,
        });
      })
      .finally(() => setIsLoading(false));
  }, [createNotification, sendDatadogError]);

  const buildSearchLabelForProduct = useCallback(
    (product) => `${product.sku ? `${product.sku},` : ''} ${product.name} (${buildPriceLabelForProduct(product)})`,
    [],
  );
  const buildSearchLabelForVenue = useCallback((venue) => `#${venue.id}, ${venue.venue}`, []);

  // fetch venues
  const fetchVenues = useCallback(() => {
    setLoadingLossLeaderVenues(true);
    setAPIError(false);
    FoodbombAPI.get('/venues', { credentials: 'include' })
      .then((response) => {
        setVenues(
          response?.data?.venues
            .map((venue: Venues) => ({
              ...venue,
              label: buildSearchLabelForVenue(venue),
              value: venue.id,
            }))
            .filter(
              (c: LossLeaderVenuesForSelect) => c.status === ACTIVE_VENUE_STATUS && c.venueType !== PERSONAL_VENUE_TYPE,
            ),
        );
      })
      .catch((error: AxiosError) => {
        sendDatadogError('Unable to fetch venues for supplier', {
          error,
          location: 'loss leader products page',
        });
        setAPIError(true);
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to load venues',
          timeout: 4000,
          closable: true,
        });
      })
      .finally(() => {
        setLoadingLossLeaderVenues(false);
      });
  }, [buildSearchLabelForVenue, createNotification, sendDatadogError]);

  // fetch products
  const fetchProducts = useCallback(() => {
    setLoadingProducts(true);
    setAPIError(false);
    FoodbombAPI.get(`suppliers/products`, { credentials: 'include' })
      .then((response: any) => {
        setProducts(
          response?.data?.data?.map((product: Products) => ({
            ...product,
            label: buildSearchLabelForProduct(product),
            value: product.id,
          })),
        );
      })
      .catch((error: AxiosError) => {
        sendDatadogError('Unable to fetch products for supplier', {
          error,
          location: 'Loss leaders page',
        });
        setAPIError(true);
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to load products',
          timeout: 4000,
          closable: true,
        });
      })
      .finally(() => {
        setLoadingProducts(false);
      });
  }, [buildSearchLabelForProduct, sendDatadogError, createNotification]);

  // fetch loss products
  const fetchLossLeaderProducts = useCallback(() => {
    setLoadingLossLeaderProducts(true);
    setAPIError(false);
    FoodbombAPI.get(`suppliers/loss-products`)
      .then((response: any) => {
        setLossProducts(response?.data?.data);
      })
      .catch((error: AxiosError) => {
        sendDatadogError('Unable to fetch loss products', {
          error,
          location: 'Loss leaders page',
        });
        setAPIError(true);
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to load loss leader products',
          timeout: 4000,
          closable: true,
        });
      })
      .finally(() => {
        setLoadingLossLeaderProducts(false);
      });
  }, [createNotification, sendDatadogError]);

  // fetch loss leader venues
  const fetchLossLeaderVenues = useCallback(() => {
    setLoadingLossLeaderVenues(true);
    setAPIError(false);
    FoodbombAPI.get(`suppliers/loss-venues`)
      .then((response: any) => {
        setLossVenues(response?.data?.data);
      })
      .catch((error: AxiosError) => {
        sendDatadogError('Unable to fetch loss venues', {
          error,
          location: 'Loss leaders page',
        });
        setAPIError(true);
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to load loss leader venues',
          timeout: 4000,
          closable: true,
        });
      })
      .finally(() => {
        setLoadingLossLeaderVenues(false);
      });
  }, [createNotification, sendDatadogError]);

  const handleUpdateOrderThreshold = (): void => {
    fetchLossLeaderMinimumAmount();
  };

  const handleReloadLossLeaderProducts = (): void => {
    fetchLossLeaderProducts();
  };

  const handleReloadLossLeaderVenues = (): void => {
    fetchLossLeaderVenues();
  };

  // on initial page reload, call all loss leaders endpoints
  useEffect(() => {
    const allPromises = [
      fetchLossLeaderVenues(),
      fetchProducts(),
      fetchLossLeaderMinimumAmount(),
      fetchLossLeaderProducts(),
      fetchVenues(),
    ];
    Promise.all(allPromises);
  }, [fetchLossLeaderMinimumAmount, fetchProducts, fetchLossLeaderProducts, fetchVenues, fetchLossLeaderVenues]);

  const lossLeaderProductsToDisplay = useMemo(() => {
    const lossLeadersProducts = products?.filter((product: LossLeaderProductsForSelect) =>
      lossProducts?.some((lossProduct: PresentedLossLeaderProducts) => lossProduct.productId === product.id),
    );
    return lossLeadersProducts;
  }, [lossProducts, products]);

  const lossLeaderVenuesToDisplay = useMemo(() => {
    const lossLeadersVenues = venues?.filter((venue: LossLeaderVenuesForSelect) =>
      lossVenues?.some((lossVenue: PresentedLossLeaderVenue) => lossVenue.venueId === venue.id),
    );
    return lossLeadersVenues;
  }, [lossVenues, venues]);

  return (
    <div className={styles.LossLeaderContainer}>
      <PageHeader>
        <Breadcrumbs currentPageTitle={<>&nbsp;Loss Leaders</>} />
        <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.LossLeaderSubHeader}>
          Prevent selected venues from cherry-picking your loss leader products.
        </Typography>
      </PageHeader>
      {isLoading ? (
        <LargeLoadingSpinner />
      ) : (
        <>
          <OrderThreshold
            onSuccess={handleUpdateOrderThreshold}
            minimumAmount={minimumAmount}
            sendDatadogError={sendDatadogError}
            createNotification={createNotification}
          />
          <div className={styles.LossLeaderWrapper}>
            <div className={styles.LossLeaderComponentsWrapper}>
              <LossLeaderProducts
                addProductDisabled={
                  Boolean(!minimumAmount) || lossProducts?.length === MAX_NUMBER_OF_LOSS_LEADER_PRODUCTS
                }
                loadingLossLeaderProducts={loadingLossLeaderProducts || loadingProducts}
                setLoadingLossLeaderProducts={setLoadingLossLeaderProducts}
                products={products}
                onSuccessfulLossProductAPICall={handleReloadLossLeaderProducts}
                lossLeaderProducts={lossLeaderProductsToDisplay}
                sendDatadogError={sendDatadogError}
                createNotification={createNotification}
                APIError={APIError}
              />
              <LossLeaderVenues
                addVenuesDisabled={Boolean(!minimumAmount)}
                loadingLossLeaderVenues={loadingLossLeaderVenues}
                setLoadingLossLeaderVenues={setLoadingLossLeaderVenues}
                venues={venues}
                onSuccessfulLossVenuesAPICall={handleReloadLossLeaderVenues}
                lossLeaderVenues={lossLeaderVenuesToDisplay}
                sendDatadogError={sendDatadogError}
                createNotification={createNotification}
                APIError={APIError}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

LossLeaders.propTypes = {
  sendDatadogError: PropTypes.any.isRequired,
  createNotification: PropTypes.any.isRequired,
};

export default withErrorReports(withNotifications(LossLeaders));
