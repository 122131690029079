import _ from 'lodash';
import Fuse from 'fuse.js';

const defaultSearchOptions = {
  keys: ['description'],
};

export const initialiseFuseInstance = (initialData) => new Fuse(initialData, defaultSearchOptions);

/**
 * Search a list of images against a query string
 * @param {Fuse Instance} instance of fuse object to search against
 * @param {Array} images list of images to search
 * @param {String} searchString query string to search against
 * @returns {Array} an array of images matching the searchString
 */
export const search = (fuseInstance, unformattedImages, searchString) => {
  const regexSpecialCharacters = /[-/\\^$*+?.()|[\]{}]/g;

  // Sanitise the image descriptions
  const images = unformattedImages.map((img) => ({
    ...img,
    description: img.description.replace(regexSpecialCharacters, '').trim(),
  }));

  if (!searchString) return images;

  // Sanitise the input a bit
  const safeSearchString = searchString.replace(regexSpecialCharacters, '').trim();

  const regex1 = RegExp(`(?:^|\\W)${safeSearchString}(?:$|\\W)`, 'i');

  const exactMatches = _.filter(images, (i) => regex1.test(i.description));
  let fuzzyMatches = fuseInstance.search(safeSearchString).map((res) => res.item);

  // Get only fuzzy results that aren't already in the list of exact results
  fuzzyMatches = _.differenceBy(fuzzyMatches, exactMatches, (image) => image.id);

  return exactMatches.concat(fuzzyMatches);
};
export default search;
