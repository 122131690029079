import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { VIEW_BY_SUBURB } from '../../utils/Constants';

function withRedirectHelper(WrappedComponent) {
  class WithRedirectHelperWrapper extends Component {
    goToExternalLink = (externalLink) => {
      window.open(externalLink, '_blank');
    };

    replacePageWithWebFlowLink = (pageLink) => {
      this.goToExternalLink(`https://www.foodbomb.com.au/${pageLink}`);
    };

    goToTermsAndConditions = () => {
      this.replacePageWithWebFlowLink('terms-and-conditions');
    };

    goToRoute = (route) => {
      window.scrollTo(0, 0);
      this.props.history.push(route);
    };

    redirectToOrders = () => {
      this.goToRoute('/orders');
    };

    redirectToOrder = (orderId) => {
      this.goToRoute(`/orders/${orderId}`);
    };

    redirectToOrderWithQueryParams = (orderId, query) => {
      this.goToRoute(`/orders/${orderId}${query}`);
    };

    redirectToSpecials = () => {
      this.goToRoute('/specials');
    };

    redirectToNewSpecial = () => {
      this.goToRoute('/specials/new');
    };

    redirectToCustomPrices = () => {
      this.goToRoute('/custom-prices');
    };

    redirectToNewCustomPrice = () => {
      this.goToRoute('/custom-prices/new');
    };

    redirectToNewProduct = () => {
      this.goToRoute('/products/new');
    };

    redirectToProducts = () => {
      this.goToRoute('/products');
    };

    redirectToProductsWithCustomPricingFilterEnabled = () => {
      this.goToRoute('/products?customPricingFilterId=1');
    };

    redirectToProduct = (productId) => {
      this.goToRoute(`/products/${productId}`);
    };

    redirectToProductWithQueryParams = (productId, query) => {
      this.goToRoute(`/products/${productId}${query}`);
    };

    redirectToEditMultipleProducts = () => {
      this.goToRoute('/products/edit-multiple-products');
    };

    redirectToAddMultipleProducts = () => {
      this.goToRoute('/products/add-multiple-products');
    };

    redirectToDeliveryZones = () => {
      this.goToRoute('/delivery-zones');
    };

    redirectToDeliveryZonesBySuburb = () => {
      this.goToRoute(`/delivery-zones?view=${VIEW_BY_SUBURB}`);
    };

    redirectToDeliveryZonesBySuburbWithPostcodeQuery = (postcode) => {
      this.goToRoute(`/delivery-zones?view=${VIEW_BY_SUBURB}&q=?postcode=${postcode}`);
    };

    redirectToDeliveryZonesBySuburbWithSuburbQuery = (suburb) => {
      this.goToRoute(`/delivery-zones?view=${VIEW_BY_SUBURB}&q=?suburb=${suburb}`);
    };

    replaceCurrentPageWithAdminPortalPageOrReferrer = (referrer) => {
      if (referrer) {
        window.location = referrer;
      } else {
        // TODO: replace with environment variable when configured for react apps
        switch (window.location.host) {
          case 'localhost:8000':
            window.location = 'http://localhost:8002';
            break;
          case 'testsupplierportal.foodbomb.com.au':
            window.location = 'https://testsupplier.foodbomb.com.au';
            break;
          case 'supplierportal.foodbomb.com.au':
            window.location = 'https://supplier.foodbomb.com.au';
            break;
          default:
            window.location = 'https://supplier.foodbomb.com.au';
        }
      }
    };

    render() {
      return (
        <WrappedComponent
          redirectToOrders={this.redirectToOrders}
          redirectToOrder={this.redirectToOrder}
          redirectToOrderWithQueryParams={this.redirectToOrderWithQueryParams}
          redirectToSpecials={this.redirectToSpecials}
          redirectToNewSpecial={this.redirectToNewSpecial}
          redirectToCustomPrices={this.redirectToCustomPrices}
          redirectToNewCustomPrice={this.redirectToNewCustomPrice}
          redirectToProducts={this.redirectToProducts}
          redirectToNewProduct={this.redirectToNewProduct}
          redirectToProduct={this.redirectToProduct}
          redirectToProductWithQueryParams={this.redirectToProductWithQueryParams}
          replaceCurrentPageWithAdminPortalPageOrReferrer={this.replaceCurrentPageWithAdminPortalPageOrReferrer}
          redirectToEditMultipleProducts={this.redirectToEditMultipleProducts}
          redirectToAddMultipleProducts={this.redirectToAddMultipleProducts}
          goToTermsAndConditions={this.goToTermsAndConditions}
          redirectToDeliveryZones={this.redirectToDeliveryZones}
          redirectToDeliveryZonesBySuburb={this.redirectToDeliveryZonesBySuburb}
          redirectToDeliveryZonesBySuburbWithPostcodeQuery={this.redirectToDeliveryZonesBySuburbWithPostcodeQuery}
          redirectToDeliveryZonesBySuburbWithSuburbQuery={this.redirectToDeliveryZonesBySuburbWithSuburbQuery}
          redirectToProductsWithCustomPricingFilterEnabled={this.redirectToProductsWithCustomPricingFilterEnabled}
          {...this.props}
        />
      );
    }
  }

  WithRedirectHelperWrapper.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  return withRouter(WithRedirectHelperWrapper);
}

export default withRedirectHelper;
