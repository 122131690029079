import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { FC } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { BUTTON_VARIANTS, NOTIFICATION_TYPES, TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import { Button, FormikFormField, Typography } from '../../../components/UI/FB';
import styles from './AddSupplierOrderIdDialog.module.scss';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import { NotificationConfig, SendErrorReportType } from '../../../utils/Presenters/ReduxType';

type AddSupplierOrderIdDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  orderId: number;
  handleSupplierOrderIdAdded: (supplierOrderId: string) => void;
  supplierReferenceId: string | null;
  createNotification: (notification: NotificationConfig) => void;
  sendDatadogError: SendErrorReportType;
};

type APIValues = {
  supplierOrderId: string;
};

const AddSupplierOrderIdDialog: FC<AddSupplierOrderIdDialogProps> = ({
  isOpen,
  handleClose,
  orderId,
  supplierReferenceId,
  createNotification,
  sendDatadogError,
  handleSupplierOrderIdAdded,
}) => {
  const handleSaveClicked = (values: APIValues) => {
    if (values.supplierOrderId !== supplierReferenceId) {
      FoodbombAPI.patch(`suppliers/orders/${orderId}/supplier-reference`, {
        supplierReference: values.supplierOrderId,
      })
        .then(() => {
          createNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            content: `Successfully added new internal order ID to order #${orderId}`,
            timeout: 4000,
            closable: true,
          });
          if (values?.supplierOrderId) {
            handleSupplierOrderIdAdded(values.supplierOrderId);
          }
          handleClose();
        })
        .catch((error) => {
          sendDatadogError('Unable to update the internal order ID', {
            error,
            location: 'Order Page',
          });
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to update the internal order ID',
            timeout: 4000,
            closable: true,
          });
        });
    } else {
      // Dont bother submitting to the API unless the value changes
      handleClose();
    }
  };

  const supplierOrderIdSchema = Yup.object().shape({
    supplierOrderId: Yup.string().required('Supplier order ID is required'),
  });

  return (
    <>
      <Dialog
        open={isOpen}
        classes={{ root: styles.AddSupplierOrderIdDialogRoot, paper: styles.AddSupplierOrderIdDialogPaper }}
      >
        <DialogTitle>
          <Typography className={styles.DialogHeader} type={TYPOGRAPHY_TYPES.HEADING_XL}>
            {supplierReferenceId ? 'Edit' : 'Add'} order ID
          </Typography>
          <IconButton className={styles.AddSupplierOrderIdDialog__closeBtn} aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Typography className={styles.BodyText}>
          Enter your internal order ID so that you can easily reconcile it against your remittance document.
        </Typography>
        <DialogContent classes={{ root: styles.FillableFieldContainer }}>
          <Formik
            initialValues={{ supplierOrderId: supplierReferenceId || '' }}
            validationSchema={supplierOrderIdSchema}
            onSubmit={handleSaveClicked}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form className={styles.FormContainer}>
                <div className={styles.FormRow}>
                  <FormikFormField
                    fieldName="supplierOrderId"
                    label="Internal order ID"
                    touched={touched}
                    errors={errors}
                    placeholder="Enter internal order ID"
                  />
                </div>
                <Button
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  type="submit"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  className={styles.SubmitBtn}
                >
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

// TODO: Replace withNotifications and withErrorReports with Hook in other PR
export default withErrorReports(withNotifications(AddSupplierOrderIdDialog));
