import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { matchSorter } from 'match-sorter';

import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import {
  ErrorNotification,
  SearchBar,
  Button,
  Typography,
  Table,
  Paper,
  AlertDialog,
  Emoji,
} from '../../components/UI/FB';
import {
  ALERT_TYPES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  TYPOGRAPHY_TYPES,
  NOTIFICATION_TYPES,
} from '../../utils/Constants';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import withQueryParams from '../../hoc/withQueryParams/withQueryParams';
import { useDebounce } from '../../hooks';
import styles from './CustomPrices.module.scss';
import AddCustomPriceDialog from './AddCustomPriceDialog/AddCustomPriceDialog';
import EditCustomPriceDialog from './EditCustomPriceDialog/EditCustomPriceDialog';
import { presentCurrency } from '../../utils/Presenters/PresentCurrency/PresentCurrency';

import { FoodbombAPI } from '../../utils/AxiosInstances';

const CustomPrices = ({ createNotification, sendDatadogError, getQueryFromQueryString, updateSearchQueryParam }) => {
  const [customPrices, setCustomPrices] = useState([]);
  const [filteredCustomPrices, setFilteredCustomPrices] = useState([]);
  const [customPriceIdsInOperation, setCustomPriceIdsInOperation] = useState([]);

  const [loadingCustomPrices, setLoadingCustomPrices] = useState(true);
  const [searchQuery, setSearchQuery] = useState(getQueryFromQueryString() || '');
  const debouncedSearchQuery = useDebounce(searchQuery, 200);
  const [shouldShowSearchBar, setShouldShowSearchBar] = useState(false);

  const [deleteCustomPriceDialogOpen, setDeleteCustomPriceDialogOpen] = useState(false);
  const [customPriceToDelete, setCustomPriceToDelete] = useState(undefined);

  const [editCustomPriceDialogOpen, setEditCustomPriceDialogOpen] = useState(false);
  const [customPriceToEdit, setCustomPriceToEdit] = useState(undefined);

  const [addCustomPriceDialogOpen, setAddCustomPriceDialogOpen] = useState(false);

  const [APIError, setAPIError] = useState(undefined);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const addCustomPriceIdToCustomPriceIdsInOperation = (customPriceId) => {
    setCustomPriceIdsInOperation((previousIds) => [...previousIds, customPriceId]);
  };

  const removeCustomPriceIdFromCustomPriceIdsInOperation = (customPriceId) => {
    setCustomPriceIdsInOperation((previousIds) => previousIds.filter((id) => id !== customPriceId));
  };

  const updateCustomPriceData = (customPriceToUpdate) => {
    const idxToUpdate = customPrices.findIndex((customPrice) => customPrice.id === customPriceToUpdate.id);
    const updatedCustomPrices = [...customPrices];
    updatedCustomPrices[idxToUpdate] = customPriceToUpdate;
    setCustomPrices(updatedCustomPrices);
  };

  const createCustomPriceIncGSTCell = (customPrice) => (
    <strong>
      {customPrice.product.priceInCents < customPrice.customPriceInCents ? (
        <React.Fragment>
          <Emoji content="⚠️" label="warning" />
          &nbsp;
        </React.Fragment>
      ) : null}
      {presentCurrency(customPrice.priceInCents / 100)}/{customPrice.product.priceUnit}
    </strong>
  );

  const customPricesColumnSpecs = [
    {
      index: 0,
      title: 'SKU',
      mobileText: 'SKU',
      sortMethod: 'product.sku',
      className: styles.SKU,
      columnDataFunction: (customPrice) => customPrice.product.sku,
      searchable: true,
    },
    {
      index: 1,
      title: 'Product Name',
      className: styles.ProductName,
      sortMethod: 'product.name',
      columnDataFunction: (customPrice) => customPrice.product.name,
      searchable: true,
    },
    {
      index: 2,
      title: 'Venue',
      className: styles.Venue,
      sortMethod: 'venue.venue',
      columnDataFunction: (customPrice) => customPrice.venue.venue,
      searchable: true,
    },
    {
      index: 3,
      className: styles.OGPrice,
      title: 'Original (Ex GST)',
      sortMethod: 'product.priceInCents',
      columnDataFunction: (customPrice) =>
        `${presentCurrency(customPrice.product.priceInCents / 100)}/${customPrice.product.priceUnit}`,
      searchable: true,
    },
    {
      index: 4,
      className: styles.Price,
      title: '⚡️ (Ex. GST)',
      sortMethod: 'priceInCentsExGST',
      columnDataFunction: (customPrice) =>
        `${presentCurrency(customPrice.priceInCentsExGST / 100)}/${customPrice.product.priceUnit}`,
      searchable: true,
    },
    {
      index: 5,
      className: styles.Price,
      title: '⚡️ (Inc. GST)',
      sortMethod: 'priceInCents',
      columnDataFunction: (customPrice) =>
        `${presentCurrency(customPrice.priceInCents / 100)}/${customPrice.product.priceUnit}`,
      getChild: createCustomPriceIncGSTCell,
      searchable: true,
    },
  ];

  const filterCustomPrices = useCallback((customPricesToFilter, searchToQueryAgainst) => {
    const filterKeys = customPricesColumnSpecs.filter((spec) => spec.searchable).map((spec) => spec.columnDataFunction);
    const newlyFilteredCustomPrices = matchSorter(customPricesToFilter, searchToQueryAgainst, {
      keys: filterKeys,
      threshold: matchSorter.rankings.CONTAINS,
    });
    setFilteredCustomPrices(newlyFilteredCustomPrices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCustomPrices = useCallback(() => {
    setLoadingCustomPrices(true);
    FoodbombAPI.get(`custom-pricing`)
      .then((response) => {
        const customPricesToSet = response.data.map((customPricingObject) => ({
          ...customPricingObject,
          priceInCentsExGST: customPricingObject.product.gstExempt
            ? customPricingObject.priceInCents
            : Math.round(customPricingObject.priceInCents - customPricingObject.priceInCents / 11),
        }));
        setCustomPrices(customPricesToSet);
        setShouldShowSearchBar(true);
      })
      .catch((error) => {
        sendDatadogError('Unable to load custom prices', {
          error,
          location: 'Custom Prices Index Page',
        });
        setAPIError(<ErrorNotification />);
      })
      .finally(() => {
        setLoadingCustomPrices(false);
      });
  }, [sendDatadogError]);

  const openAddCustomPriceDialog = () => {
    setAddCustomPriceDialogOpen(true);
  };

  const closeAddCustomPriceDialog = () => {
    setAddCustomPriceDialogOpen(false);
  };

  const openDeleteCustomPriceDialog = (customPrice) => {
    setDeleteCustomPriceDialogOpen(true);
    setCustomPriceToDelete(customPrice);
  };

  const closeDeleteCustomPriceDialog = () => {
    setDeleteCustomPriceDialogOpen(false);
    setCustomPriceToDelete(undefined);
  };

  const openEditCustomPriceDialog = (customPrice) => {
    setEditCustomPriceDialogOpen(true);
    setCustomPriceToEdit(customPrice);
  };

  const closeEditCustomPriceDialog = () => {
    setEditCustomPriceDialogOpen(false);
    setTimeout(() => {
      setCustomPriceToEdit(undefined);
    }, 200);
  };

  const onSuccessfullyCreateCustomPrice = () => {
    closeAddCustomPriceDialog();
    fetchCustomPrices();
  };

  const handleDeleteCustomPrice = () => {
    addCustomPriceIdToCustomPriceIdsInOperation(customPriceToDelete.id);
    FoodbombAPI.delete(`custom-pricing/${customPriceToDelete.id}`)
      .then(() => {
        fetchCustomPrices();
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully deleted custom price',
          timeout: 4000,
          closable: true,
        });
      })
      .catch((error) => {
        sendDatadogError('Unable to delete custom price', {
          error,
          location: 'Custom price index page',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to delete custom price. Please try again.',
          timeout: 4000,
          closable: true,
        });
      })
      .finally(() => {
        removeCustomPriceIdFromCustomPriceIdsInOperation(customPriceToDelete.id);
      });
  };

  const handleRowClick = (customPrice) => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 768) {
      openEditCustomPriceDialog(customPrice);
    }
  };

  const createRowActions = (customPrice) => (
    <React.Fragment>
      <Button
        onClick={() => openDeleteCustomPriceDialog(customPrice)}
        className={styles.TableBtn}
        variant={BUTTON_VARIANTS.DANGER}
        size={BUTTON_SIZES.EXTRA_SMALL}
        disabled={customPriceIdsInOperation.includes(customPrice.id)}
      >
        Delete Custom Price
      </Button>
      <Button
        onClick={() => openEditCustomPriceDialog(customPrice)}
        className={styles.TableBtn}
        variant={BUTTON_VARIANTS.SECONDARY}
        size={BUTTON_SIZES.EXTRA_SMALL}
        disabled={customPriceIdsInOperation.includes(customPrice.id)}
      >
        Edit Custom Price
      </Button>
    </React.Fragment>
  );

  // When first loading this page, update the search query
  useEffect(() => {
    fetchCustomPrices();
  }, [fetchCustomPrices]);

  useEffect(() => {
    filterCustomPrices(customPrices, debouncedSearchQuery);
  }, [filterCustomPrices, debouncedSearchQuery, customPrices]);

  // Update the query string when the debounce search changes
  useEffect(() => {
    updateSearchQueryParam(debouncedSearchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  return (
    <div className={styles.PageContainer}>
      <PageHeader>
        <Breadcrumbs
          currentPageTitle={
            <React.Fragment>
              <Emoji content="⚡️" label="bolt" />
              &nbsp;Custom Pricing
            </React.Fragment>
          }
        />
        <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.Font__standardGrey}>
          Here are all your custom prices
        </Typography>
        <div className={styles.ActionBar}>
          <div className={styles.SearchBarContainer}>
            {shouldShowSearchBar ? (
              <SearchBar
                placeholder="Search product or customer"
                // Matching is happening on change, method required for react error
                handleSearch={() => {}}
                onChange={handleSearchChange}
                handleClear={() => setSearchQuery('')}
                value={searchQuery}
              />
            ) : null}
          </div>
          <div className={styles.ActionBar__buttonsContainer}>
            <Button className={styles.ActionBar__button} onClick={openAddCustomPriceDialog}>
              Add Custom Price
            </Button>
          </div>
        </div>
      </PageHeader>
      {APIError ? (
        <div className={styles.ErrorWrapper}>{APIError}</div>
      ) : (
        <React.Fragment>
          <div className={styles.TableContainer}>
            <Table
              className={styles.CustomPricesTable}
              columnSpecs={customPricesColumnSpecs}
              rowData={filteredCustomPrices}
              loadingRowData={loadingCustomPrices}
              handleRowClick={handleRowClick}
              rowOverlayColSpan={3}
              rowActions={createRowActions}
              noResultsComponent={
                <Paper className={styles.NoResultsContainer}>
                  <div className={styles.TypographySection}>
                    <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
                      <span role="img" aria-label="wave">
                        👋
                      </span>
                    </Typography>
                    <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Welcome to the custom pricing page</Typography>
                    <div className={styles.TypographySection}>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                        Here is where we&apos;ll display all of your custom prices
                      </Typography>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                        To get started, please create a new custom price!
                      </Typography>
                    </div>
                  </div>
                  <div className={styles.NoResultsActions}>
                    <Button onClick={openAddCustomPriceDialog}>Add Custom Price</Button>
                  </div>
                </Paper>
              }
              searchQuery={debouncedSearchQuery}
              grow
              defaultSortColumn={1}
              sortAscending={false}
            />
          </div>
        </React.Fragment>
      )}
      <AlertDialog
        variant={ALERT_TYPES.WARNING}
        alertTitle={'Delete Custom Price'}
        alertMessage={'Please note deleting a custom price is permanent and cannot be undone.'}
        isOpen={deleteCustomPriceDialogOpen}
        handleClose={closeDeleteCustomPriceDialog}
        handleConfirm={handleDeleteCustomPrice}
        confirmMessage={'Delete Custom Price'}
      />
      <AddCustomPriceDialog
        isOpen={addCustomPriceDialogOpen}
        handleClose={closeAddCustomPriceDialog}
        onSuccessfullyCreateCustomPrice={onSuccessfullyCreateCustomPrice}
      />
      {customPriceToEdit ? (
        <EditCustomPriceDialog
          isOpen={editCustomPriceDialogOpen}
          handleClose={closeEditCustomPriceDialog}
          customPrice={customPriceToEdit}
          onUpdateComplete={() => removeCustomPriceIdFromCustomPriceIdsInOperation(customPriceToEdit.id)}
          onUpdateBegin={() => addCustomPriceIdToCustomPriceIdsInOperation(customPriceToEdit.id)}
          updateCustomPriceData={updateCustomPriceData}
        />
      ) : null}
    </div>
  );
};

CustomPrices.propTypes = {
  createNotification: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  getQueryFromQueryString: PropTypes.func.isRequired,
  updateSearchQueryParam: PropTypes.func.isRequired,
};

export default withErrorReports(withNotifications(withQueryParams(CustomPrices)));
