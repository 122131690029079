import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { FC } from 'react';
import { TYPOGRAPHY_TYPES } from '../../utils/Constants';
import { Button, Typography } from '../UI/FB';
import styles from './DeleteConfirmationDialog.module.scss';

type DeleteConfirmationDialogTypes = {
  isOpen: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  confirmationMessage?: string;
};

const DeleteConfirmationDialog: FC<DeleteConfirmationDialogTypes> = ({
  isOpen,
  handleClose,
  handleDelete,
  confirmationMessage,
}) => {
  const handleContactDelete = () => {
    handleDelete();
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ root: styles.ConfirmationDeleteDialogRoot, paper: styles.ConfirmationDeleteDialogPaper }}
    >
      <DialogTitle className={styles.ConfirmationDeleteDialog__header}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Confirm Delete</Typography>
        <IconButton className={styles.ConfirmationDeleteDialog__closeBtn} aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: styles.ConfirmationDeleteDialogContent }}>
        <div>
          <Typography type={TYPOGRAPHY_TYPES.BODY}>{confirmationMessage}</Typography>
        </div>
      </DialogContent>
      <div className={styles.ConfirmationDeleteDialogFooter}>
        <Button onClick={handleContactDelete}>Confirm</Button>
      </div>
    </Dialog>
  );
};

DeleteConfirmationDialog.defaultProps = {
  confirmationMessage: 'Are you sure?',
};

DeleteConfirmationDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  confirmationMessage: PropTypes.string,
};

export default DeleteConfirmationDialog;
