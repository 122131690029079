import { FC, ElementType, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Switch } from '@material-ui/core';
import { Button, Paper, Typography } from '../../../components/UI/FB';
import { BUTTON_VARIANTS, NOTIFICATION_TYPES, TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import useDDErrorReporting from '../../../hooks/useDDErrorReporting/useDDErrorReporting';
import useNotifications from '../../../hooks/useNotifications/useNotifications';
import { ContactTypes } from '../../../utils/Presenters/Common';
import styles from '../Settings.module.scss';

type NewCustomerPreferencesCardTypes = {
  innerRef: () => void | ElementType;
  updateSupplierDetails: (data: ContactTypes) => void;
  areNewCustomersAllowed: boolean;
};

const NewCustomerPreferencesCard: FC<NewCustomerPreferencesCardTypes> = ({
  innerRef,
  updateSupplierDetails,
  areNewCustomersAllowed,
}) => {
  const { createNotification } = useNotifications();
  const { sendDatadogError } = useDDErrorReporting();

  const [buttonToggled, setButtonToggled] = useState<Boolean>(areNewCustomersAllowed);
  const [editMode, setEditMode] = useState<Boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<Boolean>(false);

  const cancelClicked = () => {
    setEditMode(false);
    setButtonToggled(areNewCustomersAllowed);
  };

  const handleUpdateNewCustomerPreference = () => {
    const payload = {
      availableForNewCustomers: buttonToggled,
    };

    FoodbombAPI.patch('suppliers/auth/me', payload)
      .then((response) => {
        updateSupplierDetails(response.data);
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Successfully ${buttonToggled ? 'enabled' : 'disabled'} receiving new customer orders`,
          timeout: 4000,
          closable: true,
        });
        setEditMode(false);
      })
      .catch((error) => {
        if (error?.response?.status === 400 && error?.response?.data?.errors) {
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to update preferences for receiving new customer orders',
            timeout: 4000,
            closable: true,
          });
        } else {
          sendDatadogError('Unable to update preferences for receiving new customer orders', {
            error,
            location: 'Settings Page',
          });
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to update preferences for receiving new customer orders',
            timeout: 4000,
            closable: true,
          });
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    handleUpdateNewCustomerPreference();
  };

  return (
    <div ref={innerRef}>
      <Paper className={styles.SettingsCard}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.SettingsCard__sectionHeading}>
          New Customer Preferences
        </Typography>
        <div className={styles.NewCustomers__buttonContainer}>
          {editMode ? (
            <>
              <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.buttonExplanationText}>
                Enable new customers to order&nbsp;
              </Typography>
              <div className={styles.ToggleButton}>
                <Switch
                  checked={Boolean(buttonToggled)}
                  onChange={() => setButtonToggled((prev) => !prev)}
                  disabled={!editMode}
                  classes={{
                    root: styles.SwitchRoot,
                    colorPrimary: styles.ColorPrimary,
                    thumb: styles.ColorPrimary,
                    track: buttonToggled ? styles.ActiveColor : styles.PausedColor,
                  }}
                />
              </div>
            </>
          ) : (
            <>{buttonToggled ? 'Enabled' : 'Disabled'}</>
          )}
        </div>

        {editMode ? (
          <>
            <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__description}>
              If you disable this setting, only venues who have previously ordered from you will be able to place
              orders. You will not receive orders from any new customers.
            </Typography>
            <div className={styles.SettingsCard__editBtnContainer}>
              <Button type="button" disabled={isSubmitting} variant={BUTTON_VARIANTS.SECONDARY} onClick={cancelClicked}>
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting} onClick={handleSubmit}>
                {isSubmitting ? (
                  <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                ) : (
                  <>Update Details</>
                )}
              </Button>
            </div>
          </>
        ) : (
          <div className={styles.SettingsCard__editBtnContainer}>
            <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={() => setEditMode(true)} className={styles.EditBtn}>
              Edit Preferences
            </Button>
          </div>
        )}
      </Paper>
    </div>
  );
};

NewCustomerPreferencesCard.propTypes = {
  updateSupplierDetails: PropTypes.func.isRequired,
  areNewCustomersAllowed: PropTypes.bool.isRequired,
};

export default NewCustomerPreferencesCard;
