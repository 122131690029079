import * as actionTypes from '../actions/ActionTypes';
import { updateObject } from '../ReduxUtilities';

const initialState = {
  loading: false,
};

const authStart = (state) => updateObject(state, { error: undefined, loading: true });

const authFail = (state, action) => updateObject(state, { error: action.payload.error, loading: false });

const authSuccess = (state, action) =>
  updateObject(state, {
    token: action.payload.token,
    tokenExpirationDate: action.payload.tokenExpirationDate,
    supplierDetails: action.payload.supplierDetails,
    loading: false,
  });

const authLogout = () => initialState;

const authReset = () => initialState;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_RESET:
      return authReset(state, action);
    default:
      return state;
  }
};

export default reducer;
