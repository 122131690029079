import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { matchSorter } from 'match-sorter';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Moment from 'moment';
import { IconButton, MenuItem } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import SpecialsIcon from '../../components/UI/SpecialsIcon/SpecialsIcon';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import withRedirectHelper from '../../hoc/withRedirectHelper/withRedirectHelper';
import withQueryParams from '../../hoc/withQueryParams/withQueryParams';
import {
  ErrorNotification,
  Menu,
  StatusChip,
  SearchBar,
  Button,
  Typography,
  Table,
  Paper,
  AlertDialog,
} from '../../components/UI/FB';
import {
  ALERT_TYPES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  TYPOGRAPHY_TYPES,
  NOTIFICATION_TYPES,
  SPECIAL_STATUSES,
  STATUS_CHIP_TYPES,
} from '../../utils/Constants';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import { useDebounce } from '../../hooks';
import styles from './Specials.module.scss';

import { FoodbombAPI } from '../../utils/AxiosInstances';

const NUMBER_OF_CONCURRENT_SPECIALS_IN_EACH_CATEGORY = 8;

const Specials = ({
  isStaff,
  redirectToProduct,
  redirectToNewSpecial,
  createNotification,
  sendDatadogError,
  updateSearchQueryParam,
  getQueryFromQueryString,
  updateSpecialFilterQueryParam,
  getSpecialFilterQueryFromQueryString,
}) => {
  const [specials, setSpecials] = useState([]);
  const [filteredSpecials, setFilteredSpecials] = useState([]);
  const [specialIdsInOperation, setSpecialIdsInOperation] = useState([]);
  const [specialsFilterAnchorElem, setSpecialsFilterAnchorElem] = useState(undefined);
  const [selectedSpecialsFilter, setSelectedSpecialsFilter] = useState(getSpecialFilterQueryFromQueryString());
  const [showRemainingCountMessage, setShowRemainingCountMessage] = useState(true);

  const [loadingSpecials, setLoadingSpecials] = useState(false);
  const [searchQuery, setSearchQuery] = useState(getQueryFromQueryString() || '');
  const debouncedSearchQuery = useDebounce(searchQuery, 200);
  const [shouldShowSearchBar, setShouldShowSearchBar] = useState(false);

  const [archiveSpecialDialogOpen, setArchivedSpecialDialogOpen] = useState(false);
  const [featureSpecialDialogOpen, setFeatureSpecialDialogOpen] = useState(false);
  const [specialToArchive, setSpecialToArchive] = useState(undefined);
  const [specialToFeature, setSpecialToFeature] = useState(undefined);

  const [APIError, setAPIError] = useState('');

  const handleSpecialsFilterDropDownClicked = (e) => setSpecialsFilterAnchorElem(e.currentTarget);
  const handleCloseSpecialsFilterMenu = () => setSpecialsFilterAnchorElem(undefined);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const addSpecialIdToSpecialIdsInOperation = (specialId) => {
    setSpecialIdsInOperation((previousIds) => [...previousIds, specialId]);
  };

  const removeSpecialIdFromSpecialIdsInOperation = (specialId) => {
    setSpecialIdsInOperation((previousIds) => previousIds.filter((id) => id !== specialId));
  };

  const createStatusCell = (special) => {
    let statusType = STATUS_CHIP_TYPES.FAILED;
    if (special.status === SPECIAL_STATUSES.ACTIVE) {
      statusType = STATUS_CHIP_TYPES.SUCCESS;
    }
    if (special.status === SPECIAL_STATUSES.SCHEDULED) {
      statusType = STATUS_CHIP_TYPES.PENDING;
    }
    return <StatusChip hideIcon type={statusType} title={special.status} />;
  };

  const createBannerCell = (special) => (
    <StatusChip
      hideIcon
      type={special.isBanner ? STATUS_CHIP_TYPES.SUCCESS : STATUS_CHIP_TYPES.FAILED}
      title={special.isBanner ? 'yes' : 'no'}
    />
  );

  const specialsColumnSpecs = [
    {
      index: 0,
      title: 'SKU',
      mobileText: 'SKU',
      sortMethod: 'product.sku',
      className: styles.SKU,
      columnDataFunction: (special) => special.product.sku,
      searchable: true,
    },
    {
      index: 1,
      title: 'Product Name',
      className: styles.ProductName,
      sortMethod: 'product.name',
      columnDataFunction: (special) => special.product.name,
      searchable: true,
    },
    {
      index: 2,
      title: '🗓 Start',
      className: styles.Date,
      sortMethod: 'startDate',
      columnDataFunction: (special) => Moment(special.startDate).format('DD MMM YYYY'),
      searchable: true,
    },
    {
      index: 3,
      title: '🗓 End',
      className: styles.Date,
      sortMethod: 'endDate',
      columnDataFunction: (special) => Moment(special.endDate).format('DD MMM YYYY'),
      searchable: true,
    },
    {
      index: 4,
      className: styles.Price,
      title: 'OG Price',
      sortMethod: 'product.price',
      columnDataFunction: (special) =>
        `$${(special.product.price / 100).toLocaleString()}/${special.product.priceUnit}`,
      searchable: true,
    },
    {
      index: 5,
      className: styles.Price,
      title: (
        <span>
          <SpecialsIcon />
          &nbsp;Price
        </span>
      ),
      sortMethod: 'specialPrice',
      columnDataFunction: (special) => `$${(special.specialPrice / 100).toLocaleString()}/${special.product.priceUnit}`,
      searchable: true,
    },
    {
      index: 6,
      title: 'Status',
      sortMethod: 'status',
      columnDataFunction: (special) => special.status,
      getChild: createStatusCell,
      searchable: true,
    },
  ];

  const additionalAdminColumns = [
    {
      index: 7,
      title: 'Banner',
      sortMethod: 'isBanner',
      columnDataFunction: (special) => (special.isBanner ? 'Yes' : 'No'),
      getChild: createBannerCell,
      searchable: true,
    },
  ];

  if (isStaff) {
    specialsColumnSpecs.push(...additionalAdminColumns);
  }

  const filterSpecials = useCallback((specialsToFilter, searchToQueryAgainst) => {
    const filterKeys = specialsColumnSpecs.filter((spec) => spec.searchable).map((spec) => spec.columnDataFunction);
    const newlyFilteredSpecials = matchSorter(specialsToFilter, searchToQueryAgainst, {
      keys: filterKeys,
      threshold: matchSorter.rankings.CONTAINS,
    });
    setFilteredSpecials(newlyFilteredSpecials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNumberOfActiveSpecials = () =>
    specials.filter((special) => special.status === SPECIAL_STATUSES.ACTIVE).length;

  const fetchSpecials = useCallback(() => {
    setLoadingSpecials(true);
    FoodbombAPI.get(selectedSpecialsFilter ? `specials?status=${selectedSpecialsFilter}` : 'specials')
      .then((response) => {
        const activeSpecials = response.data.active
          ? response.data.active.map((s) => ({ ...s, status: SPECIAL_STATUSES.ACTIVE }))
          : [];
        const scheduledSpecials = response.data.scheduled
          ? response.data.scheduled.map((s) => ({ ...s, status: SPECIAL_STATUSES.SCHEDULED }))
          : [];
        const archivedSpecials = response.data.archived
          ? response.data.archived.map((s) => ({ ...s, status: SPECIAL_STATUSES.ARCHIVED }))
          : [];

        const specialsData = activeSpecials.concat(scheduledSpecials, archivedSpecials);

        setSpecials(specialsData);
        setShouldShowSearchBar(true);
      })
      .catch((error) => {
        sendDatadogError('Unable to load specials', {
          error,
          location: 'Specials Index Page',
        });
        setAPIError(<ErrorNotification />);
      })
      .finally(() => {
        setLoadingSpecials(false);
      });
  }, [sendDatadogError, selectedSpecialsFilter]);

  const handleSelectSpecialsFilter = (status) => {
    setSelectedSpecialsFilter(status);
    handleCloseSpecialsFilterMenu();
    updateSpecialFilterQueryParam(status);
  };

  const openFeatureSpecialDialog = (special) => {
    setFeatureSpecialDialogOpen(true);
    setSpecialToFeature(special);
  };

  const closeFeatureSpecialDialog = () => {
    setFeatureSpecialDialogOpen(false);
    setSpecialToFeature(undefined);
  };

  const openArchiveSpecialDialog = (special) => {
    setArchivedSpecialDialogOpen(true);
    setSpecialToArchive(special);
  };

  const closeArchiveSpecialDialog = () => {
    setArchivedSpecialDialogOpen(false);
    setSpecialToArchive(undefined);
  };

  const handleToggleFeatureSpecial = () => {
    addSpecialIdToSpecialIdsInOperation(specialToFeature.id);
    FoodbombAPI.patch(`specials/${specialToFeature.id}`, {
      is_banner: !specialToFeature.isBanner,
    })
      .then(() => {
        fetchSpecials();
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Successfully updated special for ${specialToFeature.product.name}`,
          timeout: 4000,
          closable: true,
        });
      })
      .catch((error) => {
        sendDatadogError('Unable to toggle featuring of special', {
          error,
          location: 'Specials index page',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to update special. Please try again.',
          timeout: 4000,
          closable: true,
        });
      })
      .finally(() => {
        removeSpecialIdFromSpecialIdsInOperation(specialToFeature.id);
      });
  };

  const handleArchiveSpecial = () => {
    addSpecialIdToSpecialIdsInOperation(specialToArchive.id);
    FoodbombAPI.delete(`specials/${specialToArchive.id}`)
      .then(() => {
        fetchSpecials();
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully archived special',
          timeout: 4000,
          closable: true,
        });
      })
      .catch((error) => {
        sendDatadogError('Unable to archive special', {
          error,
          location: 'Specials index page',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to archive special. Please try again.',
          timeout: 4000,
          closable: true,
        });
      })
      .finally(() => {
        removeSpecialIdFromSpecialIdsInOperation(specialToArchive.id);
      });
  };

  const handleRowClick = (special) => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 768) {
      redirectToProduct(special.id);
    }
  };

  const createRowActions = (special) => (
    <React.Fragment>
      <Button
        onClick={() => redirectToProduct(special.product.id)}
        className={styles.TableBtn}
        variant={BUTTON_VARIANTS.SECONDARY}
        size={BUTTON_SIZES.EXTRA_SMALL}
      >
        View Product
      </Button>
      {special.status === SPECIAL_STATUSES.ARCHIVED ? null : (
        <React.Fragment>
          <Button
            onClick={() => openArchiveSpecialDialog(special)}
            className={styles.TableBtn}
            variant={BUTTON_VARIANTS.DANGER}
            size={BUTTON_SIZES.EXTRA_SMALL}
            disabled={special.status === SPECIAL_STATUSES.ARCHIVED || specialIdsInOperation.includes(special.id)}
          >
            Archive Special
          </Button>
          {isStaff ? (
            <Button
              onClick={() => openFeatureSpecialDialog(special)}
              className={styles.TableBtn}
              size={BUTTON_SIZES.EXTRA_SMALL}
              disabled={special.status === SPECIAL_STATUSES.ARCHIVED || specialIdsInOperation.includes(special.id)}
              variant={special.isBanner ? BUTTON_VARIANTS.DANGER : BUTTON_VARIANTS.PRIMARY}
            >
              {special.isBanner ? 'Unfeature Special' : 'Feature Special'}
            </Button>
          ) : null}
        </React.Fragment>
      )}
    </React.Fragment>
  );

  useEffect(() => {
    fetchSpecials();
  }, [fetchSpecials]);

  useEffect(() => {
    filterSpecials(specials, debouncedSearchQuery);
  }, [filterSpecials, debouncedSearchQuery, specials]);

  // Update the query string when the debounce search changes
  useEffect(() => {
    updateSearchQueryParam(debouncedSearchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  return (
    <div className={styles.PageContainer}>
      <PageHeader>
        <Breadcrumbs
          currentPageTitle={
            <React.Fragment>
              <SpecialsIcon forHeading />
              &nbsp;Specials
            </React.Fragment>
          }
        />
        <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.Font__standardGrey}>
          Here are all of your specials
        </Typography>
        {shouldShowSearchBar ? (
          <div className={styles.ActionBar}>
            <div className={styles.SearchBarContainer}>
              <SearchBar
                placeholder="Search specials"
                // Matching is happening on change, method required for react error
                handleSearch={() => {}}
                onChange={handleSearchChange}
                handleClear={() => setSearchQuery('')}
                value={searchQuery}
              />
            </div>
            <div className={styles.ActionBar__buttonsContainer}>
              <Button
                variant={selectedSpecialsFilter ? BUTTON_VARIANTS.SUCCESS : BUTTON_VARIANTS.SECONDARY}
                className={styles.ActionBar__button}
                onClick={handleSpecialsFilterDropDownClicked}
              >
                {selectedSpecialsFilter ? `Only ${selectedSpecialsFilter} specials` : 'All Specials'}
                <ArrowDropDownIcon className={styles.DropdownIcon} />
              </Button>
              <Button className={styles.ActionBar__button} onClick={redirectToNewSpecial}>
                Create Special
              </Button>
            </div>
            <Menu
              open={Boolean(specialsFilterAnchorElem)}
              anchorEl={specialsFilterAnchorElem}
              onClose={handleCloseSpecialsFilterMenu}
            >
              <MenuItem onClick={() => handleSelectSpecialsFilter(undefined)} classes={{ root: styles.MenuLink }}>
                All specials
              </MenuItem>
              {Object.values(SPECIAL_STATUSES).map((status) => (
                <MenuItem
                  key={status}
                  onClick={() => handleSelectSpecialsFilter(status)}
                  classes={{ root: styles.MenuLink }}
                >
                  Only {status} specials
                </MenuItem>
              ))}
            </Menu>
          </div>
        ) : null}
      </PageHeader>
      {APIError ? (
        <div className={styles.ErrorWrapper}>{APIError}</div>
      ) : (
        <React.Fragment>
          {!loadingSpecials &&
          showRemainingCountMessage &&
          (selectedSpecialsFilter === undefined || selectedSpecialsFilter === SPECIAL_STATUSES.ACTIVE) ? (
            <Paper className={styles.RemainingCountMessagePaper}>
              <IconButton
                className={styles.RemainingCountMessage__closeBtn}
                onClick={() => setShowRemainingCountMessage(false)}
              >
                <HighlightOffIcon className={styles.CloseIcon} />
              </IconButton>
              <div className={styles.RemainingCountMessage__heading}>
                <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
                  <SpecialsIcon forHeading />
                </Typography>
                <Typography type={TYPOGRAPHY_TYPES.HEADING_XL} className={styles.BlockHeading}>
                  {`You have ${getNumberOfActiveSpecials()} specials active`}
                </Typography>
              </div>
              <div className={styles.RemainingCountMessage__body}>
                <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.RemainingCountMessageBlock}>
                  To ensure an f-bomb awesome experience for your customers, Foodbomb allows suppliers to have a maximum
                  of&nbsp;
                  <span className={styles.Bold}>
                    {NUMBER_OF_CONCURRENT_SPECIALS_IN_EACH_CATEGORY} specials in each category
                  </span>
                  &nbsp; running at the same time.
                </Typography>
                <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.RemainingCountMessageBlock}>
                  <ul className={styles.RemainingCountMessageList}>
                    <li>
                      You can run each special for a maximum of <span className={styles.Bold}>4 weeks</span>
                    </li>
                    <li>The special discount needs to be at least 5%</li>
                  </ul>
                </Typography>
              </div>
            </Paper>
          ) : null}
          <div className={styles.TableContainer}>
            <Table
              className={styles.SpecialsTable}
              columnSpecs={specialsColumnSpecs}
              rowData={filteredSpecials}
              loadingRowData={loadingSpecials}
              handleRowClick={handleRowClick}
              rowOverlayColSpan={6}
              rowActions={createRowActions}
              noResultsComponent={
                <React.Fragment>
                  {selectedSpecialsFilter ? (
                    <Paper className={styles.NoResultsContainer}>
                      <div className={styles.TypographySection}>
                        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
                          <span role="img" aria-label="heartbreak">
                            🙅
                          </span>
                        </Typography>
                        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
                          No {selectedSpecialsFilter} specials found
                        </Typography>
                        <div className={styles.TypographySection}>
                          <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                            Please remove your filter to see all results
                          </Typography>
                        </div>
                      </div>
                      <div className={styles.NoResultsActions}>
                        <Button
                          onClick={() => handleSelectSpecialsFilter(undefined)}
                          variant={BUTTON_VARIANTS.SECONDARY}
                        >
                          Show all specials
                        </Button>
                      </div>
                    </Paper>
                  ) : (
                    <Paper className={styles.NoResultsContainer}>
                      <div className={styles.TypographySection}>
                        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
                          <span role="img" aria-label="heartbreak">
                            👋
                          </span>
                        </Typography>
                        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Welcome to the specials page</Typography>
                        <div className={styles.TypographySection}>
                          <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                            Here is where we&apos;ll display all of the specials.
                          </Typography>
                          <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                            To get started, please create a special!
                          </Typography>
                        </div>
                      </div>
                      <div className={styles.NoResultsActions}>
                        <Button onClick={redirectToNewSpecial}>Create Special</Button>
                      </div>
                    </Paper>
                  )}
                </React.Fragment>
              }
              searchQuery={debouncedSearchQuery}
              grow
              defaultSortColumn={2}
              sortAscending={false}
            />
          </div>
        </React.Fragment>
      )}
      <AlertDialog
        variant={ALERT_TYPES.WARNING}
        alertTitle={'Archive Special'}
        alertMessage={'Please note archiving a special is permanent and cannot be undone.'}
        isOpen={archiveSpecialDialogOpen}
        handleClose={closeArchiveSpecialDialog}
        handleConfirm={handleArchiveSpecial}
        confirmMessage={'Archive Special'}
      />
      <AlertDialog
        variant={ALERT_TYPES.CONFIRM}
        alertTitle={specialToFeature && specialToFeature.isBanner ? 'Un-feature special' : 'Feature Special as Banner'}
        alertMessage={
          specialToFeature && specialToFeature.isBanner
            ? 'Un-featuring a special will remove it from appearing in dashboard banners. Do you wish to continue?'
            : 'Featuring this special will make it appear in dashboard banners. Do you wish to continue?'
        }
        isOpen={featureSpecialDialogOpen}
        handleClose={closeFeatureSpecialDialog}
        handleConfirm={handleToggleFeatureSpecial}
        confirmMessage={'Yes'}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isStaff: state.auth.supplierDetails ? state.auth.supplierDetails.isStaff : false,
});

Specials.propTypes = {
  isStaff: PropTypes.bool,
  redirectToProduct: PropTypes.func.isRequired,
  redirectToNewSpecial: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  updateSearchQueryParam: PropTypes.func.isRequired,
  getQueryFromQueryString: PropTypes.func.isRequired,
  updateSpecialFilterQueryParam: PropTypes.func.isRequired,
  getSpecialFilterQueryFromQueryString: PropTypes.func.isRequired,
};

export default withErrorReports(
  withNotifications(withRedirectHelper(withQueryParams(connect(mapStateToProps, null)(Specials)))),
);
