import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { ClickAwayListener } from '@material-ui/core';
import {
  BUTTON_VARIANTS,
  MAX_NUMBER_OF_LOSS_LEADER_PRODUCTS,
  NOTIFICATION_TYPES,
  TYPOGRAPHY_TYPES,
} from '../../utils/Constants';
import { Button, ErrorNotification, Paper, Tooltip, Typography } from '../UI/FB';
import LossLeaderProductsModal from './LossLeaderProductsModal/LossLeaderProductsModal';
import { LossLeaderProductsForSelect } from '../../containers/LossLeaders/LossLeaderTypes';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import { CreateNotification, SendErrorReportType } from '../../utils/Presenters/ReduxType';
import styles from './LossLeaderProduct.module.scss';
import LargeLoadingSpinner from '../UI/LargeLoadingSpinner/LargeLoadingSpinner';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog/DeleteConfirmationDialog';

type LossLeaderProductsTypes = {
  addProductDisabled: boolean;
  products: LossLeaderProductsForSelect[];
  loadingLossLeaderProducts: boolean;
  setLoadingLossLeaderProducts: (val: boolean) => void;
  onSuccessfulLossProductAPICall: () => void;
  lossLeaderProducts: LossLeaderProductsForSelect[];
  sendDatadogError: SendErrorReportType;
  createNotification: CreateNotification;
  APIError: boolean;
};

const LossLeaderProducts: FC<LossLeaderProductsTypes> = ({
  addProductDisabled,
  products,
  loadingLossLeaderProducts,
  setLoadingLossLeaderProducts,
  onSuccessfulLossProductAPICall,
  lossLeaderProducts,
  sendDatadogError,
  createNotification,
  APIError,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [lossProductToDeleteId, setLossProductToDeleteId] = useState<number | undefined>(undefined);
  const [lossLeaderTooltip, setLossLeaderTooltip] = useState<boolean>(false);

  const triggerModal = (): void => setOpenModal(true);
  const closeModal = (): void => setOpenModal(false);

  const handleDeleteLossProducts = (id: number) => {
    setLossProductToDeleteId(id);
  };

  const handleDelete = () => {
    setLoadingLossLeaderProducts(true);
    FoodbombAPI.delete(`/suppliers/loss-products/${lossProductToDeleteId}`)
      .then(() => {
        onSuccessfulLossProductAPICall();
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully removed loss product.',
          timeout: 4000,
          closable: true,
        });
      })
      .catch((error) => {
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to remove loss product.',
          timeout: 4000,
          closable: true,
        });
        sendDatadogError('Unable to remove loss product.', {
          error,
          location: 'Loss leader product page',
        });
      })
      .finally(() => setLoadingLossLeaderProducts(false));
  };

  return (
    <Paper
      className={
        lossLeaderProducts
          ? [styles.LossLeaderProductsContainer, styles.LossLeaderProducts].join(' ')
          : styles.LossLeaderProductsContainer
      }
    >
      <div className={styles.LossLeaderProductsHeader}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL} className={styles.LossLeaderProducts__title}>
          Loss leader products
        </Typography>
        {lossLeaderProducts.length > 0 ? (
          <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.LossLeaderProducts__subTitle}>
            You can add up to 10 loss leader products.
          </Typography>
        ) : null}
      </div>
      {APIError ? (
        <ErrorNotification
          className={styles.ErrorWrapper}
          body="We were unable to load your products, our engineers have been notified."
        />
      ) : (
        <>
          {loadingLossLeaderProducts ? (
            <LargeLoadingSpinner small />
          ) : (
            <>
              {lossLeaderProducts?.length ? (
                <div className={styles.LossProductsInfoContainer}>
                  <div className={styles.LossProductInfoHeaderRow}>
                    <Typography className={styles.ProductSKU__head} type={TYPOGRAPHY_TYPES.BODY_BOLD}>
                      SKU
                    </Typography>
                    <Typography className={styles.ProductName__head} type={TYPOGRAPHY_TYPES.BODY_BOLD}>
                      Product Name
                    </Typography>
                    <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD} />
                  </div>
                  {lossLeaderProducts.map((lossProduct: LossLeaderProductsForSelect) => (
                    <div key={lossProduct.id} className={styles.LossProductInfo__bodyRow}>
                      <Typography className={styles.ProductSKU__body} type={TYPOGRAPHY_TYPES.BODY}>
                        {lossProduct.sku}
                      </Typography>
                      <Link className={styles.ProductName__body} to={`/products/${lossProduct.id}`}>
                        <Typography className={styles.ProductName__text} type={TYPOGRAPHY_TYPES.BODY}>
                          {lossProduct.name}
                        </Typography>
                      </Link>

                      <button
                        type="button"
                        onClick={() => handleDeleteLossProducts(lossProduct.id)}
                        className={styles.LossProductsDeleteButton}
                      >
                        <DeleteOutlineIcon className={styles.LossProductsIcon} />
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div className={styles.LossLeaderProducts__bodyInfo}>
                    <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.LossLeaderProducts__bodyText}>
                      Any products added here will not be available to selected venues until they meet the threshold.
                    </Typography>
                    <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.LossLeaderProductsBodyText}>
                      You can add up to 10 products and you can change them at anytime.
                    </Typography>
                  </div>
                </>
              )}
              <div className={styles.LossLeaderProductsAction}>
                {addProductDisabled ? (
                  <div>
                    <button
                      className={styles.FakeBtn}
                      onClick={() => setLossLeaderTooltip(true)}
                      onMouseLeave={() => setLossLeaderTooltip(false)}
                      type="button"
                    >
                      <ClickAwayListener onClickAway={() => setLossLeaderTooltip(false)}>
                        <Tooltip
                          title={
                            lossLeaderProducts.length === MAX_NUMBER_OF_LOSS_LEADER_PRODUCTS
                              ? 'You cannot add more than 10 products'
                              : 'Please set an order threshold first'
                          }
                          onClose={() => setLossLeaderTooltip(false)}
                          onOpen={() => setLossLeaderTooltip(true)}
                          open={lossLeaderTooltip}
                        >
                          <Button
                            onClick={triggerModal}
                            variant={BUTTON_VARIANTS.SECONDARY}
                            disabled={addProductDisabled}
                            className={styles.LossLeaderProductAddButton}
                          >
                            <AddIcon className={styles.ActionIcon} />
                            Add Product
                          </Button>
                        </Tooltip>
                      </ClickAwayListener>
                    </button>
                  </div>
                ) : (
                  <Button onClick={triggerModal} variant={BUTTON_VARIANTS.SECONDARY} disabled={addProductDisabled}>
                    <AddIcon className={styles.ActionIcon} />
                    Add Product
                  </Button>
                )}
              </div>
            </>
          )}
        </>
      )}
      <LossLeaderProductsModal
        isOpen={openModal}
        onClose={closeModal}
        loadingLossLeaderProducts={loadingLossLeaderProducts}
        products={products}
        onSuccessfulLossProductAPICall={onSuccessfulLossProductAPICall}
        sendDatadogError={sendDatadogError}
        createNotification={createNotification}
      />
      <DeleteConfirmationDialog
        isOpen={Boolean(lossProductToDeleteId)}
        handleClose={() => setLossProductToDeleteId(undefined)}
        handleDelete={handleDelete}
        confirmationMessage="Are you sure you want to remove this product?"
      />
    </Paper>
  );
};

LossLeaderProducts.propTypes = {
  addProductDisabled: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  loadingLossLeaderProducts: PropTypes.bool.isRequired,
  onSuccessfulLossProductAPICall: PropTypes.func.isRequired,
  lossLeaderProducts: PropTypes.array.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  APIError: PropTypes.bool.isRequired,
};

export default LossLeaderProducts;
