import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { withRouter, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { CircularProgress, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from '../../store/actions';
import { FormikFormField, Button, Typography, NotificationMessageSection } from '../UI/FB';
import { TYPOGRAPHY_TYPES, NOTIFICATION_MESSAGE_TYPES, BUTTON_VARIANTS } from '../../utils/Constants';
import styles from './LoginForm.module.scss';

const LoginForm = ({ hasToken, isAuthLoading, loginError, location, onLogin }) => {
  const [showPassword, setShowPassword] = useState(false);

  const onClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const attemptToLogin = (values) => {
    onLogin(values.email, values.password);
  };

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string().trim().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  if (hasToken) {
    return <Redirect to={location.state && location.state.previousLink ? location.state.previousLink : '/dashboard'} />;
  }

  return (
    <div>
      <div className={styles.Wrapper}>
        <div>
          <Typography type={TYPOGRAPHY_TYPES.HEADING_S} className={styles.SubHeading}>
            <span role="img" aria-label="wave">
              👋
            </span>
            &nbsp;Welcome back!
          </Typography>
          <Typography type={TYPOGRAPHY_TYPES.HEADING_XXL}>Sign In</Typography>
        </div>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={loginValidationSchema}
          onSubmit={attemptToLogin}
          id={'loginForm'}
        >
          {({ errors, touched }) => (
            <Form className={styles.LoginForm}>
              <div className={styles.LoginForm__formContent}>
                <div className={styles.LoginForm__inputs}>
                  <div className={styles.Login__inputContainer}>
                    <FormikFormField
                      id="loginEmailInput"
                      fieldName="email"
                      touched={touched}
                      errors={errors}
                      placeholder="Enter your email"
                      label="Email"
                      fieldProps={{
                        autoComplete: 'email',
                      }}
                    />
                  </div>
                  <div className={styles.Login__inputContainer}>
                    <FormikFormField
                      id="loginPasswordInput"
                      fieldName="password"
                      touched={touched}
                      errors={errors}
                      placeholder="Enter your password"
                      label="Password"
                      fieldProps={{ type: showPassword ? 'text' : 'password', autoComplete: 'current-password' }}
                      endAdornment={
                        <IconButton
                          tabIndex="-1"
                          aria-label="toggle password visibility"
                          className={styles.PasswordField__endAdornment}
                          onClick={onClickShowPassword}
                          onMouseDown={onMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={styles.LoginForm__errors}>
                {loginError ? (
                  <NotificationMessageSection
                    type={NOTIFICATION_MESSAGE_TYPES.ERROR}
                    title="We were unable to log you in!"
                    emoji="😨"
                    ariaLabelForEmoji="fearFace"
                  >
                    {loginError.status === 400 ? (
                      <ul className={styles.ErrorList}>
                        {loginError.data.errors.map((error) => (
                          <li key={`${error.code}-${error.param}`}>{error.message}</li>
                        ))}
                      </ul>
                    ) : (
                      'Please check your email and password and try again'
                    )}
                  </NotificationMessageSection>
                ) : null}
              </div>
              <div className={styles.LoginForm__footer}>
                <Button
                  variant={BUTTON_VARIANTS.LIGHT_PRIMARY}
                  type="submit"
                  className={styles.SubmitBtn}
                  disabled={isAuthLoading || Boolean(errors && Object.keys(errors).length)}
                >
                  {isAuthLoading ? (
                    <CircularProgress size={24} className={styles.SubmitBtn__loading} />
                  ) : (
                    <React.Fragment>Sign In</React.Fragment>
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginError: state.auth.error,
  hasToken: Boolean(state.auth.token),
  isAuthLoading: state.auth.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onLogin: (email, password) => dispatch(actions.authLogin(email, password)),
});

LoginForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
  loginError: PropTypes.object,
  isAuthLoading: PropTypes.bool.isRequired,
  hasToken: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
