export interface TimeType {
  id: string;
  label: string;
  value: string;
}
export const TIMES: TimeType[] = [
  {
    id: '01:00:00',
    label: '1am',
    value: '01:00:00',
  },
  {
    id: '02:00:00',
    label: '2am',
    value: '02:00:00',
  },
  {
    id: '03:00:00',
    label: '3am',
    value: '03:00:00',
  },
  {
    id: '04:00:00',
    label: '4am',
    value: '04:00:00',
  },
  {
    id: '05:00:00',
    label: '5am',
    value: '05:00:00',
  },
  {
    id: '06:00:00',
    label: '6am',
    value: '06:00:00',
  },
  {
    id: '07:00:00',
    label: '7am',
    value: '07:00:00',
  },
  {
    id: '08:00:00',
    label: '8am',
    value: '08:00:00',
  },
  {
    id: '09:00:00',
    label: '9am',
    value: '09:00:00',
  },
  {
    id: '10:00:00',
    label: '10am',
    value: '10:00:00',
  },
  {
    id: '11:00:00',
    label: '11am',
    value: '11:00:00',
  },
  {
    id: '12:00:00',
    label: '12pm - Noon',
    value: '12:00:00',
  },
  {
    id: '13:00:00',
    label: '1pm',
    value: '13:00:00',
  },
  {
    id: '14:00:00',
    label: '2pm',
    value: '14:00:00',
  },
  {
    id: '15:00:00',
    label: '3pm',
    value: '15:00:00',
  },
  {
    id: '16:00:00',
    label: '4pm',
    value: '16:00:00',
  },
  {
    id: '17:00:00',
    label: '5pm',
    value: '17:00:00',
  },
  {
    id: '18:00:00',
    label: '6pm',
    value: '18:00:00',
  },
  {
    id: '19:00:00',
    label: '7pm',
    value: '19:00:00',
  },
  {
    id: '20:00:00',
    label: '8pm',
    value: '20:00:00',
  },
  {
    id: '21:00:00',
    label: '9pm',
    value: '21:00:00',
  },
  {
    id: '22:00:00',
    label: '10pm',
    value: '22:00:00',
  },
  {
    id: '23:00:00',
    label: '11pm',
    value: '23:00:00',
  },
  {
    id: '00:00:00',
    label: '12:00am - Midnight',
    value: '00:00:00',
  },
];
