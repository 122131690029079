import React, { useState, useEffect, useCallback } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { MenuItem } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

import {
  ErrorNotification,
  Typography,
  Emoji,
  Paper,
  Tooltip,
  MetricWidgetCard,
  Menu,
  Button,
} from '../../components/UI/FB';
import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import styles from './Reports.module.scss';
import { TYPOGRAPHY_TYPES, BUTTON_VARIANTS } from '../../utils/Constants';
import NumberedRowCard from '../../components/Reports/NumberedRowCard/NumberedRowCard';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import { presentCurrency } from '../../utils/Presenters/PresentCurrency/PresentCurrency';
import DownloadOrdersDialog from '../../components/DownloadOrdersDialog/DownloadOrdersDialog';
import DownloadProductsDialog from '../../components/DownloadProductsDialog/DownloadProductsDialog';

const Reports = ({ sendDatadogError }) => {
  const [customersThisMonth, setCustomersThisMonth] = useState(0);
  const [customersLastMonth, setCustomersLastMonth] = useState(0);
  const [loadingCustomerStats, setLoadingCustomerStats] = useState(false);
  const [customerStatsError, setCustomerStatsError] = useState(false);

  const [AOVThisMonth, setAOVThisMonth] = useState(0);
  const [AOVLastMonth, setAOVLastMonth] = useState(0);
  const [loadingAOVStats, setLoadingAOVStats] = useState(false);
  const [AOVError, setAOVError] = useState(false);

  const [salesStats, setSalesStats] = useState([]);
  const [ordersStats, setOrdersStats] = useState([]);
  const [loadingSalesStats, setLoadingSalesStats] = useState(false);
  const [salesStatsError, setSalesStatsError] = useState(false);
  const [loadingOrdersStats, setLoadingOrdersStats] = useState(false);
  const [ordersStatsError, setOrdersStatsError] = useState(false);

  const [topCustomersThisMonth, setTopCustomersThisMonth] = useState([]);
  const [topCustomersLastMonth, setTopCustomersLastMonth] = useState([]);
  const [topCustomersError, setTopCustomersError] = useState(false);
  const [topCustomersAnchorElem, setTopCustomersAnchorElem] = useState(undefined);
  const [topCustomersDisplay, setTopCustomersDisplay] = useState([]);
  const [topCustomersSelectedMonth, setTopCustomersSelectedMonth] = useState('');

  const [topProductsThisMonth, setTopProductsThisMonth] = useState([]);
  const [topProductsLastMonth, setTopProductsLastMonth] = useState([]);
  const [topProductsError, setTopProductsError] = useState(false);
  const [topProductsAnchorElem, setTopProductsAnchorElem] = useState(undefined);
  const [topProductsDisplay, setTopProductsDisplay] = useState([]);
  const [topProductsSelectedMonth, setTopProductsSelectedMonth] = useState('');
  const [downloadOrdersDialogOpen, setDownloadOrdersDialogOpen] = useState(false);
  const [downloadProductsDialogOpen, setDownloadProductsDialogOpen] = useState(false);

  const handleTopCustomersFilterClicked = (e) => setTopCustomersAnchorElem(e.currentTarget);
  const handleCloseTopCustomersFilterMenu = () => setTopCustomersAnchorElem(undefined);

  const handleTopProductsFilterClicked = (e) => setTopProductsAnchorElem(e.currentTarget);
  const handleCloseTopProductsFilterMenu = () => setTopProductsAnchorElem(undefined);

  const openDownloadProductsDialog = () => {
    setDownloadProductsDialogOpen(true);
  };

  const closeDownloadProductsDialog = () => {
    setDownloadProductsDialogOpen(false);
  };

  const openDownloadOrdersDialog = () => {
    setDownloadOrdersDialogOpen(true);
  };

  const closeDownloadOrdersDialog = () => {
    setDownloadOrdersDialogOpen(false);
  };

  const SELECTED_MONTH = {
    THIS_MONTH: 'thisMonth',
    LAST_MONTH: 'lastMonth',
  };

  const thisMonth = moment().format('MMMM');
  const lastMonth = moment().subtract(1, 'month').format('MMMM');

  const selectTopCustomersFilter = (selectedMonth) => {
    if (selectedMonth === SELECTED_MONTH.THIS_MONTH) {
      setTopCustomersSelectedMonth(SELECTED_MONTH.THIS_MONTH);
    } else {
      setTopCustomersSelectedMonth(SELECTED_MONTH.LAST_MONTH);
    }
    handleCloseTopCustomersFilterMenu();
  };

  const selectTopProductsFilter = (selectedMonth) => {
    if (selectedMonth === SELECTED_MONTH.THIS_MONTH) {
      setTopProductsSelectedMonth(SELECTED_MONTH.THIS_MONTH);
    } else {
      setTopProductsSelectedMonth(SELECTED_MONTH.LAST_MONTH);
    }
    handleCloseTopProductsFilterMenu();
  };

  const fetchLastYearSalesStats = useCallback(() => {
    setLoadingSalesStats(true);
    setSalesStatsError(false);

    setLoadingOrdersStats(true);
    setOrdersStatsError(false);

    setLoadingCustomerStats(true);
    setCustomerStatsError(false);

    setLoadingAOVStats(true);
    setAOVError(false);

    FoodbombAPI.get('suppliers/report/last-twelve-months')
      .then((response) => {
        const sales = response.data.map((month) => parseInt(month.sales, 10));
        setSalesStats(sales);

        const orders = response.data.map((month) => month.orders, 10);
        setOrdersStats(orders);

        setCustomersThisMonth(response.data[0].active_customers);
        setCustomersLastMonth(response.data[1].active_customers);

        setAOVThisMonth(parseInt(response.data[0].aov, 10));
        setAOVLastMonth(parseInt(response.data[1].aov, 10));
      })
      .catch((error) => {
        sendDatadogError('Unable to load sales stats for reports', {
          error,
          location: 'Reports Page',
        });
        setSalesStatsError(true);
        setOrdersStatsError(true);
        setCustomerStatsError(true);
        setAOVError(true);
      })
      .finally(() => {
        setLoadingSalesStats(false);
        setLoadingOrdersStats(false);
        setLoadingCustomerStats(false);
        setLoadingAOVStats(false);
      });
  }, [sendDatadogError]);

  const fetchTopCustomers = useCallback(() => {
    setTopCustomersError(false);
    FoodbombAPI.get('suppliers/report/customers/top')
      .then((response) => {
        setTopCustomersThisMonth(response.data.thisMonth);
        setTopCustomersLastMonth(response.data.lastMonth);
      })
      .catch((error) => {
        sendDatadogError('Unable to load top customers for reports', {
          error,
          location: 'Reports Page',
        });
        setTopCustomersError(true);
      });
  }, [sendDatadogError]);

  const fetchTopProducts = useCallback(() => {
    setTopProductsError(false);
    FoodbombAPI.get('suppliers/report/best-selling')
      .then((response) => {
        setTopProductsThisMonth(response.data.thisMonth);
        setTopProductsLastMonth(response.data.lastMonth);
      })
      .catch((error) => {
        sendDatadogError('Unable to load best selling products for reports', {
          error,
          location: 'Reports Page',
        });
        setTopProductsError(true);
      });
  }, [sendDatadogError]);

  const filterTopCustomers = useCallback(
    (selectedMonth, customersThisMonthToFilter, customersLastMonthToFilter) => {
      if (selectedMonth === SELECTED_MONTH.THIS_MONTH) {
        setTopCustomersDisplay(customersThisMonthToFilter);
      } else {
        setTopCustomersDisplay(customersLastMonthToFilter);
      }
    },
    [SELECTED_MONTH.THIS_MONTH],
  );

  const filterTopProducts = useCallback(
    (selectedMonth, bestSellingThisMonth, bestSellingLastMonth) => {
      if (selectedMonth === SELECTED_MONTH.THIS_MONTH) {
        setTopProductsDisplay(bestSellingThisMonth);
      } else {
        setTopProductsDisplay(bestSellingLastMonth);
      }
    },
    [SELECTED_MONTH.THIS_MONTH],
  );

  useEffect(() => {
    fetchLastYearSalesStats();
    fetchTopCustomers();
    fetchTopProducts();
    setTopCustomersSelectedMonth(SELECTED_MONTH.THIS_MONTH);
    setTopProductsSelectedMonth(SELECTED_MONTH.THIS_MONTH);
  }, [fetchLastYearSalesStats, fetchTopCustomers, fetchTopProducts, SELECTED_MONTH.THIS_MONTH]);

  useEffect(() => {
    filterTopCustomers(topCustomersSelectedMonth, topCustomersThisMonth, topCustomersLastMonth);
  }, [filterTopCustomers, topCustomersSelectedMonth, topCustomersThisMonth, topCustomersLastMonth]);

  useEffect(() => {
    filterTopProducts(topProductsSelectedMonth, topProductsThisMonth, topProductsLastMonth);
  }, [filterTopProducts, topProductsSelectedMonth, topProductsThisMonth, topProductsLastMonth]);

  const formatDataForChart = () => {
    const display = salesStats.slice(0, 12);
    return display.reverse();
  };

  const formatOrdersDataForChart = () => {
    const display = ordersStats.slice(0, 12);
    return display.reverse();
  };

  const getLabelsForChart = () => {
    const chartBarCount = formatDataForChart().length;
    const chartLabels = [];
    for (let i = 0; i < chartBarCount; i += 1) {
      chartLabels.push(moment().subtract(i, 'month').format('MMM'));
    }
    return chartLabels.reverse();
  };

  const chartOptions = {
    legend: {
      display: true,
    },
    scales: {
      yAxes: [
        {
          id: 'L',
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Dollars ($)',
          },
          ticks: {
            beginAtZero: true, // minimum value will be 0.
            suggestedMax: 10,
          },
          gridLines: {
            display: true,
          },
          pointLabels: {
            fontFamily: "'Source Sans Pro'",
          },
        },
        {
          id: 'R',
          position: 'right',
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Orders',
          },
          ticks: {
            beginAtZero: true, // minimum value will be 0.
            suggestedMax: 10,
          },
          gridLines: {
            display: true,
          },
          pointLabels: {
            fontFamily: "'Source Sans Pro'",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontFamily: "'Source Sans Pro'",
            fontSize: 16,
            fontStyle: 'bold',
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };

  const chartData = {
    labels: getLabelsForChart(),
    title: {
      display: false,
    },
    datasets: [
      {
        label: 'Sales per month',
        yAxisID: 'L',
        backgroundColor: 'rgba(0, 148, 71, 1)',
        borderColor: 'rgba(0, 148, 71, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(0, 107, 51, 1)',
        hoverBorderColor: 'rgba(0, 107, 51, 1)',
        data: formatDataForChart(),
        barPercentage: 1,
        barThickness: 'flex',
      },
      {
        label: 'Orders per month',
        yAxisID: 'R',
        backgroundColor: 'rgba(0, 71, 148, 1)',
        borderColor: 'rgba(0, 71, 148, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(0, 51, 107, 1)',
        hoverBorderColor: 'rgba(0, 51, 107, 1)',
        data: formatOrdersDataForChart(),
        barPercentage: 1,
        barThickness: 'flex',
      },
    ],
  };

  return (
    <React.Fragment>
      <div className={styles.ReportsContainer}>
        <PageHeader>
          <div className={styles.SubheadingContainer}>
            <Breadcrumbs
              currentPageTitle={
                <React.Fragment>
                  <Emoji content="📊" label="chart" />
                  &nbsp; Reports
                </React.Fragment>
              }
            />
            <div className={styles.DownloadActionsContainer}>
              <Tooltip title={'Download CSV product data '} placement="bottom">
                <div>
                  <Button
                    className={styles.DownloadActionBtn__orderCSV}
                    variant={BUTTON_VARIANTS.SECONDARY}
                    onClick={() => openDownloadProductsDialog()}
                  >
                    <GetAppOutlinedIcon className={styles.ActionButton__icon} />
                    Product CSV
                  </Button>
                </div>
              </Tooltip>
              <Tooltip title={'Download CSV order data '} placement="bottom">
                <div>
                  <Button
                    className={styles.DownloadActionBtn__orderCSV}
                    variant={BUTTON_VARIANTS.SECONDARY}
                    onClick={() => openDownloadOrdersDialog()}
                  >
                    <GetAppOutlinedIcon className={styles.ActionButton__icon} />
                    Orders CSV
                  </Button>
                </div>
              </Tooltip>
            </div>
          </div>
          <Typography type={TYPOGRAPHY_TYPES.HEADING_M}>Here&apos;s how your business is going</Typography>
        </PageHeader>
        <div className={styles.PageContainer}>
          <Paper className={styles.SalesChartContainer}>
            <Typography className={styles.SalesReportHeader} type={TYPOGRAPHY_TYPES.HEADING_L}>
              Sales and Orders Report (last 12 months)
            </Typography>
            {salesStatsError ? (
              <ErrorNotification
                className={styles.ErrorWrapper}
                body="We were unable to load your sales report! Don't worry, our engineers have been notified."
              />
            ) : (
              <Bar data={chartData} options={chartOptions} />
            )}
          </Paper>
          <div className={styles.MonthlySnapshotContainer}>
            <Typography className={styles.ReportSectionHeader} type={TYPOGRAPHY_TYPES.HEADING_XXL}>
              Monthly Snapshot
              <Tooltip
                title="This is the monthly snapshot for the current month. Quickly compare how you’re tracking with the previous month."
                placement="top"
              >
                <InfoOutlinedIcon className={styles.InfoIcon} />
              </Tooltip>
            </Typography>
            <div className={styles.SnapShotCardContainer}>
              <MetricWidgetCard
                title={`Sales`}
                secondarySubValue={salesStats[1] ? `${lastMonth}: ${presentCurrency(salesStats[1])}` : ''}
                primaryValue={salesStats[0] ? `${presentCurrency(salesStats[0])}` : ''}
                loading={loadingSalesStats}
                errored={salesStatsError}
              />
              <MetricWidgetCard
                title={`Orders`}
                secondarySubValue={ordersStats[1] ? `${lastMonth}: ${ordersStats[1]}` : ''}
                primaryValue={ordersStats[0] ? `${ordersStats[0]}` : ''}
                loading={loadingOrdersStats}
                errored={ordersStatsError}
              />
              <MetricWidgetCard
                title={`Customers`}
                secondarySubValue={customersLastMonth ? `${lastMonth}: ${customersLastMonth}` : ''}
                primaryValue={customersThisMonth || ''}
                loading={loadingCustomerStats}
                errored={customerStatsError}
              />
              <MetricWidgetCard
                title={`Average order value`}
                secondarySubValue={AOVLastMonth ? `${lastMonth}: ${presentCurrency(AOVLastMonth)}` : ''}
                primaryValue={AOVThisMonth ? `${presentCurrency(AOVThisMonth)}` : ''}
                loading={loadingAOVStats}
                errored={AOVError}
              />
            </div>
          </div>
          <div className={styles.TopCustomersContainer}>
            <div className={styles.TopCustomersHeaderContainer}>
              <Typography className={styles.ReportSectionHeader} type={TYPOGRAPHY_TYPES.HEADING_XXL}>
                Top Customers
                <Tooltip
                  title="These are your top customers by monthly total spend. We’re working on showing you more details in the future. Anything you’d like to see? Email info@foodbomb.com.au"
                  placement="top"
                >
                  <InfoOutlinedIcon className={styles.InfoIcon} />
                </Tooltip>
              </Typography>
              <div className={styles.ActionBar__buttonsContainer}>
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  className={styles.ActionBar__button}
                  onClick={handleTopCustomersFilterClicked}
                >
                  {topCustomersSelectedMonth === SELECTED_MONTH.THIS_MONTH ? thisMonth : lastMonth}
                  <ArrowDropDownIcon className={styles.DropdownIcon} />
                </Button>
              </div>
              <Menu
                open={Boolean(topCustomersAnchorElem)}
                anchorEl={topCustomersAnchorElem}
                onClose={handleCloseTopCustomersFilterMenu}
              >
                <MenuItem
                  key={thisMonth}
                  onClick={() => selectTopCustomersFilter(SELECTED_MONTH.THIS_MONTH)}
                  classes={{ root: styles.MenuLink }}
                >
                  {thisMonth}
                </MenuItem>
                <MenuItem
                  key={lastMonth}
                  onClick={() => selectTopCustomersFilter(SELECTED_MONTH.LAST_MONTH)}
                  classes={{ root: styles.MenuLink }}
                >
                  {lastMonth}
                </MenuItem>
              </Menu>
            </div>
            <div className={styles.RowCardContainer}>
              {topCustomersDisplay.length > 0 ? (
                <React.Fragment>
                  {topCustomersDisplay.map((item, idx) => (
                    <NumberedRowCard
                      key={item.name}
                      number={idx + 1}
                      firstField={item.name}
                      middleField={item.orders}
                      lastField={presentCurrency(item.sales)}
                    />
                  ))}
                </React.Fragment>
              ) : (
                <Paper className={styles.EmptyPaper}>
                  <Typography className={styles.EmptyRow} type={TYPOGRAPHY_TYPES.HEADING_M}>
                    No top customers to display
                  </Typography>
                  {topCustomersError ? <ErrorOutlineOutlinedIcon className={styles.ErrorIcon} /> : null}
                </Paper>
              )}
            </div>
          </div>
          <div className={styles.BestSellingItemsContainer}>
            <div className={styles.TopProductsHeaderContainer}>
              <Typography className={styles.ReportSectionHeader} type={TYPOGRAPHY_TYPES.HEADING_XXL}>
                Best selling products
                <Tooltip
                  title="These are your best selling items. We’re working on showing you more details in the future. Anything you’d like to see? Email info@foodbomb.com.au"
                  placement="top"
                >
                  <InfoOutlinedIcon className={styles.InfoIcon} />
                </Tooltip>
              </Typography>
              <div className={styles.ActionBar__buttonsContainer}>
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  className={styles.ActionBar__button}
                  onClick={handleTopProductsFilterClicked}
                >
                  {topProductsSelectedMonth === SELECTED_MONTH.THIS_MONTH ? thisMonth : lastMonth}
                  <ArrowDropDownIcon className={styles.DropdownIcon} />
                </Button>
              </div>
              <Menu
                open={Boolean(topProductsAnchorElem)}
                anchorEl={topProductsAnchorElem}
                onClose={handleCloseTopProductsFilterMenu}
              >
                <MenuItem
                  key={thisMonth}
                  onClick={() => selectTopProductsFilter(SELECTED_MONTH.THIS_MONTH)}
                  classes={{ root: styles.MenuLink }}
                >
                  {thisMonth}
                </MenuItem>
                <MenuItem
                  key={lastMonth}
                  onClick={() => selectTopProductsFilter(SELECTED_MONTH.LAST_MONTH)}
                  classes={{ root: styles.MenuLink }}
                >
                  {lastMonth}
                </MenuItem>
              </Menu>
            </div>
            <div className={styles.RowCardContainer}>
              {topProductsDisplay.length > 0 ? (
                <React.Fragment>
                  {topProductsDisplay.map((item, idx) => (
                    <NumberedRowCard
                      key={item.name}
                      number={idx + 1}
                      firstField={item.name}
                      lastField={presentCurrency(item.sales)}
                    />
                  ))}
                </React.Fragment>
              ) : (
                <Paper className={styles.EmptyPaper}>
                  <Typography className={styles.EmptyRow} type={TYPOGRAPHY_TYPES.HEADING_M}>
                    No best selling products to display
                  </Typography>
                  {topProductsError ? <ErrorOutlineOutlinedIcon className={styles.ErrorIcon} /> : null}
                </Paper>
              )}
            </div>
          </div>
        </div>
        <DownloadOrdersDialog isOpen={downloadOrdersDialogOpen} handleClose={closeDownloadOrdersDialog} />
        <DownloadProductsDialog isOpen={downloadProductsDialogOpen} handleClose={closeDownloadProductsDialog} />
      </div>
    </React.Fragment>
  );
};

Reports.propTypes = {
  sendDatadogError: PropTypes.func.isRequired,
};
export default withErrorReports(withNotifications(Reports));
