import React from 'react';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';

import { Typography } from '../FB';
import { TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ currentPageTitle, previousLinks }) => (
  <div className={styles.BreadcrumbsContainer}>
    {previousLinks
      ? previousLinks.map((previousLink) => (
          <div key={previousLink.previousPageTitle} className={styles.Breadcrumb}>
            {previousLink.asATag ? (
              <a
                href={previousLink.previousPageLink}
                className={styles.PreviousLink}
                onClick={previousLink.onClickFunction}
              >
                <Typography type={TYPOGRAPHY_TYPES.HEADING_XXL} className={styles.noMargin}>
                  {previousLink.previousPageTitle}
                </Typography>
              </a>
            ) : (
              <Link
                to={previousLink.previousPageLink}
                className={styles.PreviousLink}
                onClick={previousLink.onClickFunction}
              >
                <Typography type={TYPOGRAPHY_TYPES.HEADING_XXL} className={styles.noMargin}>
                  {previousLink.previousPageTitle}
                </Typography>
              </Link>
            )}
            <ChevronRightIcon className={styles.SeparatorIcon} />
          </div>
        ))
      : null}
    <Typography type={TYPOGRAPHY_TYPES.HEADING_XXL} className={styles.noMargin}>
      {currentPageTitle}
    </Typography>
  </div>
);

Breadcrumbs.propTypes = {
  previousLinks: PropTypes.array,
  currentPageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node, PropTypes.func]),
};

export default Breadcrumbs;
