import { useState, FC, ElementType } from 'react';
import PropTypes from 'prop-types';
import { AxiosError } from 'axios';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Formik, Form, FormikHelpers } from 'formik';
import { CircularProgress } from '@material-ui/core';
import { Paper, Typography, Button, ErrorNotification } from '../../../components/UI/FB';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES, BUTTON_VARIANTS } from '../../../utils/Constants';
import { SendErrorReportType, CreateNotification } from '../../../utils/Presenters/ReduxType';
import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import BackgroundImageUploader from '../ImageUploader/BackgroundImageUploader';
import styles from '../Settings.module.scss';
import { Supplier } from '../../../utils/Presenters/Common';
import LogoImageUploader from '../ImageUploader/LogoImageUploader';

type StoreImagesCardProps = {
  pulse: boolean;
  supplierInfo: Supplier;
  innerRef: () => void | ElementType;
  sendDatadogError: SendErrorReportType;
  createNotification: CreateNotification;
  updateSupplierDetails: (data: Supplier) => void;
};

type ValuesType = {
  backgroundImageUrl: string;
  image: string;
};

const StoreImagesCard: FC<StoreImagesCardProps> = ({
  pulse,
  innerRef,
  createNotification,
  sendDatadogError,
  supplierInfo,
  updateSupplierDetails,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [backgroundImage, setBackgroundImage] = useState<string>(supplierInfo?.backgroundImageUrl || '');
  const [logoImage, setLogoImage] = useState<string>(supplierInfo?.image || '');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const triggerEditMode = (): void => setEditMode(true);
  const triggerViewMode = (): void => setEditMode(false);

  const onSuccessBackgroundUpload = (url: string): void => {
    setBackgroundImage(url);
  };

  const onSuccessLogoUpload = (url: string): void => {
    const logoUrl = url?.slice(0, -12) + url?.slice(-4);
    setLogoImage(logoUrl);
  };

  const handleRemoveBackground = (): void => setBackgroundImage('');
  const handleRemoveLogo = (): void => setLogoImage('');

  // Update supplier background image
  const handleUpdateImageForSupplier = (values: ValuesType, actions: FormikHelpers<ValuesType>) => {
    const detailsToUpdate: { backgroundImageUrl?: string; image?: string } = {
      backgroundImageUrl: backgroundImage || values.backgroundImageUrl,
      image: logoImage || values.image,
    };

    FoodbombAPI.patch('suppliers/auth/me', detailsToUpdate)
      .then((response) => {
        updateSupplierDetails(response.data);
        triggerViewMode();
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully updated supplier background image',
          timeout: 4000,
          closable: true,
        });
        actions.setSubmitting(false);
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status === 400 && error?.response?.data?.errors) {
          setErrorMessage(error?.response?.data?.errors[0]?.message);
        } else {
          sendDatadogError('Unable to supplier background image', {
            error,
            location: 'Settings Page',
          });
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to update supplier background image',
            timeout: 4000,
            closable: true,
          });
          actions.setStatus({
            apiError: (
              <ErrorNotification
                body={"We were unable to update your store information. Don't worry, our engineers have been notified!"}
                className={styles.ErrorNotification}
              />
            ),
          });
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <div ref={innerRef}>
      <Paper className={pulse ? [styles.SettingsCard, styles.pulse].join(' ') : styles.SettingsCard}>
        <Formik
          initialValues={{ backgroundImageUrl: backgroundImage, image: logoImage }}
          initialStatus={{
            apiError: undefined,
          }}
          onSubmit={handleUpdateImageForSupplier}
          enableReinitialize
        >
          {({ isSubmitting, errors, status }) => (
            <>
              <CheckCircleOutlineIcon
                className={
                  !editMode
                    ? styles.SettingsCard__completeIcon
                    : [styles.SettingsCard__completeIcon, styles.hide].join(' ')
                }
              />
              <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.SettingsCard__sectionHeading}>
                Store Images
              </Typography>
              <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__imageUploader__subHeading}>
                Personalise your store by adding header and logo images.
              </Typography>
              {editMode ? (
                <>
                  <Form>
                    <div className={styles.ImageUpload__container}>
                      <BackgroundImageUploader
                        onSuccessUpload={onSuccessBackgroundUpload}
                        supplierInfo={supplierInfo}
                        sendDatadogError={sendDatadogError}
                        createNotification={createNotification}
                        errorMessage={Object.values(errors)[0]}
                        onRemoveBackground={handleRemoveBackground}
                      />
                      <div className={styles.Divider} />
                      <LogoImageUploader
                        onSuccessUpload={onSuccessLogoUpload}
                        supplierInfo={supplierInfo}
                        sendDatadogError={sendDatadogError}
                        createNotification={createNotification}
                        errorMessage={Object.values(errors)[1]}
                        onRemoveLogo={handleRemoveLogo}
                      />
                      {errorMessage ? (
                        <Typography type={TYPOGRAPHY_TYPES.BODY_S} className={styles.ImageUpload__errorMessage}>
                          {errorMessage}
                        </Typography>
                      ) : null}
                      <div className={styles.ToggleFooterActionContainer}>
                        <Button type="button" variant={BUTTON_VARIANTS.SECONDARY} onClick={() => setEditMode(false)}>
                          Cancel
                        </Button>
                        <Button type="submit" disabled={isSubmitting || Object.keys(errors)?.length > 0}>
                          {isSubmitting ? (
                            <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                          ) : (
                            <>Save changes</>
                          )}
                        </Button>
                      </div>
                    </div>
                    <div className={styles.ErrorContainer}>{status.apiError}</div>
                  </Form>
                </>
              ) : (
                <div className={styles.SettingsCard__editBtnContainer}>
                  <Button
                    type="button"
                    variant={BUTTON_VARIANTS.SECONDARY}
                    onClick={triggerEditMode}
                    className={styles.EditBtn}
                  >
                    Upload images
                  </Button>
                </div>
              )}
            </>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

StoreImagesCard.propTypes = {
  createNotification: PropTypes.func.isRequired,
  pulse: PropTypes.bool.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  updateSupplierDetails: PropTypes.func.isRequired,
};

export default withErrorReports(withNotifications(StoreImagesCard));
