const categoryIdToName = (categoryId, categories) => {
  const matchedCategory = categories.filter((cat) => cat.id === categoryId);
  return matchedCategory.length ? matchedCategory[0].name : '';
};

const mapCategoryNamesToIds = (categoryNames, categories) => {
  const categoryIds = [];
  let categoryParentId = null;
  categoryNames.forEach((catName) => {
    const category = categories.filter((cat) => cat.name === catName && cat.parentCategoryId === categoryParentId);
    categoryIds.push(category[0].id);
    categoryParentId = category[0].id;
  });
  return categoryIds;
};

const mapWeightClassDescriptionsToIds = (unit, weightClassDescriptions) =>
  weightClassDescriptions.filter(
    (weightClass) =>
      weightClass.title.toLowerCase() === unit.toLowerCase() || weightClass.unit.toLowerCase() === unit.toLowerCase(),
  )[0].id;

const mapCategoryIdsToNames = (categoryId, categories) => categories.filter((cat) => cat.id === categoryId)[0].name;

const mapPortionUnitIdsToTitles = (portionUnitId, portionUnits) =>
  portionUnits.filter((unit) => unit.id === portionUnitId)[0].title;

const sortProductsByAnyErrorFirst = (prod1, prod2) => {
  const prod1Errors = prod1.productErrors.length;
  const prod2Errors = prod2.productErrors.length;
  if (!prod1Errors && prod2Errors) return 1;
  if (prod1Errors && !prod2Errors) return -1;
  return 0;
};

const sortProductsByCategoryOneErrorFirst = (prod1, prod2) => {
  const prod1Errors = prod1.productErrors.some((e) => e.path === 'categoryOne');
  const prod2Errors = prod2.productErrors.some((e) => e.path === 'categoryOne');
  if (!prod1Errors && prod2Errors) return 1;
  if (prod1Errors && !prod2Errors) return -1;
  return 0;
};

module.exports = {
  categoryIdToName,
  mapCategoryNamesToIds,
  mapWeightClassDescriptionsToIds,
  mapCategoryIdsToNames,
  mapPortionUnitIdsToTitles,
  sortProductsByAnyErrorFirst,
  sortProductsByCategoryOneErrorFirst,
};
