import jwtDecode from 'jwt-decode';
import { FoodbombAPI, FoodbombPDFAPI, DeliveryPreferencesAPI } from './AxiosInstances';
import { localStorageRemoveItem, localStorageSetItem } from './StorageUtils';

export const TOKEN = 'jwtToken';

const axiosInstancesToAuthenticate = [FoodbombAPI, FoodbombPDFAPI, DeliveryPreferencesAPI];

export const potentiallySetAxiosAuthorizationToken = (token) => {
  if (token) {
    axiosInstancesToAuthenticate.forEach((instance) => {
      // eslint-disable-next-line no-param-reassign
      instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    });
  } else {
    axiosInstancesToAuthenticate.forEach((instance) => {
      // eslint-disable-next-line no-param-reassign
      delete instance.defaults.headers.common.Authorization;
    });
  }
};

export const removeAxiosAuthorizationToken = () => {
  axiosInstancesToAuthenticate.forEach((instance) => {
    // eslint-disable-next-line no-param-reassign
    delete instance.defaults.headers.common.Authorization;
  });
};

export const getSupplierDetailsFromAuthToken = (token) => {
  const decodedToken = jwtDecode(token);
  return {
    id: parseInt(decodedToken.sub, 10),
    state: decodedToken.state,
    isStaff: decodedToken.staff,
    staffName: decodedToken.username ?? '',
    supplierName: decodedToken.supplierName ?? '',
  };
};

export const tokenHasValidHeader = (token) => {
  try {
    const header = jwtDecode(token, { header: true });
    return header.typ === 'JWT' && header.alg === 'HS256';
  } catch (e) {
    return false;
  }
};

export const saveAuthorizationTokenToLocalStorage = (token) => {
  if (token) {
    localStorageSetItem(TOKEN, token);
  }
};

export const clearAuthorizationTokenFromLocalStorage = () => {
  localStorageRemoveItem(TOKEN);
};

export const getTokenExpirationDate = (token) => new Date(jwtDecode(token).exp * 1000);

export const checkIfTokenExpired = (token) => {
  if (!token) {
    return true;
  }
  return !(getTokenExpirationDate(token) > new Date());
};
