import React from 'react';
import PropTypes from 'prop-types';

import styles from './PageHeader.module.scss';

const PageHeader = ({ children }) => (
  <div className={styles.HeaderContainer}>
    <div className={styles.Header}>
      <div className={styles.HeaderContent}>{children}</div>
    </div>
  </div>
);

PageHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node, PropTypes.func]),
};

export default PageHeader;
