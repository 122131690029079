import Moment from 'moment';

export const PresentCountdownTime = (totalTimeInSeconds) => {
  const hours = Math.floor(totalTimeInSeconds / 3600);
  const minutes = Math.floor(totalTimeInSeconds / 60) % 60;
  const seconds = Math.floor(totalTimeInSeconds % 60);

  return [hours, minutes, seconds]
    .map((value) => (value < 10 ? `0${value}` : value))
    .filter((value, idx) => value !== '00' || idx > 0)
    .join(':');
};

export const PresentHourOfDay = (cutoffTime) => {
  switch (cutoffTime) {
    case '23:59:59':
      return 'Midnight';
    case '00:00:00':
      return 'Midnight';
    case '12:00:00':
      return 'Noon';
    default:
      return Moment().hour(cutoffTime.split(':')[0]).format('h a');
  }
};

export const presentTimeZone = (timeZone) => timeZone.split('/')?.[1] || timeZone;
