import { useState, FC, ElementType } from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Formik, Form, FormikHelpers } from 'formik';
import { CircularProgress, Switch } from '@material-ui/core';
import { Paper, Typography, Button } from '../../../components/UI/FB';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES, BUTTON_VARIANTS } from '../../../utils/Constants';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import useNotifications from '../../../hooks/useNotifications/useNotifications';
import useDDErrorReporting from '../../../hooks/useDDErrorReporting/useDDErrorReporting';
import { ContactTypes } from '../../../utils/Presenters/Common';
import styles from '../Settings.module.scss';

type EnableB2CCardProps = {
  pulse: boolean;
  innerRef: () => void | ElementType;
  updateSupplierDetails: (data: ContactTypes) => void;
  b2cEnabled: boolean;
  supplierName: string;
};

type FormValuesType = {
  b2cEnabled: boolean;
};

const EnableB2CCard: FC<EnableB2CCardProps> = ({
  pulse,
  innerRef,
  updateSupplierDetails,
  b2cEnabled,
  supplierName,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const triggerEditMode = (): void => setEditMode(true);
  const { createNotification } = useNotifications();
  const { sendDatadogError } = useDDErrorReporting();

  const handleUpdateB2CEnabled = (values: FormValuesType, actions: FormikHelpers<FormValuesType>) => {
    const payload = {
      b2cEnabled: values.b2cEnabled,
    };

    FoodbombAPI.patch('suppliers/auth/me', payload)
      .then((response) => {
        updateSupplierDetails(response.data);
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Successfully ${response.data.b2cEnabled ? 'enabled' : 'disabled'} supplier for B2C.`,
          timeout: 4000,
          closable: true,
        });
        setEditMode(false);
      })
      .catch((error) => {
        if (error?.response?.status === 400 && error?.response?.data?.errors) {
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to update supplier for B2C.',
            timeout: 4000,
            closable: true,
          });
        } else {
          sendDatadogError('Unable to update supplier for B2C.', {
            error,
            location: 'Settings Page',
          });
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <div ref={innerRef}>
      <Paper className={pulse ? [styles.SettingsCard, styles.pulse].join(' ') : styles.SettingsCard}>
        <Formik
          initialValues={{ b2cEnabled: Boolean(b2cEnabled) }}
          initialStatus={{
            apiError: undefined,
          }}
          onSubmit={handleUpdateB2CEnabled}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <>
              <CheckCircleOutlineIcon
                className={
                  editMode
                    ? [styles.SettingsCard__completeIcon, styles.hide].join(' ')
                    : styles.SettingsCard__completeIcon
                }
              />
              <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.SettingsCard__sectionHeading}>
                Enable B2C
              </Typography>
              {editMode ? (
                <>
                  <div className={styles.b2cEnabledHeader__action}>
                    <div className={styles.ToggleButton}>
                      <span>Off</span>
                      <Switch
                        checked={values.b2cEnabled}
                        onChange={() => setFieldValue('b2cEnabled', !values.b2cEnabled)}
                        size="medium"
                        classes={{
                          root: styles.SwitchRoot,
                          colorPrimary: styles.ColorPrimary,
                          thumb: styles.ColorPrimary,
                          track: values.b2cEnabled ? styles.ActiveColor : styles.PausedColor,
                        }}
                      />
                      <span>On</span>
                    </div>
                    <div className={styles.ToggleInfo}>
                      <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD}>Enable supplier for B2C</Typography>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ToggleSubText}>
                        If you switch this setting on, {supplierName} will be enabled for B2C customer.
                      </Typography>
                    </div>
                  </div>
                  <Form>
                    <div className={styles.ToggleFooterActionContainer}>
                      <Button type="button" variant={BUTTON_VARIANTS.SECONDARY} onClick={() => setEditMode(false)}>
                        Cancel
                      </Button>
                      <Button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? (
                          <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                        ) : (
                          <>Update details</>
                        )}
                      </Button>
                    </div>
                  </Form>
                </>
              ) : (
                <>
                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__description}>
                    {values.b2cEnabled ? 'Enabled' : 'Disabled'}
                  </Typography>
                  <div className={styles.SettingsCard__editBtnContainer}>
                    <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={triggerEditMode} className={styles.EditBtn}>
                      Edit Details
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

EnableB2CCard.propTypes = {
  b2cEnabled: PropTypes.bool.isRequired,
  updateSupplierDetails: PropTypes.func.isRequired,
  innerRef: PropTypes.func.isRequired,
  pulse: PropTypes.bool.isRequired,
  supplierName: PropTypes.string.isRequired,
};

export default EnableB2CCard;
