import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as ErrorReportHelpers from '../../utils/ErrorReportHelpers/ErrorReportHelpers';

function withErrorReports(WrappedComponent) {
  class withErrorReportsWrapper extends Component {
    potentiallyGetStatusCodeForError = (error) => error?.error?.response?.status;

    potentiallyStripCredentialsFromData = (data) => {
      const dataToSend = { ...data };
      const keyToStrip = Object.keys(data)[0];
      if (dataToSend[keyToStrip]?.config) {
        // eslint-disable-next-line no-param-reassign
        dataToSend[keyToStrip].config.headers = undefined;
      }
      return dataToSend;
    };

    sendDatadogError = (errorMessage, data) => {
      const { supplierDetails } = this.props;
      const tags = [];
      const statusCode = this.potentiallyGetStatusCodeForError(data);
      if (statusCode) {
        tags.push(statusCode);
      }

      const dataToSend = this.potentiallyStripCredentialsFromData(data);

      ErrorReportHelpers.sendError(data.error, errorMessage, { data: dataToSend, supplierDetails }, tags);
    };

    trackPageView = (pathName) => {
      ErrorReportHelpers.trackPageView(pathName);
    };

    render() {
      return (
        <WrappedComponent sendDatadogError={this.sendDatadogError} trackPageView={this.trackPageView} {...this.props} />
      );
    }
  }

  const mapStateToProps = (state) => ({
    supplierDetails: state.auth.supplierDetails,
  });

  withErrorReportsWrapper.propTypes = {
    supplierDetails: PropTypes.object,
  };

  return connect(mapStateToProps, null)(withErrorReportsWrapper);
}

export default withErrorReports;
