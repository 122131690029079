import React from 'react';
import PropTypes from 'prop-types';

import OnStatusChip from '../../../components/UI/OnStatusChip/OnStatusChip';
import DeliveryDaysStatus from '../DeliveryZoneDetailComponents/DeliveryDaysStatus/DeliveryDaysStatus';
import { Tooltip, Typography } from '../../../components/UI/FB/index';
import { TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import { convertCentsToPrettyDollar } from '../../../utils/Presenters/PresentCurrency/PresentCurrency';
import { PresentHourOfDay } from '../../../utils/Presenters/TimePresenters';
import styles from './DeliveryZoneIndexCard.module.scss';

const DeliveryZoneIndexCard = ({ deliveryZone, handleSelectZone, selected }) => {
  const { name, published, minimumOrderTotal, suburbCount, cutoff, deliveryDays } = deliveryZone;

  const indexCardStyles = [styles.DeliveryZoneIndexCard];
  const suburbCountStyles = [styles.SuburbCount];
  const deliveryDetailsElementsStyles = [styles.DeliveryDetails__Elements];
  const deliveryZoneNameStyles = [styles.DeliveryZoneName];

  if (selected) {
    indexCardStyles.push(styles.Selected);
    deliveryDetailsElementsStyles.push(styles.Selected);
  }
  if (published) {
    indexCardStyles.push(styles.Published);
    suburbCountStyles.push(styles.Published);
    deliveryDetailsElementsStyles.push(styles.Published);
    deliveryZoneNameStyles.push(styles.Published);
  }

  return (
    <button className={indexCardStyles.join(' ')} onClick={handleSelectZone}>
      <div className={styles.HeaderRow}>
        <OnStatusChip onStatus={published} className={styles.OnStatusChip} />
        <Tooltip title={name}>
          <div className={styles.DeliveryZoneNameContainer}>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={deliveryZoneNameStyles.join(' ')}>
              {name}
            </Typography>
          </div>
        </Tooltip>
      </div>
      <div className={styles.DetailsRow}>
        <div className={styles.DeliveryDays}>
          <DeliveryDaysStatus
            activeDays={deliveryDays.filter((day) => day.enabled).map((day) => day.deliveryDay)}
            greyscale={!published}
          />
        </div>
        <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD} className={deliveryDetailsElementsStyles.join(' ')}>
          <span className={styles.DeliveryDetailsSpan}></span>|
          <span className={styles.DeliveryDetailsSpan}>{PresentHourOfDay(cutoff)}</span>|
          <span className={styles.DeliveryDetailsSpan}>{convertCentsToPrettyDollar(minimumOrderTotal)}</span>|
          <span className={styles.DeliveryDetailsSpan}>
            <span className={suburbCountStyles.join(' ')}>{suburbCount || 0}</span>&nbsp;suburbs
          </span>
        </Typography>
      </div>
    </button>
  );
};
DeliveryZoneIndexCard.propTypes = {
  deliveryZone: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    published: PropTypes.bool,
    deliveryDays: PropTypes.array.isRequired,
    minimumOrderTotal: PropTypes.number.isRequired,
    suburbCount: PropTypes.number.isRequired,
    cutoff: PropTypes.string.isRequired,
  }).isRequired,
  handleSelectZone: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default DeliveryZoneIndexCard;
