// eslint-disable-next-line import/prefer-default-export
export const presentCustomers = (venues: any) => {
  const allVenues = venues.map((venue: any) => ({
    venueId: venue.venueId,
    venueCRN: venue.venueCRN,
    venueName: venue.venueName,
    venueGroup: venue.venueGroup,
    ownerName: venue.ownerName,
    postcode: venue.postcode,
    suburb: venue.suburb,
    commission: venue.commission,
    customPrices: venue.customPrices,
    totalSpent: venue.totalSpent,
    orderCount: venue.orderCount,
    lastOrdered: venue.lastOrdered,
    customCommissionExists: venue.customCommissionExists,
  }));
  return allVenues;
};
