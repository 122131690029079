export const OBJECT_TYPES = {
  ORDERS: 'orders',
  PRODUCTS: 'products',
  DELIVERY_ZONES: 'delivery zones',

  // Children of orders
  PURCHASE_ORDER: 'purchase order',
  INVOICE: 'invoice',
  DELIVERY_SLIP: 'delivery slip',
};

export const ACTIONS = {
  DOWNLOAD: 'download',
  BULK_UPLOAD: 'bulk upload',
};

export const PAGE_POSITION = {
  TABLE: 'table',
  HEADER: 'header',
};

export const FILE_TYPES = {
  PDF: 'pdf',
  CSV: 'csv',
};
