import React from 'react';
import Moment from 'moment';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import * as actionTypes from './ActionTypes';
import { notificationAdd } from './NotificationActions';
import { handleReceiveImages } from './ProductImageActions';
import { NOTIFICATION_TYPES } from '../../utils/Constants';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import { getCookie, deleteCookie } from '../../utils/CookieUtils';
import {
  potentiallySetAxiosAuthorizationToken,
  getSupplierDetailsFromAuthToken,
  getTokenExpirationDate,
  tokenHasValidHeader,
  TOKEN,
  removeAxiosAuthorizationToken,
  clearAuthorizationTokenFromLocalStorage,
  saveAuthorizationTokenToLocalStorage,
} from '../../utils/TokenHelper';
import { localStorageGetItem } from '../../utils/StorageUtils';

const SUPPLIER_SERVICE_JWT = 'supplier_service_jwt';

const authStart = () => ({
  type: actionTypes.AUTH_START,
});

export const authReset = () => ({
  type: actionTypes.AUTH_RESET,
});

const authSuccess = (token, tokenExpirationDate, supplierDetails) => {
  const ddUser = {
    id: supplierDetails.id,
    isStaff: supplierDetails.isStaff,
    state: supplierDetails.state,
    name: supplierDetails.staffName || supplierDetails.supplierName,
  };
  datadogRum.setUser(ddUser);
  datadogLogs.setUser(ddUser);
  window.heap.resetIdentity();
  window.heap.identify(`S${supplierDetails.id}`);
  window.heap.addUserProperties({ isSupplier: true });
  window.heap.addEventProperties({ isStaff: Boolean(supplierDetails.isStaff) });
  window.heap.track(`Auth - ${supplierDetails.isStaff ? 'Masquerade from Admin' : 'Login Form'} - Login`);
  return {
    type: actionTypes.AUTH_SUCCESS,
    payload: {
      token,
      tokenExpirationDate,
      supplierDetails,
    },
  };
};

const authFail = (error) => ({
  type: actionTypes.AUTH_FAIL,
  payload: {
    error,
  },
});

const potentiallyClearMasqueradingCookie = () => {
  // Admin overried uses cookies
  deleteCookie(SUPPLIER_SERVICE_JWT);
};

const clearAllCookiesAndTokens = () => {
  window.heap.clearEventProperties();
  potentiallyClearMasqueradingCookie();
  clearAuthorizationTokenFromLocalStorage();
};

const authLogout = () => {
  clearAllCookiesAndTokens();
  removeAxiosAuthorizationToken();
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const logout = () => (dispatch) => dispatch(authLogout());

const checkAuthTimeout = (expirationDate) => (dispatch) => {
  const timeUntilLogout = expirationDate - new Date();
  const timeUntilLogoutWarning = timeUntilLogout - 15 * 1000;

  // If we're too far in the future, don't bother with this
  const shouldUseNotifications = Math.abs(Moment(expirationDate).diff(Moment(), 'days')) < 10;

  if (shouldUseNotifications) {
    setTimeout(() => {
      dispatch(
        notificationAdd({
          type: NOTIFICATION_TYPES.WARNING,
          content: (
            <React.Fragment>
              <div>To ensure your security, your session will expire in 15 seconds</div>
              <div>You will have to log in again.</div>
            </React.Fragment>
          ),
          timeout: 15000,
          closable: true,
        }),
      );
    }, timeUntilLogoutWarning);

    setTimeout(() => {
      dispatch(
        notificationAdd({
          type: NOTIFICATION_TYPES.ERROR,
          content: (
            <React.Fragment>
              <div>Your session has expired</div>
              <div>Please log in again</div>
            </React.Fragment>
          ),
          timeout: 6000,
          closable: true,
        }),
      );
      dispatch(logout());
    }, timeUntilLogout);
  }
};

export const authLogin = (username, password) => (dispatch) => {
  dispatch(authStart());
  FoodbombAPI.post('/auth/login', {
    username,
    password,
    role: 'supplier',
  })
    .then((response) => {
      const token = response.data.access_token;
      const supplierDetails = getSupplierDetailsFromAuthToken(token);

      const tokenExpirationDate = getTokenExpirationDate(token);
      saveAuthorizationTokenToLocalStorage(token);
      potentiallySetAxiosAuthorizationToken(token);
      dispatch(
        notificationAdd({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: (
            <React.Fragment>
              <div>Successfully logged in</div>
              <div>Your session will expire {Moment().to(tokenExpirationDate)}</div>
            </React.Fragment>
          ),
          timeout: 6000,
          closable: true,
        }),
      );
      dispatch(authSuccess(token, tokenExpirationDate, supplierDetails));
      dispatch(checkAuthTimeout(tokenExpirationDate));
      if (supplierDetails.isStaff) {
        dispatch(handleReceiveImages());
      }
    })
    .catch((e) => {
      if (e && e.response) {
        const error = { data: e.response.data, status: e.response.status, statusText: e.response.statusText };
        dispatch(authFail(error));
      } else {
        dispatch(authFail({ message: 'No response!' }));
      }
    });
};

export const authCheckState = () => (dispatch) => {
  // Cookies come from the admin override
  const token = getCookie(SUPPLIER_SERVICE_JWT) || localStorageGetItem(TOKEN);
  if (!token || !tokenHasValidHeader(token)) {
    dispatch(authLogout());
  } else {
    const tokenExpirationDate = getTokenExpirationDate(token);
    // if the token has expired in their local storage, then log them out
    if (tokenExpirationDate > new Date()) {
      const supplierDetails = getSupplierDetailsFromAuthToken(token);
      potentiallySetAxiosAuthorizationToken(token);
      dispatch(
        notificationAdd({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: (
            <React.Fragment>
              <div>Session restored</div>
              <div>Your session will expire {Moment().to(tokenExpirationDate)}</div>
            </React.Fragment>
          ),
          timeout: 6000,
          closable: true,
        }),
      );
      dispatch(authSuccess(token, tokenExpirationDate, supplierDetails));
      dispatch(checkAuthTimeout(tokenExpirationDate));
      if (supplierDetails.isStaff) {
        dispatch(handleReceiveImages());
        if (process.env.REACT_APP_DISABLE_ADMIN_AUTO_LOGOUT !== 'true') {
          clearAllCookiesAndTokens();
        }
      }
    } else {
      dispatch(authLogout());
    }
  }
};
