import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { BUTTON_VARIANTS, TYPOGRAPHY_TYPES } from '../../utils/Constants';
import { Button, Paper, Typography } from '../UI/FB';
import styles from './OrderThreshold.module.scss';
import OrderThresholdModal from './OrderThresholdModal/OrderThresholdModal';
import { CreateNotification, SendErrorReportType } from '../../utils/Presenters/ReduxType';

type OrderThresholdTypes = {
  onSuccess: () => void;
  minimumAmount?: number;
  sendDatadogError: SendErrorReportType;
  createNotification: CreateNotification;
};

const OrderThreshold: FC<OrderThresholdTypes> = ({
  onSuccess,
  minimumAmount,
  sendDatadogError,
  createNotification,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const triggerModal = (): void => {
    setOpenModal(true);
  };

  const closeModal = (): void => {
    setOpenModal(false);
  };

  return (
    <Paper className={styles.OrderThresholdContainer}>
      <div className={styles.OrderThresholdWrapper}>
        <div className={styles.OrderThresholdContent}>
          <div className={styles.OrderThresholdTitle}>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Order Threshold</Typography>
          </div>
          <div className={styles.OrderThresholdAmount}>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.OrderThreshold__amountText}>
              ${minimumAmount || '-'}
            </Typography>
          </div>
        </div>
        <div className={styles.OrderThresholdAction}>
          <Button
            onClick={triggerModal}
            className={styles.OrderThresholdActionButton}
            variant={BUTTON_VARIANTS.SECONDARY}
          >
            Edit threshold
          </Button>
        </div>
      </div>
      {openModal ? (
        <OrderThresholdModal
          open={openModal}
          onClose={closeModal}
          onSuccess={onSuccess}
          minimumAmount={minimumAmount}
          sendDatadogError={sendDatadogError}
          createNotification={createNotification}
        />
      ) : null}
    </Paper>
  );
};

OrderThreshold.defaultProps = {
  minimumAmount: 0,
};

OrderThreshold.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  minimumAmount: PropTypes.number,
  createNotification: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
};

export default OrderThreshold;
