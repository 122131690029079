import { FC } from 'react';
import PropTypes from 'prop-types';
import { ORDER_TYPES } from '../../utils/Constants';
import styles from './OrderTypeChip.module.scss';

type OrderTypeChipType = {
  apiOrderType: string;
};

type OT = keyof typeof ORDER_TYPES;

const OrderTypeChip: FC<OrderTypeChipType> = ({ apiOrderType }) => {
  const OrderTypeChipStyles = [styles.OrderTypeChip];

  OrderTypeChipStyles.push(styles[ORDER_TYPES[apiOrderType as OT]?.toLowerCase()]);

  return (
    <div className={styles.OrderTypeChipContainer}>
      <div className={OrderTypeChipStyles.join(' ')}>{ORDER_TYPES[apiOrderType as OT]?.toLowerCase()}</div>
    </div>
  );
};

OrderTypeChip.propTypes = {
  apiOrderType: PropTypes.string.isRequired,
};

export default OrderTypeChip;
