import { CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Form, Formik, FormikHelpers } from 'formik';
import PropTypes from 'prop-types';
import { ElementType, FC, ReactChild, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import DeleteConfirmationDialog from '../../../components/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { Button, ErrorNotification, FormikFormField, Paper, Tooltip, Typography } from '../../../components/UI/FB';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import { BUTTON_VARIANTS, NOTIFICATION_TYPES, TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import { ApiMethodTypes, ContactTypes, ValuesType, VT } from '../../../utils/Presenters/Common';
import { NotificationConfig, SendErrorReportType, ReduxState } from '../../../utils/Presenters/ReduxType';
import styles from '../Settings.module.scss';

type MultiContactCardType = {
  sendDatadogError: SendErrorReportType;
  contactInfos: ContactTypes[];
  updateContactDetails: (data: ContactTypes) => void;
  createNotification: (notification: NotificationConfig) => void;
  innerRef: () => void | ElementType;
  keys: VT[];
  pulse?: boolean;
  contactTypeId: number;
  cardTitle: string;
  cardDescription: string;
  tooltipMessage?: string;
};

const MultiContactCard: FC<MultiContactCardType> = ({
  sendDatadogError,
  contactInfos,
  updateContactDetails,
  createNotification,
  innerRef,
  keys,
  pulse,
  contactTypeId,
  cardTitle,
  cardDescription,
  tooltipMessage,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [addContact, setAddContact] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [idForValuesToUpdate, setIdForValuesToUpdate] = useState<number | null>(null);
  const triggerEditMode = (): void => setEditMode(true);
  const triggerAddContact = (): void => {
    setAddContact(true);
    setOpenDeleteModal(false);
  };
  const handleDeleteClose = (): void => setOpenDeleteModal(false);
  const [contactInitialValue, setContactInitialValue] = useState<ValuesType>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    contactDays: '',
    contactHours: '',
    contactTypeId,
  });
  const [contactInfoDetails, setContactInfoDetails] = useState<ContactTypes[]>(contactInfos);
  const [contactInitialValueToEdit, setContactInitialValueToEdit] = useState<ValuesType | null>(null);
  const isStaff = useSelector((state: ReduxState) => state.auth.supplierDetails?.isStaff);

  const triggerViewMode = (callback?: Function): void => {
    setEditMode(false);
    setAddContact(false);
    if (callback) {
      callback();
    }
  };

  const shouldUpdateValues = (values: ValuesType): boolean => {
    const idx = contactInfoDetails?.findIndex((info: ContactTypes) => info.id === idForValuesToUpdate);
    const singleContactInfo = contactInfoDetails[idx];
    return keys.some((key: VT) => values[key] !== singleContactInfo?.[key]);
  };

  const handelInitialValueToEdit = (id: number): void => {
    const idxToFind = contactInfoDetails.findIndex((info: ContactTypes) => info.id === id);
    if (idxToFind !== -1) {
      setContactInitialValueToEdit(contactInfoDetails[idxToFind]);
    }
    setIdForValuesToUpdate(id);
  };

  useEffect(() => {
    setContactInitialValue({
      firstName: addContact ? '' : contactInitialValueToEdit?.firstName,
      lastName: addContact ? '' : contactInitialValueToEdit?.lastName,
      email: addContact ? '' : contactInitialValueToEdit?.email,
      phone: addContact ? '' : contactInitialValueToEdit?.phone,
      contactDays: addContact ? '' : contactInitialValueToEdit?.contactDays,
      contactHours: addContact ? '' : contactInitialValueToEdit?.contactHours,
      contactTypeId,
    });
  }, [addContact, contactInitialValueToEdit, contactTypeId]);

  const buildContactDetails = (): ReactChild => (
    <>
      <div className={styles.SettingsCard__summary_details_container}>
        {contactInfoDetails?.map((info: ContactTypes) => {
          const { firstName, lastName, phone, email, id, contactDays, contactHours } = info;
          const isInvalid = !email;
          return (
            <div className={styles.SettingsCard__summary_details} key={id}>
              <div>
                <div>
                  <Typography
                    type={TYPOGRAPHY_TYPES.BODY_BOLD}
                    className={[styles.SettingsCard__summary, styles.title].join(' ')}
                  >
                    {firstName}&nbsp;{lastName}
                  </Typography>
                </div>
                <div>
                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__summary}>
                    {phone}&nbsp;
                  </Typography>
                </div>
                <div>
                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__summary}>
                    {email}&nbsp;
                  </Typography>
                </div>
                {keys.includes('contactHours') ? (
                  <>
                    <div>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__summary}>
                        {contactDays}&nbsp;
                      </Typography>
                    </div>
                    <div>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__summary}>
                        {contactHours}&nbsp;
                      </Typography>
                    </div>
                  </>
                ) : null}
                {isInvalid ? <span className={styles.RedText}>Email is required!</span> : null}
              </div>
              <div className={styles.SettingsCard__editBtnContainer}>
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  onClick={() => {
                    triggerEditMode();
                    handelInitialValueToEdit(id);
                  }}
                  className={styles.EditBtn}
                >
                  Edit Details
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );

  const mapAPIErrorsToFormFields = (errors: any) => {
    const formErrors: any | {} = {};
    errors.forEach((e: any) => {
      formErrors[e.param] = e.message;
    });
    return formErrors;
  };

  const handleSubmit = (values: ValuesType, actions: FormikHelpers<ValuesType>) => {
    actions.setStatus({ apiError: undefined });
    const contactDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      contactDays: values.contactDays,
      contactHours: values.contactHours,
      contactTypeId,
    };

    const apiMethod: ApiMethodTypes = addContact ? ApiMethodTypes.post : ApiMethodTypes.patch;
    const endpoint: string = addContact ? `suppliers/contacts` : `suppliers/contacts/${idForValuesToUpdate}`;

    if (!shouldUpdateValues(values)) {
      triggerViewMode();
      createNotification({
        type: NOTIFICATION_TYPES.WARNING,
        content: 'Nothing to update',
        timeout: 4000,
        closable: true,
      });
      actions.setSubmitting(false);
    } else {
      FoodbombAPI[apiMethod](endpoint, contactDetails)
        .then((response: any) => {
          updateContactDetails(response.data);
          setContactInfoDetails([
            response.data,
            ...contactInfoDetails.filter((cid: ContactTypes) => cid.id !== response.data.id),
          ]);
          triggerViewMode();
          createNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            content: `Successfully ${addContact ? 'created' : 'updated'}  account details`,
            timeout: 4000,
            closable: true,
          });
          actions.setSubmitting(false);
          actions.resetForm();
        })
        .catch((error: any) => {
          if (error?.response?.status === 400 && error?.response?.data?.errors) {
            createNotification({
              type: NOTIFICATION_TYPES.ERROR,
              content: 'Unable to update account details',
              timeout: 4000,
              closable: true,
            });

            actions.setErrors(mapAPIErrorsToFormFields(error.response.data.errors));
          } else {
            sendDatadogError('Unable to update account details', {
              error,
              location: 'Settings Page',
            });
            createNotification({
              type: NOTIFICATION_TYPES.ERROR,
              content: 'Unable to update account details',
              timeout: 4000,
              closable: true,
            });
            actions.setStatus({
              apiError: (
                <ErrorNotification
                  body={"We were unable to update your account details. Don't worry, our engineers have been notified!"}
                  className={styles.ErrorNotification}
                />
              ),
            });
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  };

  const handleDelete = (handleReset: Function) => {
    FoodbombAPI.delete(`suppliers/contacts/${idForValuesToUpdate}`)
      .then(() => {
        setContactInfoDetails(contactInfoDetails.filter((cid) => cid.id !== idForValuesToUpdate));
        triggerViewMode(handleReset);
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully deleted contact',
          timeout: 4000,
          closable: true,
        });
      })
      .catch((error) => {
        sendDatadogError('Unable to delete contact', {
          error,
          location: 'Settings Page',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to delete contact',
          timeout: 4000,
          closable: true,
        });
      });
  };

  const contactDetailsValidationSchema = () =>
    Yup.object({
      firstName: Yup.string().trim().nullable(),
      lastName: Yup.string().trim().nullable(),
      email: Yup.string().email('Must be a valid email address').trim().required('Email is required'),
      phone: Yup.string()
        .trim()
        .matches(
          /^(([+]61)|([(]?0[2-57-8][)]?))?([-. ]?[1-9]{1})?([-. ]?[0-9]{4}){2}|([-. ]?[0-9]{3}){3}$/,
          'Must be a valid phone number',
        )
        .nullable(),
      contactDays: Yup.string().trim().nullable(),
      contactHours: Yup.string().trim().nullable(),
    });

  const showTick = (values: ValuesType, errors: any) =>
    editMode ? !keys.some((key) => errors[key] || !values[key]) : keys.every((key) => !errors[key]);

  const showErrorIcon = (touched: any, errors: any) =>
    editMode ? keys.some((key) => touched[key] && errors[key]) : keys.some((key) => touched[key]);

  return (
    <div ref={innerRef}>
      <Paper className={pulse ? [styles.SettingsCard, styles.pulse].join(' ') : styles.SettingsCard}>
        <Formik
          initialValues={contactInitialValue}
          enableReinitialize
          setValues={contactInitialValue}
          initialStatus={{
            apiError: undefined,
          }}
          validationSchema={contactDetailsValidationSchema()}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched, values, status, handleReset }) => (
            <>
              {!addContact && !editMode ? (
                <CheckCircleOutlineIcon
                  className={
                    showTick(values, errors)
                      ? styles.SettingsCard__completeIcon
                      : [styles.SettingsCard__completeIcon, styles.hide].join(' ')
                  }
                />
              ) : null}

              <ErrorOutlineIcon
                className={
                  showErrorIcon(touched, errors)
                    ? styles.SettingsCard__invalidIcon
                    : [styles.SettingsCard__invalidIcon, styles.hide].join(' ')
                }
              />

              <ExpandLessIcon
                className={
                  editMode || addContact
                    ? styles.SettingsCard__expandableIcon
                    : [styles.SettingsCard__expandableIcon, styles.hide].join(' ')
                }
              />

              <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.SettingsCard__sectionHeading}>
                {cardTitle}&nbsp;
                <Tooltip title={tooltipMessage} placement="top">
                  <span className={styles.RequiredIcon}>*</span>
                </Tooltip>
              </Typography>
              {editMode || addContact ? (
                <div>
                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__description}>
                    {cardDescription}
                  </Typography>
                  <div>
                    <Form>
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="firstName"
                          touched={touched}
                          errors={errors}
                          placeholder="First name"
                          label="First Name"
                        />
                      </div>
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="lastName"
                          touched={touched}
                          errors={errors}
                          placeholder="Last Name"
                          label="Last Name"
                        />
                      </div>
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="email"
                          touched={touched}
                          errors={errors}
                          placeholder="Email"
                          label="Email"
                          fieldProps={{
                            type: 'email',
                          }}
                        />
                      </div>
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="phone"
                          touched={touched}
                          errors={errors}
                          placeholder="Phone"
                          label="Phone Number"
                        />
                      </div>
                      {keys.includes('contactHours') ? (
                        <>
                          <div className={styles.FormRow}>
                            <FormikFormField
                              fieldName="contactHours"
                              touched={touched}
                              errors={errors}
                              placeholder="Contact Hours"
                            />
                          </div>
                          <div className={styles.FormRow}>
                            <FormikFormField
                              fieldName="contactDays"
                              touched={touched}
                              errors={errors}
                              placeholder="Contact Days"
                            />
                          </div>
                        </>
                      ) : null}

                      {status.apiError ? <div className={styles.ErrorMessageContainer}>{status.apiError}</div> : null}
                      <div className={styles.SettingsCard__editBtnContainer}>
                        <Button
                          type="button"
                          disabled={isSubmitting}
                          variant={BUTTON_VARIANTS.SECONDARY}
                          onClick={() => triggerViewMode(handleReset)}
                        >
                          Cancel
                        </Button>
                        {editMode && isStaff ? (
                          <Button
                            type="button"
                            variant={BUTTON_VARIANTS.DANGER}
                            onClick={() => setOpenDeleteModal(true)}
                          >
                            Delete
                          </Button>
                        ) : null}
                        <Button type="submit" disabled={isSubmitting} variant={BUTTON_VARIANTS.SECONDARY}>
                          {isSubmitting ? (
                            <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                          ) : (
                            `${addContact ? 'Create' : 'Update Details'}`
                          )}
                        </Button>
                      </div>
                    </Form>
                  </div>
                  {openDeleteModal ? (
                    <DeleteConfirmationDialog
                      isOpen={openDeleteModal}
                      handleClose={handleDeleteClose}
                      handleDelete={() => handleDelete(handleReset)}
                      confirmationMessage="Are you sure you want to delete this contact?"
                    />
                  ) : null}
                </div>
              ) : (
                <>
                  {buildContactDetails()}
                  <div className={styles.SettingsCard__addBtnContainer}>
                    <Button
                      type="button"
                      disabled={isSubmitting}
                      variant={BUTTON_VARIANTS.PRIMARY}
                      onClick={triggerAddContact}
                    >
                      <AddIcon />
                      Add Recipient
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

MultiContactCard.propTypes = {
  contactInfos: PropTypes.array.isRequired,
  updateContactDetails: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  pulse: PropTypes.bool.isRequired,
  keys: PropTypes.array.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  cardTitle: PropTypes.string.isRequired,
  cardDescription: PropTypes.string.isRequired,
  tooltipMessage: PropTypes.string.isRequired,
};

export default withErrorReports(withNotifications(MultiContactCard));
