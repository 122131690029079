import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Typography,
  Emoji,
  FormikFormField,
  FormikSingleSelectField,
  Button,
  ErrorNotification,
} from '../../../components/UI/FB';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import { DeliveryPreferencesAPI } from '../../../utils/AxiosInstances';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES, TIMEZONE_OPTIONS, STATE_TO_TIMEZONE } from '../../../utils/Constants';
import { CUTOFF_TIMES } from '../../../utils/TimeConstants';
import templateZones from '../../../utils/deliveryTemplates.json';
import styles from './CreateZoneModal.module.scss';

const deliveryTemplateOptions = Object.entries(templateZones)
  .map(([key, value]) => ({
    id: key,
    label: `${value[0].state} - ${key}`,
    value: key,
  }))
  .sort((optA, optB) => optA.label.localeCompare(optB.label));

const CreateZoneModal = ({
  isOpen,
  handleClose,
  createNotification,
  existingDeliveryZone,
  sendDatadogError,
  handleZoneCreated,
  supplierDefaultState,
}) => {
  const mapAPIErrorsToFormFields = (errors) => {
    // Overide API keys that dont match the UI
    const formErrors = { ...errors };
    formErrors.cutoffTime = errors?.cutoff;
    return formErrors;
  };

  const rollbackAndDeleteZone = async (zoneId) =>
    DeliveryPreferencesAPI.delete(`zones/${zoneId}`).catch((error) => {
      sendDatadogError(`Unable to rollback and delete delivery zone ${zoneId}`, {
        error,
        location: 'Duplicate Delivery Zone Modal',
      });
      throw error;
    });

  const copyDeliveryZoneSuburbData = async (zoneId, suburbData) =>
    DeliveryPreferencesAPI.put(`zones/${zoneId}/suburbs`, suburbData).catch((error) => {
      sendDatadogError(`Unable to copy suburbs for zone ${zoneId}`, {
        error,
        location: 'Duplicate Delivery Zone Modal',
      });
      throw error;
    });

  const createZone = async (values, actions) => {
    const newDeliveryZoneData = {
      name: values?.name || undefined,
      minimumOrderTotal: values.minimumOrderTotal ? values.minimumOrderTotal * 100 : undefined,
      cutoff: values?.cutoffTime || undefined,
      published: false,
      timeZone: values?.timeZone,
    };

    let createdZone;
    let suburbsToCreate = templateZones[values.template] || existingDeliveryZone?.suburbs || [];
    if (values.template) {
      suburbsToCreate = templateZones[values.template].map((templateSub) => ({
        blocked: false,
        suburb: {
          name: templateSub.suburb,
          postcode: parseInt(templateSub.postcode, 10),
          state: templateSub.state,
        },
        enabled: true,
      }));
    }

    try {
      const response = await DeliveryPreferencesAPI.post('/zones', newDeliveryZoneData);
      createdZone = response.data;
      if (suburbsToCreate.length) {
        await copyDeliveryZoneSuburbData(createdZone.id, suburbsToCreate);
      }
      createNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        content: `Created Zone: ${createdZone.name}`,
        timeout: 4000,
        closable: true,
      });
      handleZoneCreated(createdZone);
    } catch (error) {
      if (createdZone) {
        await rollbackAndDeleteZone(createdZone.id);
      }
      if (error?.response?.status === 400 && error?.response?.data) {
        actions.setErrors(mapAPIErrorsToFormFields(error.response.data));
      } else {
        sendDatadogError('Unable to create delivery zone', {
          error,
          location: 'Delivery Zones Page',
        });
        actions.setStatus({
          apiError: (
            <ErrorNotification body={'We were unable to create this zone. Our engineers have been notified!'} />
          ),
        });
      }
    }
    actions.setSubmitting(false);
  };

  const validateCreateZoneSchema = Yup.object({
    name: Yup.string().trim().required('Name is required'),
    minimumOrderTotal: Yup.number().required('Minimum order is Required').min(0, 'Minimum order cannot be negative'),
    cutoffTime: Yup.string()
      .oneOf(CUTOFF_TIMES.map((cutoff) => cutoff.id))
      .required('A cutoff time is required'),
    template: Yup.string(),
    timeZone: Yup.string()
      .oneOf(TIMEZONE_OPTIONS.map((tz) => tz.id))
      .required('A time zone is required'),
  });

  const computeDefaultTimezoneId = () => {
    const supplierStatesToTimezones = {
      ACT: 'Australia/Sydney',
      NSW: 'Australia/Sydney',
      ...STATE_TO_TIMEZONE,
    };

    const zone = supplierStatesToTimezones[supplierDefaultState] || 'Australia/Sydney';

    return TIMEZONE_OPTIONS.find((tz) => tz.id === zone).id;
  };

  return (
    <Dialog
      open={isOpen}
      keepMounted={false}
      onClose={handleClose}
      aria-labelledby="create-zone-dialog"
      classes={{ root: styles.CreateZoneModal__root, paper: styles.CreateZoneModal__paper }}
    >
      <IconButton aria-label="close" onClick={handleClose} className={styles.CloseBtn}>
        <CloseIcon />
      </IconButton>
      <DialogTitle className={styles.CreateZoneModal__title}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
          <Emoji content="🚚" label="delivery-truck" />
          {existingDeliveryZone ? (
            <React.Fragment>&nbsp;Duplicate Delivery Zone</React.Fragment>
          ) : (
            <React.Fragment>&nbsp;Create a Delivery Zone</React.Fragment>
          )}
        </Typography>
      </DialogTitle>
      <DialogContent className={styles.CreateZoneModal__content}>
        <Formik
          initialValues={{
            name: existingDeliveryZone ? `${existingDeliveryZone.name} (copy)` : '',
            cutoffTime: existingDeliveryZone
              ? CUTOFF_TIMES.find((cutoffTime) => existingDeliveryZone.cutoff === cutoffTime.id).id
              : CUTOFF_TIMES.find((cutoffTime) => cutoffTime.label === '11pm').id,
            minimumOrderTotal: existingDeliveryZone ? existingDeliveryZone.minimumOrderTotal / 100 : '',
            timeZone: existingDeliveryZone
              ? TIMEZONE_OPTIONS.find((tz) => existingDeliveryZone.timeZone === tz.id).id
              : computeDefaultTimezoneId(),
            template: '',
          }}
          onSubmit={createZone}
          validationSchema={validateCreateZoneSchema}
        >
          {({ errors, touched, isSubmitting, values, setTouched, setFieldValue, status }) => (
            <Form>
              <FormikFormField
                fieldName={'name'}
                touched={touched}
                errors={errors}
                label="Delivery Zone Name"
                placeholder="Enter a name for your delivery zone"
                fieldProps={{
                  autoFocus: true,
                }}
              />

              {existingDeliveryZone ? null : (
                <div className={styles.Input__fullWidth}>
                  <FormikSingleSelectField
                    label="Populate Suburbs from a Template"
                    fieldName="template"
                    isDisabled={existingDeliveryZone}
                    errors={errors}
                    touched={touched}
                    value={deliveryTemplateOptions.find((template) => template.id === values.template) || ''}
                    options={deliveryTemplateOptions}
                    placeholder="None"
                    setTouched={setTouched}
                    setFieldValue={setFieldValue}
                    maxMenuHeight={200}
                  />
                </div>
              )}
              <div className={styles.InputRow}>
                <div className={styles.Input__halfWidth}>
                  <FormikSingleSelectField
                    label="Cutoff Time"
                    fieldName="cutoffTime"
                    errors={errors}
                    touched={touched}
                    value={CUTOFF_TIMES.find((cutoffTime) => cutoffTime.id === values.cutoffTime) || ''}
                    options={CUTOFF_TIMES}
                    placeholder="Select a cutoff time"
                    setTouched={setTouched}
                    setFieldValue={setFieldValue}
                    maxMenuHeight={150}
                  />
                </div>
                <div className={styles.Input__halfWidth}>
                  <FormikSingleSelectField
                    label="Time Zone"
                    fieldName="timeZone"
                    errors={errors}
                    touched={touched}
                    value={TIMEZONE_OPTIONS.find((tz) => tz.id === values.timeZone) || ''}
                    options={TIMEZONE_OPTIONS}
                    placeholder="Select a time zone"
                    setTouched={setTouched}
                    setFieldValue={setFieldValue}
                    maxMenuHeight={150}
                  />
                </div>
              </div>
              <div className={styles.Input__fullWidth}>
                <FormikFormField
                  fieldName={'minimumOrderTotal'}
                  touched={touched}
                  errors={errors}
                  label="Minimum Order ($)"
                  placeholder="Enter your minimum order"
                  fieldProps={{
                    type: 'number',
                    step: '1.0',
                  }}
                />
              </div>
              <div className={styles.ErrorContainer}>{status?.apiError}</div>
              <div className={styles.SubmitBtn__container}>
                <Button
                  type="submit"
                  disabled={isSubmitting || Boolean(errors && Object.keys(errors).length)}
                  className={styles.SubmitBtn}
                >
                  {isSubmitting ? (
                    <CircularProgress thickness={3} size={24} className={styles.ButtonLoadingSpinner} />
                  ) : (
                    <React.Fragment>Create Zone</React.Fragment>
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  supplierDefaultState: state.auth?.supplierDetails?.state,
});

CreateZoneModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  existingDeliveryZone: PropTypes.shape({
    name: PropTypes.string.isRequired,
    published: PropTypes.bool,
    deliveryDays: PropTypes.array.isRequired,
    minimumOrderTotal: PropTypes.number.isRequired,
    suburbCount: PropTypes.number.isRequired,
    cutoff: PropTypes.string.isRequired,
    suburbs: PropTypes.array,
    timeZone: PropTypes.oneOf(Object.values(STATE_TO_TIMEZONE)).isRequired,
  }),
  sendDatadogError: PropTypes.func.isRequired,
  handleZoneCreated: PropTypes.func.isRequired,
  supplierDefaultState: PropTypes.string,
};

export default withErrorReports(withNotifications(connect(mapStateToProps, null)(CreateZoneModal)));
