export const devAlert = (...rest) => {
  // eslint-disable-next-line no-alert
  alert(rest);
};

export const devLog = (...rest) => {
  // eslint-disable-next-line no-console
  console.log(rest);
};

export const devWarn = (...rest) => {
  // eslint-disable-next-line no-console
  console.warn(rest);
};

export const devError = (...rest) => {
  // eslint-disable-next-line no-console
  console.error(rest);
};

export const devTable = (...rest) => {
  // eslint-disable-next-line no-console
  console.table(rest);
};
