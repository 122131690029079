/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState, FC, ElementType } from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Formik, Form, FormikHelpers } from 'formik';
import { startOfToday, format } from 'date-fns';
import { CircularProgress } from '@material-ui/core';
import { Paper, Typography, Button } from '../../../components/UI/FB';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES, BUTTON_VARIANTS } from '../../../utils/Constants';
import { FoodbombAPI } from '../../../utils/AxiosInstances';

import { ContactTypes } from '../../../utils/Presenters/Common';
import styles from '../Settings.module.scss';
import DatePickerCalender from '../../../components/DatePicker/DatePickerCalender';
import useNotifications from '../../../hooks/useNotifications/useNotifications';
import useDDErrorReporting from '../../../hooks/useDDErrorReporting/useDDErrorReporting';

type ActivationDateCardTypes = {
  pulse: boolean;
  innerRef: () => void | ElementType;
  updateSupplierDetails: (data: ContactTypes) => void;
  supplierActivationDate: string;
};

interface Values {
  activationDate: string;
}

const ActivationDateCard: FC<ActivationDateCardTypes> = ({
  pulse,
  innerRef,
  updateSupplierDetails,
  supplierActivationDate,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const castingActivationDateToDate =
    supplierActivationDate === null ? startOfToday() : new Date(supplierActivationDate);
  const [selectedDate, setSelectedDate] = useState<Date>(castingActivationDateToDate || startOfToday());
  const { createNotification } = useNotifications();
  const { sendDatadogError } = useDDErrorReporting();
  const triggerEditMode = (): void => setEditMode(true);

  const onDateChange = (date: Date): void => {
    setSelectedDate(date);
  };

  const formattedSelectedDate = format(selectedDate, 'yyyy-MM-dd');

  const handleUpdateActivationDate = (values: Values, actions: FormikHelpers<Values>) => {
    const payload = {
      activationDate: formattedSelectedDate,
    };

    FoodbombAPI.patch('suppliers/auth/me', payload)
      .then((response) => {
        updateSupplierDetails(response.data);
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Successfully set activation date to ${formattedSelectedDate}. `,
          timeout: 4000,
          closable: true,
        });
        actions.setSubmitting(false);
        setEditMode(false);
      })
      .catch((error) => {
        if (error?.response?.status === 400 && error?.response?.data?.errors) {
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to update activation date',
            timeout: 4000,
            closable: true,
          });
        } else {
          sendDatadogError('Unable to update activation date', {
            error,
            location: 'Settings Page',
          });
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
        setEditMode(false);
      });
  };

  return (
    <div ref={innerRef}>
      <Paper className={pulse ? [styles.SettingsCard, styles.pulse].join(' ') : styles.SettingsCard}>
        <Formik initialValues={{ activationDate: formattedSelectedDate }} onSubmit={handleUpdateActivationDate}>
          {({ isSubmitting }) => (
            <>
              <CheckCircleOutlineIcon
                className={
                  !editMode
                    ? styles.SettingsCard__completeIcon
                    : [styles.SettingsCard__completeIcon, styles.hide].join(' ')
                }
              />
              <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.SettingsCard__sectionHeading}>
                Supplier activation date
              </Typography>
              {!editMode ? (
                <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__description}>
                  Activation Date: {supplierActivationDate}
                </Typography>
              ) : null}
              {editMode ? (
                <>
                  <Form>
                    <div className={styles.DatePickerWrapper}>
                      <DatePickerCalender date={selectedDate} onDateChange={onDateChange} />
                    </div>
                    <div className={styles.ToggleFooterActionContainer}>
                      <Button type="button" variant={BUTTON_VARIANTS.SECONDARY} onClick={() => setEditMode(false)}>
                        Cancel
                      </Button>
                      <Button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? (
                          <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                        ) : (
                          <>Update details</>
                        )}
                      </Button>
                    </div>
                  </Form>
                </>
              ) : (
                <div className={styles.SettingsCard__editBtnContainer}>
                  <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={triggerEditMode} className={styles.EditBtn}>
                    Edit Details
                  </Button>
                </div>
              )}
            </>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

ActivationDateCard.propTypes = {
  updateSupplierDetails: PropTypes.func.isRequired,
  innerRef: PropTypes.func.isRequired,
  pulse: PropTypes.bool.isRequired,
};

export default ActivationDateCard;
