import { InputLabel } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import CustomPriceDialog from '../../components/CustomPriceDialog/CustomPriceDialog';
import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import {
  Button,
  Checkbox,
  Emoji,
  ErrorNotification,
  FormikFormField,
  FormikSingleSelectField,
  NotificationMessageSection,
  Paper,
  Tooltip,
  Typography,
} from '../../components/UI/FB';
import LargeLoadingSpinner from '../../components/UI/LargeLoadingSpinner/LargeLoadingSpinner';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import SpecialsIcon from '../../components/UI/SpecialsIcon/SpecialsIcon';
import { DataMismatchError } from '../../exceptions';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import withRedirectHelper from '../../hoc/withRedirectHelper/withRedirectHelper';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import { buildCategoryDropdownTree } from '../../utils/CategoryHierachyBuilder';
import {
  ACTIVE_VENUE_STATUS,
  API_METHODS,
  BUTTON_VARIANTS,
  NOTIFICATION_MESSAGE_SIZES,
  NOTIFICATION_MESSAGE_TYPES,
  NOTIFICATION_TYPES,
  PERSONAL_VENUE_TYPE,
  TYPOGRAPHY_TYPES,
} from '../../utils/Constants';
import {
  calculatePriceIncGST,
  portionSizePriceCalculator,
  round2DP,
} from '../../utils/PortionSizePricingHelper/PortionSizePricingHelper';
import { presentCurrency } from '../../utils/Presenters/PresentCurrency/PresentCurrency';
import styles from './Product.module.scss';
import ProductImageSelectorModal from './ProductImageSelectorModal';

class Product extends Component {
  state = {
    loadingCategories: true,
    loadingPortionUnits: true,
    loadingProductDetails: false,
    categories: [],
    categoryDropdowns: [],
    portionUnits: [],
    initialProductDetails: undefined,
    showImageSelectorModal: false,
    selectedImage: null,
    errorLoadingInitialData: undefined,
    venues: [],
    loadingVenues: false,
    customPriceDialogOpen: false,
    productImageSearchQuery: '',
  };

  componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = () => {
    this.fetchAllCategories();
    this.fetchAllPortionSizes();
    if (this.props.method === API_METHODS.PATCH) {
      this.fetchVenues();
    }
  };

  updateInitialProductDetails = (updatedProductDetails) => {
    this.setState({ initialProductDetails: updatedProductDetails });
  };

  handleOpenCustomPriceDialog = () => {
    this.setState({ customPriceDialogOpen: true });
  };

  handleCloseCustomPriceDialog = () => {
    this.setState({ customPriceDialogOpen: false });
  };

  filterAvailablePortionUnitsBySelectedPriceUnitId = (portionUnits, selectedPriceUnitId) => {
    if (selectedPriceUnitId === 1 || selectedPriceUnitId === 2) {
      return portionUnits.filter((unit) => unit.id === 1 || unit.id === 2);
    }
    return portionUnits.filter((unit) => unit.id === selectedPriceUnitId);
  };

  handleErrorLoadingInitialData = (e, message) => {
    this.props.sendDatadogError(message, {
      error: e,
      location: 'Product add / update page',
    });
    this.setState({ errorLoadingInitialData: <ErrorNotification /> });
  };

  buildSearchLabelForVenue = (venue) => `${venue.venue} (${venue.id})`;

  fetchVenues = () => {
    this.setState({ loadingVenues: true });
    FoodbombAPI.get('/venues', { credentials: 'include' })
      .then((response) => {
        this.setState({
          venues: response.data.venues
            .map((venue) => ({
              ...venue,
              label: this.buildSearchLabelForVenue(venue),
              value: venue.id,
            }))
            .filter((c) => c.status === ACTIVE_VENUE_STATUS && c.venueType !== PERSONAL_VENUE_TYPE),
        });
      })
      .catch((error) => {
        // If this call fails we just dont allow inline editing of CPs
        this.props.sendDatadogError('Unable to fetch venues for supplier', {
          error,
          location: 'Products index page',
        });
      })
      .finally(() => {
        this.setState({ loadingVenues: false });
      });
  };

  potentiallyFetchProduct = () => {
    if (this.props.method !== API_METHODS.PATCH) {
      return;
    }
    this.setState({ loadingProductDetails: true });
    const productId = this.props.match.params.id;
    FoodbombAPI.get(`suppliers/products/${productId}`, { credentials: 'include' })
      .then((response) => {
        const product = response.data;
        const initialProductDetails = {
          ...product,
          sku: product.sku ? product.sku : undefined,
          price: round2DP(product.priceInCents / 100),
          unitOfPriceId: product.unitOfPrice ? product.unitOfPrice.id : undefined,
          hasGST: !product.gstExempt,
          categories: product.categories ? product.categories : [],
          unitOfPortionId: product.unitOfPortion ? product.unitOfPortion.id : null,
        };
        const categoryDropdowns = [];
        initialProductDetails.categories.forEach((selectedCategoryId, idx) => {
          const selectedCategory = this.state.categories.find((cat) => cat.id === selectedCategoryId);
          const categoryDropDownToAdd = this.state.categories.filter(
            (cat) => cat.parentCategoryId === selectedCategory.parentCategoryId,
          );
          categoryDropDownToAdd.sort((catA, catB) => catA.name.localeCompare(catB.name));
          categoryDropdowns[idx] = categoryDropDownToAdd;
        });
        this.setState({
          initialProductDetails,
          categoryDropdowns,
          selectedImage: initialProductDetails.image,
        });
      })
      .catch((e) => {
        this.handleErrorLoadingInitialData(e, 'Could not fetch products');
      })
      .finally(() => {
        this.setState({ loadingProductDetails: false });
      });
  };

  fetchAllPortionSizes = () => {
    this.setState({ loadingPortionUnits: true });
    FoodbombAPI.get('portions')
      .then((response) => {
        this.setState({
          portionUnits: response.data.data.map((portionUnit) => ({
            ...portionUnit,
            label: `${portionUnit.title} (${portionUnit.unit})`,
            value: portionUnit.id,
          })),
          loadingPortionUnits: false,
        });
      })
      .catch((e) => {
        this.handleErrorLoadingInitialData(e, 'Could not fetch portion units');
      })
      .finally(() => {
        this.setState({ loadingPortionUnits: false });
      });
  };

  fetchAllCategories = () => {
    this.setState({ loadingCategories: true });
    FoodbombAPI.get('/categories')
      .then((response) => {
        const categories = response.data.data.map((cat) => ({
          ...cat,
          label: cat.name,
          value: cat.id,
        }));
        const firstLevelDropDown = categories.filter((cat) => cat.parentCategoryId === null);
        firstLevelDropDown.sort((catA, catB) => catA.name.localeCompare(catB.name));
        const categoryDropdowns = [];
        categoryDropdowns.push(firstLevelDropDown);
        this.setState({ categories, categoryDropdowns, loadingCategories: false });
        this.potentiallyFetchProduct();
      })
      .catch((e) => {
        this.handleErrorLoadingInitialData(e, 'Could not fetch categories');
      })
      .finally(() => {
        this.setState({ loadingCategories: false });
      });
  };

  potentiallyAddNewCategory = (selectedCategoryId, values, idx, push, pop) => {
    const { categories, categoryDropdowns } = this.state;
    const newCategoryDropdowns = buildCategoryDropdownTree(
      categories,
      categoryDropdowns,
      selectedCategoryId,
      values,
      idx,
      push,
      pop,
    );
    this.setState({ categoryDropdowns: newCategoryDropdowns });
  };

  sanitizeFormData = (values) => ({
    name: values.name,
    priceInCents: (values.price * 100).toFixed(0),
    sku: values.sku,
    unitOfPriceId: values.unitOfPriceId,
    gstExempt: !values.hasGST,
    categories: values.categories.filter((cat) => cat),
    unitOfPortionId: values.unitOfPortionId,
    portions: values.portions.filter((portion) => portion),
    enabled: values.enabled,
    imageId: this.state.selectedImage ? this.state.selectedImage.id : null,
    outOfStock: values.outOfStock,
    isBestBuy: values.isBestBuy,
  });

  mapAPIParamToFormParam = (param) => {
    switch (param.split('.')[0]) {
      case 'gstExempt':
        return 'hasGST';
      case 'priceInCents':
        return 'price';
      case 'enabled':
        return 'status';
      case 'portions':
        return 'portions';
      default:
        return param;
    }
  };

  mapAPIErrorsToFormFields = (errors) => {
    const formErrors = {};
    errors.forEach((e) => {
      formErrors[this.mapAPIParamToFormParam(e.param)] = e.message;
    });
    return formErrors;
  };

  handleCreateProduct = (values, actions) => {
    const sanitizedValues = this.sanitizeFormData(values);
    const { createNotification } = this.props;
    FoodbombAPI.post(`suppliers/products`, sanitizedValues)
      .then((response) => {
        actions.setSubmitting(false);
        this.props.createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully created product',
          timeout: 8000,
          closable: true,
        });
        this.props.history.replace(`/products/${response.data.id}`);
        this.fetchInitialData();
      })
      .catch((error) => {
        actions.setSubmitting(false);
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to create product. Please see the errors above',
          timeout: 4000,
          closable: true,
        });
        if (error?.response?.status === 400 && error?.response?.data?.errors) {
          actions.setErrors(this.mapAPIErrorsToFormFields(error.response.data.errors));
        } else {
          this.props.sendDatadogError('Unable to create product', {
            error,
            location: 'Product create page',
          });
          actions.setStatus({
            apiError: (
              <ErrorNotification
                body={"We were unable to create this product. Don't worry, our engineers have been notified!"}
                className={styles.ErrorNotification}
              />
            ),
          });
        }
      });
  };

  potentiallyCreateSpecialWarningNotification = (updatedProduct) => {
    if (updatedProduct.specials && updatedProduct.specials.length) {
      this.props.createNotification({
        type: NOTIFICATION_TYPES.WARNING,
        content: `Note: This product is on special! Price changes won't alter ongoing specials`,
        closable: true,
      });
    }
  };

  potentiallyCreateCustomPriceWarningNotification = (updatedProduct) => {
    if (updatedProduct.customPricing) {
      const venueNames = updatedProduct.customPricing
        .map((customPrice) => `'${customPrice.venue.venue}'`)
        .reduce((a, b, i, array) => a + (i < array.length - 1 ? ', ' : ' and ') + b);
      this.props.createNotification({
        type: NOTIFICATION_TYPES.WARNING,
        content: `Note: ${venueNames} have custom pricing for ${updatedProduct.name}. Please check these prices as custom prices are not updated when you change a product price.`,
        closable: true,
      });
    }
  };

  handleUpdateProduct = (values, actions) => {
    const productId = this.props.match.params.id;
    const { createNotification } = this.props;
    const { initialProductDetails } = this.state;
    const sanitizedValues = this.sanitizeFormData(values);
    const updatedProduct = { ...initialProductDetails, ...sanitizedValues };
    FoodbombAPI.patch(`suppliers/products/${productId}`, sanitizedValues)
      .then(() => {
        actions.setSubmitting(false);
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully updated product',
          timeout: 8000,
          closable: true,
        });
        this.potentiallyCreateCustomPriceWarningNotification(updatedProduct);
        this.potentiallyCreateSpecialWarningNotification(updatedProduct);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to update product. Please see the errors above',
          timeout: 4000,
          closable: true,
        });
        if (error.response.status === 400 && error.response.data && error.response.data.errors) {
          // NOTE: oldEndpoint parameter => migrating services, remove once PUT is migrated
          actions.setErrors(this.mapAPIErrorsToFormFields(error.response.data.errors));
        } else {
          this.props.sendDatadogError('Unable to update product', {
            error,
            location: 'Product update page',
          });
          actions.setStatus({
            apiError: (
              <ErrorNotification
                body={"We were unable to update this product. Don't worry, our engineers have been notified!"}
                className={styles.ErrorNotification}
              />
            ),
          });
        }
      });
  };

  handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    if (this.props.method === API_METHODS.PATCH) {
      this.handleUpdateProduct(values, actions);
    } else if (this.props.method === API_METHODS.POST) {
      this.handleCreateProduct(values, actions);
    } else {
      actions.setSubmitting(false);
      this.props.sendDatadogError('Unknown HTTP method', {
        error: new DataMismatchError('Unknown HTTP method', this.props.method, values),
        location: 'Product update / new page',
      });
      actions.setStatus({
        apiError: (
          <ErrorNotification
            body={"An unknow method was supplied... Don't worry, our engineers have been notified!"}
            className={styles.ErrorNotification}
          />
        ),
      });
    }
  };

  setshowImageSelectorModal = (show) => {
    this.setState({ showImageSelectorModal: show });
  };

  handleUpdateImage = (product, image) => {
    this.setState({ selectedImage: image });
    this.setshowImageSelectorModal(false);
  };

  setProductImageSearchQuery = (value) => {
    this.setState({ productImageSearchQuery: value });
  };

  render() {
    const {
      loadingCategories,
      loadingPortionUnits,
      loadingProductDetails,
      errorLoadingInitialData,
      categories,
      categoryDropdowns,
      portionUnits,
      initialProductDetails,
      selectedImage,
      loadingVenues,
      venues,
      customPriceDialogOpen,
      productImageSearchQuery,
    } = this.state;
    const { imageGallery, redirectToSpecials, redirectToCustomPrices, method, isStaff } = this.props;

    const potentiallyLoading =
      loadingCategories ||
      !categories.length ||
      !categoryDropdowns.length ||
      loadingPortionUnits ||
      !portionUnits ||
      loadingProductDetails ||
      (method === API_METHODS.PATCH && !initialProductDetails) ||
      (method === API_METHODS.PATCH && loadingVenues);

    const isCreatingNewProduct = method === API_METHODS.POST;

    const initialValues = {
      name: '',
      sku: '',
      price: '',
      unitOfPriceId: '',
      hasGST: false,
      categories: [''],
      unitOfPortionId: '',
      portions: [1],
      enabled: true,
      imageId: null,
    };

    const formValidationObject = Yup.object({
      name: Yup.string().trim().required('Product name is required'),
      sku: isCreatingNewProduct
        ? Yup.string().trim().required('Product SKU is required').max(64)
        : Yup.string().trim().max(64),
      price: Yup.string()
        .trim()
        .required('Price is required')
        .matches(
          /^(?!(?:0|0\.0|0\.00)$)[+]?\d+(\.\d|\.\d[0-9])?$/,
          'Must be a positive number with a maximum of 2 decimal places',
        ),
      unitOfPriceId: Yup.number().required('Unit of Price is required'),
      hasGST: Yup.boolean().required(),
      categories: Yup.array()
        .of(Yup.string().trim())
        .required('Must have categories')
        .min(1, 'Must have at least one category'),
      unitOfPortionId: Yup.number()
        .required('Unit of Portion is required')
        .test(
          'unit-validator',
          'Unit of portion must be one of the available options for the selected for unit of price',
          function validateUnitOfPortion(value) {
            if (this.parent.unitOfPriceId === 1 || this.parent.unitOfPriceId === 2) {
              return value === 1 || value === 2;
            }
            return value === this.parent.unitOfPriceId;
          },
        ),
      portions: Yup.array()
        .of(Yup.number().typeError('Must be a positive number').positive('Must be a positive number'))
        .required('Please add at least one portion')
        .min(1, 'Must have at least one portion')
        .test('portion-validator', 'Must have at least one portion', (value) => value.some((po) => po)),

      enabled: Yup.boolean().required('Required'),
    });

    return (
      <React.Fragment>
        <div>
          <PageHeader>
            <Breadcrumbs
              currentPageTitle={
                // eslint-disable-next-line no-nested-ternary
                isCreatingNewProduct
                  ? 'New'
                  : initialProductDetails && initialProductDetails.sku
                  ? initialProductDetails.sku
                  : 'Edit'
              }
              previousLinks={[
                {
                  previousPageTitle: (
                    <React.Fragment>
                      <Emoji content="🍏" label="apple" />
                      &nbsp; Products
                    </React.Fragment>
                  ),
                  previousPageLink: `/products${window.location?.search || ''}`,
                },
              ]}
            />
            <Typography type={TYPOGRAPHY_TYPES.HEADING_M}>
              {method === API_METHODS.PATCH ? 'Update your product' : 'Add a new product'}
            </Typography>
          </PageHeader>
        </div>
        <div className={styles.PageContainer}>
          {errorLoadingInitialData ? (
            <div className={styles.InitialLoadingErrorContainer}>{errorLoadingInitialData}</div>
          ) : (
            <React.Fragment>
              {potentiallyLoading ? (
                <LargeLoadingSpinner />
              ) : (
                <Formik
                  initialValues={isCreatingNewProduct ? initialValues : initialProductDetails}
                  initialStatus={{
                    apiError: undefined,
                  }}
                  validationSchema={formValidationObject}
                  onSubmit={this.handleSubmit}
                >
                  {({ isSubmitting, values, errors, touched, setFieldValue, setTouched, status }) => (
                    <React.Fragment>
                      <Form
                        className={
                          isSubmitting
                            ? [styles.ProductForm, styles.ProductForm__submitting].join(' ')
                            : styles.ProductForm
                        }
                      >
                        <div className={styles.ProductFormAndDetailsContainer}>
                          <div className={styles.ProductFormContainer}>
                            <Paper className={styles.ProductForm__section}>
                              <DoneIcon
                                className={
                                  values.name &&
                                  !errors.name &&
                                  values.price &&
                                  !errors.price &&
                                  values.unitOfPriceId &&
                                  !errors.unitOfPriceId
                                    ? styles.SectionCompleteIcon
                                    : [styles.SectionCompleteIcon, styles.hide].join(' ')
                                }
                              />
                              <ErrorOutlineIcon
                                className={
                                  (touched.name && errors.name) ||
                                  (touched.price && errors.price) ||
                                  (touched.unitOfPriceId && errors.unitOfPriceId)
                                    ? styles.SectionInvalidIcon
                                    : [styles.SectionInvalidIcon, styles.hide].join(' ')
                                }
                              />
                              <Typography
                                type={TYPOGRAPHY_TYPES.HEADING_L}
                                className={styles.ProductFormSectionHeading}
                              >
                                Product Details&nbsp;
                                <Tooltip title="Product details are required">
                                  <span className={styles.RequiredIcon}>*</span>
                                </Tooltip>
                              </Typography>
                              <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ProductFormSectionDescription}>
                                Enter your product details and define your product name, the default price per unit of
                                measurement and select if the product requires GST.
                              </Typography>
                              <div className={styles.ProductFormRow}>
                                <div className={[styles.InputContainer, styles.InputContainer__sku].join(' ')}>
                                  <FormikFormField
                                    fieldName="sku"
                                    touched={touched}
                                    errors={errors}
                                    placeholder="Enter product SKU"
                                    label="Product SKU"
                                  />
                                </div>
                                <div className={[styles.InputContainer, styles.InputContainer__name].join(' ')}>
                                  <FormikFormField
                                    fieldName="name"
                                    touched={touched}
                                    errors={errors}
                                    placeholder="Enter product name"
                                    label="Product Name"
                                  />
                                </div>
                              </div>
                              <div className={styles.ProductFormRow}>
                                <div className={[styles.InputContainer, styles.InputContainer__price].join(' ')}>
                                  <FormikFormField
                                    fieldName="price"
                                    touched={touched}
                                    errors={errors}
                                    placeholder={`Enter price per ${
                                      values.unitOfPriceId
                                        ? portionUnits.find((pu) => pu.id === values.unitOfPriceId).label
                                        : 'unit'
                                    }`}
                                    label={`Price per ${
                                      values.unitOfPriceId
                                        ? portionUnits.find((pu) => pu.id === values.unitOfPriceId).label
                                        : 'unit'
                                    } ($)`}
                                    fieldProps={{
                                      type: 'number',
                                      step: '0.01',
                                    }}
                                  />
                                </div>
                                <div className={[styles.InputContainer, styles.InputContainer__unit].join(' ')}>
                                  <FormikSingleSelectField
                                    label="Unit of Price"
                                    fieldName="unitOfPriceId"
                                    errors={errors}
                                    touched={touched}
                                    value={portionUnits.find((portionUnit) => portionUnit?.id === values.unitOfPriceId)}
                                    isLoading={loadingPortionUnits}
                                    loadingMessage={() => 'Loading portions...'}
                                    options={portionUnits}
                                    placeholder="Select unit of price"
                                    setTouched={setTouched}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              </div>
                              <div className={styles.ProductFormRow}>
                                <div className={[styles.InputContainer, styles.InputContainer__GSTFlag].join(' ')}>
                                  <div className={styles.GSTCheckboxWrapper}>
                                    <Checkbox
                                      label="This product requires GST to be added 💸"
                                      checked={values.hasGST}
                                      onChange={(e) => {
                                        setTouched({ ...touched, hasGST: true });
                                        setFieldValue('hasGST', e.currentTarget.checked);
                                      }}
                                    />
                                  </div>
                                  <ErrorMessage name="hasGST" component="div" className={styles.ErrorMessage} />
                                </div>
                              </div>
                            </Paper>
                            <Paper className={styles.ProductForm__section}>
                              <DoneIcon
                                className={
                                  values.categories.filter((cat) => cat).length
                                    ? styles.SectionCompleteIcon
                                    : [styles.SectionCompleteIcon, styles.hide].join(' ')
                                }
                              />
                              <ErrorOutlineIcon
                                className={
                                  touched.categories && !values.categories.filter((cat) => cat).length
                                    ? styles.SectionInvalidIcon
                                    : [styles.SectionInvalidIcon, styles.hide].join(' ')
                                }
                              />
                              <Typography
                                type={TYPOGRAPHY_TYPES.HEADING_L}
                                className={styles.ProductFormSectionHeading}
                              >
                                Product Categories&nbsp;
                                <Tooltip title="Product categories are required">
                                  <span className={styles.RequiredIcon}>*</span>
                                </Tooltip>
                              </Typography>
                              <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ProductFormSectionDescription}>
                                Define the categories and sub-categories that your product belongs to. Please select an
                                option for all sub-categories.
                              </Typography>
                              <div className={[styles.ProductFormRow, styles.ProductFormRow__categories].join(' ')}>
                                <FieldArray name="categories">
                                  {({ push, pop }) => (
                                    <React.Fragment>
                                      {values.categories &&
                                        values.categories.length > 0 &&
                                        values.categories.map((category, idx) => (
                                          <div
                                            key={`category_${idx}`}
                                            className={[styles.InputContainer, styles.InputContainer__category].join(
                                              ' ',
                                            )}
                                          >
                                            <FormikSingleSelectField
                                              label={`Category ${idx + 1}`}
                                              fieldName={`categories.${idx}`}
                                              errors={errors}
                                              touched={touched}
                                              value={
                                                categoryDropdowns[idx].find(
                                                  (cat) => cat.id === values.categories[idx],
                                                ) || ''
                                              }
                                              isLoading={loadingCategories}
                                              loadingMessage={() => 'Loading categories...'}
                                              options={categoryDropdowns[idx]}
                                              placeholder="Select a category"
                                              setTouched={setTouched}
                                              setFieldValue={setFieldValue}
                                              customOnChange={(selectedCategory) => {
                                                setTouched({ ...touched, [`categories.${idx}`]: true });
                                                setFieldValue(`categories.${idx}`, selectedCategory.id);

                                                this.potentiallyAddNewCategory(
                                                  selectedCategory.id,
                                                  values,
                                                  idx,
                                                  push,
                                                  pop,
                                                );
                                              }}
                                            />
                                          </div>
                                        ))}
                                    </React.Fragment>
                                  )}
                                </FieldArray>
                              </div>
                              {touched.categories && values.categories.filter((cat) => cat).length === 0 ? (
                                <div className={styles.ErrorMessage}>Please select at least one category</div>
                              ) : null}
                            </Paper>
                            <Paper className={styles.ProductForm__section}>
                              <DoneIcon
                                className={
                                  values.unitOfPortionId && values.portions.filter((portion) => portion).length
                                    ? styles.SectionCompleteIcon
                                    : [styles.SectionCompleteIcon, styles.hide].join(' ')
                                }
                              />
                              <ErrorOutlineIcon
                                className={
                                  (touched.unitOfPortionId && errors.unitOfPortionId) ||
                                  (touched.portions && !values.portions.filter((po) => po).length)
                                    ? styles.SectionInvalidIcon
                                    : [styles.SectionInvalidIcon, styles.hide].join(' ')
                                }
                              />
                              <Typography
                                type={TYPOGRAPHY_TYPES.HEADING_L}
                                className={styles.ProductFormSectionHeading}
                              >
                                Product Portions&nbsp;
                                <Tooltip title="Product portions are required">
                                  <span className={styles.RequiredIcon}>*</span>
                                </Tooltip>
                              </Typography>
                              <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ProductFormSectionDescription}>
                                Define the portion unit for your product and then add as many portion sizes as required.
                                Please select a unit of price before selecting your portions.
                              </Typography>
                              <div className={styles.ProductFormRow}>
                                <div className={[styles.InputContainer, styles.InputContainer__portionUnit].join(' ')}>
                                  <FormikSingleSelectField
                                    label="Unit of Portion"
                                    fieldName="unitOfPortionId"
                                    errors={errors}
                                    touched={touched}
                                    value={portionUnits.find(
                                      (portionUnit) => portionUnit.id === values.unitOfPortionId,
                                    )}
                                    isLoading={loadingPortionUnits}
                                    isDisabled={!values.unitOfPriceId}
                                    loadingMessage={() => 'Loading portions...'}
                                    options={this.filterAvailablePortionUnitsBySelectedPriceUnitId(
                                      portionUnits,
                                      values.unitOfPriceId,
                                    )}
                                    placeholder={
                                      !values.unitOfPriceId
                                        ? 'Please select a unit of price before you select your portion'
                                        : 'Select unit of portion'
                                    }
                                    setTouched={setTouched}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              </div>
                              <FieldArray
                                name="portions"
                                render={(arrayHelpers) => (
                                  <React.Fragment>
                                    <div className={styles.ProductFormRow__portions}>
                                      {values.portions &&
                                        values.portions.length > 0 &&
                                        values.portions.map((portion, idx) => (
                                          <div key={`portion_${idx}`} className={styles.InputContainer__portionSection}>
                                            <div className={styles.InputContainer__portion}>
                                              <div
                                                className={[styles.InputContainer, styles.InputContainer__portion].join(
                                                  ' ',
                                                )}
                                              >
                                                <FormikFormField
                                                  fieldName={`portions.${idx}`}
                                                  touched={touched}
                                                  errors={errors}
                                                  placeholder={`Enter portion size ${
                                                    values.unitOfPortionId
                                                      ? portionUnits.find((pu) => pu.id === values.unitOfPortionId)
                                                          .label
                                                      : ''
                                                  }`}
                                                  label={`Portion size ${
                                                    values.unitOfPortionId
                                                      ? portionUnits.find((pu) => pu.id === values.unitOfPortionId)
                                                          .label
                                                      : ''
                                                  }`}
                                                  fieldProps={{
                                                    type: 'number',
                                                    step: '0.01',
                                                  }}
                                                />
                                              </div>
                                              <div className={styles.PortionInput__portionUnit}>
                                                {values.unitOfPortionId
                                                  ? portionUnits.find((pu) => pu.id === values.unitOfPortionId).unit
                                                  : null}
                                              </div>
                                              <div>
                                                <Tooltip title="Delete Portion">
                                                  <Button
                                                    className={styles.DeletePortionBtn}
                                                    variant={BUTTON_VARIANTS.DANGER}
                                                    onClick={() => arrayHelpers.remove(idx)}
                                                    tabIndex="-1"
                                                  >
                                                    <DeleteOutlineIcon className={styles.Icon} />
                                                  </Button>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                    <Button
                                      className={styles.AddPortionBtn}
                                      variant={BUTTON_VARIANTS.SECONDARY}
                                      onClick={() => arrayHelpers.push('')}
                                    >
                                      Add Another Portion Size
                                    </Button>

                                    <div>
                                      <React.Fragment>
                                        {typeof errors.portions === 'string' ? (
                                          <ErrorMessage
                                            name="portions"
                                            component="div"
                                            className={styles.ErrorMessage}
                                          />
                                        ) : null}
                                      </React.Fragment>
                                    </div>
                                  </React.Fragment>
                                )}
                              />
                            </Paper>
                            <Paper className={styles.ProductForm__section}>
                              <Typography
                                type={TYPOGRAPHY_TYPES.HEADING_L}
                                className={styles.ProductFormSectionHeading}
                              >
                                Product Image
                              </Typography>
                              <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ProductFormSectionDescription}>
                                The image of this product.
                              </Typography>
                              <div className={styles.ProductFormRow__portions}>
                                <div className={[styles.InputContainer, styles.InputContainer__image].join(' ')}>
                                  <div className={styles.ProductImageField}>
                                    <img
                                      className={styles.ProductImg}
                                      src={selectedImage ? selectedImage.url : null}
                                      alt={selectedImage ? selectedImage.fileName : ''}
                                    />
                                    {selectedImage ? (
                                      <InputLabel className={[styles.Label, styles.SelectedImageName].join(' ')}>
                                        {selectedImage.fileName}
                                      </InputLabel>
                                    ) : null}
                                    {isStaff ? (
                                      <Button
                                        className={styles.ProductImageBtn}
                                        variant={BUTTON_VARIANTS.SECONDARY}
                                        onClick={() => this.setshowImageSelectorModal(true)}
                                      >
                                        {isCreatingNewProduct ? 'Choose Image' : 'Edit Image'}
                                      </Button>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </Paper>
                          </div>
                          <div className={styles.ProductDetailsContainer}>
                            <Paper className={[styles.ProductForm__section, styles.ProductSummary].join(' ')}>
                              <Typography
                                type={TYPOGRAPHY_TYPES.HEADING_L}
                                className={styles.ProductFormSectionHeading}
                              >
                                Product Summary
                              </Typography>
                              <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ProductSummary__section}>
                                {values.name ? `${values.name}` : null}
                                <div>{values.sku ? `[${values.sku}]` : null}</div>
                              </Typography>
                              <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ProductSummary__section}>
                                <div className={styles.SummaryHeading}>Price:</div>
                                {values.price ? (
                                  <React.Fragment>
                                    <div>
                                      {presentCurrency(values.price)}
                                      {values.unitOfPriceId
                                        ? `/${portionUnits.find((pu) => pu.id === values.unitOfPriceId).unit}`
                                        : null}{' '}
                                      (Ex. GST)
                                    </div>
                                    {values.hasGST ? (
                                      <div>
                                        {presentCurrency(calculatePriceIncGST(values.price))}
                                        {values.unitOfPriceId
                                          ? `/${portionUnits.find((pu) => pu.id === values.unitOfPriceId).unit}`
                                          : null}{' '}
                                        (Inc. GST)
                                      </div>
                                    ) : null}
                                  </React.Fragment>
                                ) : (
                                  '--'
                                )}
                              </Typography>
                              <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ProductSummary__section}>
                                <div className={styles.SummaryHeading}>Categories:</div>
                                <div>
                                  {values.categories.filter((cat) => cat).length ? (
                                    <React.Fragment>
                                      {values.categories
                                        .filter((selectedCategory) => selectedCategory)
                                        .map(
                                          (selectedCategory) =>
                                            categories.find((category) => selectedCategory === category.id).name,
                                        )
                                        .join(' > ')}
                                    </React.Fragment>
                                  ) : (
                                    <span>--</span>
                                  )}
                                </div>
                              </Typography>
                              <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ProductSummary__section}>
                                <div className={styles.SummaryHeading}>Portions:</div>
                                <table className={styles.PortionsTable}>
                                  <tbody>
                                    {values.portions
                                      .filter((portion) => portion)
                                      .map((portion, idx) => (
                                        <tr key={`portion_${idx}`}>
                                          <td className={styles.Portion}>
                                            <span className={styles.PortionTitle}>
                                              {portion}
                                              {values.unitOfPortionId
                                                ? `${portionUnits.find((pu) => pu.id === values.unitOfPortionId).unit}`
                                                : null}
                                            </span>
                                            &nbsp;
                                            {values.price && values.unitOfPortionId && values.unitOfPriceId
                                              ? `(at ${presentCurrency(values.price)}/${
                                                  portionUnits.find((pu) => pu.id === values.unitOfPriceId).unit
                                                })`
                                              : null}
                                          </td>
                                          <td className={styles.Price}>
                                            <div>
                                              {values.price && values.unitOfPriceId && values.unitOfPortionId ? (
                                                <React.Fragment>
                                                  {presentCurrency(
                                                    portionSizePriceCalculator(
                                                      round2DP(values.price),
                                                      values.unitOfPriceId,
                                                      values.unitOfPortionId,
                                                      portion,
                                                    ),
                                                  )}
                                                  &nbsp;(Ex.GST)
                                                </React.Fragment>
                                              ) : null}
                                            </div>

                                            <div>
                                              {values.price &&
                                              values.unitOfPriceId &&
                                              values.unitOfPortionId &&
                                              values.hasGST ? (
                                                <React.Fragment>
                                                  {presentCurrency(
                                                    portionSizePriceCalculator(
                                                      calculatePriceIncGST(values.price),
                                                      values.unitOfPriceId,
                                                      values.unitOfPortionId,
                                                      portion,
                                                    ),
                                                  )}
                                                  &nbsp;(Inc.GST)
                                                </React.Fragment>
                                              ) : null}
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                                <div></div>
                              </Typography>
                              <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ProductSummary__section}>
                                <div className={styles.SummaryHeading}>Image:</div>
                                <div>
                                  <img
                                    className={styles.ProductImg}
                                    src={selectedImage ? selectedImage.url : null}
                                    alt={selectedImage ? selectedImage.fileName : ''}
                                  />
                                </div>
                              </Typography>
                              {!isCreatingNewProduct && initialProductDetails.specials ? (
                                <React.Fragment>
                                  <NotificationMessageSection
                                    type={NOTIFICATION_MESSAGE_TYPES.WARNING}
                                    size={NOTIFICATION_MESSAGE_SIZES.SMALL}
                                    title="This product is on special"
                                    className={styles.SummaryWarning}
                                    emoji={<SpecialsIcon />}
                                    ariaLabelForEmoji="falme"
                                  >
                                    <button onClick={redirectToSpecials} className={styles.SpecialsLinkBtn}>
                                      Click here to manage your specials in the specials page
                                    </button>
                                  </NotificationMessageSection>
                                </React.Fragment>
                              ) : null}
                              {!isCreatingNewProduct && initialProductDetails.customPricing ? (
                                <React.Fragment>
                                  <NotificationMessageSection
                                    type={NOTIFICATION_MESSAGE_TYPES.WARNING}
                                    size={NOTIFICATION_MESSAGE_SIZES.SMALL}
                                    title="This product has custom pricing"
                                    className={styles.SummaryWarning}
                                    emoji={'⚡️'}
                                    ariaLabelForEmoji="bolt"
                                  >
                                    <button
                                      type="button"
                                      onClick={this.handleOpenCustomPriceDialog}
                                      className={styles.SpecialsLinkBtn}
                                    >
                                      Edit custom prices for this product
                                    </button>
                                    <div>or</div>
                                    <button
                                      type="button"
                                      onClick={redirectToCustomPrices}
                                      className={styles.SpecialsLinkBtn}
                                    >
                                      Manage your custom pricing for all products
                                    </button>
                                  </NotificationMessageSection>
                                </React.Fragment>
                              ) : null}
                              <div className={styles.ProductSummary__section}>
                                <Tooltip title="Disabled products will not be shown to venues">
                                  <div className={styles.EnableProductContainer}>
                                    <Checkbox
                                      label="Enable product"
                                      checked={values.enabled}
                                      onChange={(e) => {
                                        setTouched({ ...touched, enabled: true });
                                        setFieldValue('enabled', e.currentTarget.checked);
                                      }}
                                    />
                                    <InfoOutlinedIcon className={styles.Icon} />
                                  </div>
                                </Tooltip>
                                <Tooltip title="Out of stock products cannot be purchased">
                                  <div className={styles.EnableProductContainer}>
                                    <Checkbox
                                      label="Out of stock"
                                      checked={values.outOfStock}
                                      onChange={(e) => {
                                        setTouched({ ...touched, outOfStock: false });
                                        setFieldValue('outOfStock', e.currentTarget.checked);
                                      }}
                                    />
                                    <InfoOutlinedIcon className={styles.Icon} />
                                  </div>
                                </Tooltip>
                                {isStaff ? (
                                  <Tooltip title="Product is shown as a Best Buy">
                                    <div className={styles.EnableProductContainer}>
                                      <Checkbox
                                        label="Best Buy"
                                        checked={values.isBestBuy}
                                        onChange={(e) => {
                                          setTouched({ ...touched, isBestBuy: false });
                                          setFieldValue('isBestBuy', e.currentTarget.checked);
                                        }}
                                      />
                                      <InfoOutlinedIcon className={styles.Icon} />
                                    </div>
                                  </Tooltip>
                                ) : null}
                              </div>
                              <div className={styles.ProductSummary__section}>
                                <Button type="submit" disabled={isSubmitting} className={styles.SubmitBtn}>
                                  {isCreatingNewProduct ? 'Create Product' : 'Update Product'}
                                </Button>
                              </div>
                              {status.apiError ? (
                                <React.Fragment>{status.apiError}</React.Fragment>
                              ) : (
                                <React.Fragment>
                                  {touched &&
                                  errors &&
                                  Object.keys(touched).some((touchedItem) =>
                                    Object.keys(errors).includes(touchedItem),
                                  ) ? (
                                    <div className={styles.ProductSummary__section}>
                                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ErrorMessage}>
                                        Please make sure the form is complete and correct any errors
                                      </Typography>
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              )}
                            </Paper>
                          </div>
                        </div>
                      </Form>
                      {initialProductDetails ? (
                        <CustomPriceDialog
                          isOpen={customPriceDialogOpen}
                          handleClose={this.handleCloseCustomPriceDialog}
                          product={initialProductDetails}
                          venues={venues}
                          updateProductData={this.updateInitialProductDetails}
                        />
                      ) : null}
                    </React.Fragment>
                  )}
                </Formik>
              )}
              <ProductImageSelectorModal
                onSave={this.handleUpdateImage}
                onClose={() => this.setshowImageSelectorModal(false)}
                open={this.state.showImageSelectorModal}
                selectedProduct={isCreatingNewProduct ? null : initialProductDetails}
                imageGallery={imageGallery}
                productImageSearchQuery={productImageSearchQuery}
                setProductImageSearchQuery={this.setProductImageSearchQuery}
              />
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isStaff: state.auth.supplierDetails ? state.auth.supplierDetails.isStaff : false,
  imageGallery: state.images ? state.images.images : [],
});

Product.propTypes = {
  method: PropTypes.oneOf(Object.values(API_METHODS)).isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  createNotification: PropTypes.func.isRequired,
  redirectToSpecials: PropTypes.func.isRequired,
  isStaff: PropTypes.bool,
  sendDatadogError: PropTypes.func.isRequired,
  redirectToCustomPrices: PropTypes.func.isRequired,
  imageGallery: PropTypes.array.isRequired,
};

export default withErrorReports(
  withRedirectHelper(withNotifications(withRouter(connect(mapStateToProps, null)(Product)))),
);
