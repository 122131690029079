export interface Product {
  productId: number;
  name: string;
  sku: string;
  categories: number[];
  priceInCents: number;
  unitOfPriceId: number;
  gstExempt: boolean;
  unitOfPortionId: number;
  portions: number[];
  enabled: boolean;
  outOfStock: boolean;
}

// Method names: findProductDeltaToUpdate, multipleProductToUpdate
export const extractProductsToUpdate = (
  currentProductsMap: Map<number, Product>,
  productsToUploadMap: Map<number, Product>,
): Partial<Product>[] => {
  const validProductDeltas: Partial<Product>[] = [];
  const fieldsToCheck: (keyof Product)[] = [
    'categories',
    'enabled',
    'gstExempt',
    'name',
    'portions',
    'priceInCents',
    'sku',
    'unitOfPortionId',
    'unitOfPriceId',
    'outOfStock',
  ];

  productsToUploadMap.forEach((productToUpload: Product, productId: number) => {
    const isNewProduct = !currentProductsMap.has(productId);
    if (isNewProduct) {
      validProductDeltas.push(productToUpload);
      return;
    }

    const currentProduct: Product = currentProductsMap.get(productId) as Product;
    if (productToUpload !== currentProduct) {
      const productDelta: Partial<Product> = {};
      fieldsToCheck.forEach((fieldName: keyof Product) => {
        if (JSON.stringify(currentProduct[fieldName]) !== JSON.stringify(productToUpload[fieldName])) {
          (productDelta as any)[fieldName] = productToUpload[fieldName];
        }
      });

      const productHasNotChanged: boolean = Object.keys(productDelta).length === 0;
      if (productHasNotChanged) {
        return;
      }

      productDelta.productId = productToUpload.productId;
      productDelta.sku = productToUpload.sku;
      productDelta.unitOfPortionId = productToUpload.unitOfPortionId;
      productDelta.unitOfPriceId = productToUpload.unitOfPriceId;

      if ('gstExempt' in productDelta || 'priceInCents' in productDelta) {
        productDelta.priceInCents = productToUpload.priceInCents;
        productDelta.gstExempt = productToUpload.gstExempt;
      }

      validProductDeltas.push(productDelta);
    }
  });

  return validProductDeltas;
};
