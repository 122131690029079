import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import queryString from 'query-string';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import StoreInformationCard from './SettingsCards/StoreInformationCard';
import BankDetailsCard from './SettingsCards/BankDetailsCard';
import BusinessDetailsCard from './SettingsCards/BusinessDetailsCard';
import PrimaryOrderRecipientCard from './SettingsCards/PrimaryOrderRecipient';
import ContactCard from './SettingsCards/ContactCard';
import PasswordCard from './SettingsCards/PasswordCard';
import LargeLoadingSpinner from '../../components/UI/LargeLoadingSpinner/LargeLoadingSpinner';
import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import withRedirectHelper from '../../hoc/withRedirectHelper/withRedirectHelper';
import MultiContactCard from './SettingsCards/MultiContactCard.tsx';
import NewCustomerPreferencesCard from './SettingsCards/NewCustomerPreferencesCard.tsx';
import SubstitutionCard from './SettingsCards/SubstitutionCard.tsx';
import { ErrorNotification, Tooltip, Emoji } from '../../components/UI/FB';
import { SUPPLIER_CONTACT_TYPE_IDS } from '../../utils/Constants';
import StoreImagesCard from './SettingsCards/StoreImagesCard.tsx';
import EarlyDeliveryPreferenceCard from './SettingsCards/EarlyDeliveryPreferenceCard.tsx';
import EnableB2CCard from './SettingsCards/EnableB2CCard.tsx';
import ActivationDateCard from './SettingsCards/ActivationDateCard.tsx';
import styles from './Settings.module.scss';
import AdminCard from './SettingsCards/AdminCard.tsx';

const Settings = ({ isStaff, sendDatadogError, location }) => {
  const primaryOrderRecipient = useRef();
  const passwordSection = useRef();
  const businessDetailsSection = useRef();
  const newCustomerPreferencesSection = useRef();
  const additionalOrderRecipientSection = useRef();
  const newCustomerSetUp = useRef();
  const updatedDetailsRecipient = useRef();
  const bankDetailsSection = useRef();
  const RemittanceRecipientSection = useRef();
  const supportContactSection = useRef();
  const reviewsRecipientSection = useRef();
  const afterHoursContactSection = useRef();
  const ownerContactSection = useRef();
  const storeInformationSection = useRef();
  const storeImagesSection = useRef();
  const substitutionSection = useRef();
  const earlyDeliveryPreferenceSection = useRef();
  const b2cEnabledSection = useRef();
  const activationDateSection = useRef();
  const adminSection = useRef();

  const [supplierInfo, setSupplierInfo] = useState({});
  const [loadingSupplierInfo, setLoadingSupplierInfo] = useState(true);
  const [supplierInfoError, setSupplierInfoError] = useState(undefined);

  const [pulsingCard, setPulsingCard] = useState(undefined);

  const triggerPulseForCard = (card) => {
    setPulsingCard(card);
    setTimeout(() => {
      setPulsingCard(undefined);
    }, 1600);
  };

  const contactTypeExists = (contactKey) => !supplierInfo[contactKey];

  const CARDS = {
    OWNER_DETAILS: {
      name: 'OWNER_DETAILS',
      supplierContactName: 'ownerContact',
      linkName: 'Owner Details',
      // Note these are nested in the ownerContact object
      keys: ['firstName', 'lastName', 'email', 'phone'],
      ref: ownerContactSection,
      shouldHide: () => contactTypeExists('ownerContact'),
    },
    PRIMARY_ORDER_RECIPIENT: {
      name: 'PRIMARY_ORDER_RECIPIENT',
      linkName: 'Primary Order Recipient',
      keys: ['firstName', 'lastName', 'email', 'phone'],
      ref: primaryOrderRecipient,
      isInvalidFunction: () => !CARDS.PRIMARY_ORDER_RECIPIENT.keys.every((key) => supplierInfo[key]),
    },
    ADDITIONAL_ORDER_RECIPIENT: {
      name: 'ADDITIONAL_ORDER_RECIPIENT',
      linkName: 'Additional Order Recipient',
      staffOnly: true,
      // Note these are nested in the secondaryOrderRecipientContact object
      keys: ['firstName', 'lastName', 'email', 'phone'],
      ref: additionalOrderRecipientSection,
      shouldHide: () => contactTypeExists('additionalOrderRecipientContact'),
    },
    NEW_CUSTOMER_SET_UP: {
      name: 'NEW_CUSTOMER_SET_UP',
      linkName: 'New customer set-up',
      keys: ['firstName', 'lastName', 'email', 'phone'],
      ref: newCustomerSetUp,
    },
    UPDATED_DETAILS_RECIPIENT: {
      name: 'UPDATED_DETAILS_RECIPIENT',
      linkName: 'Updated Details Recipient',
      keys: ['firstName', 'lastName', 'email', 'phone'],
      ref: updatedDetailsRecipient,
    },
    REVIEWS_RECIPIENT: {
      name: 'REVIEWS_RECIPIENT',
      linkName: 'Reviews Recipient',
      // Note these are nested in the reviewsContact object
      keys: ['firstName', 'lastName', 'email', 'phone'],
      ref: reviewsRecipientSection,
      shouldHide: () => contactTypeExists('reviewsContact'),
    },
    SUPPORT_CONTACT: {
      name: 'SUPPORT_CONTACT',
      supplierContactName: 'supportContact',
      linkName: 'Support Contact',
      // Note these are nested in the supportContact object
      keys: ['firstName', 'lastName', 'email', 'phone', 'contactHours', 'contactDays'],
      ref: supportContactSection,
      shouldHide: () => contactTypeExists('supportContact'),
    },
    AFTER_HOURS_CONTACT: {
      name: 'AFTER_HOURS_CONTACT',
      supplierContactName: 'afterHoursContact',
      linkName: 'After Hours Contact',
      // Note these are nested in the afterHoursContact object
      keys: ['firstName', 'lastName', 'email', 'phone', 'contactHours', 'contactDays'],
      ref: afterHoursContactSection,
      shouldHide: () => contactTypeExists('afterHoursContact'),
    },
    REMITTANCE_RECIPIENT: {
      name: 'REMITTANCE_RECIPIENT',
      linkName: 'Remittance Recipient',
      // Note these are nested in the remittanceRecipient object
      keys: ['firstName', 'lastName', 'email', 'phone'],
      ref: RemittanceRecipientSection,
      shouldHide: () => contactTypeExists('remittanceContact'),
    },
    BUSINESS_DETAILS: {
      name: 'BUSINESS_DETAILS',
      linkName: 'Business Details',
      // Note these are nested in the address object
      keys: ['street', 'postcode', 'suburb'],
      ref: businessDetailsSection,
      isInvalidFunction: () => !CARDS.BUSINESS_DETAILS.keys.every((key) => supplierInfo.address[key]),
    },
    NEW_CUSTOMER_PREFERENCES: {
      name: 'NEW_CUSTOMER_PREFERENCES',
      linkName: 'New Customer Preferences',
      keys: ['availableForNewCustomers'],
      ref: newCustomerPreferencesSection,
      staffOnly: true,
    },
    BANK_DETAILS: {
      name: 'BANK_DETAILS',
      linkName: 'Bank Details',
      keys: ['accountName', 'bsb', 'accountNumber'],
      ref: bankDetailsSection,
      staffOnly: true,
    },
    SUBSTITUTION: {
      name: 'SUBSTITUTION',
      linkName: 'Substitution Preferences',
      keys: ['substitutionPreferenceEnabled'],
      ref: substitutionSection,
      staffOnly: true,
    },
    EARLY_DELIVERY_PREFERENCE: {
      name: 'EARLY_DELIVERY_PREFERENCE',
      linkName: 'Early Delivery Preference',
      ref: earlyDeliveryPreferenceSection,
      staffOnly: true,
    },
    ADMIN: {
      name: 'ADMIN',
      linkName: '3PL Preferences',
      ref: adminSection,
      staffOnly: true,
    },
    B2CENABLED: {
      name: 'B2CENABLED',
      linkName: 'Enable supplier for B2C',
      ref: b2cEnabledSection,
      staffOnly: true,
    },
    SUPPLIER_ACTIVATION_DATE: {
      name: 'SUPPLIER_ACTIVATION_DATE',
      linkName: 'Supplier Activation Date',
      ref: activationDateSection,
      staffOnly: true,
    },
    STORE_INFORMATION: {
      name: 'STORE_INFORMATION',
      linkName: 'Store Information',
      keys: ['company', 'bio'],
      ref: storeInformationSection,
      staffOnly: true,
      required: true,
    },
    STORE_IMAGES: {
      name: 'STORE_IMAGES',
      linkName: 'Store Images',
      keys: ['backgroundImageUrl', 'image'],
      ref: storeImagesSection,
      staffOnly: true,
    },
    PASSWORD: {
      name: 'PASSWORD',
      linkName: 'Password',
      keys: ['currentPassword', 'password', 'passwordConfirmation'],
      ref: passwordSection,
    },
  };

  const updateSupplierDetails = (newDetails) => {
    setSupplierInfo((oldInfo) => ({ ...oldInfo, ...newDetails }));
  };

  const scrollToRef = (ref) =>
    ref?.current ? window.scrollTo({ top: ref.current.offsetTop - 122, behavior: 'smooth' }) : undefined;

  const handleScrollToCardAndPulse = (cardName) => {
    scrollToRef(CARDS[cardName].ref);
    triggerPulseForCard(cardName);
  };

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query && query.section) {
      handleScrollToCardAndPulse(query.section.toUpperCase().replace('-', '_'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, loadingSupplierInfo]);

  const fetchSupplier = useCallback(
    () =>
      FoodbombAPI.get('suppliers/auth/me')
        .then((response) => response.data)
        .catch((error) => {
          sendDatadogError('Unable to fetch supplier details', {
            error,
            location: 'Settings Page',
          });
          throw error;
        }),
    [sendDatadogError],
  );

  const fetchContactTypes = useCallback(
    () =>
      FoodbombAPI.get('suppliers/contact-types')
        .then((response) => response.data)
        .catch((error) => {
          sendDatadogError('Unable to fetch contact types', {
            error,
            location: 'Settings Page',
          });
          throw error;
        }),
    [sendDatadogError],
  );

  const fetchSupplierContacts = useCallback(
    () =>
      FoodbombAPI.get('suppliers/contacts')
        .then((response) => response.data)
        .catch((error) => {
          sendDatadogError('Unable to fetch contacts', {
            error,
            location: 'Settings Page',
          });
          throw error;
        }),
    [sendDatadogError],
  );

  const fetchSupplierInfo = useCallback(() => {
    setSupplierInfoError(undefined);
    setLoadingSupplierInfo(true);
    Promise.all([fetchSupplier(), fetchContactTypes(), fetchSupplierContacts()])
      .then((values) => {
        const supplierData = values[0];
        const contactTypes = values[1];
        const supplierContacts = values[2];

        const additionalOrderRecipientContactType = contactTypes.find(
          (type) => type.id === SUPPLIER_CONTACT_TYPE_IDS.ADDITIONAL_ORDER_RECIPIENT,
        )?.id;

        const newCustomerSetUpContactType = contactTypes.find(
          (type) => type.id === SUPPLIER_CONTACT_TYPE_IDS.NEW_CUSTOMER_SETUP,
        )?.id;

        const updatedDetailsRecipientContactType = contactTypes.find(
          (type) => type.id === SUPPLIER_CONTACT_TYPE_IDS.UPDATED_DETAILS_RECIPIENT,
        )?.id;

        const supportContactType = contactTypes.find((type) => type.id === SUPPLIER_CONTACT_TYPE_IDS.SUPPORT)?.id;
        const afterHoursContactType = contactTypes.find(
          (type) => type.id === SUPPLIER_CONTACT_TYPE_IDS.AFTER_HOURS,
        )?.id;
        const ownerContactType = contactTypes.find((type) => type.id === SUPPLIER_CONTACT_TYPE_IDS.OWNER)?.id;
        const remittanceContactType = contactTypes.find((type) => type.id === SUPPLIER_CONTACT_TYPE_IDS.REMITTANCE)?.id;
        const reviewsContactType = contactTypes.find((type) => type.id === SUPPLIER_CONTACT_TYPE_IDS.REVIEWS)?.id;

        if (additionalOrderRecipientContactType) {
          const additionalOrderRecipientContact = supplierContacts.filter(
            (contact) => contact.contactTypeId === additionalOrderRecipientContactType,
          );
          supplierData.additionalOrderRecipientContact = additionalOrderRecipientContact || [
            {
              contactTypeId: additionalOrderRecipientContactType,
            },
          ];
        }

        if (newCustomerSetUpContactType) {
          const newCustomerSetUpContact = supplierContacts.filter(
            (contact) => contact.contactTypeId === newCustomerSetUpContactType,
          );
          supplierData.newCustomerSetUpContact = newCustomerSetUpContact || [
            {
              contactTypeId: newCustomerSetUpContactType,
            },
          ];
        }

        if (updatedDetailsRecipientContactType) {
          const updatedDetailsRecipientContact = supplierContacts.filter(
            (contact) => contact.contactTypeId === updatedDetailsRecipientContactType,
          );
          supplierData.updatedDetailsRecipientContact = updatedDetailsRecipientContact || [
            {
              contactTypeId: updatedDetailsRecipientContactType,
            },
          ];
        }

        if (supportContactType) {
          const supportContact = supplierContacts.filter((contact) => contact.contactTypeId === supportContactType);
          supplierData.supportContact = supportContact || [{ contactTypeId: supportContactType }];
        }

        if (afterHoursContactType) {
          const afterHoursContact = supplierContacts.filter(
            (contact) => contact.contactTypeId === afterHoursContactType,
          );
          supplierData.afterHoursContact = afterHoursContact || [{ contactTypeId: afterHoursContactType }];
        }

        if (ownerContactType) {
          const ownerContact = supplierContacts.find((contact) => contact.contactTypeId === ownerContactType);
          supplierData.ownerContact = ownerContact || { contactTypeId: ownerContactType };
        }

        if (remittanceContactType) {
          const remittanceContact = supplierContacts.filter(
            (contact) => contact.contactTypeId === remittanceContactType,
          );
          supplierData.remittanceContact = remittanceContact || [{ contactTypeId: remittanceContactType }];
        }

        if (reviewsContactType) {
          const reviewsContact = supplierContacts.filter((contact) => contact.contactTypeId === reviewsContactType);
          supplierData.reviewsContact = reviewsContact || [{ contactTypeId: reviewsContactType }];
        }

        setSupplierInfo(supplierData);
      })
      .catch(() => {
        setSupplierInfoError(<ErrorNotification />);
      })
      .finally(() => {
        setLoadingSupplierInfo(false);
      });
  }, [fetchSupplier, fetchContactTypes, fetchSupplierContacts]);

  useEffect(() => {
    fetchSupplierInfo();
  }, [fetchSupplierInfo]);

  return (
    <div className={styles.SettingsContainer}>
      <PageHeader>
        <Breadcrumbs
          currentPageTitle={
            <React.Fragment>
              <Emoji content="⚙️" label="bolt" />
              &nbsp;Account Settings
            </React.Fragment>
          }
        />
      </PageHeader>
      <div className={styles.PageContainer}>
        {loadingSupplierInfo ? (
          <LargeLoadingSpinner />
        ) : (
          <React.Fragment>
            {supplierInfoError ? (
              <div className={styles.ErrorContainer}>{supplierInfoError}</div>
            ) : (
              <div className={styles.Settings}>
                <div className={isStaff ? [styles.Settings__links, styles.ForStaff].join(' ') : styles.Settings__links}>
                  {Object.values(CARDS)
                    .filter((card) => !card.shouldHide || !card.shouldHide())
                    .map((card) => (
                      <React.Fragment key={card.name}>
                        {(card.staffOnly && isStaff) || !card.staffOnly ? (
                          <div>
                            <button
                              className={
                                card.isInvalidFunction && card.isInvalidFunction()
                                  ? [styles.SettingsLinkBtn, styles.error].join(' ')
                                  : styles.SettingsLinkBtn
                              }
                              onClick={() => handleScrollToCardAndPulse(card.name)}
                            >
                              {card.linkName}&nbsp;
                              <ErrorOutlineIcon
                                className={
                                  card.isInvalidFunction && card.isInvalidFunction()
                                    ? styles.SettingsLinkBtn__errorIcon
                                    : [styles.SettingsLinkBtn__errorIcon, styles.hide].join(' ')
                                }
                              />
                              {card.staffOnly ? (
                                <Tooltip title="This is an admin only option" placement="top">
                                  <span className={styles.TooltipIcon}>
                                    &nbsp;
                                    <Emoji content="🎭" label="masquerading" />
                                  </span>
                                </Tooltip>
                              ) : null}
                            </button>
                          </div>
                        ) : null}
                      </React.Fragment>
                    ))}
                </div>
                <div className={styles.Settings__cards}>
                  {supplierInfo.ownerContact ? (
                    <ContactCard
                      cardName={CARDS.OWNER_DETAILS.linkName}
                      supplierContactName={CARDS.OWNER_DETAILS.supplierContactName}
                      supplierContact={supplierInfo.ownerContact}
                      innerRef={ownerContactSection}
                      updateSupplierDetails={updateSupplierDetails}
                      pulse={pulsingCard === CARDS.OWNER_DETAILS.name}
                      keys={CARDS.OWNER_DETAILS.keys}
                    />
                  ) : null}
                  <PrimaryOrderRecipientCard
                    supplierInfo={supplierInfo}
                    updateSupplierDetails={updateSupplierDetails}
                    innerRef={primaryOrderRecipient}
                    pulse={pulsingCard === CARDS.PRIMARY_ORDER_RECIPIENT.name}
                    keys={CARDS.PRIMARY_ORDER_RECIPIENT.keys}
                  />
                  {isStaff && supplierInfo.additionalOrderRecipientContact ? (
                    <MultiContactCard
                      contactInfos={supplierInfo.additionalOrderRecipientContact}
                      updateContactDetails={updateSupplierDetails}
                      innerRef={additionalOrderRecipientSection}
                      pulse={pulsingCard === CARDS.ADDITIONAL_ORDER_RECIPIENT.name}
                      keys={CARDS.ADDITIONAL_ORDER_RECIPIENT.keys}
                      contactTypeId={SUPPLIER_CONTACT_TYPE_IDS.ADDITIONAL_ORDER_RECIPIENT}
                      cardTitle="Additional Order Recipient"
                      cardDescription="This is an optional additional contact who will receive a copy of every order notification email."
                      tooltipMessage="Recipient details are required"
                    />
                  ) : null}
                  <MultiContactCard
                    contactInfos={supplierInfo.newCustomerSetUpContact}
                    updateContactDetails={updateSupplierDetails}
                    innerRef={newCustomerSetUp}
                    pulse={pulsingCard === CARDS.NEW_CUSTOMER_SET_UP.name}
                    keys={CARDS.NEW_CUSTOMER_SET_UP.keys}
                    contactTypeId={SUPPLIER_CONTACT_TYPE_IDS.NEW_CUSTOMER_SETUP}
                    cardTitle="New Customer set-up"
                    cardDescription="This person will receive new customer orders"
                    tooltipMessage="Customer details are required"
                  />
                  <MultiContactCard
                    contactInfos={supplierInfo.updatedDetailsRecipientContact}
                    updateContactDetails={updateSupplierDetails}
                    innerRef={updatedDetailsRecipient}
                    pulse={pulsingCard === CARDS.UPDATED_DETAILS_RECIPIENT.name}
                    keys={CARDS.UPDATED_DETAILS_RECIPIENT.keys}
                    contactTypeId={SUPPLIER_CONTACT_TYPE_IDS.UPDATED_DETAILS_RECIPIENT}
                    cardTitle="Updated Details Recipient"
                    cardDescription="
                    This person will receive orders with updated delivery instructions."
                    tooltipMessage="Recipient details are required"
                  />
                  {supplierInfo.reviewsContact ? (
                    <MultiContactCard
                      contactInfos={supplierInfo.reviewsContact}
                      updateContactDetails={updateSupplierDetails}
                      innerRef={reviewsRecipientSection}
                      pulse={pulsingCard === CARDS.REVIEWS_RECIPIENT.name}
                      keys={CARDS.REVIEWS_RECIPIENT.keys}
                      contactTypeId={SUPPLIER_CONTACT_TYPE_IDS.REVIEWS}
                      cardTitle="Review Recipient"
                      cardDescription="
                        This person will receive and respond to reviews submitted by your customers."
                      tooltipMessage="Recipient details are required"
                    />
                  ) : null}
                  {supplierInfo.supportContact ? (
                    <MultiContactCard
                      contactInfos={supplierInfo.supportContact}
                      updateContactDetails={updateSupplierDetails}
                      innerRef={supportContactSection}
                      pulse={pulsingCard === CARDS.SUPPORT_CONTACT.name}
                      keys={CARDS.SUPPORT_CONTACT.keys}
                      contactTypeId={SUPPLIER_CONTACT_TYPE_IDS.SUPPORT}
                      cardTitle="Support Contact"
                      cardDescription="
                        Foodbomb will contact this person in the event that a customer has any issues (i.e. order not delivered, missing products etc.)."
                      tooltipMessage="Contact details are required"
                    />
                  ) : null}
                  {supplierInfo.afterHoursContact ? (
                    <MultiContactCard
                      contactInfos={supplierInfo.afterHoursContact}
                      updateContactDetails={updateSupplierDetails}
                      innerRef={afterHoursContactSection}
                      pulse={pulsingCard === CARDS.AFTER_HOURS_CONTACT.name}
                      keys={CARDS.SUPPORT_CONTACT.keys}
                      contactTypeId={SUPPLIER_CONTACT_TYPE_IDS.AFTER_HOURS}
                      cardTitle="After Hours Contact"
                      cardDescription="
                        Foodbomb will contact this person after hours in the event that a customer has any issues (i.e. order not delivered, missing products etc.)."
                      tooltipMessage="Contact details are required"
                    />
                  ) : null}
                  {supplierInfo.remittanceContact ? (
                    <>
                      <MultiContactCard
                        contactInfos={supplierInfo.remittanceContact}
                        innerRef={RemittanceRecipientSection}
                        updateContactDetails={updateSupplierDetails}
                        pulse={pulsingCard === CARDS.REMITTANCE_RECIPIENT.name}
                        keys={CARDS.REMITTANCE_RECIPIENT.keys}
                        contactTypeId={SUPPLIER_CONTACT_TYPE_IDS.REMITTANCE}
                        cardTitle="Remittance Recipient"
                        cardDescription="This person manages payments and remittance for your business."
                        tooltipMessage="Contact details are required"
                      />
                    </>
                  ) : null}
                  <BusinessDetailsCard
                    supplierInfo={supplierInfo}
                    innerRef={businessDetailsSection}
                    updateSupplierDetails={updateSupplierDetails}
                    pulse={pulsingCard === CARDS.BUSINESS_DETAILS.name}
                    keys={CARDS.BUSINESS_DETAILS.keys}
                  />
                  <NewCustomerPreferencesCard
                    updateSupplierDetails={updateSupplierDetails}
                    innerRef={newCustomerPreferencesSection}
                    areNewCustomersAllowed={supplierInfo.availableForNewCustomers}
                  />
                  {isStaff ? (
                    <BankDetailsCard
                      supplierInfo={supplierInfo}
                      innerRef={bankDetailsSection}
                      updateSupplierDetails={updateSupplierDetails}
                      pulse={pulsingCard === CARDS.BANK_DETAILS.name}
                      keys={CARDS.BANK_DETAILS.keys}
                    />
                  ) : null}
                  {isStaff ? (
                    <SubstitutionCard
                      innerRef={substitutionSection}
                      updateSupplierDetails={updateSupplierDetails}
                      pulse={pulsingCard === CARDS.SUBSTITUTION.name}
                      keys={CARDS.SUBSTITUTION.keys}
                      substitutionPreference={Boolean(supplierInfo?.substitutionPreferenceEnabled)}
                    />
                  ) : null}
                  {isStaff ? (
                    <EarlyDeliveryPreferenceCard
                      innerRef={earlyDeliveryPreferenceSection}
                      updateSupplierDetails={updateSupplierDetails}
                      pulse={pulsingCard === CARDS.EARLY_DELIVERY_PREFERENCE.name}
                      supplierInfo={supplierInfo}
                    />
                  ) : null}
                  {isStaff ? (
                    <AdminCard
                      innerRef={adminSection}
                      updateSupplierDetails={updateSupplierDetails}
                      thirdPartyDeliveryEnabled={supplierInfo.thirdPartyDeliveryEnabled}
                    />
                  ) : null}
                  {isStaff ? (
                    <EnableB2CCard
                      innerRef={b2cEnabledSection}
                      updateSupplierDetails={updateSupplierDetails}
                      pulse={pulsingCard === CARDS.B2CENABLED.name}
                      b2cEnabled={supplierInfo?.b2cEnabled}
                      supplierName={supplierInfo?.accountName}
                    />
                  ) : null}
                  {isStaff ? (
                    <ActivationDateCard
                      innerRef={activationDateSection}
                      updateSupplierDetails={updateSupplierDetails}
                      pulse={pulsingCard === CARDS.SUPPLIER_ACTIVATION_DATE.name}
                      supplierActivationDate={supplierInfo?.activationDate}
                    />
                  ) : null}
                  {isStaff ? (
                    <StoreInformationCard
                      supplierInfo={supplierInfo}
                      innerRef={storeInformationSection}
                      updateSupplierDetails={updateSupplierDetails}
                      pulse={pulsingCard === CARDS.STORE_INFORMATION.name}
                      keys={CARDS.STORE_INFORMATION.keys}
                    />
                  ) : null}
                  {isStaff ? (
                    <StoreImagesCard
                      supplierInfo={supplierInfo}
                      innerRef={storeImagesSection}
                      pulse={pulsingCard === CARDS.STORE_IMAGES.name}
                      keys={CARDS.STORE_IMAGES.keys}
                      updateSupplierDetails={updateSupplierDetails}
                    />
                  ) : null}
                  <PasswordCard
                    supplierInfo={supplierInfo}
                    innerRef={passwordSection}
                    pulse={pulsingCard === CARDS.PASSWORD.name}
                    keys={CARDS.PASSWORD.keys}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isStaff: state.auth?.supplierDetails?.isStaff || false,
});

Settings.propTypes = {
  isStaff: PropTypes.bool,
  sendDatadogError: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  redirectToDeliveryZones: PropTypes.func.isRequired,
};

export default withErrorReports(withRedirectHelper(connect(mapStateToProps, null)(Settings)));
