import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'moment';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { CircularProgress } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import withRedirectHelper from '../../hoc/withRedirectHelper/withRedirectHelper';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import OrderStatusChip from '../../components/OrderStatusChip/OrderStatusChip.tsx';
import CustomerAlertChip from '../../components/CustomerAlertChip/CustomerAlertChip';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import { ErrorNotification, StatusChip, Paper, Typography, Emoji, Button, Table } from '../../components/UI/FB';
import SpecialsIcon from '../../components/UI/SpecialsIcon/SpecialsIcon';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import { presentCurrency } from '../../utils/Presenters/PresentCurrency/PresentCurrency';
import {
  STATUS_CHIP_TYPES,
  SPECIAL_STATUSES,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
  TYPOGRAPHY_TYPES,
  DOWNLOAD_PENDING_CSV_ENABLED_SUPPLIERS,
  NOTIFICATION_TYPES,
  SORT_BY_METHODS,
} from '../../utils/Constants';
import { getDateText, getDateLabel } from '../../utils/DateHelper/DateHelper';
import styles from './Dashboard.module.scss';

const ORDER_API_FIELDS = {
  id: {
    endpointKey: 'orderId',
    searchable: true,
  },
  fullName: {
    endpointKey: 'fullName',
    searchable: true,
  },
  venue: {
    endpointKey: 'shippingCompany',
    searchable: true,
  },
  dateOrdered: {
    endpointKey: 'dateAdded',
  },
  deliveryDate: {
    endpointKey: 'deliveryDate',
  },
  status: {
    endpointKey: 'orderStatus',
  },
  total: {
    endpointKey: 'total',
  },
  venueId: {
    endpointKey: 'venueId',
  },
  orderedBefore: {
    endpointKey: 'orderedBefore',
  },
  customerAlert: {
    endpointKey: 'customerAlert',
  },
};

const Dashboard = ({
  redirectToOrder,
  redirectToOrders,
  redirectToSpecials,
  supplierId,
  sendDatadogError,
  redirectToProduct,
  createNotification,
}) => {
  const mapValueToSortByToAPIField = (valueToSortBy) => ORDER_API_FIELDS[valueToSortBy].endpointKey;
  const [orders, setOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [ordersAPIError, setOrdersAPIError] = useState(undefined);

  // Server Side Filtering of table
  const [pageSize, setPageSize] = useState(20);
  const [ordersResultsLength, setOrdersResultsLength] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [orderBy, setOrderBy] = useState(mapValueToSortByToAPIField('id'));
  const [sortBy, setSortBy] = useState(SORT_BY_METHODS.DESC);

  const [specials, setSpecials] = useState([]);
  const [loadingSpecials, setLoadingSpecials] = useState(false);
  const [specialsAPIError, setSpecialsAPIError] = useState(undefined);
  const [downloadingCSVs, setDownloadingCSVs] = useState(false);

  const handleColumnHeaderClick = (valueToSortBy, shouldSortByMin) => {
    setOrderBy(mapValueToSortByToAPIField(valueToSortBy));
    setSortBy(shouldSortByMin ? SORT_BY_METHODS.ASC : SORT_BY_METHODS.DESC);
  };

  const onNextPageClick = () => {
    setCurrentPageNumber((prev) => prev + 1);
  };

  const onPreviousPageClick = () => {
    setCurrentPageNumber((prev) => prev - 1);
  };

  const fetchAllSupplierOrders = useCallback(() => {
    setLoadingOrders(true);
    setOrdersAPIError(undefined);

    const fields = Object.values(ORDER_API_FIELDS)
      .map((field) => field.endpointKey)
      .join(';');

    FoodbombAPI.get(
      `/suppliers/orders?pageSize=${pageSize}&page=${currentPageNumber}&filter=${fields}&search=orderStatus[]:PENDING&orderBy=${orderBy}&sortedBy=${sortBy}`,
    )
      .then((response) => {
        const orderData = response.data.data.map((order) => ({
          id: order.orderId,
          status: order.orderStatus,
          name: order.fullName,
          fullName: order.fullName,
          shippingCompany: order.shippingCompany,
          dateOrdered: order.dateAdded,
          deliveryDate: order.deliveryDate,
          total: order.total,
          isSelected: false,
          orderedBefore: order.orderedBefore,
          customerAlert: order.customerAlert,
        }));

        setOrders(orderData);
        setPageSize(response.data.perPage);
        setOrdersResultsLength(response.data.total);
        setCurrentPageNumber(response.data.currentPage);
      })
      .catch((error) => {
        sendDatadogError('Unable to load orders', {
          error,
          location: 'Dashboard Page',
        });
        setOrdersAPIError(
          <ErrorNotification
            actions={
              <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={() => window.location.reload(true)}>
                Reload this page
              </Button>
            }
          />,
        );
      })
      .finally(() => {
        setLoadingOrders(false);
      });
  }, [currentPageNumber, orderBy, sortBy, pageSize, sendDatadogError]);

  const fetchActiveSpecials = useCallback(() => {
    setLoadingSpecials(true);
    setSpecialsAPIError(undefined);

    FoodbombAPI.get(`specials?status=${SPECIAL_STATUSES.ACTIVE}`)
      .then((response) => {
        const specialsData = response.data.active
          ? response.data.active.map((s) => ({ ...s, status: SPECIAL_STATUSES.ACTIVE }))
          : [];

        setSpecials(specialsData);
      })
      .catch((error) => {
        sendDatadogError('Unable to load active specials', {
          error,
          location: 'Dashboard Page',
        });
        setSpecialsAPIError(<ErrorNotification />);
      })
      .finally(() => {
        setLoadingSpecials(false);
      });
  }, [sendDatadogError]);

  const createStatusChip = (order) => <OrderStatusChip apiOrderStatus={order.status} />;

  const createCustomerAlertChip = (order) => (
    <>{order.customerAlert ? <CustomerAlertChip customerAlert={order.customerAlert} /> : null}</>
  );

  const handleOrdersRowClick = (order) => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 768) {
      redirectToOrder(order.id);
    }
  };

  const handleSpecialRowClick = (special) => {
    const productId = special?.product.id;
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 768 && productId) {
      redirectToProduct(productId);
    }
  };

  const downloadCSVs = () => {
    setDownloadingCSVs(true);
    FoodbombAPI.get(`suppliers/csv/fresho`, { responseType: 'blob' })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'pending_orders.zip'); // any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: "Unable to download pending orders CSV's. Please try again later",
          timeout: 3000,
          closable: true,
        });
        sendDatadogError("Unable to download pending orders CSV's", {
          error,
          location: 'Dashboard Page',
        });
      })
      .finally(() => {
        setDownloadingCSVs(false);
      });
  };

  const createStatusCell = (special) => {
    let statusType = STATUS_CHIP_TYPES.FAILED;
    if (special.status === SPECIAL_STATUSES.ACTIVE) {
      statusType = STATUS_CHIP_TYPES.SUCCESS;
    }
    if (special.status === SPECIAL_STATUSES.SCHEDULED) {
      statusType = STATUS_CHIP_TYPES.PENDING;
    }
    return <StatusChip hideIcon type={statusType} title={special.status} />;
  };

  const specialsColumnSpecs = [
    {
      index: 0,
      title: 'SKU',
      mobileText: 'SKU',
      sortMethod: 'product.sku',
      className: styles.SKU,
      columnDataFunction: (special) => special.product.sku,
      searchable: true,
    },
    {
      index: 1,
      title: 'Product Name',
      className: styles.ProductName,
      sortMethod: 'product.name',
      columnDataFunction: (special) => special.product.name,
      searchable: true,
    },
    {
      index: 2,
      title: '🗓 Start',
      className: styles.Date,
      sortMethod: 'startDate',
      columnDataFunction: (special) => Moment(special.startDate).format('DD MMM YYYY'),
      searchable: true,
    },
    {
      index: 3,
      title: '🗓 End',
      className: styles.Date,
      sortMethod: 'endDate',
      columnDataFunction: (special) => Moment(special.endDate).format('DD MMM YYYY'),
      searchable: true,
    },
    {
      index: 4,
      className: styles.Price,
      title: 'OG Price',
      sortMethod: 'product.price',
      columnDataFunction: (special) =>
        `$${(special.product.price / 100).toLocaleString()}/${special.product.priceUnit}`,
      searchable: true,
    },
    {
      index: 5,
      className: styles.Price,
      title: (
        <span>
          <SpecialsIcon />
          &nbsp;Price
        </span>
      ),
      sortMethod: 'specialPrice',
      columnDataFunction: (special) => `$${(special.specialPrice / 100).toLocaleString()}/${special.product.priceUnit}`,
      searchable: true,
    },
    {
      index: 6,
      title: 'Status',
      sortMethod: 'status',
      columnDataFunction: (special) => special.status,
      getChild: createStatusCell,
      searchable: true,
    },
  ];

  const ordersColumnSpecs = [
    {
      index: 0,
      title: 'Order ID',
      sortMethod: 'id',
      className: styles.OrderID,
      columnDataFunction: (order) => order.id,
      searchable: true,
    },
    {
      index: 1,
      title: '🗓 Placed',
      className: styles.Placed,
      sortMethod: 'dateOrdered',
      columnDataFunction: (order) => Moment(order.dateOrdered).format('D MMM YYYY hh:mm a'),
    },
    {
      index: 2,
      title: '🗓 Delivery',
      className: styles.Delivery,
      sortMethod: 'deliveryDate',
      columnDataFunction: (order) => getDateText(order.deliveryDate),
      // eslint-disable-next-line react/display-name
      getChild: (order) => getDateLabel(order.deliveryDate),
    },
    {
      index: 3,
      className: styles.CompanyName,
      title: 'Venue Name',
      sortMethod: 'venue',
      columnDataFunction: (order) => order.shippingCompany,
      // eslint-disable-next-line react/display-name
      getChild: (order) => <div className={styles.VenueName}>&nbsp;{order.shippingCompany}</div>,
      searchable: true,
    },
    {
      index: 4,
      className: styles.CustomerName,
      title: 'Customer Name',
      sortMethod: 'fullName',
      columnDataFunction: (order) => order.fullName,
      // eslint-disable-next-line react/display-name
      getChild: (order) => <div className={styles.VenueName}>&nbsp;{order.fullName}</div>,
      searchable: true,
    },
    {
      index: 5,
      title: 'Alert',
      className: styles.CustomerAlert,
      columnDataFunction: (order) => order.customerAlert,
      getChild: createCustomerAlertChip,
    },
    {
      index: 6,
      title: 'Status',
      className: styles.Status,
      sortMethod: 'status',
      columnDataFunction: (order) => order.status,
      getChild: createStatusChip,
      searchable: true,
    },
    {
      index: 7,
      title: 'Value',
      sortMethod: 'total',
      className: styles.OrderValue,
      columnDataFunction: (order) => presentCurrency(order.total / 100),
      searchable: true,
    },
  ];

  const createOrderRowActions = (order) => (
    <React.Fragment>
      <Button
        onClick={() => redirectToOrder(order.id)}
        className={styles.RowAction}
        variant={BUTTON_VARIANTS.SECONDARY}
        size={BUTTON_SIZES.EXTRA_SMALL}
      >
        View Order
      </Button>
    </React.Fragment>
  );

  const createSpecialRowActions = (special) => (
    <Button
      onClick={() => redirectToProduct(special.product.id)}
      className={styles.TableBtn}
      variant={BUTTON_VARIANTS.SECONDARY}
      size={BUTTON_SIZES.EXTRA_SMALL}
    >
      View Product
    </Button>
  );

  useEffect(() => {
    fetchAllSupplierOrders();
    fetchActiveSpecials();
  }, [fetchAllSupplierOrders, fetchActiveSpecials]);

  return (
    <div className={styles.DashboardContainer}>
      <PageHeader>
        <Breadcrumbs
          currentPageTitle={
            <React.Fragment>
              <Emoji content="🎛" label="dashboard" />
              &nbsp;Dashboard
            </React.Fragment>
          }
        />
      </PageHeader>
      <div className={styles.PageContainer}>
        <div className={styles.OrdersContainer}>
          <div className={styles.SectionHeading}>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Pending Orders</Typography>
            <Button
              variant={BUTTON_VARIANTS.SECONDARY}
              size={BUTTON_SIZES.SMALL}
              className={styles.HeadingBtn}
              onClick={redirectToOrders}
            >
              View all orders&nbsp;
              <ArrowForwardIosIcon className={styles.HeadingBtn__icon} />
            </Button>

            {Object.values(DOWNLOAD_PENDING_CSV_ENABLED_SUPPLIERS).includes(supplierId) ? (
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTON_SIZES.SMALL}
                disabled={downloadingCSVs}
                className={styles.DownloadCSV__Btn}
                onClick={downloadCSVs}
              >
                <React.Fragment>
                  {downloadingCSVs ? (
                    <CircularProgress className={styles.icon} size={20} thickness={3} />
                  ) : (
                    <GetAppIcon className={styles.downloadIcon} />
                  )}
                  Download CSV&apos;s&nbsp;
                </React.Fragment>
              </Button>
            ) : null}
          </div>
          <div className={styles.Orders}>
            {ordersAPIError ? (
              <div className={styles.ErrorWrapper}>{ordersAPIError}</div>
            ) : (
              <div className={styles.TableContainer}>
                {orders.length || loadingOrders ? (
                  <Table
                    className={styles.OrdersTable}
                    columnSpecs={ordersColumnSpecs}
                    rowData={orders}
                    loadingRowData={loadingOrders}
                    handleRowClick={handleOrdersRowClick}
                    grow
                    rowOverlayColSpan={4}
                    defaultSortColumn={2}
                    sortAscending={true}
                    rowActions={createOrderRowActions}
                    hideMobileArrow
                    paginationOverrideData={{
                      currentPageNumber,
                      onNextPageClick,
                      onPreviousPageClick,
                      pageSize,
                      numberOfResults: ordersResultsLength,
                    }}
                    useServerSidePagination
                    sortOverrideMethod={handleColumnHeaderClick}
                    noResultsComponent={<div>No Pending Orders</div>}
                  />
                ) : (
                  <Paper className={styles.NoResultsContainer}>
                    <Typography type={TYPOGRAPHY_TYPES.HEADING_L}>
                      <Emoji content="🎉" label="party" />
                      &nbsp;No pending orders!
                    </Typography>
                    <div className={styles.TypographySection}>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                        All is done for now, we&apos;ll notifiy you by email when new orders come through.
                      </Typography>
                    </div>
                  </Paper>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.SpecialsContainer}>
          <div className={styles.SectionHeading}>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Active Specials</Typography>
            <Button
              variant={BUTTON_VARIANTS.SECONDARY}
              size={BUTTON_SIZES.SMALL}
              className={styles.HeadingBtn}
              onClick={redirectToSpecials}
            >
              View all specials&nbsp;
              <ArrowForwardIosIcon className={styles.HeadingBtn__icon} />
            </Button>
          </div>
          <div className={styles.Specials}>
            {specialsAPIError ? (
              <div className={styles.ErrorWrapper}>{specialsAPIError}</div>
            ) : (
              <div className={styles.TableContainer}>
                {specials.length || loadingSpecials ? (
                  <Table
                    className={styles.SpecialsTable}
                    columnSpecs={specialsColumnSpecs}
                    rowData={specials}
                    loadingRowData={loadingSpecials}
                    handleRowClick={handleSpecialRowClick}
                    grow
                    rowOverlayColSpan={4}
                    sortAscending={false}
                    rowActions={createSpecialRowActions}
                    hideMobileArrow
                    noResultsComponent={<div>No Active Specials</div>}
                  />
                ) : (
                  <Paper className={styles.NoResultsContainer}>
                    <Typography type={TYPOGRAPHY_TYPES.HEADING_L}>
                      <Emoji content="☹️" label="sad" />
                      &nbsp;No active specials!
                    </Typography>
                    <div className={styles.TypographySection}>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                        Specials are a great way to get your business running up on Foodbomb. They are easy to setup and
                        you have full control.
                      </Typography>
                      <div>
                        <Button
                          onClick={redirectToSpecials}
                          size={BUTTON_SIZES.SMALL}
                          className={styles.SpecialsCTABtn}
                        >
                          Get Started with specials
                        </Button>
                      </div>
                    </div>
                  </Paper>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  supplierId: state.auth.supplierDetails ? state.auth.supplierDetails.id : undefined,
});

Dashboard.propTypes = {
  supplierId: PropTypes.number.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  redirectToOrder: PropTypes.func.isRequired,
  redirectToOrders: PropTypes.func.isRequired,
  redirectToSpecials: PropTypes.func.isRequired,
  redirectToProduct: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
};

export default withNotifications(withErrorReports(connect(mapStateToProps, null)(withRedirectHelper(Dashboard))));
