import { AddProductsCSVHeaders, EditProductsCSVHeaders } from '../CSVHeaders';
import {
  areCSVHeadersValid,
  yupValidationHandler,
  isSKUDuplicated,
  doesFieldNotHaveError,
  isWeightClassAvaliableForUnit,
  isPortionAndPriceUnitsConsistent,
  isCategoryAMainCategory,
  isCategoryEntered,
} from './CSVUploadValidatorsHelpers';

export const validateCSVFormat = (uploadedCSVHeaders, paPaParseErrors, addProductsMode = true) => {
  const CSVFormatError = { path: '', message: '' };
  const validHeaders = areCSVHeadersValid(
    uploadedCSVHeaders,
    addProductsMode ? AddProductsCSVHeaders : EditProductsCSVHeaders,
  );

  if (!validHeaders) {
    CSVFormatError.path = 'headers';
    CSVFormatError.message = 'Your CSV has incorrect headers';
    CSVFormatError.incorrectHeaders = uploadedCSVHeaders;
  } else if (paPaParseErrors.length) {
    CSVFormatError.path = 'papaparse';
    CSVFormatError.message = `Your CSV has incorrect formatting. Please check around rows ${paPaParseErrors
      .map((error) => error.row + 1)
      .join(', ')}`;
  }

  return CSVFormatError;
};

export const validateCSVData = async (products, portionUnits, categories, addProductsMode = true) => {
  const validatedProducts = [];

  const validationPromises = products.map((product) => yupValidationHandler(product, addProductsMode));
  const potentiallyValidatedProducts = await Promise.all(validationPromises);

  potentiallyValidatedProducts.forEach((validatedProduct) => {
    if (
      isSKUDuplicated(validatedProducts, validatedProduct) &&
      addProductsMode &&
      doesFieldNotHaveError(validatedProduct, 'sku')
    ) {
      validatedProduct.productErrors.push({ path: 'sku', message: 'Product SKU is duplicated' });
    }

    if (
      doesFieldNotHaveError(validatedProduct, 'unitOfPrice') &&
      !isWeightClassAvaliableForUnit(validatedProduct.productSanitized.unitOfPrice, portionUnits)
    ) {
      validatedProduct.productErrors.push({
        path: 'unitOfPrice',
        message: 'Price Unit must be Kilogram, Gram, Box, Units, Bunch(s)',
      });
    }

    if (
      doesFieldNotHaveError(validatedProduct, 'unitOfPortion') &&
      !isWeightClassAvaliableForUnit(validatedProduct.productSanitized.unitOfPortion, portionUnits)
    ) {
      validatedProduct.productErrors.push({
        path: 'unitOfPortion',
        message: 'Portion Unit must be Kilogram, Gram, Box, Units, Bunch(s)',
      });
    }

    if (
      doesFieldNotHaveError(validatedProduct, 'unitOfPortion') &&
      doesFieldNotHaveError(validatedProduct, 'unitOfPrice') &&
      !isPortionAndPriceUnitsConsistent(
        validatedProduct.productSanitized.unitOfPortion,
        validatedProduct.productSanitized.unitOfPrice,
      )
    ) {
      validatedProduct.productErrors.push({
        path: 'unitOfPrice',
        message: 'Price Unit and Portion Unit must be consistent',
      });
      validatedProduct.productErrors.push({
        path: 'unitOfPortion',
        message: 'Portion Unit and Price Unit must be consistent',
      });
    }

    // below validates the three categories
    let currentCategoryParentId = null;
    ['categoryOne', 'categoryTwo', 'categoryThree'].forEach((categoryPath) => {
      const sanitizedCategoryVal = validatedProduct.productSanitized[categoryPath];

      const categoryEntered = isCategoryEntered(sanitizedCategoryVal);

      // handle special case for categoryOne where it is required and must be a top tier category
      if (categoryPath === 'categoryOne') {
        if (!categoryEntered) {
          validatedProduct.productErrors.push({ path: categoryPath, message: 'Category One is required' });
          return;
        }
        if (!isCategoryAMainCategory(categories, sanitizedCategoryVal)) {
          validatedProduct.productErrors.push({
            path: categoryPath,
            message: 'Category is not a top tier category',
          });
          return;
        }
      }

      // ensure if other categories are entered they exist and they are related to the previous
      if (categoryEntered) {
        const currentCategory = categories.filter((cat) => cat.name === sanitizedCategoryVal);
        if (!currentCategory.length) {
          validatedProduct.productErrors.push({ path: categoryPath, message: 'Category does not exist' });
          return;
        }

        const correctCurrentCategory = currentCategory.filter(
          (cat) => cat.parentCategoryId === currentCategoryParentId,
        );
        if (!correctCurrentCategory.length) {
          validatedProduct.productErrors.push({
            path: categoryPath,
            message: 'Category is not related to the previous category',
          });
          return;
        }
        currentCategoryParentId = correctCurrentCategory[0].id;
      } else {
        currentCategoryParentId = null;
      }
    });

    validatedProducts.push(validatedProduct);
  });

  return validatedProducts;
};
