import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { InputLabel, CircularProgress, Dialog, DialogContent, IconButton, DialogTitle } from '@material-ui/core';
import Moment from 'moment';
import { Formik, Form, ErrorMessage } from 'formik';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { START_DATE, END_DATE } from 'react-nice-dates';

import DateRangePicker from '../DatePicker/DateRangePicker';
import { ErrorNotification, FormikSingleSelectField, Typography, Button, NotificationMessageSection } from '../UI/FB';
import {
  TYPOGRAPHY_TYPES,
  PENDING_ORDER,
  COMPLETED_ORDER,
  CANCELLED_ORDER,
  ALL,
  NOTIFICATION_MESSAGE_TYPES,
  NOTIFICATION_MESSAGE_SIZES,
  NOTIFICATION_TYPES,
  MOMENT_FORMATS,
} from '../../utils/Constants';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import styles from './DownloadOrdersDialog.module.scss';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';

const DownloadOrdersDialog = ({ isOpen, handleClose, createNotification, sendDatadogError }) => {
  const beginningOfWeek = () => new Date(Moment().startOf('week').toISOString());
  const endOfWeek = () => new Date(Moment().endOf('week').toISOString());

  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [visualStartDate, setVisualStartDate] = useState(Moment(beginningOfWeek()).format(MOMENT_FORMATS.SIMPLE_DATE));
  const [visualEndDate, setVisualEndDate] = useState(Moment(endOfWeek()).format(MOMENT_FORMATS.SIMPLE_DATE));

  const TIMEFRAMES = {
    TODAY: 'Today',
    TOMORROW: 'Tomorrow',
    CURRENT_WEEK: 'Current Week',
    CURRENT_MONTH: 'Current Month',
    CUSTOM: 'Custom Dates',
  };

  const timeframeOptions = [
    {
      id: 1,
      value: 1,
      label: TIMEFRAMES.TODAY,
      startDate: new Date(Moment().startOf('day').toISOString()),
      endDate: new Date(Moment().endOf('day').toISOString()),
    },
    {
      id: 2,
      value: 2,
      label: TIMEFRAMES.TOMORROW,
      startDate: new Date(Moment().add(1, 'days').startOf('day').toISOString()),
      endDate: new Date(Moment().add(1, 'days').endOf('day').toISOString()),
    },
    {
      id: 3,
      value: 3,
      label: TIMEFRAMES.CURRENT_WEEK,
      startDate: beginningOfWeek(),
      endDate: endOfWeek(),
    },
    {
      id: 4,
      value: 4,
      label: TIMEFRAMES.CURRENT_MONTH,
      startDate: new Date(Moment().startOf('month').toISOString()),
      endDate: new Date(Moment().endOf('month').toISOString()),
    },
    {
      id: 5,
      value: 5,
      label: TIMEFRAMES.CUSTOM,
      startDate: beginningOfWeek(),
      endDate: endOfWeek(),
    },
  ];

  const orderStatusOptions = [
    {
      id: ALL,
      value: ALL,
      label: 'Any status',
    },
    {
      id: PENDING_ORDER,
      value: PENDING_ORDER,
      label: 'Pending',
    },
    {
      id: COMPLETED_ORDER,
      value: COMPLETED_ORDER,
      label: 'Completed',
    },
    {
      id: CANCELLED_ORDER,
      value: CANCELLED_ORDER,
      label: 'Cancelled',
    },
  ];

  const getOrderStatusParams = (selectedOption) => {
    if (selectedOption === 'ALL') {
      return `orderStatus[]:${PENDING_ORDER};orderStatus[]:${COMPLETED_ORDER};orderStatus[]:${CANCELLED_ORDER}`;
    }
    return `orderStatus[]:${selectedOption}`;
  };

  const attemptToDownloadCSV = (values, actions) => {
    actions.setStatus({ apiError: undefined });
    const statusParams = getOrderStatusParams(values.orderStatus);
    const startDateISO = Moment(values.startDate).toISOString();
    const endDateISO = Moment(values.endDate).toISOString();
    const fileName = `orderData${Moment().valueOf()}`;
    FoodbombAPI.get(
      `/suppliers/orders?format=csv&from=${startDateISO}&to=${endDateISO}&search=${statusParams}&filename=${fileName}&filter=orderId;dateAdded;deliveryDate;firstName;lastName;orderStatus;total;shippingCompany;shippingAddress;shippingPostcode;shippingCity;shippingZone`,
      {
        responseType: 'blob',
      },
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `orderData${Moment().valueOf()}.csv`);
        document.body.appendChild(link);
        link.click();
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully downloaded order data csv',
          timeout: 4000,
          closable: true,
        });
        handleClose();
      })
      .catch((error) => {
        sendDatadogError('Unable to download order data csv', {
          error,
          location: 'Orders index page',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to download order data csv. Please try again later',
          timeout: 3000,
          closable: true,
        });
        actions.setStatus({
          apiError: (
            <ErrorNotification
              body={'We were unable to download the order data csv. Our engineers have been notified!'}
            />
          ),
        });
        actions.setSubmitting(false);
      });
  };

  const handleTimeFrameChange = (selectedValue, setTouched, touched, setFieldValue) => {
    setTouched({ ...touched, timeframeId: true, startDate: true, endDate: true });
    setFieldValue('timeframeId', selectedValue.id);

    if (selectedValue.id !== timeframeOptions.find((option) => option.label === TIMEFRAMES.CUSTOM).id) {
      setFieldValue('startDate', selectedValue.startDate);
      setVisualStartDate(Moment(selectedValue.startDate).format(MOMENT_FORMATS.SIMPLE_DATE));
      setFieldValue('endDate', selectedValue.endDate);
      setVisualEndDate(Moment(selectedValue.endDate).format(MOMENT_FORMATS.SIMPLE_DATE));
    } else {
      setShowCustomDatePicker(true);
    }
  };

  return (
    <Dialog
      open={isOpen}
      keepMounted={false}
      onClose={handleClose}
      aria-labelledby="download-orders-dialog"
      classes={{ root: styles.DownloadOrdersDialog__root, paper: styles.DownloadOrdersDialog__paper }}
    >
      <IconButton aria-label="close" onClick={handleClose} className={styles.CloseBtn}>
        <CloseIcon />
      </IconButton>
      <DialogTitle id="wholesaler-dialog" className={styles.DownloadOrdersDialog__title}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>&nbsp;Download Orders CSV</Typography>
      </DialogTitle>
      <DialogContent
        className={
          showCustomDatePicker
            ? [styles.DownloadOrdersDialog__content, styles.expand].join(' ')
            : styles.DownloadOrdersDialog__content
        }
      >
        <Typography type={TYPOGRAPHY_TYPES.BODY_L} className={styles.DownloadOrdersDialog__subheader}>
          Choose the data you’d like to download as a CSV.
        </Typography>
        <Formik
          initialValues={{
            startDate: timeframeOptions[2].startDate,
            endDate: timeframeOptions[2].endDate,
            timeframeId: timeframeOptions[2].id,
            orderStatus: orderStatusOptions[0].id,
          }}
          onSubmit={attemptToDownloadCSV}
          initialStatus={{
            apiError: undefined,
          }}
        >
          {({ errors, touched, values, status, setFieldValue, setTouched, isSubmitting }) => (
            <Form>
              <div className={styles.DownloadCSVForm}>
                <div className={styles.InputRow}>
                  <div className={styles.FullWidthInputContainer}>
                    <FormikSingleSelectField
                      fieldName="timeframeId"
                      label="Delivery Timeframe"
                      errors={errors}
                      touched={touched}
                      setTouched={setTouched}
                      setFieldValue={setFieldValue}
                      customOnChange={(selectedValue) =>
                        handleTimeFrameChange(selectedValue, setTouched, touched, setFieldValue)
                      }
                      value={timeframeOptions.find((item) => item.id === values.timeframeId)}
                      options={timeframeOptions}
                    />
                  </div>
                </div>
                {showCustomDatePicker ? (
                  <React.Fragment>
                    <div className={styles.InputRow}>
                      <DateRangePicker
                        startDate={values.startDate}
                        endDate={values.endDate}
                        onStartDateChange={(date) => {
                          setTouched({ ...touched, timeframeId: true, startDate: true });
                          setFieldValue(
                            'timeframeId',
                            timeframeOptions.find((option) => option.label === TIMEFRAMES.CUSTOM).id,
                          );
                          setFieldValue('startDate', date, true);
                          setVisualStartDate(Moment(date).format(MOMENT_FORMATS.SIMPLE_DATE));
                        }}
                        onEndDateChange={(date) => {
                          setTouched({ ...touched, timeframeId: true, startDate: true });
                          setFieldValue(
                            'timeframeId',
                            timeframeOptions.find((option) => option.label === TIMEFRAMES.CUSTOM).id,
                          );
                          setFieldValue('endDate', date, true);
                          setVisualEndDate(Moment(date).format(MOMENT_FORMATS.SIMPLE_DATE));
                        }}
                        format="dd MMM yyyy"
                      >
                        {({ startDateInputProps, endDateInputProps, focus }) => (
                          <div className={styles.DateInputsContainer}>
                            <div className={styles.DateInput}>
                              <InputLabel
                                className={
                                  touched.startDate && errors.startDate
                                    ? [styles.InputLabel, styles.error].join(' ')
                                    : styles.InputLabel
                                }
                              >
                                Delivery dates from
                              </InputLabel>
                              <input
                                className={
                                  focus === START_DATE
                                    ? [styles.InputField, styles.focused].join(' ')
                                    : styles.InputField
                                }
                                {...startDateInputProps}
                                value={visualStartDate}
                                placeholder="Select date"
                                onBlur={() => setTouched({ ...touched, startDate: true })}
                              />
                            </div>
                            <ArrowForwardIosIcon className={styles.BetweenDatesIcon} />
                            <div className={styles.DateInput}>
                              <InputLabel
                                className={
                                  touched.endDate && errors.endDate
                                    ? [styles.InputLabel, styles.error].join(' ')
                                    : styles.InputLabel
                                }
                              >
                                Delivery dates to
                              </InputLabel>
                              <input
                                className={
                                  focus === END_DATE ? [styles.InputField, styles.focused].join(' ') : styles.InputField
                                }
                                {...endDateInputProps}
                                value={visualEndDate}
                                placeholder="Select date"
                                onBlur={() => setTouched({ ...touched, endDate: true })}
                              />
                            </div>
                          </div>
                        )}
                      </DateRangePicker>
                    </div>
                    <div className={styles.InputRow}>
                      <div className={styles.Input__halfWidth}>
                        <div className={styles.ErrorMessageContainer}>
                          <ErrorMessage name="startDate" />
                        </div>
                      </div>
                      <div className={styles.Input__halfWidth}>
                        <div className={styles.ErrorMessageContainer}>
                          <ErrorMessage name="endDate" />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
                <div className={styles.InputRow}>
                  <div className={styles.FullWidthInputContainer}>
                    <FormikSingleSelectField
                      fieldName="orderStatus"
                      label="Order status"
                      errors={errors}
                      touched={touched}
                      setTouched={setTouched}
                      setFieldValue={setFieldValue}
                      value={orderStatusOptions.find((item) => item.id === values.orderStatus)}
                      options={orderStatusOptions}
                      defaultValue={orderStatusOptions[0].id}
                    />
                  </div>
                </div>
                <div className={styles.ErrorContainer}>{status.apiError}</div>
                <div
                  className={
                    showCustomDatePicker
                      ? [styles.DownloadOrderDialog__actions, styles.expand].join(' ')
                      : styles.DownloadOrderDialog__actions
                  }
                >
                  {status.apiError ? null : (
                    <NotificationMessageSection
                      type={NOTIFICATION_MESSAGE_TYPES.WARNING}
                      size={NOTIFICATION_MESSAGE_SIZES.SMALL}
                    >
                      <Typography type={TYPOGRAPHY_TYPES.BODY}>
                        Anything you’d like to see in this report? Drop us an email at&nbsp;
                        <a className={styles.Link} href={`mailto:info@foodbomb.com.au`}>
                          info@foodbomb.com.au
                        </a>
                      </Typography>
                    </NotificationMessageSection>
                  )}

                  <Button type="submit" disabled={isSubmitting} className={styles.SubmitBtn}>
                    {isSubmitting ? (
                      <React.Fragment>
                        <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                        &nbsp;Downloading...
                      </React.Fragment>
                    ) : (
                      <React.Fragment>Download CSV</React.Fragment>
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

DownloadOrdersDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
};

export default withErrorReports(withNotifications(DownloadOrdersDialog));
