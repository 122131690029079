import { useState, FC, ElementType } from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Formik, Form, FormikHelpers } from 'formik';
import { CircularProgress, Switch } from '@material-ui/core';
import * as Yup from 'yup';
import { Paper, Typography, Button, FormikSingleSelectField } from '../../../components/UI/FB';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES, BUTTON_VARIANTS } from '../../../utils/Constants';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import { ContactTypes, Supplier } from '../../../utils/Presenters/Common';
import useNotifications from '../../../hooks/useNotifications/useNotifications';
import useDDErrorReporting from '../../../hooks/useDDErrorReporting/useDDErrorReporting';
import { TIMES } from '../../../utils/TimeConstant';
import styles from '../Settings.module.scss';

type EarlyDeliveryPreferenceCardProps = {
  pulse: boolean;
  innerRef: () => void | ElementType;
  updateSupplierDetails: (data: ContactTypes) => void;
  supplierInfo: Supplier;
};

type Values = {
  isEarlyDeliveryEnabled: boolean;
  earlyDeliveryStartTime: string;
  earlyDeliveryEndTime: string;
};

const EarlyDeliveryPreferenceCard: FC<EarlyDeliveryPreferenceCardProps> = ({
  pulse,
  innerRef,
  updateSupplierDetails,
  supplierInfo,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const { createNotification } = useNotifications();
  const { sendDatadogError } = useDDErrorReporting();
  const triggerEditMode = (): void => setEditMode(true);

  const deliveryPreferenceValidationSchema = Yup.object({
    earlyDeliveryStartTime: Yup.string().required('Early delivery start time is required.'),
    earlyDeliveryEndTime: Yup.string().required('Early delivery end time is required.'),
  });

  const handleUpdateSubsPreference = (values: Values, actions: FormikHelpers<Values>) => {
    const { earlyDeliveryStartTime, earlyDeliveryEndTime, isEarlyDeliveryEnabled } = values;
    const payload = {
      earlyDeliveryStartTime,
      earlyDeliveryEndTime,
      isEarlyDeliveryEnabled,
    };

    FoodbombAPI.patch('suppliers/auth/me', payload)
      .then((response) => {
        updateSupplierDetails(response.data);
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Successfully updated early delivery preference`,
          timeout: 4000,
          closable: true,
        });
        actions.setSubmitting(false);
        setEditMode(false);
      })
      .catch((error) => {
        if (error?.response?.status === 400 && error?.response?.data?.errors) {
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to update early delivery preference',
            timeout: 4000,
            closable: true,
          });
        } else {
          sendDatadogError('Unable to update early delivery preference', {
            error,
            location: 'Settings Page ',
          });
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <div ref={innerRef}>
      <Paper className={pulse ? [styles.SettingsCard, styles.pulse].join(' ') : styles.SettingsCard}>
        <Formik
          initialValues={{
            isEarlyDeliveryEnabled: supplierInfo.isEarlyDeliveryEnabled,
            earlyDeliveryStartTime: supplierInfo.earlyDeliveryStartTime || '',
            earlyDeliveryEndTime: supplierInfo.earlyDeliveryEndTime || '',
          }}
          onSubmit={handleUpdateSubsPreference}
          validationSchema={deliveryPreferenceValidationSchema}
        >
          {({ isSubmitting, values, setFieldValue, errors, setTouched, touched }) => (
            <>
              <CheckCircleOutlineIcon
                className={
                  editMode
                    ? [styles.SettingsCard__completeIcon, styles.hide].join(' ')
                    : styles.SettingsCard__completeIcon
                }
              />
              <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.SettingsCard__sectionHeading}>
                Early Delivery Preference
              </Typography>

              <div className={styles.EarlyDeliveryPreference__headerAction}>
                <div className={styles.ToggleButton}>
                  {editMode ? (
                    <>
                      <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD}>Enable delivery preference</Typography>{' '}
                      <Switch
                        checked={values.isEarlyDeliveryEnabled}
                        onChange={() => setFieldValue('isEarlyDeliveryEnabled', !values.isEarlyDeliveryEnabled)}
                        size="medium"
                        classes={{
                          root: styles.SwitchRoot,
                          colorPrimary: styles.ColorPrimary,
                          thumb: styles.ColorPrimary,
                          track: values.isEarlyDeliveryEnabled ? styles.ActiveColor : styles.PausedColor,
                        }}
                      />
                    </>
                  ) : (
                    <>{values.isEarlyDeliveryEnabled ? null : 'Disabled'}</>
                  )}
                </div>
                {supplierInfo.isEarlyDeliveryEnabled &&
                supplierInfo.earlyDeliveryEndTime &&
                supplierInfo.earlyDeliveryStartTime ? (
                  <Typography type={TYPOGRAPHY_TYPES.BODY}>
                    Delivery occurs between {supplierInfo.earlyDeliveryStartTime} and{' '}
                    {supplierInfo.earlyDeliveryEndTime}
                  </Typography>
                ) : null}
              </div>
              {editMode ? (
                <>
                  <Form>
                    <div className={styles.InputContainer}>
                      <FormikSingleSelectField
                        label="Approx start of delivery schedule"
                        fieldName="earlyDeliveryStartTime"
                        errors={errors}
                        touched={touched}
                        value={TIMES.find((time) => time.id === values.earlyDeliveryStartTime) || ''}
                        options={TIMES}
                        setTouched={setTouched}
                        setFieldValue={setFieldValue}
                        maxMenuHeight={132}
                        isDisabled={!values.isEarlyDeliveryEnabled}
                      />

                      <FormikSingleSelectField
                        label="Approx end of delivery schedule"
                        fieldName="earlyDeliveryEndTime"
                        errors={errors}
                        touched={touched}
                        value={TIMES.find((time) => time.id === values.earlyDeliveryEndTime) || ''}
                        options={TIMES}
                        setTouched={setTouched}
                        setFieldValue={setFieldValue}
                        maxMenuHeight={132}
                        isDisabled={!values.isEarlyDeliveryEnabled}
                      />
                    </div>
                    <div className={styles.ToggleFooterActionContainer}>
                      <Button type="button" variant={BUTTON_VARIANTS.SECONDARY} onClick={() => setEditMode(false)}>
                        Cancel
                      </Button>
                      <Button type="submit">
                        {isSubmitting ? (
                          <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                        ) : (
                          <>Update details</>
                        )}
                      </Button>
                    </div>
                  </Form>
                </>
              ) : (
                <>
                  <div className={styles.SettingsCard__editBtnContainer}>
                    <Button
                      variant={BUTTON_VARIANTS.SECONDARY}
                      disabled={isSubmitting}
                      onClick={triggerEditMode}
                      className={styles.EditBtn}
                    >
                      Edit Details
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

EarlyDeliveryPreferenceCard.propTypes = {
  updateSupplierDetails: PropTypes.func.isRequired,
  pulse: PropTypes.bool.isRequired,
};

export default EarlyDeliveryPreferenceCard;
