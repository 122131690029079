import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';

const notificationIcon = ({ notificationType, className }) => {
  switch (notificationType) {
    case 'success':
      return <CheckCircleIcon className={className} />;
    case 'info':
      return <InfoIcon className={className} />;
    case 'warning':
      return <WarningIcon className={className} />;
    case 'error':
      return <ErrorIcon className={className} />;
    default:
      return null;
  }
};

notificationIcon.propTypes = {
  notificationType: PropTypes.string,
  className: PropTypes.string,
};

export default notificationIcon;
