import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Formik, Form } from 'formik';
import { CircularProgress } from '@material-ui/core';

import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import { ErrorNotification, Paper, FormikFormField, Typography, Tooltip, Button } from '../../../components/UI/FB';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES, BUTTON_VARIANTS, API_METHODS } from '../../../utils/Constants';
import styles from '../Settings.module.scss';

const ContactCard = ({
  sendDatadogError,
  cardName,
  supplierContactName,
  supplierContact,
  updateSupplierDetails,
  createNotification,
  innerRef,
  pulse,
  keys,
}) => {
  const [editMode, setEditMode] = useState(false);
  const triggerEditMode = () => setEditMode(true);
  const triggerViewMode = (callback) => {
    setEditMode(false);
    if (callback) {
      callback();
    }
  };

  const mapAPIErrorsToFormFields = (errors) => {
    const formErrors = {};
    errors.forEach((e) => {
      formErrors[e.param] = e.message;
    });
    return formErrors;
  };

  const shouldUpdateValues = (values) =>
    keys.some((key) => !supplierContact?.[key] || values[key] !== supplierContact?.[key]);

  const buildContactDetails = () => {
    const isInvalid = !keys.some((key) => supplierContact?.[key]);
    if (isInvalid) {
      return <span>None provided</span>;
    }
    return (
      <div>
        <div>
          <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD}>
            {supplierContact?.firstName}&nbsp;{supplierContact?.lastName}
          </Typography>
        </div>
        <div>
          <Typography type={TYPOGRAPHY_TYPES.BODY}>
            {supplierContact?.email}&nbsp;|&nbsp;{supplierContact?.phone}
          </Typography>
        </div>
      </div>
    );
  };

  const handleSubmit = (values, actions) => {
    actions.setStatus({ apiError: undefined });
    const detailsToUpdate = {
      supplierContactName: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        contactDays: values.contactDays,
        contactHours: values.contactHours,
        contactTypeId: supplierContact.contactTypeId,
      },
    };

    const apiMethod = supplierContact?.id ? API_METHODS.PATCH : API_METHODS.POST;
    const endpoint = supplierContact?.id ? `suppliers/contacts/${supplierContact.id}` : 'suppliers/contacts';

    if (!shouldUpdateValues(values)) {
      triggerViewMode();
      createNotification({
        type: NOTIFICATION_TYPES.WARNING,
        content: 'Nothing to update',
        timeout: 4000,
        closable: true,
      });
      actions.setSubmitting(false);
    } else {
      FoodbombAPI[apiMethod](endpoint, { ...detailsToUpdate.supplierContactName })
        .then((response) => {
          updateSupplierDetails({ [supplierContactName]: response.data });
          triggerViewMode();
          createNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            content: `Successfully updated contact`,
            timeout: 4000,
            closable: true,
          });
          actions.setSubmitting(false);
        })
        .catch((error) => {
          if (error?.response?.status === 400 && error?.response?.data?.errors) {
            createNotification({
              type: NOTIFICATION_TYPES.ERROR,
              content: 'Unable to update contact',
              timeout: 4000,
              closable: true,
            });
            actions.setErrors(mapAPIErrorsToFormFields(error.response.data.errors));
            if (error?.response?.data?.errors?.[0]?.param === 'contactTypeId') {
              sendDatadogError('Unable to update contact - invalid contactTypeId', {
                error,
                location: 'Settings Page',
              });
            }
          } else {
            sendDatadogError('Unable to update contact', {
              error,
              location: 'Settings Page',
            });
            createNotification({
              type: NOTIFICATION_TYPES.ERROR,
              content: 'Unable to update contact',
              timeout: 4000,
              closable: true,
            });
            actions.setStatus({
              apiError: (
                <ErrorNotification
                  body={"We were unable to update your support contact. Don't worry, our engineers have been notified!"}
                  className={styles.ErrorNotification}
                />
              ),
            });
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  };

  const contactInitialValues = () => ({
    firstName: supplierContact?.firstName || '',
    lastName: supplierContact?.lastName || '',
    email: supplierContact?.email || '',
    phone: supplierContact?.phone || '',
    contactDays: supplierContact?.contactDays || '',
    contactHours: supplierContact?.contactHours || '',
  });

  const contactValidationSchema = () =>
    Yup.object({
      firstName: Yup.string().trim().required('First name is required'),
      lastName: Yup.string().trim().required('Last name is required'),
      email: Yup.string().email('Must be a valid email address').trim().required('Email is required'),
      phone: Yup.string()
        .trim()
        .matches(
          /^(([+]61)|([(]?0[2-57-8][)]?))?([-. ]?[1-9]{1})?([-. ]?[0-9]{4}){2}|([-. ]?[0-9]{3}){3}$/,
          'Must be a valid phone number',
        )
        .required('Phone number is required'),
      contactDays: Yup.string().trim(),
      contactHours: Yup.string().trim(),
    });

  const showTick = (values, errors) =>
    editMode ? !keys.some((key) => errors[key] || !values[key]) : keys.every((key) => supplierContact?.[key]);

  const showErrorIcon = (touched, errors) => (editMode ? keys.some((key) => touched[key] && errors[key]) : false);

  return (
    <div ref={innerRef}>
      <Paper className={pulse ? [styles.SettingsCard, styles.pulse].join(' ') : styles.SettingsCard}>
        <Formik
          initialValues={contactInitialValues()}
          initialStatus={{
            apiError: undefined,
          }}
          validationSchema={contactValidationSchema()}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched, values, status, handleReset }) => (
            <React.Fragment>
              <CheckCircleOutlineIcon
                className={
                  showTick(values, errors)
                    ? styles.SettingsCard__completeIcon
                    : [styles.SettingsCard__completeIcon, styles.hide].join(' ')
                }
              />
              <ErrorOutlineIcon
                className={
                  showErrorIcon(touched, errors)
                    ? styles.SettingsCard__invalidIcon
                    : [styles.SettingsCard__invalidIcon, styles.hide].join(' ')
                }
              />
              <Tooltip title="This is an admin only option" placement="top">
                <React.Fragment>
                  <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.SettingsCard__sectionHeading}>
                    {cardName}
                  </Typography>
                </React.Fragment>
              </Tooltip>
              {editMode ? (
                <div>
                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__description}>
                    This is the person we will contact in the event that a customer has any issues (i.e. delivery,
                    refunds, replacements etc)
                  </Typography>
                  <div>
                    <Form>
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="firstName"
                          touched={touched}
                          errors={errors}
                          placeholder={`${cardName} first name`}
                          label="First Name"
                        />
                      </div>
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="lastName"
                          touched={touched}
                          errors={errors}
                          placeholder={`${cardName} last name`}
                          label="Last Name"
                        />
                      </div>
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="email"
                          touched={touched}
                          errors={errors}
                          placeholder={`${cardName} email`}
                          label="Email"
                          fieldProps={{
                            type: 'email',
                          }}
                        />
                      </div>
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="phone"
                          touched={touched}
                          errors={errors}
                          placeholder={`${cardName} phone number`}
                          label="Phone"
                        />
                      </div>
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="contactHours"
                          touched={touched}
                          errors={errors}
                          placeholder={`${cardName} hours. i.e. 9am - 5pm`}
                          label="Contact Hours"
                        />
                      </div>
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="contactDays"
                          touched={touched}
                          errors={errors}
                          placeholder={`${cardName} days. i.e. Mon - Fri`}
                          label="Contact Days"
                        />
                      </div>

                      {status.apiError ? <div className={styles.ErrorMessageContainer}>{status.apiError}</div> : null}
                      <div className={styles.SettingsCard__editBtnContainer}>
                        <Button
                          type="button"
                          disabled={isSubmitting}
                          variant={BUTTON_VARIANTS.SECONDARY}
                          onClick={() => triggerViewMode(handleReset)}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" disabled={isSubmitting}>
                          {isSubmitting ? (
                            <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                          ) : (
                            <React.Fragment>Update Details</React.Fragment>
                          )}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  {buildContactDetails()}
                  <div className={styles.SettingsCard__editBtnContainer}>
                    <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={triggerEditMode} className={styles.EditBtn}>
                      Edit Details
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

ContactCard.propTypes = {
  cardName: PropTypes.string.isRequired,
  supplierContactName: PropTypes.string.isRequired,
  supplierContact: PropTypes.object.isRequired,
  updateSupplierDetails: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  pulse: PropTypes.bool,
  keys: PropTypes.array.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
};

export default withErrorReports(withNotifications(ContactCard));
