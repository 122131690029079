import { FC, useCallback, useEffect, useState } from 'react';
import Moment from 'moment';
import { PresentCountdownTime } from '../../utils/Presenters/TimePresenters';

type CountdownTimerProps = {
  endDate: Date;
  decrementInterval: number;
  onPercentageCompleteChange: (n: number) => void;
};

const CountdownTimer: FC<CountdownTimerProps> = ({ endDate, decrementInterval, onPercentageCompleteChange }) => {
  const [timeRemaining, setTimeRemaining] = useState<number>(endDate.getTime() - new Date().getTime());
  const [totalTime] = useState<number>(endDate.getTime() - new Date().getTime());

  const calculatePercentageComplete = useCallback(() => {
    const percentageComplete = (timeRemaining / totalTime) * 100;

    if (onPercentageCompleteChange) {
      onPercentageCompleteChange(percentageComplete);
    }
  }, [onPercentageCompleteChange, timeRemaining, totalTime]);

  const updateTimeDifference = useCallback(() => {
    const now = new Date();

    const differenceBetweenEndDateAndNow = endDate.getTime() - now.getTime();

    if (differenceBetweenEndDateAndNow > decrementInterval) {
      setTimeRemaining(differenceBetweenEndDateAndNow);
    } else {
      setTimeRemaining(0);
    }
    calculatePercentageComplete();
  }, [calculatePercentageComplete, decrementInterval, endDate]);

  useEffect(() => {
    setInterval(updateTimeDifference, decrementInterval);
  }, [updateTimeDifference, decrementInterval]);

  const timeRemainingInSeconds = timeRemaining / 1000;
  const timeString = PresentCountdownTime(timeRemainingInSeconds);

  return <>{timeRemainingInSeconds < 60 * 60 * 24 ? `${timeString}` : `${Moment(endDate).from(Moment())}`}</>;
};

export default CountdownTimer;
