import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress, Dialog, DialogContent, IconButton, DialogTitle } from '@material-ui/core';
import { startOfTomorrow, format } from 'date-fns';

import DatePickerCalendar from '../DatePicker/DatePickerCalender';
import { Button, Typography, ErrorNotification } from '../UI/FB';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import { BUTTON_VARIANTS, TYPOGRAPHY_TYPES, NOTIFICATION_TYPES } from '../../utils/Constants';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import styles from './DownloadProductsForDeliveryDayDialog.module.scss';

const DownloadProductsForDeliveryDayDialog = ({ isOpen, handleClose, sendDatadogError, createNotification }) => {
  const [selectedDate, setSelectedDate] = useState(startOfTomorrow());
  const [downloadingCSV, setDownloadCSV] = useState(false);
  const [APIError, setAPIError] = useState(undefined);

  const handleSelectDate = (date) => setSelectedDate(date);

  const downloadProductsForDeliveryDayCSV = async () => {
    setAPIError(undefined);
    setDownloadCSV(true);

    const formattedSelectedDate = format(selectedDate, 'yyyy-MM-dd');
    try {
      const fileName = `${formattedSelectedDate}-products.csv`;
      const response = await FoodbombAPI.get(
        `suppliers/csv/custom?template=luxe&deliveryDate=${formattedSelectedDate}&format=csv&filename=${fileName}&case=pascal`,
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      createNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        content: 'Successfully downloaded products for delivery CSV',
        timeout: 4000,
        closable: true,
      });
    } catch (error) {
      sendDatadogError('Unable to download products for delivery day CSV (Custom LUXE)', {
        error,
        location: 'DownloadProductsForDeliveryDayDialog',
      });
      createNotification({
        type: NOTIFICATION_TYPES.ERROR,
        content: 'Unable to download products for delivery day CSV',
        timeout: 3000,
        closable: true,
      });
      setAPIError(<ErrorNotification body={'We were unable to download the CSV. Our engineers have been notified!'} />);
    }
    setDownloadCSV(false);
  };

  return (
    <Dialog
      open={isOpen}
      keepMounted={false}
      onClose={handleClose}
      classes={{ root: styles.DownloadProductsDialog__root, paper: styles.DownloadProductsDialog__paper }}
    >
      <IconButton aria-label="close" onClick={handleClose} className={styles.CloseBtn}>
        <CloseIcon />
      </IconButton>
      <DialogTitle id="products-csv-dialog" className={styles.DownloadProductsDialog__title}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Products for Delivery Date CSV</Typography>
      </DialogTitle>
      <DialogContent className={styles.DownloadProductsDialog__content}>
        <DatePickerCalendar date={selectedDate} onDateChange={handleSelectDate} />
        <div className={styles.ErrorContainer}>{APIError}</div>
        <div className={styles.DownloadBtnContainer}>
          <Button
            disabled={downloadingCSV}
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={downloadProductsForDeliveryDayCSV}
            className={styles.DownloadBtn}
          >
            {downloadingCSV ? (
              <React.Fragment>
                <CircularProgress thickness={3} size={22} className={styles.SubmitLoadingSpinner} />
                &nbsp;&nbsp;Downloading...
              </React.Fragment>
            ) : (
              <React.Fragment>Download CSV</React.Fragment>
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

DownloadProductsForDeliveryDayDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
};

export default withNotifications(withErrorReports(DownloadProductsForDeliveryDayDialog));
