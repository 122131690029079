import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Typography,
  Emoji,
  FormikFormField,
  FormikSingleSelectField,
  Button,
  ErrorNotification,
} from '../../../../components/UI/FB';
import withNotifications from '../../../../hoc/withNotifications/withNotifications';
import withErrorReports from '../../../../hoc/withErrorReports/withErrorReports';
import { DeliveryPreferencesAPI } from '../../../../utils/AxiosInstances';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES, TIMEZONE_OPTIONS, STATE_TO_TIMEZONE } from '../../../../utils/Constants';
import { CUTOFF_TIMES } from '../../../../utils/TimeConstants';
import styles from './EditDeliveryDetailsModal.module.scss';

const EditDeliveryDetailsModal = ({
  deliveryZone,
  createNotification,
  sendDatadogError,
  refetchZoneData,
  handleClose,
  isOpen,
}) => {
  const updateZoneDetails = (values, actions) => {
    const newValues = {
      cutoff: values.cutoff,
      minimumOrderTotal: values.minimumOrderTotal * 100,
      timeZone: values.timeZone,
    };

    if (
      newValues.cutoff !== deliveryZone.cutoff ||
      newValues.minimumOrderTotal !== deliveryZone.minimumOrderTotal ||
      newValues.timeZone !== deliveryZone.timeZone
    ) {
      DeliveryPreferencesAPI.patch(`zones/${deliveryZone.id}`, newValues)
        .then(() => {
          createNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            content: 'Successfully updated zone',
            timeout: 4000,
            closable: true,
          });
          refetchZoneData();
          handleClose();
        })
        .catch((error) => {
          if (error?.response?.status === 400 && error?.response?.data) {
            actions.setErrors(error.response.data);
          } else {
            sendDatadogError('Unable to update delivery zone details', {
              error,
              location: 'Edit Delivery Zone Details Modal',
            });
            actions.setStatus({
              apiError: (
                <ErrorNotification body={'We were unable to update this zone. Our engineers have been notified!'} />
              ),
            });
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      actions.setSubmitting(false);
      handleClose();
    }
  };

  return (
    <Dialog
      disableBackdropClick
      open={isOpen}
      keepMounted={false}
      onClose={handleClose}
      classes={{ root: styles.EditDeliveryDetailsModal__root, paper: styles.EditDeliveryDetailsModal__paper }}
    >
      <IconButton aria-label="close" onClick={handleClose} className={styles.CloseBtn}>
        <CloseIcon />
      </IconButton>
      <DialogTitle className={styles.EditDeliveryDetailsModal__title}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
          <Emoji content="✏️" label="pencil" />
          &nbsp;Edit Delivery Details
        </Typography>
      </DialogTitle>
      <DialogContent className={styles.EditDeliveryDetailsModal__content}>
        <Formik
          initialValues={{
            cutoff: CUTOFF_TIMES.find((ct) => ct.id === deliveryZone.cutoff).id,
            minimumOrderTotal: deliveryZone.minimumOrderTotal / 100,
            timeZone: TIMEZONE_OPTIONS.find((tz) => tz.id === deliveryZone.timeZone).id,
          }}
          onSubmit={updateZoneDetails}
          validationSchema={Yup.object({
            minimumOrderTotal: Yup.number()
              .required('Minimum order is Required')
              .min(0, 'Minimum order cannot be negative'),
            cutoff: Yup.string()
              .oneOf(CUTOFF_TIMES.map((cutoff) => cutoff.id))
              .required('A cutoff time is required'),
            timeZone: Yup.string()
              .oneOf(TIMEZONE_OPTIONS.map((tz) => tz.id))
              .required('A time zone is required'),
          })}
        >
          {({ errors, touched, isSubmitting, values, setTouched, setFieldValue, status }) => (
            <Form>
              <div className={styles.InputRow}>
                <div className={styles.Input__fullWidth}>
                  <FormikSingleSelectField
                    label="Cutoff Time"
                    fieldName="cutoff"
                    errors={errors}
                    touched={touched}
                    value={CUTOFF_TIMES.find((cutoffTime) => cutoffTime.id === values.cutoff) || ''}
                    options={CUTOFF_TIMES}
                    placeholder="Select a cutoff time"
                    setTouched={setTouched}
                    setFieldValue={setFieldValue}
                    maxMenuHeight={200}
                  />
                </div>
                <div className={styles.Input__fullWidth}>
                  <FormikSingleSelectField
                    label="Time Zone"
                    fieldName="timeZone"
                    errors={errors}
                    touched={touched}
                    value={TIMEZONE_OPTIONS.find((tz) => tz.id === values.timeZone) || ''}
                    options={TIMEZONE_OPTIONS}
                    placeholder="Select a time zone"
                    setTouched={setTouched}
                    setFieldValue={setFieldValue}
                    maxMenuHeight={200}
                  />
                </div>
                <div className={styles.Input__fullWidth}>
                  <FormikFormField
                    fieldName={'minimumOrderTotal'}
                    touched={touched}
                    errors={errors}
                    label="Minimum Order ($)"
                    placeholder="Enter your minimum order"
                    fieldProps={{
                      type: 'number',
                      step: '1.0',
                    }}
                  />
                </div>
              </div>
              <div className={styles.ErrorContainer}>{status?.apiError}</div>
              <div className={styles.SubmitBtn__container}>
                <Button type="submit" disabled={isSubmitting || Boolean(errors && Object.keys(errors).length)}>
                  {isSubmitting ? (
                    <CircularProgress thickness={3} size={24} className={styles.ButtonLoadingSpinner} />
                  ) : (
                    <React.Fragment>Save Details</React.Fragment>
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

EditDeliveryDetailsModal.propTypes = {
  deliveryZone: PropTypes.shape({
    id: PropTypes.number.isRequired,
    cutoff: PropTypes.oneOf(CUTOFF_TIMES.map((cutoff) => cutoff.id)),
    minimumOrderTotal: PropTypes.number.isRequired,
    timeZone: PropTypes.oneOf(Object.values(STATE_TO_TIMEZONE)).isRequired,
  }).isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refetchZoneData: PropTypes.func.isRequired,
};

export default withErrorReports(withNotifications(EditDeliveryDetailsModal));
