import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import NavigationContext from './NavigationContext';

class NavigationProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referrer: undefined,
      hideMasqueradingBanner: true,
    };
  }

  componentDidMount() {
    this.potentiallyExtractReferrer();
  }

  potentiallyExtractReferrer = () => {
    const ordersFilterQuery = queryString.parse(this.props.location.search);
    if (Object.keys(ordersFilterQuery).includes('referrer')) this.setState({ referrer: ordersFilterQuery.referrer });
  };

  onHideMasqueradingBanner = () => {
    this.setState((prevState) => ({
      hideMasqueradingBanner: !prevState.hideMasqueradingBanner,
    }));
  };

  render() {
    const { children } = this.props;
    const { referrer, hideMasqueradingBanner } = this.state;

    return (
      <NavigationContext.Provider
        value={{
          referrer,
          hideMasqueradingBanner,
          onHideMasqueradingBanner: this.onHideMasqueradingBanner,
        }}
      >
        {children}
      </NavigationContext.Provider>
    );
  }
}

NavigationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(NavigationProvider);
