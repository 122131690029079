import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, IconButton, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './ProductImageDialog.module.scss';

const ProductImageDialog = ({ isOpen, handleClose, productImageURL }) => (
  <Dialog
    open={isOpen}
    keepMounted={false}
    onClose={handleClose}
    aria-labelledby="product-image-dialog"
    classes={{ root: styles.ProductImageDialogRoot, paper: styles.ProductImageDialogPaper }}
  >
    <DialogTitle id="wholesaler-dialog" className={styles.ProductImageDialog__title}>
      <IconButton aria-label="close" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <img className={styles.ProductImage} alt="productImage" src={productImageURL} />
    </DialogContent>
  </Dialog>
);

ProductImageDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  productImageURL: PropTypes.string,
};

export default ProductImageDialog;
