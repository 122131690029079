import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Button, Typography } from '../UI/FB';
import LargeLoadingSpinner from '../UI/LargeLoadingSpinner/LargeLoadingSpinner';
import {
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  TYPOGRAPHY_TYPES,
  ACTIVE_VENUE_STATUS,
  PERSONAL_VENUE_TYPE,
} from '../../utils/Constants';
import withRedirectHelper from '../../hoc/withRedirectHelper/withRedirectHelper';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import { presentCurrency } from '../../utils/Presenters/PresentCurrency/PresentCurrency';
import CustomPriceDialog from '../CustomPriceDialog/CustomPriceDialog';
import styles from './MultipleProductsStepper.module.scss';

const StepTwo = ({
  numberOfSuccessfulProducts,
  addProductsMode,
  productsWithCustomPricing,
  redirectToProducts,
  sendDatadogError,
}) => {
  const [selectedCustomPriceProduct, setSelectedCustomPriceProduct] = useState(undefined);
  const [venues, setVenues] = useState([]);
  const [updatedProductIds, setUpdatedProductIds] = useState([]);
  const [loading, setLoading] = useState(true);

  const hasSelectedCustomPriceProduct = Boolean(selectedCustomPriceProduct);
  const hasProductsWithCustomPricing = productsWithCustomPricing.length > 0;

  const confirmationMsg = (successfulProductsNum, addMode) =>
    addMode ? `saved ${successfulProductsNum} new` : `edited`;

  const handleViewAllProductsClick = () => redirectToProducts();

  const handleCustomPriceClick = (product) => {
    setSelectedCustomPriceProduct(product);
  };

  const handleCloseCustomPriceDialog = () => {
    setSelectedCustomPriceProduct(undefined);
  };

  useEffect(() => {
    const buildSearchLabelForVenue = (venue) => `${venue.venue} (${venue.id})`;

    FoodbombAPI.get('/venues', { credentials: 'include' })
      .then((response) => {
        setVenues(
          response.data.venues
            .map((venue) => ({
              ...venue,
              label: buildSearchLabelForVenue(venue),
              value: venue.id,
            }))
            .filter((c) => c.status === ACTIVE_VENUE_STATUS && c.venueType !== PERSONAL_VENUE_TYPE),
        );
      })
      .catch((error) => {
        sendDatadogError('Unable to fetch venues for supplier', {
          error,
          location: 'Step two bulk upload',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [sendDatadogError]);

  const handleUpdateCustomPricesForProduct = (product) => {
    setUpdatedProductIds((prev) => [...prev, product.id]);
  };

  return (
    <div className={styles.MultipleProductsContainer__confirmationContainer}>
      <Paper className={styles.MultipleProductsContainer__confirmationContainer_paper}>
        {loading ? (
          <LargeLoadingSpinner small loadingMessage="Loading Results..." />
        ) : (
          <>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_S} className={styles.UploadSuccessMessage}>
              Nice one! You have successfully {confirmationMsg(numberOfSuccessfulProducts, addProductsMode)}&nbsp;
              <span className={styles.MultipleProductsContainer__text_foodBombGreen_bold}>
                {numberOfSuccessfulProducts}
              </span>
              &nbsp;product{numberOfSuccessfulProducts > 1 ? 's' : ''} on Foodbomb!
            </Typography>
            {hasProductsWithCustomPricing ? (
              <div className={styles.CustomPriceList__container}>
                <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.CustomPriceList__heading}>
                  Some of the products you updated also have custom prices that may need updating. Please review the
                  custom prices below.
                </Typography>
                <ul className={styles.ProductsWithCustomPriceList}>
                  {productsWithCustomPricing.map((p) => (
                    <li key={p.id} className={styles.CustomPriceLi}>
                      <Typography type={TYPOGRAPHY_TYPES.BODY}>
                        {p.name} is now {presentCurrency(p.priceInCents / 100)}
                        <button
                          type="button"
                          onClick={() => handleCustomPriceClick(p)}
                          className={
                            updatedProductIds.includes(p.id)
                              ? [styles.EditCustomPriceBtn, styles.Grey].join(' ')
                              : styles.EditCustomPriceBtn
                          }
                        >
                          {updatedProductIds.includes(p.id) ? '✅ View' : 'Update'}&nbsp;{p.customPricing.length}{' '}
                          existing custom prices
                        </button>
                      </Typography>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <span className={styles.MultipleProducts__cta_container}>
              <Button
                onClick={handleViewAllProductsClick}
                className={styles.MultipleProductsContainer__btn}
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTON_SIZES.SMALL}
              >
                Return to all Products
              </Button>
            </span>
          </>
        )}
      </Paper>
      {hasSelectedCustomPriceProduct ? (
        <CustomPriceDialog
          isOpen={true}
          handleClose={handleCloseCustomPriceDialog}
          product={selectedCustomPriceProduct}
          venues={venues}
          updateProductData={handleUpdateCustomPricesForProduct}
        />
      ) : null}
    </div>
  );
};

StepTwo.propTypes = {
  numberOfSuccessfulProducts: PropTypes.number,
  addProductsMode: PropTypes.bool.isRequired,
  productsWithCustomPricing: PropTypes.array,
  redirectToProducts: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
};

export default withErrorReports(withRedirectHelper(StepTwo));
