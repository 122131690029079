import { combineReducers } from 'redux';

import AuthReducer from './AuthReducer';
import NotificationsReducer from './NotificationsReducer';
import ProductImageReducer from './ProductImageReducer';
import VersionsReducer from './VersionsReducer';

export default combineReducers({
  notifications: NotificationsReducer,
  auth: AuthReducer,
  images: ProductImageReducer,
  versions: VersionsReducer,
});
