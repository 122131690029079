import { FC, useCallback, useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { Form, Formik } from 'formik';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import * as Yup from 'yup';
import {
  Button,
  ErrorNotification,
  FormikFormField,
  FormikSingleSelectField,
  Typography,
} from '../../../components/UI/FB';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import {
  ACTIVE_VENUE_STATUS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
  NOTIFICATION_TYPES,
  PERSONAL_VENUE_TYPE,
  TYPOGRAPHY_TYPES,
} from '../../../utils/Constants';
import styles from './RakeDialog.module.scss';
import useDDErrorReporting from '../../../hooks/useDDErrorReporting/useDDErrorReporting';
import useNotifications from '../../../hooks/useNotifications/useNotifications';

type APIValues = {
  venueId: number;
  customRake: number;
};

type APIVenue = {
  id: number;
  status: string;
  venue: string;
  venueType: string;
};

export interface CustomerDataType {
  venueId: number;
  venueCRN: string;
  venueName: string;
  venueGroup: string;
  ownerName: string;
  postcode: number;
  suburb: string;
  commission: number;
  customPrices: number;
  totalSpent: number;
  orderCount: number;
  lastOrdered: string;
  customCommissionExists: boolean;
}

type RakeDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  existingVenue: CustomerDataType | null | undefined;
  onSuccessfulCustomRakeCall: () => void;
};

interface VenueDataType {
  id: number;
  label: string;
  status: string;
  value: number;
  venue: string;
  venueType: string;
}

const RakeDialog: FC<RakeDialogProps> = ({ isOpen, handleClose, existingVenue, onSuccessfulCustomRakeCall }) => {
  const [venues, setVenues] = useState<VenueDataType[]>([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [loadingVenues, setLoadingVenues] = useState<boolean>(true);
  const [venuesError, setVenuesError] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { sendDatadogError } = useDDErrorReporting();
  const { createNotification } = useNotifications();

  const customCommissionValidationSchema = Yup.object().shape({
    customRake: Yup.number()
      .test('maxDecimalPlaces', 'Rake must have no more than two decimal places', (value) => {
        if (value) {
          return Number(value.toFixed(2)) === value;
        }
        return true;
      })
      .min(0, 'Rake must be greater then 0%')
      .max(100, 'Rake must be less then 100%')
      .required('Rake is required'),
  });

  const attemptToCreateVenueRake = (values: APIValues, actions: any) => {
    FoodbombAPI.post('/suppliers/custom-commission', {
      venueId: existingVenue ? existingVenue.venueId : values.venueId,
      customCommission: values.customRake,
    })
      .then(() => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully created custom rake',
          timeout: 4000,
          closable: true,
        });
        onSuccessfulCustomRakeCall();
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setErrorMessage(error.response.data);
          actions.setSubmitting(false);
        }

        sendDatadogError(
          `Unable to create a custom commission for Venue #${existingVenue ? existingVenue.venueId : values.venueId}`,
          {
            error,
            location: 'Customer Page',
          },
        );
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to create new custom commission',
          timeout: 4000,
          closable: true,
        });
      });
  };

  const attemptToChangeVenueRake = (values: APIValues) => {
    if (!existingVenue) return;

    FoodbombAPI.patch('/suppliers/custom-commission', {
      venueId: existingVenue.venueId,
      customCommission: values.customRake,
    })
      .then(() => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully updated custom rake',
          timeout: 4000,
          closable: true,
        });
        onSuccessfulCustomRakeCall();
      })
      .catch((error) => {
        sendDatadogError(`Unable to change a custom commission for Venue #${existingVenue?.venueId}`, {
          error,
          location: 'Customer Page',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to change new custom commission',
          timeout: 4000,
          closable: true,
        });
      });
  };

  const filterVenuesBySearch = (query: string) => {
    const numberOfResults = 15;

    if (query) {
      return venues
        .filter((c: VenueDataType) => c.label.toLowerCase().includes(query.toLowerCase()))
        .slice(0, numberOfResults);
    }

    return venues.slice(0, numberOfResults);
  };

  const buildSearchLabelForVenue = useCallback((venue) => `#${venue.id}, ${venue.venue}`, []);

  const loadVenues = useCallback(() => {
    setLoadingVenues(true);
    setVenuesError(undefined);
    FoodbombAPI.get('/venues', { credentials: 'include' })
      .then((response) => {
        setVenues(
          response.data.venues
            .map((venue: APIVenue) => ({
              ...venue,
              label: buildSearchLabelForVenue(venue),
              value: venue.id,
            }))
            .filter((c: APIVenue) => c.status === ACTIVE_VENUE_STATUS && c.venueType !== PERSONAL_VENUE_TYPE),
        );
      })
      .catch((error) => {
        sendDatadogError('Unable to fetch venues for supplier', {
          error,
          location: 'Create custom commission page',
        });
        setVenuesError('Unable to load venues');
      })
      .finally(() => {
        setLoadingVenues(false);
      });
  }, [buildSearchLabelForVenue, sendDatadogError]);

  useEffect(() => {
    loadVenues();
  }, [loadVenues]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ root: styles.RakeDialogRoot, paper: styles.RakeDialogPaper }}
      disableEscapeKeyDown
    >
      <DialogTitle className={styles.AddCustomCommissionDialog__header}>
        {!showConfirmDialog ? (
          <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
            {existingVenue ? 'Edit Custom Rake' : 'Create Custom Rake'}
          </Typography>
        ) : null}
        {!showConfirmDialog ? (
          <IconButton className={styles.RakeDialog__closeBtn} aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={styles.RakeDialog__content}>
        <div>
          {existingVenue && !showConfirmDialog ? (
            <Typography type={TYPOGRAPHY_TYPES.BODY}>
              {`Customise the rake for ${existingVenue.venueName} (${existingVenue.venueId})`}
            </Typography>
          ) : null}
          {!existingVenue && !showConfirmDialog ? (
            <Typography type={TYPOGRAPHY_TYPES.BODY}>Search for the venue you wish to give a custom rake</Typography>
          ) : null}
          {!existingVenue && venuesError ? (
            <div className={styles.APIErrorContainer}>
              <ErrorNotification />
              {errorMessage}
            </div>
          ) : (
            <Formik
              initialValues={{
                customRake: existingVenue ? existingVenue.commission : 0,
                venueId: existingVenue ? existingVenue.venueId : 0,
              }}
              onSubmit={existingVenue?.customCommissionExists ? attemptToChangeVenueRake : attemptToCreateVenueRake}
              initialStatus={{
                apiError: undefined,
              }}
              validationSchema={customCommissionValidationSchema}
            >
              {({ validateForm, isSubmitting, setTouched, setFieldValue, values, touched, errors }) => (
                <Form>
                  <div className={styles.CustomCommissionForm}>
                    {!existingVenue && !showConfirmDialog ? (
                      <div className={styles.InputRow}>
                        <div className={styles.FullWidthInputContainer}>
                          <FormikSingleSelectField
                            isMulti={false}
                            value={venues.find((venue: VenueDataType) => venue.id === values.venueId)}
                            errors={errors}
                            touched={touched}
                            setTouched={setTouched}
                            label="Venue"
                            setFieldValue={setFieldValue}
                            fieldName="venueId"
                            cacheOptions
                            defaultOptions
                            loadOptions={AwesomeDebouncePromise(filterVenuesBySearch, 250)}
                            placeholder={
                              loadingVenues ? 'Loading Venues...' : 'Choose from all venues (Search by venue or ID)'
                            }
                            loadingMessage={() => 'Searching for venues...'}
                            maxMenuHeight={180}
                            isDisabled={loadingVenues}
                            options={venues}
                          />
                        </div>
                      </div>
                    ) : null}
                    {showConfirmDialog ? (
                      <div>
                        <div className={styles.ConfirmDialog_header}>
                          <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Confirm custom rake</Typography>
                        </div>
                        <br />
                        {errorMessage ? (
                          <div className={styles.APIErrorMessage}>
                            <Typography className={styles.APIErrorMessage__text} type={TYPOGRAPHY_TYPES.BODY}>
                              <strong>Error:</strong>
                              {errorMessage}
                            </Typography>
                          </div>
                        ) : null}
                        <div className={styles.ConfirmDialog_text}>
                          <Typography type={TYPOGRAPHY_TYPES.BODY}>You are changing the rake for&nbsp;</Typography>
                          <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD}>
                            {`${
                              existingVenue
                                ? existingVenue.venueName
                                : venues.find((venue: VenueDataType) => venue.id === values.venueId)?.venue
                            } ${
                              existingVenue
                                ? ` (${existingVenue.venueId})`
                                : ` (${venues.find((venue: VenueDataType) => venue.id === values.venueId)?.id})`
                            } to ${values.customRake}%`}
                          </Typography>
                          <br />
                        </div>
                        <br />
                        <div className={styles.ConfirmDialog_confirmText}>
                          <Typography type={TYPOGRAPHY_TYPES.BODY}>Please confirm to proceed.</Typography>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.InputRow}>
                        <div className={[styles.FullWidthInputContainer, styles.withAnnotations].join(' ')}>
                          <FormikFormField
                            fieldName="customRake"
                            touched={touched}
                            errors={errors}
                            placeholder="Enter a custom rake, e.g 5"
                            label="Custom Rake (%)"
                            fieldProps={{
                              type: 'number',
                              step: '0',
                              pattern: '^[0-9]*',
                            }}
                          />
                        </div>
                      </div>
                    )}

                    <div className={styles.CustomCommissionForm__actions}>
                      {showConfirmDialog ? (
                        <div className={styles.ConfirmDialog_actions}>
                          <Button
                            variant={BUTTON_VARIANTS.SECONDARY}
                            size={BUTTON_SIZES.SMALL}
                            onClick={handleClose}
                            type="button"
                            disabled={isSubmitting}
                            className={styles.CancelBtn}
                          >
                            Cancel
                          </Button>
                          <Button type="submit" disabled={isSubmitting} className={styles.SubmitBtn}>
                            {isSubmitting ? (
                              <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                            ) : (
                              <>Confirm</>
                            )}
                          </Button>
                        </div>
                      ) : (
                        <Button
                          className={styles.SaveBtn}
                          type="button"
                          onClick={() => {
                            validateForm().then((result) => {
                              if (!Object.keys(result).length) {
                                setShowConfirmDialog(true);
                              }
                            });
                          }}
                        >
                          Save Custom Rake
                        </Button>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RakeDialog;
