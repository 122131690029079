import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CustomPrices from './CustomPrices';
import styles from './CustomPricingRoutes.module.scss';

const CustomPricingRoutes: FC = () => {
  const match = useRouteMatch();

  return (
    <div className={styles.CustomPriceRoutesContainer}>
      <Switch>
        <Route exact path={`${match.path}`} component={CustomPrices} />
      </Switch>
    </div>
  );
};

export default CustomPricingRoutes;
