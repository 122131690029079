import React from 'react';
import PropTypes from 'prop-types';
import { StatusChip } from '../FB';
import { STATUS_CHIP_TYPES } from '../../../utils/Constants';

import styles from './OnStatusChip.module.scss';

const OnStatusChip = ({ onStatus, className }) => (
  <StatusChip
    title={onStatus ? 'On' : 'Off'}
    type={onStatus ? STATUS_CHIP_TYPES.SUCCESS : STATUS_CHIP_TYPES.DEFAULT}
    hideIcon
    className={[styles.OnStatusChip, className].join(' ')}
  />
);

OnStatusChip.propTypes = {
  onStatus: PropTypes.bool,
  className: PropTypes.string,
};

export default OnStatusChip;
