import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Dialog, DialogContent, IconButton } from '@material-ui/core';
import { Form, Formik, ErrorMessage } from 'formik';
import debounce from 'es6-promise-debounce';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import SwitchField from '../../../../components/UI/SwitchField/SwitchField';
import {
  AlertDialog,
  Tooltip,
  Typography,
  Emoji,
  FormikSingleSelectField,
  Button,
  ErrorNotification,
} from '../../../../components/UI/FB';
import withNotifications from '../../../../hoc/withNotifications/withNotifications';
import withErrorReports from '../../../../hoc/withErrorReports/withErrorReports';
import { DeliveryPreferencesAPI } from '../../../../utils/AxiosInstances';
import {
  ALERT_TYPES,
  NOTIFICATION_TYPES,
  TYPOGRAPHY_TYPES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from '../../../../utils/Constants';

import styles from './EditDeliverySuburbsModal.module.scss';

const EditDeliverySuburbsModal = ({
  deliveryZone,
  createNotification,
  sendDatadogError,
  refetchZoneData,
  handleClose,
  isOpen,
}) => {
  const [postcodeToRemove, setPostcodeToRemove] = useState(undefined);
  const [loadingPostcodesAndSuburbs, setLoadingPostcodesAndSuburbs] = useState(false);
  const [isSearchingAValidQuery, setIsSearchingAValidQuery] = useState(false);
  const [canSave, setCanSave] = useState(true);

  const closeRemovePostcodeDialog = () => {
    setPostcodeToRemove(undefined);
  };

  const openRemovePostcodeDialog = (postcode) => {
    setPostcodeToRemove(postcode);
  };

  const suburbsByPostcodes = {};
  deliveryZone.suburbs.forEach((suburbObj) => {
    const { postcode } = suburbObj.suburb;
    const suburbName = suburbObj.suburb.name;
    const data = {
      [suburbName]: {
        enabled: suburbObj.enabled,
        blocked: suburbObj.blocked,
        suburb: suburbObj.suburb,
      },
    };
    suburbsByPostcodes[postcode] = { ...suburbsByPostcodes[postcode], ...data };
  });

  const formatErrorsFromApiResponse = (errorData) => {
    const errors = errorData
      .map((error) => ({
        postcode: Object.keys(error)[0]?.split(',')?.[1],
        suburb: Object.keys(error)[0]?.split(',')?.[0],
        errorMessage: Object.values(error)[0]?.[0],
      }))
      .filter((error) => error.postcode && error.suburb);

    const formErrors = {
      postcodes: {},
    };

    errors.forEach((error) => {
      formErrors.postcodes[error.postcode] = {
        ...formErrors[error.postcode],
        [error.suburb]: error.errorMessage,
      };
    });
    return formErrors;
  };

  const handleUpdateDeliverySuburbs = (values, actions) => {
    if (!canSave) {
      actions.setSubmitting(false);
      return;
    }
    const suburbValues = Object.values(values.postcodes)
      .map((val) => Object.values(val))
      .flat();

    DeliveryPreferencesAPI.put(`zones/${deliveryZone.id}/suburbs`, suburbValues)
      .then(() => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully updated suburbs',
          timeout: 4000,
          closable: true,
        });
        refetchZoneData();
        handleClose();
      })
      .catch((error) => {
        if (error?.response?.status === 400 && error?.response?.data) {
          if (Object.keys(error?.response?.data).includes('invalidActivation')) {
            createNotification({
              type: NOTIFICATION_TYPES.ERROR,
              content: error.response.data.invalidActivation,
              timeout: 4000,
              closable: true,
            });
          } else {
            const formErrors = formatErrorsFromApiResponse(error.response.data);
            actions.setErrors(formErrors);
          }
        } else {
          sendDatadogError('Unable to update delivery zone suburbs', {
            error,
            location: 'Edit Delivery Zone Suburbs Modal',
          });
          actions.setStatus({
            apiError: (
              <ErrorNotification
                body={'We were unable to update the delivery suburbs for this zone. Our engineers have been notified!'}
              />
            ),
          });
        }
        actions.setSubmitting(false);
      });
  };

  const searchPostcodesAndSuburbs = (query) => {
    const isPostcodeSearch = /^\d+$/.test(query);
    setIsSearchingAValidQuery(query?.length);

    setLoadingPostcodesAndSuburbs(true);
    return DeliveryPreferencesAPI.get(`/suburbs?search=${encodeURI(query)}`)
      .then((response) => {
        const resultsByPostcode = {};
        const results = response.data.map((res) => ({
          ...res,
          label: `${res.name} (${res.postcode}, ${res.state})`,
          value: `${res.name} (${res.postcode}, ${res.state})`,
          isPostcode: false,
        }));
        if (isPostcodeSearch) {
          results.forEach((res) => {
            resultsByPostcode[res.postcode] = { ...resultsByPostcode?.[res.postcode] } || {};
            resultsByPostcode[res.postcode].suburbs = resultsByPostcode[res.postcode]?.suburbs?.length
              ? resultsByPostcode[res.postcode]?.suburbs.concat(res.name)
              : [res.name];
            resultsByPostcode[res.postcode].postcode = res.postcode;
          });
        }
        const postcodeResultsToAppend = Object.values(resultsByPostcode)
          .filter((result) => result.suburbs.length > 1)
          .map((res) => ({
            ...res,
            label: (
              <React.Fragment>
                <strong>{res.postcode}</strong>&nbsp;({res.suburbs.join(', ')})
              </React.Fragment>
            ),
            value: `${res.postcode} (${res.suburbs.join(', ')})`,
            isPostcode: true,
          }));
        return postcodeResultsToAppend.concat(results);
      })
      .catch((error) => {
        sendDatadogError('Failed to search suburbs and postcodes', {
          query,
          error,
          location: 'Delivery Zones Edit Suburbs Modal',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Failed to search suburbs and postcodes. Please try again.',
          timeout: 4000,
          closable: true,
        });
      })
      .finally(() => {
        setLoadingPostcodesAndSuburbs(false);
      });
  };

  const removePostcodeFromRegion = (postcode, setFieldValue) => {
    setFieldValue(`postcodes.${postcode}`, undefined);
  };

  const appendPostcodeToZone = (postcode, setFieldValue, activateAllSuburbs, suburbsToActivate) => {
    DeliveryPreferencesAPI.post(`zones/${deliveryZone.id}/postcode/${postcode}`)
      .then((response) => {
        const postcodeValues = {};
        response.data.forEach((value) => {
          postcodeValues[value.suburb.name] = {
            ...value,
            enabled: !value.blocked && (activateAllSuburbs || suburbsToActivate.includes(value.suburb.name)),
          };
        });
        setFieldValue(`postcodes.${postcode}`, postcodeValues);
      })
      .catch((error) => {
        sendDatadogError('Failed to add a postcode to a region', {
          error,
          location: 'Delivery Zones Edit Suburbs Modal',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: `Unable to add ${postcode} to this region. Please try again.`,
          timeout: 4000,
          closable: true,
        });
      });
  };

  const enableSave = () => setCanSave(true);
  const disableSave = () => setCanSave(false);

  return (
    <Dialog
      disableBackdropClick
      open={isOpen}
      keepMounted={false}
      onClose={handleClose}
      classes={{ root: styles.EditDeliverySuburbsModal__root, paper: styles.EditDeliverySuburbsModal__paper }}
    >
      <Formik
        initialValues={{
          postcodes: suburbsByPostcodes,
          searchQuery: '',
        }}
        onSubmit={handleUpdateDeliverySuburbs}
        initialStatus={{
          apiError: undefined,
        }}
      >
        {({ errors, touched, values, status, setFieldValue, setTouched, isSubmitting }) => (
          <Form>
            <div className={styles.EditDeliverySuburbsModal__header}>
              <Typography type={TYPOGRAPHY_TYPES.HEADING_XL} styles={styles.EditDeliverySuburbsModal__title}>
                <Emoji content="✏️" label="pencil" />
                &nbsp;Edit Suburbs
              </Typography>
              <div className={styles.EditDeliverySuburbsModalHeader__actions}>
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTON_SIZES.SMALL}
                  onClick={handleClose}
                  type="button"
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTON_SIZES.SMALL}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress thickness={3} size={24} className={styles.ButtonLoadingSpinner} />
                  ) : (
                    <React.Fragment>Save</React.Fragment>
                  )}
                </Button>
              </div>
            </div>
            <DialogContent className={styles.EditDeliverySuburbsModal__content}>
              <div>
                <FormikSingleSelectField
                  fieldName={'searchQuery'}
                  isMulti={false}
                  isAsync
                  errors={errors}
                  touched={touched}
                  setTouched={setTouched}
                  value={values.searchQuery}
                  setFieldValue={setFieldValue}
                  onFocus={disableSave}
                  onBlur={enableSave}
                  customOnChange={(selectedValue) => {
                    setFieldValue('searchQuery', '');
                    enableSave();
                    const selectedPostcodeInZone = values.postcodes[selectedValue?.postcode] || {};
                    const currentEnabledSuburbsForPostcode = Object.values(selectedPostcodeInZone)
                      .filter((sub) => !sub.blocked && sub.enabled)
                      .map((sub) => sub.suburb.name);
                    if (selectedValue?.postcode) {
                      appendPostcodeToZone(
                        selectedValue.postcode,
                        setFieldValue,
                        selectedValue.isPostcode,
                        selectedValue.isPostcode ? [] : currentEnabledSuburbsForPostcode.concat(selectedValue.name),
                      );
                    }
                  }}
                  cacheOptions
                  isClearable
                  loadOptions={debounce(searchPostcodesAndSuburbs, 250)}
                  placeholder={loadingPostcodesAndSuburbs ? 'Searching ...' : 'Search for a suburb or postcode'}
                  noOptionsMessage={() =>
                    isSearchingAValidQuery ? 'No suburbs or postcodes found' : 'Enter suburb or postcode to search'
                  }
                  loadingMessage={() => 'Searching...'}
                  maxMenuHeight={250}
                  hideErrors
                />
              </div>
              <div>
                {Object.entries(values.postcodes).map(([postcode, suburbObjs]) => (
                  <div className={styles.PostcodeSectionCard} key={postcode}>
                    <Tooltip title={`Remove ${postcode}`}>
                      <IconButton
                        className={styles.PostcodeSectionCard__moreBtn}
                        onClick={() => openRemovePostcodeDialog(postcode)}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Tooltip>
                    <SwitchField
                      containerStyles={styles.PostcodeSwitchContainer}
                      disabled={Object.values(suburbObjs).some((sub) => sub.blocked)}
                      checked={Object.values(suburbObjs).every((sub) => sub.enabled)}
                      onChange={(e) => {
                        const postcodesInSubValues = values.postcodes[postcode];
                        Object.values(postcodesInSubValues).forEach((suburbObj) => {
                          postcodesInSubValues[suburbObj.suburb.name] = {
                            ...postcodesInSubValues[suburbObj.suburb.name],
                            enabled: postcodesInSubValues[suburbObj.suburb.name].blocked ? false : e.target.checked,
                          };
                        });
                        setFieldValue(`postcodes.${postcode}`, postcodesInSubValues);
                      }}
                      label={
                        <Typography
                          type={TYPOGRAPHY_TYPES.HEADING_M}
                          className={styles.PostcodeSectionCard__postcodeText}
                        >
                          {postcode}
                        </Typography>
                      }
                    />
                    {Object.entries(suburbObjs).map(([suburbName, suburbObj]) => (
                      <div className={styles.PostcodeSectionCard__suburb} key={suburbObj.suburb.name}>
                        <SwitchField
                          containerStyles={styles.SuburbSwitchContainer}
                          disabled={suburbObj.blocked}
                          checked={suburbObj.enabled}
                          onChange={(e) => {
                            setFieldValue(`postcodes.${postcode}.${suburbName}.enabled`, e.target.checked);
                          }}
                          label={
                            <Typography
                              type={TYPOGRAPHY_TYPES.BODY_BOLD}
                              className={styles.PostocdeSectionCard__suburbText}
                            >
                              {suburbObj?.suburb?.name}
                            </Typography>
                          }
                        />
                        {errors && errors?.postcodes?.[postcode]?.[suburbName] ? (
                          <div className={styles.FormFieldErrorContainer}>
                            <ErrorMessage name={`postcodes.${postcode}.${suburbName}`} />
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <div className={styles.ErrorContainer}>{status?.apiError}</div>
            </DialogContent>
            <AlertDialog
              isOpen={Boolean(postcodeToRemove)}
              variant={ALERT_TYPES.CONFIRM}
              alertTitle={`Are you sure you want to remove ${postcodeToRemove || 'postcode'}?`}
              alertMessage={' '}
              confirmMessage={'Remove Postcode'}
              handleConfirm={() => removePostcodeFromRegion(postcodeToRemove, setFieldValue)}
              handleClose={closeRemovePostcodeDialog}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

EditDeliverySuburbsModal.propTypes = {
  deliveryZone: PropTypes.shape({
    id: PropTypes.number.isRequired,
    suburbs: PropTypes.array.isRequired,
  }).isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refetchZoneData: PropTypes.func.isRequired,
};

export default withErrorReports(withNotifications(EditDeliverySuburbsModal));
