import { FC } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';
// eslint-disable-next-line
import { CircularProgress, Dialog, DialogContent, IconButton, DialogTitle } from '@material-ui/core';
import { Button, FormikFormField, FormikSingleSelectField, Typography } from '../../../components/UI/FB';
import { TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import styles from './ProductRemovalDialog.module.scss';

type OrderProductsType = {
  orderProductId: number;
  productId: number;
  sku?: number;
  gst: number;
  substitution: boolean;
  name: string;
  priceIncGST: number;
  portionSize: string;
  unitOfPortion: string;
  unitOfPrice: string;
  originalNumberOfPortions: number;
  originalQuantityPriced: number;
  originalTotalIncGST: number;
  finalQuantityPriced: number;
  finalTotalIncGST: number;
  isOnSpecial: boolean;
  isOnCustomPrice: boolean;
};

type DropdownType = {
  label: string;
  value: string;
};

type ProductRemovalDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  orderProduct: OrderProductsType;
  handleQuantityChanged: (
    values: { qtyProvided: 0 },
    actions: any,
    handleResetTableStyling: () => void,
    orderProduct: OrderProductsType,
    reason?: string,
  ) => void;
};

type FormValuesType = {
  reason: string;
  reasonFreeText?: string;
};

const otherOptionValue = 'Other';

const REMOVAL_REASON = [
  {
    label: 'Poor quality',
    value: 'Poor quality',
  },
  {
    label: 'Discontinued product',
    value: 'Discontinued product',
  },
  {
    label: 'Temporarily out of stock',
    value: 'Temporarily out of stock',
  },
  {
    label: 'Market availability',
    value: 'Market availability',
  },
  {
    label: 'Substituting with similar product',
    value: 'Substituting with similar product',
  },
  {
    label: otherOptionValue,
    value: otherOptionValue,
  },
];

const removeProductValidationSchema = Yup.object().shape({
  reason: Yup.string()
    .required('Reason for product removal is required')
    .oneOf(REMOVAL_REASON.map((reason) => reason.value)),
  reasonFreeText: Yup.string().test(
    'free-text',
    'Please enter a reason for removing the product',
    function validatedFreeTextField(value) {
      return this.parent.reason !== otherOptionValue ? true : (value as any)?.length > 1;
    },
  ),
});

const ProductRemovalDialog: FC<ProductRemovalDialogProps> = ({
  isOpen,
  handleClose,
  orderProduct,
  handleQuantityChanged,
}) => {
  const removeProduct = (values: FormValuesType, actions: FormikHelpers<FormikFormField>) => {
    handleQuantityChanged(
      { qtyProvided: 0 },
      actions,
      () => {},
      orderProduct,
      values.reasonFreeText ? values.reasonFreeText : values.reason,
    );
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ root: styles.ProductRemovalDialogRoot, paper: styles.ProductRemovalDialogPaper }}
    >
      <DialogTitle className={styles.ProductRemovalDialog__header}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Remove product from order</Typography>
        <IconButton className={styles.ProductRemovalDialog__closeBtn} aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: styles.ProductRemovalDialogContent }}>
        <Typography type={TYPOGRAPHY_TYPES.BODY}>
          Product:&nbsp;
          <strong>{orderProduct.name}</strong>
        </Typography>
        <Typography type={TYPOGRAPHY_TYPES.BODY}>
          Let customers know why you are removing this item from their order.
        </Typography>
      </DialogContent>

      <DialogContent classes={{ root: styles.ReasonContentContainer }}>
        <Formik
          initialValues={{
            reason: '',
            reasonFreeText: '',
          }}
          validationSchema={removeProductValidationSchema}
          onSubmit={removeProduct}
          initialStatus={{
            apiError: undefined,
          }}
        >
          {({ errors, touched, isSubmitting, setTouched, setFieldValue, values }) => (
            <Form>
              <div className={styles.ReasonContentContainer}>
                <div className={styles.InputRow}>
                  <div className={[styles.FullWidthInputContainer, styles.withAnnotations].join(' ')}>
                    <FormikSingleSelectField
                      label="Reason"
                      fieldName="reason"
                      errors={errors}
                      touched={touched}
                      value={REMOVAL_REASON.find((reason) => reason.value === values.reason) || ''}
                      options={REMOVAL_REASON}
                      placeholder="Select a reason"
                      setTouched={setTouched}
                      setFieldValue={setFieldValue}
                      maxMenuHeight={200}
                      customOnChange={(selectedValue: DropdownType) => {
                        setFieldValue('reason', selectedValue.value);
                      }}
                    />
                    {values.reason === otherOptionValue ? (
                      <FormikFormField
                        fieldName="reasonFreeText"
                        touched={touched}
                        errors={errors}
                        placeholder="Enter a reason"
                        label=""
                        setTouched={setTouched}
                        setFieldValue={setFieldValue}
                      />
                    ) : null}
                    <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ProductRemovalDialogContent}>
                      Please note that this reason will be visible to customers.
                    </Typography>
                  </div>
                </div>
                <div className={styles.ProductRemovalDialogButtonContainer}>
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <>
                        <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                      </>
                    ) : (
                      <>Update Order</>
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

ProductRemovalDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  orderProduct: PropTypes.any.isRequired,
};

export default ProductRemovalDialog;
