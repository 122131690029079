import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

class NotificationProgressSpinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      target: this.props.timeout,
    };
  }

  componentDidMount() {
    if (!this.props.showPermanentSpinner) {
      this.timer = setInterval(this.progress, 100);
    }
  }

  componentWillUnmount() {
    if (!this.props.showPermanentSpinner) {
      clearInterval(this.timer);
    }
  }

  progress = () => {
    const { target } = this.state;
    const numTimesToIncrement = (target - 500) / 100;
    const incrementAmount = 100 / numTimesToIncrement;
    this.setState((state) => ({
      progress: state.progress >= 100 ? 100 : state.progress + incrementAmount,
    }));
  };

  render() {
    const { progress } = this.state;
    const { rootClass, showPermanentSpinner } = this.props;

    if (showPermanentSpinner) {
      return <CircularProgress classes={{ root: rootClass }} />;
    }
    return <CircularProgress classes={{ root: rootClass }} variant="determinate" value={progress} />;
  }
}

NotificationProgressSpinner.propTypes = {
  timeout: PropTypes.number,
  rootClass: PropTypes.string.isRequired,
  showPermanentSpinner: PropTypes.bool,
};

export default NotificationProgressSpinner;
