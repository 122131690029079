export const round2DP = (number) => parseFloat(number.toFixed(2), 10);
export const round2DPString = (number) => number.toFixed(2);

export const portionSizePriceCalculator = (pricePerUnitInDollars, priceUnitId, unitOfPortionId, portion) => {
  const KILOGRAM = 1;
  const GRAM = 2;

  if (!priceUnitId || !unitOfPortionId) {
    return 'Unknown';
  }

  if (priceUnitId === KILOGRAM && unitOfPortionId === GRAM) {
    return round2DPString((pricePerUnitInDollars / 1000) * portion);
  }

  if (priceUnitId === GRAM && unitOfPortionId === KILOGRAM) {
    return round2DPString(pricePerUnitInDollars * 1000 * portion);
  }

  // This is crap, we need to figure out the bunch and box things
  return round2DPString(pricePerUnitInDollars * portion);
};

export const calculatePriceIncGST = (priceInDollars) => round2DP((round2DP(priceInDollars) * 11) / 10);

export const buildPriceLabelForProduct = (product) =>
  product ? `$${product.priceInCents / 100}/${product.unitOfPrice.unit}` : '$0/unit';

export const buildPriceIncGSTLabelForProduct = (product) =>
  product ? `$${calculatePriceIncGST(product.priceInCents / 100).toFixed(2)}/${product.unitOfPrice.unit}` : '$0/unit';
