// eslint-disable-next-line import/prefer-default-export
export const buildCategoryDropdownTree = (
  categories,
  categoryDropdowns,
  selectedCategoryId,
  formValues,
  idx,
  push,
  pop,
) => {
  const selectedId = parseInt(selectedCategoryId, 10);
  const formCategories = formValues.categories;
  const categoryDropdownsCopy = [...categoryDropdowns];

  // If there is no selectedId (bad data) or if they're reselected the same thing they had selected before then ignore
  if (!selectedId || selectedId === formCategories[idx]) {
    return categoryDropdowns;
  }

  // Helper inner func
  const generateNextCategory = () => {
    const newCategoryDropDowns = categories.filter((cat) => cat.parentCategoryId === selectedId);

    if (newCategoryDropDowns.length) {
      newCategoryDropDowns.sort((catA, catB) => catA.name.localeCompare(catB.name));
      categoryDropdownsCopy[idx + 1] = newCategoryDropDowns;
      push('');
    }
  };

  if (formCategories.length === idx + 1) {
    // Adding a new category to the end of the list
    generateNextCategory();
  } else {
    // Changing something in the middle or start of the category list
    // Remove everything after the idx we're trying to change
    categoryDropdowns.forEach((dropdownList, dropdownIdx) => {
      if (dropdownIdx > idx) {
        pop();
        categoryDropdownsCopy.pop();
      }
    });
    // Rebuild the next category
    generateNextCategory();
  }
  return categoryDropdownsCopy;
};
