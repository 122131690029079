import { FC } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress, Dialog, DialogContent, IconButton, DialogTitle } from '@material-ui/core';
import { Button, Typography } from '../../../components/UI/FB';
import { TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import styles from './ConfirmationOrderDialog.module.scss';

type ConfirmationOrderDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  finalTotal: string;
  handleOrderProcess: () => Promise<void>;
  isProcessingOrder: boolean;
};

const ConfirmationOrderDialog: FC<ConfirmationOrderDialogProps> = ({
  isOpen,
  handleClose,
  finalTotal,
  handleOrderProcess,
  isProcessingOrder,
}) => (
  <Dialog
    open={isOpen}
    onClose={handleClose}
    classes={{ root: styles.ConfirmationOrderDialogRoot, paper: styles.ConfirmationOrderDialogPaper }}
  >
    <DialogTitle className={styles.ConfirmationOrderDialog__header}>
      <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Confirm Order</Typography>
      <IconButton className={styles.ConfirmationOrderDialog__closeBtn} aria-label="close" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent classes={{ root: styles.ConfirmationOderDialogContent }}>
      <div>
        <Typography type={TYPOGRAPHY_TYPES.BODY}>Please confirm the order total.</Typography>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_M}>Total order value: {finalTotal}</Typography>
      </div>
    </DialogContent>
    <div className={styles.ConfirmationOderDialogFooter}>
      <Button onClick={handleOrderProcess} disabled={isProcessingOrder}>
        {isProcessingOrder ? (
          <>
            <CircularProgress className={styles.SubmitLoadingSpinner} /> Processing...
          </>
        ) : (
          <>Confirm</>
        )}
      </Button>
    </div>
  </Dialog>
);

ConfirmationOrderDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleOrderProcess: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isProcessingOrder: PropTypes.bool.isRequired,
  finalTotal: PropTypes.string.isRequired,
};

export default ConfirmationOrderDialog;
