export const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_RESET = 'AUTH_RESET';

export const RECEIVE_IMAGES = 'RECEIVE_IMAGES';

export const SET_FOODBOMB_API_VERSION = 'SET_FOODBOMB_API_VERSION';
export const RESET_FOODBOMB_API_VERSION = 'RESET_FOODBOMB_API_VERSION';
export const SET_FOODBOMB_PDF_API_VERSION = 'SET_FOODBOMB_PDF_API_VERSION';
export const RESET_FOODBOMB_PDF_API_VERSION = 'RESET_FOODBOMB_PDF_API_VERSION';
export const SET_DELIVERY_PREFERENCES_API_VERSION = 'SET_DELIVERY_PREFERENCES_API_VERSION';
export const RESET_DELIVERY_PREFERENCES_API_VERSION = 'RESET_DELIVERY_PREFERENCES_API_VERSION';
