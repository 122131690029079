import React, { useState, useEffect } from 'react';
import { Select, MenuItem, IconButton, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons/';
import PropTypes from 'prop-types';
import styles from './CSVProductsTable.module.scss';
import useDDErrorReporting from '../../hooks/useDDErrorReporting/useDDErrorReporting.ts';

const findCategoryChildren = (categories, parentId) =>
  categories.filter((cat) => cat.parentCategoryId === parentId).sort((a, b) => a.name.localeCompare(b.name));

const buildAllowedCategoriesFor = (field, tableData, categories, moreThanOneCategoryWarningFunction) => {
  const parentCategories = findCategoryChildren(categories, null);

  if (field === 'categoryOne') {
    return parentCategories;
  }

  // Going to assume one match :/
  const categoryOnes = parentCategories.filter((parentCat) => parentCat.name === tableData.rowData.categoryOne);
  if (categoryOnes.length > 1) {
    moreThanOneCategoryWarningFunction(categoryOnes, tableData.rowData.categoryOne);
  }
  const selectedCategoryOne = categoryOnes[0];
  const selectedCategoryOneId = selectedCategoryOne ? selectedCategoryOne.id : undefined;
  const potentialSecondLevelCategories = findCategoryChildren(categories, selectedCategoryOneId);

  if (field === 'categoryTwo') {
    return potentialSecondLevelCategories;
  }

  // Going to assume one match :/
  const categoryTwos = potentialSecondLevelCategories.filter(
    (secondLevelCat) => secondLevelCat.name === tableData.rowData.categoryTwo,
  );
  if (categoryTwos.length > 1) {
    moreThanOneCategoryWarningFunction(categoryTwos, tableData.rowData.categoryTwo);
  }
  const selectedCategoryTwo = categoryTwos[0];
  const selectedCategoryTwoId = selectedCategoryTwo ? selectedCategoryTwo.id : undefined;
  const potentialThirdLevelCategories = findCategoryChildren(categories, selectedCategoryTwoId);

  if (field === 'categoryThree') {
    return potentialThirdLevelCategories;
  }

  return [];
};

const categoryNameExistsInList = (categories, name) => categories.map((cat) => cat.name).includes(name);

const CategoryEditCell = ({ tableData, field, categories }) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const { sendDatadogError } = useDDErrorReporting();

  const duplicateCategoryWarning = (categoryList, searchQuery) => {
    sendDatadogError(`Invalid category data.`, {
      error: {
        message: 'There is invalid category data as a search returned multiple results',
      },
      search: searchQuery,
      location: 'Category Edit Call in Multiple Product Uploader',
      categoryList,
    });
  };

  const allowedCategories = buildAllowedCategoriesFor(field, tableData, categories, duplicateCategoryWarning);
  const previouslySelectedData = tableData.rowData[field];

  const handleSetPotentialChildValues = (valueToSet) => {
    let selectedCategories = {};
    const { rowData } = tableData;
    if (field === 'categoryOne') {
      selectedCategories = {
        categoryOne: valueToSet,
        categoryTwo: '',
        categoryThree: '',
      };
    } else if (field === 'categoryTwo') {
      selectedCategories = {
        categoryTwo: valueToSet,
        categoryThree: '',
      };
    } else {
      selectedCategories = {
        categoryThree: valueToSet,
      };
    }
    tableData.onRowDataChange({ ...rowData, ...selectedCategories });
  };

  const resetSelectedCategory = () => {
    setSelectedCategory('');
    handleSetPotentialChildValues('');
  };

  useEffect(() => {
    if (categoryNameExistsInList(allowedCategories, previouslySelectedData)) {
      setSelectedCategory(previouslySelectedData);
    } else if (tableData.rowData[field] !== selectedCategory) {
      setSelectedCategory('');
    }
  }, [tableData, allowedCategories, previouslySelectedData, selectedCategory, field]);

  const handleSelectCategory = (e) => {
    setSelectedCategory(e.target.value);
    handleSetPotentialChildValues(e.target.value);
  };

  const error = tableData.rowData.error
    ? tableData.rowData.errors.filter((err) => err.path === field).map((e) => e.message)
    : '';

  return (
    <div className={styles.CategoryEditCell}>
      {allowedCategories.length ? (
        <React.Fragment>
          <Select
            value={selectedCategory}
            displayEmpty
            onChange={handleSelectCategory}
            classes={
              selectedCategory === ''
                ? { root: styles.CategoryEditCell__selectField__disabled }
                : { root: styles.CategoryEditCell__selectField }
            }
          >
            <MenuItem value={''} disabled className={styles.CategoryEditCell__selectItem}>
              Select a category
            </MenuItem>
            {allowedCategories.map((parentCat) => (
              <MenuItem
                key={`${field}-${parentCat.id}`}
                value={parentCat.name}
                className={styles.CategoryEditCell__selectItem}
              >
                {parentCat.name}
              </MenuItem>
            ))}
          </Select>
          {field !== 'categoryOne' && selectedCategory !== '' ? (
            <Tooltip title="Remove Category" placement="top">
              <IconButton className={styles.CategoryEditCell__actionBtn} onClick={resetSelectedCategory}>
                <Close className={styles.ErrorIcon} />
              </IconButton>
            </Tooltip>
          ) : null}
        </React.Fragment>
      ) : (
        <p>No available subcategories</p>
      )}

      <div>
        {error.length && selectedCategory === '' ? (
          <div className={styles.CSVProductsTable__errormsg}>{error}</div>
        ) : null}
      </div>
    </div>
  );
};

CategoryEditCell.propTypes = {
  tableData: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  parentCategoryId: PropTypes.number,
};

export default CategoryEditCell;
