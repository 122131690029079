import React, { FC } from 'react';
import PropTypes from 'prop-types';

import { ORDER_STATUSES } from '../../utils/Constants';
import styles from './OrderStatusChip.module.scss';

type OrderStatusChipType = {
  apiOrderStatus: string;
};

type OS = keyof typeof ORDER_STATUSES;

const OrderStatusChip: FC<OrderStatusChipType> = ({ apiOrderStatus }) => {
  const orderStatusChipStyles = [styles.OrderStatusChip];

  orderStatusChipStyles.push(styles[ORDER_STATUSES[apiOrderStatus as OS].toLowerCase()]);

  return (
    <div className={styles.OrderStatusChipContainer}>
      <div className={orderStatusChipStyles.join(' ')}>{ORDER_STATUSES[apiOrderStatus as OS].toLowerCase()}</div>
    </div>
  );
};

OrderStatusChip.propTypes = {
  apiOrderStatus: PropTypes.string.isRequired,
};

export default OrderStatusChip;
