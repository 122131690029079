import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './DeliveryZoneDetailLoadingAnimation.module.scss';

const DeliveryZoneDetailLoadingAnimation = () => {
  const getWindowWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const getWindowHeight = () =>
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  return (
    <div>
      <React.Fragment>
        <div className={styles.DetailsContentLoader__mobile}>
          <ContentLoader
            viewBox={`0 0 ${0.9 * getWindowWidth()} ${getWindowHeight()}`}
            height={getWindowHeight()}
            width={getWindowWidth()}
          >
            <rect
              x={0.012 * getWindowWidth()}
              y={0.022 * getWindowHeight()}
              rx="5"
              ry="5"
              width={0.9 * getWindowWidth()}
              height={0.3 * getWindowHeight()}
            />
            <rect
              x={0.012 * getWindowWidth()}
              y={0.34 * getWindowHeight()}
              rx="5"
              ry="5"
              width={0.9 * getWindowWidth()}
              height={0.5 * getWindowHeight()}
            />
          </ContentLoader>
        </div>
        <div className={styles.DetailsContentLoader__desktop}>
          <ContentLoader
            viewBox={`0 0 ${getWindowWidth()} ${getWindowHeight()}`}
            height={getWindowHeight()}
            width={getWindowWidth()}
          >
            <rect
              x={0.012 * getWindowWidth()}
              y={0.022 * getWindowHeight()}
              rx="5"
              ry="5"
              width={0.317 * getWindowWidth()}
              height={0.3 * getWindowHeight()}
            />
            <rect
              x={0.341 * getWindowWidth()}
              y={0.022 * getWindowHeight()}
              rx="5"
              ry="5"
              width={0.317 * getWindowWidth()}
              height={0.826 * getWindowHeight()}
            />
            <rect
              x={0.012 * getWindowWidth()}
              y={0.34 * getWindowHeight()}
              rx="5"
              ry="5"
              width={0.317 * getWindowWidth()}
              height={0.5 * getWindowHeight()}
            />
          </ContentLoader>
        </div>
      </React.Fragment>
    </div>
  );
};

export default DeliveryZoneDetailLoadingAnimation;
