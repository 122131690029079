import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NavigationContext from '../../context/Navigation/NavigationContext';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import FloatingBanner from '../FloatingBanner/FloatingBanner';
import CountdownTimer from '../CountdownTimer/CountdownTimer.tsx';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';

const StaffMasqueradingBanner = ({ supplierId, tokenExpirationDate, sendDatadogError }) => {
  const [percentComplete, setPercentComplete] = useState(0);
  const [supplierName, setSupplierName] = useState(`Supplier: ${supplierId}`);

  const { hideMasqueradingBanner, onHideMasqueradingBanner } = useContext(NavigationContext);

  const fetchSupplierDetails = useCallback(() => {
    FoodbombAPI.get(`suppliers/auth/me`)
      .then((response) => {
        setSupplierName(response.data.company);
      })
      .catch((error) => {
        sendDatadogError(`Unable to load supplier details: ${supplierId}`, {
          error,
          location: 'Staff masquerading banner',
        });
      });
  }, [supplierId, sendDatadogError]);

  useEffect(() => {
    fetchSupplierDetails();
  }, [fetchSupplierDetails]);

  return (
    <FloatingBanner
      rainbow
      percentComplete={percentComplete}
      hide={hideMasqueradingBanner}
      onClick={onHideMasqueradingBanner}
    >
      Masquerading as {supplierName}. Your session will expire in&nbsp;
      <strong>
        <CountdownTimer
          endDate={tokenExpirationDate}
          decrementInterval={1000}
          onPercentageCompleteChange={setPercentComplete}
        />
      </strong>
    </FloatingBanner>
  );
};

const mapStateToProps = (state) => ({
  supplierId: state.auth.supplierDetails ? state.auth.supplierDetails.id : undefined,
  tokenExpirationDate: state.auth.tokenExpirationDate,
});

StaffMasqueradingBanner.propTypes = {
  supplierId: PropTypes.number.isRequired,
  tokenExpirationDate: PropTypes.instanceOf(Date).isRequired,
  sendDatadogError: PropTypes.func.isRequired,
};

export default withErrorReports(connect(mapStateToProps, null)(StaffMasqueradingBanner));
