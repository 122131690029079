import React from 'react';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Paper, Typography, Button } from '../UI/FB';
import { TYPOGRAPHY_TYPES, BUTTON_VARIANTS } from '../../utils/Constants';
import LargeLoadingSpinner from '../UI/LargeLoadingSpinner/LargeLoadingSpinner';
import styles from './MultipleProductsStepper.module.scss';
import { AddProductsCSVHeaders, EditProductsCSVHeaders, ProductSKUCSVHeader } from '../../utils/CSVHeaders';

const StepZero = ({
  isProductIdInValid,
  hideInstructions,
  incorrectHeaders,
  errorMessage,
  loadingCSV,
  loadingData,
  toggleInstructions,
  downloadCSVTemplate,
  CSVHandlerForOnChange,
  downloadExistingProducts,
  addProductsMode,
}) => {
  const openingContent = (addMode) =>
    addMode ? (
      <React.Fragment>
        <Typography type={TYPOGRAPHY_TYPES.BODY}>
          Here you can upload as many products as you would like in one go.
        </Typography>
        <Typography type={TYPOGRAPHY_TYPES.BODY}>
          To do this, place your product data into a CSV file with our format.
        </Typography>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Typography type={TYPOGRAPHY_TYPES.BODY}>
          Here you can edit as many of yours products as you would like in one go.
        </Typography>
        <Typography type={TYPOGRAPHY_TYPES.BODY}>
          To do this, download your existing products, edit them on your device and then reupload.
        </Typography>
      </React.Fragment>
    );

  const instructionsContent = (addMode, hideInstruction) =>
    addMode ? (
      <div
        className={
          hideInstruction
            ? [
                styles.MultipleProductsContainer__instructionsContainer,
                styles.MultipleProductsContainer__instructionsContainer_hidden,
              ].join(' ')
            : styles.MultipleProductsContainer__instructionsContainer
        }
      >
        <Typography type={TYPOGRAPHY_TYPES.BODY}>
          In order to add new products, the CSV format and data included must be correct. Here are a few things to keep
          in mind:
        </Typography>
        <ul className={styles.MultipleProductsContainer__instructions_disc}>
          <li>
            Your CSV column names must include:
            <br />
            <table className={styles.MultipleProductsContainer__instructions_table_border}>
              <tbody>
                <tr>
                  <th>{ProductSKUCSVHeader}</th>
                  <th>Product Name</th>
                  <th>Category One</th>
                  <th>Category Two</th>
                  <th>Category Three</th>
                  <th>Price (Ex.GST)</th>
                  <th>Price Unit</th>
                  <th>Portions</th>
                  <th>Portion Unit</th>
                  <th>Taxable (Y/N)</th>
                  <th>Enabled (Y/N)</th>
                  <th>Out of Stock (Y/N)</th>
                </tr>
              </tbody>
            </table>
            <Typography type={TYPOGRAPHY_TYPES.BODY}>You can download a template of this format below.</Typography>
            <br />
          </li>
          <li>Categories must be a valid FoodBomb category</li>
          <li>Portion and Price units must match, unless using Kilogram and Gram which can be interchangeable</li>
          <li>Product SKU must be unique</li>
        </ul>
        <div className={styles.MultipleProductsContainer__text_center_parent}>
          <button className={styles.FakeLinkBtn} onClick={() => downloadCSVTemplate()}>
            Download our CSV upload template here
          </button>
        </div>
      </div>
    ) : (
      <div
        className={
          hideInstruction
            ? [
                styles.MultipleProductsContainer__instructionsContainer,
                styles.MultipleProductsContainer__instructionsContainer_hidden,
              ].join(' ')
            : styles.MultipleProductsContainer__instructionsContainer
        }
      >
        <Typography type={TYPOGRAPHY_TYPES.BODY}>
          In order to edit your products, the CSV format and data included must be correct. Here are a few things to
          keep in mind:
        </Typography>
        <ul className={styles.MultipleProductsContainer__instructions_disc}>
          <li>
            Your CSV column names must include:
            <table className={styles.MultipleProductsContainer__instructions_table_border}>
              <tbody>
                <tr>
                  <th>Product Id</th>
                  <th>{ProductSKUCSVHeader}</th>
                  <th>Product Name</th>
                  <th>Category One</th>
                  <th>Category Two</th>
                  <th>Category Three</th>
                  <th>Price (Ex.GST)</th>
                  <th>Price Unit</th>
                  <th>Portions</th>
                  <th>Portion Unit</th>
                  <th>Taxable (Y/N)</th>
                  <th>Enabled (Y/N)</th>
                  <th>Out of Stock (Y/N)</th>
                </tr>
              </tbody>
            </table>
            <br />
          </li>
          <li>Categories must be a valid FoodBomb category</li>
          <li>Portion and Price units must match, unless using Kilogram and Gram which can be interchangeable</li>
          <li>Also, make sure you do not edit the Product Id values</li>
        </ul>
      </div>
    );

  return (
    <div className={styles.MultipleProductsContainer__text}>
      <Paper className={styles.MultipleProductsContainer__instructionsPaper}>
        {openingContent(addProductsMode)}
        <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ReadMoreLinkContainer}>
          For more instructions please&nbsp;
          <button className={styles.FakeLinkBtn} onClick={toggleInstructions}>
            click here
          </button>
        </Typography>
      </Paper>
      {instructionsContent(addProductsMode, hideInstructions)}

      <div className={styles.MultipleProductsContainer__CSVFormatErrorContainer}>
        {isProductIdInValid ? (
          <Typography type={TYPOGRAPHY_TYPES.BODY}>Please ensure you do not edit your Product Id</Typography>
        ) : null}

        {errorMessage && !incorrectHeaders.length ? (
          <Paper className={styles.MultipleProductsContainer__CSVFormatErrorMessageTitle}>{errorMessage}</Paper>
        ) : null}

        {errorMessage && incorrectHeaders.length ? (
          <Paper>
            <Typography
              type={TYPOGRAPHY_TYPES.BODY}
              className={styles.MultipleProductsContainer__CSVFormatErrorMessageTitle}
            >
              {errorMessage}
            </Typography>
            <div className={styles.MultipleProductsContainer__CSVFormatErrorContainer_text}>
              <Typography type={TYPOGRAPHY_TYPES.BODY}>Our CSV format has the following headers:</Typography>
              <Typography type={TYPOGRAPHY_TYPES.BODY}>
                {addProductsMode ? AddProductsCSVHeaders.join(',') : EditProductsCSVHeaders.join(',')}
              </Typography>
              <Typography type={TYPOGRAPHY_TYPES.BODY}>&nbsp;</Typography>
              <Typography type={TYPOGRAPHY_TYPES.BODY}>However, the CSV format that you uploaded has:</Typography>
              <Typography type={TYPOGRAPHY_TYPES.BODY}>{incorrectHeaders.join(',')}</Typography>
            </div>
          </Paper>
        ) : null}
      </div>

      {!addProductsMode ? (
        <div className={styles.MultipleProductsContainer__downloadProductsContainer}>
          <Button
            variant={BUTTON_VARIANTS.SECONDARY}
            aria-label="download products"
            className={styles.MultipleProductsContainer__downloadProductsContainer_btn}
            onClick={downloadExistingProducts}
            disabled={loadingData}
          >
            <React.Fragment>
              {loadingData ? (
                <React.Fragment>
                  <CircularProgress className={styles.MultipleProductsContainer__Spinner} size={24} />
                  &nbsp;
                </React.Fragment>
              ) : null}
              Download Products
            </React.Fragment>
          </Button>
        </div>
      ) : null}

      <div className={styles.MultipleProductsContainer__CSVInputContainer}>
        <div
          className={
            loadingCSV ? [styles.MultipleProductsContainer__CSVInputContainer__semi_translucent].join(' ') : null
          }
        >
          <div className={styles.MultipleProductsContainer__CSVInputWrapper}>
            Upload your Products CSV
            <input
              className={styles.MultipleProductsContainer__CSVInput}
              type="file"
              accept="text/csv"
              onChange={(e) => CSVHandlerForOnChange(e)}
            />
          </div>
        </div>

        {loadingCSV ? (
          <div className={styles.MultipleProductsContainer__CSVUploadSpinner}>
            <LargeLoadingSpinner />
          </div>
        ) : null}
      </div>
    </div>
  );
};

StepZero.propTypes = {
  isProductIdInValid: PropTypes.bool,
  incorrectHeaders: PropTypes.array,
  errorMessage: PropTypes.string,
  hideInstructions: PropTypes.bool,
  loadingCSV: PropTypes.bool,
  loadingData: PropTypes.bool,
  toggleInstructions: PropTypes.func,
  downloadCSVTemplate: PropTypes.func,
  CSVHandlerForOnChange: PropTypes.func.isRequired,
  CSVHandlerForDelete: PropTypes.func.isRequired,
  downloadExistingProducts: PropTypes.func.isRequired,
  addProductsMode: PropTypes.bool.isRequired,
};

export default StepZero;
