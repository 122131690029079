import React from 'react';
import PropTypes from 'prop-types';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import styles from './SpecialsIcon.module.scss';

const SpecialsIcon = ({ forHeading, asEmoji }) => {
  const iconStyles = [styles.SpecialsIcon];
  if (forHeading) {
    iconStyles.push(styles.forHeading);
  } else if (asEmoji) {
    iconStyles.push(styles.asEmoji);
  } else {
    iconStyles.push(styles.inline);
  }
  return <WhatshotIcon className={iconStyles.join(' ')} />;
};

SpecialsIcon.propTypes = {
  forHeading: PropTypes.bool,
  asEmoji: PropTypes.bool,
};

export default SpecialsIcon;
