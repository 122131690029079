const ProductSKUCSVHeader = 'Product SKU';

const AddProductsCSVHeaders = [
  ProductSKUCSVHeader,
  'Product Name',
  'Category One',
  'Category Two',
  'Category Three',
  'Price (Ex.GST)',
  'Price Unit',
  'Portions',
  'Portion Unit',
  'GST (Y/N)',
  'Enabled (Y/N)',
  'Out of Stock (Y/N)',
];

const EditProductsCSVHeaders = [
  'Product Id',
  ProductSKUCSVHeader,
  'Product Name',
  'Category One',
  'Category Two',
  'Category Three',
  'Price (Ex.GST)',
  'Price Unit',
  'Portions',
  'Portion Unit',
  'GST (Y/N)',
  'Enabled (Y/N)',
  'Out of Stock (Y/N)',
];

module.exports = {
  ProductSKUCSVHeader,
  AddProductsCSVHeaders,
  EditProductsCSVHeaders,
};
