import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { FoodbombAPI } from '../../utils/AxiosInstances';
import { ACTIONS, FILE_TYPES, OBJECT_TYPES } from './Properties';
import withErrorReports from '../withErrorReports/withErrorReports';

function withEventTracking(WrappedComponent) {
  class WithEventTrackingWrapper extends Component {
    /**
     * Track a supplier event
     * @param {String} supplier event object. Options seen in src/hoc/withEventTracking/Properties.js
     * @param {Array} objectIds used in the action
     * @params {String} url of the API endpoint
     * @params {Object} object to allow passing arbitrary data for event
     * @returns {None}
     */
    trackDownloadPDF = (objectType = undefined, objectIds = [], endpointUrl = undefined, context = {}) => {
      const contextToSave = {
        fileType: FILE_TYPES.PDF,
        ...context,
        endpointUrl,
        objectIds,
      };
      this.sendRequest(objectType, ACTIONS.DOWNLOAD, contextToSave, 'Failed to track PDF download');
    };

    trackBulkProductUpload = (products = [], endpointUrl = undefined) => {
      const contextToSave = {
        fileType: FILE_TYPES.CSV,
        endpointUrl,
        products,
      };
      this.sendRequest(
        OBJECT_TYPES.PRODUCTS,
        ACTIONS.BULK_UPLOAD,
        contextToSave,
        'Failed to track bulk product upload',
      );
    };

    getScreenResolution = () => ({ screenWidth: window.screen.width, screenHeight: window.screen.height });

    getCurrentUrl = () => this.props.location.pathname;

    sendRequest = (objectType, action, data, errorMessage = `Failed to track user event of type ${objectType}`) => {
      FoodbombAPI.post('suppliers/events', {
        objectType,
        action,
        location: this.getCurrentUrl(),
        data: { ...data, ...this.getScreenResolution() },
      }).catch((error) => {
        this.props.sendDatadogError(errorMessage, { error, location: 'Event tracking' });
      });
    };

    render() {
      return (
        <WrappedComponent
          trackDownloadPDF={this.trackDownloadPDF}
          trackBulkProductUpload={this.trackBulkProductUpload}
          {...this.props}
        />
      );
    }
  }

  WithEventTrackingWrapper.propTypes = {
    location: PropTypes.object.isRequired,
    sendDatadogError: PropTypes.func.isRequired,
  };

  return withRouter(withErrorReports(WithEventTrackingWrapper));
}

export default withEventTracking;
