import React from 'react';
import PropTypes from 'prop-types';
import styles from './DeliveryDaysTable.module.scss';
import OnStatusChip from '../../../../components/UI/OnStatusChip/OnStatusChip';
import { DAYS } from '../../../../utils/TimeConstants';

const DeliveryDaysTable = ({ deliveryDays }) => {
  const getDayData = (day) => deliveryDays.find((deliveryDay) => deliveryDay.deliveryDay === day);

  return (
    <table className={styles.DeliveryDaysTable}>
      <thead>
        <tr>
          <th className={styles.PrimaryHeading} colSpan="2">
            Delivery Days
          </th>
          <th className={styles.PrimaryHeading}>New C.</th>
          <th className={styles.PrimaryHeading}>Existing C.</th>
        </tr>
        <tr>
          <th className={styles.SecondaryHeading} colSpan="2">
            Deliveries for
          </th>
          <th className={styles.SecondaryHeading}>in by</th>
          <th className={styles.SecondaryHeading}>in by</th>
        </tr>
      </thead>
      <tbody>
        {DAYS.map((day) => (
          <tr
            key={day}
            className={
              getDayData(day).enabled
                ? [styles.ContentRow, styles.active].join(' ')
                : [styles.ContentRow, styles.inactive].join(' ')
            }
          >
            <td>
              <OnStatusChip onStatus={getDayData(day).enabled} className={styles.DayStatusChip} />
            </td>
            <td>{day}</td>
            <td>{getDayData(day)?.newCustomerOrderBy || <span className={styles.DisabledDayDash}></span>}</td>
            <td>{getDayData(day)?.existingCustomerOrderBy || <span className={styles.DisabledDayDash}></span>}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

DeliveryDaysTable.propTypes = {
  deliveryDays: PropTypes.arrayOf(
    PropTypes.shape({
      enabled: PropTypes.bool,
      deliveryDay: PropTypes.string.isRequired,
      newCustomerOrderBy: PropTypes.string,
      existingCustomerOrderBy: PropTypes.string,
    }).isRequired,
  ).isRequired,
};

export default DeliveryDaysTable;
