import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import styles from './HamburgerMenu.module.scss';

const HamburgerMenu = ({ onClick, displayAsCloseIcon }) => {
  const toggleClasses = [styles.DrawToggle];
  if (displayAsCloseIcon) {
    toggleClasses.push(styles.open);
  }

  return (
    <IconButton onClick={onClick}>
      <div className={toggleClasses.join(' ')}>
        <div className={[styles.DrawToggle__spinner, styles.spinner1].join(' ')} />
        <div className={[styles.DrawToggle__spinner, styles.spinner2].join(' ')} />
        <div className={[styles.DrawToggle__spinner, styles.spinner3].join(' ')} />
      </div>
    </IconButton>
  );
};

HamburgerMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
  displayAsCloseIcon: PropTypes.bool,
};

export default HamburgerMenu;
