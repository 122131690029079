import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import { debounce } from 'lodash';
import { CircularProgress, Dialog, IconButton } from '@material-ui/core';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import { Button, Typography, FormikSingleSelectField } from '../../UI/FB';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import { CreateNotification, SendErrorReportType } from '../../../utils/Presenters/ReduxType';
import { LossLeaderProductsForSelect } from '../../../containers/LossLeaders/LossLeaderTypes';
import styles from './LossLeaderProductsModal.module.scss';

type FormValueType = {
  productId: string;
};

type LossLeaderProductsModalTypes = {
  isOpen: boolean;
  onClose: () => void;
  sendDatadogError: SendErrorReportType;
  createNotification: CreateNotification;
  onSuccessfulLossProductAPICall: () => void;
  loadingLossLeaderProducts: boolean;
  products: LossLeaderProductsForSelect[];
};

const LossLeaderProductsModal: FC<LossLeaderProductsModalTypes> = ({
  isOpen,
  onClose,
  sendDatadogError,
  onSuccessfulLossProductAPICall,
  createNotification,
  loadingLossLeaderProducts,
  products,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const searchArrayByLabel = (query: string, data: LossLeaderProductsForSelect[]) => {
    const numberOfResults = 15;

    if (query) {
      return data
        ?.filter((c: LossLeaderProductsForSelect | any) => c.label.toLowerCase().includes(query.toLowerCase()))
        ?.slice(0, numberOfResults);
    }
    return data?.slice(0, numberOfResults);
  };

  const filterProductsBySearch = (query: string) => searchArrayByLabel(query, products);

  const lossLeaderProductValidationSchema = Yup.object().shape({
    productId: Yup.number().required('Product is required'),
  });

  const handleClose = () => {
    setErrorMessage(undefined);
    onClose();
  };

  const handleSubmit = (values: FormValueType, actions: FormikHelpers<FormValueType>) => {
    setErrorMessage(undefined);
    FoodbombAPI.post(`suppliers/loss-products/${values.productId}`)
      .then(() => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully created loss leader product',
          timeout: 4000,
          closable: true,
        });
        onSuccessfulLossProductAPICall();
        onClose();
      })
      .catch((error: any) => {
        if (error && error.response?.data && error.response?.data?.errors) {
          setErrorMessage(error.response?.data.errors[0]?.message);
        }
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to create loss leader product',
          timeout: 4000,
          closable: true,
        });
        sendDatadogError('Unable to create loss leader product', {
          error,
          location: 'Loss leader product modal',
        });
      })
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ root: styles.LossLeaderProductsDialogRoot, paper: styles.LossLeaderProductsDialogPaper }}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ productId: '' }}
        validationSchema={lossLeaderProductValidationSchema}
      >
        {({ errors, touched, isSubmitting, values, setTouched, setFieldValue }) => (
          <Form>
            <div className={styles.LossLeaderProductsDialogHeader}>
              <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Add product to loss leaders</Typography>
              <IconButton
                className={styles.LossLeaderProductsDialog__closeBtn}
                aria-label="close"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className={styles.LossLeaderProductsDialogContent}>
              <Typography type={TYPOGRAPHY_TYPES.BODY}>
                This product will be unavailable to purchase as a single item until your customer has hit your order
                threshold.
              </Typography>
            </div>
            <div className={styles.LossLeaderProductsDialogSelectedField}>
              <FormikSingleSelectField
                label="Product"
                fieldName="productId"
                errors={errors}
                touched={touched}
                setTouched={setTouched}
                setFieldValue={setFieldValue}
                value={products?.find(
                  (product: LossLeaderProductsForSelect) => product.id === parseInt(values.productId, 10),
                )}
                loadOptions={debounce(filterProductsBySearch, 250)}
                defaultOptions
                cacheOptions
                maxMenuHeight={200}
                isLoading={loadingLossLeaderProducts}
                loadingMessage="Loading product list..."
                options={products}
                placeholder="Select from your products (Search by name or SKU)"
                hideErrors
              />
              <div className={styles.ErrorMessageContainer}>
                {errors.productId ? <ErrorMessage name="productId" /> : null}
                {errorMessage}
              </div>
            </div>
            <div className={styles.LossLeaderProductsDialogAction}>
              <Button
                type="submit"
                className={styles.LossLeaderProductsModalButton}
                disabled={Boolean(errors && Object.keys(errors).length) || isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                  </>
                ) : (
                  <>Save</>
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

LossLeaderProductsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  onSuccessfulLossProductAPICall: PropTypes.func.isRequired,
  loadingLossLeaderProducts: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
};

export default LossLeaderProductsModal;
