import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { CircularProgress, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import { ErrorNotification, FormikFormField, Paper, Typography, Button } from '../../../components/UI/FB';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES, BUTTON_VARIANTS } from '../../../utils/Constants';
import styles from '../Settings.module.scss';

const PasswordCard = ({ sendDatadogError, createNotification, innerRef, pulse, keys, isStaff }) => {
  const [editMode, setEditMode] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const handleToggleShowCurrentPassword = () => setShowCurrentPassword((p) => !p);
  const handleToggleShowPassword = () => setShowPassword((p) => !p);
  const handleToggleShowPasswordConfirmation = () => setShowPasswordConfirmation((p) => !p);

  const triggerEditMode = () => setEditMode(true);
  const triggerViewMode = (callback) => {
    setEditMode(false);
    if (callback) {
      callback();
    }
  };

  const mapAPIParamToFormParam = (param) => {
    switch (param) {
      case 'newPassword':
        return 'password';
      case 'oldPassword':
        return 'currentPassword';
      default:
        return param;
    }
  };

  const mapAPIErrorsToFormFields = (errors) => {
    const formErrors = {};
    errors.forEach((e) => {
      formErrors[mapAPIParamToFormParam(e.param)] = e.message;
    });
    return formErrors;
  };

  const handleUpdatePassword = (values, actions) => {
    actions.setStatus({ apiError: undefined });
    const detailsToUpdate = {
      newPassword: values.password,
    };

    if (!isStaff) {
      detailsToUpdate.oldPassword = values.currentPassword;
    }

    FoodbombAPI.patch('/suppliers/auth/change-password', detailsToUpdate)
      .then(() => {
        triggerViewMode();
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully updated password',
          timeout: 4000,
          closable: true,
        });
        actions.setSubmitting(false);
        actions.resetForm();
      })
      .catch((error) => {
        if (error?.response?.status === 400 && error?.response?.data?.errors) {
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to update password',
            timeout: 4000,
            closable: true,
          });
          actions.setErrors(mapAPIErrorsToFormFields(error.response.data.errors));
        } else if (error?.response?.status === 401) {
          actions.setErrors({ currentPassword: 'Invalid password' });
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Invalid password',
            timeout: 4000,
            closable: true,
          });
        } else {
          sendDatadogError('Unable to update password', {
            error,
            location: 'Settings Page',
          });
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to update password',
            timeout: 4000,
            closable: true,
          });
          actions.setStatus({
            apiError: (
              <ErrorNotification
                body={"We were unable to update your password. Don't worry, our engineers have been notified!"}
                className={styles.ErrorNotification}
              />
            ),
          });
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const accountDetailsInitialValues = () => ({
    currentPassword: '',
    password: '',
    passwordConfirmation: '',
  });

  const accountDetailsValidationSchema = () =>
    Yup.object({
      currentPassword: !isStaff ? Yup.string().required('Current password is required') : undefined,
      password: Yup.string().min(8, 'Must be at least 8 characters').required('Password is required!'),
      passwordConfirmation: Yup.string()
        .required('Password confirmation is required!')
        .test(
          'passwords-match',
          'Password confirmation does not match new password',
          function validatePasswordsMatch(value) {
            return this.parent.password === value;
          },
        ),
    });

  const showErrorIcon = (touched, errors) => (editMode ? keys.some((key) => touched[key] && errors[key]) : false);

  return (
    <div ref={innerRef}>
      <Paper className={pulse ? [styles.SettingsCard, styles.pulse].join(' ') : styles.SettingsCard}>
        <Formik
          initialValues={accountDetailsInitialValues()}
          initialStatus={{
            apiError: undefined,
          }}
          validationSchema={accountDetailsValidationSchema()}
          onSubmit={handleUpdatePassword}
        >
          {({ isSubmitting, errors, touched, status, handleReset }) => (
            <React.Fragment>
              <ErrorOutlineIcon
                className={
                  showErrorIcon(touched, errors)
                    ? styles.SettingsCard__invalidIcon
                    : [styles.SettingsCard__invalidIcon, styles.hide].join(' ')
                }
              />
              <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.SettingsCard__sectionHeading}>
                Change Password&nbsp;
              </Typography>
              {editMode ? (
                <div>
                  <div>
                    <Form>
                      {isStaff ? null : (
                        <div className={styles.FormRow}>
                          <FormikFormField
                            fieldName="currentPassword"
                            touched={touched}
                            errors={errors}
                            placeholder="Your current password"
                            label="Current Password"
                            fieldProps={{
                              type: showCurrentPassword ? 'text' : 'password',
                              autoComplete: 'currentPassword',
                            }}
                            endAdornment={
                              <IconButton
                                tabIndex="-1"
                                aria-label="toggle password visibility"
                                className={styles.PasswordField__endAdornment}
                                onClick={handleToggleShowCurrentPassword}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            }
                          />
                        </div>
                      )}
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="password"
                          touched={touched}
                          errors={errors}
                          placeholder="Enter your new password"
                          label="New Password"
                          fieldProps={{ type: showPassword ? 'text' : 'password', autoComplete: 'newPassword' }}
                          endAdornment={
                            <IconButton
                              tabIndex="-1"
                              aria-label="toggle password visibility"
                              className={styles.PasswordField__endAdornment}
                              onClick={handleToggleShowPassword}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          }
                        />
                      </div>
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="passwordConfirmation"
                          touched={touched}
                          errors={errors}
                          placeholder="Confirm your new password"
                          label="New Password Confirmation"
                          fieldProps={{
                            type: showPasswordConfirmation ? 'text' : 'password',
                            autoComplete: 'passwordConfirmation',
                          }}
                          endAdornment={
                            <IconButton
                              tabIndex="-1"
                              aria-label="toggle password visibility"
                              className={styles.PasswordField__endAdornment}
                              onClick={handleToggleShowPasswordConfirmation}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          }
                        />
                      </div>
                      {status.apiError ? <div className={styles.ErrorMessageContainer}>{status.apiError}</div> : null}
                      <div className={styles.SettingsCard__editBtnContainer}>
                        <Button
                          type="button"
                          disabled={isSubmitting}
                          variant={BUTTON_VARIANTS.SECONDARY}
                          onClick={() => triggerViewMode(handleReset)}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" disabled={isSubmitting}>
                          {isSubmitting ? (
                            <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                          ) : (
                            <React.Fragment>Update Details</React.Fragment>
                          )}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <div>
                    <div>
                      <Typography className={styles.SettingsCard__summary} type={TYPOGRAPHY_TYPES.BODY_BOLD}>
                        ********
                      </Typography>
                    </div>
                  </div>
                  <div className={styles.SettingsCard__editBtnContainer}>
                    <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={triggerEditMode} className={styles.EditBtn}>
                      Change Password
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isStaff: state.auth.supplierDetails ? state.auth.supplierDetails.isStaff : false,
});

PasswordCard.propTypes = {
  createNotification: PropTypes.func.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  pulse: PropTypes.bool,
  keys: PropTypes.array.isRequired,
  isStaff: PropTypes.bool,
  sendDatadogError: PropTypes.func.isRequired,
};

export default withErrorReports(withNotifications(connect(mapStateToProps, null)(PasswordCard)));
