import axios, { AxiosError } from 'axios';
import { useCallback } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { useSelector } from 'react-redux';
import { ReduxAuthObject } from '../../utils/Presenters/ReduxType';
import { sendError, trackPageView } from '../../utils/ErrorReportHelpers/ErrorReportHelpers';

const useDDErrorReporting = (): {
  sendDatadogError: (
    errorMessage: string,
    data: { error: Error | AxiosError | any; location?: string; [key: string]: any },
  ) => void;
  trackPageView: (pathName: string) => void;
} => {
  const supplierId = useSelector((state: ReduxAuthObject) => state?.supplierDetails?.id);
  const isStaff = useSelector((state: ReduxAuthObject) => Boolean(state?.supplierDetails?.isStaff));

  const potentiallyGetStatusCodeForError = (error: AxiosError): number | undefined => {
    if (error?.response) {
      return error?.response?.status;
    }
    return undefined;
  };

  const potentiallyStripHeadersFromData = (error: AxiosError) => {
    const dataToSend = { ...error };
    if (dataToSend?.config) {
      dataToSend.config.headers.Authorization = undefined;
    }
    return dataToSend;
  };

  const sendDatadogError = useCallback(
    (errorMessage: string, data: { error: Error | AxiosError; location?: string }) => {
      const tags = [];
      const isAxiosError = axios.isAxiosError(data.error);

      const dataToSend = isAxiosError ? potentiallyStripHeadersFromData(data.error as AxiosError) : data.error;

      if (isAxiosError) {
        const statusCode = potentiallyGetStatusCodeForError(data.error as AxiosError);
        tags.push(statusCode);
      }

      sendError(data.error, errorMessage, {
        data: dataToSend,
        userDetails: {
          supplierId,
          isStaff,
        },
        tags,
      });

      const userDetails = {
        isStaff,
        supplierId,
      };
      datadogLogs.logger.error(errorMessage, {
        error: { ...data.error, dataToSend },
        location: data.location,
        userDetails,
      });
    },
    [supplierId, isStaff],
  );
  const logPageview = (pathName: string) => {
    trackPageView(pathName);
  };

  return {
    sendDatadogError,
    trackPageView: logPageview,
  };
};

export default useDDErrorReporting;
