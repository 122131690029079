import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog, IconButton, CircularProgress, FormControlLabel } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Moment from 'moment';
import StarRatings from 'react-star-ratings';
import { isOnDesktop } from '../../../utils/ScreenUtils/ScreenUtils';

import {
  ALERT_TYPES,
  TYPOGRAPHY_TYPES,
  NOTIFICATION_TYPES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from '../../../utils/Constants';
import {
  ErrorNotification,
  AlertDialog,
  FormikFormField,
  Typography,
  Emoji,
  Button,
  Checkbox,
  Tooltip,
} from '../../../components/UI/FB';
import LargeLoadingSpinner from '../../../components/UI/LargeLoadingSpinner/LargeLoadingSpinner';
import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import withRedirectHelper from '../../../hoc/withRedirectHelper/withRedirectHelper';
import styles from './ReviewDialog.module.scss';
import { FoodbombAPI } from '../../../utils/AxiosInstances';

const ERROR_CODES = {
  INVALID_PARAMETER: 'invalid_parameter',
  ALREADY_REPLIED: 'already_replied',
};

const ReviewDialog = ({
  isOpen,
  handleClose,
  isStaff,
  reviewId,
  sendDatadogError,
  createNotification,
  goToTermsAndConditions,
  reloadAllReviews,
}) => {
  const [deleteReplyDialogOpen, setDeleteReplyDialogOpen] = useState(false);
  const [loadingReview, setLoadingReview] = useState(true);
  const [APIError, setAPIError] = useState(undefined);
  const [review, setReview] = useState(undefined);

  const fetchReview = (id) => {
    setLoadingReview(true);
    setAPIError(undefined);

    FoodbombAPI.get(`/suppliers/reviews/${id}`)
      .then((response) => {
        const reviewData = {
          supplierCanReply:
            !response.data.isArchived && !response.data.replies.filter((reply) => !reply.isFoodbomb).length,
          average:
            Object.values(response.data.ratings).reduce((a, b) => a + b) / Object.values(response.data.ratings).length,
          ...response.data,
        };
        setReview(reviewData);
      })
      .catch((error) => {
        sendDatadogError('Unable to load review details', {
          error,
          location: 'View review modal',
        });
        setAPIError(<ErrorNotification />);
      })
      .finally(() => {
        setLoadingReview(false);
      });
  };

  useEffect(() => {
    if (reviewId) {
      fetchReview(reviewId);
    } else {
      setReview(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);

  const onCloseDialog = () => {
    handleClose();
  };

  const supplierReplyValidationSchema = Yup.object().shape({
    reply: Yup.string().trim().max(1500, 'Reply must less than 2500 characters').required('Reply is required'),
    acceptedTerms: Yup.bool()
      .oneOf([true], 'Please accept the terms and conditions')
      .required('Please accept the terms and conditions'),
  });

  const mapAPIParamToUIField = (param) => {
    switch (param) {
      case 'content':
        return 'reply';
      default:
        return param;
    }
  };

  const sendSupplierReply = (values, actions) => {
    actions.setSubmitting(true);

    FoodbombAPI.post(`/suppliers/reviews/${reviewId}/replies`, {
      content: values.reply,
    })
      .then(() => {
        fetchReview(reviewId);
        reloadAllReviews();
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully sent reply',
          timeout: 4000,
          closable: true,
        });
      })
      .catch((error) => {
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to send reply. Please see the errors above',
          timeout: 4000,
          closable: true,
        });
        if (error?.response?.status === 400 && error?.response?.data?.errors) {
          const errorMessages = {};
          error.response.data.errors.forEach((e) => {
            if (e.code === ERROR_CODES.INVALID_PARAMETER) {
              errorMessages[mapAPIParamToUIField(e.param)] = e.message;
            } else if (e.code === ERROR_CODES.ALREADY_REPLIED) {
              actions.setStatus({
                apiError: (
                  <ErrorNotification
                    title={'Whoops!'}
                    body={'It would appear you have already replied to this review'}
                    actions={
                      <Button
                        variant={BUTTON_VARIANTS.SECONDARY}
                        onClick={() => window.location.reload(true)}
                        type="button"
                      >
                        Reload this page
                      </Button>
                    }
                  />
                ),
              });
            } else {
              actions.setStatus({ apiError: <ErrorNotification body={e.message} /> });
            }
          });
          actions.setErrors(errorMessages);
        } else {
          actions.setStatus({
            apiError: (
              <ErrorNotification body="We were unable to save this reply. Don't worry, our engineers have been notified!" />
            ),
          });
          sendDatadogError('Unable to create reply to review', {
            error,
            location: 'View review modal',
          });
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const handleDeleteReply = () => {
    setDeleteReplyDialogOpen(false);
    setLoadingReview(true);

    FoodbombAPI.delete(`/suppliers/reviews/${reviewId}/replies`)
      .then(() => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully deleted reply',
          timeout: 4000,
          closable: true,
        });
        fetchReview(reviewId);
        reloadAllReviews();
      })
      .catch((error) => {
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to delete review reply',
          timeout: 4000,
          closable: true,
        });
        sendDatadogError('Unable to delete reply', {
          error,
          location: 'View review modal',
        });
        setLoadingReview(false);
      });
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        keepMounted={false}
        onClose={onCloseDialog}
        classes={{ root: styles.ReviewDialog__Root, paper: styles.ReviewDialog__paper }}
      >
        <div className={styles.ReviewDialog__titleContainer}>
          <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>View Customer Review</Typography>
          <IconButton aria-label="close" onClick={handleClose} className={styles.CloseButton}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.ReviewDialog__content}>
          {isStaff ? (
            <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD} className={styles.ReviewIdContainer}>
              <Emoji content="🎭" label="masquerade" />
              &nbsp;Review #{reviewId}
            </Typography>
          ) : null}
          {APIError ? (
            <div className={styles.ErrorWrapper}>{APIError}</div>
          ) : (
            <div>
              {loadingReview ? (
                <div className={styles.LoadingSpinnerContainer}>
                  <LargeLoadingSpinner small loadingMessage={'Loading Review...'} />
                </div>
              ) : (
                <div className={styles.ReviewContainer}>
                  <div className={styles.GreyPaper}>
                    <div className={styles.ReviewContent}>
                      <div className={styles.ReviewContent__left}>
                        <div className={styles.ReviewContent__reviewerDetails}>
                          <Typography type={TYPOGRAPHY_TYPES.BODY_L_BOLD}>{review?.venueName}</Typography>
                          <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ReviewContent__createdDate}>
                            {review?.createdAt ? Moment(review.createdAt).format('D MMM YYYY, LT') : '-'}
                          </Typography>
                          <StarRatings
                            rating={parseFloat(review?.average || 0)}
                            starDimension="18px"
                            starSpacing="0px"
                            starRatedColor="#ffcc00"
                            starEmptyColor="#C5C5C5"
                          />
                        </div>
                        <div className={styles.ReviewContent__ratingContainer}></div>
                      </div>
                    </div>
                    <div className={styles.ReviewContent__review}>
                      <Typography type={TYPOGRAPHY_TYPES.BODY}>
                        {review?.content || <span className={styles.MissingContent}>No Content</span>}
                      </Typography>
                    </div>
                  </div>
                  {review?.replies.map((reply) => (
                    <div
                      className={styles.GreyPaper}
                      key={`${reply.isFoodbomb ? 'foodbomb' : 'supplier'}-reviewReply-${reply.createdAt}`}
                    >
                      {!reply.isFoodbomb && !review?.isArchived ? (
                        <Button
                          className={styles.DeleteReplyBtn}
                          variant={BUTTON_VARIANTS.DANGER}
                          onClick={() => setDeleteReplyDialogOpen(true)}
                        >
                          Delete Reply
                        </Button>
                      ) : null}
                      <Typography type={TYPOGRAPHY_TYPES.HEADING_M}>
                        {reply.isFoodbomb ? 'Foodbomb Reply' : 'Your Reply'}
                      </Typography>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ReviewReplyDateContainer}>
                        {Moment(reply.createdAt).format('D MMM YYYY | LT')}
                      </Typography>
                      <Typography type={TYPOGRAPHY_TYPES.BODY}>{reply.content}</Typography>
                    </div>
                  ))}
                  {review?.supplierCanReply ? (
                    <div>
                      <div className={styles.ReplyHeadingContainer}>
                        <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.ReplyHeadingText}>
                          Add a response
                        </Typography>
                        <Tooltip
                          arrow
                          placement="top"
                          classes={{ tooltip: styles.ReplyTooltipContainer }}
                          title={
                            <div>
                              <Typography type={TYPOGRAPHY_TYPES.BODY_L} className={styles.ReplyTooltipMainText}>
                                We suggest:
                              </Typography>
                              <ul className={styles.ReplyTooltipBullets}>
                                <li>
                                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ReplyTooltipText}>
                                    Acknowledging their feedback
                                  </Typography>
                                </li>
                                <li>
                                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ReplyTooltipText}>
                                    Being factual and clear
                                  </Typography>
                                </li>
                                <li>
                                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ReplyTooltipText}>
                                    Contacting our support team if an issue needs to be resolved
                                  </Typography>
                                </li>
                              </ul>
                            </div>
                          }
                        >
                          <InfoOutlinedIcon className={styles.ReplyHeadingInfoIcon} />
                        </Tooltip>
                      </div>
                      <Formik
                        initialValues={{ reply: '', acceptedTerms: false }}
                        validationSchema={supplierReplyValidationSchema}
                        onSubmit={sendSupplierReply}
                        initialStatus={{
                          apiError: undefined,
                        }}
                      >
                        {({ errors, touched, isSubmitting, status, setTouched, setFieldValue, values }) => (
                          <Form className={styles.ReplyForm}>
                            <div className={styles.InputContainer}>
                              <FormikFormField
                                fieldName="reply"
                                touched={touched}
                                errors={errors}
                                placeholder="We recommend responding to this feedback so the venue knows you have seen it."
                                fieldProps={{
                                  as: 'textarea',
                                  rows: 3,
                                  style: {
                                    resize: 'vertical',
                                  },
                                }}
                              />
                            </div>
                            <div className={styles.ReplyFormTermsCheckbox}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.acceptedTerms}
                                    onChange={(e) => {
                                      setTouched({ ...touched, acceptedTerms: true });
                                      setFieldValue('acceptedTerms', e.currentTarget.checked, true);
                                    }}
                                  />
                                }
                                label={
                                  <Typography type={TYPOGRAPHY_TYPES.BODY}>
                                    By submitting this review I agree to the&nbsp;
                                    <button
                                      className={styles.FakeGreenLinkBtn}
                                      onClick={goToTermsAndConditions}
                                      type="button"
                                    >
                                      terms and conditions
                                    </button>
                                  </Typography>
                                }
                                classes={{
                                  label: styles.CheckboxLabel,
                                }}
                              />
                              {errors ? (
                                <div className={styles.ErrorMessageContainer}>
                                  <ErrorMessage name="acceptedTerms" />
                                </div>
                              ) : null}
                            </div>
                            {status.apiError ? <div className={styles.APIErrorContainer}>{status.apiError}</div> : null}
                            <div className={styles.ReplySubmitBtnContainer}>
                              <Button
                                type="button"
                                disabled={isSubmitting || Boolean(errors && Object.keys(errors).length)}
                                variant={BUTTON_VARIANTS.SECONDARY}
                                size={isOnDesktop() ? BUTTON_SIZES.LARGE : BUTTON_SIZES.SMALL}
                                className={styles.CancelBtn}
                                fullWidth
                              >
                                Cancel
                              </Button>
                              <Button
                                type="submit"
                                disabled={
                                  isSubmitting || Boolean(errors && Object.keys(errors).length) || !values.reply
                                }
                                className={styles.SubmitBtn}
                                size={isOnDesktop() ? BUTTON_SIZES.LARGE : BUTTON_SIZES.SMALL}
                                fullWidth
                              >
                                {isSubmitting ? (
                                  <CircularProgress size={24} className={styles.SubmitBtn__loading} />
                                ) : (
                                  <React.Fragment>Submit Reply</React.Fragment>
                                )}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          )}
        </div>
      </Dialog>
      <AlertDialog
        variant={ALERT_TYPES.WARNING}
        alertTitle={'Delete Reply'}
        alertMessage={'Please note deleting a reply is permanent and cannot be undone.'}
        isOpen={deleteReplyDialogOpen}
        handleClose={() => setDeleteReplyDialogOpen(false)}
        handleConfirm={handleDeleteReply}
        confirmMessage={'Delete Reply'}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isStaff: state.auth.supplierDetails ? state.auth.supplierDetails.isStaff : false,
});

ReviewDialog.propTypes = {
  reviewId: PropTypes.number,
  isStaff: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  goToTermsAndConditions: PropTypes.func.isRequired,
  reloadAllReviews: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(withErrorReports(withNotifications(withRedirectHelper(ReviewDialog))));
