import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { Emoji, Typography, Button } from '../../../components/UI/FB';
import { BUTTON_VARIANTS, TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import styles from './DownloadingPDFsDialog.module.scss';

const DownloadingPDFsDialog = ({ isOpen, handleClose, handleCancel }) => (
  <Dialog
    disableBackdropClick
    disableEscapeKeyDown
    open={isOpen}
    classes={{ root: styles.DialogRoot, paper: styles.DialogPaper }}
  >
    <DialogTitle className={styles.DownloadingPDFsDialog__title}>
      <Emoji content={'🍳'} label={'cooking-download'} />
      <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}> Cooking up your PDF</Typography>
    </DialogTitle>
    <DialogContent className={styles.DownloadingPDFsDialog__content}>
      <Typography type={TYPOGRAPHY_TYPES.BODY}>This may take a few minutes,</Typography>
      <Typography type={TYPOGRAPHY_TYPES.BODY}>
        If you no longer want this download you can press cancel below.
      </Typography>
      <div className={styles.ButtonContainer}>
        <Button variant={BUTTON_VARIANTS.DANGER} onClick={handleCancel}>
          Cancel Download
        </Button>
        <Button onClick={handleClose} className={styles.OkButton}>
          Ok
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

DownloadingPDFsDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default DownloadingPDFsDialog;
