import { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Specials from './Specials';
import NewSpecial from './NewSpecial/NewSpecial';
import styles from './SpecialRoutes.module.scss';

const SpecialRoutes: FC = () => {
  const match = useRouteMatch();

  return (
    <div className={styles.SpecialRoutesContainer}>
      <Switch>
        <Route exact path={`${match.path}/`} component={Specials} />
        <Route exact path={`${match.path}/new`} component={NewSpecial} />
      </Switch>
    </div>
  );
};

export default SpecialRoutes;
