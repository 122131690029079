import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { CircularProgress, Dialog, DialogContent, IconButton, DialogTitle } from '@material-ui/core';
import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import {
  ErrorNotification,
  FormikFormField,
  Button,
  Typography,
  FormikSingleSelectField,
} from '../../../components/UI/FB';
import { TYPOGRAPHY_TYPES, NOTIFICATION_TYPES } from '../../../utils/Constants';

import styles from './CancelOrderDialog.module.scss';

const CancelOrderDialog = ({
  isOpen,
  handleClose,
  onSuccessfullyCancelledOrder,
  createNotification,
  sendDatadogError,
  orderId,
}) => {
  const otherOptionValue = 'Other';

  const attemptToCancelOrder = (values, actions) => {
    actions.setStatus({ apiError: undefined });
    actions.setSubmitting(true);
    FoodbombAPI.post(`orders/${orderId}/cancel`, {
      reason: values.reasonFreeText ? `${values.reason}: ${values.reasonFreeText}` : values.reason,
    })
      .then(() => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully cancelled order',
          timeout: 3000,
          closable: true,
        });
        onSuccessfullyCancelledOrder();
      })
      .catch((error) => {
        if (error.response.status === 400 && error.response.data && error.response.data.errors) {
          const errorMessages = {};
          error.response.data.errors.forEach((e) => {
            if (e.code === 'invalid_parameter') {
              errorMessages[e.param] = e.message;
              actions.setErrors(errorMessages);
            }
          });
        } else {
          sendDatadogError('Unable to cancel order', {
            error,
            location: 'Cancel order dialog',
          });
          actions.setStatus({
            apiError: (
              <ErrorNotification body={'We were unable to cancel this order. Our engineers have been notified!'} />
            ),
          });
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const CANCEL_REASONS = [
    {
      label: 'Customer cancellation',
      value: 'Customer cancellation',
    },
    {
      label: 'Product Out of Stock',
      value: 'Product Out of Stock',
    },
    {
      label: 'Order was not delivered',
      value: 'Order was not delivered',
    },
    {
      label: otherOptionValue,
      value: otherOptionValue,
    },
  ];

  const cancelOrderValidationSchema = Yup.object().shape({
    reason: Yup.string()
      .required('Reason for cancelling is required')
      .oneOf(CANCEL_REASONS.map((reason) => reason.value)),
    reasonFreeText: Yup.string().test(
      'free-text',
      'Please enter a reason for cancelling',
      function validatedFreeTextField(value) {
        return this.parent.reason !== otherOptionValue ? true : value?.length > 1;
      },
    ),
  });

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ root: styles.CancelOrderDialogRoot, paper: styles.CancelOrderDialogPaper }}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle className={styles.CancelOrderDialog__header}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Cancel Order</Typography>
        <IconButton className={styles.CancelOrderDialog__closeBtn} aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <Typography type={TYPOGRAPHY_TYPES.BODY}>
            Please let the customer know why you are cancelling this order.
          </Typography>
          <Formik
            initialValues={{
              reason: '',
              reasonFreeText: '',
            }}
            validationSchema={cancelOrderValidationSchema}
            onSubmit={attemptToCancelOrder}
            initialStatus={{
              apiError: undefined,
            }}
          >
            {({ errors, touched, status, isSubmitting, setTouched, setFieldValue, values }) => (
              <Form>
                <div className={styles.CancelOrderForm}>
                  <div className={styles.InputRow}>
                    <div className={[styles.FullWidthInputContainer, styles.withAnnotations].join(' ')}>
                      <FormikSingleSelectField
                        label="Reason for cancelling"
                        fieldName="reason"
                        errors={errors}
                        touched={touched}
                        value={CANCEL_REASONS.find((reason) => reason.value === values.reason) || ''}
                        options={CANCEL_REASONS}
                        placeholder="Select a reason"
                        setTouched={setTouched}
                        setFieldValue={setFieldValue}
                        maxMenuHeight={200}
                        customOnChange={(selectedValue) => {
                          setFieldValue('reason', selectedValue.value);
                        }}
                      />
                      {values.reason === otherOptionValue ? (
                        <FormikFormField
                          fieldName="reasonFreeText"
                          touched={touched}
                          errors={errors}
                          placeholder="Enter a reason"
                          label={''}
                          setTouched={setTouched}
                          setFieldValue={setFieldValue}
                        />
                      ) : null}
                      <Typography type={TYPOGRAPHY_TYPES.BODY_S} className={styles.CancelDisclaimer}>
                        Please note that this reason will be sent to customers.
                      </Typography>
                    </div>
                  </div>
                  <div className={styles.ErrorContainer}>{status.apiError}</div>
                  <div className={styles.CancelOrderForm__actions}>
                    <Button type="submit" disabled={isSubmitting}>
                      {isSubmitting ? (
                        <React.Fragment>
                          <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                          Cancelling...
                        </React.Fragment>
                      ) : (
                        <React.Fragment>Cancel Order</React.Fragment>
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

CancelOrderDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  onSuccessfullyCancelledOrder: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
};

export default withErrorReports(withNotifications(CancelOrderDialog));
