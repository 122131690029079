import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Dialog, DialogContent, IconButton, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { round2DP } from '../../../utils/PortionSizePricingHelper/PortionSizePricingHelper';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import { presentCurrency } from '../../../utils/Presenters/PresentCurrency/PresentCurrency';
import styles from './EditCustomPriceDialog.module.scss';
import {
  ErrorNotification,
  FormikFormField,
  Button,
  Typography,
  NotificationMessageSection,
} from '../../../components/UI/FB';
import {
  TYPOGRAPHY_TYPES,
  NOTIFICATION_TYPES,
  NOTIFICATION_MESSAGE_TYPES,
  NOTIFICATION_MESSAGE_SIZES,
} from '../../../utils/Constants';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';

const EditCustomPriceDialog = ({
  isOpen,
  handleClose,
  customPrice,
  onUpdateComplete,
  onUpdateBegin,
  createNotification,
  updateCustomPriceData,
  sendDatadogError,
}) => {
  const customPriceValidationSchema = Yup.object({
    priceInDollars: Yup.number()
      .typeError('Custom price must be a number')
      .required('Custom price is required')
      .min(0, 'Custom price cannot be negative')
      .test('decimal-places', 'Custom price must have a maximum of 2 decimal places', (value) =>
        !value ? true : !(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2),
      ),
  });

  const attemptToUpdateCustomPrice = (values, actions) => {
    actions.setStatus({ apiError: undefined });
    onUpdateBegin();
    const priceInCentsIncGst = customPrice.product.gstExempt
      ? values.priceInDollars * 100
      : Math.round(values.priceInDollars * 1.1 * 100);

    const updatedCustomPrice = {
      ...customPrice,
      priceInCentsExGST: values.priceInDollars * 100,
      priceInCents: priceInCentsIncGst,
    };

    FoodbombAPI.patch('custom-pricing', {
      venueId: customPrice.venue.id,
      productId: customPrice.product.id,
      priceInCents: priceInCentsIncGst,
    })
      .then(() => {
        updateCustomPriceData(updatedCustomPrice);
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully updated custom price',
          timeout: 4000,
          closable: true,
        });
        handleClose();
      })
      .catch((error) => {
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to update custom price',
          timeout: 4000,
          closable: true,
        });
        if (error.response.status === 400 && error.response.data && error.response.data.errors) {
          const errorMessages = {};
          error.response.data.errors.forEach((e) => {
            if (e.code === 'invalid_parameter') {
              errorMessages[e.param] = e.message;
              actions.setErrors(errorMessages);
            }
          });
          // No custom business errors
        } else {
          sendDatadogError('Unable to update custom price', {
            error,
            location: 'Edit custom price modal',
          });
          actions.setStatus({
            apiError: (
              <ErrorNotification
                body={'We were unable to update this custom price. Our engineers have been notified!'}
              />
            ),
          });
        }
        actions.setSubmitting(false);
      })
      .finally(() => {
        onUpdateComplete();
      });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ root: styles.EditCustomPriceDialogRoot, paper: styles.EditCustomPriceDialogPaper }}
    >
      <DialogTitle className={styles.EditCustomPriceDialog__header}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Edit Custom Price</Typography>
        <IconButton className={styles.EditCustomPriceDialog__closeBtn} aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.EditCustomPriceDialog__content}>
        {customPrice ? (
          <React.Fragment>
            <div className={styles.CustomPriceSummary}>
              <div className={styles.CustomPriceSummary__row}>
                <div className={styles.CustomPriceSummary__title}>Venue→</div>
                <div className={styles.CustomPriceSummary__value}>{customPrice.venue.venue}</div>
              </div>
              <div className={styles.CustomPriceSummary__row}>
                <div className={styles.CustomPriceSummary__title}>Product →</div>
                <div className={styles.CustomPriceSummary__value}>{customPrice.product.name}</div>
              </div>
              <div className={styles.CustomPriceSummary__row}>
                <div className={styles.CustomPriceSummary__title}>Original (Ex. GST) →</div>
                <div className={styles.CustomPriceSummary__value}>
                  {presentCurrency(customPrice.product.priceInCents / 100)}/{customPrice.product.priceUnit}
                </div>
              </div>
            </div>
            <Formik
              initialValues={{ priceInDollars: customPrice.priceInCentsExGST / 100 }}
              validationSchema={customPriceValidationSchema}
              onSubmit={attemptToUpdateCustomPrice}
              initialStatus={{
                apiError: undefined,
              }}
            >
              {({ values, errors, touched, status, isSubmitting }) => (
                <Form>
                  <div className={styles.CustomPricingForm}>
                    <div className={styles.InputRow}>
                      <div className={styles.FlexInputContainer}>
                        <FormikFormField
                          fieldName="priceInDollars"
                          touched={touched}
                          errors={errors}
                          placeholder="Enter a custom price"
                          label={`Custom price per ${customPrice.product.priceUnit} ($ Ex.GST) `}
                          fieldProps={{
                            type: 'number',
                            step: '0.01',
                          }}
                        />
                        <div className={styles.CustomPricingForm__actions}>
                          <Button type="submit" disabled={isSubmitting} className={styles.SubmitBtn}>
                            {isSubmitting ? (
                              <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                            ) : (
                              <React.Fragment>Save</React.Fragment>
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {!customPrice.product.gstExempt ? (
                      <NotificationMessageSection
                        type={NOTIFICATION_MESSAGE_TYPES.WARNING}
                        size={NOTIFICATION_MESSAGE_SIZES.LARGE}
                      >
                        <Typography type={TYPOGRAPHY_TYPES.BODY}>
                          <strong>Note:</strong>&nbsp;This product has GST, hence the price for the venue will be&nbsp;
                          <strong>{presentCurrency(round2DP(values.priceInDollars * 1.1))}</strong>
                        </Typography>
                      </NotificationMessageSection>
                    ) : null}
                  </div>
                  <div className={styles.ErrorContainer}>{status.apiError}</div>
                </Form>
              )}
            </Formik>
          </React.Fragment>
        ) : (
          <div className={styles.InitialErrorContainer}>
            <ErrorNotification />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

EditCustomPriceDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  customPrice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    priceInCents: PropTypes.number.isRequired,
    priceInCentsExGST: PropTypes.number.isRequired,
    venue: PropTypes.shape({
      id: PropTypes.number.isRequired,
      venue: PropTypes.string.isRequired,
    }).isRequired,
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
      sku: PropTypes.string,
      name: PropTypes.string.isRequired,
      priceInCents: PropTypes.number.isRequired,
      priceUnit: PropTypes.string.isRequired,
      gstExempt: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  onUpdateComplete: PropTypes.func,
  onUpdateBegin: PropTypes.func,
  createNotification: PropTypes.func.isRequired,
  updateCustomPriceData: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
};

export default withErrorReports(withNotifications(EditCustomPriceDialog));
