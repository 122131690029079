/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationAdd, notificationRemove } from '../../reduxStore/actions/NotificationActions';
import { NotificationConfig, ReduxState, Notification, TargetGroup } from '../../utils/Presenters/ReduxType';

const useNotifications = (): {
  createNotification: (notificationConfig: NotificationConfig) => void;
} => {
  const currentNotifications: Notification[] = useSelector((state: ReduxState) => state.notifications.notifications);
  const dispatch = useDispatch();

  // Callback Mapped from Redux Method
  const addNotification = useCallback((notification: NotificationConfig): void => {
    dispatch(notificationAdd(notification));
  }, []);

  // Callback mapped from Redux Method
  const removeNotification = useCallback((notificationId: number): void => {
    dispatch(notificationRemove(notificationId));
  }, []);

  const removeNotificationOfTargetGroup = useCallback((targetGroup?: TargetGroup): void => {
    const notificationToRemove = currentNotifications.find(
      (notification: Notification) => notification.uniqueTargetGroup === targetGroup,
    );
    if (targetGroup && notificationToRemove) {
      removeNotification(notificationToRemove.id);
    }
  }, []);

  const createNotification = useCallback((notificationConfig: NotificationConfig): void => {
    if (notificationConfig.uniqueTargetGroup) {
      removeNotificationOfTargetGroup(notificationConfig.uniqueTargetGroup);
    }
    addNotification(notificationConfig);
  }, []);
  return {
    createNotification,
  };
};

export default useNotifications;
