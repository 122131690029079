import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Formik, Form, ErrorMessage } from 'formik';
import { InputLabel, CircularProgress, FormControlLabel } from '@material-ui/core';
import { START_DATE, END_DATE } from 'react-nice-dates';
import Moment from 'moment';

import {
  calculatePriceIncGST,
  buildPriceLabelForProduct,
  buildPriceIncGSTLabelForProduct,
} from '../../../utils/PortionSizePricingHelper/PortionSizePricingHelper';
import withRedirectHelper from '../../../hoc/withRedirectHelper/withRedirectHelper';
import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import useNotifications from '../../../hooks/useNotifications/useNotifications.ts';
import DateRangePicker from '../../../components/DatePicker/DateRangePicker';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import {
  ErrorNotification,
  FormikSingleSelectField,
  Typography,
  Paper,
  Button,
  JoinedToggleButton,
  Checkbox,
  NotificationMessageSection,
} from '../../../components/UI/FB';
import { BUTTON_VARIANTS, TYPOGRAPHY_TYPES, NOTIFICATION_TYPES, MOMENT_FORMATS } from '../../../utils/Constants';
import styles from './NewSpecial.module.scss';

const DISCOUNT_TYPES = ['LOWER PRICE', 'PERCENTAGE OFF'];
const PRICE_TYPE = DISCOUNT_TYPES[0];
const ERROR_CODES = {
  INVALID_PARAMETER: 'invalid_parameter',
  MAXIMUM_REACHED: 'maximum_reached',
};

const NewSpecial = ({ redirectToSpecials, isStaff, sendDatadogError }) => {
  const [products, setProducts] = useState([]);
  const [visualStartDate, setVisualStartDate] = useState('');
  const [visualEndDate, setVisualEndDate] = useState('');
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [productError, setProductError] = useState(undefined);
  const [fakePercentageFieldValue, setFakePercentageFieldValue] = useState('');
  const { createNotification } = useNotifications();

  const buildLabelForProduct = useCallback(
    (product) => `${product.sku ? `${product.sku},` : ''} ${product.name} (${buildPriceLabelForProduct(product)})`,
    [],
  );

  useEffect(() => {
    setLoadingProducts(true);
    setProductError(undefined);
    FoodbombAPI.get(`suppliers/products`, { credentials: 'include' })
      .then((response) => {
        setProducts(
          response.data.data.map((product) => ({
            ...product,
            label: buildLabelForProduct(product),
            value: product.id,
          })),
        );
      })
      .catch((error) => {
        sendDatadogError('Unable to fetch products for supplier', {
          error,
          location: 'Create special page',
        });
        setProductError('Unable to load products');
      })
      .finally(() => {
        setLoadingProducts(false);
      });
  }, [buildLabelForProduct, sendDatadogError]);

  const buildAPIErrorNotification = (customTitle, customBody, customEmoji) => (
    <ErrorNotification
      title={customTitle}
      body={customBody || "We were unable to create this special. Don't worry, our engineers have been notified!"}
      customEmoji={customEmoji}
      hideContactInfo={Boolean(customBody)}
    />
  );

  const mapAPIParmToUIField = (param) => {
    switch (param) {
      case 'priceInCents':
        return 'specialPrice';
      default:
        return param;
    }
  };

  const attemptToCreateSpecial = (values, actions) => {
    const productInQuestion = products.find((product) => product.id === values.productId);
    const specialPriceInCents = productInQuestion.gstExempt
      ? Math.round(values.specialPrice * 100)
      : Math.round(calculatePriceIncGST(values.specialPrice * 100));
    FoodbombAPI.post('specials', {
      productId: values.productId,
      priceInCents: specialPriceInCents,
      startDate: values.startDate,
      endDate: values.endDate,
      isBanner: values.isBanner,
    })
      .then(() => {
        redirectToSpecials();
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Successfully created special for ${productInQuestion.name} at $${values.specialPrice}/${productInQuestion.unitOfPrice.unit}`,
          timeout: 4000,
          closable: true,
        });
      })
      .catch((error) => {
        actions.setSubmitting(false);
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to create special. Please see the errors above',
          timeout: 4000,
          closable: true,
        });
        if (error.response?.status === 400 && error.response?.data?.errors) {
          const errorMessages = {};
          error.response.data.errors.forEach((e) => {
            if (e.code === ERROR_CODES.INVALID_PARAMETER) {
              errorMessages[mapAPIParmToUIField(e.param)] = e.message;
              actions.setErrors(errorMessages);
            } else {
              actions.setStatus({ apiError: buildAPIErrorNotification('Whoops', e.message) });
            }
          });
        } else {
          actions.setStatus({ apiError: buildAPIErrorNotification() });
          sendDatadogError('Unable to create special', {
            error,
            location: 'Create special page',
          });
        }
      });
  };

  const getUnitOfPriceStringForProductId = (productId) => {
    const productInQuestion = products.find((product) => product.value === productId);
    if (productInQuestion) {
      const unitOfPrice = productInQuestion.unitOfPrice.unit;
      return `per ${unitOfPrice}`;
    }
    return '';
  };

  const specialValidationSchema = Yup.object().shape({
    productId: Yup.number().integer().required('Product is required'),
    startDate: Yup.date()
      .min(Moment().subtract(1, 'days'), 'Date must be in the future')
      .required('Start date is required'),
    endDate: Yup.date()
      .min(Moment().subtract(1, 'days'), 'Date must be in the future')
      .required('End date is required'),
    discountType: Yup.mixed()
      .oneOf(DISCOUNT_TYPES, 'Please select a valid type of discount')
      .required('Type of discount is required'),
    specialPrice: isStaff
      ? Yup.number()
          .min(0.1, 'Special price must be at least 10 cents')
          .required('Special price is required')
          .test(
            'staff-price-validator',
            'Special price must be at least 1% less than the current price',
            function validatedSpecialPriceForStaff(value) {
              if (!this.parent.productId) {
                return false;
              }
              const originalPrice = products.find((product) => product.id === this.parent.productId).priceInCents;
              return value * 100 <= Math.round(0.99 * originalPrice);
            },
          )
      : Yup.number()
          .typeError('Special price must be at least 5% less than the current price')
          .required('Special price is required')
          .min(0.1, 'Special price must be at least 10 cents')
          .test(
            'price-validator',
            'Special price must be at least 5% less than the current price',
            function validatedSpecialPrice(value) {
              if (!this.parent.productId) {
                return false;
              }
              const originalPrice = products.find((product) => product.id === this.parent.productId).priceInCents;
              return value * 100 <= Math.round(0.95 * originalPrice);
            },
          ),
  });

  return (
    <div>
      <div className={styles.HeaderContainer}>
        <div className={styles.HeaderContent}>
          <Typography type={TYPOGRAPHY_TYPES.HEADING_XXL}>Create Special</Typography>
          <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.Font__standardGrey}>
            Select the product you would like to put on special
          </Typography>
        </div>
      </div>
      {productError ? (
        <div className={styles.ProductsErrorContainer}>
          <ErrorNotification
            actions={
              <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={redirectToSpecials}>
                Return to all specials
              </Button>
            }
          />
        </div>
      ) : (
        <React.Fragment>
          <Formik
            initialValues={{
              productId: '',
              startDate: undefined,
              endDate: undefined,
              discountType: DISCOUNT_TYPES[0],
              specialPrice: '',
              specialpercentage: '',
              isBanner: false,
            }}
            validationSchema={specialValidationSchema}
            onSubmit={attemptToCreateSpecial}
            initialStatus={{
              apiError: undefined,
            }}
          >
            {({ errors, touched, values, status, setFieldValue, setTouched, isSubmitting }) => (
              <Form>
                <div className={styles.SpecialContentContainer}>
                  <Paper className={styles.SpecialFormContainer}>
                    <Typography type={TYPOGRAPHY_TYPES.HEADING_XL} className={styles.BlockHeading}>
                      Special Details
                    </Typography>
                    <Typography type={TYPOGRAPHY_TYPES.BODY}>
                      Select the product you want to put on special and enter the required details.
                    </Typography>
                    <Typography type={TYPOGRAPHY_TYPES.BODY}>
                      You can put a product on special for a maximum of <span>2 weeks</span>&nbsp;at a time.
                    </Typography>
                    <div className={styles.SpecialForm}>
                      <div className={styles.InputRow}>
                        <div className={styles.ProductSelectInputContainer}>
                          <FormikSingleSelectField
                            label="Product"
                            fieldName="productId"
                            errors={errors}
                            touched={touched}
                            setTouched={setTouched}
                            setFieldValue={setFieldValue}
                            value={products.find((product) => product.id === values.productId)}
                            isLoading={loadingProducts}
                            loadingMessage={() => 'Loading product list...'}
                            options={products}
                            placeholder="Select from your products (Search by name or SKU)"
                            hideErrors
                          />
                          <div className={styles.ErrorMessageContainer}>
                            {errors.productId ? (
                              <ErrorMessage name="productId" />
                            ) : (
                              <React.Fragment>
                                {values.productId ? (
                                  <div className={styles.GreenSecondaryText}>
                                    Original price (Ex.GST):&nbsp;
                                    {buildPriceLabelForProduct(
                                      products.find((product) => product.id === values.productId),
                                    )}
                                  </div>
                                ) : null}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={styles.InputRow}>
                        <DateRangePicker
                          startDate={values.startDate}
                          endDate={values.endDate}
                          onStartDateChange={(date) => {
                            setFieldValue('startDate', date, true);
                            setVisualStartDate(Moment(date).format(MOMENT_FORMATS.SIMPLE_DATE));
                          }}
                          onEndDateChange={(date) => {
                            setFieldValue('endDate', date, true);
                            setVisualEndDate(Moment(date).format(MOMENT_FORMATS.SIMPLE_DATE));
                          }}
                          minimumDate={new Date()}
                          format="dd MMM yyyy"
                          maximumDate={
                            values.startDate
                              ? Moment(values.startDate).add(27, 'days').toDate()
                              : Moment().add(30, 'days').toDate()
                          }
                        >
                          {({ startDateInputProps, endDateInputProps, focus }) => (
                            <div className={styles.DateInputsContainer}>
                              <div className={styles.DateInput}>
                                <InputLabel
                                  className={
                                    touched.startDate && errors.startDate
                                      ? [styles.InputLabel, styles.error].join(' ')
                                      : styles.InputLabel
                                  }
                                >
                                  Start Date
                                </InputLabel>
                                <input
                                  className={
                                    focus === START_DATE
                                      ? [styles.InputField, styles.focused].join(' ')
                                      : styles.InputField
                                  }
                                  {...startDateInputProps}
                                  value={visualStartDate}
                                  placeholder="Start date"
                                  onBlur={() => setTouched({ ...touched, startDate: true })}
                                />
                              </div>
                              <ArrowForwardIosIcon className={styles.BetweenDatesIcon} />
                              <div className={styles.DateInput}>
                                <InputLabel
                                  className={
                                    touched.endDate && errors.endDate
                                      ? [styles.InputLabel, styles.error].join(' ')
                                      : styles.InputLabel
                                  }
                                >
                                  End Date
                                </InputLabel>
                                <input
                                  className={
                                    focus === END_DATE
                                      ? [styles.InputField, styles.focused].join(' ')
                                      : styles.InputField
                                  }
                                  {...endDateInputProps}
                                  value={visualEndDate}
                                  placeholder="End date"
                                  onBlur={() => setTouched({ ...touched, endDate: true })}
                                />
                              </div>
                            </div>
                          )}
                        </DateRangePicker>
                      </div>
                      <div className={styles.InputRow}>
                        <div className={styles.Input__halfWidth}>
                          <div className={styles.ErrorMessageContainer}>
                            <ErrorMessage name="startDate" />
                          </div>
                        </div>
                        <div className={styles.Input__halfWidth}>
                          <div className={styles.ErrorMessageContainer}>
                            <ErrorMessage name="endDate" />
                          </div>
                        </div>
                      </div>
                      <div className={styles.InputRow}>
                        <div>
                          <InputLabel
                            className={
                              touched.discountType && errors.discountType
                                ? [styles.InputLabel, styles.error].join(' ')
                                : styles.InputLabel
                            }
                          >
                            Type of discount
                          </InputLabel>
                          <JoinedToggleButton
                            className={styles.DiscountTypesButton}
                            buttonALabel={`$ ${DISCOUNT_TYPES[0]}`}
                            buttonBLabel={`% ${DISCOUNT_TYPES[1]}`}
                            onClickButtonA={() => {
                              setTouched({ ...touched, discountType: true });
                              setFieldValue('discountType', DISCOUNT_TYPES[0]);
                            }}
                            onClickButtonB={() => {
                              setTouched({ ...touched, discountType: true });
                              setFieldValue('discountType', DISCOUNT_TYPES[1]);
                            }}
                            buttonAActive={values.discountType === DISCOUNT_TYPES[0]}
                            buttonBActive={values.discountType === DISCOUNT_TYPES[1]}
                          />
                          <div className={styles.ErrorMessageContainer}>
                            <ErrorMessage name="discountType" />
                          </div>
                        </div>
                      </div>
                      <div className={styles.InputRow}>
                        {values.discountType === PRICE_TYPE ? (
                          <div className={styles.Input__fullWidth}>
                            <InputLabel
                              className={
                                touched.specialPrice && errors.specialPrice
                                  ? [styles.InputLabel, styles.error].join(' ')
                                  : styles.InputLabel
                              }
                            >
                              {`Special price ($ Ex.GST) ${getUnitOfPriceStringForProductId(values.productId)}`}
                            </InputLabel>
                            <input
                              placeholder={'Enter new product price'}
                              className={styles.InputField}
                              type="number"
                              step="0.01"
                              disabled={!values.productId}
                              value={values.specialPrice}
                              onChange={(e) => {
                                if (values.productId) {
                                  setTouched({ ...touched, specialPrice: true });
                                  setFieldValue('specialPrice', parseFloat(e.currentTarget.value));
                                  const percentageDiscount = (
                                    (1 -
                                      e.currentTarget.value /
                                        (products.find((product) => product.id === values.productId).priceInCents /
                                          100)) *
                                    100
                                  ).toFixed(2);
                                  setFakePercentageFieldValue(percentageDiscount);
                                }
                              }}
                            />
                            <div className={styles.ErrorMessageContainer}>
                              {values.productId && values.specialPrice && !errors.specialPrice ? (
                                <div className={styles.GreenSecondaryText}>
                                  {`Discount offered: ${fakePercentageFieldValue}%`}
                                </div>
                              ) : (
                                <ErrorMessage name="specialPrice" />
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.Input__fullWidth}>
                            <InputLabel
                              className={
                                touched.discountType && errors.discountType
                                  ? [styles.InputLabel, styles.error].join(' ')
                                  : styles.InputLabel
                              }
                            >
                              Special percentage discount (%)
                            </InputLabel>
                            <input
                              placeholder={'Enter percentage discount'}
                              className={styles.InputField}
                              type="number"
                              step="0.01"
                              disabled={!values.productId}
                              value={fakePercentageFieldValue}
                              onChange={(e) => {
                                const selectedPercentageDiscount = e.currentTarget.value;
                                if (values.productId) {
                                  const originalPriceInCents = products.find(
                                    (product) => product.id === values.productId,
                                  ).priceInCents;
                                  const calculatedSpecialPrice =
                                    Math.round((1 - selectedPercentageDiscount / 100) * originalPriceInCents) / 100;
                                  setTouched({ ...touched, specialPrice: true });
                                  setFieldValue('specialPrice', calculatedSpecialPrice);
                                  setFakePercentageFieldValue(selectedPercentageDiscount);
                                }
                              }}
                            />
                            <div className={styles.ErrorMessageContainer}>
                              {values.productId && values.specialPrice && !errors.specialPrice ? (
                                <div className={styles.GreenSecondaryText}>
                                  Discount offered:&nbsp; $
                                  {(
                                    products.find((product) => product.id === values.productId).priceInCents / 100 -
                                    values.specialPrice
                                  ).toFixed(2)}
                                </div>
                              ) : (
                                <ErrorMessage name="specialPrice" />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div></div>
                    </div>
                  </Paper>
                  <Paper className={styles.SpecialSummary}>
                    <div className={styles.SpecialSummary__header}>
                      <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
                        <span role="img" aria-label="flame">
                          🔥
                        </span>
                      </Typography>
                      <Typography type={TYPOGRAPHY_TYPES.HEADING_XL} className={styles.BlockHeading}>
                        Special Summary
                      </Typography>
                    </div>
                    <div className={styles.SpecialSummary__summary}>
                      <Typography type={TYPOGRAPHY_TYPES.BODY}>
                        Product:&nbsp;
                        <span className={styles.Bold}>
                          {values.productId ? (
                            <React.Fragment>
                              {products.find((product) => product.id === values.productId).name}
                            </React.Fragment>
                          ) : (
                            '--'
                          )}
                        </span>
                      </Typography>
                      <div className={styles.FakeLineBreak}></div>
                      <Typography type={TYPOGRAPHY_TYPES.BODY}>
                        Start Date:{' '}
                        <span className={styles.Bold}>
                          {values.startDate ? Moment(values.startDate).format(MOMENT_FORMATS.SIMPLE_DATE) : '--'}
                        </span>
                      </Typography>
                      <Typography type={TYPOGRAPHY_TYPES.BODY}>
                        End Date:{' '}
                        <span className={styles.Bold}>
                          {values.endDate ? Moment(values.endDate).format(MOMENT_FORMATS.SIMPLE_DATE) : '--'}
                        </span>
                      </Typography>
                      <Typography type={TYPOGRAPHY_TYPES.BODY}>
                        Duration:{' '}
                        <span className={styles.Bold}>
                          {values.startDate && values.endDate
                            ? `${Moment(values.endDate).diff(values.startDate, 'days') + 1} days`
                            : '--'}
                        </span>
                      </Typography>
                      <div className={styles.FakeLineBreak}></div>
                      <Typography type={TYPOGRAPHY_TYPES.BODY}>
                        Original price (Ex.GST):&nbsp;
                        <span className={styles.Bold}>
                          {values.productId
                            ? `${buildPriceLabelForProduct(
                                products.find((product) => product.id === values.productId),
                              )}`
                            : '--'}
                        </span>
                      </Typography>
                      {products.find((product) => product.id === values.productId) &&
                      !products.find((product) => product.id === values.productId).gstExempt ? (
                        <Typography type={TYPOGRAPHY_TYPES.BODY}>
                          Original price (Inc.GST):&nbsp;
                          <span className={styles.Bold}>
                            {values.productId
                              ? `${buildPriceIncGSTLabelForProduct(
                                  products.find((product) => product.id === values.productId),
                                )}`
                              : '--'}
                          </span>
                        </Typography>
                      ) : null}
                      <Typography type={TYPOGRAPHY_TYPES.BODY}>
                        Discounted price (Ex.GST):&nbsp;
                        <span className={styles.Bold}>
                          {values.specialPrice
                            ? `$${values.specialPrice.toFixed(2)}/${
                                products.find((product) => product.id === values.productId).unitOfPrice.unit
                              }`
                            : '--'}
                        </span>
                      </Typography>
                      {products.find((product) => product.id === values.productId) &&
                      !products.find((product) => product.id === values.productId).gstExempt ? (
                        <Typography type={TYPOGRAPHY_TYPES.BODY}>
                          Discounted price (Inc.GST):&nbsp;
                          <span className={styles.Bold}>
                            {values.specialPrice
                              ? `$${calculatePriceIncGST(values.specialPrice)}/${
                                  products.find((product) => product.id === values.productId).unitOfPrice.unit
                                }`
                              : '--'}
                          </span>
                        </Typography>
                      ) : null}
                      <Typography type={TYPOGRAPHY_TYPES.BODY}>
                        Discount:&nbsp;
                        <span className={styles.Bold}>
                          {values.productId && values.specialPrice ? `${fakePercentageFieldValue}%` : '--'}
                        </span>
                      </Typography>
                      {isStaff ? (
                        <div className={styles.FeatureSpecialCheckboxContainer}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isBanner}
                                onChange={(e) => {
                                  setTouched({ ...touched, isBanner: true });
                                  setFieldValue('isBanner', e.currentTarget.checked, true);
                                }}
                              />
                            }
                            label="Feature this special"
                            classes={{
                              label: styles.CheckboxLabel,
                            }}
                          />
                        </div>
                      ) : null}
                      {status.apiError ? (
                        <div>
                          <div className={styles.FakeLineBreak}></div>
                          {status.apiError}
                        </div>
                      ) : null}
                      <div className={styles.FakeLineBreak}></div>
                      {fakePercentageFieldValue > 30 ? (
                        <React.Fragment>
                          <NotificationMessageSection type={'error'}>
                            <span>Warning: This is a significant discount!</span>
                          </NotificationMessageSection>
                        </React.Fragment>
                      ) : null}
                      {products.find((product) => product.id === values.productId) &&
                      !products.find((product) => product.id === values.productId).gstExempt ? (
                        <React.Fragment>
                          <NotificationMessageSection type={'warning'}>
                            <span>Note: We&apos;ll automatically add GST to this product.</span>
                            <br />
                            <span>
                              {values.specialPrice ? `The price for customers will be ` : ''}
                              <span className={styles.Bold}>
                                {values.specialPrice
                                  ? `$${calculatePriceIncGST(values.specialPrice)}/${
                                      products.find((product) => product.id === values.productId).unitOfPrice.unit
                                    }`
                                  : ''}
                              </span>
                            </span>
                          </NotificationMessageSection>
                        </React.Fragment>
                      ) : null}
                      <div className={styles.SubmitBtnContainer}>
                        <Button type="submit" disabled={isSubmitting || Boolean(errors && Object.keys(errors).length)}>
                          {isSubmitting ? (
                            <CircularProgress size={24} className={styles.SubmitBtn__loading} />
                          ) : (
                            <React.Fragment>Publish Special</React.Fragment>
                          )}
                        </Button>
                      </div>
                    </div>
                  </Paper>
                </div>
              </Form>
            )}
          </Formik>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isStaff: state.auth.supplierDetails ? state.auth.supplierDetails.isStaff : false,
});

NewSpecial.propTypes = {
  redirectToSpecials: PropTypes.func.isRequired,
  isStaff: PropTypes.bool,
  sendDatadogError: PropTypes.func.isRequired,
};

export default withErrorReports(withRedirectHelper(connect(mapStateToProps, null)(NewSpecial)));
