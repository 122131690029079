import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Helpers
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import buildLogDetails from '../../utils/DatadogHelper.ts';
import * as actions from '../../store/actions';
// HOCs
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import ProtectedRoute from '../../hoc/ProtectedRoute/ProtectedRoute';
// Utils
import { checkIfTokenExpired } from '../../utils/TokenHelper';
import {
  DeliveryPreferencesAPI,
  FoodbombAPI,
  FoodbombPDFAPI,
  UnauthenticatedFoodbombAPI,
} from '../../utils/AxiosInstances';
// Components
import Navigation from '../../components/Navigation/Navigation';
import Dashboard from '../Dashboard/Dashboard';
import ProductRoutes from '../Products/ProductRoutes';
import OrderRoutes from '../Orders/OrdersRoutes.tsx';
import SpecialRoutes from '../Specials/SpecialRoutes.tsx';
import Settings from '../Settings/Settings';
import LossLeaders from '../LossLeaders/LossLeaders.tsx';
import CustomPricingRoutes from '../CustomPricing/CustomPricingRoutes.tsx';
import Reports from '../Reports/Reports';
import Reviews from '../Reviews/Reviews';
import DeliveryZones from '../DeliveryZones/DeliveryZones';
import Maintenance from '../Maintenance/Maintenance';
import Notifications from '../Notifications/Notifications.tsx';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import StaffMasqueradingBanner from '../../components/StaffMasqueradingBanner/StaffMasqueradingBanner';
import LargeLoadingSpinner from '../../components/UI/LargeLoadingSpinner/LargeLoadingSpinner';
import Login from '../Login/Login';
import Logout from '../Logout/Logout';
import { FB_API_VERSION, MAINTENANCE_FLAG, MAINTENANCE_HEADER } from '../../utils/Constants';
import {
  FOODBOMB_API_BASE_URL,
  FOODBOMB_PDF_API_BASE_URL,
  DELIVERY_PREFERENCES_API_BASE_URL,
} from '../../utils/AxiosInstances/BaseURLs';
import styles from './App.module.scss';

// TODO: Kill this shit
import '../../App.css';
import Customers from '../Customers/Customers.tsx';

const NETWORK_STATUSES = {
  OFFLINE: 'offline',
  ONLINE: 'online',
};

const BEFORE_UNLOAD = 'beforeunload';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      delightedTriggered: false,
      supplierData: undefined,
      isMaintenanceMode: false,
      loadingInitialHealthCheck: true,
      maintenanceRefreshRate: 2 * 60 * 1000,
    };

    this.initialiseDataDogListener();
    this.initialiseAxiosInstances();
    this.initialHealthCheck();
    this.props.attemptAutoSignIn();
  }

  componentDidMount() {
    window.addEventListener(NETWORK_STATUSES.OFFLINE, this.handleNetworkStatusChange);
    window.addEventListener(NETWORK_STATUSES.ONLINE, this.handleNetworkStatusChange);
    window.addEventListener(BEFORE_UNLOAD, this.applicationCleanUp);
    if (this.props.hasToken) {
      this.loadSupplierData();
    }
  }

  loadSupplierData() {
    const { supplierId, sendDatadogError } = this.props;
    FoodbombAPI.get(`suppliers/auth/me`)
      .then((response) => {
        this.setState({
          supplierData: response.data,
        });
      })
      .catch((error) => {
        sendDatadogError(`Unable to load supplier details: ${supplierId}`, {
          error,
          location: 'App init',
        });
      });
  }

  componentDidUpdate() {
    const { isStaff, hasToken, supplierId } = this.props;
    const { supplierData } = this.state;

    if (!this.state.delightedTriggered && !isStaff && hasToken && supplierData && window.delighted) {
      window.delighted.survey({
        email: supplierData.email,
        name: supplierData.company,
        properties: {
          market: 'supplier',
          supplierId,
          phone: supplierData.phone,
        },
        initialDelay: 120,
        minTimeOnPage: 10,
      });
      this.setState({
        delightedTriggered: true,
      });
    }
  }

  potentiallyLogOutMasqueradingStaffMember = () => {
    const { hasToken, isStaff, handleLogout } = this.props;

    if (process.env.REACT_APP_DISABLE_ADMIN_AUTO_LOGOUT !== 'true' && hasToken && isStaff) {
      handleLogout();
    }
  };

  applicationCleanUp = () => {
    this.potentiallyLogOutMasqueradingStaffMember();
    window.removeEventListener(NETWORK_STATUSES.OFFLINE, this.handleNetworkStatusChange);
    window.removeEventListener(NETWORK_STATUSES.ONLINE, this.handleNetworkStatusChange);
    window.heap.clearEventProperties();
  };

  componentWillUnmount() {
    this.applicationCleanUp();
    window.removeEventListener(BEFORE_UNLOAD, this.applicationCleanUp);
  }

  initialiseDataDogListener = () => {
    const ddApplicationId = `${process.env.REACT_APP_DD_APPLICATION_ID}`;
    const ddClientToken = `${process.env.REACT_APP_DD_CLIENT_TOKEN}`;
    const ddSite = `${process.env.REACT_APP_DD_SITE}`;
    const appEnv = `${process.env.REACT_APP_ENVIRONMENT}`;
    const appVersion = `${process.env.REACT_APP_VERSION}`;
    const appName = `${process.env.REACT_APP_NAME}`;
    const appHost = window.location.origin;

    const isDatadogRumEnabled = `${process.env.REACT_APP_IS_DD_RUM_ENABLED}` === 'true';
    if (!isDatadogRumEnabled) {
      return;
    }

    datadogLogs.addLoggerGlobalContext('host', appHost);
    datadogRum.addRumGlobalContext('host', appHost);

    const datadogDefaultConfiguration = {
      clientToken: ddClientToken,
      site: ddSite,
      service: appName,
      env: appEnv,
      version: appVersion,
      silentMultipleInit: true,
    };

    datadogLogs.init({
      ...datadogDefaultConfiguration,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
    });

    datadogLogs.onReady(() => {
      datadogLogs.logger.info('Datadog is ready to collect logs');
      datadogRum.init({
        ...datadogDefaultConfiguration,
        applicationId: ddApplicationId,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        allowedTracingUrls: [FOODBOMB_API_BASE_URL],
        defaultPrivacyLevel: 'allow',
      });

      datadogRum.onReady(() => {
        datadogLogs.logger.info('Datadog is ready to start recording sessions');
        datadogRum.startSessionReplayRecording();
      });
    });
  };

  interceptLogRequestsToDDFor = (axiosInstance) => {
    axiosInstance.interceptors.response.use(
      (response) => {
        const { message, context } = buildLogDetails(response);
        datadogLogs.logger.info(message, context);
        return response;
      },
      (error) => {
        if (error?.response) {
          const { message, context } = buildLogDetails(error.response);
          datadogLogs.logger.error(message, context);
        }
        return Promise.reject(error);
      },
    );
  };

  handleNetworkStatusChange = (e) => {
    this.props.updateNetworkNotification(e.type === NETWORK_STATUSES.OFFLINE);
  };

  logout = () => {
    this.props.history.push('/logout');
  };

  logoutAndDisplayNotification = () => {
    this.props.createExpiredSessionNotification();
    this.logout();
  };

  catchExpiredJWTAndLogoutFor = (axiosInstance) => {
    axiosInstance.interceptors.request.use(
      (config) => {
        const tokenIsExpired = checkIfTokenExpired(config.headers.common.Authorization);
        if (this.props.hasToken && tokenIsExpired) {
          this.logoutAndDisplayNotification();
          return {
            ...config,
            cancelToken: axiosInstance.CancelToken((cancel) => cancel('Authorisation expired')),
          };
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
  };

  intercept401sAndRedirectFor = (axiosInstance) => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (this.props.hasToken && error.response && error.response.status === 401) {
          this.logoutAndDisplayNotification();
          return new Promise(() => {});
        }
        if (axiosInstance.isCancel(error)) {
          return new Promise(() => {});
        }
        return Promise.reject(error);
      },
    );
  };

  handleBrowserRefreshForAPIVersionMismatch = (axiosInstance, currentAPIVersion) => {
    let storedAPIVersion;
    let updateAPIVersion;

    switch (axiosInstance.defaults.baseURL) {
      case FOODBOMB_API_BASE_URL:
        storedAPIVersion = this.props.foodbombAPIVersion;
        updateAPIVersion = this.props.onSetFoodbombAPIVersion;
        break;
      case FOODBOMB_PDF_API_BASE_URL:
        storedAPIVersion = this.props.foodbombPDFAPIVersion;
        updateAPIVersion = this.props.onSetFoodbombPDFAPIVersion;
        break;
      case DELIVERY_PREFERENCES_API_BASE_URL:
        // TODO Delivery Preferences API is not attaching fb-api-version to headers
        storedAPIVersion = this.props.deliveryPreferencesAPIVersion;
        updateAPIVersion = this.props.onSetDeliveryPreferencesAPIVersion;
        break;
      default:
        throw new Error(`Unexpected base URL: ${axiosInstance.defaults.baseURL}`);
    }

    if (storedAPIVersion && currentAPIVersion) {
      if (currentAPIVersion !== storedAPIVersion) {
        window.location.reload(true);
      }
    } else if (currentAPIVersion) {
      // eslint-disable-next-line no-param-reassign
      axiosInstance.defaults.headers.common[FB_API_VERSION] = currentAPIVersion;
      updateAPIVersion(currentAPIVersion);
    }
  };

  applyVersionInterceptorFor = (axiosInstance) => {
    axiosInstance.interceptors.response.use(
      (response) => {
        const currentAPIVersion = response?.headers?.[FB_API_VERSION];
        this.handleBrowserRefreshForAPIVersionMismatch(axiosInstance, currentAPIVersion);
        return response;
      },
      (error) => {
        const currentAPIVersion = error?.response?.headers?.[FB_API_VERSION];
        if (currentAPIVersion) {
          this.handleBrowserRefreshForAPIVersionMismatch(axiosInstance, currentAPIVersion);
        }
        return Promise.reject(error);
      },
    );
  };

  enableMaintenanceMode = () => {
    const { isMaintenanceMode, maintenanceRefreshRate } = this.state;
    const { updateMaintenanceRetryError } = this.props;
    if (!isMaintenanceMode) {
      setTimeout(() => {
        this.periodicallyCheckHealthCheck();
      }, maintenanceRefreshRate);
      updateMaintenanceRetryError(maintenanceRefreshRate);
    }
    this.setState({ isMaintenanceMode: true });
  };

  applyMaintenanceModeInterceptorFor = (axiosInstance) => {
    axiosInstance.interceptors.response.use(
      (response) => {
        const maintenanceHeader = response?.headers?.[MAINTENANCE_HEADER];
        if (maintenanceHeader && parseInt(maintenanceHeader, 10)) {
          this.enableMaintenanceMode();
        }
        return response;
      },
      (error) => {
        const maintenanceHeader = error?.response?.headers?.[MAINTENANCE_HEADER];
        if (maintenanceHeader && parseInt(maintenanceHeader, 10)) {
          this.enableMaintenanceMode();
        }
        return Promise.reject(error);
      },
    );
  };

  initialHealthCheck = () => {
    UnauthenticatedFoodbombAPI.get('/')
      .then((response) => {
        this.setState({ loadingInitialHealthCheck: false });
        if (response?.data?.[MAINTENANCE_FLAG]) {
          this.enableMaintenanceMode();
        }
      })
      .catch(() => {
        this.setState({ loadingInitialHealthCheck: false });
      });
  };

  periodicallyCheckHealthCheck = () => {
    const { maintenanceRefreshRate } = this.state;
    FoodbombAPI.get('/')
      .then((response) => {
        if (response.data === MAINTENANCE_FLAG) {
          throw new Error('Maintenance mode detected');
        }
        window.setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        setTimeout(() => {
          this.periodicallyCheckHealthCheck();
        }, maintenanceRefreshRate);
        this.props.updateMaintenanceRetryError(maintenanceRefreshRate);
      });
  };

  initialiseAxiosInstances = () => {
    [FoodbombAPI, FoodbombPDFAPI, DeliveryPreferencesAPI].forEach((instance) => {
      this.intercept401sAndRedirectFor(instance);
      this.catchExpiredJWTAndLogoutFor(instance);
      this.applyVersionInterceptorFor(instance);
    });

    [FoodbombAPI, UnauthenticatedFoodbombAPI, DeliveryPreferencesAPI].forEach((instance) => {
      this.interceptLogRequestsToDDFor(instance);
    });

    this.applyMaintenanceModeInterceptorFor(FoodbombAPI);
  };

  render() {
    const { hasToken, isStaff } = this.props;
    const { isMaintenanceMode, loadingInitialHealthCheck } = this.state;
    const PageWrapperStyles = [styles.PageWrapper];
    PageWrapperStyles.push(styles.withNavigation);
    if (hasToken) {
      PageWrapperStyles.push(styles.asAuthenticated);
    }

    const showMaintenanceScreen = process.env?.REACT_APP_MAINTENANCE_MODE === 'true' || isMaintenanceMode;
    if (showMaintenanceScreen) {
      return (
        <React.Fragment>
          <Notifications />
          <Maintenance />
          {isStaff ? <StaffMasqueradingBanner /> : null}
        </React.Fragment>
      );
    }

    if (loadingInitialHealthCheck) {
      return <LargeLoadingSpinner />;
    }

    return (
      <React.Fragment>
        <Notifications />
        <div className={styles.App}>
          <Navigation />
          <div className={PageWrapperStyles.join(' ')}>
            <Switch>
              <ProtectedRoute exact path="/dashboard" component={Dashboard} />
              <ProtectedRoute path="/products" component={ProductRoutes} />
              <ProtectedRoute path="/orders" component={OrderRoutes} />
              <ProtectedRoute path="/specials" component={SpecialRoutes} />
              <ProtectedRoute path="/customers" component={Customers} />
              <ProtectedRoute path="/custom-prices" component={CustomPricingRoutes} />
              <ProtectedRoute path="/loss-leaders" component={LossLeaders} />
              <ProtectedRoute path="/settings" component={Settings} />
              <ProtectedRoute path="/reports" component={Reports} />
              <ProtectedRoute path="/reviews" component={Reviews} />
              <ProtectedRoute path="/delivery-zones" component={DeliveryZones} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              {hasToken ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
            </Switch>
          </div>
        </div>
        {isStaff ? <StaffMasqueradingBanner /> : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  hasToken: Boolean(state.auth.token),
  isStaff: Boolean(state.auth?.supplierDetails?.isStaff),
  supplierId: state.auth?.supplierDetails?.id,
  foodbombAPIVersion: state.versions.foodbombAPIVersion,
  foodbombPDFAPIVersion: state.versions.foodbombPDFAPIVersion,
  deliveryPreferencesAPIVersion: state.versions.deliveryPreferencesAPIVersion,
});

const mapDispatchToProps = (dispatch) => ({
  attemptAutoSignIn: () => dispatch(actions.authCheckState()),
  onSetFoodbombAPIVersion: (foodbombAPIVersion) => dispatch(actions.setFoodbombAPIVersion(foodbombAPIVersion)),
  onSetFoodbombPDFAPIVersion: (foodbombPDFAPIVersion) =>
    dispatch(actions.setFoodbombPDFAPIVersion(foodbombPDFAPIVersion)),
  handleLogout: () => dispatch(actions.logout()),
  onSetDeliveryPreferencesAPIVersion: (deliveryPreferencesAPIVersion) =>
    dispatch(actions.setDeliveryPreferencesAPIVersion(deliveryPreferencesAPIVersion)),
});

App.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hasToken: PropTypes.bool.isRequired,
  attemptAutoSignIn: PropTypes.func.isRequired,
  isStaff: PropTypes.bool,
  supplierId: PropTypes.number,
  updateNetworkNotification: PropTypes.func.isRequired,
  createExpiredSessionNotification: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  foodbombAPIVersion: PropTypes.string,
  foodbombPDFAPIVersion: PropTypes.string,
  deliveryPreferencesAPIVersion: PropTypes.string,
  onSetFoodbombAPIVersion: PropTypes.func.isRequired,
  onSetFoodbombPDFAPIVersion: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  onSetDeliveryPreferencesAPIVersion: PropTypes.func.isRequired,
  updateMaintenanceRetryError: PropTypes.func.isRequired,
};

export default withErrorReports(withRouter(withNotifications(connect(mapStateToProps, mapDispatchToProps)(App))));
