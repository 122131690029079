import Moment from 'moment';
import React from 'react';
import { Emoji } from '../../components/UI/FB';
import styles from './DateHelper.module.scss';

export const isToday = (date) => Moment(date).startOf('day').isSame(Moment().startOf('day'));

export const isTomorrow = (date) => Moment(date).startOf('day').isSame(Moment().add(1, 'days').startOf('day'));

export const getDateLabel = (date, format) => {
  if (isToday(date)) {
    return (
      <span className={styles.BoldText}>
        <Emoji content="⏰" label="clock" />
        &nbsp;Today
      </span>
    );
  }
  if (isTomorrow(date)) {
    return (
      <span className={styles.BoldText}>
        <Emoji content="👉" label="pointer" />
        &nbsp;Tomorrow
      </span>
    );
  }
  return Moment(date).format(format || 'D MMM YYYY');
};

export const getDateText = (date, format) => {
  if (isToday(date)) return 'Today';
  if (isTomorrow(date)) return 'Tomorrow';
  return Moment(date).format(format || 'D MMM YYYY');
};
