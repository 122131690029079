import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import { Typography } from '../../../components/UI/FB';
import { TYPOGRAPHY_TYPES } from '../../../utils/Constants';

import styles from './BulkUploadProgressDialog.module.scss';

const BulkUploadProgressDialog = ({ isOpen, numberOfUploads, isComplete }) => {
  const SECONDS_PER_PRODUCT = 0.075; // estimation of upload time

  const [progressPercent, setProgressPercent] = useState(0);

  useEffect(() => {
    const estSecondCount = SECONDS_PER_PRODUCT * numberOfUploads;
    const incrementAmount = 100 / estSecondCount;
    const timer = setInterval(() => {
      setProgressPercent((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + incrementAmount));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [numberOfUploads]);

  return (
    <Dialog
      open={isOpen && numberOfUploads}
      keepMounted={false}
      classes={{ root: styles.Dialog__root, paper: styles.Dialog__paper }}
    >
      <DialogTitle className={styles.Dialog__title}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Processing Products</Typography>
      </DialogTitle>
      <DialogContent className={styles.Dialog__content}>
        <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.BodyText}>
          We&apos;re processing your product upload. This could take a minute.
        </Typography>
        <div className={styles.ProgressContainer}>
          <LinearProgress
            variant="determinate"
            value={isComplete ? 100 : progressPercent}
            className={styles.ProgressBar}
          />
        </div>
        <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.BodyText}>
          Please don&apos;t close this page.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

BulkUploadProgressDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  numberOfUploads: PropTypes.number.isRequired,
  isComplete: PropTypes.bool.isRequired,
};

export default BulkUploadProgressDialog;
