import React, { ChangeEvent, FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NotificationConfig, ReduxState, SendErrorReportType } from '../../utils/Presenters/ReduxType';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import { Button, Emoji, ErrorNotification, Paper, SearchBar, Table, Typography } from '../../components/UI/FB';
import { BUTTON_SIZES, BUTTON_VARIANTS, DATE_FNS_FORMATS, TYPOGRAPHY_TYPES } from '../../utils/Constants';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import { presentCurrency } from '../../utils/Presenters/PresentCurrency/PresentCurrency';
import styles from './Customers.module.scss';
import RakeDialog, { CustomerDataType } from './RakeDialog/RakeDialog';
import useDDErrorReporting from '../../hooks/useDDErrorReporting/useDDErrorReporting';
import { presentCustomers } from '../../utils/Presenters/PresentVenues/PresentCustomers';

type CustomerType = {
  sendDatadogError: SendErrorReportType;
  createNotification: (notification: NotificationConfig) => void;
};

interface ApiFieldEndPointKeyType {
  endpointKey: string;
  searchable?: boolean;
  searchableLabel?: string;
}

type CustomersColumnSpecsType = {
  index: number;
  title: string;
  sortMethod: string;
  className: string;
  columnDataFunction: (account: CustomerDataType) => string | number | null;
  searchable: boolean;
};

interface CustomerApiFieldsType {
  venueId: ApiFieldEndPointKeyType;
  venueCRN: ApiFieldEndPointKeyType;
  venueName: ApiFieldEndPointKeyType;
  venueGroup: ApiFieldEndPointKeyType;
  ownerName: ApiFieldEndPointKeyType;
  postcode: ApiFieldEndPointKeyType;
  suburb: ApiFieldEndPointKeyType;
  commission: ApiFieldEndPointKeyType;
  customPrices: ApiFieldEndPointKeyType;
  totalSpent: ApiFieldEndPointKeyType;
  orderCount: ApiFieldEndPointKeyType;
  lastOrdered: ApiFieldEndPointKeyType;
  customCommissionExists: ApiFieldEndPointKeyType;
}

const CUSTOMER_API_FIELDS: CustomerApiFieldsType = {
  venueId: {
    endpointKey: 'venueId',
    searchable: true,
  },
  venueCRN: {
    endpointKey: 'venueCRN',
  },
  venueName: {
    endpointKey: 'venueName',
    searchable: true,
  },
  venueGroup: {
    endpointKey: 'venueGroup',
  },
  ownerName: {
    endpointKey: 'ownerName',
  },
  postcode: {
    endpointKey: 'postcode',
  },
  suburb: {
    endpointKey: 'suburb',
  },
  commission: {
    endpointKey: 'commission',
  },
  customPrices: {
    endpointKey: 'customPrices',
  },
  totalSpent: {
    endpointKey: 'totalSpent',
  },
  orderCount: {
    endpointKey: 'orderCount',
  },
  lastOrdered: {
    endpointKey: 'lastOrdered',
  },
  customCommissionExists: {
    endpointKey: 'customCommissionExists',
  },
};

export type FieldObjectType = {
  value: string;
  label: string;
  searchable?: boolean;
};

const searchFields = Object.values(CUSTOMER_API_FIELDS)
  .filter((field: FieldObjectType) => field.searchable)
  .map((field) => ({
    value: field.endpointKey,
    label: field.endpointKey,
  }));

const Customers: FC<CustomerType> = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [customerData, setCustomerData] = useState<CustomerDataType[]>([]);
  const [existingVenue, setExistingVenue] = useState<CustomerDataType | null | undefined>(null);
  const [loadingCustomers, setLoadingCustomers] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(20);
  const [customerResultsLength, setCustomerResultsLength] = useState<number>(0);
  const [rakeDialogOpen, setRakeDialogOpen] = useState<boolean>(false);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [apiSearchQuery, setApiSearchQuery] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('venueId');
  const [sortBy, setSortBy] = useState<string>('desc');
  const [APIError, setAPIError] = useState<ReactNode | undefined>(undefined);
  const mapValueToSortByToAPIField = (valueToSortBy: keyof typeof CUSTOMER_API_FIELDS): string =>
    CUSTOMER_API_FIELDS[valueToSortBy].endpointKey;
  const isStaff = useSelector((state: ReduxState) => state.auth.supplierDetails?.isStaff);
  const { sendDatadogError } = useDDErrorReporting();
  const history = useHistory();

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value);
  };

  const performSearch = (): void => {
    setApiSearchQuery(searchQuery);
    setCurrentPageNumber(1);
  };

  const clearSearch = (): void => {
    setSearchQuery('');
    setApiSearchQuery('');
  };

  const customersColumnSpecs: CustomersColumnSpecsType[] = [
    {
      index: 0,
      title: 'Venue ID',
      sortMethod: 'venueId',
      className: styles.VenueId,
      columnDataFunction: (customer: CustomerDataType) => customer.venueId,
      searchable: true,
    },
    {
      index: 1,
      title: 'CRN',
      sortMethod: 'venueCRN',
      className: styles.CRN,
      columnDataFunction: (customer: CustomerDataType) => customer.venueCRN,
      searchable: true,
    },
    {
      index: 2,
      title: 'Venue Name',
      sortMethod: 'venueName',
      className: styles.VenueName,
      columnDataFunction: (customer: CustomerDataType) => customer.venueName,
      searchable: true,
    },
    {
      index: 3,
      title: 'Venue Group',
      sortMethod: 'venueGroup',
      className: styles.VenueGroup,
      columnDataFunction: (customer: CustomerDataType) => customer.venueGroup,
      searchable: false,
    },
    {
      index: 4,
      title: 'Owner Name',
      sortMethod: 'ownerName',
      className: styles.VenueOwner,
      columnDataFunction: (customer: CustomerDataType) => customer.ownerName,
      searchable: false,
    },
    {
      index: 5,
      title: 'Postcode',
      sortMethod: 'postcode',
      className: styles.Postcode,
      columnDataFunction: (customer: CustomerDataType) => customer.postcode,
      searchable: false,
    },
    {
      index: 6,
      title: 'Suburb',
      sortMethod: 'suburb',
      className: styles.suburb,
      columnDataFunction: (customer: CustomerDataType) => customer.suburb,
      searchable: false,
    },
    {
      index: 7,
      title: 'Rake',
      sortMethod: 'commission',
      className: styles.Commission,
      columnDataFunction: (customer: CustomerDataType) => `${customer.commission}%`,
      searchable: false,
    },
    {
      index: 8,
      title: 'Custom Prices',
      sortMethod: 'customPrices',
      className: styles.CustomPrice,
      columnDataFunction: (customer: CustomerDataType) => customer.customPrices,
      searchable: false,
    },
    {
      index: 9,
      title: 'Total Spent',
      sortMethod: 'totalSpent',
      className: styles.totalSpent,
      columnDataFunction: (customer: CustomerDataType) => presentCurrency(customer.totalSpent),
      searchable: false,
    },
    {
      index: 10,
      title: 'Orders',
      sortMethod: 'orderCount',
      className: styles.Orders,
      columnDataFunction: (customer: CustomerDataType) => customer.orderCount,
      searchable: false,
    },
    {
      index: 11,
      title: 'Last Ordered',
      sortMethod: 'lastOrdered',
      className: styles.LastOrdered,
      columnDataFunction: (customer: CustomerDataType) =>
        customer.lastOrdered !== ''
          ? format(parse(customer.lastOrdered, DATE_FNS_FORMATS.DB_DATE_FORMAT, new Date()), DATE_FNS_FORMATS.DATE)
          : null,
      searchable: false,
    },
  ];

  const getFilters = (): string =>
    Object.values(CUSTOMER_API_FIELDS)
      .map((field) => field.endpointKey)
      .join(';');

  const fetchCustomers = useCallback((): Promise<CustomerApiFieldsType[]> => {
    const fields = getFilters();
    const fuzzySearch = apiSearchQuery;
    const fuzzyFields = searchFields.map((sf) => sf.value).join(';');

    const filterSearches = '';
    const filterFields = '';

    return FoodbombAPI.get(
      `suppliers/venues?pageSize=${pageSize}&page=${currentPageNumber}&filter=${fields}&orderBy=${orderBy}&sortedBy=${String(
        sortBy,
      )}&fuzzyFields=${fuzzyFields}&fuzzy=${fuzzySearch}&searchFields=${filterFields}&search=${filterSearches}`,
    )
      .then((response) => response.data)
      .catch((error) => {
        sendDatadogError('Unable to load venues', {
          error,
          location: 'Venues list page',
        });
        setAPIError(<ErrorNotification body="We were unable to load venues. Our engineers have been notified!" />);
        throw error;
      });
  }, [currentPageNumber, orderBy, apiSearchQuery, sendDatadogError, sortBy, pageSize]);

  const openCustomPricing = (customer: CustomerDataType): void => {
    history.push(`/custom-prices?q=${customer.venueName}`);
  };

  const openRakeDialog = (customer?: CustomerDataType) => {
    setRakeDialogOpen(true);
    if (customer) setExistingVenue(customer);
  };

  const closeRakeDialog = () => {
    setRakeDialogOpen(false);
    setExistingVenue(null);
  };

  const createRowActions = (customer: CustomerDataType): ReactNode => (
    <>
      {customer.customPrices > 0 ? (
        <Button
          onClick={() => {
            openCustomPricing(customer);
          }}
          className={styles.RowAction}
          variant={BUTTON_VARIANTS.SECONDARY}
          size={BUTTON_SIZES.EXTRA_SMALL}
        >
          View Custom Prices
        </Button>
      ) : null}
      {isStaff ? (
        <Button
          onClick={() => {
            openRakeDialog(customer);
          }}
          className={styles.RowAction}
          variant={BUTTON_VARIANTS.SECONDARY}
          size={BUTTON_SIZES.EXTRA_SMALL}
        >
          🎭 Edit Venue Rake
        </Button>
      ) : null}
    </>
  );

  const onPreviousPageClick = (): void => {
    setCurrentPageNumber((prev) => prev - 1);
  };

  const onNextPageClick = (): void => {
    setCurrentPageNumber((prev) => prev + 1);
  };

  const handleColumnHeaderClick = (valueToSortBy: keyof typeof CUSTOMER_API_FIELDS, shouldSortByMin: string): void => {
    setOrderBy(mapValueToSortByToAPIField(valueToSortBy));
    setSortBy(shouldSortByMin ? 'asc' : 'desc');
  };

  const loadCustomerData = useCallback(async () => {
    setLoadingCustomers(true);
    fetchCustomers()
      .then((allCustomersData: any) => {
        const customers = presentCustomers(allCustomersData.data);
        setCustomerData(customers);
        setPageSize(20);
        setCustomerResultsLength(allCustomersData.total);
        setCurrentPageNumber(allCustomersData.currentPage);
      })
      .finally(() => setLoadingCustomers(false));
  }, [fetchCustomers]);

  const onSuccessfulCustomRakeCall = () => {
    closeRakeDialog();
    loadCustomerData();
  };

  useEffect(() => {
    loadCustomerData();
  }, [loadCustomerData]);

  return (
    <>
      <div className={styles.PageContainer}>
        <PageHeader>
          <div className={styles.BreadcrumbsContainer}>
            <div>
              <Breadcrumbs
                currentPageTitle={
                  <>
                    <Emoji content="👩‍🍳" label="chef" />
                    &nbsp;Customers
                  </>
                }
              />
              <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.Font__standardGrey}>
                Here are all of your customers
              </Typography>
            </div>
            <div className={styles.ActionBar}>
              <div className={styles.SearchBarContainer}>
                <SearchBar
                  placeholder="Search venue by name or ID"
                  handleSearch={performSearch}
                  onChange={handleSearchChange}
                  handleClear={clearSearch}
                  value={searchQuery}
                />
              </div>
              <div className={styles.ActionBar__buttonsContainer}>
                {isStaff ? (
                  <Button
                    variant={BUTTON_VARIANTS.SECONDARY}
                    className={styles.ActionBar__button}
                    onClick={() => {
                      openRakeDialog();
                    }}
                  >
                    🎭 New Venue Rake
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </PageHeader>
        {APIError ? (
          <div className={styles.ErrorWrapper}>{APIError}</div>
        ) : (
          <div className={styles.Customers_TableContainer}>
            <Table
              className={styles.CustomersTable}
              columnSpecs={customersColumnSpecs}
              rowData={customerData}
              loadingRowData={loadingCustomers}
              rowOverlayColSpan={4}
              rowActions={createRowActions}
              noResultsComponent={
                <Paper className={styles.NoResultsContainer}>
                  <div className={styles.TypographySection}>
                    <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Welcome to the customers page</Typography>
                    <div className={styles.TypographySection}>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                        Here is where we&apos;ll display all of your customers.
                      </Typography>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                        To get started, please search for a venue name or
                      </Typography>
                    </div>
                    <Button className={styles.LoadRecentResultsButton} onClick={loadCustomerData}>
                      Load all customers
                    </Button>
                  </div>
                </Paper>
              }
              searchQuery={apiSearchQuery}
              hideMobileArrow={false}
              defaultSortColumn={0}
              sortAscending={false}
              paginationOverrideData={{
                currentPageNumber,
                onNextPageClick,
                onPreviousPageClick,
                pageSize,
                numberOfResults: customerResultsLength,
              }}
              grow
              sortOverrideMethod={handleColumnHeaderClick}
              useServerSidePagination
            />
          </div>
        )}
        {rakeDialogOpen ? (
          <RakeDialog
            isOpen={rakeDialogOpen}
            handleClose={closeRakeDialog}
            existingVenue={existingVenue}
            onSuccessfulCustomRakeCall={onSuccessfulCustomRakeCall}
          />
        ) : null}
      </div>
    </>
  );
};

export default Customers;
