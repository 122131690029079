import React, { useEffect, useCallback, useState, useContext } from 'react';
import { withRouter, NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import WhatshotOutlinedIcon from '@material-ui/icons/WhatshotOutlined';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { AppBar, SwipeableDrawer, MenuItem } from '@material-ui/core';

import withRedirectHelper from '../../hoc/withRedirectHelper/withRedirectHelper';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import NavigationContext from '../../context/Navigation/NavigationContext';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import { TYPOGRAPHY_TYPES, NOTIFICATION_TYPES } from '../../utils/Constants';
import { Typography, Menu } from '../UI/FB';
import logo from '../../images/Navigation/foodbombLogo__light.png';
import styles from './Navigation.module.scss';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';

const Navigation = ({
  hasToken,
  supplierId,
  isStaff,
  replaceCurrentPageWithAdminPortalPageOrReferrer,
  sendDatadogError,
  createNotification,
}) => {
  const [supplierName, setSupplierName] = useState(undefined);
  const { referrer } = useContext(NavigationContext);

  const [anchorElem, setAnchorElem] = useState(null);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const handleMobileDrawerClick = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };
  const closeMobileDrawer = () => setMobileDrawerOpen(false);
  const openMobileDrawer = () => setMobileDrawerOpen(true);

  const onClickMenuBtn = (e) => setAnchorElem(e.currentTarget);

  const handleMenuClose = () => setAnchorElem(null);

  const handleLinkClick = () => {
    handleMenuClose();
    closeMobileDrawer();
    window.scrollTo(0, 0);
  };

  const simulateRaygunError = () => {
    sendDatadogError(`Devs need to pay attention! Shit is broken!`, {
      error: new Error('This is a sample error'),
      location: 'Navigation File',
    });
    createNotification({
      timeout: 6000,
      closable: true,
      type: NOTIFICATION_TYPES.SUCCESS,
      content: `Sent raygun error to developers`,
    });
  };

  const fetchSupplierDetails = useCallback(() => {
    if (hasToken) {
      FoodbombAPI.get(`suppliers/auth/me`)
        .then((response) => {
          setSupplierName(response.data.company);
        })
        .catch((error) => {
          sendDatadogError(`Unable to load supplier details: ${supplierId}`, {
            error,
            location: 'Navigation bar',
          });
        });
    }
  }, [supplierId, sendDatadogError, hasToken]);

  useEffect(() => {
    fetchSupplierDetails();
  }, [fetchSupplierDetails]);

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const navbarLinks = [
    {
      to: '/dashboard',
      text: 'Dashboard',
      icon: <DashboardOutlinedIcon className={styles.DashboardNavbar__linkIcon} />,
      exact: true,
    },
    {
      to: '/specials',
      text: 'Specials',
      icon: <WhatshotOutlinedIcon className={styles.DashboardNavbar__linkIcon} />,
    },
    {
      to: '/customers',
      text: 'Customers',
      icon: <StoreOutlinedIcon className={styles.DashboardNavbar__linkIcon} />,
    },
    {
      to: '/custom-prices',
      text: 'Custom Pricing',
      icon: <OfflineBoltOutlinedIcon className={styles.DashboardNavbar__linkIcon} />,
    },
    {
      to: '/loss-leaders',
      text: 'Loss Leaders',
      icon: <AttachMoneyIcon className={styles.DashboardNavbar__linkIcon} />,
    },
    {
      to: '/products',
      text: 'Products',
      icon: <CategoryOutlinedIcon className={styles.DashboardNavbar__linkIcon} />,
    },
    {
      to: '/orders',
      text: 'Orders',
      icon: <AssignmentOutlinedIcon className={styles.DashboardNavbar__linkIcon} />,
    },
    {
      to: '/reports',
      text: 'Reports',
      icon: <BarChartOutlinedIcon className={styles.DashboardNavbar__linkIcon} />,
    },
    {
      to: '/delivery-zones',
      text: 'Zones',
      icon: <LocalShippingOutlinedIcon className={styles.DashboardNavbar__linkIcon} />,
    },
    {
      to: '/settings',
      text: 'Settings',
      icon: <SettingsOutlinedIcon className={styles.DashboardNavbar__linkIcon} />,
    },
  ];

  const accountLinks = [
    {
      to: '/reviews',
      text: 'Reviews',
      icon: (
        <div className={styles.flipImageContent}>
          <StarBorderIcon className={styles.DashboardNavbar__linkIcon} />
        </div>
      ),
    },
    {
      to: '/logout',
      text: 'Sign Out',
      icon: (
        <div className={styles.flipImageContent}>
          <LaunchOutlinedIcon className={styles.DashboardNavbar__linkIcon} />
        </div>
      ),
    },
  ];

  if (isStaff) {
    accountLinks.push({
      to: '/logout',
      text: 'Return to Admin',
      onClick: () => replaceCurrentPageWithAdminPortalPageOrReferrer(referrer),
      icon: <VpnKeyOutlinedIcon className={styles.DashboardNavbar__linkIcon} />,
    });
    accountLinks.push({
      to: '#',
      text: 'Yell at Devs!',
      onClick: simulateRaygunError,
      icon: <ErrorOutlineIcon className={styles.DashboardNavbar__linkIcon} />,
    });
  }

  const userMenu = (
    <Menu open={Boolean(anchorElem)} anchorEl={anchorElem} onClose={handleMenuClose}>
      {accountLinks.map((link) => (
        <MenuItem
          key={`userMenu-${link.text}`}
          component={Link}
          to={link.disabled ? '#' : link.to}
          disabled={link.disabled}
          className={link.disabled ? [styles.MenuLink, styles.disabled].join(' ') : styles.MenuLink}
          onClick={link.onClick || handleMenuClose}
        >
          {link.icon}
          {link.text}
        </MenuItem>
      ))}
    </Menu>
  );

  const desktopNavbar = (
    <div className={styles.DesktopNavbarWrapper}>
      <div className={styles.DesktopNavbarBanner}>
        {navbarLinks.map((navbarLink) => (
          <NavLink
            key={`desktopNavbar-${navbarLink.text}`}
            onClick={navbarLink.disabled ? undefined : handleLinkClick}
            to={navbarLink.disabled ? '#' : navbarLink.to}
            exact={navbarLink.exact}
            className={
              navbarLink.disabled
                ? [styles.DashboardNavbar__link, styles.disabled].join(' ')
                : styles.DashboardNavbar__link
            }
            activeClassName={
              navbarLink.disabled || navbarLink.hideActive ? undefined : styles.DashboardNavbar__link__active
            }
          >
            <React.Fragment>
              {navbarLink.icon}
              {navbarLink.text}
            </React.Fragment>
          </NavLink>
        ))}
      </div>
    </div>
  );

  const mobileDrawerContent = (
    <div className={styles.MobileDrawerContainer}>
      <div className={styles.MobileDrawer__section}>
        <div className={styles.MobileDrawer__sectionContent}>
          {navbarLinks.map((navbarLink) => (
            <NavLink
              key={`mobileDrawer-${navbarLink.text}`}
              onClick={navbarLink.disabled ? undefined : handleLinkClick}
              to={navbarLink.disabled ? '#' : navbarLink.to}
              exact={navbarLink.exact}
              className={
                navbarLink.disabled ? [styles.MobileDrawerLink, styles.disabled].join(' ') : styles.MobileDrawerLink
              }
              activeClassName={
                navbarLink.disabled || navbarLink.hideActive ? undefined : styles.MobileDrawerLink__active
              }
            >
              <React.Fragment>
                {navbarLink.icon}
                {navbarLink.text}
              </React.Fragment>
            </NavLink>
          ))}
        </div>
      </div>

      <div className={styles.MobileDrawer__section}>
        <div className={styles.MobileDrawer__sectionContent}>
          {accountLinks.map((accountLink) => (
            <NavLink
              key={`mobileDrawer-${accountLink.text}`}
              onClick={accountLink.onClick || handleMenuClose}
              to={accountLink.disabled ? '#' : accountLink.to}
              exact={accountLink.exact}
              className={
                accountLink.disabled ? [styles.MobileDrawerLink, styles.disabled].join(' ') : styles.MobileDrawerLink
              }
              activeClassName={accountLink.disabled ? undefined : styles.MobileDrawerLink__active}
            >
              <React.Fragment>
                {accountLink.icon}
                {accountLink.text}
              </React.Fragment>
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.NavigationContainer}>
      <AppBar className={styles.Navigation__AppBar}>
        <div className={[styles.AppBar__content, styles.Desktop].join(' ')}>
          <div className={styles.AppBarContent__left}>
            <Link to={hasToken ? '/' : '/login'} onClick={handleLinkClick}>
              <img src={logo} className={styles.AppBar__logo} alt="Foodbomb" />
            </Link>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.AppBar__desktopHeader}>
              &middot; Supplier Portal
            </Typography>
          </div>
          {hasToken ? (
            <div className={styles.AppBarContent__right}>
              <button className={styles.AppBar__dropdownBtn} onClick={onClickMenuBtn}>
                {supplierName}
                <KeyboardArrowDownIcon />
              </button>
            </div>
          ) : null}
        </div>
        <div className={[styles.AppBar__content, styles.Mobile].join(' ')}>
          <div className={styles.AppBarContent__left}>
            {hasToken ? (
              <HamburgerMenu onClick={handleMobileDrawerClick} displayAsCloseIcon={mobileDrawerOpen} />
            ) : null}
          </div>
          <div className={styles.AppBarContent__center}>
            <Link to={hasToken ? '/' : '/login'} onClick={handleLinkClick}>
              <img src={logo} className={styles.AppBar__logo} alt="Foodbomb" />
            </Link>
          </div>
          <div className={styles.AppBarContent__right}></div>
        </div>
        {hasToken ? (
          <React.Fragment>
            {userMenu}
            <SwipeableDrawer
              anchor="left"
              disableBackdropTransition={!iOS}
              disableDiscovery={iOS}
              open={mobileDrawerOpen}
              onClose={closeMobileDrawer}
              onOpen={openMobileDrawer}
              ModalProps={{
                BackdropProps: {
                  classes: {
                    root: styles.MobileDrawerModal,
                  },
                },
              }}
              classes={{
                root: styles.MobileDrawerWrapper,
                paper: styles.MobileDrawerPaper,
              }}
            >
              {mobileDrawerContent}
            </SwipeableDrawer>
          </React.Fragment>
        ) : null}
      </AppBar>
      {hasToken ? desktopNavbar : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  hasToken: Boolean(state.auth.token),
  supplierId: state.auth.supplierDetails ? state.auth.supplierDetails.id : undefined,
  isStaff: state.auth.supplierDetails ? state.auth.supplierDetails.isStaff : false,
});

Navigation.propTypes = {
  hasToken: PropTypes.bool.isRequired,
  supplierId: PropTypes.number,
  isStaff: PropTypes.bool,
  replaceCurrentPageWithAdminPortalPageOrReferrer: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
};

export default withErrorReports(
  withRedirectHelper(withNotifications(connect(mapStateToProps, null)(withRouter(Navigation)))),
);
