import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '../../components/UI/FB';
import { TYPOGRAPHY_TYPES, NOTIFICATION_TYPES, BUTTON_VARIANTS } from '../../utils/Constants';

import withNotifications from '../../hoc/withNotifications/withNotifications';
import BombSweeper from './BombSweeper';
import foodbombLogo from '../../images/Navigation/foodbombLogo.png';

import styles from './Maintenance.module.scss';

const Maintenance = ({ createNotification }) => {
  const [hasWon, setHasWon] = useState(false);
  const [hasLost, setHasLost] = useState(false);
  const [resetKey, setResetKey] = useState(1);

  const handleWin = () => {
    setHasWon(true);
    createNotification({
      timeout: 4000,
      closable: true,
      type: NOTIFICATION_TYPES.SUCCESS,
      content: 'Victory is yours!! 🎉',
    });
  };

  const handleLose = () => {
    setHasLost(true);
    createNotification({
      timeout: 4000,
      closable: true,
      type: NOTIFICATION_TYPES.ERROR,
      content: 'Better luck next time!! 😣',
    });
  };

  const resetGame = () => {
    setHasLost(false);
    setHasWon(false);
    setResetKey((x) => x + 1);
  };

  const isScheduledMaintenance = false;

  return (
    <div className={styles.MaintenancePage}>
      <div className={styles.NavigationOffset}>
        <div className={styles.LogoContainer}>
          <img src={foodbombLogo} className={styles.NavigationLogo} alt="" />
        </div>
      </div>
      <div className={styles.HeaderContainer}>
        <div className={styles.HeaderContent}>
          <Typography type={TYPOGRAPHY_TYPES.HEADING_XXL}>
            {isScheduledMaintenance ? (
              <>Scheduled maintenance - Tuesday 1st Feb 12:00 am - 1:15 am AEDT</>
            ) : (
              <>We&apos;re down for maintenance</>
            )}
          </Typography>
          {isScheduledMaintenance ? (
            <>
              <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.Font__standardGrey}>
                Foodbomb will be temporarily offline between 12 am - 1:30 am.
              </Typography>
              <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.Font__standardGrey}>
                Please ensure you have placed all orders before this time.
              </Typography>
              <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.Font__standardGrey}>
                If you have any issues please email&nbsp;
                <a href="mailto:support@foodbomb.com.au?subject=Maintenance Outage">support@foodbomb.com.au</a>
              </Typography>
            </>
          ) : (
            <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.Font__standardGrey}>
              <>We&apos;ll be back soon. Thank you for your patience.</>
            </Typography>
          )}
        </div>
      </div>
      <div className={styles.PageContent}>
        <Typography
          type={TYPOGRAPHY_TYPES.HEADING_XL}
          className={[styles.Font__standardGrey, styles.GameTitle].join(' ')}
        >
          Here&apos;s a game to cheer you up a bit.
        </Typography>
        <BombSweeper key={resetKey} onWin={handleWin} onLose={handleLose} bombChance={0.18} />
        <div className={styles.ResetButtonContainer}>
          <Button onClick={resetGame} variant={hasWon || hasLost ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}>
            F-Bomb Play
          </Button>
        </div>
      </div>
    </div>
  );
};

Maintenance.propTypes = {
  createNotification: PropTypes.func.isRequired,
};

export default withNotifications(Maintenance);
