import { FC } from 'react';
import MaterialTable from 'material-table';
import { TablePagination, TextField } from '@material-ui/core';
import CategoryEditCell from './CategoryEditCell';
import styles from './CSVProductsTable.module.scss';
import UnitOfMeasureEditCell from './UnitOfMeasureEditCell';
import {
  MeasurementType,
  ProductCategoriesType,
  ProductDataType,
  ProductErrorsType,
  ValidatedProductsType,
} from '../../utils/Types/ProductTypes';

type CSVProductsTableProps = {
  validatedProducts: ValidatedProductsType[];
  updateAndRevalidateAProduct: (productToUpdateIdx: number, updatedProductData: ProductDataType) => void;
  deleteProduct: (idx: number) => void;
  categories: ProductCategoriesType[];
  unitsOfMeasure: MeasurementType[];
};

const CSVProductsTable: FC<CSVProductsTableProps> = ({
  validatedProducts,
  updateAndRevalidateAProduct,
  deleteProduct,
  categories,
  unitsOfMeasure,
}) => {
  const cellData = (data: ProductDataType, field: string) => {
    const error = data?.errors
      ?.filter((p: ProductErrorsType) => p.path === field)
      .map((p: ProductErrorsType) => p.message);
    return (
      <div>
        <div>{data[field as keyof ProductDataType]}</div>
        {error && error.length ? (
          <div>
            <div className={styles.CSVProductsTable__errormsg}>{error}</div>
          </div>
        ) : null}
      </div>
    );
  };

  const parseValidatedProducts = () =>
    validatedProducts.map((validatedProduct: ValidatedProductsType) => {
      const sanitized = validatedProduct.productSanitized;
      const errors = { errors: validatedProduct.productErrors };
      return { ...sanitized, ...errors };
    });

  const parsedValidatedProductsForTable = parseValidatedProducts();

  return (
    <MaterialTable
      columns={[
        {
          title: 'SKU',
          field: 'sku',
          searchable: true,
          sorting: true,
          render: (rowData) => cellData(rowData, 'sku'),
        },
        {
          title: 'Name',
          field: 'name',
          searchable: true,
          sorting: true,
          render: (rowData) => cellData(rowData, 'name'),
          editComponent: (tableData) => (
            <TextField
              className={styles.ProductNameTextField}
              multiline
              value={tableData.value}
              onChange={(e) => tableData.onChange(e.target.value)}
            />
          ),
        },
        {
          title: 'Category One',
          field: 'categoryOne',
          searchable: true,
          sorting: true,
          render: (rowData) => cellData(rowData, 'categoryOne'),
          editComponent: (tableData) => (
            <CategoryEditCell tableData={tableData} field="categoryOne" categories={categories} />
          ),
        },
        {
          title: 'Category Two',
          field: 'categoryTwo',
          searchable: true,
          sorting: true,
          render: (rowData) => cellData(rowData, 'categoryTwo'),
          editComponent: (tableData) => (
            <CategoryEditCell tableData={tableData} field="categoryTwo" categories={categories} />
          ),
        },
        {
          title: 'Category Three',
          field: 'categoryThree',
          searchable: true,
          sorting: true,
          render: (rowData) => cellData(rowData, 'categoryThree'),
          editComponent: (tableData) => (
            <CategoryEditCell tableData={tableData} field="categoryThree" categories={categories} />
          ),
        },
        {
          title: 'Price (Ex.GST)',
          field: 'price',
          type: 'numeric',
          // step: '0.01',
          searchable: true,
          sorting: true,
          render: (rowData) => cellData(rowData, 'price'),
        },
        {
          title: 'Price Unit',
          field: 'unitOfPrice',
          searchable: true,
          sorting: true,
          render: (rowData) => cellData(rowData, 'unitOfPrice'),
          editComponent: (tableData) => (
            <UnitOfMeasureEditCell
              tableData={tableData}
              cellTitle="Price Unit"
              cellField="unitOfPrice"
              unitsOfMeasure={unitsOfMeasure}
            />
          ),
        },
        {
          title: 'Portions',
          field: 'portions',
          searchable: true,
          sorting: true,
          render: (rowData) => cellData(rowData, 'portions'),
        },
        {
          title: 'Portion Unit',
          field: 'unitOfPortion',
          searchable: true,
          sorting: true,
          render: (rowData) => cellData(rowData, 'unitOfPortion'),
          editComponent: (tableData) => (
            <UnitOfMeasureEditCell
              tableData={tableData}
              cellTitle="Portion Unit"
              cellField="unitOfPortion"
              unitsOfMeasure={unitsOfMeasure}
            />
          ),
        },
        {
          title: 'GST (Y/N)',
          field: 'taxable',
          searchable: true,
          sorting: true,
          render: (rowData) => cellData(rowData, 'taxable'),
        },
        {
          title: 'Enabled (Y/N)',
          field: 'enabled',
          searchable: true,
          sorting: true,
          render: (rowData) => cellData(rowData, 'enabled'),
        },
        {
          title: 'Out of Stock (Y/N)',
          field: 'outOfStock',
          searchable: true,
          sorting: true,
          render: (rowData) => cellData(rowData, 'outOfStock'),
        },
      ]}
      data={parsedValidatedProductsForTable}
      title="Pending Products"
      options={{
        pageSize: 30,
        pageSizeOptions: [10, 50, 100],
        rowStyle: (rowData) => ({ backgroundColor: rowData.errors.length ? '#f8d7da' : '#ffff' }),
        emptyRowsWhenPaging: false,
      }}
      editable={{
        onRowUpdate: (newData: ProductDataType, oldData: ProductDataType | undefined) =>
          new Promise((resolve) => {
            const newDataToUpdate = { ...newData };
            delete newDataToUpdate.errors;
            const index = oldData?.tableData?.id;
            if (index || index === 0) updateAndRevalidateAProduct(index, newDataToUpdate);
            resolve(newData);
          }),
        onRowDelete: (newData) =>
          new Promise((resolve) => {
            if (newData?.tableData?.id || newData?.tableData?.id === 0) deleteProduct(newData.tableData.id);
            resolve(newData);
          }),
      }}
      localization={{
        body: {
          deleteTooltip: 'Ignore',
          editRow: {
            deleteText: 'Are you sure you want to ignore this product?',
          },
        },
      }}
      components={{
        Pagination: (props) => (
          <div className={styles.CSVProductsTable__tablePaginationContainer}>
            {/* eslint-disable react/jsx-props-no-spreading */}
            <TablePagination {...props} classes={{ spacer: styles.CSVProductsTable__tablePaginationSpacer }} />
          </div>
        ),
      }}
    />
  );
};

export default CSVProductsTable;
