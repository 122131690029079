import { MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { format, parse } from 'date-fns';
import { matchSorter } from 'match-sorter';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import CustomPriceDialog from '../../components/CustomPriceDialog/CustomPriceDialog';
import ProductImageDialog from '../../components/ProductImageDialog/ProductImageDialog';
import WTFCell from '../../components/ProductsTableCells/WTFCell/WTFCell';
import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import {
  Button,
  EditableTableCell,
  Emoji,
  ErrorNotification,
  Menu,
  Paper,
  SearchBar,
  StatusChip,
  Table,
  Tooltip,
  Typography,
} from '../../components/UI/FB';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import SpecialsIcon from '../../components/UI/SpecialsIcon/SpecialsIcon';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import withQueryParams from '../../hoc/withQueryParams/withQueryParams';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import withRedirectHelper from '../../hoc/withRedirectHelper/withRedirectHelper';
import { useDebounce } from '../../hooks';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import {
  ACTIVE_VENUE_STATUS,
  BUTTON_VARIANTS,
  DATE_FNS_FORMATS,
  EDITABLE_TABLE_CELL_TYPES,
  NOTIFICATION_TYPES,
  PERSONAL_VENUE_TYPE,
  STATUS_CHIP_TYPES,
  TYPOGRAPHY_TYPES,
} from '../../utils/Constants';
import { presentCurrency } from '../../utils/Presenters/PresentCurrency/PresentCurrency';
import ProductImageSelectorModal from './ProductImageSelectorModal';
import styles from './Products.module.scss';

const IMAGE_FILTERS = [
  {
    id: 0,
    title: '🎭 All Images',
  },
  {
    id: 1,
    title: 'No Image',
    filterField: 'fromGallery',
    filterValue: false,
  },
  {
    id: 2,
    title: 'Image',
    filterField: 'fromGallery',
    filterValue: true,
  },
];

const STATUS_FILTERS = [
  {
    id: 0,
    title: 'All Statuses',
  },
  {
    id: 1,
    title: 'Only Enabled',
    filterField: 'enabled',
    filterValue: true,
  },
  {
    id: 2,
    title: 'Only Disabled',
    filterField: 'enabled',
    filterValue: false,
  },
];

const STOCK_FILTERS = [
  {
    id: 0,
    title: 'All Stock Levels',
  },
  {
    id: 1,
    title: 'Only In Stock',
    filterField: 'outOfStock',
    filterValue: false,
  },
  {
    id: 2,
    title: 'Only Out Of Stock',
    filterField: 'outOfStock',
    filterValue: true,
  },
];

const GST_FILTERS = [
  {
    id: 0,
    title: 'All GST Variations',
  },
  {
    id: 1,
    title: 'Only without GST',
    filterField: 'gstExempt',
    filterValue: true,
  },
  {
    id: 2,
    title: 'Only with GST',
    filterField: 'gstExempt',
    filterValue: false,
  },
];

const CUSTOM_PRICING_FILTERS = [
  {
    id: 0,
    title: 'Any Custom Prices',
  },
  {
    id: 1,
    title: 'With Custom Price',
    filterField: 'customPricing',
    filterValue: true,
  },
  {
    id: 2,
    title: 'No Custom Price',
    filterField: 'customPricing',
    filterValue: false,
  },
];

const STATUS_OPTIONS = [
  {
    label: (
      <StatusChip title={'Enabled'} hideIcon type={STATUS_CHIP_TYPES.SUCCESS} className={styles.StatusChip__noMargin} />
    ),
    value: true,
  },
  {
    label: (
      <StatusChip title={'Disabled'} hideIcon type={STATUS_CHIP_TYPES.FAILED} className={styles.StatusChip__noMargin} />
    ),
    value: false,
  },
];

const STOCK_OPTIONS = [
  {
    label: (
      <StatusChip
        title={'In Stock'}
        hideIcon
        type={STATUS_CHIP_TYPES.SUCCESS}
        className={styles.StatusChip__noMargin}
      />
    ),
    value: 1,
  },
  {
    label: (
      <StatusChip
        title={'Out Of Stock'}
        hideIcon
        type={STATUS_CHIP_TYPES.FAILED}
        className={styles.StatusChip__noMargin}
      />
    ),
    value: 0,
  },
];

const PAGE_SIZE = 15;

const Products = ({
  redirectToNewProduct,
  redirectToProduct,
  redirectToProductWithQueryParams,
  isStaff,
  createNotification,
  redirectToEditMultipleProducts,
  redirectToAddMultipleProducts,
  sendDatadogError,
  imageGallery,
  getProductGSTFilterIdQueryFromQueryString,
  updateProductGSTFilterIdQueryParam,
  getQueryFromQueryString,
  updateSearchQueryParam,
  getProductImageFilterIdQueryFromQueryString,
  updateProductImageFilterIdQueryParam,
  getProductStatusFilterIdQueryFromQueryString,
  updateProductStatusFilterIdQueryParam,
  getProductCustomPricingFilterIdQueryFromQueryString,
  updateProductCustomPricingFilterIdQueryParam,
  getStockFilterIdQueryFromQueryString,
  updateStockFilterIdQueryParam,
}) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const [productsGSTFilterAnchorElem, setProductsGSTFilterAnchorElem] = useState(undefined);

  const [productsImageFilterAnchorElem, setProductsImageFilterAnchorElem] = useState(undefined);

  const [productsStatusFilterAnchorElem, setProductsStatusFilterAnchorElem] = useState(undefined);

  const [stockFilterAnchorElem, setStockFilterAnchorElem] = useState(undefined);

  const [productsCustomPricingFilterAnchorElem, setProductsCustomPricingFilterAnchorElem] = useState(undefined);

  const [CSVDropdownAnchorElem, setCSVDropdownAnchorElem] = useState(undefined);

  const [loadingProducts, setLoadingProducts] = useState(false);
  const [searchQuery, setSearchQuery] = useState(getQueryFromQueryString() || '');
  const debouncedSearchQuery = useDebounce(searchQuery, 200);
  const [shouldShowSearchBar, setShouldShowSearchBar] = useState(false);

  const [productForCustomPriceDialog, setProductForCustomPriceDialog] = useState(undefined);
  const [customPriceDialogOpen, setCustomPriceDialogOpen] = useState(false);
  const [productImageDialogOpen, setProductImageDialogOpen] = useState(false);
  const [productImageURLForDialog, setProductImageURLForDialog] = useState('');

  const [selectedProductToUpdateImage, setSelectedProductToUpdateImage] = useState(undefined);
  const [updatingImageInProgress, setUpdatingImageInProgress] = useState(false);
  const [updatingImageError, setUpdatingImageError] = useState(false);

  const [venues, setVenues] = useState([]);

  const [APIError, setAPIError] = useState('');

  const [productImageSearchQuery, setProductImageSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleProductsGSTFilterClicked = (e) => setProductsGSTFilterAnchorElem(e.currentTarget);
  const handleCloseProductsGSTFilterMenu = () => setProductsGSTFilterAnchorElem(undefined);

  const handleProductsStatusFilterClicked = (e) => setProductsStatusFilterAnchorElem(e.currentTarget);
  const handleCloseProductsStatusFilterMenu = () => setProductsStatusFilterAnchorElem(undefined);

  const handleStockFilterClicked = (e) => setStockFilterAnchorElem(e.currentTarget);
  const handleCloseStockFilterMenu = () => setStockFilterAnchorElem(undefined);

  const handleProductsImageFilterClicked = (e) => setProductsImageFilterAnchorElem(e.currentTarget);
  const handleCloseProductsImageFilterMenu = () => setProductsImageFilterAnchorElem(undefined);

  const handleProductsCustomPricingFilterClicked = (e) => setProductsCustomPricingFilterAnchorElem(e.currentTarget);
  const handleCloseProductsCustomPricingFilterMenu = () => setProductsCustomPricingFilterAnchorElem(undefined);

  const handleCSVDropdownClicked = (e) => setCSVDropdownAnchorElem(e.currentTarget);
  const handleCloseCSVDropdown = () => setCSVDropdownAnchorElem(undefined);

  const handleCloseProductImageDialog = () => {
    setProductImageDialogOpen(false);
    setProductImageURLForDialog('');
  };

  const handleOpenProductImageDialog = (url) => {
    if (url) {
      setProductImageURLForDialog(url);
      setProductImageDialogOpen(true);
    }
  };

  const handleOpenCustomPriceDialog = (product) => {
    setCustomPriceDialogOpen(true);
    setProductForCustomPriceDialog(product);
  };

  const handleCloseCustomPriceDialog = () => {
    setCustomPriceDialogOpen(false);
    setProductForCustomPriceDialog(undefined);
  };

  const updateProductData = (productToUpdate) => {
    const idxToUpdate = products.findIndex((product) => product.id === productToUpdate.id);
    const updatedProducts = [...products];
    updatedProducts[idxToUpdate] = productToUpdate;
    setProducts(updatedProducts);
  };

  const openImageSelectorDialog = (product) => {
    setSelectedProductToUpdateImage(product);
  };

  const handleCloseImageSelectorDialog = () => {
    setSelectedProductToUpdateImage(undefined);
  };

  const buildSearchLabelForVenue = useCallback((venue) => `${venue.venue} (${venue.id})`, []);

  const loadVenues = useCallback(() => {
    FoodbombAPI.get('/venues', { credentials: 'include' })
      .then((response) => {
        setVenues(
          response.data.venues
            .map((venue) => ({
              ...venue,
              label: buildSearchLabelForVenue(venue),
              value: venue.id,
            }))
            .filter((c) => c.status === ACTIVE_VENUE_STATUS && c.venueType !== PERSONAL_VENUE_TYPE),
        );
      })
      .catch((error) => {
        // If this call fails we just dont allow inline editing of CPs
        sendDatadogError('Unable to fetch  venuesfor supplier', {
          error,
          location: 'Products index page',
        });
      });
  }, [sendDatadogError, buildSearchLabelForVenue]);

  const potentiallyCreateSpecialWarningNotification = (updatedProduct) => {
    if (updatedProduct.specials && updatedProduct.specials.length) {
      createNotification({
        type: NOTIFICATION_TYPES.WARNING,
        content: `Note: This product is on special! Price changes won't alter ongoing specials`,
        closable: true,
      });
    }
  };

  const potentiallyCreateCustomPriceWarningNotification = (updatedProduct) => {
    if (updatedProduct.customPricing && updatedProduct.customPricing.length) {
      const venueNames = updatedProduct.customPricing
        .map((customPrice) => `'${customPrice.venue.venue}'`)
        .reduce((a, b, i, array) => a + (i < array.length - 1 ? ', ' : ' and ') + b);
      createNotification({
        type: NOTIFICATION_TYPES.WARNING,
        content: `Note: ${venueNames} have custom pricing for ${updatedProduct.name}. Please check these prices as custom prices are not updated when you change a product price.`,
        closable: true,
      });
    }
  };

  const handleUpdateImage = (product, image) => {
    setUpdatingImageError(false);
    setUpdatingImageInProgress(true);
    const imageToAdd = { ...image, fromGallery: true };
    const updatedProduct = {
      ...product,
      image: imageToAdd,
      updatedAt: new Date(),
    };
    FoodbombAPI.patch(`suppliers/products/${product.id}`, {
      imageId: image.id,
    })
      .then(() => {
        setUpdatingImageInProgress(false);
        updateProductData(updatedProduct);
        setSelectedProductToUpdateImage(undefined);
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully updated product image',
          timeout: 4000,
          closable: true,
        });
      })
      .catch((error) => {
        setUpdatingImageInProgress(false);
        setUpdatingImageError(true);
        sendDatadogError('Unable to update product image', {
          error,
          location: 'Products List Page',
        });
      });
  };

  const handleProductStatusChange = (shouldBeEnabled, product) => {
    const originalProduct = { ...product };
    if (product.enabled !== shouldBeEnabled) {
      const updatedProduct = {
        ...product,
        enabled: shouldBeEnabled,
        updatedAt: new Date(),
      };
      updateProductData(updatedProduct);

      FoodbombAPI.patch(`suppliers/products/${product.id}`, {
        enabled: shouldBeEnabled,
      })
        .then(() => {
          createNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            content: `${shouldBeEnabled ? 'Enabled' : 'Disabled'} ${product.name}`,
            timeout: 4000,
            closable: true,
          });
        })
        .catch((error) => {
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: `Unable to ${shouldBeEnabled ? 'enable' : 'disable'} ${product.name}`,
            closable: true,
          });
          updateProductData(originalProduct);
          sendDatadogError('Unable to update product status', {
            error,
            location: 'Products table',
          });
        });
    }
  };

  const handleProductStockChange = (isInStock, product) => {
    const originalProduct = { ...product };
    const newOutOfStockValue = !isInStock;

    if (newOutOfStockValue !== product.outOfStock) {
      const updatedProduct = {
        ...product,
        outOfStock: newOutOfStockValue,
        updatedAt: new Date(),
      };
      updateProductData(updatedProduct);
      FoodbombAPI.patch(`suppliers/products/${product.id}`, {
        outOfStock: newOutOfStockValue,
      })
        .then(() => {
          createNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            content: `${product.name} has been set to ${newOutOfStockValue ? 'out of stock' : 'in stock'} `,
            timeout: 4000,
            closable: true,
          });
        })
        .catch((error) => {
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: `Unable to change stock status of product: ${product.name}`,
            closable: true,
          });
          updateProductData(originalProduct);
          sendDatadogError('Unable to update product stock', {
            error,
            location: 'Products table - Stock',
          });
        });
    }
  };

  const handleUpdateProductPrice = (product, values, actions, handleResetTableStyling) => {
    const originalPrice = product.priceInCents;
    actions.setStatus({ apiError: undefined });
    const newPriceInCents = (values.price * 100).toFixed(0);
    const updatedProduct = {
      ...product,
      priceInCents: newPriceInCents,
      updatedAt: new Date(),
    };

    if (originalPrice !== newPriceInCents) {
      FoodbombAPI.patch(`suppliers/products/${product.id}`, {
        priceInCents: newPriceInCents,
        gstExempt: updatedProduct.gstExempt,
      })
        .then(() => {
          handleResetTableStyling();
          updateProductData(updatedProduct);
          createNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            content: `Successfully updated price for ${updatedProduct.name} to ${presentCurrency(values.price)}/${
              updatedProduct.unitOfPrice.unit
            }`,
            timeout: 4000,
            closable: true,
          });
          potentiallyCreateCustomPriceWarningNotification(updatedProduct);
          potentiallyCreateSpecialWarningNotification(updatedProduct);
        })
        .catch((error) => {
          handleResetTableStyling();
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: `Unable to update product price at this time. Please try again or contact support`,
            timeout: 4000,
            closable: true,
          });
          sendDatadogError('Unable to update product price', {
            error,
            location: 'Products table',
          });
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      handleResetTableStyling();
    }
  };

  const productsColumnSpecs = [];

  productsColumnSpecs.push({
    index: 0,
    title: 'SKU',
    sortMethod: 'sku',
    className: styles.SKU,
    columnDataFunction: (product) => product.sku,
    searchable: true,
  });

  if (isStaff) {
    productsColumnSpecs.push({
      index: 0.5,
      title: '🎭 ID',
      className: styles.ID,
      sortMethod: 'id',
      columnDataFunction: (product) => product.id,
      searchable: true,
    });
  }

  productsColumnSpecs.push(
    {
      index: 1,
      title: 'Image',
      className: styles.Image,
      columnDataFunction: (product) => (product.image ? product.image.fileName : 'no image'),
      // eslint-disable-next-line react/display-name
      getChild: (product) => (
        <EditableTableCell
          fieldName="image"
          type={EDITABLE_TABLE_CELL_TYPES.CUSTOM}
          iconOverride={isStaff ? undefined : <span></span>}
          editModeClickOverride={() =>
            isStaff ? openImageSelectorDialog(product) : handleOpenProductImageDialog(product.image?.url || null)
          }
          defaultContent={
            <div className={styles.ProductImageContainer}>
              <img
                className={styles.ProductImage}
                src={product.image?.url || null}
                alt={product.image?.fileName || 'No image'}
              />
            </div>
          }
        />
      ),
      searchable: false,
    },
    {
      index: 2,
      title: 'Product Name',
      sortMethod: 'name',
      className: styles.ProductName,
      columnDataFunction: (product) => product.name,
      searchable: true,
    },
    {
      index: 3,
      title: (
        <Tooltip
          placement="top"
          title={
            <div className={styles.WTFTooltip}>
              <div>
                <strong>WTF is this?</strong>
              </div>
              <br />
              <div>
                Items on special are marked with&nbsp;
                <SpecialsIcon />
              </div>
              <div>
                Items with custom prices are marked with&nbsp;
                <Emoji content="⚡️" label="bolt" />
              </div>
              <div>
                Items with GST are marked with&nbsp;
                <Emoji content="💸" label="money" />
              </div>
              <span className={styles.Arrow} />
            </div>
          }
        >
          <div className={styles.WTFCellTitle}>
            WTF&nbsp;
            <InfoOutlinedIcon className={styles.Icon} />
          </div>
        </Tooltip>
      ),
      className: styles.WTF,
      columnDataFunction: () => 'TBC',
      // eslint-disable-next-line react/display-name
      getChild: (product) => (
        <WTFCell
          product={product}
          handleOpenCustomPriceDialog={() => handleOpenCustomPriceDialog(product)}
          venues={venues}
        />
      ),
      searchable: false,
    },
    {
      index: 4,
      title: 'Status',
      className: styles.Status,
      sortMethod: 'enabled',
      columnDataFunction: (product) => (product.enabled ? 'enabled' : 'disabled'),
      // eslint-disable-next-line react/display-name
      getChild: (product) => (
        <EditableTableCell
          defaultContent={
            <StatusChip
              title={product.enabled ? 'Enabled' : 'Disabled'}
              hideIcon
              type={product.enabled ? STATUS_CHIP_TYPES.SUCCESS : STATUS_CHIP_TYPES.FAILED}
              className={styles.StatusChip__noMargin}
            />
          }
          validationSchema={Yup.object().shape({
            enabled: Yup.boolean().required('Required'),
          })}
          fieldName={'status'}
          type={EDITABLE_TABLE_CELL_TYPES.SELECT}
          initialValue={product.enabled}
          selectOptions={{
            options: STATUS_OPTIONS,
            onChange: (newStatus) => handleProductStatusChange(newStatus.value, product),
          }}
        />
      ),
      searchable: true,
    },
    {
      index: 5,
      title: 'Stock',
      className: styles.Status,
      columnDataFunction: () => (product) => product.outOfStock ? 'Out of Stock' : 'In Stock',
      sortMethod: 'outOfStock',

      // eslint-disable-next-line react/display-name
      getChild: (product) => (
        <EditableTableCell
          defaultContent={
            <StatusChip
              title={product.outOfStock ? 'Out Of Stock' : 'In Stock'}
              hideIcon
              type={product.outOfStock ? STATUS_CHIP_TYPES.FAILED : STATUS_CHIP_TYPES.SUCCESS}
              className={styles.StatusChip__noMargin}
            />
          }
          fieldName={'stock'}
          type={EDITABLE_TABLE_CELL_TYPES.SELECT}
          selectOptions={{
            options: STOCK_OPTIONS,
            onChange: (newStatus) => handleProductStockChange(newStatus.value, product),
          }}
        />
      ),
      searchable: true,
    },
    {
      index: 6,
      title: 'Price (Ex. GST)',
      className: styles.Price,
      sortMethod: 'priceInCents',
      // eslint-disable-next-line react/display-name
      getChild: (product) => (
        <div>
          <EditableTableCell
            defaultContent={<div>{presentCurrency(product.priceInCents / 100)}</div>}
            validationSchema={Yup.object().shape({
              price: Yup.number()
                .typeError('Price must be a number')
                .required('Price is required')
                .min(0.1, 'Price must be at least $0.10'),
            })}
            fieldName={'price'}
            type={EDITABLE_TABLE_CELL_TYPES.TEXT}
            initialValue={(product.priceInCents / 100).toFixed(2)}
            textOptions={{
              submitFunction: (values, actions, handleResetTableStyling) =>
                handleUpdateProductPrice(product, values, actions, handleResetTableStyling),
              fieldProps: {
                type: 'number',
                step: '0.01',
                disabled: !product.id,
              },
            }}
            placeholder="Price"
          />
        </div>
      ),
      // eslint-disable-next-line react/display-name
      columnDataFunction: (product) => `${presentCurrency(product.priceInCents / 100)}`,
      searchable: true,
    },
    {
      index: 7,
      title: 'Price Unit',
      className: styles.PriceUnit,
      sortMethod: 'priceUnit',
      columnDataFunction: (product) => product.priceUnit,
      // eslint-disable-next-line react/display-name
      getChild: (product) => (
        <StatusChip
          title={product.priceUnit}
          hideIcon
          className={styles.StatusChip__noMargin}
          type={STATUS_CHIP_TYPES.DEFAULT}
        />
      ),
      searchable: true,
    },
    {
      index: 8,
      title: 'Portions',
      className: styles.Portions,
      sortMethod: 'portions',
      columnDataFunction: (product) => product.portions,
      searchable: true,
    },
    {
      index: isStaff ? 10 : 9,
      title: '🗓 Updated',
      className: styles.Date,
      sortMethod: 'updatedAt',
      columnDataFunction: (product) => product.updatedAt,
      searchable: true,
      getChild: (product) => format(product.updatedAt, DATE_FNS_FORMATS.DATE_TIME_SECONDS),
    },
  );

  if (isStaff) {
    productsColumnSpecs.push({
      index: 9,
      title: 'Best Buy',
      className: styles.IsBestBuy,
      sortMethod: 'isBestBuy',
      columnDataFunction: (product) => (
        <StatusChip
          title={product.isBestBuy ? 'Yes' : 'No'}
          hideIcon
          type={product.isBestBuy ? STATUS_CHIP_TYPES.SUCCESS : STATUS_CHIP_TYPES.DEFAULT}
          className={styles.StatusChip__noMargin}
        />
      ),

      searchable: true,
    });
  }

  const filterProducts = useCallback(
    (productsToFilter, searchToQueryAgainst) => {
      const gstFilterId = getProductGSTFilterIdQueryFromQueryString();
      const statusFilterId = getProductStatusFilterIdQueryFromQueryString();
      const imageFilterId = getProductImageFilterIdQueryFromQueryString();
      const customPricingFilterId = getProductCustomPricingFilterIdQueryFromQueryString();
      const stockFilterId = getStockFilterIdQueryFromQueryString();

      const filterKeys = productsColumnSpecs.filter((spec) => spec.searchable).map((spec) => spec.columnDataFunction);
      let newlyFilteredProducts = matchSorter(productsToFilter, searchToQueryAgainst, {
        keys: filterKeys,
        threshold: matchSorter.rankings.CONTAINS,
      });

      const selectedGSTFilter = GST_FILTERS.find((filter) => filter.id === gstFilterId);
      const selectedStatusFilter = STATUS_FILTERS.find((filter) => filter.id === statusFilterId);
      const selectedStockFilter = STOCK_FILTERS.find((filter) => filter.id === stockFilterId);
      const selectedImageFilter = IMAGE_FILTERS.find((filter) => filter.id === imageFilterId);
      const selectedCustomPricingFilter = CUSTOM_PRICING_FILTERS.find((filter) => filter.id === customPricingFilterId);

      if (selectedGSTFilter) {
        newlyFilteredProducts = newlyFilteredProducts.filter(
          (product) => product[selectedGSTFilter.filterField] === selectedGSTFilter.filterValue,
        );
      }

      if (selectedStatusFilter) {
        newlyFilteredProducts = newlyFilteredProducts.filter(
          (product) => product[selectedStatusFilter.filterField] === selectedStatusFilter.filterValue,
        );
      }

      if (selectedStockFilter) {
        newlyFilteredProducts = newlyFilteredProducts.filter(
          (product) => product[selectedStockFilter.filterField] === selectedStockFilter.filterValue,
        );
      }

      if (selectedCustomPricingFilter) {
        newlyFilteredProducts = newlyFilteredProducts.filter((product) =>
          selectedCustomPricingFilter.filterValue
            ? product[selectedCustomPricingFilter.filterField]?.length
            : !product[selectedCustomPricingFilter.filterField]?.length,
        );
      }

      if (isStaff && selectedImageFilter && selectedImageFilter.filterValue !== undefined) {
        newlyFilteredProducts = newlyFilteredProducts.filter(
          (product) => product.image.fromGallery === selectedImageFilter.filterValue,
        );
      }

      setFilteredProducts(newlyFilteredProducts);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getProductCustomPricingFilterIdQueryFromQueryString,
      getProductGSTFilterIdQueryFromQueryString,
      getProductImageFilterIdQueryFromQueryString,
      getProductStatusFilterIdQueryFromQueryString,
      getStockFilterIdQueryFromQueryString,
      isStaff,
    ],
  );

  const handleSelectProductsGSTFilter = (filterId) => {
    updateProductGSTFilterIdQueryParam(filterId);
    handleCloseProductsGSTFilterMenu();
    setCurrentPageNumber(1);
  };

  const handleSelectProductsStatusFilter = (filterId) => {
    updateProductStatusFilterIdQueryParam(filterId);
    handleCloseProductsStatusFilterMenu();
    setCurrentPageNumber(1);
  };

  const handleSelectStockFilter = (filterId) => {
    updateStockFilterIdQueryParam(filterId);
    handleCloseStockFilterMenu();
    setCurrentPageNumber(1);
  };

  const handleSelectProductsImageFilter = (filterId) => {
    updateProductImageFilterIdQueryParam(filterId);
    handleCloseProductsImageFilterMenu();
    setCurrentPageNumber(1);
  };

  const handleSelectProductsCustomPricingFilter = (filterId) => {
    updateProductCustomPricingFilterIdQueryParam(filterId);
    handleCloseProductsCustomPricingFilterMenu();
    setCurrentPageNumber(1);
  };

  const handleRowClick = (product) => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 768) {
      redirectToProduct(product.id);
    }
  };

  const createRowActions = (product) => (
    <Button
      onClick={() => redirectToProductWithQueryParams(product.id, window.location?.search)}
      className={styles.ViewProductBtn}
    >
      View
      <KeyboardArrowRightIcon />
    </Button>
  );

  const fetchProducts = useCallback(() => {
    setLoadingProducts(true);
    FoodbombAPI.get(`suppliers/products`, { credentials: 'include' })
      .then((response) => {
        const productsData = response.data.data.map((product) => ({
          ...product,
          updatedAt: product.dateModified
            ? parse(product.dateModified, DATE_FNS_FORMATS.DB_DATE_FORMAT, new Date())
            : undefined,
          priceUnit: product.unitOfPrice ? product.unitOfPrice.title : undefined,
          customPricing: product.customPricing ? product.customPricing : [],
          portions:
            product.portions && product.unitOfPortion
              ? product.portions.map((portion) => `${portion}${product.unitOfPortion.unit}`).join(', ')
              : undefined,
        }));
        setProducts(productsData);
        setShouldShowSearchBar(Boolean(productsData.length));
      })
      .catch((error) => {
        sendDatadogError('Unable to load products', {
          error,
          location: 'Products Index Page',
        });
        setAPIError(<ErrorNotification />);
      })
      .finally(() => {
        setLoadingProducts(false);
      });
  }, [sendDatadogError]);

  useEffect(() => {
    fetchProducts();
    loadVenues();
  }, [fetchProducts, loadVenues]);

  useEffect(() => {
    filterProducts(products, debouncedSearchQuery);
  }, [filterProducts, debouncedSearchQuery, products]);

  // Update the query string when the debounce search changes
  useEffect(() => {
    updateSearchQueryParam(debouncedSearchQuery);
    setCurrentPageNumber(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  return (
    <React.Fragment>
      <div className={styles.PageContainer}>
        <PageHeader>
          <div className={styles.BreadcrumbsContainer}>
            <div>
              <Breadcrumbs
                currentPageTitle={
                  <React.Fragment>
                    <Emoji content="🍏" label="apple" />
                    &nbsp; Products
                  </React.Fragment>
                }
              />
              <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.Font__standardGrey}>
                Here are all of your products
              </Typography>
            </div>
            <div className={styles.ActionBar__buttonsContainer}>
              {isStaff ? (
                <Button
                  variant={
                    getProductImageFilterIdQueryFromQueryString() ? BUTTON_VARIANTS.SUCCESS : BUTTON_VARIANTS.SECONDARY
                  }
                  className={styles.ActionBar__button}
                  onClick={handleProductsImageFilterClicked}
                >
                  {`${
                    IMAGE_FILTERS.find((filter) => filter.id === getProductImageFilterIdQueryFromQueryString()).title
                  }`}
                  <ArrowDropDownIcon className={styles.DropdownIcon} />
                </Button>
              ) : null}
              <Button
                variant={getStockFilterIdQueryFromQueryString() ? BUTTON_VARIANTS.SUCCESS : BUTTON_VARIANTS.SECONDARY}
                className={styles.ActionBar__button}
                onClick={handleStockFilterClicked}
              >
                {STOCK_FILTERS.find((filter) => filter.id === getStockFilterIdQueryFromQueryString()).title}
                <ArrowDropDownIcon className={styles.DropdownIcon} />
              </Button>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                className={styles.ActionBar__button}
                onClick={handleCSVDropdownClicked}
              >
                Import / Export CSV
                <ArrowDropDownIcon className={styles.DropdownIcon} />
              </Button>
            </div>
          </div>
          <div className={styles.ActionBar}>
            <div className={styles.SearchBarContainer}>
              {shouldShowSearchBar ? (
                <SearchBar
                  placeholder="Search products"
                  // Matching is happening on change, method required for react error
                  handleSearch={() => {}}
                  onChange={handleSearchChange}
                  handleClear={() => setSearchQuery('')}
                  value={searchQuery}
                />
              ) : null}
            </div>
            <div className={styles.ActionBar__buttonsContainer}>
              <Button
                variant={
                  getProductStatusFilterIdQueryFromQueryString() ? BUTTON_VARIANTS.SUCCESS : BUTTON_VARIANTS.SECONDARY
                }
                className={styles.ActionBar__button}
                onClick={handleProductsStatusFilterClicked}
              >
                {STATUS_FILTERS.find((filter) => filter.id === getProductStatusFilterIdQueryFromQueryString()).title}
                <ArrowDropDownIcon className={styles.DropdownIcon} />
              </Button>
              <Button
                variant={
                  getProductGSTFilterIdQueryFromQueryString() ? BUTTON_VARIANTS.SUCCESS : BUTTON_VARIANTS.SECONDARY
                }
                className={styles.ActionBar__button}
                onClick={handleProductsGSTFilterClicked}
              >
                {GST_FILTERS.find((filter) => filter.id === getProductGSTFilterIdQueryFromQueryString()).title}
                <ArrowDropDownIcon className={styles.DropdownIcon} />
              </Button>
              <Button
                variant={
                  getProductCustomPricingFilterIdQueryFromQueryString()
                    ? BUTTON_VARIANTS.SUCCESS
                    : BUTTON_VARIANTS.SECONDARY
                }
                className={styles.ActionBar__button}
                onClick={handleProductsCustomPricingFilterClicked}
              >
                {
                  CUSTOM_PRICING_FILTERS.find(
                    (filter) => filter.id === getProductCustomPricingFilterIdQueryFromQueryString(),
                  ).title
                }
                <ArrowDropDownIcon className={styles.DropdownIcon} />
              </Button>
              <Button className={styles.ActionBar__button} onClick={redirectToNewProduct}>
                Create Product
              </Button>
            </div>
            <Menu
              open={Boolean(CSVDropdownAnchorElem)}
              anchorEl={CSVDropdownAnchorElem}
              onClose={handleCloseCSVDropdown}
            >
              <MenuItem onClick={redirectToAddMultipleProducts} classes={{ root: styles.MenuLink }}>
                Add Products Via CSV
              </MenuItem>
              <MenuItem onClick={redirectToEditMultipleProducts} classes={{ root: styles.MenuLink }}>
                Edit Products Via CSV
              </MenuItem>
            </Menu>
            <Menu
              open={Boolean(productsImageFilterAnchorElem)}
              anchorEl={productsImageFilterAnchorElem}
              onClose={handleCloseProductsImageFilterMenu}
            >
              {IMAGE_FILTERS.map((filter) => (
                <MenuItem
                  key={filter.title}
                  onClick={() => handleSelectProductsImageFilter(filter.id)}
                  classes={{ root: styles.MenuLink }}
                >
                  {filter.title}
                </MenuItem>
              ))}
            </Menu>
            <Menu
              open={Boolean(productsStatusFilterAnchorElem)}
              anchorEl={productsStatusFilterAnchorElem}
              onClose={handleCloseProductsStatusFilterMenu}
            >
              {STATUS_FILTERS.map((filter) => (
                <MenuItem
                  key={filter.title}
                  onClick={() => handleSelectProductsStatusFilter(filter.id)}
                  classes={{ root: styles.MenuLink }}
                >
                  {filter.title}
                </MenuItem>
              ))}
            </Menu>
            <Menu
              open={Boolean(stockFilterAnchorElem)}
              anchorEl={stockFilterAnchorElem}
              onClose={handleCloseStockFilterMenu}
            >
              {STOCK_FILTERS.map((filter) => (
                <MenuItem
                  key={filter.title}
                  onClick={() => handleSelectStockFilter(filter.id)}
                  classes={{ root: styles.MenuLink }}
                >
                  {filter.title}
                </MenuItem>
              ))}
            </Menu>
            <Menu
              open={Boolean(productsGSTFilterAnchorElem)}
              anchorEl={productsGSTFilterAnchorElem}
              onClose={handleCloseProductsGSTFilterMenu}
            >
              {GST_FILTERS.map((filter) => (
                <MenuItem
                  key={filter.title}
                  onClick={() => handleSelectProductsGSTFilter(filter.id)}
                  classes={{ root: styles.MenuLink }}
                >
                  {filter.title}
                </MenuItem>
              ))}
            </Menu>
            <Menu
              open={Boolean(productsCustomPricingFilterAnchorElem)}
              anchorEl={productsCustomPricingFilterAnchorElem}
              onClose={handleCloseProductsCustomPricingFilterMenu}
            >
              {CUSTOM_PRICING_FILTERS.map((filter) => (
                <MenuItem
                  key={filter.title}
                  onClick={() => handleSelectProductsCustomPricingFilter(filter.id)}
                  classes={{ root: styles.MenuLink }}
                >
                  {filter.title}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </PageHeader>
        {APIError ? (
          <div className={styles.ErrorWrapper}>{APIError}</div>
        ) : (
          <div className={styles.TableContainer}>
            <Table
              className={styles.ProductsTable}
              columnSpecs={productsColumnSpecs}
              rowData={filteredProducts}
              loadingRowData={loadingProducts}
              handleRowClick={handleRowClick}
              rowOverlayColSpan={isStaff ? 2 : 1}
              rowActions={createRowActions}
              noResultsComponent={
                <Paper className={styles.NoResultsContainer}>
                  <div className={styles.TypographySection}>
                    <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
                      <span role="img" aria-label="wave">
                        👋
                      </span>
                    </Typography>
                    <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Welcome to your products page</Typography>
                    <div className={styles.TypographySection}>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                        Here is where we&apos;ll display all of products
                      </Typography>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                        To get started, please add your first product!
                      </Typography>
                    </div>
                  </div>
                  <div className={styles.NoResultsActions}>
                    <Button onClick={redirectToNewProduct}>Create Product</Button>
                  </div>
                </Paper>
              }
              searchQuery={debouncedSearchQuery}
              isFiltering={Boolean(
                getProductGSTFilterIdQueryFromQueryString() + getProductStatusFilterIdQueryFromQueryString(),
              )}
              grow
              defaultSortColumn={isStaff ? 3 : 2}
              sortAscending={true}
              paginationOverrideData={{
                currentPageNumber,
                onNextPageClick: () => setCurrentPageNumber((prev) => prev + 1),
                onPreviousPageClick: () => setCurrentPageNumber((prev) => (prev === 1 ? 1 : prev - 1)),
                pageSize: PAGE_SIZE,
                numberOfResults: filteredProducts.length,
                resetToPageOne: () => setCurrentPageNumber(1),
              }}
              useServerSidePagination={false}
            />
          </div>
        )}
      </div>
      <ProductImageDialog
        isOpen={productImageDialogOpen}
        handleClose={handleCloseProductImageDialog}
        productImageURL={productImageURLForDialog}
      />
      {productForCustomPriceDialog && venues.length ? (
        <CustomPriceDialog
          isOpen={customPriceDialogOpen}
          handleClose={handleCloseCustomPriceDialog}
          product={productForCustomPriceDialog}
          venues={venues}
          updateProductData={updateProductData}
        />
      ) : null}
      {isStaff && imageGallery.length && selectedProductToUpdateImage ? (
        <ProductImageSelectorModal
          onSave={handleUpdateImage}
          onClose={handleCloseImageSelectorDialog}
          open={Boolean(selectedProductToUpdateImage)}
          selectedProduct={selectedProductToUpdateImage}
          updatingImageInProgress={updatingImageInProgress}
          updatingImageError={updatingImageError}
          imageGallery={imageGallery}
          productImageSearchQuery={productImageSearchQuery}
          setProductImageSearchQuery={setProductImageSearchQuery}
        />
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isStaff: state.auth.supplierDetails ? state.auth.supplierDetails.isStaff : false,
  imageGallery: state.images ? state.images.images : [],
});

Products.propTypes = {
  redirectToNewProduct: PropTypes.func.isRequired,
  redirectToProduct: PropTypes.func.isRequired,
  redirectToProductWithQueryParams: PropTypes.func.isRequired,
  isStaff: PropTypes.bool,
  createNotification: PropTypes.func.isRequired,
  redirectToEditMultipleProducts: PropTypes.func.isRequired,
  redirectToAddMultipleProducts: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  imageGallery: PropTypes.array.isRequired,
  getProductGSTFilterIdQueryFromQueryString: PropTypes.func.isRequired,
  updateProductGSTFilterIdQueryParam: PropTypes.func.isRequired,
  getProductImageFilterIdQueryFromQueryString: PropTypes.func.isRequired,
  updateProductImageFilterIdQueryParam: PropTypes.func.isRequired,
  getProductStatusFilterIdQueryFromQueryString: PropTypes.func.isRequired,
  updateProductStatusFilterIdQueryParam: PropTypes.func.isRequired,
  getQueryFromQueryString: PropTypes.func.isRequired,
  updateSearchQueryParam: PropTypes.func.isRequired,
  getProductCustomPricingFilterIdQueryFromQueryString: PropTypes.func.isRequired,
  updateProductCustomPricingFilterIdQueryParam: PropTypes.func.isRequired,
  getStockFilterIdQueryFromQueryString: PropTypes.func.isRequired,
  updateStockFilterIdQueryParam: PropTypes.func.isRequired,
};

export default withErrorReports(
  withNotifications(withRedirectHelper(withQueryParams(connect(mapStateToProps, null)(Products)))),
);
