import { CircularProgress, FormControlLabel, Grid, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetAppIcon from '@material-ui/icons/GetApp';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import { saveAs } from 'file-saver';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import CustomerAlertChip from '../../components/CustomerAlertChip/CustomerAlertChip';
import OrderStatusChip from '../../components/OrderStatusChip/OrderStatusChip.tsx';
import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import {
  AlertDialog,
  Button,
  EditableTableCell,
  Emoji,
  ErrorNotification,
  Menu,
  NotificationMessageSection,
  Paper,
  StatusChip,
  Table,
  Tooltip,
  Typography,
} from '../../components/UI/FB';
import LargeLoadingSpinner from '../../components/UI/LargeLoadingSpinner/LargeLoadingSpinner';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import { OBJECT_TYPES, PAGE_POSITION } from '../../hoc/withEventTracking/Properties';
import withEventTracking from '../../hoc/withEventTracking/withEventTracking';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import withRedirectHelper from '../../hoc/withRedirectHelper/withRedirectHelper';
import { FoodbombAPI, FoodbombPDFAPI } from '../../utils/AxiosInstances';
import {
  ALERT_TYPES,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
  COMPLETED_ORDER,
  CUSTOMER_ALERT_TYPES,
  DELIVERY_DOCKET_VALID_STATUSES,
  EDITABLE_TABLE_CELL_TYPES,
  NOTIFICATION_MESSAGE_TYPES,
  NOTIFICATION_TYPES,
  ORDER_STATUSES,
  ORDER_TYPES,
  PENDING_ORDER,
  STATUS_CHIP_TYPES,
  TYPOGRAPHY_TYPES,
} from '../../utils/Constants';
import PDFFileNameFromResponse from '../../utils/PDFFileNameFromResponse';
import { presentCurrency } from '../../utils/Presenters/PresentCurrency/PresentCurrency';
import AddCustomSubstitutionDialog from './AddCustomSubstitutionDialog/AddCustomSubstitutionDialog';
import CancelOrderDialog from './CancelOrderDialog/CancelOrderDialog';
import ConfirmationOrderDialog from './ConfirmationOrderDialog/ConfirmationOrderDialog.tsx';
import ProductRemovalDialog from './ProductRemovalDialog/ProductRemovalDialog.tsx';
import styles from './Order.module.scss';
import OrderTypeChip from '../../components/OrderTypeChip/OrderTypeChip.tsx';
import AddSupplierOrderIdDialog from './AddSupplierOrderIdDialog/AddSupplierOrderIdDialog.tsx';

const Order = ({
  isStaff,
  createNotification,
  match,
  redirectToOrders,
  redirectToProduct,
  sendDatadogError,
  trackDownloadPDF,
}) => {
  const [APIError, setAPIError] = useState(undefined);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [order, setOrder] = useState({});
  const [updatedProducts, setUpdatedProducts] = useState([]);
  const [substitutions, setSubstitutions] = useState([]);
  const [customSubstitutionDialogOpen, setCustomSubstitutionDialogOpen] = useState(false);
  const [downloadingDeliveryDetails, setDownloadingDeliveryDetails] = useState(false);
  const [downloadingDeliverySlip, setDownloadingDeliverySlip] = useState(false);
  const [downloadingPurchaseOrder, setDownloadingPurchaseOrder] = useState(false);
  const [downloadingInvoice, setDownloadingInvoice] = useState(false);
  const [processingOrder, setProcessingOrder] = useState(false);
  const [cancelOrderDialogOpen, setCancelOrderDialogOpen] = useState(false);
  const [confirmationOrderDialogOpen, setConfirmationOrderDialogOpen] = useState(false);
  const [pulsingSubstitution, setPulsingSubstitution] = useState(undefined);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [processOrderButtonDisabled, setProcessOrderButtonDisabled] = useState(false);
  const [documentsDropDownAnchorElem, setDocumentsDropDownAnchorElem] = useState(undefined);
  const [supplier, setSupplier] = useState(null);
  const [actionMenu, setActionsMenu] = useState(null);
  const [productToRemove, setProductToRemove] = useState(null);
  const [actionableProduct, setActionableProduct] = useState(null);
  const [supplierOrderIdModalOpen, setSupplierOrderIdModalOpen] = useState(false);

  const zeroProducts =
    updatedProducts?.length === order?.orderProducts?.length
      ? updatedProducts.every((product) => product.reason)
      : false;

  const handleOpenActionMenu = (e, product) => {
    setActionsMenu(e.currentTarget);
    setActionableProduct(product);
  };

  const handleCloseActionMenu = () => {
    setActionsMenu(null);
    setActionableProduct(null);
  };

  const handleRemoveProductDialogClose = () => {
    setProductToRemove(null);
  };

  const handleSupplierOrderIdClicked = () => setSupplierOrderIdModalOpen(true);
  // Dropdown handlers
  const handleDocumentsDropdownClicked = (e) => setDocumentsDropDownAnchorElem(e.currentTarget);
  const closeDocumentsDropdownAnd = (callback) => {
    setDocumentsDropDownAnchorElem(undefined);
    callback();
  };

  const openAddCustomSubstitutionDialog = () => {
    setCustomSubstitutionDialogOpen(true);
  };

  const closeAddCustomSubstitutionDialog = () => {
    setCustomSubstitutionDialogOpen(false);
  };
  const triggerCancelOrderDialog = () => {
    setCancelOrderDialogOpen(true);
    createNotification({
      type: NOTIFICATION_TYPES.INFO,
      content: 'Rather than zeroing all items, please cancel the order if you are unable to fulfill it.',
      timeout: 5000,
      closable: true,
    });
  };

  const fetchSupplierDetails = useCallback(
    () =>
      FoodbombAPI.get(`suppliers/auth/me`)
        .then((response) => {
          setSupplier(response?.data);
        })
        .catch((error) => {
          sendDatadogError(`Unable to load supplier details`, {
            error,
            location: 'Order show page',
          });
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to load supplier details',
            timeout: 3000,
            closable: true,
          });
        }),
    [createNotification, sendDatadogError],
  );

  const getOrder = useCallback(() => {
    const orderId = parseInt(match.params.id, 10);
    return FoodbombAPI.get(`/suppliers/orders/${orderId}`)
      .then((response) => {
        setOrder(response.data);
      })
      .catch((error) => {
        sendDatadogError(`Unable to load order ${orderId}`, {
          error,
          location: 'Order Processing Page',
        });
        setAPIError(
          <ErrorNotification
            actions={
              <Button onClick={redirectToOrders} className={styles.NoResultsBtn}>
                Return to Orders
              </Button>
            }
            body={' We were unable to find your order at this point in time. Our engineers have been notified.'}
          />,
        );
      });
  }, [match.params.id, redirectToOrders, sendDatadogError]);

  const fetchData = useCallback(async () => {
    setLoadingOrder(true);
    const promisesToRun = [fetchSupplierDetails(), getOrder()];
    await Promise.all(promisesToRun);
    setLoadingOrder(false);
  }, [fetchSupplierDetails, getOrder]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const downloadDeliveryDetails = () => {
    setDownloadingDeliveryDetails(true);
    const apiUrl = `orders/${order.orderId}/delivery-instructions`;
    FoodbombPDFAPI.get(apiUrl, { responseType: 'blob' })
      .then((response) => {
        if (!response.data) {
          throw new TypeError(
            `Expected data of type Object for get delivery instructions, got ${JSON.stringify(response)}`,
          );
        }
        saveAs(response.data, PDFFileNameFromResponse(response));
      })
      .catch((error) => {
        sendDatadogError('Failed to download delivery instructions', {
          error,
          location: 'Supplier Portal Order Page',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to download the delivery instructions. Please try again later',
          timeout: 3000,
          closable: true,
        });
      })
      .finally(() => setDownloadingDeliveryDetails(false));
  };

  const downloadDeliverySlip = () => {
    setDownloadingDeliverySlip(true);
    const apiUrl = `orders/${order.orderId}/delivery-docket`;
    FoodbombPDFAPI.get(apiUrl, { responseType: 'blob' })
      .then((response) => {
        if (!response.data) {
          throw new TypeError(
            `Expected data of type Object for get delivery instructions, got ${JSON.stringify(response)}`,
          );
        }
        saveAs(response.data, PDFFileNameFromResponse(response));
        trackDownloadPDF(OBJECT_TYPES.DELIVERY_SLIP, [order.orderId], apiUrl, {
          uiPosition: PAGE_POSITION.HEADER,
        });
      })
      .catch((error) => {
        sendDatadogError('Failed to download delivery slip', {
          error,
          location: 'Supplier Portal Order Page',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to download the delivery slip. Please try again later',
          timeout: 3000,
          closable: true,
        });
      })
      .finally(() => setDownloadingDeliverySlip(false));
  };

  const downloadInvoice = () => {
    setDownloadingInvoice(true);
    const apiUrl = `orders/${order.orderId}/invoice`;
    FoodbombPDFAPI.get(apiUrl, { responseType: 'blob' })
      .then((response) => {
        if (!response.data) {
          throw new TypeError(
            `Expected data of type Object for get delivery instructions, got ${JSON.stringify(response)}`,
          );
        }
        saveAs(response.data, PDFFileNameFromResponse(response));
        trackDownloadPDF(OBJECT_TYPES.INVOICE, [order.orderId], apiUrl, {
          uiPosition: PAGE_POSITION.HEADER,
        });
      })
      .catch((error) => {
        sendDatadogError('Unable to download invoice', {
          error,
          location: 'Supplier Portal Order page',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to download the invoice. Please try again later',
          timeout: 3000,
          closable: true,
        });
      })
      .finally(() => {
        setDownloadingInvoice(false);
      });
  };

  const downloadPurchaseOrder = () => {
    setDownloadingPurchaseOrder(true);
    const apiUrl = `orders/${order.orderId}/purchase-order`;
    FoodbombPDFAPI.get(apiUrl, { responseType: 'blob' })
      .then((response) => {
        if (!response.data) {
          throw new TypeError(
            `Expected data of type Object for get delivery instructions, got ${JSON.stringify(response)}`,
          );
        }
        saveAs(response.data, PDFFileNameFromResponse(response));
        trackDownloadPDF(OBJECT_TYPES.PURCHASE_ORDER, [order.orderId], apiUrl, {
          uiPosition: PAGE_POSITION.HEADER,
        });
      })
      .catch((error) => {
        sendDatadogError('Unable to download purchase order', {
          error,
          location: 'Supplier Portal Order page',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to download the purchase order. Please try again later',
          timeout: 3000,
          closable: true,
        });
      })
      .finally(() => {
        setDownloadingPurchaseOrder(false);
      });
  };

  const processOrder = () => {
    setProcessingOrder(true);
    FoodbombPDFAPI.put(`suppliers/orders/${order.orderId}/process`, {
      modifiedOrderProducts: updatedProducts.map((prod) => ({
        id: prod.orderProductId,
        suppliedQuantity: parseFloat(prod.finalQuantityPriced),
        reason: prod.reason || null,
      })),
      substitutions: substitutions.map((sub) => ({
        sku: sub.sku,
        priceIncGST: sub.priceIncGST,
        gstExempt: sub.hasGST ? 0 : 1,
        name: sub.name,
        suppliedQuantity: parseFloat(sub.finalQuantityPriced),
      })),
    })
      .then((response) => {
        setOrder(response.data);
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Order processed successfully',
          timeout: 3000,
          closable: true,
        });
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const updatedProductsWithError = [...updatedProducts];
          error.response.data.errors.forEach((e) => {
            const productIdx = e.param.split('.')[1];
            updatedProductsWithError[productIdx] = { ...updatedProductsWithError[productIdx], error: e.message };
          });
          setUpdatedProducts(updatedProductsWithError);
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Please correct the errors above',
            timeout: 5000,
            closable: true,
          });
        } else {
          sendDatadogError('Unable to process order', {
            error,
            location: 'Supplier Portal Order page',
          });
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to process order. Please try again later',
            timeout: 3000,
            closable: true,
          });
        }
      })
      .finally(() => {
        setProcessingOrder(false);
        setConfirmationOrderDialogOpen(false);
      });
  };

  const onSuccessfullyCancelledOrder = () => {
    const updatedOrder = { ...order, orderStatus: ORDER_STATUSES.CANCELLED };
    setOrder(updatedOrder);
    setCancelOrderDialogOpen(false);
  };

  const triggerPulseForSubstitution = (sub) => {
    setPulsingSubstitution(sub.name);
    setTimeout(() => {
      setPulsingSubstitution(undefined);
    }, 1600);
  };

  const displayOrderType = () => {
    let type = '';
    if (order.parentOrderId) {
      type = ORDER_TYPES.TOPUP;
    } else if (order.standingOrderId) {
      type = ORDER_TYPES.STANDING;
    } else {
      type = ORDER_TYPES.REGULAR;
    }
    return <OrderTypeChip apiOrderType={type} />;
  };

  const createAnnotatedProduct = (product) => {
    const currentUpdatedProduct = updatedProducts.find(
      (updatedProduct) => updatedProduct.orderProductId === product.orderProductId,
    );
    return (
      <React.Fragment>
        {product.isOnSpecial ? (
          <Tooltip
            placement="top"
            title={
              <div>
                <WhatshotIcon className={styles.Flame_Icon_Orange} />
                &nbsp; means this product was sold on special
              </div>
            }
          >
            <WhatshotIcon className={styles.Flame_Icon_Orange} />
          </Tooltip>
        ) : null}
        {product.isOnCustomPrice ? (
          <Tooltip
            placement="top"
            title={
              <div>
                <Emoji content="⚡️" label="lightning" />
                &nbsp; means this product has a custom price
              </div>
            }
          >
            <div>
              <Emoji content="⚡️" label="lightning" />
            </div>
          </Tooltip>
        ) : null}

        {product.substitution ? (
          <div
            className={
              // eslint-disable-next-line no-nested-ternary
              parseFloat(product.finalQuantityPriced) === 0
                ? styles.Strikethrough
                : pulsingSubstitution === product.name
                ? [styles.Substitution, styles.pulse].join(' ')
                : styles.Substitution
            }
          >
            {product.name}
          </div>
        ) : (
          <div
            className={
              currentUpdatedProduct?.reason || product?.reason
                ? [styles.ProductNameContainer, styles.Tall].join(' ')
                : styles.ProductNameContainer
            }
          >
            <button
              className={
                parseFloat(product.finalQuantityPriced) === 0
                  ? [styles.Link, styles.Grey].join(' ')
                  : [styles.Link, styles.Active].join(' ')
              }
              onClick={() => redirectToProduct(product.productId)}
            >
              {product.name}
            </button>
            <div>
              {currentUpdatedProduct?.reason || product.reason ? (
                <div className={styles.SubstitutionText}>
                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SubtitutionTextColor}>
                    <strong>Product not supplied reason: </strong> {currentUpdatedProduct?.reason || product.reason}
                  </Typography>
                </div>
              ) : null}
            </div>
            <div>
              {currentUpdatedProduct?.error ? (
                <div>
                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ErrorText}>
                    <strong>Error:&nbsp;</strong>
                    {currentUpdatedProduct?.error}
                  </Typography>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  const getOrderPercentageChange = () =>
    (
      ((parseInt(order.finalTotalIncGST, 10) - parseInt(order.originalTotalIncGST, 10)) /
        parseInt(order.originalTotalIncGST, 10)) *
      100
    ).toFixed(1);

  const createPriceField = (product) => (
    <React.Fragment>
      {
        <span className={parseFloat(product.finalQuantityPriced) === 0 ? styles.Strikethrough : ''}>
          {presentCurrency(product.priceIncGST / 100)}/{product.unitOfPrice}
        </span>
      }
    </React.Fragment>
  );

  const findIndexOfProduct = (orderToSearch, productToUpdate) => {
    if (productToUpdate.substitution) {
      // if substitution, find by name as productId is 0
      return orderToSearch.orderProducts.findIndex((prod) => prod.name === productToUpdate.name && prod.substitution);
    }
    return orderToSearch.orderProducts.findIndex((prod) => prod.orderProductId === productToUpdate.orderProductId);
  };

  const calculateUpdatedOrderTotal = (product, newProductTotal) => {
    const priceDiff = newProductTotal - product.finalTotalIncGST;
    if (product.substitution) {
      return priceDiff === 0 // substitution was zeroed
        ? parseInt(order.finalTotalIncGST, 10) - product.priceIncGST
        : parseInt(order.finalTotalIncGST, 10) + priceDiff;
    }
    return priceDiff === 0
      ? parseInt(order.finalTotalIncGST, 10) - product.priceIncGST // product was zeroed and then re-added
      : parseInt(order.finalTotalIncGST, 10) + priceDiff;
  };

  const updateChangedProductsList = (updatedProd, reason = null) => {
    const updatedProductsCopy = [...updatedProducts];
    const prodIdxInList = updatedProducts.findIndex((prod) => prod.orderProductId === updatedProd.orderProductId);
    if (prodIdxInList !== -1) {
      updatedProductsCopy.splice(prodIdxInList, 1);
    }
    if (parseFloat(updatedProd.originalQuantityPriced) !== parseFloat(updatedProd.finalQuantityPriced)) {
      updatedProductsCopy.push({ ...updatedProd, reason });
    }
    setUpdatedProducts(updatedProductsCopy);
  };

  const updateSubstitutionList = (updatedProduct, idxToUpdate, updatedOrderProductsData) => {
    const updatedSubstitutions = [...substitutions];
    const substitutionIdx = substitutions.findIndex((prod) => prod.name === updatedProduct.name);
    if (parseFloat(updatedProduct.finalQuantityPriced) === 0) {
      updatedSubstitutions.splice(substitutionIdx, 1);
      updatedOrderProductsData.splice(idxToUpdate, 1);
    } else {
      updatedSubstitutions[substitutionIdx] = updatedProduct;
    }
    setSubstitutions(updatedSubstitutions);
    return updatedOrderProductsData;
  };

  const handleQuantityChanged = (values, actions, handleResetTableStyling, product, reason = null) => {
    const updatedQtyProvided = parseFloat(values.qtyProvided);
    // ignore cases where there is no change in the value supplied
    if (parseFloat(updatedQtyProvided) === parseFloat(product.finalQuantityPriced)) {
      actions?.setSubmitting(false);
      if (handleResetTableStyling) {
        handleResetTableStyling();
      }
      return;
    }

    const newProductTotal = Math.round(product.priceIncGST * updatedQtyProvided); // calculation is in cents
    // update product
    const updatedProduct = {
      ...product,
      finalQuantityPriced: updatedQtyProvided,
      finalTotalIncGST: newProductTotal,
    };
    if (handleResetTableStyling) {
      handleResetTableStyling();
    }

    const idxToUpdate = findIndexOfProduct(order, product);
    let updatedOrderProductsData = [...order.orderProducts];
    updatedOrderProductsData[idxToUpdate] = updatedProduct;

    if (product.substitution) {
      updatedOrderProductsData = updateSubstitutionList(updatedProduct, idxToUpdate, updatedOrderProductsData);
    } else {
      updateChangedProductsList(updatedProduct, reason);
    }
    const updatedOrderTotal = calculateUpdatedOrderTotal(product, newProductTotal);
    // update order total and products list
    const updatedOrder = {
      ...order,
      orderProducts: updatedOrderProductsData,
      finalTotalIncGST: updatedOrderTotal,
    };
    setOrder(updatedOrder);
    actions?.setSubmitting(false);
    if (handleResetTableStyling) {
      handleResetTableStyling();
    }
  };

  const onSubstitutionAdded = (productAdded) => {
    const updatedOrderProductsData = [...order.orderProducts];
    updatedOrderProductsData.push(productAdded);
    const updatedSubstitutions = [...substitutions, productAdded];
    setSubstitutions(updatedSubstitutions);

    const updatedOrderTotal = order.finalTotalIncGST + productAdded.finalTotalIncGST;
    const updatedOrder = { ...order, orderProducts: updatedOrderProductsData, finalTotalIncGST: updatedOrderTotal };
    setOrder(updatedOrder);
    triggerPulseForSubstitution(productAdded);
    closeAddCustomSubstitutionDialog();
  };

  const quantityValidationObject = Yup.object({
    qtyProvided: Yup.number()
      .typeError('Supplied quantity must be a number')
      .required('Supplied quantity is required')
      .min(0, 'Quantity must be a positive number')
      .moreThan(0, "Supplied quantity can't be 0")
      .test('test for max 3 decimal precision', 'Quantity cannot be over 3 decimal places', (value) => {
        const decimals = `${value}`.split('.')[1];
        return decimals ? decimals.length <= 3 : true;
      }),
  });

  const getPaymentMethod = (paymentString) => {
    if (paymentString === 'credit_card') {
      const payment = paymentString.replace('_', ' ');
      return payment[0].toUpperCase() + payment.slice(1);
    }
    if (paymentString === 'trevipay') {
      return 'BombPay';
    }
    return paymentString;
  };

  const totalBeenChanged = (product) =>
    product.substitution || product.originalTotalIncGST !== product.finalTotalIncGST;

  const getProductPercentageChange = (product) => {
    if (product.substitution) {
      return 0;
    }
    if (product.originalTotalIncGST !== product.finalTotalIncGST) {
      return (
        ((product.finalQuantityPriced - parseFloat(product.originalQuantityPriced)) * 100) /
        parseFloat(product.originalQuantityPriced)
      ).toFixed(2);
    }
    return 0;
  };

  const getProductPercentageChangeAsText = (product) => {
    if (product.substitution) {
      return '';
    }
    if (product.originalTotalIncGST !== product.finalTotalIncGST) {
      if (getProductPercentageChange(product) > 0) {
        return `(+${getProductPercentageChange(product)}%)`;
      }
      return `(${getProductPercentageChange(product)}%)`;
    }
    return '';
  };

  const isToday = (date) => Moment(date).startOf('day').isSame(Moment().startOf('day'));

  const isTomorrow = (date) => Moment(date).startOf('day').isSame(Moment().add(1, 'days').startOf('day'));

  const handleSupplierOrderIdAdded = (supplierReference) => {
    setOrder((prev) => ({
      ...prev,
      supplierReference,
    }));
  };
  // NOTE: We might add more links for drop down actions in the future
  const dropDownActions = [
    {
      onClick: () => {
        if (actionableProduct.substitution) {
          handleQuantityChanged({ qtyProvided: 0 }, null, null, actionableProduct);
        } else {
          setProductToRemove(actionableProduct);
        }
        handleCloseActionMenu();
      },
      actionName: 'Zero product',
      visible: true,
    },
  ];

  const productsColumnSpecs = [
    {
      index: 0,
      title: '#',
      sortMethod: 'orderProductId',
      className: styles.OrderProductId,
      columnDataFunction: (product) => product.orderProductId,
    },
    {
      index: 1,
      title: 'SKU',
      sortMethod: 'sku',
      className: styles.SKU,
      columnDataFunction: (product) => product.sku,
    },
    {
      index: 2,
      title: 'Product',
      sortMethod: 'name',
      className: styles.ProductName,
      columnDataFunction: (product) => product.name,
      getChild: (product) => createAnnotatedProduct(product),
    },
    {
      index: 3,
      title: 'Portion',
      sortMethod: 'portionSize',
      columnDataFunction: (product) => product.portionSize,
      // eslint-disable-next-line react/display-name
      getChild: (product) => (
        <div
          className={parseFloat(product.finalQuantityPriced) === 0 ? styles.Strikethrough : ''}
        >{`${product.portionSize} ${product.unitOfPortion}`}</div>
      ),
    },
    {
      index: 4,
      className: styles.Quantity,
      title: 'QTY',
      columnDataFunction: (product) => parseFloat(product.originalNumberOfPortions),
      // eslint-disable-next-line react/display-name
      getChild: (product) => (
        <div className={parseFloat(product.finalQuantityPriced) === 0 ? styles.Strikethrough : ''}>
          {parseFloat(product.originalNumberOfPortions)}
        </div>
      ),
    },
    {
      index: 5,
      title: 'Ordered',
      className: styles.Ordered,
      sortMethod: 'originalQuantityPriced',
      columnDataFunction: (product) => product.originalQuantityPriced,
      // eslint-disable-next-line react/display-name
      getChild: (product) => (
        <div className={parseFloat(product.finalQuantityPriced) === 0 ? styles.Strikethrough : ''}>
          {`${product.originalQuantityPriced} ${product.unitOfPrice}`}
        </div>
      ),
    },
    {
      index: 6,
      className: styles.Supplied,
      title: 'Supplied',
      sortMethod: 'finalQuantityPriced',
      columnDataFunction: (product) => parseFloat(product.finalQuantityPriced),
      // eslint-disable-next-line react/display-name
      getChild: (product) => (
        <React.Fragment>
          {order.orderStatus === PENDING_ORDER ? (
            <React.Fragment>
              <div className={styles.EditableQuantityCell}>
                <EditableTableCell
                  defaultContent={`${product.finalQuantityPriced} ${product.unitOfPrice}`}
                  validationSchema={quantityValidationObject}
                  fieldName={'qtyProvided'}
                  type={EDITABLE_TABLE_CELL_TYPES.TEXT}
                  initialValue={parseFloat(product.finalQuantityPriced)}
                  textOptions={{
                    submitFunction: (values, actions, handleResetTableStyling) =>
                      handleQuantityChanged(values, actions, handleResetTableStyling, product),
                    fieldProps: {
                      type: 'text',
                    },
                  }}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>{`${product.finalQuantityPriced} ${product.unitOfPrice}`}</React.Fragment>
          )}
        </React.Fragment>
      ),
    },
    {
      index: 7,
      title: 'Unit Price',
      sortMethod: 'priceIncGST',
      columnDataFunction: (product) => parseInt(product.priceIncGST, 10),
      getChild: (product) => createPriceField(product),
    },
    {
      index: 8,
      title: 'GST',
      className: styles.Value,
      columnDataFunction: (product) => product.finalTotalIncGST,
      // eslint-disable-next-line react/display-name
      getChild: (product) => (
        <React.Fragment>
          <div className={styles.PriceContainer}>
            <div className={totalBeenChanged(product) ? styles.UpdatedItemRed : ''}>
              {presentCurrency(product.gst !== 0 ? (product.gst * product.finalQuantityPriced) / 100 : 0)}
            </div>
          </div>
        </React.Fragment>
      ),
    },
    {
      index: 9,
      title: 'Total Price',
      sortMethod: 'finalTotalIncGST',
      className: styles.Value,
      columnDataFunction: (product) => product.finalTotalIncGST,
      // eslint-disable-next-line react/display-name
      getChild: (product) => (
        <React.Fragment>
          <div className={styles.PriceContainer}>
            <div className={totalBeenChanged(product) ? styles.UpdatedItemRed : ''}>
              {presentCurrency(product.finalTotalIncGST / 100)}
            </div>
            <div className={styles.PricePercentageChange}>{getProductPercentageChangeAsText(product)}</div>
          </div>
        </React.Fragment>
      ),
    },
    {
      index: 10,
      title: '',
      className: styles.ActionButton,
      columnDataFunction: () => {},
      getChild: (product) => (
        <>
          {product.finalQuantityPriced > 0 && order.orderStatus === ORDER_STATUSES.PENDING ? (
            <div className={styles.ActionButtonContainer}>
              <Button
                className={styles.ActionButton}
                variant={BUTTON_VARIANTS.SECONDARY}
                onClick={(e) => {
                  handleOpenActionMenu(e, product);
                }}
                size={BUTTON_SIZES.SMALL}
              >
                <span className={styles.ChevronButton}>Actions</span>
                <ArrowDropDownIcon />
              </Button>
            </div>
          ) : null}
        </>
      ),
    },
  ];

  const DocumentDownloadMenuItem = ({ onClick, disabled, loading, title }) => (
    <MenuItem
      classes={{ root: styles.MenuLink }}
      onClick={() => closeDocumentsDropdownAnd(onClick)}
      disabled={disabled || loading}
    >
      <>
        {loading ? (
          <CircularProgress className={styles.icon} size={20} thickness={3} />
        ) : (
          <GetAppIcon className={styles.downloadIcon} />
        )}
        {title}
      </>
    </MenuItem>
  );
  DocumentDownloadMenuItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    title: PropTypes.string.isRequired,
  };

  useEffect(() => {
    if (getOrderPercentageChange() > 100 && order.orderStatus === ORDER_STATUSES.PENDING) {
      if (isStaff) {
        createNotification({
          type: NOTIFICATION_TYPES.WARNING,
          content: 'Heads up! Order total is over 100%',
          timeout: 3000,
          closable: true,
        });
      } else {
        setWarningDialogOpen(true);
        setProcessOrderButtonDisabled(true);
      }
    } else {
      setProcessOrderButtonDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const refetchOrderData = async () => {
    setLoadingOrder(true);
    await getOrder();
    setUpdatedProducts([]);
    setLoadingOrder(false);
  };

  return (
    <div className={styles.PageContainer}>
      <PageHeader>
        <div className={styles.LayoutContainer}>
          <div className={styles.TitleContainer}>
            <Breadcrumbs
              currentPageTitle={`#${match.params.id}`}
              previousLinks={[
                {
                  previousPageTitle: (
                    <>
                      <Emoji content="📋" label="clipboard" />
                      &nbsp; Orders
                    </>
                  ),
                  previousPageLink: `/orders${window.location?.search || ''}`,
                },
              ]}
            />
            {order.supplierReference ? (
              <Typography type={TYPOGRAPHY_TYPES.HEADING_XL} className={styles.NegativeMargin}>
                (Supplier order ID:&nbsp;{order.supplierReference})
              </Typography>
            ) : null}
          </div>
          <div className={styles.ActionBar}>
            <div className={styles.ActionBar__buttonsContainer}>
              <Button
                onClick={handleSupplierOrderIdClicked}
                className={styles.ActionBar__button}
                variant={BUTTON_VARIANTS.PRIMARY}
              >
                {order.supplierReference ? 'Edit order ID' : 'Add order ID'}
              </Button>
              <Button
                className={styles.ActionBar__button}
                variant={BUTTON_VARIANTS.SECONDARY}
                onClick={handleDocumentsDropdownClicked}
              >
                <GetAppOutlinedIcon className={styles.ActionButton__icon} />
                Documents
                <ArrowDropDownIcon className={styles.DropdownIcon} />
              </Button>

              <Menu
                open={Boolean(documentsDropDownAnchorElem)}
                anchorEl={documentsDropDownAnchorElem}
                onClose={() => setDocumentsDropDownAnchorElem(undefined)}
              >
                <DocumentDownloadMenuItem
                  onClick={downloadDeliveryDetails}
                  title="Delivery Details"
                  loading={downloadingDeliveryDetails}
                />
                <DocumentDownloadMenuItem
                  onClick={downloadDeliverySlip}
                  title="Delivery Slip"
                  disabled={!DELIVERY_DOCKET_VALID_STATUSES.includes(order.orderStatus)}
                  loading={downloadingDeliverySlip}
                />
                <DocumentDownloadMenuItem
                  onClick={downloadPurchaseOrder}
                  title="Purchase Order"
                  loading={downloadingPurchaseOrder}
                />
                <DocumentDownloadMenuItem
                  onClick={downloadInvoice}
                  title="Invoice"
                  disabled={order.orderStatus !== COMPLETED_ORDER}
                  loading={downloadingInvoice}
                />
              </Menu>
            </div>
          </div>
        </div>
      </PageHeader>
      <div className={styles.Order__content}>
        {loadingOrder ? (
          <LargeLoadingSpinner />
        ) : (
          <React.Fragment>
            {order?.orderId ? (
              <React.Fragment>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Paper className={styles.OrderWidgetPaperWrapper}>
                      <div className={styles.Order__detailsHeaderRow}>
                        <div className={styles.Order__detailsHeaderRowLeft}>
                          <Typography className={styles.Order__orderNumberHeader} type={TYPOGRAPHY_TYPES.HEADING_XL}>
                            Order #{order.orderId}
                          </Typography>
                          <div>
                            <OrderStatusChip apiOrderStatus={order.orderStatus} />
                            {order.customerAlert ? <CustomerAlertChip customerAlert={order.customerAlert} /> : null}
                          </div>
                          <div className={styles.OrderType}>{displayOrderType()}</div>
                        </div>
                      </div>
                      <div className={styles.DeliveryDetailsContainer}>
                        <div className={[styles.DeliveryDetailsWrapper, styles.DeliverTo].join(' ')}>
                          <div className={styles.DeliverTo}>
                            <Typography type={TYPOGRAPHY_TYPES.HEADING_M}>Deliver to</Typography>
                            <Typography className={styles.OrderDetails__row} type={TYPOGRAPHY_TYPES.BODY_BOLD}>
                              <span className={styles.VenueName}>{order.shippingCompany}</span>
                            </Typography>
                            <Typography className={styles.OrderDetails__row} type={TYPOGRAPHY_TYPES.BODY}>
                              {order.shippingAddress}
                            </Typography>
                            <Typography className={styles.OrderDetails__row} type={TYPOGRAPHY_TYPES.BODY}>
                              {order.shippingCity}&nbsp;{order.state}&nbsp;{order.shippingPostcode}
                            </Typography>
                            <Typography className={styles.OrderDetails__row} type={TYPOGRAPHY_TYPES.BODY_BOLD}>
                              Delivery Contact:
                            </Typography>
                            <Typography className={styles.OrderDetails__row} type={TYPOGRAPHY_TYPES.BODY}>
                              {order.shippingFirstname}&nbsp;{order.shippingLastname} - {order.phone}
                            </Typography>
                          </div>
                        </div>
                        <div className={[styles.DeliveryDetailsWrapper, styles.DeliveryDetails].join(' ')}>
                          <div className={styles.DeliveryDetailsSummary}>
                            <Typography type={TYPOGRAPHY_TYPES.HEADING_M}>Delivery details</Typography>
                            <div className={styles.DeliveryDetails__row}>
                              <Typography className={styles.DeliveryDetails__title} type={TYPOGRAPHY_TYPES.BODY}>
                                Order date:
                              </Typography>
                              <Typography className={styles.DeliveryDetails__value} type={TYPOGRAPHY_TYPES.BODY_BOLD}>
                                {Moment(order.dateAdded).format('ddd D MMM YYYY')}
                              </Typography>
                            </div>
                            <div className={styles.DeliveryDetails__row}>
                              <Typography className={styles.DeliveryDetails__title} type={TYPOGRAPHY_TYPES.BODY}>
                                Delivery date:
                              </Typography>
                              <Typography className={styles.DeliveryDetails__value} type={TYPOGRAPHY_TYPES.BODY_BOLD}>
                                {isToday(order.deliveryDate) ? (
                                  <span className={styles.BoldText}>
                                    <Emoji content="⏰" label="clock" />
                                    &nbsp;Today ({Moment(order.deliveryDate).format('D MMM YYYY')})
                                  </span>
                                ) : null}
                                {isTomorrow(order.deliveryDate) ? (
                                  <span className={styles.BoldText}>
                                    &nbsp;Tomorrow ({Moment(order.deliveryDate).format('D MMM YYYY')})
                                  </span>
                                ) : null}
                                {!isToday(order.deliveryDate) && !isTomorrow(order.deliveryDate)
                                  ? Moment(order.deliveryDate).format('ddd D MMM YYYY')
                                  : null}
                              </Typography>
                            </div>
                            <div className={styles.DeliveryDetails__row}>
                              <Typography className={styles.DeliveryDetails__title} type={TYPOGRAPHY_TYPES.BODY}>
                                Payment:
                              </Typography>
                              <Typography className={styles.DeliveryDetails__value} type={TYPOGRAPHY_TYPES.BODY_BOLD}>
                                {getPaymentMethod(order.payment)}
                              </Typography>
                            </div>

                            <div className={styles.DeliveryDetails__row}>
                              <Typography className={styles.DeliveryDetails__title} type={TYPOGRAPHY_TYPES.BODY}>
                                Venue email:
                              </Typography>
                              <Typography className={styles.DeliveryDetails__value} type={TYPOGRAPHY_TYPES.BODY_BOLD}>
                                {order.email}
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <div className={styles.DeliveryDetailsWrapper}>
                          {order?.liquorLicence ? (
                            <StatusChip
                              title={`Liquor Licence: ${order.liquorLicence}`}
                              type={STATUS_CHIP_TYPES.DEFAULT}
                              hideIcon
                              className={styles.LiquorStatusChip}
                            />
                          ) : null}
                          <div>
                            <Typography type={TYPOGRAPHY_TYPES.HEADING_M}>Order comments</Typography>
                            <Typography type={TYPOGRAPHY_TYPES.BODY}>
                              {order.deliveryComment ? order.deliveryComment : 'No comments on this order.'}
                            </Typography>
                            &nbsp;
                            {order?.orderStatus === ORDER_STATUSES.CANCELLED ? (
                              <React.Fragment>
                                <Typography type={TYPOGRAPHY_TYPES.HEADING_M}>Cancellation Notes</Typography>
                                <div className={styles.Order__cancellationNotes}>
                                  <div className={styles.OrderWidgetPaperWrapper}>
                                    {order?.cancellationComment || 'No cancellation notes supplied'}
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : null}
                          </div>
                          {supplier?.substitutionPreferenceEnabled ? (
                            <StatusChip
                              hideIcon
                              title={
                                <>
                                  {order?.substitutionAllowed ? (
                                    <CheckCircleOutlinedIcon className={styles.substitutionIcon} />
                                  ) : (
                                    <CancelOutlinedIcon className={styles.substitutionIcon} />
                                  )}
                                  &nbsp;
                                  {order?.substitutionAllowed ? (
                                    <span>&nbsp;Substitutions Allowed</span>
                                  ) : (
                                    <span>&nbsp;Substitutions Not Allowed</span>
                                  )}
                                </>
                              }
                              className={
                                order?.substitutionAllowed
                                  ? [styles.SubstitutionChip, styles.Allowed].join(' ')
                                  : [styles.SubstitutionChip, styles.NotAllowed].join(' ')
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                      {order?.customerAlert === CUSTOMER_ALERT_TYPES.UPDATED_DETAILS ? (
                        <NotificationMessageSection
                          type={NOTIFICATION_MESSAGE_TYPES.WARNING}
                          className={styles.WarningNotificationMessageSection}
                        >
                          <Typography
                            type={TYPOGRAPHY_TYPES.HEADING_M}
                            className={styles.WarningNotificationMessageSection__title}
                          >
                            Customer delivery details have been updated!
                          </Typography>
                          <Button
                            onClick={downloadDeliveryDetails}
                            className={styles.UpdatedDeliveryDetailsBtn}
                            variant={BUTTON_VARIANTS.SECONDARY}
                            size={BUTTON_SIZES.EXTRA_SMALL}
                          >
                            {downloadingDeliveryDetails ? (
                              <>
                                <CircularProgress size={18} />
                                &nbsp;Downloading...
                              </>
                            ) : (
                              <>
                                <GetAppOutlinedIcon />
                                &nbsp;Download Updated Delivery Details
                              </>
                            )}
                          </Button>
                        </NotificationMessageSection>
                      ) : null}
                    </Paper>
                  </Grid>
                </Grid>
                {order.orderStatus === ORDER_STATUSES.COMPLETED ? (
                  <Paper className={styles.CompletedOrderPanel}>
                    <div className={styles.OriginalOrderTotal}>
                      <Typography className={styles.OriginalOrder} type={TYPOGRAPHY_TYPES.BODY}>
                        Original order value
                      </Typography>
                      <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD}>
                        {presentCurrency(order.originalTotalIncGST / 100)}
                      </Typography>
                    </div>
                    <div className={styles.SuppliedValueContainer}>
                      <div className={styles.SuppliedValue}>
                        <Typography className={styles.SuppliedOrderLabel} type={TYPOGRAPHY_TYPES.HEADING_M}>
                          Supplied order value
                        </Typography>
                        <Typography className={styles.SuppliedOrderValue} type={TYPOGRAPHY_TYPES.HEADING_M}>
                          {presentCurrency(order.finalTotalIncGST / 100)}
                        </Typography>
                        {order.finalTotalIncGST !== order.originalTotalIncGST ? (
                          <Typography className={styles.PercentageChange} type={TYPOGRAPHY_TYPES.BODY}>
                            {`(${getOrderPercentageChange() > 0 ? '+' : ''}${getOrderPercentageChange()}%)`}
                          </Typography>
                        ) : null}
                      </div>
                    </div>
                    <StatusChip className={styles.ProcessedStatusChip} type="success" title="Processed" />
                  </Paper>
                ) : null}
                <div className={styles.ProductDetailsContainer}>
                  <Table
                    className={styles.ProductsTable}
                    columnSpecs={productsColumnSpecs}
                    rowData={order.orderProducts}
                    loadingRowData={loadingOrder}
                    grow
                    numberOfRowsPerPage={150}
                    noResultsComponent={
                      <Paper className={styles.NoResultsContainer}>
                        <div className={styles.TypographySection}>
                          <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
                            <span role="img" aria-label="wave">
                              👋
                            </span>
                          </Typography>
                          <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Welcome to the order page</Typography>
                          <div className={styles.TypographySection}>
                            <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                              Here is where we&apos;ll display all of your order products.
                            </Typography>
                          </div>
                        </div>
                      </Paper>
                    }
                  />
                </div>
                {productToRemove ? (
                  <ProductRemovalDialog
                    isOpen={Boolean(productToRemove)}
                    handleClose={handleRemoveProductDialogClose}
                    orderProduct={productToRemove}
                    handleQuantityChanged={handleQuantityChanged}
                  />
                ) : null}
                <AddSupplierOrderIdDialog
                  isOpen={supplierOrderIdModalOpen}
                  handleClose={() => setSupplierOrderIdModalOpen(false)}
                  orderId={order.orderId}
                  handleSupplierOrderIdAdded={handleSupplierOrderIdAdded}
                  supplierReferenceId={order.supplierReference}
                />
                <Menu
                  anchorEl={actionMenu}
                  className={styles.ActionMenu}
                  open={Boolean(actionMenu)}
                  onClose={handleCloseActionMenu}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                  <div className={styles.Dropdown__content}>
                    {dropDownActions
                      .filter((a) => a.visible)
                      .map((action) => (
                        <div key={action.actionName}>
                          <FormControlLabel
                            onClick={() => {
                              action.onClick();
                            }}
                            control={<></>}
                            label={action.actionName}
                            classes={{
                              root: styles.RadioLabel__root,
                              label: styles.FormControlLabel__label,
                            }}
                          />
                        </div>
                      ))}
                  </div>
                </Menu>
                {order.orderStatus === ORDER_STATUSES.PENDING ? (
                  <React.Fragment>
                    <div className={styles.AddSubstitutionContainer}>
                      <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={openAddCustomSubstitutionDialog}>
                        Add Custom Product
                      </Button>
                    </div>

                    <Paper className={styles.ProcessOrderPanel}>
                      <div className={styles.OriginalOrderTotal}>
                        <Typography className={styles.OriginalOrder} type={TYPOGRAPHY_TYPES.BODY}>
                          Original order value
                        </Typography>
                        <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD}>
                          {presentCurrency(order.originalTotalIncGST / 100)}
                        </Typography>
                      </div>
                      <div className={styles.SuppliedValueContainer}>
                        <div className={styles.SuppliedValue}>
                          <Typography className={styles.SuppliedOrderLabel} type={TYPOGRAPHY_TYPES.HEADING_M}>
                            Supplied order value
                          </Typography>
                          <Typography className={styles.SuppliedOrderValue} type={TYPOGRAPHY_TYPES.HEADING_M}>
                            {presentCurrency(order.finalTotalIncGST / 100)}
                          </Typography>
                          {order.originalTotalIncGST !== order.finalTotalIncGST ? (
                            <Typography className={styles.PercentageChange} type={TYPOGRAPHY_TYPES.BODY}>
                              {`(${getOrderPercentageChange() > 0 ? '+' : ''}${getOrderPercentageChange()}%)`}
                            </Typography>
                          ) : null}
                        </div>
                      </div>
                      {confirmationOrderDialogOpen ? (
                        <ConfirmationOrderDialog
                          isOpen={confirmationOrderDialogOpen}
                          handleClose={() => setConfirmationOrderDialogOpen(false)}
                          finalTotal={presentCurrency(order.finalTotalIncGST / 100)}
                          isProcessingOrder={processingOrder}
                          handleOrderProcess={processOrder}
                        />
                      ) : null}
                      <Tooltip
                        title={
                          <div className={styles.Tooltip}>
                            <div>
                              {processOrderButtonDisabled
                                ? 'You are trying to increase the order total above 100%. If you need to do this please contact support at support@foodbomb.com.au'
                                : 'Confirm this Order'}
                            </div>
                          </div>
                        }
                        placement="top"
                      >
                        <Button
                          className={styles.ProcessOrderButton}
                          variant={BUTTON_VARIANTS.PRIMARY}
                          onClick={() => {
                            if (zeroProducts) {
                              triggerCancelOrderDialog();
                            } else {
                              setConfirmationOrderDialogOpen(true);
                            }
                          }}
                          disabled={processOrderButtonDisabled || processingOrder}
                        >
                          <React.Fragment>
                            {processingOrder ? (
                              <CircularProgress className={styles.icon} size={20} thickness={3} />
                            ) : null}
                          </React.Fragment>
                          Process Order
                        </Button>
                      </Tooltip>
                    </Paper>
                    <React.Fragment>
                      <div className={styles.CancelOrderContainer}>
                        <Button
                          className={styles.ProcessOrderButton}
                          variant={BUTTON_VARIANTS.DANGER}
                          onClick={() => setCancelOrderDialogOpen(true)}
                        >
                          Cancel Order
                        </Button>
                      </div>
                      <CancelOrderDialog
                        isOpen={cancelOrderDialogOpen}
                        handleClose={() => {
                          if (zeroProducts) {
                            refetchOrderData();
                          }
                          setCancelOrderDialogOpen(false);
                        }}
                        onSuccessfullyCancelledOrder={onSuccessfullyCancelledOrder}
                        orderId={order.orderId}
                      />
                    </React.Fragment>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            ) : (
              <div className={styles.ErrorWrapper}>{APIError}</div>
            )}
          </React.Fragment>
        )}
      </div>
      <AddCustomSubstitutionDialog
        isOpen={customSubstitutionDialogOpen}
        handleClose={closeAddCustomSubstitutionDialog}
        onSubstitutionAdded={onSubstitutionAdded}
        createNotification={createNotification}
        sendDatadogError={sendDatadogError}
      />
      <AlertDialog
        variant={ALERT_TYPES.WARNING}
        emoji="😱"
        alertTitle="Order total increase too high"
        alertMessage="You are trying to increase the order total above 100%. If you need to do this please contact support at support@foodbomb.com.au"
        handleClose={() => setWarningDialogOpen(false)}
        handleConfirm={() => setWarningDialogOpen(false)}
        hideButtons={true}
        isOpen={warningDialogOpen}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isStaff: state.auth.supplierDetails ? state.auth.supplierDetails.isStaff : false,
});

Order.propTypes = {
  isStaff: PropTypes.bool.isRequired,
  createNotification: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  redirectToOrders: PropTypes.func.isRequired,
  redirectToProduct: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  trackDownloadPDF: PropTypes.func.isRequired,
};

export default withEventTracking(
  withErrorReports(withRedirectHelper(withNotifications(withRouter(connect(mapStateToProps, null)(Order))))),
);
