import { CircularProgress, Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik, FormikHelpers } from 'formik';
import PropTypes from 'prop-types';
import { FC } from 'react';
import * as Yup from 'yup';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import { CreateNotification, SendErrorReportType } from '../../../utils/Presenters/ReduxType';
import { Button, FormikFormField, Typography } from '../../UI/FB';
import styles from './OrderThresholdModal.module.scss';

type OrderThresholdModalProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  minimumAmount?: number;
  sendDatadogError: SendErrorReportType;
  createNotification: CreateNotification;
};

type FormValuesTypes = {
  lossProductsMinimumSpend: string;
};

const OrderThresholdModal: FC<OrderThresholdModalProps> = ({
  open,
  onClose,
  onSuccess,
  minimumAmount,
  sendDatadogError,
  createNotification,
}) => {
  const validationSchema = Yup.object().shape({
    lossProductsMinimumSpend: Yup.string().trim().required('Price is required').min(0),
  });

  const handleSubmit = (values: FormValuesTypes, actions: FormikHelpers<FormValuesTypes>) => {
    FoodbombAPI.patch('/suppliers/loss-products-minimum-spend', {
      lossProductsMinimumSpend: values.lossProductsMinimumSpend,
    })
      .then(() => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully updated threshold minimum amount.',
          timeout: 4000,
          closable: true,
        });
        onSuccess();
        onClose();
      })
      .catch((error) => {
        if (error.response.status === 400 && error.response.data && error.response.data.errors) {
          const errorMessages: { [key: string]: string } = {};
          error.response.data.errors.forEach((e: any) => {
            if (e.code === 'invalid_parameter') {
              errorMessages[e.param] = e.message;
              actions.setErrors(errorMessages);
            }
          });
        }
        sendDatadogError('Unable to update threshold minimum amount.', {
          error,
          location: 'Order threshold modal',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Unable to update threshold minimum amount.',
          timeout: 4000,
          closable: true,
        });
      })
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ root: styles.OrderThresholdDialogRoot, paper: styles.OrderThresholdDialogPaper }}
    >
      <div className={styles.OrderThresholdDialogHeader}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Edit order threshold</Typography>
        <IconButton
          className={styles.OrderThresholdDialog__closeBtn}
          aria-label="close"
          onClick={onClose}
          type="button"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ lossProductsMinimumSpend: minimumAmount?.toString() || '' }}
        validationSchema={validationSchema}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className={styles.OrderThresholdDialogContent}>
              <Typography type={TYPOGRAPHY_TYPES.BODY}>
                Once customers have met this threshold on their order, your selected products will become available to
                purchase.
              </Typography>

              <FormikFormField
                fieldName="lossProductsMinimumSpend"
                touched={touched}
                errors={errors}
                label="Order Price Threshold"
                placeholder="Eg. $50"
                className={styles.OrderThresholdField}
              />
            </div>
            <div className={styles.OrderThresholdDialogAction}>
              <Button
                type="submit"
                className={styles.OrderThresholdModalButton}
                disabled={Boolean(errors && Object.keys(errors).length) || isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                  </>
                ) : (
                  <>Save</>
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

OrderThresholdModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  minimumAmount: PropTypes.number.isRequired,
};

export default OrderThresholdModal;
