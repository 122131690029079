import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import LoopIcon from '@material-ui/icons/Loop';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
  ErrorNotification,
  FormikFormField,
  AlertDialog,
  Button,
  Typography,
  Paper,
  ToggleButton,
} from '../../../components/UI/FB';
import DeliveryDaysTable from '../DeliveryZoneDetailComponents/DeliveryDaysTable/DeliveryDaysTable';
import DeliveryZoneSuburbCards from '../DeliveryZoneDetailComponents/DeliveryZoneSuburbCards/DeliveryZoneSuburbCards';
import EditDeliveryDetailsModal from '../DeliveryZoneDetailComponents/EditDeliveryDetailsModal/EditDeliveryDetailsModal';
import EditDeliveryDaysModal from '../DeliveryZoneDetailComponents/EditDeliveryDaysModal/EditDeliveryDaysModal';
import EditDeliverySuburbsModal from '../DeliveryZoneDetailComponents/EditDeliverySuburbsModal/EditDeliverySuburbsModal';
import DeliveryZoneDetailLoadingAnimation from '../DeliveryZoneDetailComponents/DeliveryZoneDetailLoadingAnimation/DeliveryZoneDetailLoadingAnimation';
import { convertCentsToPrettyDollar } from '../../../utils/Presenters/PresentCurrency/PresentCurrency';
import {
  ALERT_TYPES,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
  NOTIFICATION_TYPES,
  TYPOGRAPHY_TYPES,
  STATE_TO_TIMEZONE,
} from '../../../utils/Constants';
import { presentTimeZone, PresentHourOfDay } from '../../../utils/Presenters/TimePresenters';
import { DeliveryPreferencesAPI } from '../../../utils/AxiosInstances';
import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import withNotifications from '../../../hoc/withNotifications/withNotifications';

import styles from './DeliveryZoneDetailView.module.scss';

const DeliveryZoneDetailView = ({
  initialDeliveryZoneData,
  sendDatadogError,
  createNotification,
  handleZoneUpdated,
  handleZoneDeleted,
  handleOpenDuplicateZoneDialog,
  displayShowMobileMenuBtn,
  onOpenMobileMenu,
}) => {
  const [loadingZone, setLoadingZone] = useState(true);
  const [deliveryZone, setDeliveryZone] = useState({});
  const [apiError, setApiError] = useState(undefined);
  const [deleteDeliveryZoneAlertOpen, setDeleteDeliveryZoneAlertOpen] = useState(false);
  const [loadingPublished, setLoadingPublished] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [editingName, setEditingName] = useState(false);

  const [editDetailsModalOpen, setEditDetailsModalOpen] = useState(false);
  const [editDaysModalOpen, setEditDaysModalOpen] = useState(false);
  const [editSuburbsModalOpen, setEditSuburbsModalOpen] = useState(false);

  const formInputRef = useRef();

  const handleExitZoneNameMode = () => {
    setEditingName(false);
    // eslint-disable-next-line no-use-before-define
    document.removeEventListener('keydown', handleESCListener, false);
  };

  const handleEnterEditZoneNameMode = () => {
    setEditingName(true);
    // eslint-disable-next-line no-use-before-define
    document.addEventListener('keydown', handleESCListener, false);
    setTimeout(() => {
      formInputRef.current.focus();
    }, 100);
  };

  const openDeleteZoneAlert = () => setDeleteDeliveryZoneAlertOpen(true);
  const closeDeleteZoneAlert = () => setDeleteDeliveryZoneAlertOpen(false);

  const handleESCListener = (e) => {
    // ESC for exiting edit mode
    if (e.keyCode === 27) {
      handleExitZoneNameMode();
    }
  };

  const cancelSources = useRef([]);

  const buildNewCancelSource = () => DeliveryPreferencesAPI.CancelToken.source();

  const generateCancelSourceAndReturnToken = useCallback(() => {
    const cancelSource = buildNewCancelSource();
    cancelSources.current.push(cancelSource);
    return { cancelToken: cancelSource.token };
  }, []);

  const cancelExistingRequests = () => {
    cancelSources.current.forEach((cancelSource) => {
      cancelSource.cancel('Operation cancelled as the user navigated away from the page');
    });
    cancelSources.current = [];
  };

  const fetchZoneSuburbs = useCallback(
    (zoneId) =>
      DeliveryPreferencesAPI.get(`zones/${zoneId}/suburbs`, generateCancelSourceAndReturnToken()).catch((error) => {
        sendDatadogError('Unable to load delivery zone suburbs', {
          error,
          location: 'Delivery Zone Detail View',
        });
        throw error;
      }),
    [sendDatadogError, generateCancelSourceAndReturnToken],
  );

  const fetchZoneDetails = useCallback(
    (zoneId) =>
      DeliveryPreferencesAPI.get(`/zones/${zoneId}`, generateCancelSourceAndReturnToken()).catch((error) => {
        sendDatadogError('Unable to load delivery zone', {
          error,
          location: 'Delivery Zone Detail View',
        });
        throw error;
      }),
    [sendDatadogError, generateCancelSourceAndReturnToken],
  );

  const fetchZoneDays = useCallback(
    (zoneId) =>
      DeliveryPreferencesAPI.get(`/zones/${zoneId}/days`, generateCancelSourceAndReturnToken()).catch((error) => {
        sendDatadogError('Unable to load delivery zone days', {
          error,
          location: 'Delivery Zone Detail View',
        });
        throw error;
      }),
    [sendDatadogError, generateCancelSourceAndReturnToken],
  );

  const fetchDeliveryZoneData = useCallback(async () => {
    setLoadingZone(true);
    setDeliveryZone({});

    try {
      const [zoneDetails, zoneSuburbs, zoneDays] = await Promise.all([
        fetchZoneDetails(initialDeliveryZoneData?.id),
        fetchZoneSuburbs(initialDeliveryZoneData?.id),
        fetchZoneDays(initialDeliveryZoneData?.id),
      ]);
      const zoneData = { ...zoneDetails.data };
      zoneData.deliveryDays = zoneDays.data.map((day) => ({
        ...day,
        enabled: Boolean(day.newCustomerOrderBy && day.existingCustomerOrderBy),
      }));
      handleZoneUpdated(zoneData);
      zoneData.suburbs = zoneSuburbs.data;
      setDeliveryZone(zoneData);
    } catch (error) {
      // We have error handling in each of the above promises, but adding this line in attempt to isolate a production issue for AZTEC supplier
      sendDatadogError('Something went wrong loading the delivery zone', {
        error,
        location: 'Delivery Zone Detail view',
      });
      setApiError(
        <ErrorNotification
          className={styles.APIError}
          body={
            <span>
              We&apos;re unable to load this delivery zone. Don&apos;t worry, our engineers have been notified!
            </span>
          }
        />,
      );
    }
    setLoadingZone(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDeliveryZoneData?.id]);

  useEffect(() => {
    cancelExistingRequests();
    fetchDeliveryZoneData();
  }, [fetchDeliveryZoneData]);

  useEffect(() => {
    setEditingName(false);
  }, [initialDeliveryZoneData.id]);

  const deleteDeliveryZone = () => {
    setLoadingDelete(true);
    DeliveryPreferencesAPI.delete(`zones/${deliveryZone.id}`)
      .then(() => {
        handleZoneDeleted(deliveryZone.id);
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Deleted ${deliveryZone.name}`,
          timeout: 4000,
          closable: true,
        });
      })
      .catch((error) => {
        sendDatadogError(`Unable to delete delivery zone ${deliveryZone.id}`, {
          error,
          location: 'Delivery Zones Detail View',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: `Unable to delete delivery zone`,
          timeout: 4000,
          closable: true,
        });
      })
      .finally(() => {
        setLoadingDelete(false);
      });
  };

  const canActivateZone = () =>
    !(
      loadingPublished ||
      loadingZone ||
      !deliveryZone?.deliveryDays?.filter((day) => day.existingCustomerOrderBy).length ||
      !deliveryZone?.suburbs?.filter((sub) => sub.enabled).length
    );

  const handleTogglePublishedStatus = () => {
    setLoadingPublished(true);
    const currentPublishedStatus = deliveryZone.published;
    DeliveryPreferencesAPI.patch(`zones/${deliveryZone.id}`, {
      published: !currentPublishedStatus,
    })
      .then((response) => {
        const updatedZone = { ...deliveryZone, published: response.data.published };
        setDeliveryZone(updatedZone);
        handleZoneUpdated(updatedZone);
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Successfully ${currentPublishedStatus ? 'deactivated' : 'activated'} ${deliveryZone.name}`,
          timeout: 4000,
          closable: true,
        });
      })
      .catch((error) => {
        if (error?.response?.status === 400 && error?.response?.data) {
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: Object.values(error.response.data)[0],
            closable: true,
          });
        }
        sendDatadogError(`Unable to ${currentPublishedStatus ? 'deactivate' : 'activate'} ${deliveryZone.name}`, {
          error,
          location: 'Delivery Zones Detail view',
        });
      })
      .finally(() => {
        setLoadingPublished(false);
      });
  };

  const handleZoneNameChange = (values, actions) => {
    DeliveryPreferencesAPI.patch(`zones/${deliveryZone.id}`, {
      name: values.name,
    })
      .then((response) => {
        setDeliveryZone((currentZone) => ({ ...currentZone, name: response.data.name }));
        handleZoneUpdated(response.data);
        handleExitZoneNameMode();
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully updated zone name',
          timeout: 4000,
          closable: true,
        });
      })
      .catch((error) => {
        if (error?.response?.status === 400 && error?.response?.data) {
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: Object.values(error.response.data)[0],
            closable: true,
          });
        } else {
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to update zone name',
            timeout: 4000,
            closable: true,
          });
        }
        sendDatadogError(`Unable to update delivery zone name`, {
          error,
          location: 'Delivery Zones Detail view',
          zone: deliveryZone,
        });
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <div className={styles.DeliveryZones__content}>
      <div className={styles.DeliveryZones__PageHeaderContainer}>
        <div className={styles.DeliveryZones__PageHeader}>
          {editingName ? (
            <Formik
              initialValues={{
                name: deliveryZone?.name,
              }}
              validationSchema={Yup.object({
                name: Yup.string().required('Zone name is required'),
              })}
              onSubmit={handleZoneNameChange}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form className={styles.NameForm}>
                  <FormikFormField
                    fieldName="name"
                    touched={touched}
                    errors={errors}
                    placeholder="Enter zone name"
                    hideErrors
                    fieldProps={{
                      className: errors?.name
                        ? [styles.NameChangeField, styles.errored].join(' ')
                        : styles.NameChangeField,
                      innerRef: formInputRef,
                    }}
                  />
                  <div className={styles.NameFormButtons}>
                    {isSubmitting ? (
                      <IconButton className={styles.NameFormButton} disabled>
                        <LoopIcon className={[styles.NameFormButton__icon, styles.spin].join(' ')} />
                      </IconButton>
                    ) : (
                      <React.Fragment>
                        <IconButton type="submit" className={[styles.NameFormButton, styles.submit].join(' ')}>
                          <CheckCircleOutlinedIcon className={styles.NameFormButton__icon} />
                        </IconButton>
                        <IconButton
                          type="button"
                          onClick={handleExitZoneNameMode}
                          className={[styles.NameFormButton, styles.cancel].join(' ')}
                        >
                          <HighlightOffOutlinedIcon className={styles.NameFormButton__icon} />
                        </IconButton>
                      </React.Fragment>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div className={styles.DeliveryZoneNameContainer}>
              <Typography type={TYPOGRAPHY_TYPES.HEADING_XXL} className={styles.SelectedDeliveryZone__Name}>
                {loadingZone ? (
                  <React.Fragment>{initialDeliveryZoneData?.name}</React.Fragment>
                ) : (
                  <React.Fragment>{deliveryZone?.name}</React.Fragment>
                )}
              </Typography>
              <IconButton
                onClick={handleEnterEditZoneNameMode}
                className={styles.EditZoneNameBtn}
                disabled={loadingZone}
              >
                <EditOutlinedIcon />
              </IconButton>
            </div>
          )}
          <div className={styles.ActionBar}>
            {displayShowMobileMenuBtn ? (
              <div className={styles.MobileMenuBtnContainer}>
                <Button variant={BUTTON_VARIANTS.PRIMARY} onClick={onOpenMobileMenu}>
                  <ArrowForwardIosIcon />
                  &nbsp;All Zones
                </Button>
              </div>
            ) : null}
            {loadingZone ? null : (
              <React.Fragment>
                <Button
                  variant={BUTTON_VARIANTS.DANGER}
                  onClick={openDeleteZoneAlert}
                  disabled={loadingDelete || loadingZone}
                >
                  {loadingDelete ? (
                    <CircularProgress thickness={3} size={24} className={styles.ButtonLoadingSpinner} />
                  ) : (
                    <React.Fragment>Delete</React.Fragment>
                  )}
                </Button>
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  onClick={handleOpenDuplicateZoneDialog}
                  disabled={loadingZone}
                >
                  <FileCopyOutlinedIcon />
                  &nbsp;Duplicate
                </Button>
                <ToggleButton
                  isActive={Boolean(deliveryZone?.published)}
                  onClick={() => handleTogglePublishedStatus(deliveryZone)}
                  disabled={!canActivateZone()}
                >
                  Activate Zone
                </ToggleButton>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <div className={styles.DeliveryZones__SectionContainer}>
        {loadingZone ? (
          <DeliveryZoneDetailLoadingAnimation />
        ) : (
          <React.Fragment>
            {apiError ? (
              <div className={styles.ErrorContainer}>{apiError}</div>
            ) : (
              <React.Fragment>
                <div className={styles.DeliveryZones__SectionColumn}>
                  <Paper className={styles.DetailsPaper}>
                    <div className={styles.DetailsPaper__header}>
                      <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Delivery Details</Typography>
                      <Button
                        variant={BUTTON_VARIANTS.SECONDARY}
                        size={BUTTON_SIZES.SMALL}
                        className={styles.EditButton}
                        onClick={() => setEditDetailsModalOpen(true)}
                      >
                        Edit Details
                      </Button>
                    </div>
                    <div className={styles.DetailsPaper__content}>
                      <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.GreyStandard}>
                        {PresentHourOfDay(deliveryZone?.cutoff)} ({presentTimeZone(deliveryZone.timeZone)} time)
                        <span>&nbsp;|&nbsp;</span>
                        {convertCentsToPrettyDollar(deliveryZone?.minimumOrderTotal)}
                      </Typography>
                    </div>
                  </Paper>
                  <Paper className={styles.DetailsPaper}>
                    <div className={styles.DetailsPaper__header}>
                      <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Delivery Days</Typography>
                      <Button
                        variant={BUTTON_VARIANTS.SECONDARY}
                        size={BUTTON_SIZES.SMALL}
                        className={styles.EditButton}
                        onClick={() => setEditDaysModalOpen(true)}
                      >
                        Edit Days
                      </Button>
                    </div>
                    <div className={styles.DetailsPaper__content}>
                      <DeliveryDaysTable deliveryDays={deliveryZone?.deliveryDays} />
                    </div>
                  </Paper>
                </div>
                <div className={styles.DeliveryZones__SectionColumn}>
                  <Paper className={styles.DetailsPaper}>
                    <div className={styles.DetailsPaper__header}>
                      <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Suburbs</Typography>
                      <Button
                        variant={BUTTON_VARIANTS.SECONDARY}
                        size={BUTTON_SIZES.SMALL}
                        className={styles.EditButton}
                        onClick={() => setEditSuburbsModalOpen(true)}
                      >
                        Edit Suburbs
                      </Button>
                    </div>
                    <div className={styles.DetailsPaper__content}>
                      <DeliveryZoneSuburbCards suburbs={deliveryZone?.suburbs} />
                    </div>
                  </Paper>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
      <AlertDialog
        isOpen={deleteDeliveryZoneAlertOpen}
        variant={ALERT_TYPES.WARNING}
        alertTitle={`Are you sure you want to delete ${deliveryZone?.name}?`}
        handleConfirm={() => deleteDeliveryZone(deliveryZone)}
        handleClose={closeDeleteZoneAlert}
      />
      {Object.keys(deliveryZone).length ? (
        <EditDeliveryDetailsModal
          isOpen={editDetailsModalOpen}
          handleClose={() => setEditDetailsModalOpen(false)}
          deliveryZone={deliveryZone}
          refetchZoneData={fetchDeliveryZoneData}
        />
      ) : null}
      {Object.keys(deliveryZone).length ? (
        <EditDeliveryDaysModal
          isOpen={editDaysModalOpen}
          handleClose={() => setEditDaysModalOpen(false)}
          deliveryZone={deliveryZone}
          refetchZoneData={fetchDeliveryZoneData}
        />
      ) : null}
      {Object.keys(deliveryZone).length ? (
        <EditDeliverySuburbsModal
          isOpen={editSuburbsModalOpen}
          handleClose={() => setEditSuburbsModalOpen(false)}
          deliveryZone={deliveryZone}
          refetchZoneData={fetchDeliveryZoneData}
        />
      ) : null}
    </div>
  );
};

DeliveryZoneDetailView.propTypes = {
  initialDeliveryZoneData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    published: PropTypes.bool,
    deliveryDays: PropTypes.array.isRequired,
    minimumOrderTotal: PropTypes.number.isRequired,
    suburbCount: PropTypes.number.isRequired,
    cutoff: PropTypes.string.isRequired,
    timeZone: PropTypes.oneOf(Object.values(STATE_TO_TIMEZONE)).isRequired,
  }).isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  handleZoneUpdated: PropTypes.func.isRequired,
  handleZoneDeleted: PropTypes.func.isRequired,
  handleOpenDuplicateZoneDialog: PropTypes.func.isRequired,
  displayShowMobileMenuBtn: PropTypes.bool.isRequired,
  onOpenMobileMenu: PropTypes.func.isRequired,
};

export default withNotifications(withErrorReports(DeliveryZoneDetailView));
