import React from 'react';
import PropTypes from 'prop-types';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import AddIcon from '@material-ui/icons/Add';

import { round2DP } from '../../../utils/PortionSizePricingHelper/PortionSizePricingHelper';
import { presentCurrency } from '../../../utils/Presenters/PresentCurrency/PresentCurrency';
import SpecialsIcon from '../../UI/SpecialsIcon/SpecialsIcon';
import { Tooltip, Emoji } from '../../UI/FB';
import styles from './WTFCell.module.scss';

const WTFCell = ({ product, venues, handleOpenCustomPriceDialog }) => {
  const customPrices = product.customPricing;

  const emojis = [];
  let showGSTMessage = false;
  let showSpecialsMessage = false;
  let customPriceMessages;

  if (!product.gstExempt) {
    emojis.push('💸');
    showGSTMessage = true;
  }
  if (product.specials && product.specials.length) {
    emojis.push(<SpecialsIcon asEmoji key={`product_${product.id}_special`} />);
    showSpecialsMessage = true;
  }

  if (product.customPricing && product.customPricing.length) {
    emojis.push('⚡️');
    customPriceMessages = product.customPricing.slice(0, 3).map((cp) => (
      <div key={`customPrice_${cp.venue.id}_${cp.id}`}>
        {cp.venue.venue} &#8594;{' '}
        <React.Fragment>
          {product.gstExempt ? (
            <strong>
              {presentCurrency(cp.priceInCents / 100)}/{product.unitOfPrice.unit}
            </strong>
          ) : (
            <strong>
              {presentCurrency(round2DP(cp.priceInCents / 100 - cp.priceInCents / 100 / 11))}/{product.unitOfPrice.unit}{' '}
            </strong>
          )}
        </React.Fragment>
        &nbsp;(Ex. GST)
      </div>
    ));
    if (product.customPricing.length > 3) {
      customPriceMessages.push(
        <div key={`${product.id}_more`}>
          and <strong>{product.customPricing.length - 3}</strong> more
        </div>,
      );
    }
  }

  return (
    <div className={styles.WTFCell}>
      <div className={styles.OnlyOnRowHover}>
        <Tooltip
          placement="top"
          title={
            <div className={styles.WTFTooltip}>
              {showGSTMessage ? (
                <div>
                  <Emoji content="💸" label="money" />
                  &nbsp; This product has GST
                  <br />
                </div>
              ) : null}

              {showSpecialsMessage ? (
                <div>
                  <SpecialsIcon asEmoji />
                  &nbsp; This product is currently on special
                </div>
              ) : null}

              <div className={styles.TextWithTopMargin}>
                <Emoji content="⚡️" label="bolt" />
                &nbsp; Custom Pricing
                {customPriceMessages}
                {customPrices && customPrices.length ? (
                  <div className={styles.TextWithTopMargin}>Click here to edit custom prices</div>
                ) : (
                  <div className={styles.TextWithTopMargin}>Click here to add custom prices</div>
                )}
              </div>

              <span className={styles.Arrow} />
            </div>
          }
        >
          <button
            onClick={venues.length ? () => handleOpenCustomPriceDialog(product) : null}
            className={venues.length ? styles.EditBtn : styles.InvisibleBtn}
          >
            <div className={styles.EditBtn__content}>
              <div className={styles.WTFCellContent}>{emojis}</div>
            </div>
            {customPrices.length ? (
              <CreateOutlinedIcon className={styles.EditBtn__editIcon} />
            ) : (
              <AddIcon className={styles.EditBtn__addIcon} />
            )}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

WTFCell.propTypes = {
  product: PropTypes.object.isRequired,
  handleOpenCustomPriceDialog: PropTypes.func.isRequired,
  venues: PropTypes.array.isRequired,
};

export default WTFCell;
