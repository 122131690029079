import React from 'react';
import PropTypes from 'prop-types';

import { TYPOGRAPHY_TYPES } from '../../../../utils/Constants';
import { Typography, Emoji } from '../../../../components/UI/FB';
import OnStatusChip from '../../../../components/UI/OnStatusChip/OnStatusChip';
import withRedirectHelper from '../../../../hoc/withRedirectHelper/withRedirectHelper';
import styles from './DeliveryZoneSuburbCards.module.scss';

const DeliveryZoneSuburbCards = ({
  suburbs,
  redirectToDeliveryZonesBySuburbWithSuburbQuery,
  redirectToDeliveryZonesBySuburbWithPostcodeQuery,
}) => {
  if (!suburbs.length) {
    return (
      <div className={styles.NoResultsContainer}>
        <Emoji content="🤔" label="thinking" />
        <Typography type={TYPOGRAPHY_TYPES.BODY_L} className={styles.NoResultsHeading}>
          This zone has no suburbs. Add some to get started!
        </Typography>
      </div>
    );
  }

  const suburbsByPostcodes = {};
  suburbs
    .map((sub) => ({
      enabled: sub.enabled,
      blocked: sub.blocked,
      name: sub.suburb.name,
      postcode: sub.suburb.postcode,
      state: sub.suburb.state,
    }))
    .forEach((suburb) => {
      suburbsByPostcodes[suburb.postcode] = suburbsByPostcodes[suburb.postcode]
        ? suburbsByPostcodes[suburb.postcode].concat([suburb])
        : [suburb];
    });

  return (
    <div className={styles.PostcodeSectionCardsContainer}>
      {Object.entries(suburbsByPostcodes).map(([postcode, suburbObjs]) => (
        <div className={styles.PostcodeSectionCard} key={postcode}>
          <button
            className={styles.InvisibleButton}
            onClick={() => redirectToDeliveryZonesBySuburbWithPostcodeQuery(postcode)}
          >
            <Typography type={TYPOGRAPHY_TYPES.HEADING_M} className={styles.PostcodeSectionCard__postcode}>
              {postcode}
            </Typography>
          </button>
          {suburbObjs.map((suburbObj) => (
            <button
              className={[styles.PostcodeSectionCard__suburb, styles.InvisibleButton].join(' ')}
              key={suburbObj?.name}
              onClick={() => redirectToDeliveryZonesBySuburbWithSuburbQuery(suburbObj.name)}
            >
              <OnStatusChip onStatus={suburbObj?.enabled} className={styles.SuburbStatusChip} />
              <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD}>{suburbObj?.name}</Typography>
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

DeliveryZoneSuburbCards.propTypes = {
  suburbs: PropTypes.arrayOf(
    PropTypes.shape({
      enabled: PropTypes.bool,
      blocked: PropTypes.bool,
      suburb: PropTypes.shape({
        name: PropTypes.string.isRequired,
        postcode: PropTypes.number.isRequired,
        state: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  redirectToDeliveryZonesBySuburbWithSuburbQuery: PropTypes.func.isRequired,
  redirectToDeliveryZonesBySuburbWithPostcodeQuery: PropTypes.func.isRequired,
};

export default withRedirectHelper(DeliveryZoneSuburbCards);
