import { devWarn } from '../DevUtils';
import { ENVIRONMENT } from '../Constants';

const isProduction = () => process.env.NODE_ENV === ENVIRONMENT.PRODUCTION;

/**
 * Sends errors to Raygun
 * @function sendError
 * @param {Error}   error The actual error object
 * @param {String}  errorMessage The title of the error
 * @param {Object}  data  Additional data to be sent to raygun to help identify the error including the errorObject itself
 * @param {Array}   tags  Optional tags to send to raygun to assist in grouping or filtering errors
 * @return {void}
 *
 *
 * Sends an error message to raygun in production mode
 * Logs a warning to the console in dev mode
 * Appends user data and API version headers if present
 *
 *
 */
export const sendError = (error, errorMessage, data = {}, tags = []) => {
  if (!(error instanceof Error)) {
    const message = `Expected error to be of type Error, got ${typeof error}`;
    if (isProduction()) {
      sendError(new TypeError(message), 'Attempting to log invalid error');
    } else {
      throw new TypeError(message);
    }
  }

  const errorAsString = JSON.stringify(error, Object.getOwnPropertyNames(error));
  const clientVersionLabel = process.env.REACT_APP_VERSION_LABEL;

  devWarn(`${errorMessage} ⛔️`, {
    error: {
      kind: 'Raygun Error',
      origin: 'custom',
      stack: errorAsString,
      message: error.message,
    },
    ...data,
    clientVersion: clientVersionLabel,
    tags,
  });
};

export const trackPageView = (pathName) => {
  if (isProduction()) {
    window.rg4js('trackEvent', { type: 'pageView', path: pathName });
  }
};
