import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FilterList from '@material-ui/icons/FilterList';
import { Formik, Form } from 'formik';
import { MenuItem } from '@material-ui/core';
import debounce from 'es6-promise-debounce';

import DeliveryDaysStatus from '../DeliveryZoneDetailComponents/DeliveryDaysStatus/DeliveryDaysStatus';
import OnStatusChip from '../../../components/UI/OnStatusChip/OnStatusChip';
import { DeliveryPreferencesAPI } from '../../../utils/AxiosInstances';
import withQueryParams from '../../../hoc/withQueryParams/withQueryParams';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import { convertCentsToPrettyDollar } from '../../../utils/Presenters/PresentCurrency/PresentCurrency';
import { PresentHourOfDay } from '../../../utils/Presenters/TimePresenters';

import {
  STATUS_CHIP_TYPES,
  TYPOGRAPHY_TYPES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  NOTIFICATION_TYPES,
} from '../../../utils/Constants';
import { StatusChip, Table, Paper, Typography, Button, Menu, FormikSingleSelectField } from '../../../components/UI/FB';
import styles from './DeliveryZonesBySuburbView.module.scss';

const STATUS_FILTERS = [
  {
    id: 0,
    title: 'All Statuses',
  },
  {
    id: 1,
    title: 'Only On',
    queryString: 'on',
    field: 'enabled',
    value: true,
  },
  {
    id: 2,
    title: 'Only Off',
    queryString: 'off',
    field: 'enabled',
    value: false,
  },
];

const DeliveryZonesBySuburbView = ({
  getDeliveryZoneStatusFromQueryString,
  updateDeliveryZoneStatusQueryString,
  sendDatadogError,
  createNotification,
  getQueryFromQueryString,
  updateSearchQueryParam,
  handleShowDeliveryZone,
  displayShowMobileMenuBtn,
  onOpenMobileMenu,
}) => {
  const [loadingPostcodesAndSuburbs, setLoadingPostcodesAndSuburbs] = useState(false);
  const [isSearchingAValidQuery, setIsSearchingAValidQuery] = useState(false);
  const [loadingZoneSuburbs, setLoadingZoneSuburbs] = useState(false);
  const [zoneSuburbs, setZoneSuburbs] = useState([]);
  const initialQueryValue = useRef(getQueryFromQueryString());

  const [isQuerying, setIsQuerying] = useState(false);

  // Dropdown Anchor Elements
  const [statusFilterAnchorElem, setStatusFilterAnchorElem] = useState(undefined);

  //  Dropdown handlers
  const handleStatusFilterClicked = (e) => setStatusFilterAnchorElem(e.currentTarget);
  const handleCloseStatusFilterMenu = () => setStatusFilterAnchorElem(undefined);

  const getCurrentlySelectedStatusFilter = () =>
    STATUS_FILTERS.find((filter) => filter.queryString === getDeliveryZoneStatusFromQueryString());

  const hasValidFilter = () => Boolean(getCurrentlySelectedStatusFilter());

  const getTitleOfCurrentlySelectedStatusFilter = () =>
    STATUS_FILTERS.find((filter) => filter.queryString === getDeliveryZoneStatusFromQueryString())?.title ||
    STATUS_FILTERS.find((filter) => filter.queryString === undefined).title;

  const handleSelectStatusFilter = (filter) => {
    updateDeliveryZoneStatusQueryString(filter.queryString);
    handleCloseStatusFilterMenu();
  };

  const getDeliveryZoneSuburbs = useCallback(
    (query) => {
      setLoadingZoneSuburbs(true);
      DeliveryPreferencesAPI.get(`/zone-suburbs${query}`)
        .then((response) => {
          setZoneSuburbs(
            response.data.map((res) => ({ ...res, key: `Zone: ${res.deliveryZone.id} - ${res.suburb.name}` })),
          );
        })
        .catch((error) => {
          sendDatadogError('Failed to load zone suburbs', {
            error,
            location: 'Delivery Zones by Suburb',
          });
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Failed to load your delivery zones. Please try again.',
            timeout: 4000,
            closable: true,
          });
        })
        .finally(() => {
          setLoadingZoneSuburbs(false);
        });
    },
    [createNotification, sendDatadogError],
  );

  useEffect(() => {
    if (initialQueryValue?.current) {
      getDeliveryZoneSuburbs(`${initialQueryValue?.current}`);
    }
  }, [initialQueryValue, getDeliveryZoneSuburbs]);

  const handleSuburbPostcodeChange = (selectedValue) => {
    if (selectedValue && Object.keys(selectedValue)?.length) {
      const queryString = selectedValue?.isPostcode
        ? `?postcode=${selectedValue.postcode}`
        : `?suburb=${selectedValue.name}`;
      updateSearchQueryParam(queryString);
      getDeliveryZoneSuburbs(queryString);
    }
  };

  const searchPostcodesAndSuburbs = (query) => {
    const isPostcodeSearch = /^\d+$/.test(query);
    setIsSearchingAValidQuery(query?.length);

    setLoadingPostcodesAndSuburbs(true);
    return DeliveryPreferencesAPI.get(`/suburbs?search=${encodeURI(query)}`)
      .then((response) => {
        const resultsByPostcode = {};
        const results = response.data.map((res) => ({
          ...res,
          label: `${res.name} (${res.postcode}, ${res.state})`,
          value: `${res.name} (${res.postcode}, ${res.state})`,
          isPostcode: false,
        }));
        if (isPostcodeSearch) {
          results.forEach((res) => {
            resultsByPostcode[res.postcode] = { ...resultsByPostcode?.[res.postcode] } || {};
            resultsByPostcode[res.postcode].suburbs = resultsByPostcode[res.postcode]?.suburbs?.length
              ? resultsByPostcode[res.postcode]?.suburbs.concat(res.name)
              : [res.name];
            resultsByPostcode[res.postcode].postcode = res.postcode;
          });
        }
        const postcodeResultsToAppend = Object.values(resultsByPostcode)
          .filter((result) => result.suburbs.length > 1)
          .map((res) => ({
            ...res,
            label: (
              <React.Fragment>
                <strong>{res.postcode}</strong>&nbsp;({res.suburbs.join(', ')})
              </React.Fragment>
            ),
            value: `${res.postcode} (${res.suburbs.join(', ')})`,
            isPostcode: true,
          }));
        return postcodeResultsToAppend.concat(results);
      })
      .catch((error) => {
        sendDatadogError('Failed to search suburbs and postcodes', {
          query,
          error,
          location: 'Delivery Zones by Suburb',
        });
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Failed to search suburbs and postcodes. Please try again.',
          timeout: 4000,
          closable: true,
        });
      })
      .finally(() => {
        setLoadingPostcodesAndSuburbs(false);
      });
  };

  const zoneSuburbsColumnSpecs = [
    {
      index: 0,
      title: 'Status',
      columnDataFunction: (zoneSuburb) => zoneSuburb.enabled,
      // eslint-disable-next-line react/display-name
      getChild: (zoneSuburb) => <OnStatusChip onStatus={zoneSuburb.enabled} className={styles.ZoneSuburbStatusChip} />,
      className: styles.Table__status,
    },
    {
      index: 1,
      title: 'Postcode',
      columnDataFunction: (zoneSuburb) => zoneSuburb.suburb.postcode,
      className: styles.Table__postcode,
    },
    {
      index: 2,
      title: 'Suburb',
      columnDataFunction: (zoneSuburb) => zoneSuburb.suburb.name,
      className: styles.Table__suburb,
    },
    {
      index: 3,
      title: 'Delivery Zone',
      columnDataFunction: (zoneSuburb) => zoneSuburb.deliveryZone.name,
      className: styles.Table__zoneName,
    },
    {
      index: 4,
      title: 'Zone Status',
      columnDataFunction: (zoneSuburb) => zoneSuburb.deliveryZone.published,
      // eslint-disable-next-line react/display-name
      getChild: (zoneSuburb) => (
        <StatusChip
          hideIcon
          type={zoneSuburb.deliveryZone.published ? STATUS_CHIP_TYPES.SUCCESS : STATUS_CHIP_TYPES.DEFAULT}
          title={zoneSuburb.deliveryZone.published ? 'Published' : 'Unpublished'}
          className={styles.ZoneStatusChip}
        />
      ),
      className: styles.Table__published,
    },
    {
      index: 5,
      title: 'Days',
      columnDataFunction: (zoneSuburb) => zoneSuburb.deliveryZone.deliveryDays,
      // eslint-disable-next-line react/display-name
      getChild: (zoneSuburb) => (
        <DeliveryDaysStatus
          activeDays={zoneSuburb.deliveryZone.deliveryDays.map((day) => day.deliveryDay)}
          greyscale={!zoneSuburb.deliveryZone.published}
        />
      ),
      className: styles.Table__days,
    },
    {
      index: 6,
      title: 'Minimum',
      columnDataFunction: (zoneSuburb) => zoneSuburb.deliveryZone.minimumOrderTotal,
      // eslint-disable-next-line react/display-name
      getChild: (zoneSuburb) => convertCentsToPrettyDollar(zoneSuburb.deliveryZone.minimumOrderTotal),
      className: styles.Table__minimum,
    },
    {
      index: 7,
      title: 'Cut Off',
      columnDataFunction: (zoneSuburb) => zoneSuburb.deliveryZone.cutoff,
      // eslint-disable-next-line react/display-name
      getChild: (zoneSuburb) => PresentHourOfDay(zoneSuburb.deliveryZone.cutoff),
      className: styles.Table__cutoff,
    },
  ];

  const filterZoneSuburbsAgainstStatusFilter = () => {
    const currentFilter = getCurrentlySelectedStatusFilter();
    if (!currentFilter) {
      return zoneSuburbs;
    }
    return zoneSuburbs.filter((res) => res[currentFilter.field] === currentFilter.value);
  };

  return (
    <div className={styles.BySuburbs__container}>
      <div className={styles.BySuburbs__headerContainer}>
        <div className={styles.BySuburbs__header}>
          <div className={styles.HeaderActionsContainer}>
            <Formik
              initialValues={{
                suburb: initialQueryValue?.current ? { label: initialQueryValue?.current?.split('=')?.[1] || '' } : '',
              }}
            >
              {({ errors, touched, values, setFieldValue, setTouched }) => (
                <Form className={styles.SuburbSearchForm}>
                  <FormikSingleSelectField
                    fieldName={'suburb'}
                    isMulti={false}
                    isAsync
                    errors={errors}
                    touched={touched}
                    setTouched={setTouched}
                    value={values.suburb}
                    setFieldValue={setFieldValue}
                    customOnChange={(selectedValue) => {
                      setTouched({ ...touched, suburb: true });
                      setFieldValue('suburb', selectedValue);
                      handleSuburbPostcodeChange(selectedValue);
                      setIsQuerying(Boolean(selectedValue));
                    }}
                    cacheOptions
                    isClearable
                    loadOptions={debounce(searchPostcodesAndSuburbs, 250)}
                    placeholder={loadingPostcodesAndSuburbs ? 'Searching ...' : 'Search for a suburb or postcode'}
                    noOptionsMessage={() =>
                      isSearchingAValidQuery ? 'No suburbs or postcodes found' : 'Enter suburb or postcode to search'
                    }
                    loadingMessage={() => 'Searching...'}
                    maxMenuHeight={250}
                    hideErrors
                  />
                </Form>
              )}
            </Formik>
            <Button
              variant={hasValidFilter() ? BUTTON_VARIANTS.SUCCESS : BUTTON_VARIANTS.SECONDARY}
              className={styles.FilterMenuBtn}
              onClick={handleStatusFilterClicked}
            >
              <FilterList className={styles.ActionButton__icon} />
              {getTitleOfCurrentlySelectedStatusFilter()}
              <ArrowDropDownIcon className={styles.DropdownIcon} />
            </Button>
          </div>
          <Menu
            open={Boolean(statusFilterAnchorElem)}
            anchorEl={statusFilterAnchorElem}
            onClose={handleCloseStatusFilterMenu}
          >
            {STATUS_FILTERS.map((filter) => (
              <MenuItem
                key={filter.title}
                onClick={() => handleSelectStatusFilter(filter)}
                classes={{ root: styles.MenuLink }}
              >
                {filter.title}
              </MenuItem>
            ))}
          </Menu>

          {displayShowMobileMenuBtn ? (
            <Button variant={BUTTON_VARIANTS.PRIMARY} onClick={onOpenMobileMenu} className={styles.MobileMenuBtn}>
              <ArrowForwardIosIcon />
              &nbsp;All Zones
            </Button>
          ) : null}
        </div>
      </div>
      <div className={styles.TableContainer}>
        <Table
          className={styles.ZoneSuburbsTable}
          columnSpecs={zoneSuburbsColumnSpecs}
          rowData={filterZoneSuburbsAgainstStatusFilter()}
          loadingRowData={loadingZoneSuburbs}
          rowActions={(zoneSuburb) => (
            <React.Fragment>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTON_SIZES.EXTRA_SMALL}
                onClick={() => handleShowDeliveryZone(zoneSuburb.deliveryZone)}
                className={styles.RowActionBtn}
              >
                View Zone
              </Button>
            </React.Fragment>
          )}
          rowOverlayColSpan={4}
          grow
          noResultsComponent={
            <Paper className={styles.NoResultsContainer}>
              {isQuerying ? (
                <div className={styles.TypographySection}>
                  <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
                    <span role="img" aria-label="truck">
                      🚚
                    </span>
                  </Typography>
                  <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>No Delivery Zones Found</Typography>
                  <div className={styles.TypographySection}>
                    <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                      It appears you don&apos;t have any delivery zones that match this search.
                    </Typography>
                  </div>
                  {hasValidFilter() ? (
                    <div className={styles.TypographySection}>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                        Try removing your filter to see all results.
                      </Typography>
                      <Button
                        onClick={() => updateDeliveryZoneStatusQueryString(undefined)}
                        variant={BUTTON_VARIANTS.SECONDARY}
                        className={styles.RemoveFilterBtn}
                        size={BUTTON_SIZES.SMALL}
                      >
                        Remove Filter
                      </Button>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className={styles.TypographySection}>
                  <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
                    <span role="img" aria-label="wave">
                      👋
                    </span>
                  </Typography>
                  <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Welcome to your delivery zones by suburb</Typography>
                  <div className={styles.TypographySection}>
                    <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.TypographyBody}>
                      Enter a suburb or postcode to begin your search
                    </Typography>
                  </div>
                </div>
              )}
            </Paper>
          }
        />
      </div>
    </div>
  );
};

DeliveryZonesBySuburbView.propTypes = {
  getDeliveryZoneStatusFromQueryString: PropTypes.func.isRequired,
  updateDeliveryZoneStatusQueryString: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  getQueryFromQueryString: PropTypes.func.isRequired,
  updateSearchQueryParam: PropTypes.func.isRequired,
  handleShowDeliveryZone: PropTypes.func.isRequired,
  displayShowMobileMenuBtn: PropTypes.bool.isRequired,
  onOpenMobileMenu: PropTypes.func.isRequired,
};

export default withQueryParams(withErrorReports(withNotifications(DeliveryZonesBySuburbView)));
