import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import { Typography } from '../FB';
import { TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import styles from './LargeLoadingSpinner.module.scss';

const LargeLoadingSpinner = ({ loadingMessage, small = false }) => {
  const containerStyles = [styles.LargeLoadingSpinnerContainer];
  const progressStyles = [styles.CircularProgress];
  const textStyles = [styles.LoadingText];

  if (small) {
    containerStyles.push(styles.small);
    progressStyles.push(styles.small);
    textStyles.push(styles.small);
  }

  return (
    <div className={containerStyles.join(' ')}>
      <CircularProgress
        className={progressStyles.join(' ')}
        size={small ? 30 : 60}
        thickness={2}
        classes={{
          svg: styles.Circle,
        }}
      />
      <Typography type={small ? TYPOGRAPHY_TYPES.BODY : TYPOGRAPHY_TYPES.HEADING_L} className={textStyles.join(' ')}>
        {loadingMessage || 'Loading...'}
      </Typography>
    </div>
  );
};

LargeLoadingSpinner.propTypes = {
  loadingMessage: PropTypes.string,
  small: PropTypes.bool,
};

export default LargeLoadingSpinner;
