import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import styles from './CSVProductsTable.module.scss';

const UnitOfMeasureEditCell = ({ tableData, cellTitle, cellField, unitsOfMeasure }) => {
  const [selectedUnit, setSelectedUnit] = useState('');

  const handleSelectedUnit = (e) => {
    setSelectedUnit(e.target.value);
    const { rowData } = tableData;
    const overWrittenValue = { [cellField]: e.target.value };
    tableData.onRowDataChange({ ...rowData, ...overWrittenValue });
  };

  useEffect(() => {
    setSelectedUnit(tableData.rowData[cellField]);
  }, [tableData, cellField]);

  return (
    <div>
      <Select
        value={selectedUnit}
        displayEmpty
        onChange={handleSelectedUnit}
        classes={
          selectedUnit === ''
            ? { root: styles.CategoryEditCell__selectField__disabled }
            : { root: styles.CategoryEditCell__selectField }
        }
      >
        <MenuItem value={''} disabled className={styles.CategoryEditCell__selectItem}>
          Select a {cellTitle}
        </MenuItem>
        {unitsOfMeasure.map((unit) => (
          <MenuItem key={`unit.id`} value={unit.title} className={styles.CategoryEditCell__selectItem}>
            {unit.title}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default UnitOfMeasureEditCell;

UnitOfMeasureEditCell.propTypes = {
  tableData: PropTypes.object.isRequired,
  cellTitle: PropTypes.string.isRequired,
  cellField: PropTypes.string.isRequired,
  unitsOfMeasure: PropTypes.array.isRequired,
};
