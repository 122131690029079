import { useState, FC, ElementType } from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Formik, Form, FormikHelpers } from 'formik';
import { CircularProgress, Switch } from '@material-ui/core';
import { Paper, Typography, Button } from '../../../components/UI/FB';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES, BUTTON_VARIANTS } from '../../../utils/Constants';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import { NotificationConfig, SendErrorReportType } from '../../../utils/Presenters/ReduxType';
import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import { ContactTypes } from '../../../utils/Presenters/Common';
import styles from '../Settings.module.scss';

type SubstitutionCardTypes = {
  pulse: boolean;
  innerRef: () => void | ElementType;
  sendDatadogError: SendErrorReportType;
  updateSupplierDetails: (data: ContactTypes) => void;
  createNotification: (notification: NotificationConfig) => void;
  substitutionPreference: boolean;
};

type FormValuesType = {
  substitutionPreferenceEnabled: boolean;
};

const SubstitutionCard: FC<SubstitutionCardTypes> = ({
  pulse,
  innerRef,
  createNotification,
  sendDatadogError,
  updateSupplierDetails,
  substitutionPreference,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const triggerEditMode = (): void => setEditMode(true);

  const handleUpdateSubsPreference = (values: FormValuesType, actions: FormikHelpers<FormValuesType>) => {
    const payload = {
      substitutionPreferenceEnabled: values.substitutionPreferenceEnabled,
    };

    FoodbombAPI.patch('suppliers/auth/me', payload)
      .then((response) => {
        updateSupplierDetails(response.data);
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Successfully ${
            values.substitutionPreferenceEnabled ? 'enabled' : 'disabled'
          } substitution preference`,
          timeout: 4000,
          closable: true,
        });
        actions.setSubmitting(false);
        setEditMode(false);
      })
      .catch((error) => {
        if (error?.response?.status === 400 && error?.response?.data?.errors) {
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to update substitution preference',
            timeout: 4000,
            closable: true,
          });
        } else {
          sendDatadogError('Unable to update substitution preference', {
            error,
            location: 'Settings Page',
          });
          createNotification({
            type: NOTIFICATION_TYPES.ERROR,
            content: 'Unable to update substitution preference',
            timeout: 4000,
            closable: true,
          });
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
        setEditMode(false);
      });
  };

  return (
    <div ref={innerRef}>
      <Paper className={pulse ? [styles.SettingsCard, styles.pulse].join(' ') : styles.SettingsCard}>
        <Formik
          initialValues={{ substitutionPreferenceEnabled: substitutionPreference || false }}
          initialStatus={{
            apiError: undefined,
          }}
          onSubmit={handleUpdateSubsPreference}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <>
              <CheckCircleOutlineIcon
                className={
                  !editMode
                    ? styles.SettingsCard__completeIcon
                    : [styles.SettingsCard__completeIcon, styles.hide].join(' ')
                }
              />
              <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.SettingsCard__sectionHeading}>
                Substitution Preferences
              </Typography>
              {!editMode ? (
                <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__description}>
                  {values.substitutionPreferenceEnabled ? 'Enabled' : 'Disabled'}
                </Typography>
              ) : null}
              {editMode ? (
                <>
                  <div className={styles.SubstitutionHeader__action}>
                    <div className={styles.ToggleButton}>
                      <span>Off</span>
                      <Switch
                        checked={values.substitutionPreferenceEnabled}
                        onChange={() =>
                          setFieldValue('substitutionPreferenceEnabled', !values.substitutionPreferenceEnabled)
                        }
                        size="medium"
                        classes={{
                          root: styles.SwitchRoot,
                          colorPrimary: styles.ColorPrimary,
                          thumb: styles.ColorPrimary,
                          track: values.substitutionPreferenceEnabled ? styles.ActiveColor : styles.PausedColor,
                        }}
                      />
                      <span>On</span>
                    </div>
                    <div className={styles.ToggleInfo}>
                      <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD}>Allow substitution requests</Typography>
                      <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ToggleSubText}>
                        If you switch this setting on, venues will be able to specify whether they do or do not wish to
                        receive substitutions.
                      </Typography>
                    </div>
                  </div>
                  <Form>
                    <div className={styles.ToggleFooterActionContainer}>
                      <Button type="button" variant={BUTTON_VARIANTS.SECONDARY} onClick={() => setEditMode(false)}>
                        Cancel
                      </Button>
                      <Button type="submit">
                        {isSubmitting ? (
                          <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                        ) : (
                          <>Update details</>
                        )}
                      </Button>
                    </div>
                  </Form>
                </>
              ) : (
                <div className={styles.SettingsCard__editBtnContainer}>
                  <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={triggerEditMode} className={styles.EditBtn}>
                    Edit Details
                  </Button>
                </div>
              )}
            </>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

SubstitutionCard.propTypes = {
  substitutionPreference: PropTypes.bool.isRequired,
  updateSupplierDetails: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  pulse: PropTypes.bool.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
};

export default withErrorReports(withNotifications(SubstitutionCard));
