import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { FieldArray, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import withNotifications from '../../hoc/withNotifications/withNotifications';
import withErrorReports from '../../hoc/withErrorReports/withErrorReports';
import { FoodbombAPI } from '../../utils/AxiosInstances';
import {
  API_METHODS,
  BUTTON_VARIANTS,
  NOTIFICATION_MESSAGE_SIZES,
  NOTIFICATION_MESSAGE_TYPES,
  NOTIFICATION_TYPES,
  TYPOGRAPHY_TYPES,
} from '../../utils/Constants';
import { presentCurrency } from '../../utils/Presenters/PresentCurrency/PresentCurrency';
import {
  Button,
  FormikFormField,
  FormikSingleSelectField,
  NotificationMessageSection,
  Tooltip,
  Typography,
} from '../UI/FB';
import styles from './CustomPricesForm.module.scss';

const CustomPricesForm = ({
  product,
  venues,
  createNotification,
  updateProductData,
  sendDatadogError,
  afterSubmitCallback,
}) => {
  const getPriceInCentsExGst = (customPrice) =>
    product.gstExempt ? customPrice.priceInCents : Math.round(customPrice.priceInCents - customPrice.priceInCents / 11);

  const buildInitialValues = () =>
    product.customPricing.map((cp) => ({
      productId: product.id,
      venueId: cp.venue.id,
      priceInDollarsExGST: getPriceInCentsExGst(cp) / 100,
      apiMethod: API_METHODS.PATCH,
    }));

  const addCustomPrice = (customPriceToAdd) =>
    FoodbombAPI.post('custom-pricing', {
      venueId: customPriceToAdd.venueId,
      productId: product.id,
      priceInCents: customPriceToAdd.priceInCentsIncGst,
    })
      .then((response) => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Successfully created custom price for ${
            venues.find((venue) => venue.id === customPriceToAdd.venueId).venue
          }`,
          timeout: 4000,
          closable: true,
        });
        return {
          ...customPriceToAdd,
          priceInCents: customPriceToAdd.priceInCentsIncGst,
          id: response.data.customPriceId,
        };
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          sendDatadogError('Unable to add custom price', {
            error,
            location: 'Products page custom price modal',
          });
        }
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: `Unable to create custom price for ${
            venues.find((venue) => venue.id === customPriceToAdd.venueId).venue
          }`,
          closable: true,
        });
        throw error;
      });

  const deleteCustomPrice = (customPriceToDelete) =>
    FoodbombAPI.delete(`custom-pricing/${customPriceToDelete.id}`)
      .then(() => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Successfully deleted custom price for ${
            venues.find((venue) => venue.id === customPriceToDelete.venueId).venue
          }`,
          timeout: 4000,
          closable: true,
        });
        return { ...customPriceToDelete };
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          sendDatadogError('Unable to add custom price', {
            error,
            location: 'Products page custom price modal',
          });
        }
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: `Unable to delete custom price for ${
            venues.find((venue) => venue.id === customPriceToDelete.venueId).venue
          }`,
          closable: true,
        });
        throw error;
      });

  const updateCustomPrice = (customPrice) =>
    FoodbombAPI.patch('custom-pricing', {
      venueId: customPrice.venueId,
      productId: product.id,
      priceInCents: customPrice.priceInCentsIncGst,
    })
      .then(() => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: `Successfully updated custom price for ${
            venues.find((venue) => venue.id === customPrice.venueId).venue
          }`,
          timeout: 4000,
          closable: true,
        });
        return { ...customPrice, priceInCents: customPrice.priceInCentsIncGst };
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          sendDatadogError('Unable to add custom price', {
            error,
            location: 'Products page custom price modal',
          });
        }
        createNotification({
          type: NOTIFICATION_TYPES.ERROR,
          content: `Unable to update custom price for ${
            venues.find((venue) => venue.id === customPrice.venueId).venue
          }`,
          closable: true,
        });
        throw error;
      });

  const attemptToUpdateCustomPrices = (values, actions) => {
    const customPrices = [...values.customPrices].map((cp, idx) => ({
      ...cp,
      idx,
      priceInCentsIncGst: product.gstExempt
        ? values.customPrices[idx].priceInDollarsExGST * 100
        : Math.round(values.customPrices[idx].priceInDollarsExGST * 1.1 * 100),
    }));
    const customPricesToDelete = customPrices
      .filter((cp) => cp.apiMethod === API_METHODS.DELETE)
      .map((cp) => ({
        ...cp,
        id: product.customPricing.find((existingCustomPrice) => existingCustomPrice.venue.id === cp.venueId).id,
      }));
    const customPricesToAdd = customPrices.filter((cp) => cp.apiMethod === API_METHODS.POST);
    const customPricesToPotentiallyUpdate = customPrices.filter((cp) => cp.apiMethod === API_METHODS.PATCH);
    // Filter out custom prices that haven't actually changed -> do nothing with them
    const customPricesToUpdate = customPricesToPotentiallyUpdate.filter((customPriceToUpdate) => {
      const potentiallyExistingCustomPrice = product.customPricing.find(
        (existingCustomPrice) => existingCustomPrice.venue.id === customPriceToUpdate.venueId,
      );
      if (!potentiallyExistingCustomPrice) {
        return true;
      }
      if (customPriceToUpdate.priceInCentsIncGst !== potentiallyExistingCustomPrice.priceInCents) {
        return true;
      }
      return false;
    });

    const deletePromises = customPricesToDelete.map((cp) => deleteCustomPrice(cp, actions));
    const postPromises = customPricesToAdd.map((cp) => addCustomPrice(cp, actions));
    const patchPromises = customPricesToUpdate.map((cp) => updateCustomPrice(cp, actions));

    const apiPromises = postPromises.concat(patchPromises);

    // Perform all deletes before post to avoid validation errors
    // (A supplier could delete and add CPs again rather than editing existing ones)

    Promise.allSettled(deletePromises).then((deleteResults) => {
      Promise.allSettled(apiPromises).then((results) => {
        let customPricingCopy = [...product.customPricing];

        // Remove Deleted Custom Prices
        const deletedResultsToUpdate = deleteResults
          .filter((res) => res.status === 'fulfilled')
          .map((res) => res.value);
        const deletedVenueIds = deletedResultsToUpdate.map((res) => res.venueId);

        customPricingCopy = customPricingCopy.filter((existingCp) => !deletedVenueIds.includes(existingCp.venue.id));

        // Updated modified custom prices
        const updatedResultsToUpdate = results
          .filter((res) => res.status === 'fulfilled' && res.value.apiMethod === API_METHODS.PATCH)
          .map((res) => res.value);
        updatedResultsToUpdate.forEach((updatedCustomPrice) => {
          const idxToUpdate = customPricingCopy.findIndex(
            (existingCp) => existingCp.venue.id === updatedCustomPrice.venueId,
          );
          customPricingCopy[idxToUpdate].priceInCents = updatedCustomPrice.priceInCents;
        });

        // Add additional custom prices
        const addedResultsToUpdate = results
          .filter((res) => res.status === 'fulfilled' && res.value.apiMethod === API_METHODS.POST)
          .map((res) => res.value)
          .map((newCp) => ({
            venue: {
              id: newCp.venueId,
              venue: venues.find((c) => c.id === newCp.venueId).venue,
            },
            priceInCents: newCp.priceInCents,
            id: newCp.id,
          }));

        const updatedCustomPricing = customPricingCopy.concat(addedResultsToUpdate);

        const updatedProduct = { ...product, customPricing: updatedCustomPricing };

        updateProductData(updatedProduct);

        actions.setSubmitting(false);
        afterSubmitCallback();
      });
    });
  };

  const customPriceValidationSchema = Yup.object().shape({
    customPrices: Yup.array().of(
      Yup.object().shape({
        venueId: Yup.number().integer().required('venueId is required'),
        priceInDollarsExGST: Yup.number()
          .typeError('Custom price must be a number')
          .required('Custom price is required')
          .min(0, 'Custom price cannot be negative')
          .test('decimal-places', 'Custom price must have a maximum of 2 decimal places', (value) =>
            !value ? true : !(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2),
          ),
      }),
    ),
  });

  const emptyCustomPriceObject = () => ({
    productId: product.id,
    venueId: '',
    priceInDollarsExGST: '',
    apiMethod: API_METHODS.POST,
  });

  const editAllCustomPrices = (values, setValues, setTouched, touched) => {
    const { bulkEditPrice, customPrices } = values;

    const updatedCustomPriceArray = customPrices.map((customPrice) => ({
      ...customPrice,
      priceInDollarsExGST: bulkEditPrice,
    }));
    setValues({ bulkEditPrice, customPrices: updatedCustomPriceArray });
    const touchedPrices = customPrices.map(() => ({ priceInDollarsExGST: true }));
    setTouched({
      ...touched,
      customPrices: touchedPrices,
    });
  };

  return (
    <div className={styles.CustomPriceFormContainer}>
      <Formik
        initialValues={{
          bulkEditPrice: product.customPricing?.length
            ? getPriceInCentsExGst(product.customPricing[0]) / 100
            : product.priceInCents,
          customPrices: buildInitialValues(),
        }}
        validationSchema={customPriceValidationSchema}
        onSubmit={attemptToUpdateCustomPrices}
        initialStatus={{
          apiError: undefined,
        }}
      >
        {({ values, errors, touched, isSubmitting, setTouched, setFieldValue, setValues }) => (
          <Form className={styles.CustomPriceForm}>
            <div className={styles.ProductTitle}>
              <Typography type={TYPOGRAPHY_TYPES.BODY}>
                Product: <strong>{product.name}</strong>
              </Typography>
            </div>
            {product.customPricing?.length ? (
              <div className={styles.EditAllPricesSection}>
                <div className={[styles.InputContainer, styles.InputContainer__customer].join(' ')}>
                  <FormikFormField
                    fieldName={'bulkEditPrice'}
                    touched={touched}
                    errors={errors}
                    setTouched={setTouched}
                    label={'Price to apply to all venues'}
                    placeholder={'Enter a Price to apply to all venues'}
                    className={styles.EditAllPrices__Field}
                    fieldProps={{
                      type: 'number',
                      step: '0.01',
                    }}
                  />
                </div>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.SECONDARY}
                  onClick={() => {
                    editAllCustomPrices(values, setValues, setTouched, touched);
                  }}
                  className={styles.ApplyToAllBtn}
                >
                  Apply to All
                </Button>
              </div>
            ) : null}
            <div className={styles.CustomPriceForm__fields}>
              <FieldArray name="customPrices">
                {({ push, remove }) => (
                  <React.Fragment>
                    {values.customPrices &&
                      values.customPrices.length > 0 &&
                      values.customPrices.map((customPrice, idx) => (
                        <React.Fragment key={`customPrice_${idx}`}>
                          {!product.gstExempt &&
                          values.customPrices[idx].venueId &&
                          values.customPrices[idx].priceInDollarsExGST ? (
                            <NotificationMessageSection
                              type={NOTIFICATION_MESSAGE_TYPES.WARNING}
                              size={NOTIFICATION_MESSAGE_SIZES.LARGE}
                            >
                              <Typography type={TYPOGRAPHY_TYPES.BODY}>
                                <strong>Note:</strong>&nbsp;This product has GST, hence the price for{' '}
                                {venues.find((v) => v.id === values.customPrices[idx].venueId).venue} will be&nbsp;
                                <strong>{presentCurrency(values.customPrices[idx].priceInDollarsExGST * 1.1)}</strong>
                              </Typography>
                            </NotificationMessageSection>
                          ) : null}
                          <div
                            className={
                              customPrice.apiMethod === API_METHODS.DELETE
                                ? [styles.CustomPriceForm__row, styles.deleted].join(' ')
                                : styles.CustomPriceForm__row
                            }
                          >
                            <div className={[styles.InputContainer, styles.InputContainer__customer].join(' ')}>
                              <FormikSingleSelectField
                                label="Venue"
                                fieldName={`customPrices.${idx}.venueId`}
                                errors={errors}
                                touched={touched}
                                value={venues.find((venue) => venue.id === values.customPrices[idx].venueId) || ''}
                                options={venues}
                                placeholder="Select a venue"
                                setTouched={setTouched}
                                setFieldValue={setFieldValue}
                                isDisabled={values.customPrices[idx].apiMethod === API_METHODS.PATCH}
                                maxMenuHeight={200}
                              />
                            </div>
                            <div className={[styles.InputContainer, styles.InputContainer__price].join(' ')}>
                              <FormikFormField
                                fieldName={`customPrices.${idx}.priceInDollarsExGST`}
                                touched={touched}
                                errors={errors}
                                label={`Custom price per ${product.unitOfPrice.unit} (Ex. GST)`}
                                placeholder={`Custom price / ${product.unitOfPrice.unit}`}
                                fieldProps={{
                                  type: 'number',
                                  step: '0.01',
                                }}
                              />
                            </div>
                            <div>
                              {values.customPrices[idx].apiMethod !== API_METHODS.DELETE ? (
                                <Tooltip title={'Remove custom price'}>
                                  <Button
                                    className={styles.DeleteCustomPriceBtn}
                                    variant={BUTTON_VARIANTS.DANGER}
                                    onClick={() => {
                                      setTouched({ ...touched, [`customPrices.${idx}`]: true });
                                      if (values.customPrices[idx].apiMethod === API_METHODS.PATCH) {
                                        setFieldValue(`customPrices.${idx}.apiMethod`, API_METHODS.DELETE);
                                      } else {
                                        remove(idx);
                                      }
                                    }}
                                    tabIndex="-1"
                                  >
                                    <DeleteOutlineIcon className={styles.Icon} />
                                  </Button>
                                </Tooltip>
                              ) : null}
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    {values.customPrices.filter((cp) => cp.apiMethod !== API_METHODS.DELETE).length === 0 ? (
                      <NotificationMessageSection
                        type={NOTIFICATION_MESSAGE_TYPES.WARNING}
                        size={NOTIFICATION_MESSAGE_SIZES.LARGE}
                      >
                        <Typography type={TYPOGRAPHY_TYPES.BODY}>
                          Click <strong>&apos;Add&apos;</strong> to create another custom price or click{' '}
                          <strong>&apos;Save&apos;</strong> to apply your changes
                        </Typography>
                      </NotificationMessageSection>
                    ) : null}
                    <div className={styles.CustomPriceForm__addBtnContainer}>
                      <Button
                        disabled={isSubmitting}
                        className={styles.NoMarginBtn}
                        variant={BUTTON_VARIANTS.SECONDARY}
                        onClick={() => push(emptyCustomPriceObject())}
                      >
                        Add another custom price
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </FieldArray>
            </div>
            <div className={styles.CustomPriceForm__submitBtnContainer}>
              <Button type="submit" disabled={isSubmitting} className={styles.NoMarginBtn}>
                Save Custom Prices
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

CustomPricesForm.propTypes = {
  product: PropTypes.object.isRequired,
  venues: PropTypes.array.isRequired,
  createNotification: PropTypes.func.isRequired,
  updateProductData: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  afterSubmitCallback: PropTypes.func.isRequired,
};

export default withErrorReports(withNotifications(CustomPricesForm));
