import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';
import { TYPOGRAPHY_TYPES } from '../../utils/Constants';
import CustomPricesForm from '../CustomPricesForm/CustomPricesForm';
import { Emoji, Typography } from '../UI/FB';
import styles from './CustomPriceDialog.module.scss';

const CustomPriceDialog = ({ isOpen, handleClose, product, venues, updateProductData }) => {
  const venuesWithCustomPricingButNoOrders = [];
  const venuesWithCustomPricing = product?.customPricing?.map((cp) => cp.venue) || [];
  venuesWithCustomPricing.forEach((cpVenue) => {
    if (!venues.some((venue) => venue.id === cpVenue.id)) {
      venuesWithCustomPricingButNoOrders.push({
        ...cpVenue,
        value: cpVenue.id,
        label: `${cpVenue.venue} (${cpVenue.id})`,
      });
    }
  });

  return (
    <Dialog
      open={isOpen}
      keepMounted={false}
      onClose={handleClose}
      aria-labelledby="custom-price-dialog"
      classes={{ root: styles.CustomPriceDialog__root, paper: styles.CustomPriceDialog__paper }}
    >
      <IconButton aria-label="close" onClick={handleClose} className={styles.CloseBtn}>
        <CloseIcon />
      </IconButton>
      <DialogTitle id="wholesaler-dialog" className={styles.CustomPriceDialog__title}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
          <Emoji content="⚡️" label="bolt" />
          &nbsp;Edit Custom Pricing
        </Typography>
      </DialogTitle>
      <DialogContent className={styles.CustomPriceDialog__content}>
        <CustomPricesForm
          venues={venues.concat(venuesWithCustomPricingButNoOrders)}
          product={product}
          updateProductData={updateProductData}
          afterSubmitCallback={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
};

CustomPriceDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  venues: PropTypes.array.isRequired,
  updateProductData: PropTypes.func.isRequired,
};

export default CustomPriceDialog;
