import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper } from '../../UI/FB';
import styles from './NumberedRowCard.module.scss';
import { TYPOGRAPHY_TYPES } from '../../../utils/Constants';

const NumberedRowCard = ({ number, firstField, middleField, lastField }) => (
  <React.Fragment>
    <Paper className={styles.RowCard}>
      <Typography className={styles.RowCardNumber} type={TYPOGRAPHY_TYPES.HEADING_M}>
        #{number}
      </Typography>
      <Typography
        className={
          middleField
            ? [styles.RowCardField, styles.firstField].join(' ')
            : [styles.RowCardField, styles.widerFirstField].join(' ')
        }
        type={TYPOGRAPHY_TYPES.HEADING_M}
      >
        {firstField}
      </Typography>
      <Typography className={styles.RowCardField} type={TYPOGRAPHY_TYPES.HEADING_M}>
        {middleField ? `${middleField} orders` : ''}
      </Typography>
      <Typography className={[styles.RowCardField, styles.lastField].join(' ')} type={TYPOGRAPHY_TYPES.HEADING_M}>
        {lastField}
      </Typography>
    </Paper>
  </React.Fragment>
);

NumberedRowCard.propTypes = {
  number: PropTypes.number.isRequired,
  firstField: PropTypes.string.isRequired,
  middleField: PropTypes.number,
  lastField: PropTypes.string.isRequired,
};
export default NumberedRowCard;
