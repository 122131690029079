import PropTypes from 'prop-types';
import React from 'react';
import { CUSTOMER_ALERT_TYPES, STATUS_CHIP_TYPES, TYPOGRAPHY_TYPES } from '../../utils/Constants';
import { Emoji, StatusChip, Typography } from '../UI/FB/index';
import styles from './CustomerAlertChip.module.scss';

const CustomerAlertChip = ({ customerAlert }) => (
  <StatusChip
    type={customerAlert === CUSTOMER_ALERT_TYPES.UPDATED_DETAILS ? STATUS_CHIP_TYPES.PENDING : STATUS_CHIP_TYPES.INFO}
    title={
      customerAlert === CUSTOMER_ALERT_TYPES.UPDATED_DETAILS ? (
        <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD} className={styles.ChipText}>
          <Emoji content="⚠️" label="warning" className={styles.Emoji} />
          {customerAlert}
          <Emoji content="⚠️" label="warning" className={styles.Emoji} />
        </Typography>
      ) : (
        <>{customerAlert}</>
      )
    }
    hideIcon
  />
);

CustomerAlertChip.propTypes = {
  customerAlert: PropTypes.oneOf(Object.values(CUSTOMER_ALERT_TYPES)).isRequired,
};

export default CustomerAlertChip;
