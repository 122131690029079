import * as actionTypes from '../actions/ActionTypes';
import { updateObject } from '../ReduxUtilities';

const FOODBOMB_API_VERSION = 'foodbombAPIVersion';
const FOODBOMB_PDF_API_VERSION = 'foodbombPDFAPIVersion';
const DELIVERY_PREFERENCES_API_VERSION = 'deliveryPreferencesAPIVersion';

const initialState = {
  [FOODBOMB_API_VERSION]: undefined,
  [FOODBOMB_PDF_API_VERSION]: undefined,
  [DELIVERY_PREFERENCES_API_VERSION]: undefined,
};

// Common Functions
const setExternalAPIVersion = (state, action, key) => updateObject(state, { [key]: action.payload[key] });
const resetExternalAPIVersion = (state, key) => updateObject(state, { [key]: undefined });
// Reducer Helper Functions for APIs
const setFoodbombAPIVersion = (state, action) => setExternalAPIVersion(state, action, FOODBOMB_API_VERSION);

const resetFoodbombAPIVersion = (state) => resetExternalAPIVersion(state, FOODBOMB_API_VERSION);

const setFoodbombPDFAPIVersion = (state, action) => setExternalAPIVersion(state, action, FOODBOMB_PDF_API_VERSION);

const resetFoodbombPDFAPIVersion = (state) => resetExternalAPIVersion(state, FOODBOMB_PDF_API_VERSION);

const setDeliveryPreferencesAPIVersion = (state, action) =>
  setExternalAPIVersion(state, action, DELIVERY_PREFERENCES_API_VERSION);

const resetDeliveryPreferencesAPIVersion = (state) => resetExternalAPIVersion(state, DELIVERY_PREFERENCES_API_VERSION);

// Reducer Function
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FOODBOMB_API_VERSION:
      return setFoodbombAPIVersion(state, action);
    case actionTypes.SET_FOODBOMB_PDF_API_VERSION:
      return setFoodbombPDFAPIVersion(state, action);
    case actionTypes.SET_DELIVERY_PREFERENCES_API_VERSION:
      return setDeliveryPreferencesAPIVersion(state, action);
    case actionTypes.RESET_FOODBOMB_API_VERSION:
      return resetFoodbombAPIVersion(state, action);
    case actionTypes.RESET_FOODBOMB_PDF_API_VERSION:
      return resetFoodbombPDFAPIVersion(state, action);
    case actionTypes.RESET_DELIVERY_PREFERENCES_API_VERSION:
      return resetDeliveryPreferencesAPIVersion(state, action);
    default:
      return state;
  }
};

export default reducer;
