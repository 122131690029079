import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, IconButton, DialogActions, CircularProgress } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { initialiseFuseInstance, search } from '../../utils/ImageSearchHelpers/SearchImageList';
import styles from './ProductImageSelectorModal.module.scss';
import { Typography, Button, SearchBar, NotificationMessageSection } from '../../components/UI/FB';
import {
  NOTIFICATION_MESSAGE_TYPES,
  NOTIFICATION_MESSAGE_SIZES,
  TYPOGRAPHY_TYPES,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from '../../utils/Constants';

const ProductImageSelectorModal = ({
  onClose,
  onSave,
  open,
  selectedProduct,
  imageGallery,
  updatingImageInProgress,
  updatingImageError,
  productImageSearchQuery,
  setProductImageSearchQuery,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchQuery, setSearchQuery] = useState(productImageSearchQuery || '');
  const [filteredImages, setFilteredImages] = useState([]);
  const [shouldFocusSearchBar, setShouldFocusSearchBar] = useState(true);
  const fuseInstance = useRef();

  const handleSelectImage = (img) => {
    setSelectedImage(img);
  };

  const handleOnSave = () => {
    onSave(selectedProduct, selectedImage);
    setSelectedImage(null);
  };

  const handleOnCancel = () => {
    setSelectedImage(null);
    onClose();
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  const fakeRefocusInputField = () => {
    setShouldFocusSearchBar(false);
    setTimeout(() => {
      setShouldFocusSearchBar(true);
    }, 200);
  };

  const onHandleSearch = () => {
    setProductImageSearchQuery(searchQuery);
    fakeRefocusInputField();
  };

  const getPrefilName = (productName, wordCount) =>
    productName
      ? productName
          .replace('-', '')
          .split(' ')
          .filter((sp) => sp)
          .slice(0, wordCount)
          .join(' ')
      : '';

  const searchForPrefilledProductName = (wordsToPrefill) => {
    setSearchQuery(getPrefilName(selectedProduct.name, wordsToPrefill));
    setProductImageSearchQuery(getPrefilName(selectedProduct.name, wordsToPrefill));
  };

  // Selected product has changed and modal has reloaded
  useEffect(() => {
    // Initialise fuse instance if not present
    if (imageGallery.length && !fuseInstance.current) {
      fuseInstance.current = initialiseFuseInstance(imageGallery);
    }

    // handle perfilling seach query if no search query set
    if (productImageSearchQuery === '' && selectedProduct?.name) {
      searchForPrefilledProductName(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  // Filter the image results based on the changing search value
  useEffect(() => {
    if (imageGallery.length && productImageSearchQuery && fuseInstance.current) {
      const imageMatches = search(fuseInstance.current, imageGallery, productImageSearchQuery);
      setFilteredImages(imageMatches);
    }
  }, [productImageSearchQuery, imageGallery]);

  return (
    <Dialog open={open} onEscapeKeyDown={handleOnCancel} onBackdropClick={handleOnCancel} keepMounted={true}>
      <div className={styles.Dialog}>
        <DialogTitle className={styles.DialogTitle}>
          <IconButton className={styles.CloseBtn} aria-label="close" onClick={handleOnCancel}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={styles.ImageSelectorContainer}>
          <div className={styles.HeaderContent}>
            <Typography type={TYPOGRAPHY_TYPES.HEADING_XL} className={styles.ImageSelectorHeader}>
              Select image
            </Typography>
            {selectedProduct === null ? (
              <div className={styles.ProductInfoSection}>
                <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ProductInfo}>
                  Type in the product&apos;s name and press enter{' '}
                </Typography>
              </div>
            ) : (
              <div className={styles.ProductInfoSection}>
                <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ProductInfo}>
                  SKU:&nbsp;{selectedProduct && selectedProduct.sku ? selectedProduct.sku : 'Not available'}
                </Typography>
                <Typography type={TYPOGRAPHY_TYPES.BODY}>
                  Product name:&nbsp;{selectedProduct ? selectedProduct.name : 'Not available'}
                </Typography>
                <div className={styles.PrefilBtnContainer}>
                  {[2, 3].map((numWords) => (
                    <Button
                      key={`${numWords}_prefill_search_button`}
                      onClick={() => searchForPrefilledProductName(numWords)}
                      variant={BUTTON_VARIANTS.SECONDARY}
                      size={BUTTON_SIZES.EXTRA_SMALL}
                      className={styles.PrefilBtn}
                      disabled={!getPrefilName(selectedProduct?.name, numWords)}
                    >
                      <SearchIcon /> &apos;{getPrefilName(selectedProduct?.name, numWords)}&apos;
                    </Button>
                  ))}
                </div>
              </div>
            )}
            <div className={styles.SearchBarContainer}>
              <SearchBar
                forceFocus={shouldFocusSearchBar}
                fullWidth
                value={searchQuery}
                placeholder="Search product image catalogue"
                onChange={handleSearchChange}
                handleClear={handleClearSearch}
                handleSearch={onHandleSearch}
              />
            </div>
          </div>
          <div
            className={
              selectedProduct === null ? [styles.ImageSection, styles.newProduct].join(' ') : styles.ImageSection
            }
          >
            {filteredImages.map((img) => (
              <Button
                key={img.id}
                variant="secondary"
                className={
                  selectedImage && selectedImage.id === img.id
                    ? [styles.ProductImageRow, styles.selected].join(' ')
                    : styles.ProductImageRow
                }
                onClick={() => handleSelectImage(img)}
              >
                <img className={styles.ProductImg} src={img.url} alt={img.fileName} />
                <Typography type={TYPOGRAPHY_TYPES.BODY}>{img.description}</Typography>
              </Button>
            ))}
          </div>
        </DialogContent>
        {updatingImageError ? (
          <div className={styles.FooterActions}>
            <NotificationMessageSection
              type={NOTIFICATION_MESSAGE_TYPES.ERROR}
              size={NOTIFICATION_MESSAGE_SIZES.SMALL}
              title="The image could not be saved"
              emoji="😔"
              ariaLabelForEmoji="sad"
              className={styles.SaveError}
            >
              <p>Please try again later</p>
            </NotificationMessageSection>
          </div>
        ) : null}
        <DialogActions className={styles.DialogFooter}>
          <Button variant="secondary" onClick={handleOnCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleOnSave} disabled={selectedImage === null || updatingImageInProgress}>
            {updatingImageInProgress ? (
              <React.Fragment>
                <Typography className={styles.ProgressText} type={TYPOGRAPHY_TYPES.HEADING_S}>
                  Saving..
                </Typography>
                <CircularProgress className={styles.ProgressSpinner} size={18} thickness={3} />
              </React.Fragment>
            ) : (
              <React.Fragment>Save</React.Fragment>
            )}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ProductImageSelectorModal;

ProductImageSelectorModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  selectedProduct: PropTypes.object,
  updatingImageInProgress: PropTypes.bool,
  updatingImageError: PropTypes.bool,
  imageGallery: PropTypes.array.isRequired,
  productImageSearchQuery: PropTypes.string,
  setProductImageSearchQuery: PropTypes.func.isRequired,
};
