/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, FC } from 'react';
import PropTypes from 'prop-types';
import Resizer from 'react-image-file-resizer';
import { AxiosError } from 'axios';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import { CreateNotification, SendErrorReportType } from '../../../utils/Presenters/ReduxType';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import { Button, Typography } from '../../../components/UI/FB';
import { Supplier } from '../../../utils/Presenters/Common';
import styles from './ImageUploader.module.scss';

type BackgroundImageUploaderProps = {
  onSuccessUpload: (imageUrl: string) => void;
  sendDatadogError: SendErrorReportType;
  createNotification: CreateNotification;
  supplierInfo: Supplier;
  onRemoveBackground: () => void;
  errorMessage?: string | null;
};

const BackgroundImageUploader: FC<BackgroundImageUploaderProps> = ({
  onSuccessUpload,
  onRemoveBackground,
  sendDatadogError,
  createNotification,
  supplierInfo,
  errorMessage,
}) => {
  const [progress, setProgress] = useState<number>(0);
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const [supplierBackgroundImage, setSupplierBackgroundImage] = useState<string | null>(
    supplierInfo.backgroundImageUrl || null,
  );
  const [uploadError, setUploadError] = useState<string>('');

  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1400,
        700,
        'jpg',
        85,
        0,
        async (uri: string | Blob | File | ProgressEvent<FileReader>) => {
          resolve(uri);
        },
        'file',
        100,
        100,
      );
    });

  const handleUploadImage = async (event: any): Promise<void> => {
    setUploadError('');
    const imageObject = event.target.files[0];

    const formData = new FormData();
    const image: any = await resizeFile(imageObject);
    formData.append('backgroundImageUrl', image);

    const options = {
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const { loaded, total } = progressEvent;
        const percentage = Math.floor((loaded * 100) / total);
        // display 99% until we get a response for the image url
        setProgress(percentage === 100 ? 99 : percentage);
      },
    };

    FoodbombAPI.post(`/suppliers/${supplierInfo.id}/background-image`, formData, options)
      .then((res) => {
        onSuccessUpload(res.data.imageUrl);
        setBackgroundImage(res.data.imageUrl);
        setProgress(100);
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status === 400 && error?.response?.data?.errors) {
          setUploadError(error?.response?.data?.errors[0]?.message);
        } else {
          sendDatadogError('Failed to upload image', {
            error,
            location: 'Image uploader component, setting page',
          });
        }
        createNotification({
          timeout: 6000,
          closable: true,
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Sorry we were unable to upload your image. Please try again.',
        });
      });
  };

  const handleRemove = () => {
    setSupplierBackgroundImage(null);
    onRemoveBackground();
  };

  return (
    <>
      {!supplierInfo.backgroundImageUrl ? (
        <div className={styles.ImageUpload__wrapper}>
          <Typography type={TYPOGRAPHY_TYPES.BODY_BOLD}>Header image</Typography>
          <div className={styles.UploadSection}>
            <label>
              <input
                name="backgroundImageUrl"
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={(e) => handleUploadImage(e)}
                className={styles.InputField}
                multiple
                disabled={!!supplierInfo.backgroundImageUrl}
              />
              <div className={styles.ImageUpload_content}>
                <div className={styles.UploadIcon__container}>
                  <CloudUploadOutlinedIcon className={styles.UploadIcon} />
                </div>
                <div>
                  <Button
                    type="button"
                    className={styles.ImageUpload__actionButton}
                    disabled={!!supplierInfo.backgroundImageUrl}
                    component="div"
                  >
                    Click to upload
                  </Button>
                  <Typography type={TYPOGRAPHY_TYPES.BODY}>or drag & drop,</Typography>
                </div>
                <Typography type={TYPOGRAPHY_TYPES.BODY}>PNG, JPEG, JPG</Typography>
              </div>
            </label>
          </div>
          <Typography type={TYPOGRAPHY_TYPES.BODY_S} className={styles.ErrorMessage}>
            {errorMessage || uploadError}
          </Typography>
        </div>
      ) : null}
      {progress > 0 ? (
        <div className={styles.UploadedImage__contentContainer}>
          <div className={styles.UploadedImage__contentLeft}>
            <div className={styles.ImageWrapper}>
              {backgroundImage ? (
                <img className={styles.UploadedImage__image} src={backgroundImage} alt="" />
              ) : (
                <>
                  {uploadError ? (
                    <ReportProblemOutlinedIcon className={styles.ErrorIcon} />
                  ) : (
                    <CircularProgress thickness={3} size={40} className={styles.Spinner} />
                  )}
                </>
              )}
            </div>
            <Typography type={TYPOGRAPHY_TYPES.BODY}>{supplierInfo.company} &nbsp; background</Typography>
          </div>
          <div className={styles.UploadedImage__contentRight}>
            {uploadError ? (
              <div className={styles.ErrorWrapper}>
                <Typography type={TYPOGRAPHY_TYPES.BODY_S} className={styles.ErrorMessage__upload}>
                  Upload failed.
                </Typography>
                <ReportProblemOutlinedIcon className={styles.ErrorIcon} />
              </div>
            ) : (
              <>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  classes={{ root: styles.LeanerProgress__root, bar: styles.LeanerProgress__bar }}
                />
                {progress}%
              </>
            )}
          </div>
        </div>
      ) : null}
      {supplierInfo.backgroundImageUrl && supplierBackgroundImage ? (
        <>
          <div className={styles.Image__contentContainer}>
            <div className={styles.Image__contentLeft}>
              <div className={styles.ImageWrapper}>
                <img className={styles.Image} src={supplierInfo.backgroundImageUrl} alt="" />
              </div>
              <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.ImageCompany__name}>
                {supplierInfo.company}&nbsp;background
              </Typography>
            </div>
            <div className={styles.Image__contentRightAction}>
              <button type="button" className={styles.DeleteButton} onClick={handleRemove}>
                <DeleteOutlineIcon className={styles.DeleteIcon} />
              </button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

BackgroundImageUploader.defaultProps = {
  errorMessage: null,
};

BackgroundImageUploader.propTypes = {
  onSuccessUpload: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  onRemoveBackground: PropTypes.func.isRequired,
};

export default BackgroundImageUploader;
