import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import {
  EXPIRED_SESSION_NOTIFICATION_TARGET_GROUP,
  NOTIFICATION_TYPES,
  NETWORK_STATUS_NOTIFICATION_TARGET_GROUP,
  DOWNLOAD_IN_PROGRESS_TARGET_GROUP,
  MAINTENANCE_MODE_ERROR_TARGET_GROUP,
} from '../../utils/Constants';

/**
 *
 * Notification config structure e.g:
 *
 * notification = {
 *  type: NOTIFICATION_TYPES.SUCCESS,
 *  content: "my notification message",
 *  uniqueTargetGroup: (optional) "SOME_CONSTANT",
 *  timeout: 1000,
 *  closable: true
 * }
 *
 * Call createNotification(notification) from inside the component wrapped with this class (withNotifications)
 */
function withNotifications(WrappedComponent) {
  class WithNotificationsWrapper extends Component {
    updateNetworkNotification = (isUserOffline) => {
      this.createNotification({
        timeout: isUserOffline ? undefined : 2000,
        closable: !isUserOffline,
        type: isUserOffline ? NOTIFICATION_TYPES.ERROR : NOTIFICATION_TYPES.SUCCESS,
        uniqueTargetGroup: NETWORK_STATUS_NOTIFICATION_TARGET_GROUP,
        content: isUserOffline ? 'You are currently offline' : 'Connected',
      });
    };

    removeNotificationOfTargetGroup = (targetGroup) => {
      const notificationToRemove = this.props.currentNotifications.find(
        (notification) => notification.uniqueTargetGroup === targetGroup,
      );
      if (targetGroup && notificationToRemove) {
        this.props.removeNotification(notificationToRemove.id);
      }
    };

    createNotification = (notificationConfig) => {
      this.removeNotificationOfTargetGroup(notificationConfig.uniqueTargetGroup);
      this.props.addNotification(notificationConfig);
    };

    createDownloadInProgressNotification = (downloadCount) => {
      this.createNotification({
        type: NOTIFICATION_TYPES.INFO,
        showPermanentSpinner: true,
        content: downloadCount
          ? `Downloading ${downloadCount} ${downloadCount > 1 ? 'files' : 'file'}`
          : 'File download in progress',
        uniqueTargetGroup: DOWNLOAD_IN_PROGRESS_TARGET_GROUP,
      });
    };

    removeDownloadInProgressNotification = () =>
      this.removeNotificationOfTargetGroup(DOWNLOAD_IN_PROGRESS_TARGET_GROUP);

    createExpiredSessionNotification = () => {
      if (
        !this.props.currentNotifications.find(
          (notification) => notification.uniqueTargetGroup === EXPIRED_SESSION_NOTIFICATION_TARGET_GROUP,
        )
      ) {
        this.createNotification({
          timeout: 6000,
          closable: true,
          type: NOTIFICATION_TYPES.ERROR,
          content: 'Your session has expired, please log in again',
          uniqueTargetGroup: EXPIRED_SESSION_NOTIFICATION_TARGET_GROUP,
        });
      }
    };

    updateMaintenanceRetryError = (countdown) => {
      this.createNotification({
        timeout: countdown,
        closable: true,
        type: NOTIFICATION_TYPES.WARNING,
        content: 'Attempting to reconnect shortly...',
        uniqueTargetGroup: MAINTENANCE_MODE_ERROR_TARGET_GROUP,
      });
    };

    render() {
      return (
        <WrappedComponent
          createNotification={this.createNotification}
          updateAddedToCartNotification={this.updateAddedToCartNotification}
          updateNetworkNotification={this.updateNetworkNotification}
          createExpiredSessionNotification={this.createExpiredSessionNotification}
          createDownloadInProgressNotification={this.createDownloadInProgressNotification}
          removeDownloadInProgressNotification={this.removeDownloadInProgressNotification}
          removeNotificationOfTargetGroup={this.removeNotificationOfTargetGroup}
          updateMaintenanceRetryError={this.updateMaintenanceRetryError}
          {...this.props}
        />
      );
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    addNotification: (notification) => dispatch(actions.notificationAdd(notification)),
    removeNotification: (notificationId) => dispatch(actions.notificationRemove(notificationId)),
  });

  const mapStateToProps = (state) => ({
    currentNotifications: state.notifications.notifications,
  });

  WithNotificationsWrapper.propTypes = {
    addNotification: PropTypes.func.isRequired,
    removeNotification: PropTypes.func.isRequired,
    currentNotifications: PropTypes.array.isRequired,
  };

  return connect(mapStateToProps, mapDispatchToProps)(WithNotificationsWrapper);
}

export default withNotifications;
