import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { CircularProgress, Dialog, DialogContent, IconButton, DialogTitle } from '@material-ui/core';
import { Button, Typography, Checkbox, FormikFormField } from '../../../components/UI/FB';
import { TYPOGRAPHY_TYPES } from '../../../utils/Constants';
import { calculatePriceIncGST } from '../../../utils/PortionSizePricingHelper/PortionSizePricingHelper';
import { presentCurrency } from '../../../utils/Presenters/PresentCurrency/PresentCurrency';

import styles from './AddCustomSubstitutionDialog.module.scss';

const AddCustomSubstitutionDialog = ({ isOpen, handleClose, onSubstitutionAdded }) => {
  const substitutionValidationSchema = Yup.object().shape({
    productSKU: Yup.string().required('SKU is required'),
    productName: Yup.string().required('Product name is required'),
    price: Yup.number()
      .typeError('Total price must be a number')
      .required('Total price is required')
      .min(0, 'Total price cannot be negative')
      .test('decimal-places', 'Total price must have a maximum of 2 decimal places', (value) =>
        !value ? true : !(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2),
      ),
  });

  const attemptToAddSubstitution = (values, actions) => {
    actions.setStatus({ apiError: undefined });
    const productToAdd = {
      orderProductId: undefined,
      productId: 0,
      sku: values.productSKU,
      gst: values.hasGST ? (values.price * 100) / 11 : 0,
      hasGST: values.hasGST,
      substitution: true,
      name: values.productName,
      priceIncGST: values.hasGST
        ? calculatePriceIncGST(parseFloat(values.price)) * 100
        : parseFloat(values.price * 100),
      portionSize: '1.00',
      unitOfPortion: 'unit',
      unitOfPrice: 'unit',
      originalNumberOfPortions: 0,
      originalQuantityPriced: '0.00',
      originalTotalIncGST: 0,
      finalQuantityPriced: '1.00',
      finalTotalIncGST: values.hasGST
        ? calculatePriceIncGST(parseFloat(values.price)) * 100
        : parseFloat(values.price * 100),
      isOnSpecial: false,
      isOnCustomPrice: false,
    };
    onSubstitutionAdded(productToAdd);
    actions.setSubmitting(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ root: styles.AddCustomSubstitutionDialogRoot, paper: styles.AddCustomSubstitutionDialogPaper }}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle className={styles.AddCustomSubstitutionDialog__header}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Add Custom Product</Typography>
        <IconButton className={styles.AddCustomSubstitutionDialog__closeBtn} aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <Typography type={TYPOGRAPHY_TYPES.BODY}>
            Note that custom products will only be added to this order. If you want to add this product to Foodbomb,
            you’ll have to do it through your Products page.
          </Typography>

          <Formik
            initialValues={{
              productSKU: '',
              productName: '',
              price: 0,
              hasGST: false,
            }}
            validationSchema={substitutionValidationSchema}
            onSubmit={attemptToAddSubstitution}
            initialStatus={{
              apiError: undefined,
            }}
          >
            {({ errors, touched, values, status, setFieldValue, setTouched, isSubmitting }) => (
              <Form>
                <div className={styles.CustomSubstitutionForm}>
                  <div className={styles.InputRow}>
                    <div className={styles.FullWidthInputContainer}>
                      <FormikFormField
                        fieldName="productSKU"
                        touched={touched}
                        errors={errors}
                        placeholder="Enter a product SKU"
                        label={'Product SKU'}
                      />
                    </div>
                  </div>
                  <div className={styles.InputRow}>
                    <div className={styles.FullWidthInputContainer}>
                      <FormikFormField
                        fieldName="productName"
                        touched={touched}
                        errors={errors}
                        placeholder="Enter a product name"
                        label={'Product name'}
                      />
                    </div>
                  </div>
                  <div className={styles.InputRow}>
                    <div className={styles.FullWidthInputContainer}>
                      <FormikFormField
                        fieldName="price"
                        touched={touched}
                        errors={errors}
                        placeholder="Enter price"
                        label={'Product Price (Ex. GST)'}
                      />
                    </div>
                  </div>
                  <div className={styles.InputRow}>
                    <div className={styles.FullWidthInputContainer}>
                      <Checkbox
                        label="GST is applicable to this product"
                        checked={values.hasGST}
                        onChange={(e) => {
                          setTouched({ ...touched, hasGST: true });
                          setFieldValue('hasGST', e.currentTarget.checked);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.FinalPriceContainer}>
                    <div className={styles.FinalPriceSection}>
                      <Typography type={TYPOGRAPHY_TYPES.BODY}>
                        Final Price: {presentCurrency(values.price)} (Ex.GST)
                      </Typography>
                    </div>
                    {values.hasGST ? (
                      <div className={styles.FinalPriceSection}>
                        <Typography type={TYPOGRAPHY_TYPES.BODY}>
                          Final Price: {presentCurrency(calculatePriceIncGST(parseFloat(values.price)))} (Inc.GST)
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.ErrorContainer}>{status.apiError}</div>
                  <div className={styles.CustomSubstitutionForm__actions}>
                    <Button type="submit" disabled={isSubmitting} className={styles.SubmitBtn}>
                      {isSubmitting ? (
                        <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                      ) : (
                        <React.Fragment>Add to Order</React.Fragment>
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

AddCustomSubstitutionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubstitutionAdded: PropTypes.func.isRequired,
};

export default AddCustomSubstitutionDialog;
