import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Formik, Form } from 'formik';
import { CircularProgress } from '@material-ui/core';

import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import { ErrorNotification, FormikFormField, Paper, Typography, Tooltip, Button } from '../../../components/UI/FB';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES, BUTTON_VARIANTS } from '../../../utils/Constants';
import styles from '../Settings.module.scss';

const BankDetailsCard = ({
  sendDatadogError,
  supplierInfo,
  updateSupplierDetails,
  createNotification,
  innerRef,
  keys,
  pulse,
}) => {
  const [editMode, setEditMode] = useState(false);
  const triggerEditMode = () => setEditMode(true);
  const triggerViewMode = (callback) => {
    setEditMode(false);
    if (callback) {
      callback();
    }
  };

  const shouldUpdateValues = (values) => keys.some((key) => values[key] !== supplierInfo[key]);

  const buildBankDetails = () => {
    const isInvalid = keys.some((key) => !supplierInfo[key]);
    if (isInvalid) {
      return <span className={styles.RedText}>Invalid bank details</span>;
    }
    return (
      <div>
        <div>
          <Typography
            className={[styles.SettingsCard__summary, styles.title].join(' ')}
            type={TYPOGRAPHY_TYPES.BODY_BOLD}
          >
            {supplierInfo.accountName}
          </Typography>
        </div>
        <div>
          <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__summary}>
            {supplierInfo.bsb}&nbsp;|&nbsp;{supplierInfo.accountNumber}
          </Typography>
        </div>
      </div>
    );
  };

  const mapAPIErrorsToFormFields = (errors) => {
    const formErrors = {};
    errors.forEach((e) => {
      formErrors[e.param] = e.message;
    });
    return formErrors;
  };

  const handleSubmit = (values, actions) => {
    actions.setStatus({ apiError: undefined });
    const detailsToUpdate = {
      accountName: values.accountName,
      bsb: values.bsb.toString(),
      accountNumber: values.accountNumber.toString(),
    };

    if (!shouldUpdateValues(values)) {
      triggerViewMode();
      createNotification({
        type: NOTIFICATION_TYPES.WARNING,
        content: 'Nothing to update',
        timeout: 4000,
        closable: true,
      });
      actions.setSubmitting(false);
    } else {
      FoodbombAPI.patch('suppliers/auth/me', detailsToUpdate)
        .then((response) => {
          updateSupplierDetails(response.data);
          triggerViewMode();
          createNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            content: 'Successfully updated bank details',
            timeout: 4000,
            closable: true,
          });
          actions.setSubmitting(false);
        })
        .catch((error) => {
          if (error?.response?.status === 400 && error?.response?.data?.errors) {
            createNotification({
              type: NOTIFICATION_TYPES.ERROR,
              content: 'Unable to update bank details',
              timeout: 4000,
              closable: true,
            });

            actions.setErrors(mapAPIErrorsToFormFields(error.response.data.errors));
          } else {
            sendDatadogError('Unable to update bank details', {
              error,
              location: 'Settings Page',
            });
            createNotification({
              type: NOTIFICATION_TYPES.ERROR,
              content: 'Unable to update bank details',
              timeout: 4000,
              closable: true,
            });
            actions.setStatus({
              apiError: (
                <ErrorNotification
                  body={"We were unable to update your bank details. Don't worry, our engineers have been notified!"}
                  className={styles.ErrorNotification}
                />
              ),
            });
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  };

  const accountDetailsInitialValues = () => ({
    accountName: supplierInfo.accountName || '',
    bsb: supplierInfo.bsb || '',
    accountNumber: supplierInfo.accountNumber || '',
  });

  const accountDetailsValidationSchema = () =>
    Yup.object({
      accountName: Yup.string().trim().required('Account name is required'),
      bsb: Yup.string()
        .typeError('BSB must be a number')
        .required('BSB is required')
        .trim()
        .matches(/^\d{3}(-?|\s?)\d{3}$/, 'Must be a valid BSB'),
      accountNumber: Yup.string()
        .typeError('Account number must be a number')
        .required('Account number is required')
        .test('length', 'Account number should have at least 8 digits', (value) =>
          !value ? true : value.toString().length >= 0,
        ),
    });

  const showTick = (values, errors) =>
    editMode ? !keys.some((key) => errors[key] || !values[key]) : keys.every((key) => supplierInfo[key]);

  const showErrorIcon = (touched, errors) =>
    editMode ? keys.some((key) => touched[key] && errors[key]) : keys.some((key) => !supplierInfo[key]);

  return (
    <div ref={innerRef}>
      <Paper className={pulse ? [styles.SettingsCard, styles.pulse].join(' ') : styles.SettingsCard}>
        <Formik
          initialValues={accountDetailsInitialValues()}
          initialStatus={{
            apiError: undefined,
          }}
          validationSchema={accountDetailsValidationSchema()}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched, values, status, handleReset }) => (
            <React.Fragment>
              <CheckCircleOutlineIcon
                className={
                  showTick(values, errors)
                    ? styles.SettingsCard__completeIcon
                    : [styles.SettingsCard__completeIcon, styles.hide].join(' ')
                }
              />
              <ErrorOutlineIcon
                className={
                  showErrorIcon(touched, errors)
                    ? styles.SettingsCard__invalidIcon
                    : [styles.SettingsCard__invalidIcon, styles.hide].join(' ')
                }
              />
              <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.SettingsCard__sectionHeading}>
                Bank Details&nbsp;
                <Tooltip title="Bank details are required" placement="top">
                  <span className={styles.RequiredIcon}>*</span>
                </Tooltip>
              </Typography>
              {editMode ? (
                <div>
                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__description}>
                    This is where we will send your payments.
                  </Typography>
                  <div>
                    <Form>
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="accountName"
                          touched={touched}
                          errors={errors}
                          placeholder="Your account name"
                          label="Account Name"
                        />
                      </div>
                      <div className={styles.FormRow}>
                        <div className={styles.BSBFormInput}>
                          <FormikFormField
                            fieldName="bsb"
                            touched={touched}
                            errors={errors}
                            placeholder="Your BSB code"
                            label="BSB"
                          />
                        </div>
                        <div className={styles.AccountNumberFormInput}>
                          <FormikFormField
                            fieldName="accountNumber"
                            touched={touched}
                            errors={errors}
                            placeholder="Your account number"
                            label="Account Number"
                          />
                        </div>
                      </div>

                      {status.apiError ? <div className={styles.ErrorMessageContainer}>{status.apiError}</div> : null}
                      <div className={styles.SettingsCard__editBtnContainer}>
                        <Button
                          type="button"
                          disabled={isSubmitting}
                          variant={BUTTON_VARIANTS.SECONDARY}
                          onClick={() => triggerViewMode(handleReset)}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" disabled={isSubmitting}>
                          {isSubmitting ? (
                            <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                          ) : (
                            <React.Fragment>Save Preferences</React.Fragment>
                          )}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  {buildBankDetails()}
                  <div className={styles.SettingsCard__editBtnContainer}>
                    <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={triggerEditMode} className={styles.EditBtn}>
                      Edit Details
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

BankDetailsCard.propTypes = {
  supplierInfo: PropTypes.object.isRequired,
  updateSupplierDetails: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  pulse: PropTypes.bool,
  keys: PropTypes.array.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
};

export default withErrorReports(withNotifications(BankDetailsCard));
