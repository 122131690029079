import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import styles from './EmailDownloadPDFDialog.module.scss';
import {
  TYPOGRAPHY_TYPES,
  PDF_DOCUMENT_TYPES,
  NOTIFICATION_MESSAGE_TYPES,
  BUTTON_VARIANTS,
} from '../../../utils/Constants';
import { FoodbombPDFAPI } from '../../../utils/AxiosInstances';
import withEventTracking from '../../../hoc/withEventTracking/withEventTracking';
import useNotifications from '../../../hooks/useNotifications/useNotifications.ts';
import useDDErrorReporting from '../../../hooks/useDDErrorReporting/useDDErrorReporting.ts';
import { Button, Emoji, FormikFormField, Typography } from '../../../components/UI/FB';
import { OBJECT_TYPES, PAGE_POSITION } from '../../../hoc/withEventTracking/Properties';

const BULK_PDF_ENDPOINTS = {
  [PDF_DOCUMENT_TYPES.INVOICE]: 'invoices-async',
  [PDF_DOCUMENT_TYPES.PURCHASE_ORDER]: 'purchase-orders-async',
  [PDF_DOCUMENT_TYPES.DELIVERY_SLIP]: 'delivery-dockets-async',
};

const EVENT_OBJECTS = {
  [PDF_DOCUMENT_TYPES.INVOICE]: OBJECT_TYPES.INVOICE,
  [PDF_DOCUMENT_TYPES.PURCHASE_ORDER]: OBJECT_TYPES.PURCHASE_ORDER,
  [PDF_DOCUMENT_TYPES.DELIVERY_SLIP]: OBJECT_TYPES.DELIVERY_SLIP,
};

const MAX_NUMBER_OF_BROWSER_PDF_DOWNLOADS = 20;

const EmailDownloadPDFDialog = ({
  isOpen,
  handleClose,
  documentType,
  orderIds,
  defaultEmail,
  trackDownloadPDF,
  handleClearSelectedOrders,
}) => {
  const [submittedEmail, setSubmittedEmail] = useState('');
  const { createNotification } = useNotifications();
  const { sendDatadogError } = useDDErrorReporting();

  const emailValidationSchema = Yup.object({
    email: Yup.string().email('Must be a valid email address').required('Email is required').trim(),
  });

  const handleCloseAndReset = () => {
    handleClose();
    setTimeout(() => setSubmittedEmail(''), 100);
  };

  const downloadPDFs = (values, actions) => {
    actions.setSubmitting(true);
    const apiUrl = `orders/${BULK_PDF_ENDPOINTS[documentType]}`;
    const prettyDocumentType = documentType.replace('-', ' ');
    FoodbombPDFAPI({
      url: apiUrl,
      method: 'POST',
      responseType: 'blob',
      params: {
        orderIds,
        email: values.email,
      },
    })
      .then(() => {
        trackDownloadPDF(EVENT_OBJECTS[documentType], orderIds, apiUrl, {
          uiPosition: PAGE_POSITION.HEADER,
        });
        setSubmittedEmail(values.email);
        handleClearSelectedOrders();
      })
      .catch((error) => {
        sendDatadogError(`Failed to download multiple ${prettyDocumentType}s`, {
          error,
          location: 'Supplier Portal Orders Page',
        });
        createNotification({
          type: NOTIFICATION_MESSAGE_TYPES.ERROR,
          content: `Unable to download ${prettyDocumentType}s. Please try again later`,
          closable: true,
          timeout: 3000,
        });
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ root: styles.DialogRoot, paper: styles.DialogPaper }}
    >
      <DialogTitle className={styles.EmailDownloadPDFDialog__header}>
        <Emoji content={submittedEmail ? '🍳' : '📧'} label="email" className={styles.HeadingEmoji} />
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>
          {submittedEmail ? 'Cooking up your email' : 'Confirm Email Download'}
        </Typography>
        {submittedEmail ? (
          <IconButton
            className={styles.EmailDownloadPDFDialog__closeBtn}
            aria-label="close"
            onClick={handleCloseAndReset}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        {submittedEmail ? (
          <div className={styles.SuccessContainer}>
            <Typography type={TYPOGRAPHY_TYPES.BODY}>
              Feel free to leave this page, as this might take a while, we&apos;ll send a download link to&nbsp;
              <strong>{submittedEmail}</strong>
            </Typography>
          </div>
        ) : (
          <Formik
            initialValues={{
              email: defaultEmail ?? '',
            }}
            validationSchema={emailValidationSchema}
            onSubmit={downloadPDFs}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <Typography type={TYPOGRAPHY_TYPES.BODY_L} className={styles.BodyText}>
                  Downloading more than {MAX_NUMBER_OF_BROWSER_PDF_DOWNLOADS} items exceeds the maximum file size, so we
                  will have to send them by email.
                </Typography>
                <Typography type={TYPOGRAPHY_TYPES.BODY_L} className={styles.BodyText}>
                  If you prefer, you can still download them directly in blocks of 20 or less
                </Typography>
                <FormikFormField
                  label="Email"
                  fieldName="email"
                  touched={touched}
                  errors={errors}
                  placeholder="Enter the email you wish to recieve the download to"
                />
                <div className={styles.ButtonContainer}>
                  <Button
                    variant={BUTTON_VARIANTS.SECONDARY}
                    onClick={handleCloseAndReset}
                    className={styles.CancelBtn}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isSubmitting} className={styles.SubmitBtn}>
                    {isSubmitting ? (
                      <CircularProgress size={24} className={styles.SubmitLoadingSpinner} />
                    ) : (
                      <React.Fragment>Confirm Email Download</React.Fragment>
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

EmailDownloadPDFDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  documentType: PropTypes.string.isRequired,
  orderIds: PropTypes.array.isRequired,
  defaultEmail: PropTypes.string,
  trackDownloadPDF: PropTypes.func.isRequired,
  handleClearSelectedOrders: PropTypes.func.isRequired,
};

export default withEventTracking(EmailDownloadPDFDialog);
