import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import withNotifications from '../withNotifications/withNotifications';
import { NOTIFICATION_TYPES } from '../../utils/Constants';

class ProtectedRoute extends Component {
  render() {
    // eslint-disable-next-line
    const { hasToken, history, createNotification, component: Component, ...rest } = this.props;
    const isAuthenticated = hasToken;

    if (!isAuthenticated) {
      createNotification({
        timeout: 4000,
        closable: true,
        type: NOTIFICATION_TYPES.ERROR,
        content: 'Please log in to view that page',
      });
    }

    const redirectComponent = (
      <Redirect
        to={{
          pathname: '/login',
          state: { previousLink: `${history.location.pathname}${history.location.search}` },
        }}
      />
    );

    if (Component) {
      return (
        <Route
          {...rest}
          render={(props) =>
            isAuthenticated ? <Component {...props} /> : <React.Fragment>{redirectComponent}</React.Fragment>
          }
        />
      );
    }
    return (
      <React.Fragment>
        {isAuthenticated ? <Route {...rest} /> : <React.Fragment>{redirectComponent}</React.Fragment>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  hasToken: Boolean(state.auth.token),
});

ProtectedRoute.propTypes = {
  hasToken: PropTypes.bool.isRequired,
  history: PropTypes.object,
  createNotification: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node, PropTypes.func]),
};

export default withRouter(withNotifications(connect(mapStateToProps, null)(ProtectedRoute)));
