import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Formik, Form } from 'formik';
import { CircularProgress } from '@material-ui/core';

import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import { ErrorNotification, FormikFormField, Paper, Typography, Tooltip, Button } from '../../../components/UI/FB';
import { NOTIFICATION_TYPES, TYPOGRAPHY_TYPES, BUTTON_VARIANTS } from '../../../utils/Constants';
import styles from '../Settings.module.scss';

const StoreInformationCard = ({
  sendDatadogError,
  supplierInfo,
  updateSupplierDetails,
  createNotification,
  innerRef,
  pulse,
  keys,
}) => {
  const [editMode, setEditMode] = useState(false);
  const triggerEditMode = () => setEditMode(true);
  const triggerViewMode = (callback) => {
    setEditMode(false);
    if (callback) {
      callback();
    }
  };

  const shouldUpdateValues = (values) => keys.some((key) => values[key] !== supplierInfo[key]);

  const mapAPIErrorsToFormFields = (errors) => {
    const formErrors = {};
    errors.forEach((e) => {
      formErrors[e.param] = e.message;
    });
    return formErrors;
  };

  const handleSubmit = (values, actions) => {
    actions.setStatus({ apiError: undefined });
    const detailsToUpdate = {
      company: values.company,
      bio: values.bio,
    };

    if (!shouldUpdateValues(values)) {
      triggerViewMode();
      createNotification({
        type: NOTIFICATION_TYPES.WARNING,
        content: 'Nothing to update',
        timeout: 4000,
        closable: true,
      });
      actions.setSubmitting(false);
    } else {
      FoodbombAPI.patch('suppliers/auth/me', detailsToUpdate)
        .then((response) => {
          updateSupplierDetails(response.data);
          triggerViewMode();
          createNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            content: 'Successfully updated store information',
            timeout: 4000,
            closable: true,
          });
          actions.setSubmitting(false);
        })
        .catch((error) => {
          if (error?.response?.status === 400 && error?.response?.data?.errors) {
            createNotification({
              type: NOTIFICATION_TYPES.ERROR,
              content: 'Unable to update store information',
              timeout: 4000,
              closable: true,
            });

            actions.setErrors(mapAPIErrorsToFormFields(error.response.data.errors));
          } else {
            sendDatadogError('Unable to update store information', {
              error,
              location: 'Settings Page',
            });
            createNotification({
              type: NOTIFICATION_TYPES.ERROR,
              content: 'Unable to update store information',
              timeout: 4000,
              closable: true,
            });
            actions.setStatus({
              apiError: (
                <ErrorNotification
                  body={
                    "We were unable to update your store information. Don't worry, our engineers have been notified!"
                  }
                  className={styles.ErrorNotification}
                />
              ),
            });
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  };

  const businessDetailsInitialValues = () => ({
    company: supplierInfo.company || '',
    bio: supplierInfo.bio || '',
  });

  const businessDetailsValidationSchema = () =>
    Yup.object({
      company: Yup.string().trim().required('Store name is required'),
      bio: Yup.string().trim().required('Description is required'),
    });

  const showTick = (values, errors) =>
    editMode ? !keys.some((key) => errors[key] || !values[key]) : keys.every((key) => supplierInfo[key]);

  const showErrorIcon = (touched, errors) =>
    editMode ? keys.some((key) => touched[key] && errors[key]) : keys.some((key) => !supplierInfo[key]);

  const buildStoreInformation = () => {
    const isInvalid = keys.some((key) => !supplierInfo[key]);
    if (isInvalid) {
      return <span className={styles.RedText}>Missing store information</span>;
    }
    return (
      <div>
        <div>
          <Typography
            className={[styles.SettingsCard__summary, styles.title].join(' ')}
            type={TYPOGRAPHY_TYPES.BODY_BOLD}
          >
            {supplierInfo.company}
          </Typography>
        </div>
        <div>
          <Typography className={styles.SettingsCard__summary} type={TYPOGRAPHY_TYPES.BODY}>
            {supplierInfo.bio}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div ref={innerRef}>
      <Paper className={pulse ? [styles.SettingsCard, styles.pulse].join(' ') : styles.SettingsCard}>
        <Formik
          initialValues={businessDetailsInitialValues()}
          initialStatus={{
            apiError: undefined,
          }}
          validationSchema={businessDetailsValidationSchema()}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, errors, touched, status, handleReset }) => (
            <React.Fragment>
              <CheckCircleOutlineIcon
                className={
                  showTick(values, errors)
                    ? styles.SettingsCard__completeIcon
                    : [styles.SettingsCard__completeIcon, styles.hide].join(' ')
                }
              />
              <ErrorOutlineIcon
                className={
                  showErrorIcon(touched, errors)
                    ? styles.SettingsCard__invalidIcon
                    : [styles.SettingsCard__invalidIcon, styles.hide].join(' ')
                }
              />
              <Typography type={TYPOGRAPHY_TYPES.HEADING_L} className={styles.SettingsCard__sectionHeading}>
                Store Information&nbsp;
                <Tooltip title="Store information is required" placement="top">
                  <span className={styles.RequiredIcon}>*</span>
                </Tooltip>
              </Typography>
              {editMode ? (
                <div>
                  <Typography type={TYPOGRAPHY_TYPES.BODY} className={styles.SettingsCard__description}>
                    These details will appear in your shop on Foodbomb where customers will purchase from you.
                  </Typography>
                  <div>
                    <Form>
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="company"
                          touched={touched}
                          errors={errors}
                          placeholder="Enter your store name"
                          label="Store Name"
                        />
                      </div>
                      <div className={styles.FormRow}>
                        <FormikFormField
                          fieldName="bio"
                          touched={touched}
                          errors={errors}
                          placeholder="Enter a description about your store"
                          label="Description"
                          fieldProps={{
                            as: 'textarea',
                            rows: 5,
                          }}
                        />
                      </div>

                      {status.apiError ? <div className={styles.ErrorMessageContainer}>{status.apiError}</div> : null}
                      <div className={styles.SettingsCard__editBtnContainer}>
                        <Button
                          type="button"
                          disabled={isSubmitting}
                          variant={BUTTON_VARIANTS.SECONDARY}
                          onClick={() => triggerViewMode(handleReset)}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" disabled={isSubmitting}>
                          {isSubmitting ? (
                            <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                          ) : (
                            <React.Fragment>Update Details</React.Fragment>
                          )}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <div>
                    <div>
                      <Typography type={TYPOGRAPHY_TYPES.BODY}>{buildStoreInformation()}</Typography>
                    </div>
                  </div>
                  <div className={styles.SettingsCard__editBtnContainer}>
                    <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={triggerEditMode} className={styles.EditBtn}>
                      Edit Preferences
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

StoreInformationCard.propTypes = {
  supplierInfo: PropTypes.object.isRequired,
  updateSupplierDetails: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  pulse: PropTypes.bool,
  keys: PropTypes.array,
  sendDatadogError: PropTypes.func.isRequired,
};

export default withErrorReports(withNotifications(StoreInformationCard));
