import * as Yup from 'yup';

export const areCSVHeadersValid = (CSVHeadersFromFile, correctCSVHeaders) =>
  JSON.stringify(correctCSVHeaders) === JSON.stringify(CSVHeadersFromFile);

export const yupValidator = Yup.object().shape({
  productId: Yup.string()
    .test('productId', 'Product id must be a positive number', function test(productIdValue) {
      // if in add product mode, the id does not need to be used
      if (this.options.context.addProductsMode) {
        return true;
      }
      return typeof Number(productIdValue) === 'number' && Number(productIdValue) > 0 && productIdValue !== '';
    })
    .trim(),
  sku: Yup.string()
    .test('sku', 'Product SKU is required', function test(productSKUValue) {
      // if in edit product mode, the sku does not need to be used
      if (!this.options.context.addProductsMode) {
        return true;
      }
      return typeof productSKUValue === 'string' && productSKUValue !== ' ' && productSKUValue !== '';
    })
    .trim()
    .nullable(),
  name: Yup.string().typeError('Name is required').trim().required('Product Name is required').nullable(false),
  price: Yup.string()
    .typeError('Price is required')
    .trim()
    .required('Price is required')
    .matches(
      /^(?!(?:0|0\.0|0\.00)$)[+]?\d+(\.\d|\.\d[0-9])?$/,
      'Must be a positive number with a maximum of 2 decimal places',
    )
    .nullable(false),
  unitOfPrice: Yup.string()
    .typeError('Unit of Price is required')
    .trim()
    .required('Unit of Price is required')
    .nullable(false),
  unitOfPortion: Yup.string()
    .typeError('Unit of Portion is required')
    .trim()
    .required('Unit of Portion is required')
    .nullable(false),
  taxable: Yup.string()
    .typeError('Taxable is required')
    .uppercase()
    .trim()
    .required('Taxable is required')
    .oneOf(['N', 'Y'])
    .nullable(false),
  portions: Yup.string()
    .typeError('Portions must be a positive number separated by commas')
    .trim()
    .test('portions', 'Portions must be a positive number separated by commas', (portionValues) => {
      if (portionValues === null || portionValues === undefined) {
        return false;
      }
      return portionValues.split(',').every((portion) => Number(portion) && Number(portion) > 0);
    })
    .nullable(false),
  // categories are nullable since they're handled after the yup validator
  categoryOne: Yup.string().trim().nullable(),
  categoryTwo: Yup.string().trim().nullable(),
  categoryThree: Yup.string().trim().nullable(),
  enabled: Yup.string()
    .typeError('Enabled is required')
    .uppercase()
    .trim()
    .required('Enabled is required')
    .oneOf(['N', 'Y'])
    .nullable(false),
  outOfStock: Yup.string().typeError('Out of Stock is required').uppercase().trim().oneOf(['N', 'Y']).nullable(false),
});

export const yupValidationHandler = async (product, addProductsMode) => {
  let productErrors = [];
  let productSanitized = {};

  try {
    productSanitized = await yupValidator.validateSync(product, {
      abortEarly: false,
      context: { addProductsMode },
    });
  } catch (error) {
    productErrors = error.inner.map((e) => ({ path: e.path, message: e.message }));
    productSanitized = error.value;
  }
  return { productSanitized, productErrors };
};

export const isSKUDuplicated = (validatedProducts, validatedProduct) =>
  validatedProducts
    .filter((prod) => prod.productSanitized.sku !== '')
    .some((prod) => prod.productSanitized.sku === validatedProduct.productSanitized.sku);

export const isPortionAndPriceUnitsConsistent = (unitOfPortion, unitOfPrice) => {
  const unitOfPortionLower = unitOfPortion.toLowerCase();
  const unitOfPriceLower = unitOfPrice.toLowerCase();

  const portionUnitKiloGramRelated = ['kilogram', 'gram', 'kg', 'g'].includes(unitOfPortionLower);
  const priceUnitKiloGramRelated = ['kilogram', 'gram', 'kg', 'g'].includes(unitOfPriceLower);

  if (portionUnitKiloGramRelated && priceUnitKiloGramRelated) {
    return true;
  }
  return unitOfPortionLower === unitOfPriceLower;
};

export const isWeightClassAvaliableForUnit = (unitToCheck, availableUnits) =>
  // would rather use flatMap() but flatMap does not exist in node :(
  []
    .concat(...availableUnits.map((a) => [a.title.toLowerCase(), a.unit.toLowerCase()]))
    .includes(unitToCheck.toLowerCase());

export const doesFieldNotHaveError = (validatedProduct, field) =>
  !validatedProduct.productErrors.some((prod) => prod.path === field);

export const isCategoryEntered = (categoryName) =>
  categoryName !== undefined && categoryName !== '' && categoryName !== null;

export const isCategoryAMainCategory = (categories, categoryName) =>
  categories.filter((cat) => cat.name === categoryName).some((cat) => cat.parentCategoryId === null);
