import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import NotificationIcon from '../../components/UI/Notifications/NotificationIcon/NotificationIcon';
import { notificationRemove } from '../../store/actions';
import { Notification, ReduxState } from '../../utils/Presenters/ReduxType';
import styles from './Notifications.module.scss';
import NotificationProgressSpinner from '../../components/UI/Notifications/NotificationProgressSpinner/NotificationProgressSpinner';

const Notifications = () => {
  const notifications = useSelector((state: ReduxState) => state.notifications.notifications);
  const dispatch = useDispatch();

  const handleDismiss = (notificationId: number): void => {
    dispatch(notificationRemove(notificationId));
  };

  return (
    <div className={styles.NotificationsContainer}>
      {notifications.map((notification: Notification) => (
        <Snackbar
          key={notification.id}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{ root: styles.NotificationSnackbar }}
          open
        >
          <SnackbarContent
            className={[styles[notification.type], styles.Notification].join(' ')}
            classes={{
              message: styles.NotificationMessageContainer,
              action: styles.NotificationAction,
            }}
            message={
              <div>
                <div className={styles.NotificationMessage}>
                  <NotificationIcon notificationType={notification.type} className={styles.NotificationIcon} />
                  <div>
                    {notification?.title ? <strong>{notification.title}</strong> : null}
                    {notification?.content ? <div>{notification.content}</div> : null}
                  </div>
                </div>
                {notification.actionElement ? (
                  <div className={styles.ActionsContainer}>{notification.actionElement}</div>
                ) : null}
              </div>
            }
            action={
              <>
                {notification.timeout || notification.showPermanentSpinner ? (
                  <NotificationProgressSpinner
                    showPermanentSpinner={notification.showPermanentSpinner}
                    timeout={notification.timeout}
                    rootClass={styles.NotificationProgressSpinner}
                  />
                ) : null}
                {notification.closable
                  ? [
                      <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        size="small"
                        onClick={() => handleDismiss(notification.id)}
                      >
                        <Close fontSize="small" />
                      </IconButton>,
                    ]
                  : []}
              </>
            }
          />
        </Snackbar>
      ))}
    </div>
  );
};

export default Notifications;
