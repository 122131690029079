import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import debounce from 'es6-promise-debounce';
import { ErrorMessage, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import {
  Button,
  ErrorNotification,
  FormikFormField,
  FormikSingleSelectField,
  NotificationMessageSection,
  Typography,
} from '../../../components/UI/FB';
import withNotifications from '../../../hoc/withNotifications/withNotifications';
import withErrorReports from '../../../hoc/withErrorReports/withErrorReports';
import { FoodbombAPI } from '../../../utils/AxiosInstances';
import {
  ACTIVE_VENUE_STATUS,
  BUTTON_VARIANTS,
  NOTIFICATION_MESSAGE_SIZES,
  NOTIFICATION_MESSAGE_TYPES,
  NOTIFICATION_TYPES,
  PERSONAL_VENUE_TYPE,
  TYPOGRAPHY_TYPES,
} from '../../../utils/Constants';
import {
  buildPriceIncGSTLabelForProduct,
  buildPriceLabelForProduct,
  calculatePriceIncGST,
} from '../../../utils/PortionSizePricingHelper/PortionSizePricingHelper';
import styles from './AddCustomPriceDialog.module.scss';

const AddCustomPriceDialog = ({
  isOpen,
  handleClose,
  onSuccessfullyCreateCustomPrice,
  createNotification,
  isStaff,
  sendDatadogError,
}) => {
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [productsError, setProductsError] = useState(undefined);

  const [venues, setVenues] = useState([]);
  const [loadingVenues, setLoadingVenues] = useState(true);
  const [venuesError, setVenuesError] = useState(undefined);

  const buildCustomPriceAlreadyExistsError = (product, venue) => (
    <ErrorNotification
      emoji={'🙅‍♂️'}
      title="Sorry!"
      body={
        <div className={styles.CustomPriceExistsError}>
          <div className={styles.CustomPriceExistsError__body}>
            You already have a custom price for <strong>{product.name}</strong> for <strong>{venue.venue}</strong>
          </div>
          <div>Please edit your existing custom price</div>
        </div>
      }
      hideContactInfo
      actions={
        <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={handleClose}>
          Return to all custom prices
        </Button>
      }
    />
  );

  const attemptToCreateCustomPrice = (values, actions) => {
    actions.setStatus({ apiError: undefined });

    const productInQuestion = products.find((product) => product.id === values.productId);
    const venueInQuestion = venues.find((venue) => venue.id === values.venueId);
    const customPriceInCents = productInQuestion.gstExempt
      ? Math.round(values.customPrice * 100)
      : Math.round(calculatePriceIncGST(values.customPrice * 100));

    FoodbombAPI.post('/custom-pricing', {
      venueId: values.venueId,
      productId: values.productId,
      priceInCents: customPriceInCents,
    })
      .then(() => {
        createNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          content: 'Successfully created custom price',
          timeout: 4000,
          closable: true,
        });
        onSuccessfullyCreateCustomPrice();
      })
      .catch((error) => {
        if (error.response.status === 400 && error.response.data && error.response.data.errors) {
          const errorMessages = {};
          error.response.data.errors.forEach((e) => {
            if (e.code === 'invalid_parameter') {
              errorMessages[e.param] = e.message;
              actions.setErrors(errorMessages);
            }
            if (e.code === 'custom_pricing_already_exists_for_product_and_customer') {
              actions.setStatus({
                apiError: buildCustomPriceAlreadyExistsError(productInQuestion, venueInQuestion),
              });
            }
          });
        } else {
          sendDatadogError('Unable to create custom price', {
            error,
            location: 'Add custom price dialog',
          });
          actions.setStatus({
            apiError: (
              <ErrorNotification
                body={'We were unable to create this custom price. Our engineers have been notified!'}
              />
            ),
          });
        }
        actions.setSubmitting(false);
      });
  };

  const buildSearchLabelForVenue = useCallback((venue) => `#${venue.id}, ${venue.venue}`, []);

  const buildSearchLabelForProduct = useCallback(
    (product) => `${product.sku ? `${product.sku},` : ''} ${product.name} (${buildPriceLabelForProduct(product)})`,
    [],
  );

  const getUnitOfPriceStringForProductId = (productId) => {
    const productInQuestion = products.find((product) => product.value === productId);
    if (productInQuestion) {
      const unitOfPrice = productInQuestion.unitOfPrice.unit;
      return `per ${unitOfPrice}`;
    }
    return '';
  };

  const customPriceValidationSchema = Yup.object().shape({
    venueId: Yup.number().integer().required('Venue is required'),
    productId: Yup.number().integer().required('Product is required'),
    customPrice: Yup.number()
      .typeError('Custom price must be a number')
      .required('Custom price is required')
      .min(0, 'Custom price cannot be negative')
      .test('decimal-places', 'Custom price must have a maximum of 2 decimal places', (value) =>
        !value ? true : !(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2),
      ),
  });

  const loadProducts = useCallback(() => {
    setLoadingProducts(true);
    setProductsError(undefined);
    FoodbombAPI.get(`suppliers/products`, { credentials: 'include' })
      .then((response) => {
        setProducts(
          response.data.data.map((product) => ({
            ...product,
            label: buildSearchLabelForProduct(product),
            value: product.id,
          })),
        );
      })
      .catch((error) => {
        sendDatadogError('Unable to fetch products for supplier', {
          error,
          location: 'Create custom price page',
        });
        setProductsError('Unable to load products');
      })
      .finally(() => {
        setLoadingProducts(false);
      });
  }, [sendDatadogError, buildSearchLabelForProduct]);

  const searchArrayByLabel = (query, data) => {
    const numberOfResults = 15;

    if (query) {
      return data.filter((c) => c.label.toLowerCase().includes(query.toLowerCase())).slice(0, numberOfResults);
    }
    return data.slice(0, numberOfResults);
  };

  const filterVenuesBySearch = (query) => searchArrayByLabel(query, venues);

  const filterProductsBySearch = (query) => searchArrayByLabel(query, products);

  const loadVenues = useCallback(() => {
    setLoadingVenues(true);
    setVenuesError(undefined);
    FoodbombAPI.get('/venues', { credentials: 'include' })
      .then((response) => {
        setVenues(
          response.data.venues
            .map((venue) => ({
              ...venue,
              label: buildSearchLabelForVenue(venue),
              value: venue.id,
            }))
            .filter((c) => c.status === ACTIVE_VENUE_STATUS && c.venueType !== PERSONAL_VENUE_TYPE),
        );
      })
      .catch((error) => {
        sendDatadogError('Unable to fetch venues for supplier', {
          error,
          location: 'Create custom price page',
        });
        setVenuesError('Unable to load venues');
      })
      .finally(() => {
        setLoadingVenues(false);
      });
  }, [buildSearchLabelForVenue, sendDatadogError]);

  useEffect(() => {
    loadProducts();
    loadVenues();
  }, [loadProducts, loadVenues]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ root: styles.AddCustomPriceDialogRoot, paper: styles.AddCustomPriceDialogPaper }}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle className={styles.AddCustomPriceDialog__header}>
        <Typography type={TYPOGRAPHY_TYPES.HEADING_XL}>Add Custom Price</Typography>
        <IconButton className={styles.AddCustomPriceDialog__closeBtn} aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.AddCustomPriceDialog__content}>
        <div>
          <Typography type={TYPOGRAPHY_TYPES.BODY}>
            Select the venue you want to give custom pricing to and fill out the details below.
          </Typography>
          {!isStaff ? (
            <NotificationMessageSection
              type={NOTIFICATION_MESSAGE_TYPES.WARNING}
              size={NOTIFICATION_MESSAGE_SIZES.LARGE}
            >
              <Typography type={TYPOGRAPHY_TYPES.BODY}>
                If you would like to add custom pricing for a venue who has not purchased from you before, contact us
                on&nbsp;
                <a className={styles.Link} href={`tel:1300309055`}>
                  1300 309 055
                </a>
                &nbsp;or&nbsp;
                <a className={styles.Link} href={`mailto:support@foodbomb.com.au`}>
                  support@foodbomb.com.au
                </a>
              </Typography>
            </NotificationMessageSection>
          ) : null}
          {productsError || venuesError ? (
            <div className={styles.APIErrorContainer}>
              <ErrorNotification />
            </div>
          ) : (
            <Formik
              initialValues={{
                venueId: '',
                productId: '',
                customPrice: '',
              }}
              validationSchema={customPriceValidationSchema}
              onSubmit={attemptToCreateCustomPrice}
              initialStatus={{
                apiError: undefined,
              }}
            >
              {({ errors, touched, values, status, setFieldValue, setTouched, isSubmitting }) => (
                <Form>
                  <div className={styles.CustomPricingForm}>
                    <div className={styles.InputRow}>
                      <div className={styles.FullWidthInputContainer}>
                        <FormikSingleSelectField
                          isMulti={false}
                          value={venues.find((venue) => venue.id === values.venueId)}
                          isAsync
                          errors={errors}
                          touched={touched}
                          setTouched={setTouched}
                          label="Venue"
                          setFieldValue={setFieldValue}
                          fieldName="venueId"
                          cacheOptions
                          defaultOptions
                          loadOptions={debounce(filterVenuesBySearch, 250)}
                          placeholder={
                            loadingVenues ? 'Loading Venues...' : 'Select from your venues (Search by venue or ID)'
                          }
                          loadingMessage={() => 'Searching for venues...'}
                          maxMenuHeight={220}
                          isDisabled={loadingVenues}
                        />
                      </div>
                    </div>
                    <div className={styles.InputRow}>
                      <div className={[styles.FullWidthInputContainer, styles.withAnnotations].join(' ')}>
                        <FormikSingleSelectField
                          isMulti={false}
                          value={products.find((product) => product.id === values.productId)}
                          isAsync
                          errors={errors}
                          touched={touched}
                          setTouched={setTouched}
                          label="Product"
                          setFieldValue={setFieldValue}
                          fieldName="productId"
                          cacheOptions
                          defaultOptions
                          loadOptions={debounce(filterProductsBySearch, 250)}
                          placeholder={
                            loadingProducts
                              ? 'Loading Products...'
                              : 'Select from your products (Search by name or SKU)'
                          }
                          loadingMessage={() => 'Loading product list...'}
                          hideErrors
                          maxMenuHeight={220}
                          isDisabled={loadingProducts}
                        />
                        <div className={styles.ErrorMessageContainer}>
                          {errors.productId ? (
                            <ErrorMessage name="productId" />
                          ) : (
                            <React.Fragment>
                              {values.productId ? (
                                <div className={styles.SecondaryText}>
                                  Original price (Ex.GST):&nbsp;
                                  <strong>
                                    {buildPriceLabelForProduct(
                                      products.find((product) => product.id === values.productId),
                                    )}
                                  </strong>
                                </div>
                              ) : null}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.InputRow}>
                      <div className={[styles.FullWidthInputContainer, styles.withAnnotations].join(' ')}>
                        <FormikFormField
                          fieldName="customPrice"
                          touched={touched}
                          errors={errors}
                          placeholder="Enter a custom price"
                          label={`Custom price ${getUnitOfPriceStringForProductId(values.productId)} ($ Ex.GST) `}
                        />
                      </div>
                    </div>
                    <div>
                      {values.customPrice &&
                      products.find((product) => product.id === values.productId) &&
                      !products.find((product) => product.id === values.productId).gstExempt ? (
                        <NotificationMessageSection
                          type={NOTIFICATION_MESSAGE_TYPES.WARNING}
                          size={NOTIFICATION_MESSAGE_SIZES.LARGE}
                        >
                          <Typography type={TYPOGRAPHY_TYPES.BODY}>
                            <strong>Note:</strong>&nbsp;This product has GST, hence the price for the venue will
                            be&nbsp;
                            <strong>
                              {`${buildPriceIncGSTLabelForProduct({
                                ...products.find((product) => product.id === values.productId),
                                priceInCents: values.customPrice * 100,
                              })}`}
                            </strong>
                          </Typography>
                        </NotificationMessageSection>
                      ) : (
                        <div className={styles.NotificationSpaceBlocker}></div>
                      )}
                    </div>
                    <div className={styles.ErrorContainer}>{status.apiError}</div>
                    <div className={styles.CustomPricingForm__actions}>
                      <Button type="submit" disabled={isSubmitting} className={styles.SubmitBtn}>
                        {isSubmitting ? (
                          <CircularProgress thickness={3} size={24} className={styles.SubmitLoadingSpinner} />
                        ) : (
                          <React.Fragment>Add Custom Price</React.Fragment>
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  isStaff: state.auth.supplierDetails ? state.auth.supplierDetails.isStaff : false,
});

AddCustomPriceDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  isStaff: PropTypes.bool,
  createNotification: PropTypes.func.isRequired,
  onSuccessfullyCreateCustomPrice: PropTypes.func.isRequired,
  sendDatadogError: PropTypes.func.isRequired,
};

export default withErrorReports(withNotifications(connect(mapStateToProps, null)(AddCustomPriceDialog)));
