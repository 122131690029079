import React from 'react';
import withRedirectHelper from '../withRedirectHelper/withRedirectHelper';

const QUERY_PARAMS = {
  Q: 'q',
  PAGE: 'page',
  SPECIAL_STATUS: 'specialStatus',
  ORDER_STATUS_ID: 'orderStatusId',
  PRODUCT_STATUS_ID: 'productStatusId',
  GST_FILTER_ID: 'gstFilterId',
  IMAGE_FILTER_ID: 'imageFilterId',
  CUSTOM_PRICING_FILTER_ID: 'customPricingFilterId',
  DELIVERY_ZONE_STATUS: 'status',
  VIEW_BY: 'view',
  ZONE_ID: 'zoneId',
  OUT_OF_STOCK_ID: 'outOfStockId',
};

function withQueryParams(WrappedComponent) {
  const withQueryParamsWrapper = ({ location, history, ...props }) => {
    const getItemFromQueryString = (itemName) => {
      const { search } = location;
      const queryString = new URLSearchParams(search);
      return queryString.get(itemName);
    };

    const hasItemInQueryString = (itemName) => new URLSearchParams(location.search).has(itemName);

    const valueIsFalseyOrEmptyArray = (value) =>
      (!Array.isArray(value) && !value) || (Array.isArray(value) && !value.length);

    const getQueryFromQueryString = () => getItemFromQueryString(QUERY_PARAMS.Q);

    const getPageNumberQueryFromQueryString = () =>
      parseInt(getItemFromQueryString(QUERY_PARAMS.PAGE), 10) || undefined;

    const getSpecialFilterQueryFromQueryString = () => getItemFromQueryString(QUERY_PARAMS.SPECIAL_STATUS);

    const getDeliveryZoneStatusFromQueryString = () => getItemFromQueryString(QUERY_PARAMS.DELIVERY_ZONE_STATUS);

    const getViewFromQueryString = () => getItemFromQueryString(QUERY_PARAMS.VIEW_BY);

    const getZoneIdFromQueryString = () => parseInt(getItemFromQueryString(QUERY_PARAMS.ZONE_ID), 10) || undefined;

    const getOrderFilterIdQueryFromQueryString = () =>
      parseInt(getItemFromQueryString(QUERY_PARAMS.ORDER_STATUS_ID), 10) || 0;

    const getProductStatusFilterIdQueryFromQueryString = () =>
      parseInt(getItemFromQueryString(QUERY_PARAMS.PRODUCT_STATUS_ID), 10) || 0;

    const getStockFilterIdQueryFromQueryString = () =>
      parseInt(getItemFromQueryString(QUERY_PARAMS.OUT_OF_STOCK_ID), 10) || 0;

    const getProductGSTFilterIdQueryFromQueryString = () =>
      parseInt(getItemFromQueryString(QUERY_PARAMS.GST_FILTER_ID), 10) || 0;

    const getProductCustomPricingFilterIdQueryFromQueryString = () =>
      parseInt(getItemFromQueryString(QUERY_PARAMS.CUSTOM_PRICING_FILTER_ID), 10) || 0;

    const getProductImageFilterIdQueryFromQueryString = () =>
      parseInt(getItemFromQueryString(QUERY_PARAMS.IMAGE_FILTER_ID), 10) || 0;

    const getCurrentQueryParams = () => {
      const queryParams = {
        [QUERY_PARAMS.Q]: getQueryFromQueryString(),
        [QUERY_PARAMS.SPECIAL_STATUS]: getSpecialFilterQueryFromQueryString(),
        [QUERY_PARAMS.PRODUCT_STATUS_ID]: getProductStatusFilterIdQueryFromQueryString(),
        [QUERY_PARAMS.GST_FILTER_ID]: getProductGSTFilterIdQueryFromQueryString(),
        [QUERY_PARAMS.IMAGE_FILTER_ID]: getProductImageFilterIdQueryFromQueryString(),
        [QUERY_PARAMS.ORDER_STATUS_ID]: getOrderFilterIdQueryFromQueryString(),
        [QUERY_PARAMS.PAGE]: getPageNumberQueryFromQueryString(),
        [QUERY_PARAMS.CUSTOM_PRICING_FILTER_ID]: getProductCustomPricingFilterIdQueryFromQueryString(),
        [QUERY_PARAMS.DELIVERY_ZONE_STATUS]: getDeliveryZoneStatusFromQueryString(),
        [QUERY_PARAMS.VIEW_BY]: getViewFromQueryString(),
        [QUERY_PARAMS.ZONE_ID]: getZoneIdFromQueryString(),
        [QUERY_PARAMS.OUT_OF_STOCK_ID]: getStockFilterIdQueryFromQueryString(),
      };
      Object.entries(queryParams).forEach(([k, v]) => {
        if (valueIsFalseyOrEmptyArray(v)) {
          delete queryParams[k];
        }
      });
      return queryParams;
    };

    const refreshPageWithNewSearchParams = (searchParams) => {
      window.scrollTo(0, 0);
      history.replace({
        pathname: history.location.pathname,
        search: searchParams.toString(),
      });
    };

    const updateQueryParams = (fieldToUpdate, newValue, clearSearch = false) => {
      const searchParams = new URLSearchParams();
      const currentQueryParams = getCurrentQueryParams();
      delete currentQueryParams[fieldToUpdate];
      if (clearSearch && currentQueryParams.q) {
        delete currentQueryParams.q;
      }

      const appendValueToSearchParams = (key, value) => {
        if (Array.isArray(value)) {
          value.forEach((val) => {
            searchParams.append(key, val);
          });
        } else {
          searchParams.append(key, value);
        }
      };

      Object.entries(currentQueryParams).forEach(([key, value]) => {
        appendValueToSearchParams(key, value);
      });

      if (!valueIsFalseyOrEmptyArray(newValue)) {
        appendValueToSearchParams(fieldToUpdate, newValue);
      }

      refreshPageWithNewSearchParams(searchParams);
    };

    const hasQueryInQueryString = () => hasItemInQueryString(QUERY_PARAMS.Q);

    const updateSearchQueryParam = (value) => updateQueryParams(QUERY_PARAMS.Q, value);

    const updateSpecialFilterQueryParam = (value) => updateQueryParams(QUERY_PARAMS.SPECIAL_STATUS, value);

    const updateDeliveryZoneStatusQueryString = (value) => updateQueryParams(QUERY_PARAMS.DELIVERY_ZONE_STATUS, value);

    const updateViewQueryString = (value) => updateQueryParams(QUERY_PARAMS.VIEW_BY, value);

    const updateOrderFilterIdQueryParam = (value) => updateQueryParams(QUERY_PARAMS.ORDER_STATUS_ID, value);
    const updateOrderTypeIdQueryParam = (value) => updateQueryParams(QUERY_PARAMS.ORDER_TYPE_ID, value);
    const updateProductStatusFilterIdQueryParam = (value) => updateQueryParams(QUERY_PARAMS.PRODUCT_STATUS_ID, value);
    const updateStockFilterIdQueryParam = (value) => updateQueryParams(QUERY_PARAMS.OUT_OF_STOCK_ID, value);

    const updateProductGSTFilterIdQueryParam = (value) => updateQueryParams(QUERY_PARAMS.GST_FILTER_ID, value);

    const updateProductImageFilterIdQueryParam = (value) => updateQueryParams(QUERY_PARAMS.IMAGE_FILTER_ID, value);

    const updateZoneIdQueryParamAndClearViewBy = (value) => {
      const searchParams = new URLSearchParams();
      searchParams.append(QUERY_PARAMS.ZONE_ID, value);

      refreshPageWithNewSearchParams(searchParams);
    };

    const updatePageNumberQueryParam = (value) => updateQueryParams(QUERY_PARAMS.PAGE, value);

    const updateProductCustomPricingFilterIdQueryParam = (value) =>
      updateQueryParams(QUERY_PARAMS.CUSTOM_PRICING_FILTER_ID, value);

    return (
      <WrappedComponent
        hasQueryInQueryString={hasQueryInQueryString}
        updateSearchQueryParam={updateSearchQueryParam}
        getQueryFromQueryString={getQueryFromQueryString}
        updateSpecialFilterQueryParam={updateSpecialFilterQueryParam}
        getSpecialFilterQueryFromQueryString={getSpecialFilterQueryFromQueryString}
        getProductGSTFilterIdQueryFromQueryString={getProductGSTFilterIdQueryFromQueryString}
        updateProductGSTFilterIdQueryParam={updateProductGSTFilterIdQueryParam}
        getProductImageFilterIdQueryFromQueryString={getProductImageFilterIdQueryFromQueryString}
        updateProductImageFilterIdQueryParam={updateProductImageFilterIdQueryParam}
        getProductStatusFilterIdQueryFromQueryString={getProductStatusFilterIdQueryFromQueryString}
        updateProductStatusFilterIdQueryParam={updateProductStatusFilterIdQueryParam}
        updateOrderFilterIdQueryParam={updateOrderFilterIdQueryParam}
        updateOrderTypeIdQueryParam={updateOrderTypeIdQueryParam}
        getOrderFilterIdQueryFromQueryString={getOrderFilterIdQueryFromQueryString}
        updatePageNumberQueryParam={updatePageNumberQueryParam}
        getPageNumberQueryFromQueryString={getPageNumberQueryFromQueryString}
        getProductCustomPricingFilterIdQueryFromQueryString={getProductCustomPricingFilterIdQueryFromQueryString}
        updateProductCustomPricingFilterIdQueryParam={updateProductCustomPricingFilterIdQueryParam}
        getDeliveryZoneStatusFromQueryString={getDeliveryZoneStatusFromQueryString}
        getViewFromQueryString={getViewFromQueryString}
        updateDeliveryZoneStatusQueryString={updateDeliveryZoneStatusQueryString}
        updateViewQueryString={updateViewQueryString}
        getZoneIdFromQueryString={getZoneIdFromQueryString}
        updateZoneIdQueryParamAndClearViewBy={updateZoneIdQueryParamAndClearViewBy}
        getStockFilterIdQueryFromQueryString={getStockFilterIdQueryFromQueryString}
        updateStockFilterIdQueryParam={updateStockFilterIdQueryParam}
        {...props}
      />
    );
  };

  return withRedirectHelper(withQueryParamsWrapper);
}

export default withQueryParams;
